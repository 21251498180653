import { isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    minHeight: 216,
    minWidth: 240,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  upper: {
    padding: theme.spacing(4, 3),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  text: {
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 108px)',
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  iconCircle: {
    minWidth: 60,
    maxWidth: 60,
    minHeight: 60,
    maxHeight: 60,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    marginTop: 30,
    borderRadius: '50%',
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  icon: {
    fontSize: 40,
  },
  buttonContainer: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
  },
  button: {
    width: '62%',
  },
}));

const SettingCard = (props) => {
  const { className, icon, title, body, button } = props;
  const styles = useStyles();

  const renderedIcon = isValidElement(icon)
    ? cloneElement(icon, {
        className: clsx(icon.props.className, styles.icon),
      })
    : icon;

  const renderedButton = isValidElement(button)
    ? cloneElement(button, {
        className: clsx(button.props.className, styles.button),
      })
    : button;

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.upper}>
        <div className={styles.text}>
          <Typography variant="h5" className={styles.title}>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {body}
          </Typography>
        </div>
        <div className={styles.iconCircle}>{renderedIcon}</div>
      </div>
      <div className={styles.buttonContainer}>{renderedButton}</div>
    </div>
  );
};

SettingCard.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  button: PropTypes.element.isRequired,
};

export default SettingCard;
