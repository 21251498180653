import { useLocation, matchPath, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { get, find, findIndex } from 'lodash';

import {
  selectCoursesRequest,
  selectCoursesWithGraph,
  selectLessonsRequest,
  selectModulesRequest,
  selectLessonVaultResources,
} from 'Redux/selectors';
import { fetchCourses, fetchLessons, fetchModules } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import StickyTabs from 'Components/shared/sticky-tabs';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import CourseHeader from 'Components/shared/course-header';
import ModuleAccordion from 'Components/pages/help/module-accordion';
import HeaderNavigation from 'Components/pages/help/header-navigation';
import Cards from 'Components/pages/help/cards';

import { Launch as LaunchIcon } from '@material-ui/icons';
import {
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  Tab,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(10),
      paddingTop: theme.spacing(10),
    },
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  tabs: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      top: 79,
    },
  },
  container: {
    width: '100%',
  },
  list: {
    width: '100%',
    marginBottom: -1,
  },
  listItem: {
    padding: theme.spacing(3, 8, 3, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  listItemTitle: {
    marginBottom: theme.spacing(1),
  },
  listItemIcon: {
    marginRight: theme.spacing(1.5),
    pointerEvents: 'none',
    color: theme.palette.text.disabled,
  },
}));

const HelpResourcesAndToolsPage = () => {
  const styles = useStyles();
  const { hash } = useLocation();
  const { courseSlug } = useParams();
  const dispatch = useDispatch();
  const match =
    matchPath('/#/:resourceSlug/:lessonSlug', '/' + hash) ||
    matchPath('/#/:resourceSlug', '/' + hash);

  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectModulesRequest,
    selectLessonsRequest,
  );
  const courses = useSelector(selectCoursesWithGraph);

  const course = find(courses, ['slug', courseSlug]);
  const modules = course ? course.modules : null;
  const resources = useSelector(selectLessonVaultResources);

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    dispatch(fetchModules({ courseId: courseSlug }));
    dispatch(fetchLessons({ courseId: courseSlug }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug]);

  useEffect(() => {
    if (!isLoading && !error) {
      const timeout = setTimeout(() => {
        const element = document.getElementById(hash.slice(1));
        if (element) {
          element.scrollIntoView({ inline: 'nearest' });
        }
      }, 500);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  const params = get(match, 'params', {});

  const resourceSlug = params.resourceSlug || get(resources, '0.slug');

  const coursesWithResourceVault = courses
    ? courses.filter(
        (c) => c.purchasedAt && ['amazon-fba', 'shopify'].includes(c.slug),
      )
    : [];

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <CourseHeader course={course}>
            <HeaderNavigation variant="vault" option="resource-vault" />
          </CourseHeader>
          <PageContainer component="section" className={styles.root}>
            <Typography variant="h3" className={styles.title} align="center">
              Resource Vault
            </Typography>

            {coursesWithResourceVault.length > 1 ? (
              <StickyTabs
                variant="scrollable"
                scrollButtons={isSmUp ? 'off' : 'on'}
                className={styles.tabs}
                value={findIndex(
                  coursesWithResourceVault,
                  (c) => c.slug === courseSlug,
                )}
              >
                {coursesWithResourceVault.map((c) => (
                  <Tab
                    key={c.slug}
                    label={c.title}
                    component={Link}
                    to={'/help/resource-vault/' + c.slug}
                  />
                ))}
              </StickyTabs>
            ) : null}

            <div className={styles.container}>
              {resources.map((resource) => (
                <ModuleAccordion
                  key={resource.slug}
                  id={`/${resource.slug}`}
                  href={`./#/${resource.slug}`}
                  expanded={resourceSlug === resource.slug}
                  title={resource.title}
                >
                  <List className={styles.list} disablePadding>
                    {resource.links.map((link, index) => (
                      <ListItem
                        key={index}
                        className={styles.listItem}
                        divider={index !== modules.length - 1}
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={link.url}
                      >
                        <Typography
                          variant="subtitle1"
                          className={styles.listItemTitle}
                          color="textPrimary"
                        >
                          {link.title}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {link.description}
                        </Typography>
                        <ListItemSecondaryAction
                          className={styles.listItemIcon}
                        >
                          <LaunchIcon fontSize="large" />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </ModuleAccordion>
              ))}
            </div>
          </PageContainer>
          <Cards />
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default HelpResourcesAndToolsPage;
