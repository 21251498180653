import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import {
  makeStyles,
  AccordionDetails,
  Typography,
  withStyles,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core';

const Accordion = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    '&$expanded': {
      marginBottom: theme.spacing(3),
    },
    '&:before': {
      display: 'none',
    },
  },
  rounded: {
    borderRadius: theme.shape.borderRadius + 'px !important',
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    minHeight: 84,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: 1,

    '&$expanded': {
      minHeight: 84,
    },
  },
  expanded: {
    backgroundColor: theme.palette.grey[50],
    minHeight: 84,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  expandIcon: {
    width: 48,
    maxHeight: 48,
    minHeight: 48,
    transform: 'rotate(-90deg)',
    '&$expanded': {
      transform: 'rotate(0deg)',
    },
  },
  content: {
    margin: 0,
    alignItems: 'center',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&$expanded': {
      margin: 0,
    },
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}))(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  details: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    padding: 0,
  },
  content: {
    width: '100%',
  },
}));

const ModuleAccordion = (props) => {
  const { href, title, children, ...rest } = props;
  const styles = useStyles();

  return (
    <Accordion {...rest}>
      <AccordionSummary
        component={Link}
        to={href}
        expandIcon={<ExpandMoreIcon fontSize="large" />}
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.details}>
        <div className={styles.content}>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};

ModuleAccordion.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ModuleAccordion;
