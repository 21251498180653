import { useEffect } from 'react';
import useNavigateExternal from 'Hooks/use-navigate-external';

const NavigateExternal = ({ to, replace, state }) => {
  const navigate = useNavigateExternal();
  useEffect(() => {
    navigate(to, { replace, state });
  });

  return null;
};

export default NavigateExternal;
