import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useState, useCallback } from 'react';
import { times, find, get } from 'lodash';
import { format, parseISO } from 'date-fns';
import clsx from 'clsx';

import {
  selectAchievementLevelsRequest,
  selectAchievementsRequest,
  selectAchievementStatsRequest,
  selectCoursesRequest,
  selectCoursesWithGraph,
  selectLastLesson,
  selectLastLessonRequest,
  selectLessonsRequest,
  selectMe,
  selectMeRequest,
  selectModulesRequest,
} from 'Redux/selectors';
import {
  fetchAchievements,
  fetchAchievementStats,
  fetchCourses,
  fetchLastLesson,
  fetchLessons,
  fetchModules,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import ChangeGoalDialog from 'Dialogs/change-goal-dialog';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import Carousel from 'Components/shared/carousel';
import ArrowButton from 'Components/shared/arrow-button';
import AchievementLevels from 'Components/shared/achievement-levels';
import AchievementCard from 'Components/shared/achievement-card';
import Header from 'Components/pages/learn/header';
import AchievementList from 'Components/pages/learn/achievement-list';

import {
  makeStyles,
  withStyles,
  Divider as MuiDivider,
  Typography,
  Button,
} from '@material-ui/core';

const Divider = withStyles((theme) => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: `calc(100% - ${theme.spacing(8)}px)`,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.spacing(12)}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${theme.spacing(20)}px)`,
    },
  },
}))(MuiDivider);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    marginBottom: 0,
    maxWidth: 'unset',
  },
  goalTitle: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(3),
  },
  achievementsTitle: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
  },
  achievementLevels: {
    marginBottom: theme.spacing(6),
  },
  productsTitle: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
  text: {
    maxWidth: 564,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(0, 3),
    },
  },
  deadline: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(10),
  },
  switcher: {
    height: 48,
    display: 'grid',
    gridTemplateColumns: '40px auto 40px',
    alignItems: 'center',
    columnGap: theme.spacing(2) + 'px',
  },
  achievementsSwitcher: {
    marginBottom: theme.spacing(3),
  },
  studentsSwitcher: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(0, 1),
  },
  carousel: {
    marginBottom: theme.spacing(9),
    width: '100%',
  },
}));

const DashboardPage = () => {
  const styles = useStyles();
  const { courseSlug } = useParams();
  const [view, setView] = useState('top');
  const [currentModule, setCurrentModule] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading: isLoadingGraph, error: graphError } =
    useCombinedRequestsSelector(
      selectMeRequest,
      selectCoursesRequest,
      selectModulesRequest,
      selectLessonsRequest,
      selectAchievementsRequest,
      selectAchievementStatsRequest,
      selectAchievementLevelsRequest,
    );
  // This request must run separatelly because it might return 404
  // and the error should be ignored
  const { isLoading: isLoadingLastLesson, lastLessonError } = useSelector(
    selectLastLessonRequest,
  );

  const isLoading = isLoadingGraph || isLoadingLastLesson;
  const error =
    graphError ||
    (lastLessonError && lastLessonError.code !== 'ERROR_CODE_NOT_FOUND'
      ? lastLessonError
      : null);

  const courses = useSelector(selectCoursesWithGraph);
  const me = useSelector(selectMe);
  const lastLesson = useSelector(selectLastLesson);

  const course = find(courses, ['slug', courseSlug]);

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    dispatch(fetchModules({ courseId: courseSlug }, { isPromise: true })).catch(
      (error) => {
        if (error?.error?.code === 'ERROR_CODE_FORBIDDEN') {
          navigate('/?lostAccessTo=' + courseSlug);
        }
      },
    );
    dispatch(fetchLessons({ courseId: courseSlug }));
    dispatch(fetchLastLesson({ courseId: courseSlug }));
    dispatch(fetchAchievements({ courseId: courseSlug }));
    dispatch(fetchAchievementStats());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug, navigate]);

  const [showChangeGoalModal, hideChangeGoalModal] = useModal(() => (
    <ChangeGoalDialog onClose={hideChangeGoalModal} />
  ));

  const handleToggleView = useCallback(
    () => setView(view === 'top' ? 'recent' : 'top'),
    [view],
  );
  const handlePrevModule = useCallback(
    () => setCurrentModule(currentModule - 1),
    [currentModule],
  );
  const handleNextModule = useCallback(
    () => setCurrentModule(currentModule + 1),
    [currentModule],
  );

  const modules = course ? course.modules.map((m) => m.title) : null;

  const lesson =
    lastLesson ||
    get(find(course ? course.modules : [], ['index', 0]), 'lessons.0');
  const module =
    lesson && course ? find(course.modules, ['id', lesson.moduleId]) : null;

  const studentAchievements = times(5, (num) => ({
    id: 'id-' + num,
    title: '$10,000/month',
    user: {
      firstName: 'Scott',
      lastName: 'Suggs',
      image: {
        url: 'https://ecomfreedom-public-assets.s3.amazonaws.com/app/scott-suggs.jpg',
        isResizable: true,
      },
      country: num % 2 ? 'US' : 'CA',
    },
  }));

  if (!isLoading && (!course || !module || !lesson)) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <Header />
          <PageContainer component="section" className={styles.root}>
            <Typography className={styles.goalTitle} variant="h4">
              My Goal
            </Typography>
            {me.goal ? (
              <>
                <Typography variant="h6" className={styles.text} align="center">
                  {me.goal}
                </Typography>
                <Typography variant="body1" className={styles.deadline}>
                  Deadline: {format(parseISO(me.goalDeadlineAt), 'MMM d, yyyy')}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body1" className={styles.deadline}>
                  You haven't set your goal yet.
                </Typography>
              </>
            )}

            <Button
              variant="outlined"
              color="primary"
              className={styles.button}
              onClick={showChangeGoalModal}
              data-testid="setGoal"
            >
              {me.goal ? 'Change my goal' : 'Set my goal'}
            </Button>

            {course.modules[currentModule] &&
            course.modules[currentModule].achievements &&
            course.modules[currentModule].achievements.length ? (
              <>
                <Divider variant="middle" />

                <Typography className={styles.achievementsTitle} variant="h4">
                  Achievements
                </Typography>

                <AchievementLevels className={styles.achievementLevels} />

                <div
                  className={clsx(styles.switcher, styles.achievementsSwitcher)}
                >
                  <ArrowButton
                    orientation="left"
                    onClick={handlePrevModule}
                    disabled={currentModule === 0}
                    data-testid="prevModule"
                  />

                  <Typography variant="h5">{modules[currentModule]}</Typography>

                  <ArrowButton
                    orientation="right"
                    onClick={handleNextModule}
                    disabled={currentModule === modules.length - 1}
                    data-testid="nextModule"
                  />
                </div>
                <Carousel
                  className={styles.carousel}
                  data={course.modules[currentModule].achievements}
                  component={AchievementCard}
                  slideWidth={268}
                />
              </>
            ) : null}

            {/* Disabled until we get enough data */}
            {false && (
              <>
                <Divider variant="middle" />

                <div className={clsx(styles.switcher, styles.studentsSwitcher)}>
                  <ArrowButton
                    data-testid="prevStudents"
                    orientation="left"
                    onClick={handleToggleView}
                  />
                  <Typography variant="h4" align="center">
                    {view === 'top' ? 'Top' : 'Rising'} Students
                  </Typography>
                  <ArrowButton
                    data-testid="nextStudents"
                    orientation="right"
                    onClick={handleToggleView}
                  />
                </div>

                <Carousel
                  className={styles.carousel}
                  data={times(2, () => ({
                    achievements: studentAchievements,
                  }))}
                  breakpoint="lg"
                  slidesToShow={1}
                  component={AchievementList}
                  slideWidth={820}
                />
              </>
            )}
          </PageContainer>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default DashboardPage;
