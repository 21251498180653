import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPLOAD_ACHIEVEMENT_LEVEL_PICTURE_SUCCESS } from './upload-achievement-level-picture';
import { UPDATE_ACHIEVEMENT_LEVEL_SUCCESS } from './update-achievement-level';
import { DELETE_ACHIEVEMENT_LEVEL_PICTUREL_SUCCESS } from './delete-achievement-level-picture';

const MODULE = 'achievement-levels';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchAchievementLevels = Exec.requestActionCreator(MODULE);
export const fetchAchievementLevelsLoading = Exec.loadingActionCreator(MODULE);
export const fetchAchievementLevelsSuccess = Exec.successActionCreator(MODULE);
export const fetchAchievementLevelsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ACHIEVEMENT_LEVEL_SUCCESS:
    case DELETE_ACHIEVEMENT_LEVEL_PICTUREL_SUCCESS:
    case UPLOAD_ACHIEVEMENT_LEVEL_PICTURE_SUCCESS:
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((v) => (v.id === data.id ? data : v)),
      };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectAchievementLevelsRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectAchievementLevels = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchAchievementLevels = function* ({ meta }) {
  try {
    const { data } = yield call(Api.getAchievementLevels);
    yield put(fetchAchievementLevelsSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchAchievementLevelsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchAchievementLevels);
