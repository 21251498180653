import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'event-video';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////

export const fetchEventVideo = Exec.requestActionCreator(MODULE);
export const fetchEventVideoLoading = Exec.loadingActionCreator(MODULE);
export const fetchEventVideoSuccess = Exec.successActionCreator(MODULE);
export const fetchEventVideoError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE, {
  cacheControl: Exec.CACHE_CONTROL_NO_CACHE,
});

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectEventVideoRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectEventVideo = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchEventVideo = function* ({ payload, meta }) {
  try {
    const { data } = yield call(
      Api.getEventVideo,
      payload.eventId,
      payload.recordingOnly,
    );
    yield put(fetchEventVideoSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchEventVideoError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchEventVideo);
