import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectArchiveOfferRequest } from 'Redux/selectors';
import { archiveOffer, enqueueSnackbar } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmArchiveOfferDialog = (props) => {
  const { onClose, offerId } = props;
  const { isLoading } = useSelector(selectArchiveOfferRequest);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleArchive = useCallback(() => {
    dispatch(archiveOffer({ offerId }, { isPromise: true }))
      .then(() => {
        dispatch(
          enqueueSnackbar('SUCCESS', {
            variant: 'success',
            message: 'Offer archived successfully',
          }),
        );
        navigate('/admin/offers');
        onClose && onClose();
      })
      .catch(noop);
  }, [dispatch, navigate, offerId, onClose]);

  return (
    <Dialog
      title="Archive Offer"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleArchive}>
          Archive
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to archive this offer? You can reactivate the
        offer later.
      </Typography>
    </Dialog>
  );
};

ConfirmArchiveOfferDialog.propTypes = {
  offerId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmArchiveOfferDialog;
