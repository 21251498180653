import { put, call, select } from 'redux-saga/effects';
import { pick, filter } from 'lodash';
import elasticlunr from 'elasticlunr';
import { isAfter, addMinutes } from 'date-fns';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'search';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SEARCH_SET_INDEX = 'search-set-index';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const search = Exec.requestActionCreator(MODULE);
export const searchLoading = Exec.loadingActionCreator(MODULE);
export const searchSuccess = Exec.successActionCreator(MODULE);
export const searchError = Exec.errorActionCreator(MODULE);

const searchSetIndex = (payload) => {
  return {
    type: SEARCH_SET_INDEX,
    payload,
  };
};

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE, {
  cacheControl: Exec.CACHE_CONTROL_NO_CACHE,
  defaultState: {
    index: null,
    indexBuiltAt: null,
  },
});

export const reducer = (state, action) => {
  switch (action.type) {
    case SEARCH_SET_INDEX:
      return { ...state, ...action.payload };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSearchRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectSearch = Exec.dataSelectorCreator(MODULE);

const selectSearchIndex = (store) =>
  pick(store.search, ['index', 'indexBuiltAt']);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSearch = function* ({ payload, meta }) {
  const previous = yield select(selectSearchIndex);

  let instance = null;
  let index = previous.index;

  try {
    if (!index || isAfter(new Date(), addMinutes(previous.indexBuiltAt, 5))) {
      yield put(searchLoading());

      try {
        const { data } = yield call(Api.getLunrCoursesIndex);

        yield put(
          searchSetIndex({ index: data.index, indexBuiltAt: new Date() }),
        );

        instance = elasticlunr.Index.load(data.index);

        index = data.index;
      } catch (err) {
        if (index) {
          instance = elasticlunr.Index.load(index);
        } else {
          throw err;
        }
      }
    } else {
      instance = elasticlunr.Index.load(index);
    }

    const all = instance
      .search(payload.query, {
        fields: {
          title: { boost: 3 },
          description: { boost: 2 },
        },
      })
      .filter((res) => res.score > 1)
      .map((res) => index.documentStore.docs[res.ref]);

    yield put(
      searchSuccess(
        {
          data: {
            questions: filter(all, ['type', 'question']),
            resources: filter(all, ['type', 'resource']),
          },
        },
        meta,
      ),
    );
  } catch (err) {
    yield put(searchError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSearch);
