import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { find, groupBy, map } from 'lodash';

import { selectCourses, selectCoursesRequest } from 'Redux/selectors';
import { enqueueSnackbar, fetchCourses } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import TrustpilotUnlockDialog from 'Dialogs/trustpilot-unlock-dialog';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import Divider from 'Components/shared/divider';
import CourseCard from 'Components/shared/course-card';

import { LockOpen as LockOpenIcon } from '@material-ui/icons';
import { makeStyles, useMediaQuery, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(9),
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    maxWidth: 768,
    marginBottom: theme.spacing(6),
  },
  item: {
    margin: theme.spacing(0, 0, 4),
  },
  divider: {
    margin: theme.spacing(2, 0, 4),
  },
}));

const IndexPage = () => {
  const styles = useStyles();
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const once = useRef(false);
  const [showUnlockTrustpilotModalTitle, setUnlockTrustpilotModalTitle] =
    useState(null);

  const { isLoading, error } = useSelector(selectCoursesRequest);
  const courses = useSelector(selectCourses);

  const handleShowUnlockTrustpilotModal = useMemoizedCallback(
    (title) => () => setUnlockTrustpilotModalTitle(title),
    [],
  );
  const handleHideUnlockTrustpilotDialog = useCallback(
    () => setUnlockTrustpilotModalTitle(null),
    [],
  );

  const mappedCourses =
    courses &&
    courses
      .filter((course) => !('isVisible' in course) || course.isVisible)
      .map((course) => {
        const locked = !Boolean(course.purchasedAt);
        return {
          ...course,
          locked,
          subtitle: { COURSE: 'Course', BLUEPRINT: 'Blueprint' }[course.type],
          ButtonProps: locked
            ? course.isReleased
              ? course.unlockAfterTrustpilotReview
                ? {
                    'data-testid': 'showUnlockModal',
                    label: 'Unlock Now!',
                    startIcon: <LockOpenIcon />,
                    component: 'button',
                    onClick: handleShowUnlockTrustpilotModal(course.title),
                  }
                : course.unlockAfterTrustpilotReviewInDays
                ? {
                    label: `Unlocks in ${
                      course.unlockAfterTrustpilotReviewInDays
                    } day${
                      course.unlockAfterTrustpilotReviewInDays === 1 ? '' : 's'
                    }`,
                    component: 'div',
                  }
                : {
                    'data-testid': 'courseLink',
                    label: 'Enroll',
                    component: 'a',
                    href: course.salesPageUrl,
                  }
              : {
                  label: 'Coming Soon',
                  component: 'div',
                }
            : {
                'data-testid': 'courseLink',
                label: 'Learn Now',
                to: '/learn/' + course.slug,
              },
        };
      });

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const lostAccessTo = params.get('lostAccessTo');
    const course = find(courses, ['slug', lostAccessTo]);
    if (course && !once.current) {
      once.current = true;
      dispatch(
        enqueueSnackbar('LOST_ACCESS_TO', {
          message: `You no longer have access to the ${course.title} course.`,
          variant: 'error',
        }),
      );
    }
  }, [courses, dispatch, params]);

  const groups = useMemo(
    () =>
      map(groupBy(mappedCourses, 'type'), (items, type) => ({
        name: {
          BLUEPRINT: 'Blueprints',
          COURSE: 'Courses',
        }[type],
        items,
      })),
    [mappedCourses],
  );

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <PageContainer className={styles.root}>
            {showUnlockTrustpilotModalTitle ? (
              <TrustpilotUnlockDialog
                title={showUnlockTrustpilotModalTitle}
                onClose={handleHideUnlockTrustpilotDialog}
              />
            ) : null}
            {groups.map((group) => (
              <div className={styles.group} key={group.name}>
                <Typography align="center" variant="h4">
                  {group.name}
                </Typography>
                <Divider className={styles.divider} />
                <Grid
                  container
                  spacing={isLgUp ? 6 : isMdUp ? 3 : 2}
                  justify="center"
                >
                  {group.items.map((item, index) => (
                    <Grid
                      key={index}
                      className={styles.item}
                      item
                      xs={12}
                      sm={6}
                    >
                      <CourseCard elevation={8} {...item} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </PageContainer>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default IndexPage;
