import { put, take, call, race } from 'redux-saga/effects';
import { get } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import {
  uploadFile,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_CANCEL,
  UPLOAD_FILE_ERROR,
} from './upload-file';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { fetchLessonVideoSuccess } from './lesson-video';

const MODULE = 'upload-lesson-video';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPLOAD_LESSON_VIDEO_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPLOAD_LESSON_VIDEO_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const uploadLessonVideo = Exec.requestActionCreator(MODULE);
export const uploadLessonVideoLoading = Exec.loadingActionCreator(MODULE);
export const uploadLessonVideoSuccess = Exec.successActionCreator(MODULE);
export const uploadLessonVideoError = Exec.errorActionCreator(MODULE);
export const uploadLessonVideoReset = Exec.resetActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUploadLessonVideoRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUploadLessonVideo = function* ({ payload, meta }) {
  const { courseId, moduleId, lessonId, file } = payload || {};

  try {
    yield put(closeSnackbar(UPLOAD_LESSON_VIDEO_REQUEST));

    yield put(uploadLessonVideoLoading());
    yield put(
      uploadFile({
        method: 'PUT',
        url: '/upload/wistia-video?fileName=' + file.name,
        data: file,
        headers: {
          'Content-Type': file.type,
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        axios: Api.axios,
      }),
    );
    const [success, cancel, error] = yield race([
      take(UPLOAD_FILE_SUCCESS),
      take(UPLOAD_FILE_CANCEL),
      take(UPLOAD_FILE_ERROR),
    ]);

    if (error) {
      throw error.payload.error;
    }

    if (cancel) {
      return;
    }

    const wistiaVideoUrl = get(success, 'payload.data.url');
    const { data } = yield call(
      Api.updateLesson,
      courseId,
      moduleId,
      lessonId,
      {
        video: {
          url: wistiaVideoUrl,
          fileName: file.name,
        },
      },
    );

    const { data: lessonVideo } = yield call(
      Api.getLessonVideo,
      courseId,
      moduleId,
      lessonId,
      true,
    );
    yield put(fetchLessonVideoSuccess({ data: lessonVideo }));

    yield put(uploadLessonVideoSuccess({ data }, meta));
  } catch (err) {
    yield put(uploadLessonVideoError(err, meta));

    yield put(
      enqueueSnackbar(UPLOAD_LESSON_VIDEO_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUploadLessonVideo);
