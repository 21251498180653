import { useMemo } from 'react';
import PropTypes from 'prop-types';
import flag from 'country-code-emoji';
import clsx from 'clsx';

import countries from 'Util/countries';

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  flag: {
    fontFamily: '"Noto Color Emoji"',
    marginRight: 4,
    maxWidth: 24,
  },
}));

const UserLocation = (props) => {
  const { className, flag: showFlag, user, ...rest } = props;
  const styles = useStyles();

  const country = useMemo(
    () => countries.find((c) => c.code === user.country),
    [user.country],
  );

  return (
    <Typography
      className={clsx(styles.root, className)}
      variant="body1"
      {...rest}
    >
      {showFlag && <span className={styles.flag}>{flag(user.country)}</span>}
      {user.city ? user.city + ', ' : ''}
      {country ? country.name : 'Earth'}
    </Typography>
  );
};

UserLocation.propTypes = {
  flag: PropTypes.bool,
  user: PropTypes.shape({
    country: PropTypes.string.isRequired,
    city: PropTypes.string,
  }),
};

UserLocation.defaultProps = {
  flag: true,
};

export default UserLocation;
