import { put, take, call, race } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import {
  uploadFile,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_CANCEL,
  UPLOAD_FILE_ERROR,
} from './upload-file';

const MODULE = 'submit-achievement';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
export const SUBMIT_ACHIEVEMENT_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const submitAchievement = Exec.requestActionCreator(MODULE);
export const submitAchievementLoading = Exec.loadingActionCreator(MODULE);
export const submitAchievementSuccess = Exec.successActionCreator(MODULE);
export const submitAchievementError = Exec.errorActionCreator(MODULE);
export const submitAchievementReset = Exec.resetActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSubmitAchievementRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSubmitAchievement = function* ({ payload, meta }) {
  const { file, courseId, id } = payload || {};

  try {
    yield put(submitAchievementLoading());

    const {
      data: { url: signedUrl },
    } = yield call(Api.createSignedUrl, 'achievements', file.type);

    yield put(
      uploadFile({
        method: 'PUT',
        url: signedUrl,
        data: file,
        headers: {
          'Content-Type': file.type,
        },
      }),
    );

    const [, cancel, error] = yield race([
      take(UPLOAD_FILE_SUCCESS),
      take(UPLOAD_FILE_CANCEL),
      take(UPLOAD_FILE_ERROR),
    ]);

    if (error) {
      throw error.payload.error;
    }

    if (cancel) {
      return;
    }

    const url = new URL(signedUrl);
    url.search = '';

    const { data } = yield call(Api.submitAchievement, courseId, id, url.href);

    yield put(submitAchievementSuccess({ data }, meta));
  } catch (err) {
    yield put(submitAchievementError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSubmitAchievement);
