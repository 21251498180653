import { put, select, call } from 'redux-saga/effects';
import hash from 'object-hash';
import { toNumber, omit } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPDATE_USER_SUCCESS } from './update-user';
import { DELETE_USER_SUCCESS } from './delete-user';

const MODULE = 'users';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const FETCH_USERS_SET_ROWS_PER_PAGE = 'fetch-users-set-rows-per-page';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchUsers = Exec.requestActionCreator(MODULE);
export const fetchUsersLoading = Exec.loadingActionCreator(MODULE);
export const fetchUsersSuccess = Exec.successActionCreator(MODULE);
export const fetchUsersError = Exec.errorActionCreator(MODULE);
export const fetchUsersSetRowsPerPage = (pageSize) => {
  return {
    type: FETCH_USERS_SET_ROWS_PER_PAGE,
    payload: {
      pageSize,
    },
  };
};

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE, {
  defaultState: { pageSize: 25 },
  cacheKeyFunction: (payload) =>
    hash(omit(payload, ['orderBy', 'orderDirection'])),
});
export const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_USERS_SET_ROWS_PER_PAGE:
      return {
        ...state,
        pageSize: action.payload.pageSize,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        data: state.data
          ? state.data.map((user) =>
              user.id === action.payload.data.id ? action.payload.data : user,
            )
          : null,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        data: state.data
          ? state.data.filter((user) => user.id !== action.payload.data.id)
          : null,
      };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUsersRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectUsers = Exec.dataSelectorCreator(MODULE);

export const selectUsersRowsPerPage = (store) => store.users.pageSize;
export const selectUsersTotalCount = (store) =>
  store.users.pageInfo ? store.users.pageInfo.totalCount : 0;

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchUsers = function* ({ payload, meta }) {
  const { page = 0, ...searchOptions } = payload || {};
  const pageSize = yield select(selectUsersRowsPerPage);
  const offset = pageSize * toNumber(page);

  try {
    const { data, __meta = {} } = yield call(
      Api.getUsers,
      { ...searchOptions, withUnverified: true, withAccountStats: true },
      pageSize,
      offset,
    );

    yield put(fetchUsersSuccess({ data, pageInfo: __meta.pageInfo }, meta));
  } catch (err) {
    yield put(fetchUsersError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchUsers);
