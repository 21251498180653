import { createSelector } from 'reselect';

import { selectVerifyUserRequest } from './modules/verify-user';
import { selectUploadProfilePictureRequest } from './modules/upload-profile-picture';
import { selectUploadLessonVideoRequest } from './modules/upload-lesson-video';
import { selectUploadLessonBoxsetPdfRequest } from './modules/upload-lesson-boxset-pdf';
import { selectUploadEventRecordingRequest } from './modules/upload-event-recording';
import { selectUploadCourseGraphicRequest } from './modules/upload-course-graphic';
import { selectUploadCourseBackgroundRequest } from './modules/upload-course-background';
import { selectUploadAchievementLevelPictureRequest } from './modules/upload-achievement-level-picture';
import { selectUploadAchievementGraphicRequest } from './modules/upload-achievement-graphic';
import { selectUpgradeCourseTrialRequest } from './modules/upgrade-course-trial';
import { selectUpgradeCourseMentorshipRequest } from './modules/upgrade-course-mentorship';
import { selectUpdateWebhookRequest } from './modules/update-webhook';
import { selectUpdateUserRequest } from './modules/update-user';
import { selectUpdateResourceRequest } from './modules/update-resource';
import { selectUpdateQuestionRequest } from './modules/update-question';
import { selectUpdatePaymentLinkRequest } from './modules/update-payment-link';
import { selectUpdateOfferRequest } from './modules/update-offer';
import { selectUpdateModuleRequest } from './modules/update-module';
import { selectUpdateMeRequest } from './modules/update-me';
import { selectUpdateLessonRequest } from './modules/update-lesson';
import { selectUpdateEventRequest } from './modules/update-event';
import { selectUpdateCourseRequest } from './modules/update-course';
import { selectUpdateAchievementLevelRequest } from './modules/update-achievement-level';
import { selectUpdateAchievementRequest } from './modules/update-achievement';
import { selectSuspendUserRequest } from './modules/suspend-user';
import { selectSubscribeToListRequest } from './modules/subscribe-to-list';
import { selectSubmitToolProblemRequest } from './modules/submit-tool-problem';
import { selectSignoutRequest } from './modules/signout';
import { selectSigninRequest } from './modules/signin';
import { selectSetDefaultPaymentMethodRequest } from './modules/set-default-payment-method';
import { selectRevokeUserSuspensionRequest } from './modules/revoke-user-suspension';
import { selectRestoreOfferRequest } from './modules/restore-offer';
import { selectResetPasswordRequest } from './modules/reset-password';
import { selectRegisterRequest } from './modules/register';
import { selectRefundTransactionsRequest } from './modules/refund-transactions';
import { selectPageSuspended } from './modules/page-suspended';
import { selectMeRequest } from './modules/me';
import { selectDeleteUserRequest } from './modules/delete-user';
import { selectDeleteProfilePictureRequest } from './modules/delete-profile-picture';
import { selectDeletePaymentMethodRequest } from './modules/delete-payment-method';
import { selectDeletePaymentLinkRequest } from './modules/delete-payment-link';
import { selectDeleteEventRequest } from './modules/delete-event';
import { selectDeleteAchievementLevelPictureRequest } from './modules/delete-achievement-level-picture';
import { selectCreateWebhookRequest } from './modules/create-webhook';
import { selectCreateUserRequest } from './modules/create-user';
import { selectCreateTopicRequest } from './modules/create-topic';
import { selectCreateResourceRequest } from './modules/create-resource';
import { selectCreateQuestionRequest } from './modules/create-question';
import { selectCreatePaymentMethodRequest } from './modules/create-payment-method';
import { selectCreatePaymentLinkRequest } from './modules/create-payment-link';
import { selectCreatePaymentRequest } from './modules/create-payment';
import { selectCreateOfferRequest } from './modules/create-offer';
import { selectCreateModuleRequest } from './modules/create-module';
import { selectCreateLessonRequest } from './modules/create-lesson';
import { selectCreateEventRequest } from './modules/create-event';
import { selectCreateCourseBackupRequest } from './modules/create-course-backup';
import { selectCreateCourseRequest } from './modules/create-course';
import { selectCreateAffiliateRedeemRequestRequest } from './modules/create-affiliate-redeem-request';
import { selectCreateAchievementRequest } from './modules/create-achievement';
import { selectConfirmRegisterRequest } from './modules/confirm-register';
import { selectConfirmEmailChangeRequest } from './modules/confirm-email-change';
import { selectConfirmBoxsetShippingAddressRequest } from './modules/confirm-boxset-shipping-address';
import { selectChangePasswordRequest } from './modules/change-password';
import { selectCancelTrialsRequest } from './modules/cancel-trials';
import { selectArchiveOfferRequest } from './modules/archive-offer';

export {
  selectAchievementStats,
  selectAchievementStatsRequest,
} from './modules/achievement-stats';
export {
  selectAchievementLevels,
  selectAchievementLevelsRequest,
} from './modules/achievement-levels';
export {
  selectAchievements,
  selectAchievementsRequest,
} from './modules/achievements';
export {
  selectActiveCampaignLists,
  selectActiveCampaignListsRequest,
} from './modules/active-campaign-lists';
export {
  selectAffiliateEarnings,
  selectAffiliateEarningsRequest,
  selectAffiliateEarningsRowsPerPage,
  selectAffiliateEarningsTotalCount,
} from './modules/affiliate-earnings';
export {
  selectAffiliateRedeemRequests,
  selectAffiliateRedeemRequestsRequest,
  selectAffiliateRedeemRequestsRowsPerPage,
  selectAffiliateRedeemRequestsTotalCount,
} from './modules/affiliate-redeem-requests';
export {
  selectAffiliateStats,
  selectAffiliateStatsRequest,
} from './modules/affiliate-stats';
export {
  selectLastLesson,
  selectLastLessonRequest,
} from './modules/last-lesson';
export {
  selectEventAttendees,
  selectEventAttendeesRequest,
  selectEventAttendeesMaxPages,
} from './modules/event-attendees';
export {
  selectEventVideo,
  selectEventVideoRequest,
} from './modules/event-video';
export { selectEvent, selectEventRequest } from './modules/event';
export {
  selectEvents,
  selectUpcomingEvents,
  selectPastEvents,
  selectEventsRequest,
  selectEventsTotalCount,
} from './modules/events';
export {
  selectLessonVideo,
  selectLessonVideoRequest,
} from './modules/lesson-video';
export {
  selectLessons,
  selectLessonsRequest,
  selectLessonQuestionsWithTags,
  selectLessonQuestionTopics,
  selectLessonVaultResources,
} from './modules/lessons';
export { selectMe } from './modules/me';
export { selectOnboardingPostponedAt } from './modules/onboarding-postponed-at';
export { selectModules, selectModulesRequest } from './modules/modules';
export { selectRecordLessonProgressRequest } from './modules/record-lesson-progress';
export {
  selectCoursesRequest,
  selectCourses,
  selectCoursesWithGraph,
  selectLastWatchedCourse,
  selectCoursesWithSubscriptionStatus,
} from './modules/courses';
export { selectOffers, selectOffersRequest } from './modules/offers';
export {
  selectPaymentLinks,
  selectPaymentLinksRequest,
  selectPaymentLinksRowsPerPage,
  selectPaymentLinksTotalCount,
} from './modules/payment-links';
export {
  selectPaymentLink,
  selectPaymentLinkRequest,
} from './modules/payment-link';
export {
  selectPaymentMethods,
  selectPaymentMethodsRequest,
} from './modules/payment-methods';
export {
  selectQuestions,
  selectQuestionsRequest,
  selectQuestionsTotalCount,
} from './modules/questions';
export {
  selectResources,
  selectResourcesRequest,
  selectResourcesTotalCount,
} from './modules/resources';
export { selectRegisterLastPayload } from './modules/register';
export { selectResetPasswordLastPayload } from './modules/reset-password';
export { selectRoles, selectRolesRequest } from './modules/roles';
export { selectSearch, selectSearchRequest } from './modules/search';
export { selectSnackbarNotifications } from './modules/snackbar';
export { selectSubmitAchievementRequest } from './modules/submit-achievement';
export { selectSortCoursesRequest } from './modules/sort-courses';
export { selectSortLessonQuestionsRequest } from './modules/sort-lesson-questions';
export { selectSortLessonResourcesRequest } from './modules/sort-lesson-resources';
export { selectSortLessonsRequest } from './modules/sort-lessons';
export { selectSortModulesRequest } from './modules/sort-modules';
export { selectSortAchievementsRequest } from './modules/sort-achievements';
export {
  selectSubscriptionSteps,
  selectSubscriptionStepsRequest,
} from './modules/subscription-steps';
export { selectConfirmBoxsetShippingAddressPostponedAt } from './modules/confirm-boxset-shipping-address-postponed-at';
export {
  selectTransactions,
  selectTransactionsRequest,
} from './modules/transactions';
export { selectTrials, selectTrialsRequest } from './modules/trials';
export { selectTopics, selectTopicsRequest } from './modules/topics';
export { selectUploadFileRequest } from './modules/upload-file';
export {
  selectRememberMe,
  selectRememberMeAsSessionType,
} from './modules/remember-me';
export { selectUserStats, selectUserStatsRequest } from './modules/user-stats';
export { selectUser, selectUserRequest } from './modules/user';
export {
  selectUsers,
  selectUsersRequest,
  selectUsersRowsPerPage,
  selectUsersTotalCount,
} from './modules/users';
export { selectUpgradeCourseMentorship } from './modules/upgrade-course-mentorship';
export { selectUpgradeCourseTrial } from './modules/upgrade-course-trial';
export { selectWebhooks, selectWebhooksRequest } from './modules/webhooks';

export {
  selectMeRequest,
  selectArchiveOfferRequest,
  selectCancelTrialsRequest,
  selectChangePasswordRequest,
  selectConfirmEmailChangeRequest,
  selectConfirmRegisterRequest,
  selectConfirmBoxsetShippingAddressRequest,
  selectCreateTopicRequest,
  selectCreateQuestionRequest,
  selectCreateResourceRequest,
  selectCreatePaymentMethodRequest,
  selectCreatePaymentLinkRequest,
  selectCreateModuleRequest,
  selectCreateLessonRequest,
  selectCreateEventRequest,
  selectCreateCourseRequest,
  selectCreateCourseBackupRequest,
  selectCreateOfferRequest,
  selectCreatePaymentRequest,
  selectCreateUserRequest,
  selectCreateAffiliateRedeemRequestRequest,
  selectCreateAchievementRequest,
  selectDeleteProfilePictureRequest,
  selectDeletePaymentMethodRequest,
  selectDeletePaymentLinkRequest,
  selectDeleteEventRequest,
  selectDeleteAchievementLevelPictureRequest,
  selectCreateWebhookRequest,
  selectDeleteUserRequest,
  selectRegisterRequest,
  selectRefundTransactionsRequest,
  selectPageSuspended,
  selectResetPasswordRequest,
  selectRestoreOfferRequest,
  selectRevokeUserSuspensionRequest,
  selectSetDefaultPaymentMethodRequest,
  selectSigninRequest,
  selectSignoutRequest,
  selectSubmitToolProblemRequest,
  selectSubscribeToListRequest,
  selectSuspendUserRequest,
  selectUpdateLessonRequest,
  selectUpdateMeRequest,
  selectUpdateModuleRequest,
  selectUpdateOfferRequest,
  selectUpdateUserRequest,
  selectUpdateQuestionRequest,
  selectUpdatePaymentLinkRequest,
  selectUpdateResourceRequest,
  selectUpdateWebhookRequest,
  selectUpgradeCourseMentorshipRequest,
  selectUpgradeCourseTrialRequest,
  selectUpdateEventRequest,
  selectUpdateCourseRequest,
  selectUpdateAchievementLevelRequest,
  selectUpdateAchievementRequest,
  selectUploadProfilePictureRequest,
  selectUploadLessonVideoRequest,
  selectUploadLessonBoxsetPdfRequest,
  selectUploadCourseGraphicRequest,
  selectUploadCourseBackgroundRequest,
  selectUploadEventRecordingRequest,
  selectUploadAchievementLevelPictureRequest,
  selectUploadAchievementGraphicRequest,
  selectVerifyUserRequest,
};

export const selectAnyLoading = createSelector(
  selectPageSuspended,
  selectMeRequest,
  selectArchiveOfferRequest,
  selectCancelTrialsRequest,
  selectChangePasswordRequest,
  selectConfirmEmailChangeRequest,
  selectConfirmRegisterRequest,
  selectConfirmBoxsetShippingAddressRequest,
  selectCreateTopicRequest,
  selectCreateQuestionRequest,
  selectCreateResourceRequest,
  selectCreatePaymentMethodRequest,
  selectCreatePaymentLinkRequest,
  selectCreateEventRequest,
  selectCreateCourseRequest,
  selectCreateCourseBackupRequest,
  selectCreateOfferRequest,
  selectCreatePaymentRequest,
  selectCreateUserRequest,
  selectCreateAffiliateRedeemRequestRequest,
  selectCreateAchievementRequest,
  selectDeleteProfilePictureRequest,
  selectDeletePaymentMethodRequest,
  selectDeletePaymentLinkRequest,
  selectDeleteEventRequest,
  selectDeleteAchievementLevelPictureRequest,
  selectCreateWebhookRequest,
  selectDeleteUserRequest,
  selectRefundTransactionsRequest,
  selectRegisterRequest,
  selectResetPasswordRequest,
  selectRestoreOfferRequest,
  selectRevokeUserSuspensionRequest,
  selectSetDefaultPaymentMethodRequest,
  selectSigninRequest,
  selectSignoutRequest,
  selectSubmitToolProblemRequest,
  selectSubscribeToListRequest,
  selectSuspendUserRequest,
  selectUpdateLessonRequest,
  selectUpdateMeRequest,
  selectUpdateModuleRequest,
  selectUpdateOfferRequest,
  selectUpdateUserRequest,
  selectUpdateQuestionRequest,
  selectUpdatePaymentLinkRequest,
  selectUpdateResourceRequest,
  selectUpdateWebhookRequest,
  selectUpgradeCourseMentorshipRequest,
  selectUpgradeCourseTrialRequest,
  selectUpdateEventRequest,
  selectUpdateCourseRequest,
  selectUpdateAchievementLevelRequest,
  selectUpdateAchievementRequest,
  selectUploadProfilePictureRequest,
  selectUploadLessonVideoRequest,
  selectUploadLessonBoxsetPdfRequest,
  selectUploadCourseGraphicRequest,
  selectUploadCourseBackgroundRequest,
  selectUploadEventRecordingRequest,
  selectUploadAchievementLevelPictureRequest,
  selectUploadAchievementGraphicRequest,
  selectVerifyUserRequest,
  (pageSuspended, ...requests) =>
    pageSuspended || Boolean(requests.find((r) => r && r.isLoading)),
);
