import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'user-stats';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchUserStats = Exec.requestActionCreator(MODULE);
export const fetchUserStatsLoading = Exec.loadingActionCreator(MODULE);
export const fetchUserStatsSuccess = Exec.successActionCreator(MODULE);
export const fetchUserStatsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUserStatsRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectUserStats = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchUserStats = function* ({ payload, meta }) {
  try {
    const { data } = yield call(Api.getUserStats, payload.userId);
    yield put(fetchUserStatsSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchUserStatsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchUserStats);
