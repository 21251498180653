import { isEmpty, mapValues } from 'lodash';
import { formatISO, parseISO } from 'date-fns';

const parseValue = (value) => {
  if (!value) {
    return {};
  }

  try {
    return mapValues(JSON.parse(value), (v) => parseISO(v));
  } catch (err) {
    return {};
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SET_CONFIRM_BOXSET_SHIPPING_ADDRESS_POSTPONED_AT =
  'set-confirm-boxset-shipping-address-postponed-at';
const KEY = 'confirm-boxset-shipping-address-postponed-at';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const setConfirmBoxsetShippingAddressPostponedAt = (courseId, value) => {
  return {
    type: SET_CONFIRM_BOXSET_SHIPPING_ADDRESS_POSTPONED_AT,
    payload: {
      courseId,
      value,
    },
  };
};

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultState = parseValue(localStorage.getItem(KEY));

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CONFIRM_BOXSET_SHIPPING_ADDRESS_POSTPONED_AT:
      const { courseId, value } = action.payload;
      const map = parseValue(localStorage.getItem(KEY)) || {};

      if (value) {
        map[courseId] = formatISO(value);
      } else {
        delete map[courseId];
      }

      if (isEmpty(map)) {
        localStorage.removeItem(KEY);
      } else {
        localStorage.setItem(KEY, JSON.stringify(map));
      }

      return parseValue(localStorage.getItem(KEY));
    default:
      return state;
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectConfirmBoxsetShippingAddressPostponedAt = (store) => {
  return store.confirmBoxsetShippingAddressPostponedAt;
};
