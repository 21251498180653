import {
  useState,
  useCallback,
  cloneElement,
  Children,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Menu } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: 48,
    marginLeft: -8,
  },
  menuItem: {
    minWidth: 130,
    height: 46,
  },
}));

const DropdownMenu = forwardRef((props, ref) => {
  const { control, keepOpen, menuClassName, children, ...rest } = props;

  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleShowMenu = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    [],
  );
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useImperativeHandle(ref, () => ({
    close: handleCloseMenu,
  }));

  const controlWithEvents = cloneElement(control, {
    'aria-haspopup': 'true',
    onClick: handleShowMenu,
  });

  const moddedChildren = Children.map(
    children,
    (child) =>
      child &&
      cloneElement(child, {
        className: clsx(child.props.className, styles.menuItem),
        onClick: () => {
          if (!keepOpen) {
            handleCloseMenu();
          }
          child.props.onClick && child.props.onClick();
        },
      }),
  );

  return (
    <div {...rest}>
      {controlWithEvents}
      <Menu
        className={clsx(styles.menu, menuClassName)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {moddedChildren}
      </Menu>
    </div>
  );
});

DropdownMenu.propTypes = {
  control: PropTypes.node.isRequired,
  keepOpen: PropTypes.bool,
  menuClassName: PropTypes.string,
};

export default DropdownMenu;
