import PropTypes from 'prop-types';

import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import PageNotFound from 'Components/pages/error/page-not-found';
import ErrorDetailsWithGraphic from 'Components/pages/error/error-details-with-graphic';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(/error-bg.png)`,
    backgroundSize: 'cover',
  },
  root: {
    padding: theme.spacing(7.5, 4),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(10, 4),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(20, 9, 15),
    },
  },
}));

const ErrorPage = (props) => {
  const { error, notFound: notFound_ } = props;
  const styles = useStyles();

  const notFound =
    notFound_ || (error && error.code === 'ERROR_CODE_NOT_FOUND');

  return (
    <PageLayout className={styles.background} footer={false}>
      <PageContainer className={styles.root}>
        {notFound ? (
          <PageNotFound />
        ) : (
          <ErrorDetailsWithGraphic error={error} />
        )}
      </PageContainer>
    </PageLayout>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.any,
  notFound: PropTypes.bool,
};

export default ErrorPage;
