import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { fetchEventVideoSuccess } from './event-video';

const MODULE = 'update-event';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPDATE_EVENT_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPDATE_EVENT_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const updateEvent = Exec.requestActionCreator(MODULE);
export const updateEventLoading = Exec.loadingActionCreator(MODULE);
export const updateEventSuccess = Exec.successActionCreator(MODULE);
export const updateEventError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpdateEventRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpdateEvent = function* ({ payload, meta }) {
  const { eventId, ...attributes } = payload;

  try {
    yield put(closeSnackbar(UPDATE_EVENT_REQUEST));
    yield put(updateEventLoading());
    const { data } = yield call(Api.updateEvent, eventId, attributes);

    if ('recording' in attributes) {
      if (attributes.recording) {
        const { data: eventVideo } = yield call(
          Api.getEventVideo,
          eventId,
          true,
        );
        yield put(fetchEventVideoSuccess({ data: eventVideo }));
      } else {
        yield put(fetchEventVideoSuccess({ data: null }));
      }
    }

    yield put(updateEventSuccess({ data }, meta));
  } catch (err) {
    yield put(updateEventError(err, meta));
    yield put(
      enqueueSnackbar(UPDATE_EVENT_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpdateEvent);
