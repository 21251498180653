import { Navigate, useRoutes } from 'react-router-dom';

import VerifyEmailPage from 'Pages/verify-email';
import SigninPage from 'Pages/signin';
import RegisterPage from 'Pages/register';
import ReferralsPage from 'Pages/referrals';
import ProfilePage from 'Pages/profile';
import OnboardingPage from 'Pages/onboarding';
import WatchMentorshipCallPage from 'Pages/mentorship-calls/watch';
import MentorshipCallsViewAllPage from 'Pages/mentorship-calls/view-all';
import MentorshipCallsPage from 'Pages/mentorship-calls';
import LoadingPage from 'Pages/loading';
import WatchLessonPage from 'Pages/learn/watch';
import DashboardPage from 'Pages/learn/dashboard';
import HelpToolsPage from 'Pages/help/tools';
import HelpSearchPage from 'Pages/help/search';
import HelpResourceVaultPage from 'Pages/help/resource-vault';
import HelpMentorshipPage from 'Pages/help/mentorship';
import HelpFaqPage from 'Pages/help/faq';
import HelpCoursesPage from 'Pages/help/courses';
import HelpCoursePage from 'Pages/help/course';
import HelpCommunityPage from 'Pages/help/community';
import GetHelpPage from 'Pages/get-help';
import FacebookGroupsPage from 'Pages/facebook-groups';
import ErrorPage from 'Pages/error';
import ChangePasswordPage from 'Pages/change-password';
import AdminWebhooksPage from 'Pages/admin/webhooks';
import AdminUsersPage from 'Pages/admin/users';
import AdminUserStatsPage from 'Pages/admin/user-stats';
import AdminUserProfilePage from 'Pages/admin/user-profile';
import AdminUserFinancialsPage from 'Pages/admin/user-financials';
import AdminUpdateAchievementLevelPage from 'Pages/admin/update-achievement-level';
import AdminPaymentLinksPage from 'Pages/admin/payment-links';
import AdminOffersPage from 'Pages/admin/offers';
import AdminModuleOverviewPage from 'Pages/admin/module-overview';
import AdminMentorshipCallsPage from 'Pages/admin/mentorship-calls';
import AdminLessonOverviewPage from 'Pages/admin/lesson-overview';
import AdminCreateOrUpdateWebhookPage from 'Pages/admin/create-or-update-webhook';
import AdminCreateOrUpdateUserPage from 'Pages/admin/create-or-update-user';
import AdminCreateOrUpdateResourcePage from 'Pages/admin/create-or-update-resource';
import AdminCreateOrUpdateQuestionPage from 'Pages/admin/create-or-update-question';
import AdminCreateOrUpdatePaymentLinkPage from 'Pages/admin/create-or-update-payment-link';
import AdminCreateOrUpdateOfferPage from 'Pages/admin/create-or-update-offer';
import AdminCreateOrUpdateModulesPage from 'Pages/admin/create-or-update-module';
import AdminCreateOrUpdateMentorshipCallPage from 'Pages/admin/create-or-update-mentorship-call';
import AdminCreateOrUpdateLessonPage from 'Pages/admin/create-or-update-lesson';
import AdminCreateOrUpdateCoursePage from 'Pages/admin/create-or-update-course';
import AdminCreateOrUpdateAchievementPage from 'Pages/admin/create-or-update-achievement';
import AdminCoursesPage from 'Pages/admin/courses';
import AdminCourseOverviewPage from 'Pages/admin/course-overview';
import AdminConnectLessonResourcePage from 'Pages/admin/connect-lesson-resource';
import AdminConnectLessonQuestionPage from 'Pages/admin/connect-lesson-question';
import AdminAchievementLevelsPage from 'Pages/admin/achievement-levels';
import CourseAchievementsPage from 'Pages/achievements/course';
import AchievementsPage from 'Pages/achievements';
import SubscriptionsPage from 'Pages/account/subscriptions';
import PaymentPage from 'Pages/account/payment';
import MePage from 'Pages/account/me';
import AccountRecoveryPage from 'Pages/account-recovery';
import AccountPage from 'Pages/account';
import IndexPage from 'Pages';
import useConfirmShippingAddress from 'Hooks/use-confirm-shipping-address';

import { PageLoaderSuspense } from 'Components/shared/page-loader';
import ErrorBoundary from 'Components/shared/error-boundary';
import AuthStateGuard from 'Components/shared/auth-state-guard';

const helpRoutes = process.env.REACT_APP_DISABLE_SUPPORT
  ? []
  : [
      {
        path: 'get-help',
        element: (
          <AuthStateGuard allow="regular-user" element={<GetHelpPage />} />
        ),
      },
      {
        path: 'help/',
        children: [
          {
            path: '/',
            element: (
              <AuthStateGuard
                allow="regular-user"
                element={<Navigate replace to="/help/courses" />}
              />
            ),
          },
          {
            path: 'tools',
            element: (
              <AuthStateGuard
                allow="regular-user"
                element={<HelpToolsPage />}
              />
            ),
          },
          {
            path: 'community',
            element: (
              <AuthStateGuard
                allow="regular-user"
                element={<HelpCommunityPage />}
              />
            ),
          },
          {
            path: 'mentorship',
            element: (
              <AuthStateGuard
                allow="regular-user"
                element={<HelpMentorshipPage />}
              />
            ),
          },
          {
            path: 'search',
            element: (
              <AuthStateGuard
                allow="regular-user"
                element={<HelpSearchPage />}
              />
            ),
          },
          {
            path: 'courses',
            element: (
              <AuthStateGuard
                allow="regular-user"
                element={<HelpCoursesPage />}
              />
            ),
          },
          {
            path: 'courses/:courseSlug',
            element: (
              <AuthStateGuard
                allow="regular-user"
                element={<HelpCoursePage />}
              />
            ),
          },
          {
            path: 'faq/:courseSlug',
            element: (
              <AuthStateGuard allow="regular-user" element={<HelpFaqPage />} />
            ),
          },
          {
            path: 'resource-vault/:courseSlug',
            element: (
              <AuthStateGuard
                allow="regular-user"
                element={<HelpResourceVaultPage />}
              />
            ),
          },
        ],
      },
    ];

const onboardingRoutes = process.env.REACT_APP_DISABLE_ONBOARDING
  ? []
  : [
      {
        path: 'onboarding',
        element: (
          <AuthStateGuard allow="regular-user" element={<OnboardingPage />} />
        ),
      },
    ];

const routes = [
  {
    path: '/',
    element: <AuthStateGuard allow="regular-user" element={<IndexPage />} />,
  },
  {
    path: 'account-recovery',
    element: <AuthStateGuard allow="guest" element={<AccountRecoveryPage />} />,
  },
  {
    path: 'change-password',
    element: <AuthStateGuard allow="guest" element={<ChangePasswordPage />} />,
  },
  {
    path: 'register',
    element: <AuthStateGuard allow="guest" element={<RegisterPage />} />,
  },
  {
    path: 'signin',
    element: <AuthStateGuard allow="guest" element={<SigninPage />} />,
  },
  {
    path: 'verify-email',
    element: <VerifyEmailPage />,
  },
  {
    path: 'referrals',
    element: (
      <AuthStateGuard allow="regular-user" element={<ReferralsPage />} />
    ),
  },
  {
    path: 'profile',
    element: <AuthStateGuard allow="regular-user" element={<ProfilePage />} />,
  },
  {
    path: 'facebook-groups',
    element: (
      <AuthStateGuard allow="regular-user" element={<FacebookGroupsPage />} />
    ),
  },
  {
    path: 'account/',
    children: [
      {
        path: '/',
        element: (
          <AuthStateGuard allow="regular-user" element={<AccountPage />} />
        ),
      },
      {
        path: 'me',
        element: <AuthStateGuard allow="regular-user" element={<MePage />} />,
      },
      {
        path: 'payment',
        element: (
          <AuthStateGuard allow="regular-user" element={<PaymentPage />} />
        ),
      },
      {
        path: 'subscriptions',
        element: (
          <AuthStateGuard
            allow="regular-user"
            element={<SubscriptionsPage />}
          />
        ),
      },
    ],
  },
  {
    path: 'achievements/',
    children: [
      {
        path: '/',
        element: (
          <AuthStateGuard allow="regular-user" element={<AchievementsPage />} />
        ),
      },
      {
        path: ':courseSlug',
        element: (
          <AuthStateGuard
            allow="regular-user"
            element={<CourseAchievementsPage />}
          />
        ),
      },
    ],
  },
  {
    path: 'learn/:courseSlug/',
    children: [
      {
        path: '/',
        element: (
          <AuthStateGuard allow="regular-user" element={<DashboardPage />} />
        ),
      },
      {
        path: 'watch/:moduleSlug/:lessonSlug',
        element: (
          <AuthStateGuard allow="regular-user" element={<WatchLessonPage />} />
        ),
      },
    ],
  },
  {
    path: 'mentorship-calls/',
    children: [
      {
        path: '/',
        element: (
          <AuthStateGuard
            allow="regular-user"
            element={<MentorshipCallsPage />}
          />
        ),
      },
      {
        path: ':slug',
        element: (
          <AuthStateGuard
            allow="regular-user"
            element={<MentorshipCallsPage />}
          />
        ),
      },
      {
        path: ':slug/:year/:month',
        element: (
          <AuthStateGuard
            allow="regular-user"
            element={<MentorshipCallsViewAllPage />}
          />
        ),
      },
      {
        path: 'watch/:eventId',
        element: (
          <AuthStateGuard
            allow="regular-user"
            element={<WatchMentorshipCallPage />}
          />
        ),
      },
    ],
  },
  {
    path: 'admin/',
    children: [
      { path: '/', element: <Navigate replace to="/admin/courses" /> },
      {
        path: 'users',
        element: <AuthStateGuard allow="admin" element={<AdminUsersPage />} />,
      },
      {
        path: 'users/create',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateUserPage />}
          />
        ),
      },
      {
        path: 'users/:userId',
        element: (
          <AuthStateGuard allow="admin" element={<AdminUserProfilePage />} />
        ),
      },
      {
        path: 'users/:userId/stats',
        element: (
          <AuthStateGuard allow="admin" element={<AdminUserStatsPage />} />
        ),
      },
      {
        path: 'users/:userId/financials',
        element: (
          <AuthStateGuard allow="admin" element={<AdminUserFinancialsPage />} />
        ),
      },
      {
        path: 'users/:userId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateUserPage />}
          />
        ),
      },
      {
        path: 'courses',
        element: (
          <AuthStateGuard allow="admin" element={<AdminCoursesPage />} />
        ),
      },
      {
        path: 'courses/create',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateCoursePage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/overview',
        element: (
          <AuthStateGuard allow="admin" element={<AdminCourseOverviewPage />} />
        ),
      },
      {
        path: 'courses/:courseId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateCoursePage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/create',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateModulesPage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/overview',
        element: (
          <AuthStateGuard allow="admin" element={<AdminModuleOverviewPage />} />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateModulesPage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/lessons/create',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateLessonPage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/achievements/create',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateAchievementPage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/achievements/:achievementId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateAchievementPage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/lessons/:lessonId/overview',
        element: (
          <AuthStateGuard allow="admin" element={<AdminLessonOverviewPage />} />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/lessons/:lessonId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateLessonPage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/lessons/:lessonId/resources/connect',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminConnectLessonResourcePage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/lessons/:lessonId/questions/connect',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminConnectLessonQuestionPage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/lessons/:lessonId/resources/create',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateResourcePage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/lessons/:lessonId/resources/:resourceId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateResourcePage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/lessons/:lessonId/questions/create',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateQuestionPage />}
          />
        ),
      },
      {
        path: 'courses/:courseId/modules/:moduleId/lessons/:lessonId/questions/:questionId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateQuestionPage />}
          />
        ),
      },
      {
        path: 'payment-links',
        element: (
          <AuthStateGuard allow="admin" element={<AdminPaymentLinksPage />} />
        ),
      },
      {
        path: 'payment-links/create',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdatePaymentLinkPage />}
          />
        ),
      },
      {
        path: 'payment-links/:paymentLinkId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdatePaymentLinkPage />}
          />
        ),
      },
      {
        path: 'achievement-levels',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminAchievementLevelsPage />}
          />
        ),
      },
      {
        path: 'achievement-levels/:achievementLevelId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminUpdateAchievementLevelPage />}
          />
        ),
      },
      {
        path: 'mentorship-calls',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminMentorshipCallsPage />}
          />
        ),
      },
      {
        path: 'mentorship-calls/create',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateMentorshipCallPage />}
          />
        ),
      },
      {
        path: 'mentorship-calls/:eventId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateMentorshipCallPage />}
          />
        ),
      },
      {
        path: 'offers',
        element: <AuthStateGuard allow="admin" element={<AdminOffersPage />} />,
      },
      {
        path: 'offers/create',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateOfferPage />}
          />
        ),
      },
      {
        path: 'offers/:offerId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateOfferPage />}
          />
        ),
      },
      {
        path: 'webhooks',
        element: (
          <AuthStateGuard allow="admin" element={<AdminWebhooksPage />} />
        ),
      },
      {
        path: 'webhooks/create',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateWebhookPage />}
          />
        ),
      },
      {
        path: 'webhooks/:webhookId/edit',
        element: (
          <AuthStateGuard
            allow="admin"
            element={<AdminCreateOrUpdateWebhookPage />}
          />
        ),
      },
    ],
  },
  ...onboardingRoutes,
  ...helpRoutes,
  { path: '*', element: <ErrorPage notFound /> },
  { path: '/products', element: <Navigate replace to="/" /> }, // Previously, index page was redirecting to /products
];

const Router = () => {
  const element = useRoutes(routes);
  useConfirmShippingAddress();

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <PageLoaderSuspense fallback={<LoadingPage />}>
        {element}
      </PageLoaderSuspense>
    </ErrorBoundary>
  );
};
export default Router;
