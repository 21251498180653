import { useDropzone } from 'react-dropzone';
import { isBrowser } from 'react-device-detect';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  SystemUpdateAlt as SystemUpdateAltIcon,
  CloudUpload as CloudUploadIcon,
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  fade,
  FormHelperText,
  LinearProgress,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 4,
    width: '100%',
  },
  dropzone: {
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4.5, 2),
    minHeight: 180,
    outline: 'none',
    width: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
  },
  dropzonePointer: {
    cursor: 'pointer',
  },
  dropzoneHoverable: {
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.light, 0.1),
    },
  },
  dropzoneHighligth: {
    backgroundColor: fade(theme.palette.primary.light, 0.1) + '!important',
  },
  dropzoneProgress: {
    '& > *': {
      opacity: 0.5,
    },
  },
  completedIcon: (props) => ({
    width: 40,
    height: 40,
    marginBottom: theme.spacing(1.5),
    color: props.error
      ? theme.palette.error.light
      : theme.palette.success.light,
  }),
  completedTitle: (props) => ({
    marginBottom: theme.spacing(0.5),
    color: props.error ? theme.palette.error.main : theme.palette.success.main,
  }),
  completedText: {
    maxWidth: 320,
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  icon: {
    marginBottom: theme.spacing(1.5),
  },
}));

const UploadDropzone = (props) => {
  const {
    className,
    loading,
    progress,
    DropzoneProps,
    errorText,
    successText,
    helperText,
  } = props;
  const styles = useStyles({ error: Boolean(errorText) });

  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    DropzoneProps,
  );

  return (
    <div className={clsx(styles.root, className)}>
      {loading && (
        <LinearProgress
          aria-busy
          color="primary"
          className={styles.loader}
          value={progress || 0}
          variant={progress ? 'determinate' : 'indeterminate'}
        />
      )}
      {errorText || successText ? (
        <div className={styles.dropzone}>
          {errorText ? (
            <ErrorIcon className={styles.completedIcon} />
          ) : (
            <CheckCircleIcon className={styles.completedIcon} />
          )}
          <Typography
            variant="subtitle1"
            align="center"
            className={styles.completedTitle}
          >
            {errorText ? 'Upload Failed' : 'Success!'}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            color="textSecondary"
            className={styles.completedText}
          >
            {errorText || successText}
          </Typography>
        </div>
      ) : (
        <div
          {...getRootProps({
            className: clsx(styles.dropzone, {
              [styles.dropzonePointer]: !loading,
              [styles.dropzoneHighligth]: isDragActive,
              [styles.dropzoneProgress]: loading || isDragActive,
              [styles.dropzoneHoverable]: !loading,
            }),
          })}
        >
          <input data-testid="input" {...getInputProps()} disabled={loading} />
          {loading ? (
            <>
              <CloudUploadIcon className={styles.icon} />
              <Typography variant="body2" align="center">
                One Sec, Your File's Uploading...
              </Typography>
            </>
          ) : (
            <>
              <SystemUpdateAltIcon className={styles.icon} />
              <Typography variant="body2" align="center">
                <strong>Choose A File</strong>
                {isBrowser && ' Or Drag It Here'}
              </Typography>
            </>
          )}
        </div>
      )}
      <FormHelperText error>{helperText || ' '}</FormHelperText>
    </div>
  );
};

UploadDropzone.propTypes = {
  loading: PropTypes.bool,
  progress: PropTypes.number,
  errorText: PropTypes.string,
  successText: PropTypes.string,
  helperText: PropTypes.string,
  DropzoneProps: PropTypes.object.isRequired,
};

export default UploadDropzone;
