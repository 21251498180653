import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectMe, selectMeRequest } from 'Redux/selectors';
import UserIcon from 'Icons/user';
import PaymentIcon from 'Icons/payment';
import UserAvatar from 'Components/shared/user-avatar';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import DrawerAccountItems from 'Components/shared/drawer-account-items';
import SettingCard from 'Components/pages/account/setting-card';

import { Repeat as RepeatIcon } from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  drawer: {
    position: 'fixed',
    marginTop: 1, // divider offset
    width: 280,
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  avatar: {
    marginBottom: theme.spacing(3.5),
  },
  greeting: {
    marginBottom: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(5),
  },
  cards: {
    maxWidth: 840 + theme.spacing(3),
  },
}));

const AccountPage = () => {
  const styles = useStyles();
  const me = useSelector(selectMe);
  const { isLoading } = useSelector(selectMeRequest);
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <PageLoader loading={isLoading}>
      {() =>
        me && (
          <PageLayout
            headerItems={<HeaderItems />}
            drawerItems={[<DrawerAccountItems />, <DrawerItems />]}
          >
            <div className={styles.root}>
              {isLgUp && <DrawerAccountItems className={styles.drawer} />}

              <PageContainer sidebarWidth={280}>
                <UserAvatar size={88} user={me} className={styles.avatar} />
                <Typography
                  variant="h5"
                  align="center"
                  className={styles.greeting}
                >
                  Hey, {me.firstName}!
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  className={styles.text}
                >
                  Manage all of your info, payments and security in one place.
                </Typography>

                <Grid
                  className={styles.cards}
                  container
                  spacing={isMdUp ? 2 : 1}
                  justify="center"
                >
                  <Grid className={styles.item} item xs={12} sm={6}>
                    <SettingCard
                      title="My Account"
                      body="Add, remove, or edit any info about your account."
                      icon={<UserIcon />}
                      button={
                        <Button
                          size="large"
                          component={Link}
                          to="me"
                          color="primary"
                        >
                          Manage
                        </Button>
                      }
                    />
                  </Grid>

                  <Grid className={styles.item} item xs={12} sm={6}>
                    <SettingCard
                      title="Payment Info"
                      body="Track any purchases and manage your method of payment."
                      icon={<PaymentIcon />}
                      button={
                        <Button
                          size="large"
                          component={Link}
                          to="payment"
                          color="primary"
                        >
                          Manage
                        </Button>
                      }
                    />
                  </Grid>

                  <Grid className={styles.item} item xs={12} sm={6}>
                    <SettingCard
                      title="My Subscriptions"
                      body="Cancel free trial and view upcoming payments."
                      icon={<RepeatIcon />}
                      button={
                        <Button
                          size="large"
                          component={Link}
                          to="subscriptions"
                          color="primary"
                        >
                          More
                        </Button>
                      }
                    />
                  </Grid>
                </Grid>
              </PageContainer>
            </div>
          </PageLayout>
        )
      }
    </PageLoader>
  );
};

export default AccountPage;
