import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useCallback, useState } from 'react';
import { find, get, toLower } from 'lodash';
import clsx from 'clsx';

import states from 'Util/states';
import { ensureInternationalPhoneNumber } from 'Util/ensure-phone-number';
import countries from 'Util/countries';
import { selectMe, selectMeRequest } from 'Redux/selectors';
import UploadProfilePictureDialog from 'Dialogs/upload-profile-picture-dialog';
import UpdateMePasswordDialog from 'Dialogs/update-me-password-dialog';
import UpdateMeFieldDialog from 'Dialogs/update-me-field-dialog';
import ConfirmDeleteProfilePictureDialog from 'Dialogs/confirm-delete-profile-picture-dialog';
import UserAvatar from 'Components/shared/user-avatar';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import OptionListItem from 'Components/shared/option-list-item';
import OptionList from 'Components/shared/option-list';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import DrawerAccountItems from 'Components/shared/drawer-account-items';

import {
  Edit as EditIcon,
  Add as AddIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';

const options = countries.map((c) => ({ value: c.code, label: c.name }));
const statesCanadaOptions = states
  .filter((s) => s.countryCode === 'CA')
  .map((s) => ({ value: s.name, label: s.name }));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  drawer: {
    position: 'fixed',
    marginTop: 1, // divider offset
    width: 280,
  },
  title: {
    marginBottom: theme.spacing(7),
  },
  group: {
    marginBottom: theme.spacing(5),
  },
  textValue: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  avatarWithButton: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  deleteAvatarButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  iconOn: {
    color: theme.palette.success.main,
  },
}));

const MePage = () => {
  const styles = useStyles();
  const me = useSelector(selectMe);
  const { isLoading } = useSelector(selectMeRequest);
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [updateFieldDialog, setUpdateFiledDialog] = useState(null);

  const [showChangePasswordModal, hideChangePasswordModal] = useModal(() => (
    <UpdateMePasswordDialog onClose={hideChangePasswordModal} />
  ));
  const [showUploadProfilePictureModal, hideUploadProfilePictureModal] =
    useModal(() => (
      <UploadProfilePictureDialog onClose={hideUploadProfilePictureModal} />
    ));
  const [showDeleteProfilePictureModal, hideDeleteProfilePictureModal] =
    useModal(() => (
      <ConfirmDeleteProfilePictureDialog
        onClose={hideDeleteProfilePictureModal}
      />
    ));

  const handleClickAction = useCallback(
    (event) => {
      const name = event.currentTarget.getAttribute('data-name');
      const title = event.currentTarget.getAttribute('data-title');
      const message = event.currentTarget.getAttribute('data-message');
      const required_ = event.currentTarget.getAttribute('data-required');

      const required = Boolean(required_ === 'true');
      const canadianState = Boolean(
        statesCanadaOptions.find((o) => toLower(o.label) === toLower(me.state)),
      );

      const countryProps =
        name === 'country'
          ? {
              variant: 'select',
              options,
            }
          : {};
      const stateProps =
        name === 'state' && me.country === 'CA'
          ? {
              variant: 'select',
              options: [
                !canadianState
                  ? {
                      label: me.state + ' (current)',
                      value: me.state,
                      isPlaceholder: true,
                    }
                  : null,
                ...statesCanadaOptions,
              ].filter(Boolean),
            }
          : {};
      const phoneNumberProps =
        name === 'phoneNumber'
          ? {
              format: 'phoneNumber',
              locale: me.country,
            }
          : {};
      const booleanProps = name.startsWith('enabled')
        ? {
            variant: 'switch',
          }
        : {};

      setUpdateFiledDialog({
        name,
        title,
        message,
        required,
        validationSchema:
          name === 'email'
            ? yup.object().shape({
                email: yup
                  .string()
                  .required('Field Required.')
                  .email('Invalid Email.')
                  .notOneOf([me.email], 'Please use new email.'),
              })
            : required
            ? yup.object().shape({
                [name]: yup.string().required('Field Required.'),
              })
            : null,
        ...countryProps,
        ...stateProps,
        ...phoneNumberProps,
        ...booleanProps,
      });
    },
    [me],
  );

  const handleCloseUpdateFieldDialog = useCallback(
    () => setUpdateFiledDialog(null),
    [],
  );

  const textFields = [
    {
      name: 'firstName',
      title: 'First Name',
      required: true,
    },
    {
      name: 'lastName',
      title: 'Last Name',
      required: true,
    },
    {
      name: 'phoneNumber',
      title: 'Phone Number',
      required: true,
      resolveValue(value) {
        return ensureInternationalPhoneNumber(value, me.country);
      },
    },
    {
      name: 'country',
      title: 'Country',
      required: true,
      resolveValue(value) {
        return get(find(countries, ['code', value]), 'name', 'Unknown');
      },
    },
    {
      name: 'addressLine',
      title: 'Address',
    },
    {
      name: 'city',
      title: 'City',
    },
    {
      name: 'state',
      title: 'State',
    },
    {
      name: 'zipCode',
      title: 'Zip Code',
    },
  ];

  const notificationFields = [
    {
      name: 'enabledAccountEmails',
      title: 'Account Emails',
      message:
        'Any emails pertaining to your account with Freedom.co that need your attention.',
    },
    {
      name: 'enabledMarketingEmails',
      title: 'Marketing Emails',
      message:
        'Emails to keep you up to date with our latest news, promotions, and offers.',
    },
  ];

  return (
    <PageLoader loading={isLoading}>
      {() =>
        me && (
          <PageLayout
            headerItems={<HeaderItems />}
            drawerItems={[<DrawerAccountItems />, <DrawerItems />]}
          >
            <div className={styles.root}>
              {isLgUp && <DrawerAccountItems className={styles.drawer} />}

              <PageContainer sidebarWidth={280}>
                {updateFieldDialog && (
                  <UpdateMeFieldDialog
                    {...updateFieldDialog}
                    onClose={handleCloseUpdateFieldDialog}
                  />
                )}
                <Typography variant="h4" className={styles.title}>
                  My Account
                </Typography>
                <OptionList className={styles.group} title="Personal Info">
                  <OptionListItem
                    data-testid="image"
                    name="image"
                    title="Profile Picture"
                    value={
                      me.image ? (
                        <div className={styles.avatarWithButton}>
                          <UserAvatar user={me} size={60} />
                          {isSmUp ? (
                            <Button
                              className={styles.deleteAvatarButton}
                              startIcon={<DeleteOutlineIcon />}
                              onClick={showDeleteProfilePictureModal}
                            >
                              Delete
                            </Button>
                          ) : (
                            <IconButton onClick={showDeleteProfilePictureModal}>
                              <DeleteOutlineIcon fontSize="small" />
                            </IconButton>
                          )}
                        </div>
                      ) : (
                        <Typography
                          variant="body2"
                          className={styles.textValue}
                          color="textSecondary"
                        >
                          Add an image.
                        </Typography>
                      )
                    }
                    actionIcon={
                      me.image ? (
                        <EditIcon fontSize="small" />
                      ) : (
                        <AddIcon fontSize="small" />
                      )
                    }
                    onActionClick={showUploadProfilePictureModal}
                    divider
                  />
                  {textFields.map(
                    (
                      { name, title, resolveValue, placeholder, ...props },
                      index,
                    ) => {
                      const value = me[name];
                      return (
                        <OptionListItem
                          {...props}
                          data-testid={name}
                          key={name}
                          name={name}
                          title={title}
                          value={
                            value ? (
                              <Typography
                                variant="body2"
                                className={styles.textValue}
                              >
                                {resolveValue ? resolveValue(value) : value}
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                className={styles.textValue}
                                color="textSecondary"
                              >
                                Add {title}.
                              </Typography>
                            )
                          }
                          actionIcon={
                            value ? (
                              <EditIcon fontSize="small" />
                            ) : (
                              <AddIcon fontSize="small" />
                            )
                          }
                          onActionClick={handleClickAction}
                          divider={textFields.length - 1 !== index}
                        />
                      );
                    },
                  )}
                </OptionList>
                <OptionList className={styles.group} title="Security">
                  <OptionListItem
                    data-testid="email"
                    name="email"
                    title="Email Address"
                    onActionClick={handleClickAction}
                    value={
                      <Typography variant="body2" className={styles.textValue}>
                        {me.email}
                      </Typography>
                    }
                    actionIcon={<EditIcon fontSize="small" />}
                    required
                    divider
                  />
                  <OptionListItem
                    data-testid="password"
                    name="password"
                    title="Password"
                    onActionClick={showChangePasswordModal}
                    value={
                      <Typography variant="body2" className={styles.textValue}>
                        ●●●●●●●●
                      </Typography>
                    }
                    actionIcon={<EditIcon fontSize="small" />}
                  />
                </OptionList>
                <OptionList className={styles.group} title="Notifications">
                  {notificationFields.map(
                    ({ name, title, ...props }, index) => {
                      const value = me[name];
                      const Icon = value ? CheckCircleIcon : CancelIcon;
                      return (
                        <OptionListItem
                          {...props}
                          data-testid={name}
                          name={name}
                          key={name}
                          title={title}
                          value={
                            <div className={styles.switchContainer}>
                              <Icon
                                fontSize="small"
                                className={clsx(
                                  styles.icon,
                                  value && styles.iconOn,
                                )}
                              />
                              <Typography
                                variant="body2"
                                className={styles.textValue}
                              >
                                {value ? 'On' : 'Off'}
                              </Typography>
                            </div>
                          }
                          actionIcon={<EditIcon fontSize="small" />}
                          onActionClick={handleClickAction}
                          divider={notificationFields.length - 1 !== index}
                        />
                      );
                    },
                  )}
                </OptionList>
              </PageContainer>
            </div>
          </PageLayout>
        )
      }
    </PageLoader>
  );
};

export default MePage;
