import { sagas as webhooks } from './modules/webhooks';
import { sagas as verifyUser } from './modules/verify-user';
import { sagas as users } from './modules/users';
import { sagas as userStats } from './modules/user-stats';
import { sagas as user } from './modules/user';
import { sagas as uploadProfilePicture } from './modules/upload-profile-picture';
import { sagas as uploadLessonVideo } from './modules/upload-lesson-video';
import { sagas as uploadLessonBoxsetPdf } from './modules/upload-lesson-boxset-pdf';
import { sagas as uploadFile } from './modules/upload-file';
import { sagas as uploadEventRecording } from './modules/upload-event-recording';
import { sagas as uploadCourseGraphic } from './modules/upload-course-graphic';
import { sagas as uploadCourseBackground } from './modules/upload-course-background';
import { sagas as uploadAchievementLevelPicture } from './modules/upload-achievement-level-picture';
import { sagas as uploadAchievementGraphic } from './modules/upload-achievement-graphic';
import { sagas as upgradeCourseTrial } from './modules/upgrade-course-trial';
import { sagas as upgradeCourseMentorship } from './modules/upgrade-course-mentorship';
import { sagas as updateWebhook } from './modules/update-webhook';
import { sagas as updateUser } from './modules/update-user';
import { sagas as updateResource } from './modules/update-resource';
import { sagas as updateQuestion } from './modules/update-question';
import { sagas as updatePaymentLink } from './modules/update-payment-link';
import { sagas as updateOffer } from './modules/update-offer';
import { sagas as updateModule } from './modules/update-module';
import { sagas as updateMe } from './modules/update-me';
import { sagas as updateLesson } from './modules/update-lesson';
import { sagas as updateEvent } from './modules/update-event';
import { sagas as updateCourse } from './modules/update-course';
import { sagas as updateAchievementLevel } from './modules/update-achievement-level';
import { sagas as updateAchievement } from './modules/update-achievement';
import { sagas as trials } from './modules/trials';
import { sagas as transactions } from './modules/transactions';
import { sagas as topics } from './modules/topics';
import { sagas as suspendUser } from './modules/suspend-user';
import { sagas as subscriptionSteps } from './modules/subscription-steps';
import { sagas as subscribeToList } from './modules/subscribe-to-list';
import { sagas as submitToolProblem } from './modules/submit-tool-problem';
import { sagas as submitAchievement } from './modules/submit-achievement';
import { sagas as sortModules } from './modules/sort-modules';
import { sagas as sortLessons } from './modules/sort-lessons';
import { sagas as sortLessonQuestions } from './modules/sort-lesson-resources';
import { sagas as sortLessonResources } from './modules/sort-lesson-questions';
import { sagas as sortCourses } from './modules/sort-courses';
import { sagas as sortAchievements } from './modules/sort-achievements';
import { sagas as signout } from './modules/signout';
import { sagas as signin } from './modules/signin';
import { sagas as setDefaultPaymentMethod } from './modules/set-default-payment-method';
import { sagas as search } from './modules/search';
import { sagas as roles } from './modules/roles';
import { sagas as revokeUserSuspension } from './modules/revoke-user-suspension';
import { sagas as restoreOffer } from './modules/restore-offer';
import { sagas as resources } from './modules/resources';
import { sagas as resetPassword } from './modules/reset-password';
import { sagas as register } from './modules/register';
import { sagas as refundTransactions } from './modules/refund-transactions';
import { sagas as recordLessonProgress } from './modules/record-lesson-progress';
import { sagas as questions } from './modules/questions';
import { sagas as paymentMethods } from './modules/payment-methods';
import { sagas as paymentLinks } from './modules/payment-links';
import { sagas as paymentLink } from './modules/payment-link';
import { sagas as offers } from './modules/offers';
import { sagas as modules } from './modules/modules';
import { sagas as me } from './modules/me';
import { sagas as lessons } from './modules/lessons';
import { sagas as lessonVideo } from './modules/lesson-video';
import { sagas as lastLesson } from './modules/last-lesson';
import { sagas as events } from './modules/events';
import { sagas as eventVideo } from './modules/event-video';
import { sagas as eventAttendees } from './modules/event-attendees';
import { sagas as event } from './modules/event';
import { sagas as deleteUser } from './modules/delete-user';
import { sagas as deleteProfilePicture } from './modules/delete-profile-picture';
import { sagas as deletePaymentMethod } from './modules/delete-payment-method';
import { sagas as deletePaymentLink } from './modules/delete-payment-link';
import { sagas as deleteEvent } from './modules/delete-event';
import { sagas as deleteAchievementLevelPicture } from './modules/delete-achievement-level-picture';
import { sagas as createWebhook } from './modules/create-webhook';
import { sagas as createUser } from './modules/create-user';
import { sagas as createTopic } from './modules/create-topic';
import { sagas as createResource } from './modules/create-resource';
import { sagas as createQuestion } from './modules/create-question';
import { sagas as createPaymentMethod } from './modules/create-payment-method';
import { sagas as createPaymentLink } from './modules/create-payment-link';
import { sagas as createPayment } from './modules/create-payment';
import { sagas as createOffer } from './modules/create-offer';
import { sagas as createModule } from './modules/create-module';
import { sagas as createLesson } from './modules/create-lesson';
import { sagas as createEvent } from './modules/create-event';
import { sagas as createCourseBackup } from './modules/create-course-backup';
import { sagas as createCourse } from './modules/create-course';
import { sagas as createAffiliateRedeemRequest } from './modules/create-affiliate-redeem-request';
import { sagas as createAchievement } from './modules/create-achievement';
import { sagas as courses } from './modules/courses';
import { sagas as confirmRegister } from './modules/confirm-register';
import { sagas as confirmEmailChange } from './modules/confirm-email-change';
import { sagas as confirmBoxsetShippingAddress } from './modules/confirm-boxset-shipping-address';
import { sagas as changePassword } from './modules/change-password';
import { sagas as cancelTrials } from './modules/cancel-trials';
import { sagas as archiveOffer } from './modules/archive-offer';
import { sagas as affiliateStats } from './modules/affiliate-stats';
import { sagas as affiliateRedeemRequests } from './modules/affiliate-redeem-requests';
import { sagas as affiliateEarnings } from './modules/affiliate-earnings';
import { sagas as activeCampaignLists } from './modules/active-campaign-lists';
import { sagas as achievements } from './modules/achievements';
import { sagas as achievementStats } from './modules/achievement-stats';
import { sagas as achievementLevels } from './modules/achievement-levels';

const sagas = [
  achievementStats,
  achievementLevels,
  achievements,
  activeCampaignLists,
  affiliateEarnings,
  affiliateRedeemRequests,
  affiliateStats,
  archiveOffer,
  courses,
  confirmBoxsetShippingAddress,
  confirmEmailChange,
  changePassword,
  cancelTrials,
  confirmRegister,
  createAchievement,
  createAffiliateRedeemRequest,
  createTopic,
  createQuestion,
  createResource,
  createPaymentMethod,
  createPaymentLink,
  createPayment,
  createOffer,
  createCourse,
  createCourseBackup,
  createModule,
  createLesson,
  createEvent,
  createUser,
  createWebhook,
  deleteProfilePicture,
  deletePaymentMethod,
  deletePaymentLink,
  deleteEvent,
  deleteAchievementLevelPicture,
  deleteUser,
  eventAttendees,
  eventVideo,
  event,
  events,
  lessons,
  lessonVideo,
  lastLesson,
  me,
  modules,
  paymentMethods,
  questions,
  offers,
  paymentLinks,
  paymentLink,
  recordLessonProgress,
  refundTransactions,
  register,
  resetPassword,
  resources,
  restoreOffer,
  revokeUserSuspension,
  roles,
  search,
  signin,
  setDefaultPaymentMethod,
  signout,
  submitAchievement,
  sortAchievements,
  sortCourses,
  sortLessons,
  sortLessonQuestions,
  sortLessonResources,
  sortModules,
  submitToolProblem,
  subscribeToList,
  subscriptionSteps,
  suspendUser,
  trials,
  transactions,
  topics,
  updateAchievement,
  updateAchievementLevel,
  updateCourse,
  updateEvent,
  updateLesson,
  updateMe,
  updateModule,
  updateUser,
  updateWebhook,
  updateOffer,
  updatePaymentLink,
  updateQuestion,
  updateResource,
  upgradeCourseTrial,
  upgradeCourseMentorship,
  uploadAchievementGraphic,
  uploadAchievementLevelPicture,
  uploadFile,
  uploadEventRecording,
  uploadCourseGraphic,
  uploadCourseBackground,
  uploadLessonVideo,
  uploadLessonBoxsetPdf,
  uploadProfilePicture,
  user,
  userStats,
  users,
  verifyUser,
  webhooks,
];

export default sagas;
