import instance from 'Api/instance';

export const getCourse = (courseIdOrSlug) =>
  instance.get('/courses/' + courseIdOrSlug);

export const getCourses = () => instance.get('/courses');

export const upgradeCourseTrial = (courseIdOrSlug) =>
  instance.post('/courses/' + courseIdOrSlug + '/upgrade');

export const upgradeCourseMentorship = (courseIdOrSlug) =>
  instance.post('/courses/' + courseIdOrSlug + '/upgrade-mentorship');

export const updateCourse = (courseId, attributes) =>
  instance.patch('/courses/' + courseId, { data: { attributes } });

export const createCourse = (attributes) =>
  instance.post('/courses', { data: { attributes } });

export const sortCourses = (attributes) =>
  instance.post('/courses/sort', { data: { attributes } });

export const createCourseBackup = (courseId) =>
  instance.post('/courses/' + courseId + '/create-backup');
