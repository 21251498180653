import { put, call, takeLeading } from 'redux-saga/effects';
import { get } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPDATE_QUESTION_SUCCESS } from './update-question';
import { CREATE_QUESTION_SUCCESS } from './create-question';

const MODULE = 'questions';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
const defaultFetchQuestions = Exec.requestActionCreator(MODULE);
export const fetchQuestions = (payload, options) => {
  const offset = get(payload, 'offset', 0);
  return defaultFetchQuestions(payload, {
    isAppending: offset > 0,
    ...options,
  });
};
export const fetchQuestionsLoading = Exec.loadingActionCreator(MODULE);
export const fetchQuestionsSuccess = Exec.successActionCreator(MODULE);
export const fetchQuestionsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);
export const reducer = (state, action) => {
  switch (action.type) {
    case CREATE_QUESTION_SUCCESS:
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      };
    case UPDATE_QUESTION_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((q) => (q.id === data.id ? data : q)),
      };
    }
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectQuestionsRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectQuestions = Exec.dataSelectorCreator(MODULE);

export const selectQuestionsTotalCount = (store) =>
  store.questions.pageInfo ? store.questions.pageInfo.totalCount : 0;

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchQuestions = function* ({ payload, meta }) {
  try {
    const { data, __meta = {} } = yield call(Api.getQuestions, payload);

    yield put(fetchQuestionsSuccess({ data, pageInfo: __meta.pageInfo }, meta));
  } catch (err) {
    yield put(fetchQuestionsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchQuestions, takeLeading);
