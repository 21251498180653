import clsx from 'clsx';

import { makeStyles, Tabs } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[2],
    padding: theme.spacing(0, 1.5),
    borderRadius: 16,
  },
}));

const NavTabs = (props) => {
  const { className, ...rest } = props;
  const styles = useStyles();

  return (
    <Tabs
      className={clsx(className, styles.root)}
      textColor="primary"
      indicatorColor="primary"
      {...rest}
    />
  );
};

export default NavTabs;
