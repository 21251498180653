const BugGraphic = (props) => {
  return (
    <svg data-name="Layer 1" viewBox="0 0 556.91 372.56" {...props}>
      <defs>
        <linearGradient
          id="bug_fixing_svg__i"
          x1={413.9}
          y1={212.71}
          x2={380.98}
          y2={205.44}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#010101" stopOpacity={0} />
          <stop offset={0.55} stopColor="#010101" stopOpacity={0.26} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="bug_fixing_svg__a"
          x1={289.35}
          y1={362.02}
          x2={290.8}
          y2={235.9}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#010101" stopOpacity={0} />
          <stop offset={0.95} stopColor="#010101" />
        </linearGradient>
        <linearGradient
          id="bug_fixing_svg__c"
          x1={290.5}
          y1={281.91}
          x2={290.63}
          y2={326.89}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__d"
          x1={377.03}
          y1={142.55}
          x2={385.74}
          y2={162.36}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__e"
          x1={365.5}
          y1={153.2}
          x2={360.26}
          y2={157.87}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__f"
          x1={393.05}
          y1={154.73}
          x2={366.59}
          y2={177.13}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__g"
          x1={326.66}
          y1={108.3}
          x2={423.88}
          y2={242.67}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={0.13} stopColor="#fff" stopOpacity={0.23} />
          <stop offset={0.23} stopColor="#fff" stopOpacity={0.41} />
          <stop offset={0.35} stopColor="#fff" stopOpacity={0.59} />
          <stop offset={0.48} stopColor="#fff" stopOpacity={0.74} />
          <stop offset={0.6} stopColor="#fff" stopOpacity={0.85} />
          <stop offset={0.72} stopColor="#fff" stopOpacity={0.93} />
          <stop offset={0.84} stopColor="#fff" stopOpacity={0.98} />
          <stop offset={0.95} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="bug_fixing_svg__h"
          x1={420.73}
          y1={210.31}
          x2={399.73}
          y2={206.62}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__b"
          x1={293.17}
          y1={0.57}
          x2={294.84}
          y2={618.77}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__j"
          x1={395.97}
          y1={103.3}
          x2={394.24}
          y2={123.52}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__k"
          x1={398.06}
          y1={120}
          x2={393.87}
          y2={119.82}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__l"
          x1={380.79}
          y1={187.64}
          x2={436.85}
          y2={125.12}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__m"
          x1={393.7}
          y1={225.65}
          x2={398.03}
          y2={260.72}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__n"
          x1={412.88}
          y1={223.21}
          x2={417.21}
          y2={258.32}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__o"
          x1={358.64}
          y1={82.15}
          x2={319.33}
          y2={161.78}
          xlinkHref="#bug_fixing_svg__a"
        />
        <linearGradient
          id="bug_fixing_svg__p"
          x1={347.18}
          y1={76.61}
          x2={308.05}
          y2={155.85}
          xlinkHref="#bug_fixing_svg__a"
        />
      </defs>
      <path
        d="M234.34 3.97c-45.22-9.91-97.65-2.18-128.21 33.37-13.12 15.2-21.18 34.22-28.53 53.08-9.53 24.35-18.49 49.38-33.82 70.41-14 19.27-33.47 35.39-40.73 58.24-10.69 33.67 8.72 69.4 31 96.48 12 14.53 25.39 28.44 42.2 36.5 14.3 6.84 30.24 9.07 45.94 10.8 90.16 9.95 181.17 6.14 271.78 2.33 25.49-1.07 51.09-2.16 76.15-7 19.76-3.84 39.45-10.21 55.76-22.25s28.92-30.42 30.8-50.9c1.68-18.36-5.22-36.49-14.3-52.41-7.28-12.74-16.23-25.28-17.78-39.88-1.41-13.19 3.42-26.13 5.75-39.18 7.16-40.06-11.58-83.67-45.28-105.35-22.69-14.59-49.95-19.29-76.41-23.68l-62.73-10.41c-18.59-3.09-34-.87-52.36.23-20.19 1.2-39.75-6.11-59.23-10.38z"
        fill="#68e1fd"
        opacity={0.24}
        style={{
          isolation: 'isolate',
        }}
      />
      <path
        fill="#68e1fd"
        d="M350.33 354.46H228.29l21.29-43.14h84.6l16.15 43.14z"
      />
      <path
        fill="url(#bug_fixing_svg__a)"
        d="M347.87 348.18H231.15l18.43-36.86h84.6l13.69 36.86z"
      />
      <path
        fill="#68e1fd"
        d="M218.31 351.35h143.3v10.45h-143.3zM469.5 61.89V308.6a11.38 11.38 0 01-11.28 11.49h-330a11.4 11.4 0 01-11.3-11.49V61.89a11.46 11.46 0 0111.3-11.55h330a11.44 11.44 0 0111.28 11.55z"
      />
      <path
        d="M469.91 65.55v206.31H117.33V65.55A11.43 11.43 0 01128.62 54h330a11.43 11.43 0 0111.29 11.55z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__b)"
      />
      <path fill="#fff" d="M129.87 64.88h328.17v194.36H129.87z" />
      <path
        d="M368.01 130.34c-3.21 15.59-16.74 27.3-32.94 27.3-18.64 0-33.73-15.43-33.73-34.46a34.83 34.83 0 012.65-13.44 35.23 35.23 0 00-.73 7.17c0 19 15.09 34.46 33.7 34.46a33.68 33.68 0 0031.05-21.03z"
        fill="#231f20"
        opacity={0.2}
      />
      <ellipse
        cx={290.54}
        cy={293.73}
        rx={7.78}
        ry={7.96}
        fill="url(#bug_fixing_svg__c)"
      />
      <path
        d="M396.39 141.4l-6.13 1.54 1.2 18.51h-31l-1.29-20.05-6.11 1.54 11.31 177.15h6.23l-1.05-16.17h31l1.05 16.17h6.19zm-35.59 25.94h31l1.38 21.43h-31zm1.74 27.3h31l1.38 21.46h-31zm1.75 27.33h31l1.38 21.47h-31zm3.18 49.6l-1.44-22.23h31l1.39 21.43H367.6zm1.69 26.5l-1.39-21.46h31l1.38 21.46z"
        fill="#231f20"
        opacity={0.2}
      />
      <path
        d="M406.7 246.56a30.87 30.87 0 00-7.25-21.79c-1 3.62-.19 7.45-.16 11.2s-1 8-4.26 9.77c-3.53-2.58-4.43-7.46-5.06-11.84q-3.44-24.21-6.89-48.42c-2.31 1.12-5.16.17-7.05-1.6s-3-4.2-4.07-6.57c-4.81-10.6-9.7-21.57-10.07-33.24 1.81 2.48 3.68 5 6.23 6.68s6 2.3 8.62.77a12.1 12.1 0 003.89-4.4c1.81-2.95 3.54-6.26 3.12-9.72a22.61 22.61 0 00-1.6-5.07c-2.31-6.16-2.66-12.88-3-19.47a5.28 5.28 0 01.36-2.74 4.07 4.07 0 012.42-1.74 11.13 11.13 0 0113.48 6.68c1.12 3.13.81 6.57 1 9.89.68 9.88 6.15 18.75 8.07 28.46 1.51 7.62.78 15.49.05 23.23l-4.28 45.52"
        fill="#231f20"
        opacity={0.07}
      />
      <path
        d="M353.6 142.97l58.5 227.29a3 3 0 003.67 2.2 3.09 3.09 0 002.15-3.76l-9-34.82h31l9.36 36.38a3 3 0 003.67 2.2 3.09 3.09 0 002.15-3.76l-58.49-227.3-5.81 1.57 4.82 18.75h-31l-5.23-20.32zm41.1 135.51h31l5.6 21.75h-31zm-7.1-27.71h31l5.59 21.75h-30.81v.82zm-7.12-27.7h31l5.6 21.75h-31zm-7.16-27.73h31l5.6 21.75h-31zm-7.13-27.7h31l5.6 21.75h-31zm72.26 160.26h-31l-5.6-21.75h31z"
        fill="#231f20"
      />
      <path
        d="M386.03 145.26a10.11 10.11 0 01-4.47 4.66l-8.25 5.2a2.34 2.34 0 00-.87.81 2.26 2.26 0 00.26 1.86 12.19 12.19 0 001.76 3.06 3.91 3.91 0 003.05 1.48 5.91 5.91 0 002.82-1.24l9.39-6.36a4.81 4.81 0 001.68-1.58 4.46 4.46 0 00.42-1.84 18.65 18.65 0 00-1.26-8.59c-1.82-4-3.23.28-4.53 2.54z"
        fill="#68e1fd"
      />
      <path
        d="M386.43 148.92a10.11 10.11 0 01-4.47 4.66l-8.25 5.2a2.34 2.34 0 00-.87.81 2.26 2.26 0 00.26 1.86 12.19 12.19 0 001.76 3.06 3.91 3.91 0 003.05 1.48 5.91 5.91 0 002.82-1.24l9.39-6.36a4.81 4.81 0 001.68-1.58 4.46 4.46 0 00.42-1.84 18.65 18.65 0 00-1.26-8.59c-1.82-4-3.23.28-4.53 2.54z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__d)"
      />
      <path
        d="M369.4 159.72a2.86 2.86 0 01-4.08-.48l-9.23-11.9a3 3 0 01.48-4.17 2.85 2.85 0 014.07.49l9.23 11.93a3 3 0 01-.47 4.13z"
        fill="#68e1fd"
      />
      <path
        d="M369.8 163.38a2.86 2.86 0 01-4.08-.48l-9.23-11.9a3 3 0 01.48-4.17 2.85 2.85 0 014.07.49l9.23 11.93a3 3 0 01-.47 4.13z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__e)"
      />
      <path
        d="M386.51 181.34a5.21 5.21 0 01-7.43-.88l-16.84-21.79a5.5 5.5 0 01.87-7.6 5.22 5.22 0 017.43.89l16.84 21.78a5.48 5.48 0 01-.87 7.6z"
        fill="#68e1fd"
      />
      <path
        d="M386.91 185a5.21 5.21 0 01-7.43-.88l-16.84-21.79a5.5 5.5 0 01.87-7.6 5.22 5.22 0 017.43.89l16.84 21.78a5.48 5.48 0 01-.87 7.6z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__f)"
      />
      <path
        d="M336.38 82.55c-19.44 0-35.28 16.18-35.28 36.07s15.84 36.08 35.28 36.08 35.27-16.18 35.27-36.08-15.82-36.07-35.27-36.07zm.38 67.86c-17.12 0-31-14.24-31-31.74s13.92-31.75 31-31.75 31 14.25 31 31.75-13.89 31.74-31 31.74z"
        fill="#68e1fd"
      />
      <path
        d="M336.78 86.21c-19.44 0-35.28 16.18-35.28 36.07s15.84 36.08 35.28 36.08 35.27-16.18 35.27-36.08-15.82-36.07-35.27-36.07zm.38 67.86c-17.12 0-31-14.24-31-31.74s13.92-31.75 31-31.75 31 14.25 31 31.75-13.89 31.74-31 31.74z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__g)"
      />
      <path
        d="M411.68 205.65c-1.25 7.8.56 15.87 3.86 23a12.72 12.72 0 011.41 4.1 3.79 3.79 0 01-1.66 3.75c-1.51.81-3.37 0-4.74-1.08-4-3-6.43-7.72-8.33-12.41a87.81 87.81 0 01-6.08-24.8 30.48 30.48 0 01.62-11.39 25.46 25.46 0 014.59-8.37 14 14 0 014.06-3.78 8.29 8.29 0 018.2.44c3 1.85 3.37 4 3.35 7.32-.04 8.17-4.02 15.31-5.28 23.22z"
        fill="#606161"
      />
      <path
        d="M411.94 209.1c-1.26 7.8.56 15.87 3.86 23a12.59 12.59 0 011.41 4.09 3.79 3.79 0 01-1.66 3.75c-1.51.81-3.37 0-4.74-1.08-4-3-6.43-7.72-8.34-12.41a88.07 88.07 0 01-6.07-24.79 30.26 30.26 0 01.62-11.39 25.41 25.41 0 014.59-8.38 14.18 14.18 0 014.06-3.78 8.32 8.32 0 018.2.44c3 1.86 3.37 4 3.35 7.33-.04 8.12-4.02 15.31-5.28 23.22z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__h)"
      />
      <path
        d="M402.44 204.07a28.88 28.88 0 00-3.16 5.87 21.89 21.89 0 00-.78 6.91 113.75 113.75 0 00.68 11.82c.25 2.22.55 4.58-.29 6.6s-3.34 3.37-4.93 2a5.47 5.47 0 01-1.24-2 76.44 76.44 0 01-5.56-41.17c.67-4.63 1.8-9.29 4.19-13.14s6.23-6.83 10.44-7c2.26-.09 6.08.78 7.56 2.93s.47 5.89 0 8.47a53.35 53.35 0 01-6.91 18.71z"
        fill="#606161"
      />
      <path
        d="M402.84 207.73a28.88 28.88 0 00-3.16 5.87 21.89 21.89 0 00-.78 6.91 113.75 113.75 0 00.68 11.82c.25 2.22.55 4.58-.29 6.6s-3.34 3.37-4.93 2a5.47 5.47 0 01-1.24-2 76.44 76.44 0 01-5.56-41.17c.67-4.63 1.8-9.29 4.19-13.14s6.23-6.83 10.44-7c2.26-.09 6.08.78 7.56 2.93s.47 5.89 0 8.47a53.35 53.35 0 01-6.91 18.71z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__i)"
      />
      <path
        d="M366.14 157.78c-.42.69.62 1.75 0 2.33-.35.36-.95.1-1.44.2a1.37 1.37 0 00-.92 1.5 4.18 4.18 0 00.76 1.76l1.1 1.72a3.35 3.35 0 00.54.7 2.58 2.58 0 00.79.48 4.11 4.11 0 003.67-.39 2.06 2.06 0 00.6-.54 2 2 0 00.33-.7 5.59 5.59 0 00-1.26-4.63c-.81-.93-2.87-2.53-4.17-2.43zM385.69 108.54a22.56 22.56 0 00-.15 7.65l.47 4.88a6.86 6.86 0 00.91 3.35c1.19 1.73 3.57 1.94 5.63 2l.4 4.05a3.68 3.68 0 00.38 1.54 2.66 2.66 0 002.82 1c3-.55 4.7-3.73 5.54-6.69a28.77 28.77 0 00.61-12.85 14.89 14.89 0 00-3.88-8.23c-4.31-4.14-11.21-2.68-12.73 3.3z"
        fill="#c8a59c"
      />
      <path
        d="M391.54 97.64c-2.36-.39-5-1-7 .34a5.35 5.35 0 00-1.75 6.14 7.83 7.83 0 004.83 4.5c2.21.79 5 1.12 5.91 3.33.57 1.46 0 3.27.85 4.58l2-3.31a.57.57 0 01.27-.28.52.52 0 01.43.08 3.55 3.55 0 01-.25 6.36 6.39 6.39 0 012.44 6.12 3.17 3.17 0 003.45-.59 8.44 8.44 0 002-3.13 37.52 37.52 0 001.7-4.73 15.53 15.53 0 00.74-6.4 7.06 7.06 0 00-3.27-5.29 3.06 3.06 0 01-2.35-3.13c-1.04-5.35-6.01-3.89-10-4.59z"
        fill="#606161"
      />
      <path
        d="M391.94 101.3c-2.36-.39-5-1-7 .34a5.35 5.35 0 00-1.75 6.14 7.83 7.83 0 004.83 4.5c2.21.79 5 1.12 5.91 3.33.57 1.46 0 3.27.85 4.58l2-3.31a.57.57 0 01.27-.28.52.52 0 01.43.08 3.55 3.55 0 01-.25 6.36 6.39 6.39 0 012.44 6.12 3.17 3.17 0 003.45-.59 8.44 8.44 0 002-3.13 37.52 37.52 0 001.7-4.73 15.53 15.53 0 00.74-6.4 7.06 7.06 0 00-3.27-5.29 3.06 3.06 0 01-2.35-3.13c-1.04-5.35-6.01-3.89-10-4.59z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__j)"
      />
      <path
        d="M396.25 120.94a.79.79 0 00.4-.56 1.7 1.7 0 000-.5 2.3 2.3 0 010-1 1.32 1.32 0 01.61-.78.32.32 0 01.19 0 .33.33 0 01.16 0l.33.14a.62.62 0 01.25.17.66.66 0 01.08.21 3.5 3.5 0 01-.69 2.94.91.91 0 01-.23.21.85.85 0 01-.38.09 1.74 1.74 0 01-.88-.08c-.16-.09-.52-.3-.44-.52s.43-.26.6-.32z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__k)"
      />
      <path
        d="M417.41 172.26c-.06 2.18-.24 4.46-1.39 6.27-1.92 3.14-6 4.06-9.62 4.06a23.31 23.31 0 01-4.87-.46 15.29 15.29 0 01-3.45-1 12.29 12.29 0 01-6.16-5.69 20 20 0 01-1.57-6.7c-.42-3.53-.84-7.07-1.17-10.64-.36-4-.6-7.93-.63-11.89a32.84 32.84 0 011.05-9.38 13.77 13.77 0 015.14-7.6c2-1.38 5.89-3.13 8.42-2.76 2.82.4 4.72 4.18 5.83 6.51a84.17 84.17 0 018.42 39.28z"
        fill="#68e1fd"
      />
      <path
        d="M402.81 167.59a46.51 46.51 0 00-.63 10.27c.18 2.3 1.44 5.16.18 7.34a3.56 3.56 0 01-.43.59 15.29 15.29 0 01-3.45-1 12.29 12.29 0 01-6.16-5.69 20 20 0 01-1.57-6.7c-.42-3.53-.84-7.07-1.17-10.64.81-3.29 3.31-5.93 5.56-8.45 3.22-3.54 6.31-7.38 7.76-12a5 5 0 012.52 3.69 15.3 15.3 0 01-.15 4.67q-1.27 8.91-2.46 17.92z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__l)"
      />
      <path
        d="M396.24 134.07c-4.18 3.89-5.36 10-7.94 15.15a8.51 8.51 0 01-1.34 2.08c-1.22 1.32-3 1.88-4.68 2.48a49.41 49.41 0 00-9.81 4.77 7.66 7.66 0 00-3.47 3.6c-.79 2.3.64 4.95 2.73 6.14a11.27 11.27 0 007 1 45.42 45.42 0 0013.09-4.06 16.86 16.86 0 004-2.33 18.38 18.38 0 002.77-3.25 61 61 0 003.77-5.74 32.7 32.7 0 004-12.8 12.66 12.66 0 00-.62-6.12c-2.04-4.83-6.34-3.86-9.5-.92z"
        fill="#68e1fd"
      />
      <path
        d="M399.94 239.17c.28 1 .52 2.29-.13 3.15a2 2 0 01-1.32.75 2.84 2.84 0 01-2-.55 5.15 5.15 0 01-1.4-1.67 8 8 0 01-1-3.51 2.07 2.07 0 01.18-1.1 2 2 0 011.16-.85c.9-.34 2.6-.93 3.37-.07s.86 2.77 1.14 3.85zM418.6 236.34c.56.93 1.13 2 .74 3.07a2 2 0 01-1.05 1.09 2.85 2.85 0 01-2.11.06 4.91 4.91 0 01-1.8-1.2 7.75 7.75 0 01-1.9-3.08 2.12 2.12 0 01-.13-1.11 2 2 0 01.87-1.15c.77-.58 2.24-1.64 3.22-1s1.6 2.32 2.16 3.32z"
        fill="#c8a59c"
      />
      <path
        d="M397.77 241.39a3.58 3.58 0 01-1.84.16 2.69 2.69 0 00-.94 0 2.88 2.88 0 00-.74.38l-3.46 2.22a2 2 0 00-1 1 1.43 1.43 0 00.69 1.54 3.91 3.91 0 001.72.43 32.94 32.94 0 008.12-.28 2.07 2.07 0 001.09-.41 1.91 1.91 0 00.47-.77 5.59 5.59 0 00.32-3.33 3.59 3.59 0 00-1.8-2.47c-1.04-.27-1.8 1.14-2.63 1.53zM416.6 238.98a3.71 3.71 0 01-1.81.38 2.63 2.63 0 00-.93.15 2.72 2.72 0 00-.69.47l-3.16 2.63a2.09 2.09 0 00-.85 1.13 1.44 1.44 0 00.87 1.45 3.73 3.73 0 001.76.21 32.66 32.66 0 008-1.28 2.2 2.2 0 001-.55 2.06 2.06 0 00.38-.82 5.67 5.67 0 00-.08-3.34c-.31-.84-1.14-2.06-2.08-2.23-1.03-.19-1.62 1.3-2.41 1.8z"
        fill="#68e1fd"
      />
      <path
        d="M398.17 245.05a3.58 3.58 0 01-1.84.16 2.69 2.69 0 00-.94 0 2.88 2.88 0 00-.74.38l-3.46 2.22a2 2 0 00-1 1 1.43 1.43 0 00.69 1.54 3.91 3.91 0 001.72.43 32.94 32.94 0 008.12-.28 2.07 2.07 0 001.09-.41 1.91 1.91 0 00.47-.77 5.59 5.59 0 00.32-3.33 3.59 3.59 0 00-1.8-2.47c-1.04-.27-1.8 1.14-2.63 1.53z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__m)"
      />
      <path
        d="M417 242.64a3.71 3.71 0 01-1.81.38 2.63 2.63 0 00-.93.15 2.72 2.72 0 00-.69.47l-3.16 2.63a2.09 2.09 0 00-.85 1.13 1.44 1.44 0 00.87 1.45 3.73 3.73 0 001.76.21 32.66 32.66 0 008-1.28 2.2 2.2 0 001-.55 2.06 2.06 0 00.38-.82 5.67 5.67 0 00-.08-3.34c-.31-.84-1.14-2.06-2.08-2.23-1.03-.19-1.62 1.3-2.41 1.8z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__n)"
      />
      <rect
        x={166.35}
        y={106.32}
        width={88.45}
        height={6.49}
        rx={3.21}
        fill="#68e1fd"
        opacity={0.36}
      />
      <rect
        x={194.92}
        y={125.15}
        width={88.45}
        height={6.49}
        rx={3.21}
        fill="#68e1fd"
        opacity={0.36}
      />
      <rect
        x={170.37}
        y={143.76}
        width={88.45}
        height={6.49}
        rx={3.21}
        fill="#68e1fd"
        opacity={0.36}
      />
      <path
        d="M344.15 112.45c1-1 2-1.83 3-2.69.22-.2.49-.51.73-.5a2 2 0 011.26.38.92.92 0 01-.16 1.38c-.95.89-1.91 1.77-2.93 2.71a9.57 9.57 0 01.77 1 16.61 16.61 0 011.58 3.08 1.46 1.46 0 001.65 1.22c1.41 0 2.82.11 4.23.19.88 0 1.28.46 1.25 1.22s-.48 1.17-1.33 1.14c-1.74-.06-3.47-.16-5.3-.25a10.38 10.38 0 01-2.88 5.79l-11.47-13.09-1.95 1.77 11.57 13.25a8.11 8.11 0 01-5.13 2.12c-.86 0-1.14.32-1.09 1.19.08 1.29 0 2.58 0 3.87 0 1.13-.5 1.79-1.35 1.78s-1.2-.56-1.2-1.8v-4.51a1.13 1.13 0 00-.68-1.13c-1.3-.8-2.53-1.73-3.95-2.72l-1.62 1.49-1 .92c-.84.76-1.53.84-2.05.26s-.38-1.22.49-2l2.88-2.65c-1.27-1.44-2.48-2.84-3.72-4.2a1.32 1.32 0 00-.83-.24c-1.26-.09-2.52-.15-3.78-.22-.92 0-1.37-.46-1.36-1.24s.43-1.14 1.41-1.11c1.29 0 2.58.17 3.87.17a2.08 2.08 0 001.26-.49q5.61-5.07 11.16-10.2a2 2 0 00.52-1.17c.13-1.28.15-2.58.22-3.86 0-.24 0-.58.17-.7.37-.29.85-.7 1.23-.64a1.39 1.39 0 01.87 1 27.75 27.75 0 01-.15 3.86 2.29 2.29 0 00.66 2c1.07 1.16 2.07 2.38 3.15 3.62z"
        fill="#68e1fd"
      />
      <path
        d="M334.6 107.34l-9.22 8.44a6 6 0 01-1.53-5.16 6.16 6.16 0 0110.75-3.28z"
        fill="#68e1fd"
      />
      <path
        d="M344.57 116.09c1-1 2-1.83 3-2.69.22-.2.49-.51.73-.5a2 2 0 011.26.38.92.92 0 01-.16 1.38c-.95.89-1.91 1.77-2.93 2.71a9.57 9.57 0 01.77 1 16.61 16.61 0 011.58 3.08 1.45 1.45 0 001.65 1.21c1.41 0 2.82.12 4.23.2.88 0 1.28.46 1.25 1.22s-.48 1.17-1.33 1.14c-1.74-.06-3.47-.16-5.3-.25a10.31 10.31 0 01-2.88 5.79l-11.47-13.09-1.97 1.77 11.57 13.25a8.11 8.11 0 01-5.13 2.12c-.86 0-1.14.32-1.09 1.19.08 1.29.05 2.58.05 3.87 0 1.13-.5 1.79-1.35 1.77s-1.2-.55-1.2-1.79v-4.52a1.12 1.12 0 00-.68-1.12c-1.3-.8-2.53-1.73-3.95-2.72l-1.65 1.51-1 .92c-.84.76-1.53.84-2.05.26s-.38-1.22.49-2l2.88-2.65c-1.27-1.44-2.48-2.84-3.72-4.21a1.38 1.38 0 00-.83-.23c-1.26-.09-2.52-.15-3.78-.22-.92-.05-1.37-.46-1.36-1.24s.43-1.14 1.41-1.11c1.29 0 2.58.17 3.87.17a2.11 2.11 0 001.27-.49q5.6-5.07 11.15-10.2a2 2 0 00.52-1.17c.13-1.28.15-2.58.22-3.86 0-.24 0-.58.17-.7.37-.29.85-.7 1.23-.64a1.39 1.39 0 01.87 1 27.75 27.75 0 01-.15 3.86 2.29 2.29 0 00.66 2c1.07 1.14 2.07 2.36 3.15 3.6z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__o)"
      />
      <path
        d="M335 110.94l-9.22 8.44a6 6 0 01-1.52-5.16 6.16 6.16 0 0110.74-3.28z"
        transform="translate(-.4 -3.66)"
        fill="url(#bug_fixing_svg__p)"
      />
    </svg>
  );
};

export default BugGraphic;
