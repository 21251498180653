const PageNotFoundGraphic = (props) => {
  return (
    <svg viewBox="0 0 421 205" fill="none" {...props}>
      <g clipPath="url(#404_page_not_found__svg__clip0)">
        <path
          opacity={0.18}
          d="M24.04 168.231A61.896 61.896 0 01.145 123.483a61.891 61.891 0 0117.587-47.582c9.16-9.24 21-15.18 31.16-23.18C66.46 38.97 79.47 19.11 99.07 8.61c23.6-12.65 52.2-9.31 78.12-3 25.92 6.31 51.96 15.35 78.54 13.07 15.83-1.37 31.07-6.73 46.75-9.34 15.68-2.61 32.88-2.14 46 7 19.1 13.39 23.64 40.45 39.31 57.9 7.59 8.46 17.67 14.48 24.39 23.68 10.81 14.79 10.53 37-.65 51.51-14.32 18.55-40.16 21.49-61 31.69-11.6 5.68-22 13.87-34 18.63-25.22 10-53.59 3.49-79.45-4.59s-52.5-17.8-79.27-13.84c-25 3.72-47.53 19.15-72.74 21.49-11.7 1.09-25.06-1-32.21-11.1-7.69-10.83-18.41-15.48-28.82-23.48z"
          fill="#5FB1EB"
        />
        <path
          d="M30.82 110.921c6.79-7.55 6.79-7.55 14.49-16.61l32.77-38.35c7.25-8.31 10.12-12.08 14-18h31a217.137 217.137 0 00-1.21 25.06v49.11h4.38a105.806 105.806 0 0015-1.21v27.48a120.62 120.62 0 00-14.8-.9h-4.53v7.55a105.591 105.591 0 001.21 18.57H91.97a131.002 131.002 0 001.21-18.87v-7.4H50.45c-9.82 0-14 .15-19.63.6v-27.03zm62.51-16c0-6.8.3-15.1.91-21.75-3 4.23-5.29 7.25-9.36 12.38l-22.05 26.88h30.5v-17.51z"
          fill="#5FB1EB"
        />
        <path
          d="M30.82 110.921c6.79-7.55 6.79-7.55 14.49-16.61l32.77-38.35c7.25-8.31 10.12-12.08 14-18h31a217.137 217.137 0 00-1.21 25.06v49.11h4.38a105.806 105.806 0 0015-1.21v27.48a120.62 120.62 0 00-14.8-.9h-4.53v7.55a105.591 105.591 0 001.21 18.57H91.97a131.002 131.002 0 001.21-18.87v-7.4H50.45c-9.82 0-14 .15-19.63.6v-27.03zm62.51-16c0-6.8.3-15.1.91-21.75-3 4.23-5.29 7.25-9.36 12.38l-22.05 26.88h30.5v-17.51z"
          fill="url(#404_page_not_found__svg__paint0_linear)"
        />
        <path
          d="M198.86 73.051c3 .5 5.79 2.34 8.8 3 2.63.6 5.4.29 8 1.21 4.85 1.72 8 7.57 8.69 13.44.69 5.87-.54 11.81-1.94 17.51-3.68 15.02-8.68 30.02-17.68 41.41-.63.79-1.39 1.62-2.32 1.6a3.223 3.223 0 01-1.73-.81 28.915 28.915 0 01-5.15-4.51c-2.4-2.89-3.79-6.7-5-10.48-3.41-10.64-9.92-17.38-11.13-28.66-1.32-11.83 5.15-36.07 19.46-33.71z"
          fill="#5FB1EB"
        />
        <path
          d="M189.18 80.629a5.39 5.39 0 00-3.71.67 10.072 10.072 0 00-4.59 6.54 25.249 25.249 0 00-.29 8.19 110.79 110.79 0 004.63 23.58c.7 2.22-1.3 7.49.15 9.31 1.18 1.48.59 10.37 2.13 11.47 4.27 3.08 13.14 1.38 17.41 4.46 5.53 4 5.16-6.56 4.28-10.91-1-4.85-8.51-4-14.26-20.38-7.65-21.8-3.79-32.48-5.75-32.93z"
          fill="url(#404_page_not_found__svg__paint1_linear)"
        />
        <path
          d="M263.971 110.361c6.79-7.55 6.79-7.55 14.49-16.61l32.77-38.36a150.83 150.83 0 0014-18h31a216.861 216.861 0 00-1.21 25.07v49.08h4.38a107.526 107.526 0 0014.95-1.21v27.48c-4.91-.612-9.853-.916-14.8-.91h-4.53v7.55a105.222 105.222 0 001.21 18.57h-31.11a131.069 131.069 0 001.21-18.79v-7.4h-42.73c-9.82 0-14.05.15-19.63.61v-27.08zm62.51-16c0-6.8.3-15.1.9-21.74-3 4.22-5.28 7.24-9.36 12.38l-22 26.88h30.5l-.04-17.52z"
          fill="#5FB1EB"
        />
        <path
          d="M263.971 110.361c6.79-7.55 6.79-7.55 14.49-16.61l32.77-38.36a150.83 150.83 0 0014-18h31a216.861 216.861 0 00-1.21 25.07v49.08h4.38a107.526 107.526 0 0014.95-1.21v27.48c-4.91-.612-9.853-.916-14.8-.91h-4.53v7.55a105.222 105.222 0 001.21 18.57h-31.11a131.069 131.069 0 001.21-18.79v-7.4h-42.73c-9.82 0-14.05.15-19.63.61v-27.08zm62.51-16c0-6.8.3-15.1.9-21.74-3 4.22-5.28 7.24-9.36 12.38l-22 26.88h30.5l-.04-17.52z"
          fill="url(#404_page_not_found__svg__paint2_linear)"
        />
        <path
          d="M166.26 151.171a47.64 47.64 0 01-11-16.77 100.317 100.317 0 01-5.89-34.58c0-15.85 3.78-32.31 9.52-41.82 9.21-15.41 24.91-23.86 43.94-23.86 14.65 0 27.48 5 36.69 14a47.609 47.609 0 0111 16.76 100.648 100.648 0 015.88 34.73c0 15.86-3.77 32.47-9.51 42-9.06 15.12-24.91 23.6-44.09 23.6-14.8 0-27.33-4.86-36.54-14.06zm14-52.55c0 27 7.7 40.92 22.65 40.92s22.35-13.59 22.35-40.47c0-25.82-7.85-39.41-22.5-39.41-14.15 0-22.46 14.34-22.46 38.96h-.04z"
          fill="#5FB1EB"
        />
        <path
          d="M166.26 151.171a47.64 47.64 0 01-11-16.77 100.317 100.317 0 01-5.89-34.58c0-15.85 3.78-32.31 9.52-41.82 9.21-15.41 24.91-23.86 43.94-23.86 14.65 0 27.48 5 36.69 14a47.609 47.609 0 0111 16.76 100.648 100.648 0 015.88 34.73c0 15.86-3.77 32.47-9.51 42-9.06 15.12-24.91 23.6-44.09 23.6-14.8 0-27.33-4.86-36.54-14.06zm14-52.55c0 27 7.7 40.92 22.65 40.92s22.35-13.59 22.35-40.47c0-25.82-7.85-39.41-22.5-39.41-14.15 0-22.46 14.34-22.46 38.96h-.04z"
          fill="url(#404_page_not_found__svg__paint3_linear)"
        />
        <path
          d="M34.21 110.361c6.8-7.55 6.8-7.55 14.5-16.61l32.77-38.36a150.706 150.706 0 0014-18h31.001a216.861 216.861 0 00-1.21 25.07v49.08h4.38a107.476 107.476 0 0015-1.21v27.48c-4.91-.612-9.853-.916-14.8-.91h-4.53v7.55a105.317 105.317 0 001.21 18.57h-31.16a130.996 130.996 0 001.21-18.79v-7.4H53.84c-9.81 0-14 .15-19.63.61v-27.08zm62.52-16c0-6.8.3-15.1.9-21.74-3 4.22-5.28 7.24-9.36 12.38l-22 26.88h30.46v-17.52zM169.65 151.731a47.629 47.629 0 01-11-16.76 100.376 100.376 0 01-5.92-34.58c0-15.85 3.78-32.31 9.52-41.83 9.21-15.4 24.91-23.85 43.94-23.85 14.64 0 27.48 5 36.69 14a47.476 47.476 0 0111 16.76 100.331 100.331 0 015.92 34.76c0 15.85-3.77 32.47-9.51 42-9.06 15.1-24.92 23.55-44.09 23.55-14.8-.01-27.33-4.84-36.55-14.05zm14.08-52.5c0 27 7.7 40.92 22.65 40.92 14.8 0 22.35-13.59 22.35-40.46 0-25.83-7.86-39.42-22.5-39.42-14.23-.04-22.5 14.3-22.5 38.96z"
          fill="#5FB1EB"
        />
        <path
          d="M230.83 46.68a7.06 7.06 0 012.71 7.34 14.34 14.34 0 019.74 5.2 18.803 18.803 0 013.75 9.77c.288 3.525.211 7.07-.23 10.58-.05 1.212-.3 2.409-.74 3.54a7.499 7.499 0 01-5.13 3.85 22.092 22.092 0 01-6.6.31l-5.25-.28a1.08 1.08 0 01-.5-.11.993.993 0 01-.35-.72c-.36-2.15-.91-3.09-1.3-5.24a33.499 33.499 0 00-5.6-12.59 4.72 4.72 0 01-1-1.95 5.296 5.296 0 01.64-2.86 39.376 39.376 0 001.76-5.29 55.879 55.879 0 011.65-6.38 9.364 9.364 0 014-5.08"
          fill="url(#404_page_not_found__svg__paint4_linear)"
        />
        <path
          d="M228.381 109.488s19.22 1.7 28-10c3.5-4.64-4.3-26.5-4.3-26.5l-8.87 15.88-14 3.18-.83 17.44z"
          fill="url(#404_page_not_found__svg__paint5_linear)"
        />
        <path
          d="M267.65 110.361c6.79-7.55 6.79-7.55 14.49-16.61l32.77-38.36c7.25-8.3 10.12-12.08 14-18h31a217.367 217.367 0 00-1.18 25.1v49.08h4.38a107.514 107.514 0 0014.95-1.21v27.48a118.472 118.472 0 00-14.8-.91h-4.53v7.55c-.145 6.213.26 12.427 1.21 18.57H328.8a130.899 130.899 0 001.21-18.82v-7.4h-42.73c-9.82 0-14.05.15-19.63.61v-27.08zm62.51-16c0-6.8.3-15.1.91-21.74-3 4.22-5.29 7.24-9.36 12.38l-22.05 26.88h30.5v-17.52z"
          fill="#5FB1EB"
        />
        <path
          d="M183.281 75.29c-.518.189-1.02.42-1.5.69a3.473 3.473 0 00-1.16.95 1.476 1.476 0 00-.2 1.43 1.86 1.86 0 001.93.81c1.23-.12 2.37-.73 3.6-.94 1.23-.21 2.39-1.06 1.52-2.37-.87-1.31-2.96-1.03-4.19-.57z"
          fill="#F7AF87"
        />
        <path
          d="M187.2 78.23c-1.8-.47-3.68.32-5.3 1.23a3.136 3.136 0 00-1 .77 1.005 1.005 0 00-.309 1.144 1.004 1.004 0 00.999.636c.369.037.74.013 1.1-.07l2.49-.43a5.33 5.33 0 001.67-.48c1.13-.66 2.09-2.37.35-2.8z"
          fill="#F7AF87"
        />
        <path
          d="M183.73 81.541c-.516.197-1.018.43-1.5.7a3.47 3.47 0 00-1.16 1 1.491 1.491 0 00-.2 1.42 1.859 1.859 0 001.93.81c1.23-.12 2.37-.73 3.6-.94 1.23-.21 2.39-1.05 1.52-2.37-.87-1.32-2.98-1.08-4.19-.62z"
          fill="#F7AF87"
        />
        <path
          d="M187.609 84.06c-1.85-.15-3.56 1-5 2.14a3.29 3.29 0 00-.87.95 1 1 0 001 1.62 3.292 3.292 0 001.06-.26l2.37-.87a5.334 5.334 0 001.57-.77c.99-.82 1.66-2.64-.13-2.81zM240.61 55.828a20.16 20.16 0 00-9.35-1.95 3.906 3.906 0 00-1.6.26 1.4 1.4 0 00-.84 1.28c.097.475.369.896.76 1.18a17.012 17.012 0 003.94 2.68c.433.171.827.426 1.16.75a1.063 1.063 0 01.17 1.27c-.33.46-1.09.48-1.34 1-.33.65.5 1.29 1.22 1.41a4.429 4.429 0 002.28-.21 4.251 4.251 0 011.64-.46 3.991 3.991 0 011.56.71c1.58.9 6.49.93 6.18-1.81-.29-2.54-3.76-5.06-5.78-6.11z"
          fill="#F7AF87"
        />
        <path
          d="M216.449 77.881s22.11 9.11 24.64 8.64c2.53-.47-1.56-22.29-1.56-22.29l7.54-3.45s17 33.7 3.08 38.6a40.448 40.448 0 01-26.48-.28l-7.22-21.22z"
          fill="#5FB1EB"
        />
        <path
          d="M228.211 65.231a14.945 14.945 0 01-1.36 5.66 10.006 10.006 0 01-7.55 4.77 22.36 22.36 0 00-3.34.47 3.153 3.153 0 00-2.29 1.91c-.89 2.16-1.81 4.35-4.68 3.62-.27-.06-.532-.15-.78-.27a7.708 7.708 0 01-2.39-1.75 14.334 14.334 0 01-2.18-2.84 4.13 4.13 0 01-.62-1.58 5.144 5.144 0 01.24-2.05c1-4 1.16-8.53 2.58-12.43a18.232 18.232 0 017.86-9.42c3.59-2.09 8.58-2.12 11.63.73a10.507 10.507 0 013 6.2c.24 2.323.199 4.667-.12 6.98z"
          fill="#F7AF87"
        />
        <path
          d="M203.89 60.228c.19-1.88.18-3.79.55-5.64a8 8 0 012.67-4.87 4.468 4.468 0 011.2-.68c.39-.14.8-.22 1.2-.33a15.723 15.723 0 003.34-1.66 16.498 16.498 0 015.12-2 22.743 22.743 0 013.69-.26c2.267-.04 4.544-.06 6.83-.06a3.358 3.358 0 011.57.26c.544.336.941.864 1.11 1.48a7.036 7.036 0 010 4.24 20.172 20.172 0 01-1.75 3.92 6.822 6.822 0 01-1.46 2.09c-.53-.92-1-1.87-1.48-2.83-1.53 1.59-3.94 2-6.14 1.83-2.2-.17-4.36-.74-6.56-.77a3.995 3.995 0 00-2.94.87 4.61 4.61 0 00-1 2 49.966 49.966 0 00-1.13 5c-.26-.49-1.31-1.77-1.86-1.75a1.158 1.158 0 00-.81.45c-.91 1-.46 4.67.38 5.73.169.16.296.36.37.58a1.123 1.123 0 01-.239.83 2.242 2.242 0 01-1.901.78c-.88 0-1.05-1.68-1.14-2.3-.37-2.31.16-4.64.38-6.91z"
          fill="#606060"
        />
        <path
          d="M207.4 64.449a1.663 1.663 0 01-.16-.83 2.44 2.44 0 00-.09-.85 1.092 1.092 0 00-.51-.59.379.379 0 00-.21 0 .392.392 0 00-.23.28 5 5 0 00.11 3.35 1.326 1.326 0 00.77.86.999.999 0 00.91-.28 1.233 1.233 0 00.26-1.27c-.17-.4-.64-.31-.85-.67z"
          fill="url(#404_page_not_found__svg__paint6_linear)"
        />
        <path
          d="M215.961 76.111a3.153 3.153 0 00-2.29 1.91c-.89 2.16-1.81 4.35-4.68 3.62-.27-.06-.532-.15-.78-.27a2.314 2.314 0 01-.58-.75 2.673 2.673 0 01.08-2.1 4.686 4.686 0 014.22-2.9 13.47 13.47 0 012.27.3c.46.06 1 0 1.42.05.122.022.238.07.34.14z"
          fill="url(#404_page_not_found__svg__paint7_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="404_page_not_found__svg__paint0_linear"
          x1={-65.27}
          y1={8.391}
          x2={262.66}
          y2={191.891}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.99} />
        </linearGradient>
        <linearGradient
          id="404_page_not_found__svg__paint1_linear"
          x1={6546.74}
          y1={10821.2}
          x2={2723.33}
          y2={11879.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.99} />
        </linearGradient>
        <linearGradient
          id="404_page_not_found__svg__paint2_linear"
          x1={47215.2}
          y1={31345.6}
          x2={12050.8}
          y2={5006.88}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.99} />
        </linearGradient>
        <linearGradient
          id="404_page_not_found__svg__paint3_linear"
          x1={31820.2}
          y1={21953.7}
          x2={-1812.82}
          y2={20839.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.99} />
        </linearGradient>
        <linearGradient
          id="404_page_not_found__svg__paint4_linear"
          x1={7188.78}
          y1={4336.43}
          x2={3719.28}
          y2={6788.99}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.99} />
        </linearGradient>
        <linearGradient
          id="404_page_not_found__svg__paint5_linear"
          x1={7351.02}
          y1={6670.58}
          x2={6754.24}
          y2={4446.67}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.99} />
        </linearGradient>
        <linearGradient
          id="404_page_not_found__svg__paint6_linear"
          x1={694.158}
          y1={642.9}
          x2={700.797}
          y2={641.695}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.99} />
        </linearGradient>
        <linearGradient
          id="404_page_not_found__svg__paint7_linear"
          x1={1970.82}
          y1={928.494}
          x2={2066.2}
          y2={1036.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={0.99} />
        </linearGradient>
        <clipPath id="404_page_not_found__svg__clip0">
          <path fill="#fff" d="M0 0h420.07v204.87H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PageNotFoundGraphic;
