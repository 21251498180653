import { isValidElement, cloneElement, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    fontWeight: 500,
    fontSize: '1.25rem',
  },
  icon: {
    fontSize: '32px !important',
  },
}));

const WideButton = forwardRef((props, ref) => {
  const { className, startIcon, ...rest } = props;
  const styles = useStyles();

  const renderedStartIcon = isValidElement(startIcon)
    ? cloneElement(startIcon, {
        className: clsx(startIcon.props.className, styles.icon),
      })
    : startIcon;

  return (
    <Button
      size="large"
      startIcon={renderedStartIcon}
      className={clsx(styles.root, className)}
      ref={ref}
      {...rest}
    />
  );
});

WideButton.propTypes = {
  startIcon: PropTypes.element.isRequired,
};

export default WideButton;
