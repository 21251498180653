import { put, call } from 'redux-saga/effects';
import { compact, uniq, map, find } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPDATE_EVENT_SUCCESS } from './update-event';

const MODULE = 'event';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchEvent = Exec.requestActionCreator(MODULE);
export const fetchEventLoading = Exec.loadingActionCreator(MODULE);
export const fetchEventSuccess = Exec.successActionCreator(MODULE);
export const fetchEventError = Exec.errorActionCreator(MODULE);
export const fetchEventReset = Exec.resetActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);
export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_EVENT_SUCCESS:
      if (!state || !state.data) {
        return state;
      }

      const { data } = action.payload;

      if (data.id !== state.data.id) {
        return state;
      }

      return { ...state, data };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectEventRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectEvent = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchEvent = function* ({ payload, meta }) {
  try {
    const { data } = yield call(Api.getEvent, payload.eventId);
    const mentors = yield Promise.all(
      compact(uniq(data.mentorIds)).map((id) =>
        Api.getUser(id).then((res) => res.data),
      ),
    );

    data.mentors = map(data.mentorIds, (id) => find(mentors, ['id', id]));
    delete data.mentorIds;

    yield put(fetchEventSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchEventError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchEvent);
