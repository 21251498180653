import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectVerifyUserRequest, selectUser } from 'Redux/selectors';
import { verifyUser, enqueueSnackbar } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const VerifyUserDialog = (props) => {
  const { onClose } = props;

  const { id: userId } = useSelector(selectUser);
  const { isLoading } = useSelector(selectVerifyUserRequest);
  const dispatch = useDispatch();

  const handleVerify = useCallback(() => {
    dispatch(verifyUser({ userId }, { isPromise: true }))
      .then(() => {
        dispatch(
          enqueueSnackbar('VERIFY_USER', {
            variant: 'success',
            message: 'User email verified successfully',
          }),
        );
        onClose && onClose();
      })
      .catch(noop);
  }, [dispatch, onClose, userId]);

  return (
    <Dialog
      title="Verify Email"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleVerify}>
          Verify
        </Button>
      }
    >
      <Typography variant="body1" align="center" gutterBottom>
        Are you sure you want to verify this email? Make sure you confirmed user
        actually owns it.
      </Typography>
    </Dialog>
  );
};

VerifyUserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default VerifyUserDialog;
