import { call, put, select } from 'redux-saga/effects';

import { findIndex, sortBy } from 'lodash';
import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { selectCourses } from './courses';

const MODULE = 'sort-courses';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SORT_COURSES_REQUEST = Exec.requestConstantCreator(MODULE);
export const SORT_COURSES_SUCCESS = Exec.successConstantCreator(MODULE);
export const SORT_COURSES_ERROR = Exec.errorConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const sortCourses = Exec.requestActionCreator(MODULE);
export const sortCoursesLoading = Exec.loadingActionCreator(MODULE);
export const sortCoursesSuccess = Exec.successActionCreator(MODULE);
export const sortCoursesError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSortCoursesRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSortCourses = function* ({ payload, meta }) {
  const { sortConfig } = payload;
  const courses = yield select(selectCourses);

  try {
    yield put(closeSnackbar(SORT_COURSES_REQUEST));
    yield put(
      sortCoursesSuccess({
        data: sortBy(courses, (c) => findIndex(sortConfig, ['id', c.id])),
      }),
    );
    yield put(sortCoursesLoading());

    const { data } = yield call(Api.sortCourses, { sortConfig });

    yield put(
      sortCoursesSuccess(
        { data: data.filter((c) => ['COURSE', 'BLUEPRINT'].includes(c.type)) },
        meta,
      ),
    );
  } catch (err) {
    yield put(sortCoursesError({ ...err, data: courses }, meta));
    yield put(
      enqueueSnackbar(SORT_COURSES_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSortCourses);
