import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  SnackbarProvider as NotistackSnackbarProvider,
  useSnackbar,
} from 'notistack';
import { replace } from 'lodash';

import { selectSnackbarNotifications } from 'Redux/selectors';
import { removeSnackbar } from 'Redux/actions';

import { useMediaQuery } from '@material-ui/core';

import SnackbarAction from './snackbar-action';

let displayed = [];

const ConnectedSnackbar = ({ children }) => {
  const mdScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const notifications = useSelector(selectSnackbarNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = displayed.filter((key) => id !== key);
  };

  useEffect(() => {
    notifications.forEach(({ key, message, dismissed = false, ...options }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        removeDisplayed(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) {
        return;
      }

      // display snackbar using notistack
      enqueueSnackbar(replace(message + '.', /([.])$/g, '').trim(), {
        ...options,
        key,
        autoHideDuration: 3000,
        anchorOrigin: {
          horizontal: mdScreen ? 'center' : 'left',
          vertical: 'bottom',
        },
        onClose: (event, reason, key) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }
        },
        onExited: (event, key) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar(key));
          removeDisplayed(key);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, mdScreen]);

  return children;
};

const renderAction = (id) => <SnackbarAction id={id} />;

const SnackbarProvider = ({ children, ...rest }) => (
  <NotistackSnackbarProvider action={renderAction} {...rest}>
    <ConnectedSnackbar>{children}</ConnectedSnackbar>
  </NotistackSnackbarProvider>
);

export default SnackbarProvider;
