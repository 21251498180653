import PropTypes from 'prop-types';
import { toUpper } from 'lodash';

import dynamicImageUrl from 'Util/dynamic-image-url';

import { makeStyles, Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: 'relative',
    width: props.size,
    height: props.size,
    overflow: 'visible',
    zIndex: 1,
    ...(props.disableOutline
      ? {}
      : {
          '&:after': {
            width: props.size,
            height: props.size,
            content: "' '",
            position: 'absolute',
            border: `2px solid ${theme.palette.secondary.light}`,
            borderRadius: '50%',
          },
        }),
    '& img': {
      backgroundColor: theme.palette.grey[300],
      width: props.size - 8,
      height: props.size - 8,
      borderRadius: '50%',
    },
  }),
  colorDefault: (props) => ({
    backgroundColor: 'transparent',
    '&:before': {
      backgroundColor: theme.palette.grey[400],
      width: props.size - 8,
      height: props.size - 8,
      content: "' '",
      position: 'absolute',
      zIndex: -1,
      borderRadius: '50%',
    },
  }),
}));

const UserAvatar = (props) => {
  const { disableOutline, size, user, ...rest } = props;
  const styles = useStyles({ disableOutline, size });

  return user && user.image ? (
    <Avatar
      classes={styles}
      alt={user.firstName + ' ' + user.lastName}
      src={dynamicImageUrl(user.image, {
        resize: {
          width: size,
          height: size,
          fit: 'cover',
        },
      })}
      {...rest}
    />
  ) : (
    <Avatar classes={styles} {...rest}>
      {user ? toUpper(user.firstName[0] + user.lastName[0]) : ''}
    </Avatar>
  );
};

UserAvatar.propTypes = {
  disableOutline: PropTypes.bool,
  size: PropTypes.number.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
};

UserAvatar.defaultProps = {
  size: 32,
};

export default UserAvatar;
