import { put, call } from 'redux-saga/effects';
import axios from 'axios';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'lesson-video';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchLessonVideo = Exec.requestActionCreator(MODULE);
export const fetchLessonVideoLoading = Exec.loadingActionCreator(MODULE);
export const fetchLessonVideoSuccess = Exec.successActionCreator(MODULE);
export const fetchLessonVideoError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE, {
  cacheControl: Exec.CACHE_CONTROL_NO_CACHE,
});

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectLessonVideoRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectLessonVideo = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchLessonVideo = function* ({ payload, meta }) {
  try {
    const { data } = yield call(
      Api.getLessonVideo,
      payload.courseId,
      payload.moduleId,
      payload.lessonId,
      payload.skipAccessCheck,
    );
    if (data) {
      try {
        const { data: wistiaMetadata, status } = yield call(
          axios.get,
          `https://fast.wistia.net/oembed?url=${data.url}`,
        );
        if (status >= 200 || status < 300) {
          data.wistiaMetadata = wistiaMetadata;
        }
      } catch {}
    }
    yield put(fetchLessonVideoSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchLessonVideoError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchLessonVideo);
