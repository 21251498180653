import PropTypes from 'prop-types';
import { constant, times } from 'lodash';
import clsx from 'clsx';

import { Skeleton } from '@material-ui/lab';
import { makeStyles, Typography } from '@material-ui/core';

import SearchResult from './search-result';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  heading: {
    display: 'flex',
    marginBottom: theme.spacing(3),

    padding: theme.spacing(0, 2.5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 4),
    },

    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
  },
  count: {
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      marginBottom: 5,
    },
  },
  results: {
    display: 'grid',
    width: '100%',
    rowGap: theme.spacing(1) + 'px',
  },
}));

const SearchResultList = (props) => {
  const {
    className,
    title,
    results,
    target,
    currentIndex = 0,
    totalCount = 0,
    skeleton,
  } = props;
  const styles = useStyles();

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.heading}>
        <Typography variant="h4">
          {skeleton ? <Skeleton width={220} /> : title}
        </Typography>
        <Typography variant="body2" className={styles.count}>
          {skeleton ? (
            <Skeleton width={120} />
          ) : (
            <>
              Results
              <strong>
                {' '}
                {currentIndex + 1}-{results.length + currentIndex}{' '}
              </strong>
              out of <strong>{totalCount}</strong>
            </>
          )}
        </Typography>
      </div>
      <div className={styles.results}>
        {(skeleton ? times(3, constant({ skeleton })) : results).map(
          (result, index) => {
            return <SearchResult key={index} target={target} {...result} />;
          },
        )}
      </div>
    </div>
  );
};

SearchResultList.propTypes = {
  title: PropTypes.string,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ),
  target: PropTypes.oneOf(['open', 'navigate']),
  currentIndex: PropTypes.number,
  totalCount: PropTypes.number,
  skeleton: PropTypes.bool,
};

export default SearchResultList;
