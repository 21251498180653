import { useLocation, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useEffect, useMemo } from 'react';
import { get, find, findLast } from 'lodash';
import { addHours, isAfter, isBefore } from 'date-fns';

import {
  selectConfirmBoxsetShippingAddressPostponedAt,
  selectCourses,
  selectSubscriptionSteps,
} from 'Redux/selectors';
import { setConfirmBoxsetShippingAddressPostponedAt } from 'Redux/actions';

import useMemoizedCallback from 'Hooks/use-memoized-callback';
import ConfirmShippingAddressDialog from 'Dialogs/confirm-shipping-address-dialog';

const useConfirmShippingAddress = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const courses = useSelector(selectCourses);
  const subscriptionSteps = useSelector(selectSubscriptionSteps);
  const confirmBoxsetShippingAddressPostponedAt = useSelector(
    selectConfirmBoxsetShippingAddressPostponedAt,
  );

  const match = useMemo(
    () =>
      matchPath('/learn/:courseSlug', pathname) ||
      matchPath('/learn/:courseSlug/watch/:moduleSlug/:lessonSlug', pathname),
    [pathname],
  );

  const course = match
    ? find(courses, ['slug', match.params.courseSlug])
    : null;

  const postponedAt = course
    ? get(confirmBoxsetShippingAddressPostponedAt, course.id)
    : null;

  const steps = course
    ? find(subscriptionSteps, ['courseId', course.id])
    : null;

  const handlePostponeClose = useMemoizedCallback(
    (onClose) => (success) => {
      const value = success ? null : new Date();

      dispatch(setConfirmBoxsetShippingAddressPostponedAt(course.id, value));
      onClose();
    },
    [course, dispatch],
  );

  const [showConfirmShippingAddressModal, hideConfirmShippingAddressModal] =
    useModal(
      () => (
        <ConfirmShippingAddressDialog
          courseId={course ? course.id : ''}
          onClose={handlePostponeClose(hideConfirmShippingAddressModal)}
        />
      ),
      [course],
    );

  useEffect(() => {
    if (!steps) {
      return;
    }

    if (postponedAt && isBefore(new Date(), addHours(postponedAt, 6))) {
      return;
    }

    const activeStep = findLast(steps.steps, (s) =>
      isAfter(new Date(), new Date(s.activateAt)),
    );

    const shouldConfirmShipping =
      activeStep &&
      activeStep.type === 'boxset-shipment' &&
      !activeStep.submittedAddress;

    if (shouldConfirmShipping) {
      showConfirmShippingAddressModal();
    }
  }, [postponedAt, showConfirmShippingAddressModal, steps]);
};

export default useConfirmShippingAddress;
