import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const PaymentIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        d="M36.25 12.5c0-2.07-1.68-3.75-3.75-3.75h-25c-2.07 0-3.75 1.68-3.75 3.75v1.25h32.5V12.5zM3.75 27.5c0 2.07 1.68 3.75 3.75 3.75h25c2.07 0 3.75-1.68 3.75-3.75V16.25H3.75V27.5zm5-6.25h2.5a1.25 1.25 0 110 2.5h-2.5a1.25 1.25 0 110-2.5zm0 3.75h7.5a1.25 1.25 0 110 2.5h-7.5a1.25 1.25 0 110-2.5z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default PaymentIcon;
