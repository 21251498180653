import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar } from './snackbar';

const MODULE = 'upgrade-course-mentorship';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPGRADE_COURSE_MENTORSHIP_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const upgradeCourseMentorship = Exec.requestActionCreator(MODULE);
export const upgradeCourseMentorshipLoading = Exec.loadingActionCreator(MODULE);
export const upgradeCourseMentorshipSuccess = Exec.successActionCreator(MODULE);
export const upgradeCourseMentorshipError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpgradeCourseMentorshipRequest =
  Exec.mutateRequestSelectorCreator(MODULE);
export const selectUpgradeCourseMentorship = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpgradeCourseMentorship = function* ({ payload, meta }) {
  try {
    yield put(upgradeCourseMentorshipLoading());

    const { data } = yield call(Api.upgradeCourseMentorship, payload.courseId);

    yield put(upgradeCourseMentorshipSuccess({ data }, meta));
  } catch (err) {
    yield put(upgradeCourseMentorshipError(err, meta));
    yield put(
      enqueueSnackbar(UPGRADE_COURSE_MENTORSHIP_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpgradeCourseMentorship);
