import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    backgroundColor: '#FF9900',
    minWidth: 40,
    minHeight: 40,
    borderRadius: '50%',
    color: 'white',
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
}));

const SectionTitle = (props) => {
  const { className, icon, children } = props;
  const styles = useStyles();

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.icon}>{icon}</div>
      <Typography variant="h5">{children}</Typography>
    </div>
  );
};

SectionTitle.propTypes = {
  icon: PropTypes.element.isRequired,
};

export default SectionTitle;
