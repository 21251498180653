import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { get, find, last, differenceBy, isEqual } from 'lodash';

import {
  selectCoursesWithGraph,
  selectCoursesRequest,
  selectModulesRequest,
  selectLessonsRequest,
  selectTopics,
  selectTopicsRequest,
  selectUpdateQuestionRequest,
  selectCreateQuestionRequest,
  selectUpdateLessonRequest,
} from 'Redux/selectors';
import {
  createQuestion,
  fetchCourses,
  fetchLessons,
  fetchModules,
  fetchTopics,
  updateLesson,
  updateQuestion,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import AddTopicDialog from 'Dialogs/add-topic-dialog';
import RichTextEditor from 'Components/shared/rich-text-editor';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Save as SaveIcon } from '@material-ui/icons';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  TextField,
} from '@material-ui/core';

const filter = createFilterOptions();

const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      inputValue: params.inputValue,
      title: `Add "${params.inputValue}"`,
    });
  }

  return filtered;
};

const getOptionLabel = (option) => {
  // e.g value selected with enter, right from the input
  if (typeof option === 'string') {
    return option;
  }
  if (option.inputValue) {
    return option.inputValue;
  }
  return option.title;
};

const renderOption = (option) => option.title;

const renderInput = (params) => (
  <TextField {...params} label="Topics" variant="outlined" helperText=" " />
);

const schema = yup.object().shape({
  title: yup.string().required('Field Required.'),
  slug: yup
    .string()
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g, 'Only Letters, Numbers and Dashes.')
    .required('Field Required.'),
  answer: yup.string().required('Field Required.'),
});

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  input: {
    width: '100%',
  },
  colorPreview: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  switch: {
    marginBottom: theme.spacing(2.5),
  },
  upload: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  topics: {
    marginBottom: theme.spacing(3),
  },
}));

const AdminCreateOrUpdateQuestionPage = () => {
  const styles = useStyles();
  const loadedElRef = useRef(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId, moduleId, lessonId, questionId } = useParams();

  const [createNewTopic, setCreateNewTopic] = useState();
  const [selectedTopics, setSelectedTopics] = useState([]);

  const topics = useSelector(selectTopics);
  const courses = useSelector(selectCoursesWithGraph);
  const course = find(courses, ['id', courseId]);
  const module = course ? find(course.modules, ['id', moduleId]) : null;
  const lesson = module ? find(module.lessons, ['id', lessonId]) : null;
  const question = lesson ? find(lesson.questions, ['id', questionId]) : null;

  const options = useMemo(
    () => differenceBy(topics, selectedTopics, 'id'),
    [topics, selectedTopics],
  );

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectModulesRequest,
    selectLessonsRequest,
    selectTopicsRequest,
  );

  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectUpdateQuestionRequest,
    selectCreateQuestionRequest,
    selectUpdateLessonRequest,
  );

  useEffect(() => {
    if (question && question.topics && !loadedElRef.current) {
      loadedElRef.current = true;
      setSelectedTopics(question.topics);
    }
  }, [question]);

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    if (!module) {
      dispatch(fetchModules({ courseId, skipAccessCheck: true }));
    }
    if (!lesson) {
      dispatch(fetchLessons({ courseId, skipAccessCheck: true }));
    }
    dispatch(fetchTopics());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId]);

  const handleFormSubmit = useCallback(
    async (data) => {
      const action = questionId
        ? updateQuestion(
            { ...data, topics: selectedTopics.map((t) => t.id), questionId },
            { isPromise: true },
          )
        : createQuestion(
            { ...data, topics: selectedTopics.map((t) => t.id) },
            { isPromise: true },
          );

      try {
        const payload = await dispatch(action);

        if (!questionId) {
          await dispatch(
            updateLesson(
              {
                courseId,
                moduleId,
                lessonId,
                questions: [
                  ...lesson.questions.map((l) => l.id),
                  payload.data.id,
                ],
              },
              { isPromise: true },
            ),
          );
        }

        navigate(
          `/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/overview`,
        );
      } catch {}
    },
    [
      dispatch,
      navigate,
      courseId,
      lessonId,
      moduleId,
      questionId,
      lesson,
      selectedTopics,
    ],
  );

  const handleChangeTopic = useCallback((event, values) => {
    const newValue = last(values);

    if (typeof newValue === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        setCreateNewTopic(newValue);
      });
    } else if (newValue && newValue.inputValue) {
      setCreateNewTopic(newValue.inputValue);
    } else {
      setSelectedTopics(values);
    }
  }, []);

  const handleCloseAddTopicDialog = useCallback((created) => {
    setCreateNewTopic(null);
    if (created) {
      setSelectedTopics((previous) => [...previous, created]);
    }
  }, []);

  if (!isLoading && !question && questionId) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { control, errors, formState, handleSubmit } = useForm({
          defaultValues: question
            ? {
                ...question,
                answer: question.answer ? question.answer.html : null,
              }
            : {},
          validationSchema: schema,
          reValidateMode: 'onChange',
        });

        const edited =
          formState.dirty ||
          Boolean(
            question
              ? !isEqual(
                  selectedTopics.map((t) => t.id),
                  question.topics.map((t) => t.id),
                )
              : selectedTopics.length,
          );

        return (
          <UserDetailsLayout>
            {createNewTopic ? (
              <AddTopicDialog
                defaultTitle={createNewTopic}
                onClose={handleCloseAddTopicDialog}
              />
            ) : null}

            <Breadcrumbs
              className={styles.breadcrumbs}
              links={[
                { href: '/admin/courses', label: 'Courses' },
                {
                  href: `/admin/courses/${courseId}/overview`,
                  label: course.title,
                },
                {
                  href: `/admin/courses/${courseId}/modules/${moduleId}/overview`,
                  label: module.title,
                },
                {
                  href: `/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/overview`,
                  label: lesson.title,
                },
                {
                  label: question ? question.title : 'Question',
                },
              ]}
            />

            <form
              className={styles.form}
              noValidate
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <TitleAndAction
                className={styles.titleAndAction}
                title={
                  <Typography variant="h4">
                    {question ? 'Edit Question' : 'Create New Question'}
                  </Typography>
                }
              />

              <Grid container spacing={2} direction="column">
                <Grid item container spacing={2} direction="row">
                  <Grid item xs={12} sm={6} md={5}>
                    <Input
                      data-testid="title"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Title"
                      name="title"
                      errorText={get(errors, 'title.message')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Input
                      data-testid="slug"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Slug"
                      name="slug"
                      errorText={get(errors, 'slug.message')}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <RichTextEditor
                    data-testid="answer"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Answer"
                    name="answer"
                    errorText={get(errors, 'answer.message')}
                  />
                </Grid>

                <Grid item>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    value={selectedTopics}
                    onChange={handleChangeTopic}
                    filterOptions={filterOptions}
                    options={options}
                    getOptionLabel={getOptionLabel}
                    selectOnFocus
                    clearOnBlur
                    clearOnEscape
                    handleHomeEndKeys
                    renderOption={renderOption}
                    freeSolo
                    renderInput={renderInput}
                  />
                </Grid>

                <Grid item xs={10} sm={6} md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={!edited}
                  >
                    Save Question
                  </Button>
                </Grid>
              </Grid>
            </form>
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminCreateOrUpdateQuestionPage;
