import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { get, find, omit, noop } from 'lodash';

import {
  selectCoursesWithGraph,
  selectCoursesRequest,
  selectModulesRequest,
  selectCreateModuleRequest,
  selectUpdateModuleRequest,
} from 'Redux/selectors';
import {
  createModule,
  fetchCourses,
  fetchModules,
  updateModule,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import TooltipButton from 'Components/shared/tooltip-button';
import Switch from 'Components/shared/switch';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import { Save as SaveIcon } from '@material-ui/icons';
import {
  Grid,
  InputAdornment,
  Button,
  makeStyles,
  Typography,
  FormControlLabel,
} from '@material-ui/core';

const schemaFields = {
  title: yup.string().required('Field Required.'),
  slug: yup
    .string()
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g, 'Only Letters, Numbers and Dashes.')
    .required('Field Required.'),
  summary: yup.string().required('Field Required.'),
  daysToUnlock: yup
    .number()
    .min(0, 'At Least 0.')
    .default(0)
    .nullable()
    .transform((value) => (isNaN(value) ? undefined : value))
    .typeError('Not a Number.'),
  isVisible: yup.boolean().required('Field Required.'),
};
const updateModuleSchema = yup.object().shape(schemaFields);
const createModuleSchema = yup
  .object()
  .shape(omit(schemaFields, ['isVisible']));

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  input: {
    width: '100%',
  },
  colorPreview: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  switch: {
    marginBottom: theme.spacing(2.5),
  },
  upload: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tooltip: {
    marginBottom: 20,
  },
}));

const AdminCreateOrUpdateModulePage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId, moduleId } = useParams();

  const courses = useSelector(selectCoursesWithGraph);
  const course = find(courses, ['id', courseId]);
  const module = course ? find(course.modules, ['id', moduleId]) : null;

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectModulesRequest,
  );

  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectCreateModuleRequest,
    selectUpdateModuleRequest,
  );

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    if (!module) {
      dispatch(fetchModules({ courseId, skipAccessCheck: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId]);

  const handleFormSubmit = useCallback(
    async (data) => {
      const action = moduleId
        ? updateModule({ courseId, moduleId, ...data }, { isPromise: true })
        : createModule({ courseId, ...data }, { isPromise: true });

      dispatch(action)
        .then((payload) =>
          navigate(
            `/admin/courses/${courseId}/modules/${payload.data.id}/overview`,
          ),
        )
        .catch(noop);
    },
    [moduleId, dispatch, navigate, courseId],
  );

  if (!isLoading && !module && moduleId) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { control, errors, handleSubmit, formState } = useForm({
          defaultValues:
            moduleId && module
              ? { ...module, daysToUnlock: String(module.daysToUnlock) }
              : {},
          validationSchema:
            moduleId && module ? updateModuleSchema : createModuleSchema,
          reValidateMode: 'onChange',
        });

        const edited = formState.dirty;

        return (
          <UserDetailsLayout>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={
                moduleId && module
                  ? [
                      { href: '/admin/courses', label: 'Courses' },
                      {
                        href: `/admin/courses/${courseId}/overview`,
                        label: course.title,
                      },
                      {
                        href: `/admin/courses/${courseId}/modules/${moduleId}/overview`,
                        label: module.title,
                      },
                      { label: 'Edit' },
                    ]
                  : [
                      { href: '/admin/courses', label: 'Courses' },
                      {
                        href: `/admin/courses/${courseId}/overview`,
                        label: course.title,
                      },
                      { label: 'Create' },
                    ]
              }
            />

            <form
              className={styles.form}
              noValidate
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <TitleAndAction
                className={styles.titleAndAction}
                title={
                  <Typography variant="h4">
                    {moduleId ? 'Edit Module' : 'Create New Module'}
                  </Typography>
                }
              />

              <Grid container spacing={2} direction="column">
                <Grid item container spacing={2} direction="row">
                  <Grid item xs={12} sm={6} md={5}>
                    <Input
                      data-testid="title"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Title"
                      name="title"
                      errorText={get(errors, 'title.message')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Input
                      data-testid="slug"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Slug"
                      name="slug"
                      errorText={get(errors, 'slug.message')}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <Input
                    data-testid="summary"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Summary"
                    name="summary"
                    errorText={get(errors, 'summary.message')}
                  />
                </Grid>

                <Grid
                  item
                  container
                  spacing={1}
                  xs={12}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={10} sm={6} md={5}>
                    <Input
                      data-testid="daysToUnlock"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Days To Unlock"
                      name="daysToUnlock"
                      errorText={get(errors, 'daysToUnlock.message')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2">days</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TooltipButton
                      className={styles.tooltip}
                      data-testid="daysToUnlockTooltip"
                      title="Days To Unlock"
                      message={[
                        'Use this to specify the drip feed setting.',
                        "This options is useful, for example, when users are signing up with Free Trial option and they shouldn't be able to see the entire course until X days have elapsed since their singup.",
                        'NOTE: This option is controlled per-module, so make sure to specify it for each module individualy.',
                      ]}
                    />
                  </Grid>
                </Grid>

                {moduleId ? (
                  <Grid item>
                    <FormControlLabel
                      className={styles.switch}
                      control={
                        <Switch
                          data-testid="isVisible"
                          control={control}
                          disabled={isLoadingMutation}
                          name="isVisible"
                          color="primary"
                        />
                      }
                      label="Visible for users"
                    />
                  </Grid>
                ) : null}

                <Grid item xs={10} sm={6} md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={!edited}
                  >
                    Save Module
                  </Button>
                </Grid>
              </Grid>
            </form>
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminCreateOrUpdateModulePage;
