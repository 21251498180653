import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import dynamicImageUrl from 'Util/dynamic-image-url';
import LogoLarge from 'Components/shared/logo-large';

import { Lock as LockIcon } from '@material-ui/icons';
import { blue, green } from '@material-ui/core/colors';
import { makeStyles, ButtonBase } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: (props) => ({
      position: 'relative',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      height: 128,
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${props.imageUrl}")`,
      boxShadow: theme.shadows[props.elevation],
      backgroundSize: 'auto, cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }),
    logo: {
      position: 'absolute',
      color: 'white',
      left: 0,
      right: 0,
      backgroundColor: '#02060E',
      borderRadius: 4,
      boxShadow: theme.shadows[16],
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 124,
      height: 32,
    },
    iconContainer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      marginBottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      pointerEvents: 'none',
    },
    icon: {
      width: 40,
      height: 40,
      color: 'white',
    },
    text: (props) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      userSelect: 'none',
      opacity: props.locked ? 0.6 : 1,
      color: 'white',
      marginTop: 'auto',
    }),
    title: (props) => ({
      fontFamily: 'din-2014',
      color: props.color,
      lineHeight: '30px',
      fontWeight: 700,
      fontSize: 24,
      wordWrap: 'nowrap',
      textAlign: 'center',
    }),
    subtitle: {
      fontFamily: 'din-2014',
      lineHeight: '43px',
      fontSize: 28,
    },
    description: {
      marginTop: theme.spacing(1.5),
    },
    button: (props) => ({
      position: 'absolute',
      left: theme.spacing(3),
      right: theme.spacing(3),
      bottom: theme.spacing(3),
      width: `calc(100% - ${theme.spacing(6)})`,
      color: 'white',
      backgroundColor: props.locked ? green[500] : blue[500],
      '&:hover': {
        backgroundColor: props.locked ? green[700] : blue[700],
      },
    }),
  };
});

const CourseCardSmall = (props) => {
  const { className, elevation, graphic, color, locked, title, ButtonProps } =
    props;

  const styles = useStyles({
    imageUrl: dynamicImageUrl(graphic, {
      resize: {
        width: 360,
        height: 360,
        fit: 'cover',
      },
    }),
    elevation,
    color,
    locked,
  });

  return (
    <ButtonBase
      className={clsx(styles.root, className)}
      component={Link}
      to=""
      {...ButtonProps}
    >
      <div className={styles.logo}>
        <LogoLarge />
      </div>
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
      </div>
      {locked && (
        <div className={styles.iconContainer}>
          <LockIcon className={styles.icon} />
        </div>
      )}
    </ButtonBase>
  );
};

CourseCardSmall.propTypes = {
  elevation: PropTypes.number.isRequired,
  graphic: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  locked: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string,
  }),
  ButtonProps: PropTypes.any,
};

CourseCardSmall.defaultProps = {
  elevation: 2,
  color: '#FFFFFF',
  locked: false,
};

export default CourseCardSmall;
