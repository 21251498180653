import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectSignoutRequest } from 'Redux/selectors';
import { signout } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmSignOutDialog = (props) => {
  const { onClose } = props;
  const { isLoading } = useSelector(selectSignoutRequest);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = useCallback(() => {
    dispatch(signout(null, { isPromise: true }))
      .then(() => {
        onClose && onClose();
        navigate('/signin');
      })
      .catch(noop);
  }, [dispatch, navigate, onClose]);

  return (
    <Dialog
      title="Sign out"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleSignOut}>
          Sign Out
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to sign out of your account?
      </Typography>
    </Dialog>
  );
};

ConfirmSignOutDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ConfirmSignOutDialog;
