import { useCallback, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef();

  const handleReady = useCallback((element) => {
    elementRef.current = element;
  }, []);

  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));

  return <Component onReady={handleReady} {...props} />;
};

StripeInput.propTypes = {
  component: PropTypes.elementType.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default StripeInput;
