import { isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 16,
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'column',
  },
  upper: {
    padding: theme.spacing(4, 2, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },
  iconCircle: {
    height: 80,
    width: 80,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 48,
  },
  buttonContainer: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
  },
  button: {
    width: '62%',
  },
}));

const NavigationCard = (props) => {
  const { className, icon, title, body, button } = props;
  const styles = useStyles();

  const renderedIcon = isValidElement(icon)
    ? cloneElement(icon, {
        className: clsx(icon.props.className, styles.icon),
      })
    : icon;

  const renderedButton = isValidElement(button)
    ? cloneElement(button, {
        className: clsx(button.props.className, styles.button),
      })
    : button;

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.upper}>
        <div className={styles.iconCircle}>{renderedIcon}</div>
        <Typography variant="h5" align="center" className={styles.title}>
          {title}
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary">
          {body}
        </Typography>
      </div>
      <div className={styles.buttonContainer}>{renderedButton}</div>
    </div>
  );
};

NavigationCard.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  button: PropTypes.element.isRequired,
};

export default NavigationCard;
