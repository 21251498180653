import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const FacebookIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M14.25 5.125H18V1h-3.75C11.355 1 9 3.159 9 5.813v2.062H6V12h3v11h4.5V12h3.75L18 7.875h-4.5V5.812c0-.372.344-.687.75-.687z" />
    </SvgIcon>
  );
};

export default FacebookIcon;
