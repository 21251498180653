import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import ensurePrice from 'Util/ensure-price';
import {
  selectAffiliateStats,
  selectCreateAffiliateRedeemRequestRequest,
  selectMe,
} from 'Redux/selectors';
import { createAffiliateRedeemRequest } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmCollectEarningsDialog = (props) => {
  const { onClose } = props;
  const navigate = useNavigate();
  const { isLoading } = useSelector(selectCreateAffiliateRedeemRequestRequest);
  const stats = useSelector(selectAffiliateStats);
  const me = useSelector(selectMe);

  const dispatch = useDispatch();

  const handleCollect = useCallback(() => {
    dispatch(createAffiliateRedeemRequest(null, { isPromise: true }))
      .then(() => {
        navigate('#/withdrawals/0');
      })
      .then(onClose)
      .catch(noop);
  }, [dispatch, navigate, onClose]);

  return (
    <Dialog
      title="Collect Earnings"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleCollect}>
          Collect
        </Button>
      }
    >
      <Typography variant="body1" align="center" gutterBottom>
        You are about to collect your affiliate earnings with a total of{' '}
        <strong>{ensurePrice(stats.pending)}</strong>. The money will be sent to{' '}
        <strong>{me.paypalAccount}</strong> paypal account.
      </Typography>
      <Typography variant="body1" align="center">
        <strong>
          NOTE: you can't change the destination paypal account after you've
          made the request!
        </strong>
      </Typography>
    </Dialog>
  );
};

ConfirmCollectEarningsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ConfirmCollectEarningsDialog;
