import { matchPath, useLocation, useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { toNumber, min, times } from 'lodash';
import { format } from 'date-fns';
import clsx from 'clsx';

import ensurePrice from 'Util/ensure-price';
import {
  selectAffiliateRedeemRequests,
  selectAffiliateRedeemRequestsRequest,
  selectAffiliateRedeemRequestsRowsPerPage,
  selectAffiliateRedeemRequestsTotalCount,
} from 'Redux/selectors';
import { fetchAffiliateRedeemRequests } from 'Redux/actions';
import Scrollable from 'Components/shared/scrollable';
import Highlight from 'Components/shared/highlight';

import { Skeleton } from '@material-ui/lab';
import {
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 648,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    borderRadius: 16,
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
  },
  amount: {
    width: '99%',
    height: 56,
  },
  scrollable: {
    width: '100%',
    padding: 0,
  },
  tableWrapper: {
    minWidth: 648,
    borderRadius: theme.shape.borderRadius,
  },
  table: {
    minWidth: 648,
    '& .MuiTableCell-root': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 200,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '& .MuiTableCell-root:first-child': {
      paddingLeft: theme.spacing(4),
    },
    '& .MuiTableCell-root:last-child': {
      paddingRight: theme.spacing(4),
    },
  },
  tableBody: {
    '&>:last-child td': {
      borderBottom: 0,
    },
  },
  pagination: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(4),
    },
  },
  noData: {
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Redeem = (props) => {
  const { className } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();

  const match = matchPath('/#/withdrawals/:page', '/' + hash);
  const currentPage = match ? toNumber(match.params.page) : 0;

  const requests = useSelector(selectAffiliateRedeemRequests);
  const rowsPerPage = useSelector(selectAffiliateRedeemRequestsRowsPerPage);
  const totalCount = useSelector(selectAffiliateRedeemRequestsTotalCount);

  const expectedCount = totalCount
    ? min([totalCount - rowsPerPage * currentPage, rowsPerPage])
    : 3;

  const { isLoading } = useSelector(selectAffiliateRedeemRequestsRequest);

  const handleChangePage = useCallback(
    (event, value) => navigate('#/withdrawals/' + value),
    [navigate],
  );

  const showSkeletons = isLoading && (!requests || !requests.length);
  const requestsOrSkeleton = showSkeletons
    ? times(expectedCount)
    : requests || [];

  useEffect(() => {
    dispatch(fetchAffiliateRedeemRequests({ page: currentPage }));
  }, [dispatch, currentPage]);

  return (
    <div className={clsx(styles.root, className)}>
      <TableContainer className={styles.container} component="div">
        <Scrollable className={styles.scrollable}>
          <div className={styles.tableWrapper}>
            <Table
              size="small"
              aria-label="table of earnings"
              className={styles.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell className={styles.amount}>
                    <Typography variant="caption" color="textSecondary">
                      Earnings
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption" color="textSecondary">
                      Payout Submitted
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption" color="textSecondary">
                      Payout Received
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption" color="textSecondary">
                      Status
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={styles.tableBody}>
                {requestsOrSkeleton.length
                  ? requestsOrSkeleton.map((request, index) => {
                      if (showSkeletons) {
                        return (
                          <TableRow key={index}>
                            <TableCell colSpan={10}>
                              <Skeleton variant="rect" height={30} />
                            </TableCell>
                          </TableRow>
                        );
                      }

                      const { id, amount, createdAt, closedAt } = request;

                      return (
                        <TableRow key={id}>
                          <TableCell className={styles.amount}>
                            {ensurePrice(amount)}
                          </TableCell>
                          <TableCell align="right">
                            {format(new Date(createdAt), 'MM/d/yyyy')}
                          </TableCell>
                          <TableCell align="right">
                            {closedAt
                              ? format(new Date(closedAt), 'MM/d/yyyy')
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="right">
                            <Highlight
                              size="small"
                              color={closedAt ? 'success' : 'warning'}
                              value={closedAt ? 'Paid' : 'In Review'}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </div>
        </Scrollable>
        {requestsOrSkeleton.length ? (
          <TablePagination
            component="div"
            classes={{ toolbar: styles.pagination }}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
            rowsPerPageOptions={[]}
          />
        ) : (
          <Typography
            className={styles.noData}
            align="center"
            variant="subtitle2"
          >
            No payouts for now
          </Typography>
        )}
      </TableContainer>
    </div>
  );
};

export default Redeem;
