import { put, take, call, race, select } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { fetchUserSuccess, selectUser } from './user';
import {
  uploadFile,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_CANCEL,
  UPLOAD_FILE_ERROR,
} from './upload-file';
import { fetchMeSuccess, selectMe } from './me';

const MODULE = 'upload-profile-picture';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const uploadProfilePicture = Exec.requestActionCreator(MODULE);
export const uploadProfilePictureLoading = Exec.loadingActionCreator(MODULE);
export const uploadProfilePictureSuccess = Exec.successActionCreator(MODULE);
export const uploadProfilePictureError = Exec.errorActionCreator(MODULE);
export const uploadProfilePictureReset = Exec.resetActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUploadProfilePictureRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUploadProfilePicture = function* ({ payload, meta }) {
  const { userId, file } = payload || {};

  const me = yield select(selectMe);
  const user = yield select(selectUser);

  try {
    yield put(uploadProfilePictureLoading());

    const {
      data: { url: signedUrl },
    } = yield call(Api.createSignedUrl, 'avatars', file.type);

    yield put(
      uploadFile({
        method: 'PUT',
        url: signedUrl,
        data: file,
        headers: {
          'Content-Type': file.type,
        },
      }),
    );

    const [, cancel, error] = yield race([
      take(UPLOAD_FILE_SUCCESS),
      take(UPLOAD_FILE_CANCEL),
      take(UPLOAD_FILE_ERROR),
    ]);

    if (error) {
      throw error.payload.error;
    }

    if (cancel) {
      return;
    }

    const url = new URL(signedUrl);
    url.search = '';

    const { data } = userId
      ? yield call(Api.updateUser, userId, { image: { url: url.href } })
      : yield call(Api.updateMe, { image: { url: url.href } });

    if (!userId || (me && me.id === userId)) {
      yield put(fetchMeSuccess({ data }));
    }

    if (user && user.id === userId) {
      yield put(fetchUserSuccess({ data }));
    }

    yield put(uploadProfilePictureSuccess(null, meta));
  } catch (err) {
    yield put(uploadProfilePictureError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUploadProfilePicture);
