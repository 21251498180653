import { useModal } from 'react-modal-hook';

import SubmitToolProblemDialog from 'Dialogs/submit-tool-problem-dialog';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import CourseCard from 'Components/shared/course-card';
import SearchHeader from 'Components/pages/help/search-header';
import HeaderNavigation from 'Components/pages/help/header-navigation';
import Cards from 'Components/pages/help/cards';

import {
  makeStyles,
  useMediaQuery,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';

const headingImage = {
  url: 'https://ecomfreedom-public-assets.s3.amazonaws.com/app/help-center-cover.jpg',
  isResizable: true,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10.5),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(9),
    },
    flexGrow: 1,
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    maxWidth: 768,
    marginBottom: theme.spacing(12),
  },
  item: {
    margin: theme.spacing(0, 0, 4),
  },
  haveAProblem: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
}));

const HelpToolsPage = () => {
  const styles = useStyles();
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const tools = [
    {
      color: '#FF9900',
      graphic: {
        url: 'https://ecomfreedom-public-assets.s3.amazonaws.com/app/amazon-fba-course-cover.jpg',
        isResizable: true,
      },
      locked: false,
      title: 'Amazon FBA',
      subtitle: 'Profit Calculator',
      type: 'CUSTOM',
      description: {
        text: 'Easily calculate how much profit you will make with your product',
      },
      ButtonProps: {
        label: 'Get Help',
        component: 'a',
        href: 'https://calculator.ecomfreedom.com/faq',
      },
    },
    {
      color: '#FFFFFF',
      graphic: {
        url: 'https://ecomfreedom-public-assets.s3.amazonaws.com/app/shopify-course-cover.jpg',
        isResizable: true,
      },
      locked: false,
      title: 'Ecom Radar',
      description: {
        text: 'The #1 tool for finding profitable eCommerce products to sell',
      },
      ButtonProps: {
        label: 'Get Help',
        component: 'a',
        href: 'https://chrome.google.com/webstore/detail/ecom-radar/dnnjlaebcepcagnefdgodliagfhbebba?hl=en',
      },
    },
  ];

  const [showSubmitModal, hideSubmitModal] = useModal(() => (
    <SubmitToolProblemDialog onClose={hideSubmitModal} />
  ));

  return (
    <PageLoader>
      <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
        <SearchHeader image={headingImage}>
          <HeaderNavigation variant="search" option="tools" />
        </SearchHeader>
        <PageContainer component="section" className={styles.root}>
          <div className={styles.group}>
            <Grid
              container
              spacing={isLgUp ? 6 : isMdUp ? 3 : 2}
              justify="center"
            >
              {tools.map((community, index) => (
                <Grid
                  key={index}
                  className={styles.item}
                  container
                  item
                  xs={12}
                  sm={6}
                  justify="center"
                  alignItems="center"
                >
                  <CourseCard elevation={8} {...community} />
                </Grid>
              ))}
            </Grid>
          </div>
          <Typography className={styles.haveAProblem} variant="h4">
            Have a Problem?
          </Typography>
          <Button
            className={styles.explainButton}
            variant="contained"
            onClick={showSubmitModal}
          >
            Explain The Issue
          </Button>
        </PageContainer>
        <Cards />
      </PageLayout>
    </PageLoader>
  );
};

export default HelpToolsPage;
