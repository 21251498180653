import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { selectEventVideo, selectUpdateEventRequest } from 'Redux/selectors';
import { updateEvent } from 'Redux/actions';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}));

const AddEventRecordingDialog = (props) => {
  const { onClose, eventId } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const video = useSelector(selectEventVideo);
  const { isLoading } = useSelector(selectUpdateEventRequest);

  const { control, handleSubmit } = useForm({
    defaultValues: video ? { recording: { url: video.url } } : {},
  });

  const handleUpdate = useCallback(
    (data) => {
      dispatch(updateEvent({ ...data, eventId }, { isPromise: true })).then(
        onClose,
      );
    },
    [dispatch, eventId, onClose],
  );

  return (
    <Dialog
      title="Add Recording"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleUpdate)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">Save</Button>}
    >
      <Input
        data-testid="recording.url"
        autoFocus
        className={styles.input}
        control={control}
        label="Wistia Recording Url"
        name="recording.url"
      />
    </Dialog>
  );
};

AddEventRecordingDialog.propTypes = {
  eventId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddEventRecordingDialog;
