import {
  makeStyles,
  StepConnector as MuiStepConnector,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  alternativeLabel: {
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)',
  },
  active: (props) => ({
    '& .MuiStepConnector-line': {
      height: 3,
      position: 'relative',
      backgroundColor: theme.palette.grey[300],

      '&:after': {
        content: '" "',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.primary.main,
        height: '100%',
        width: props.progress + '%',
      },
    },
  }),
  completed: {
    '& .MuiStepConnector-line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey[300],
  },
}));

const StepConnector = (props) => {
  const styles = useStyles(props);
  return <MuiStepConnector classes={styles} {...props} />;
};

export default StepConnector;
