import { combineReducers } from 'redux';

import { reducer as webhooks } from './modules/webhooks';
import { reducer as verifyUser } from './modules/verify-user';
import { reducer as users } from './modules/users';
import { reducer as userStats } from './modules/user-stats';
import { reducer as user } from './modules/user';
import { reducer as uploadProfilePicture } from './modules/upload-profile-picture';
import { reducer as uploadLessonVideo } from './modules/upload-lesson-video';
import { reducer as uploadLessonBoxsetPdf } from './modules/upload-lesson-boxset-pdf';
import { reducer as uploadFile } from './modules/upload-file';
import { reducer as uploadEventRecording } from './modules/upload-event-recording';
import { reducer as uploadCourseGraphic } from './modules/upload-course-graphic';
import { reducer as uploadCourseBackground } from './modules/upload-course-background';
import { reducer as uploadAchievementLevelPicture } from './modules/upload-achievement-level-picture';
import { reducer as uploadAchievementGraphic } from './modules/upload-achievement-graphic';
import { reducer as upgradeCourseTrial } from './modules/upgrade-course-trial';
import { reducer as upgradeCourseMentorship } from './modules/upgrade-course-mentorship';
import { reducer as updateWebhook } from './modules/update-webhook';
import { reducer as updateUser } from './modules/update-user';
import { reducer as updateResource } from './modules/update-resource';
import { reducer as updateQuestion } from './modules/update-question';
import { reducer as updatePaymentLink } from './modules/update-payment-link';
import { reducer as updateOffer } from './modules/update-offer';
import { reducer as updateModule } from './modules/update-module';
import { reducer as updateMe } from './modules/update-me';
import { reducer as updateLesson } from './modules/update-lesson';
import { reducer as updateEvent } from './modules/update-event';
import { reducer as updateCourse } from './modules/update-course';
import { reducer as updateAchievementLevel } from './modules/update-achievement-level';
import { reducer as updateAchievement } from './modules/update-achievement';
import { reducer as trials } from './modules/trials';
import { reducer as transactions } from './modules/transactions';
import { reducer as topics } from './modules/topics';
import { reducer as suspendUser } from './modules/suspend-user';
import { reducer as subscriptionSteps } from './modules/subscription-steps';
import { reducer as subscribeToList } from './modules/subscribe-to-list';
import { reducer as submitToolProblem } from './modules/submit-tool-problem';
import { reducer as submitAchievement } from './modules/submit-achievement';
import { reducer as sortModules } from './modules/sort-modules';
import { reducer as sortLessons } from './modules/sort-lessons';
import { reducer as sortLessonResources } from './modules/sort-lesson-resources';
import { reducer as sortLessonQuestions } from './modules/sort-lesson-questions';
import { reducer as sortCourses } from './modules/sort-courses';
import { reducer as sortAchievements } from './modules/sort-achievements';
import { reducer as snackbar } from './modules/snackbar';
import { reducer as signout } from './modules/signout';
import { reducer as signin } from './modules/signin';
import { reducer as setDefaultPaymentMethod } from './modules/set-default-payment-method';
import { reducer as search } from './modules/search';
import { reducer as roles } from './modules/roles';
import { reducer as revokeUserSuspension } from './modules/revoke-user-suspension';
import { reducer as restoreOffer } from './modules/restore-offer';
import { reducer as resources } from './modules/resources';
import { reducer as resetPassword } from './modules/reset-password';
import { reducer as rememberMe } from './modules/remember-me';
import { reducer as register } from './modules/register';
import { reducer as refundTransactions } from './modules/refund-transactions';
import { reducer as recordLessonProgress } from './modules/record-lesson-progress';
import { reducer as questions } from './modules/questions';
import { reducer as paymentMethods } from './modules/payment-methods';
import { reducer as paymentLinks } from './modules/payment-links';
import { reducer as paymentLink } from './modules/payment-link';
import { reducer as pageSuspended } from './modules/page-suspended';
import { reducer as onboardingPostponedAt } from './modules/onboarding-postponed-at';
import { reducer as offers } from './modules/offers';
import { reducer as modules } from './modules/modules';
import { reducer as me } from './modules/me';
import { reducer as lessons } from './modules/lessons';
import { reducer as lessonVideo } from './modules/lesson-video';
import { reducer as lastLesson } from './modules/last-lesson';
import { reducer as events } from './modules/events';
import { reducer as eventVideo } from './modules/event-video';
import { reducer as eventAttendees } from './modules/event-attendees';
import { reducer as event } from './modules/event';
import { reducer as deleteUser } from './modules/delete-user';
import { reducer as deleteProfilePicture } from './modules/delete-profile-picture';
import { reducer as deletePaymentMethod } from './modules/delete-payment-method';
import { reducer as deletePaymentLink } from './modules/delete-payment-link';
import { reducer as deleteEvent } from './modules/delete-event';
import { reducer as deleteAchievementLevelPicture } from './modules/delete-achievement-level-picture';
import { reducer as createWebhook } from './modules/create-webhook';
import { reducer as createUser } from './modules/create-user';
import { reducer as createTopic } from './modules/create-topic';
import { reducer as createResource } from './modules/create-resource';
import { reducer as createQuestion } from './modules/create-question';
import { reducer as createPaymentMethod } from './modules/create-payment-method';
import { reducer as createPaymentLink } from './modules/create-payment-link';
import { reducer as createPayment } from './modules/create-payment';
import { reducer as createOffer } from './modules/create-offer';
import { reducer as createModule } from './modules/create-module';
import { reducer as createLesson } from './modules/create-lesson';
import { reducer as createEvent } from './modules/create-event';
import { reducer as createCourseBackup } from './modules/create-course-backup';
import { reducer as createCourse } from './modules/create-course';
import { reducer as createAffiliateRedeemRequest } from './modules/create-affiliate-redeem-request';
import { reducer as createAchievement } from './modules/create-achievement';
import { reducer as courses } from './modules/courses';
import { reducer as confirmRegister } from './modules/confirm-register';
import { reducer as confirmEmailChange } from './modules/confirm-email-change';
import { reducer as confirmBoxsetShippingAddressPostponedAt } from './modules/confirm-boxset-shipping-address-postponed-at';
import { reducer as confirmBoxsetShippingAddress } from './modules/confirm-boxset-shipping-address';
import { reducer as changePassword } from './modules/change-password';
import { reducer as cancelTrials } from './modules/cancel-trials';
import { reducer as archiveOffer } from './modules/archive-offer';
import { reducer as affiliateStats } from './modules/affiliate-stats';
import { reducer as affiliateRedeemRequests } from './modules/affiliate-redeem-requests';
import { reducer as affiliateEarnings } from './modules/affiliate-earnings';
import { reducer as activeCampaignLists } from './modules/active-campaign-lists';
import { reducer as achievements } from './modules/achievements';
import { reducer as achievementStats } from './modules/achievement-stats';
import { reducer as achievementLevels } from './modules/achievement-levels';

const reducers = (asyncReducers) => {
  return combineReducers({
    achievementStats,
    achievementLevels,
    achievements,
    activeCampaignLists,
    affiliateEarnings,
    affiliateRedeemRequests,
    affiliateStats,
    archiveOffer,
    courses,
    confirmBoxsetShippingAddressPostponedAt,
    confirmBoxsetShippingAddress,
    confirmEmailChange,
    changePassword,
    cancelTrials,
    confirmRegister,
    createWebhook,
    createUser,
    createTopic,
    createQuestion,
    createResource,
    createPaymentMethod,
    createPaymentLink,
    createPayment,
    createModule,
    createLesson,
    createEvent,
    createCourseBackup,
    createCourse,
    createOffer,
    createAffiliateRedeemRequest,
    createAchievement,
    deleteProfilePicture,
    deletePaymentMethod,
    deletePaymentLink,
    deleteEvent,
    deleteUser,
    deleteAchievementLevelPicture,
    eventAttendees,
    eventVideo,
    event,
    events,
    lastLesson,
    lessonVideo,
    lessons,
    me,
    modules,
    paymentMethods,
    questions,
    pageSuspended,
    onboardingPostponedAt,
    offers,
    paymentLinks,
    paymentLink,
    recordLessonProgress,
    refundTransactions,
    register,
    rememberMe,
    resetPassword,
    resources,
    restoreOffer,
    revokeUserSuspension,
    roles,
    search,
    setDefaultPaymentMethod,
    signin,
    signout,
    snackbar,
    submitAchievement,
    sortAchievements,
    sortCourses,
    sortLessons,
    sortLessonResources,
    sortLessonQuestions,
    sortModules,
    submitToolProblem,
    subscribeToList,
    subscriptionSteps,
    suspendUser,
    trials,
    transactions,
    topics,
    updateAchievement,
    updateAchievementLevel,
    updateCourse,
    updateEvent,
    updateLesson,
    updateMe,
    updateModule,
    updateOffer,
    updateResource,
    updateQuestion,
    updatePaymentLink,
    updateUser,
    updateWebhook,
    upgradeCourseTrial,
    upgradeCourseMentorship,
    uploadEventRecording,
    uploadCourseGraphic,
    uploadCourseBackground,
    uploadFile,
    uploadProfilePicture,
    uploadLessonVideo,
    uploadLessonBoxsetPdf,
    uploadAchievementLevelPicture,
    uploadAchievementGraphic,
    user,
    userStats,
    users,
    verifyUser,
    webhooks,
    ...asyncReducers,
  });
};

export default reducers;
