import { useParams, useNavigate, Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { camelCase, findIndex } from 'lodash';
import clsx from 'clsx';

import {
  selectCourses,
  selectCoursesRequest,
  selectEventsRequest,
  selectMeRequest,
  selectPastEvents,
  selectSubscriptionStepsRequest,
  selectUpcomingEvents,
} from 'Redux/selectors';
import {
  fetchCourses,
  fetchEvents,
  fetchSubscriptionSteps,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useLocalStorageState from 'Hooks/use-local-storage-state';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import UnderlineButton from 'Components/shared/underline-button';
import StickyTabs from 'Components/shared/sticky-tabs';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import Divider from 'Components/shared/divider';
import HelpVideo from 'Components/pages/mentorship-calls/help-video';
import Card from 'Components/pages/mentorship-calls/card';

import { makeStyles, useMediaQuery, Typography, Tab } from '@material-ui/core';

const tutorialVideo = {
  url: 'https://support.wistia.com/medias/26sk4lmiix',
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  moduleContainer: {
    padding: theme.spacing(10, 0),
  },
  divider: {
    margin: theme.spacing(2, 0, 4),
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 768,
    marginBottom: theme.spacing(5),
  },
  cardsGap: {
    marginBottom: theme.spacing(10),
  },
  card: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  tabs: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      top: 79,
    },
  },
  helpVideo: {
    width: '100%',
    marginBottom: theme.spacing(10),
  },
}));

const MentorshipCallsPage = () => {
  const styles = useStyles();
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();

  const { isLoading, error } = useCombinedRequestsSelector(
    selectMeRequest,
    selectCoursesRequest,
    selectEventsRequest,
    selectSubscriptionStepsRequest,
  );

  const courses = useSelector(selectCourses);
  const upcomingEvents = useSelector(selectUpcomingEvents) || [];
  const pastEvents = useSelector(selectPastEvents) || [];

  const [showHelpVideo, setShowHelpVideo] = useLocalStorageState(
    'mentorship-calls-help-video',
    true,
  );

  const groups = useMemo(
    () =>
      courses
        ? courses
            .filter((c) => Boolean(c.hasMentorshipCalls))
            .concat([{ title: 'Dan Vas', slug: 'dan-vas' }])
        : [],
    [courses],
  );

  const currentGroupIndex = findIndex(groups, ['slug', slug]);
  const group = currentGroupIndex !== -1 ? groups[currentGroupIndex] : null;

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchSubscriptionSteps());
    if (slug) {
      dispatch(
        fetchEvents({ courseIdOrMentorSlug: slug, type: 'mentorship-call' }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, dispatch, navigate]);

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const handleTabChange = useCallback(
    (event, value) => navigate(`/mentorship-calls/${groups[value].slug}`),
    [groups, navigate],
  );

  const handleCloseHelpVideo = useCallback(
    () => setShowHelpVideo(false),
    [setShowHelpVideo],
  );

  if (!slug && groups && groups.length) {
    return <Navigate replace to={`/mentorship-calls/${groups[0].slug}`} />;
  }

  if (!isLoading && slug && !group) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading || !slug} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <PageContainer>
            <Typography className={styles.title} variant="h4" align="center">
              Mentorship Calls
            </Typography>
            <StickyTabs
              variant="scrollable"
              scrollButtons={isSmUp ? 'off' : 'on'}
              className={styles.tabs}
              value={currentGroupIndex < 0 ? 0 : currentGroupIndex}
              onChange={handleTabChange}
            >
              {groups.map((course) => (
                <Tab
                  data-testid={camelCase(course.title + 'Tab')}
                  key={course.slug}
                  label={course.title}
                />
              ))}
            </StickyTabs>
            {false && showHelpVideo && (
              <HelpVideo
                data-testid="helpVideo"
                className={styles.helpVideo}
                video={tutorialVideo}
                onClose={handleCloseHelpVideo}
              />
            )}
            {upcomingEvents.length ? (
              <>
                <Typography variant="h5" align="center">
                  Next Mentorship Call
                </Typography>
                <Divider className={styles.divider} />
                <div
                  className={clsx(
                    styles.cards,
                    pastEvents.length ? styles.cardsGap : null,
                  )}
                >
                  <Card
                    data-testid="eventCardUpcoming"
                    className={styles.card}
                    {...upcomingEvents[0]}
                  />
                </div>
              </>
            ) : null}
            {pastEvents.length ? (
              <>
                <Typography variant="h5" align="center">
                  Previous Mentorship Calls
                </Typography>
                <Divider className={styles.divider} />
                <div className={styles.cards}>
                  {pastEvents.map((event, index) => (
                    <Card
                      key={event.id}
                      data-testid={`eventCardPrevious${index}`}
                      className={styles.card}
                      {...event}
                    />
                  ))}
                </div>
              </>
            ) : null}
            <UnderlineButton
              size="large"
              component={Link}
              to={`/mentorship-calls/${slug}/${currentYear}/${currentMonth}`}
              data-testid="viewAll"
            >
              View All Mentorship Calls
            </UnderlineButton>
          </PageContainer>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default MentorshipCallsPage;
