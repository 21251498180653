import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { selectLessonVideo, selectUpdateLessonRequest } from 'Redux/selectors';
import { updateLesson } from 'Redux/actions';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}));

const PasteLessonVideoUrlDialog = (props) => {
  const { onClose, courseId, moduleId, lessonId } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const video = useSelector(selectLessonVideo);
  const { isLoading } = useSelector(selectUpdateLessonRequest);

  const { control, handleSubmit } = useForm({
    defaultValues: video ? { recording: { url: video.url } } : {},
  });

  const handleUpdate = useCallback(
    (data) => {
      dispatch(
        updateLesson(
          { courseId, moduleId, lessonId, ...data },
          { isPromise: true },
        ),
      ).then(onClose);
    },
    [courseId, lessonId, moduleId, onClose, dispatch],
  );

  return (
    <Dialog
      title="Paste Wistia Link"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleUpdate)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">Save</Button>}
    >
      <Input
        data-testid="video.url"
        autoFocus
        className={styles.input}
        control={control}
        label="Wistia Url"
        name="video.url"
      />
    </Dialog>
  );
};

PasteLessonVideoUrlDialog.propTypes = {
  courseId: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
  lessonId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PasteLessonVideoUrlDialog;
