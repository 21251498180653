import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'create-user';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CREATE_USER_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const createUser = Exec.requestActionCreator(MODULE);
export const createUserLoading = Exec.loadingActionCreator(MODULE);
export const createUserSuccess = Exec.successActionCreator(MODULE);
export const createUserError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCreateUserRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doCreateUser = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(CREATE_USER_REQUEST));
    yield put(createUserLoading());
    const { data } = yield call(Api.createUser, payload);
    yield put(createUserSuccess({ data }, meta));
  } catch (err) {
    yield put(createUserError(err, meta));
    yield put(
      enqueueSnackbar(CREATE_USER_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCreateUser);
