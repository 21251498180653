import clsx from 'clsx';

import { makeStyles, Tabs } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[2],
    padding: theme.spacing(0, 1.5),
    borderRadius: 16,
    [theme.breakpoints.down('xs')]: {
      position: 'sticky',
      zIndex: 1099,
      padding: 0,
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      width: `calc(100% + ${theme.spacing(2)}px)`,
      borderRadius: 0,
    },
  },
}));

const StickyTabs = (props) => {
  const { className, ...rest } = props;
  const styles = useStyles();

  return (
    <Tabs
      className={clsx(className, styles.root)}
      textColor="primary"
      indicatorColor="primary"
      {...rest}
    />
  );
};

export default StickyTabs;
