import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';

import { selectCourses, selectCoursesRequest } from 'Redux/selectors';
import { fetchCourses } from 'Redux/actions';
import ToolIcon from 'Icons/tool';
import FaqIcon from 'Icons/faq';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import CourseHeader from 'Components/shared/course-header';
import HeaderNavigation from 'Components/pages/help/header-navigation';
import Cards from 'Components/pages/help/cards';

import { makeStyles, ButtonBase, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(10),
      paddingTop: theme.spacing(10),
    },
  },
  cards: {
    maxWidth: 804,
    width: '100%',
    display: 'grid',
    gap: theme.spacing(6) + 'px',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  card: {
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    maxWidth: 378,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(3.5, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    margin: 'auto',
  },
  iconCircle: {
    width: 80,
    height: 80,
    backgroundColor: '#009DDC',
    marginBottom: theme.spacing(3),
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 48,
  },
}));

const HelpCoursesPage = () => {
  const { courseSlug } = useParams();
  const styles = useStyles();
  const dispatch = useDispatch();

  const { isLoading, error } = useSelector(selectCoursesRequest);
  const courses = useSelector(selectCourses);

  const course = find(courses, ['slug', courseSlug]);

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cards = [
    {
      label: 'Resource Vault',
      icon: <ToolIcon className={styles.icon} />,
      to: `/help/resource-vault/${courseSlug}`,
    },
    {
      label: 'FAQ',
      icon: <FaqIcon className={styles.icon} />,
      to: `/help/faq/${courseSlug}`,
    },
  ];

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <CourseHeader course={course}>
            <HeaderNavigation variant="course" option="courses" />
          </CourseHeader>
          <PageContainer component="section" className={styles.root}>
            <div className={styles.cards}>
              {cards.map(({ label, icon, to }) => (
                <ButtonBase
                  key={to}
                  component={Link}
                  to={to}
                  className={styles.card}
                >
                  <div className={styles.iconCircle}>{icon}</div>
                  <Typography variant="h5" align="center">
                    {label}
                  </Typography>
                </ButtonBase>
              ))}
            </div>
          </PageContainer>
          <Cards />
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default HelpCoursesPage;
