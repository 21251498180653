import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { selectUpdateUserRequest, selectUser } from 'Redux/selectors';
import { enqueueSnackbar, updateUser } from 'Redux/actions';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button, Typography } from '@material-ui/core';

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Too Short.')
    .notOneOf([yup.ref('oldPassword')], 'Please create new password.')
    .required('Field Required.'),
  confirmPassword: yup
    .string()
    .required('Field Required.')
    .oneOf([yup.ref('password'), null], 'Password Mismatch.'),
});

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

const UpdateUserPasswordDialog = (props) => {
  const { onClose } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const { id: userId } = useSelector(selectUser);
  const { isLoading } = useSelector(selectUpdateUserRequest);

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
  });

  const handleUpdate = useCallback(
    (data) => {
      dispatch(updateUser({ userId, ...data }, { isPromise: true }))
        .then(() => {
          dispatch(
            enqueueSnackbar('UPDATE_USER', {
              variant: 'success',
              message: 'User password changed',
            }),
          );
          onClose && onClose();
        })
        .catch(() => {});
    },
    [dispatch, onClose, userId],
  );

  return (
    <Dialog
      title="Reset Password"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleUpdate)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">Reset</Button>}
    >
      <Typography variant="body1" align="center" gutterBottom>
        Are you sure you want to reset this user's password? They will not be
        able to access their account with their old password. This change is
        permanent!
      </Typography>
      <Input
        data-testid="password"
        className={styles.input}
        control={control}
        type="password"
        name="password"
        label="New Password"
        errorText={get(errors, 'password.message')}
      />
      <Input
        data-testid="confirmPassword"
        className={styles.input}
        control={control}
        type="password"
        name="confirmPassword"
        label="Confirm New Password"
        errorText={get(errors, 'confirmPassword.message')}
      />
    </Dialog>
  );
};

UpdateUserPasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UpdateUserPasswordDialog;
