import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'create-webhook';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CREATE_WEBHOOK_REQUEST = Exec.requestConstantCreator(MODULE);
export const CREATE_WEBHOOK_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const createWebhook = Exec.requestActionCreator(MODULE);
export const createWebhookLoading = Exec.loadingActionCreator(MODULE);
export const createWebhookSuccess = Exec.successActionCreator(MODULE);
export const createWebhookError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCreateWebhookRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doCreateWebhook = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(CREATE_WEBHOOK_REQUEST));
    yield put(createWebhookLoading());
    const { data } = yield call(Api.createWebhook, payload);
    yield put(createWebhookSuccess({ data }, meta));
  } catch (err) {
    yield put(createWebhookError(err, meta));
    yield put(
      enqueueSnackbar(CREATE_WEBHOOK_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCreateWebhook);
