import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { memo, useCallback } from 'react';
import { get, find, memoize } from 'lodash';

import { selectCourses } from 'Redux/selectors';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const memoizedCreateMuiTheme = memoize((color, theme) =>
  createMuiTheme({
    ...theme,
    palette: {
      ...theme.palette,
      secondary: {
        main: color,
        contrastText: '#FFF',
      },
    },
  }),
);

const CourseThemeProvider = (props) => {
  const { children } = props;
  const { pathname } = useLocation();

  const parts = pathname.split('/');

  const courses = useSelector(selectCourses);
  const color = get(
    find(courses, (course) => parts.includes(course.slug)),
    'color',
  );

  const createTheme = useCallback(
    (theme) => memoizedCreateMuiTheme(color, theme),
    [color],
  );

  if (!color) {
    return children;
  }

  return <ThemeProvider theme={createTheme}>{children}</ThemeProvider>;
};

CourseThemeProvider.whyDidYouRender = true;

export default memo(CourseThemeProvider);
