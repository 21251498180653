import qs from 'qs';

import instance from 'Api/instance';

const { REACT_APP_OAUTH_CLIENT_ID } = process.env;

export const signin = (username, password, sessionType = 'session_short') =>
  instance.post(
    '/oauth/token',
    qs.stringify({
      username,
      password,
      session_type: sessionType,
      grant_type: 'password',
      client_id: REACT_APP_OAUTH_CLIENT_ID,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
    },
  );

export const refreshToken = (() => {
  let promise = null;
  return (sessionType = 'session_short') =>
    promise ||
    (promise = instance
      .post(
        '/oauth/token',
        qs.stringify({
          session_type: sessionType,
          grant_type: 'refresh_token',
          client_id: REACT_APP_OAUTH_CLIENT_ID,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
          },
        },
      )
      .finally(() => (promise = null)));
})();

export const signout = () => instance.post('/oauth/revoke');
