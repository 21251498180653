import { memo } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: 80,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
}));

const Divider = (props) => {
  const { className } = props;
  const styles = useStyles();

  return <div className={clsx(styles.divider, className)} />;
};

export default memo(Divider);
