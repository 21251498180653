import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';

import {
  selectAchievementLevels,
  selectCourses,
  selectMe,
} from 'Redux/selectors';
import ToolIcon from 'Icons/tool';
import SupportIcon from 'Icons/support';
import HelpCenterIcon from 'Icons/help-center';
import FacebookIcon from 'Icons/facebook';
import ConstructionIcon from 'Icons/construction';
import CalculateIcon from 'Icons/calculate';

import ConfirmSignOutDialog from 'Dialogs/confirm-sign-out-dialog';
import {
  Apps as AppsIcon,
  AccountCircle as AccountCircleIcon,
  QuestionAnswer as QuestionAnswerIcon,
  HelpOutline as HelpOutlineIcon,
  Explore as ExploreIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Sports as SportsIcon,
  Face as FaceIcon,
  EmojiEvents as EmojiEventsIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
  Dashboard as DashboardIcon,
  GroupAdd as GroupAddIcon,
} from '@material-ui/icons';
import { List } from '@material-ui/core';

import DrawerItem from './drawer-item';

const DrawerItems = (props) => {
  const { className } = props;

  const me = useSelector(selectMe);
  const courses = useSelector(selectCourses);
  const levels = useSelector(selectAchievementLevels);

  const [showSignoutModal, hideSignoutModal] = useModal(() => (
    <ConfirmSignOutDialog onClose={hideSignoutModal} />
  ));

  const coursesWithResourceVault = courses
    ? courses
        .filter(
          (c) => c.purchasedAt && ['amazon-fba', 'shopify'].includes(c.slug),
        )
        .map((c) => c.slug)
    : [];

  const coursesWithMentorship = courses
    ? courses.filter((c) => c.isMentorshipEnabled)
    : [];

  const coursesWithAffiliateLinks = courses
    ? courses.filter((c) => c.supportsAffiliateLinks)
    : [];

  return (
    <List className={className}>
      <DrawerItem
        component={Link}
        to="/"
        text="Courses"
        icon={AppsIcon}
        data-testid="products"
      />
      {coursesWithResourceVault.length ? (
        <DrawerItem
          component={Link}
          to={`/help/resource-vault/${coursesWithResourceVault[0]}`}
          text="Resources"
          icon={ToolIcon}
          data-testid="resources"
        />
      ) : null}
      {coursesWithMentorship.length ? (
        <DrawerItem
          text="Mentorship"
          icon={QuestionAnswerIcon}
          data-testid="community"
          items={
            <>
              <DrawerItem
                component={Link}
                to="/help/mentorship"
                text="How To Get Mentorship"
                icon={SupervisorAccountIcon}
                data-testid="getMentorship"
              />
              <DrawerItem
                component={Link}
                to="/facebook-groups"
                text="Facebook groups"
                icon={FacebookIcon}
                data-testid="facebookGroups"
              />
              {me?.enrolledCourseIds?.length ? (
                <DrawerItem
                  component={Link}
                  to="/mentorship-calls"
                  text="Live Mentorship Calls"
                  icon={SportsIcon}
                  data-testid="liveCalls"
                />
              ) : null}
            </>
          }
        />
      ) : null}
      {!process.env.REACT_APP_DISABLE_TOOLS ? (
        <DrawerItem
          text="Tools"
          icon={ConstructionIcon}
          data-testid="tools"
          items={
            <>
              <DrawerItem
                component="a"
                href="https://www.ecomfreedom.com/profitcalculator"
                text="Profit Calculator"
                icon={CalculateIcon}
                data-testid="profitCalculator"
              />
              <DrawerItem
                component="a"
                href="https://www.ecomfreedom.com/ecomradar"
                text="Ecom Radar"
                icon={ExploreIcon}
                data-testid="ecomRadar"
              />
            </>
          }
        />
      ) : null}
      {me?.enrolledCourseIds?.length && coursesWithAffiliateLinks.length ? (
        <DrawerItem
          component={Link}
          to="/referrals"
          text="Refer A Friend"
          icon={GroupAddIcon}
          data-testid="referAFriend"
        />
      ) : null}
      {!process.env.REACT_APP_DISABLE_SUPPORT ? (
        <DrawerItem
          text="Support"
          icon={HelpOutlineIcon}
          data-testid="support"
          items={
            <>
              <DrawerItem
                component={Link}
                to="/get-help"
                text="How to get help"
                icon={SupportIcon}
                data-testid="getHelp"
              />
              <DrawerItem
                component={Link}
                to="/help"
                text="Help Center"
                icon={HelpCenterIcon}
                data-testid="help"
              />
            </>
          }
        />
      ) : null}
      <DrawerItem
        text="Account"
        icon={AccountCircleIcon}
        data-testid="account"
        items={
          <>
            <DrawerItem
              component={Link}
              to="/profile"
              text="Your profile"
              icon={FaceIcon}
              data-testid="profile"
            />
            {levels && levels.length ? (
              <DrawerItem
                component={Link}
                to="/achievements"
                text="Your rewards"
                icon={EmojiEventsIcon}
                data-testid="rewards"
              />
            ) : null}
            <DrawerItem
              component={Link}
              to="/account"
              text="Settings"
              icon={SettingsIcon}
              data-testid="settings"
            />
            <DrawerItem
              onClick={showSignoutModal}
              text="Sign out"
              icon={ExitToAppIcon}
              data-testid="signOut"
            />
            {me && me.roleId === 'ROLE_ADMIN' && (
              <DrawerItem
                component={Link}
                to="/admin"
                data-testid="admin"
                text="Admin"
                icon={DashboardIcon}
              />
            )}
          </>
        }
      />
    </List>
  );
};

export default DrawerItems;
