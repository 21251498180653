import { unformat, formatMoney } from 'accounting';

const symbols = {
  US: '$',
  CA: '$',
  DE: '€',
  FR: '€',
  SP: '€',
  GB: '£',
  IT: '€',
};

const ensurePrice = (
  value,
  {
    country = 'US',
    compact = true,
    compactBreakpoint = 1000,
    decimals = 2,
  } = {},
) => {
  const symbol = symbols[country] || '$';
  const raw = unformat(value);
  const groupPoint =
    raw >= compactBreakpoint && compact ? (raw >= 1000000 ? 1000000 : 1000) : 0;
  const groupMark = { 1000: 'K', 1000000: 'M' }[groupPoint] || '';

  const formatted = formatMoney(
    groupPoint ? raw / groupPoint : raw,
    symbol,
    decimals,
  );
  const fixed = formatted === `${symbol}-0.00` ? `${symbol}0.00` : formatted;
  const final = fixed + groupMark;

  return final;
};

export default ensurePrice;
