import { put, call, select } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import { Link } from 'react-router-dom';

import * as Exec from 'Redux/creators';
import SnackbarAction from 'Components/shared/snackbar-action';
import * as Api from 'Api';

import { Button } from '@material-ui/core';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { selectRememberMeAsSessionType } from './remember-me';
import { fetchMeSuccess } from './me';
import { fetchAchievementLevels } from './achievement-levels';

const MODULE = 'confirm-register';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CONFIRM_REGISTER_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const confirmRegister = Exec.requestActionCreator(MODULE);
export const confirmRegisterLoading = Exec.loadingActionCreator(MODULE);
export const confirmRegisterSuccess = Exec.successActionCreator(MODULE);
export const confirmRegisterError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectConfirmRegisterRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const renderTryAgain = (id) => (
  <SnackbarAction
    id={id}
    render={
      <Button color="inherit" component={Link} to="/register">
        TRY AGAIN
      </Button>
    }
  />
);
const doConfirmRegister = function* ({ payload, meta }) {
  try {
    const sessionType = yield select(selectRememberMeAsSessionType);
    yield put(closeSnackbar(CONFIRM_REGISTER_REQUEST));
    yield put(confirmRegisterLoading());
    yield call(Api.verifyEmail, payload.code, sessionType);
    const { data } = yield call(Api.getMe);
    yield put(fetchAchievementLevels());
    yield put(
      batchActions([
        Exec.resetGlobalFetch(),
        fetchMeSuccess({ data }),
        confirmRegisterSuccess(null, meta),
      ]),
    );
  } catch (err) {
    yield put(confirmRegisterError(err, meta));
    yield put(
      enqueueSnackbar(CONFIRM_REGISTER_REQUEST, {
        message:
          err.code === 'ERROR_CODE_VERIFY_TOKEN_INVALID'
            ? 'The code you used is no longer valid'
            : err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : 'There was an error, try again later',
        variant: 'error',
        action:
          err.code === 'ERROR_CODE_VERIFY_TOKEN_INVALID'
            ? renderTryAgain
            : null,
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doConfirmRegister);
