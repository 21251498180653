import { useModal } from 'react-modal-hook';
import Dotdotdot from 'react-dotdotdot';
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { noop, floor } from 'lodash';
import clsx from 'clsx';
import dynamicImageUrl from 'Util/dynamic-image-url';

import PendingIcon from 'Icons/pending';
import useHover from 'Hooks/use-hover';
import SubmitAchievementPictureDialog from 'Dialogs/submit-achievement-picture-dialog';

import {
  CheckCircle as CheckCircleIcon,
  Add as AddIcon,
  Lock as LockIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';
import { green, red, grey, yellow } from '@material-ui/core/colors';
import {
  makeStyles,
  Button,
  ButtonBase,
  Paper,
  Typography,
  fade,
} from '@material-ui/core';

const numberWithCommas = (x = 0) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: 'relative',
    height: 304,
    userSelect: 'none',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(3.5, 2),
    cursor: props.status === 'STATUS_NONE' ? 'pointer' : 'initial',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: 268,
    },
  }),
  rootUnlocked: {
    '&:before': {
      borderRadius: theme.shape.borderRadius,
      content: "' '",
      position: 'absolute',
      zIndex: 2,
      bottom: 0,
      width: 268,
      height: 304,
      opacity: 0,
      left: 0,
      right: 0,
      transition: 'opacity 0.2s ease-in-out',
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover': {
      // to deal with the glitch when we add carousel
      overflow: 'hidden',
    },
    '&:hover:before': {
      opacity: 1,
    },
    '&:hover $graphic': {
      transform: 'scale(0.7, 0.7)',
      opacity: 0.1,
    },
    '&:hover $earnCount': {
      marginTop: theme.spacing(-3.5),
      marginBottom: theme.spacing(0.75),
      transform: 'scale(1, 0)',
      opacity: 0,
    },
    '&:hover $title': {
      color: 'white',
      marginBottom: theme.spacing(1),
    },
    '&:hover $nextSteps': {
      marginBottom: theme.spacing(1),
      transform: 'scale(1, 1)',
      opacity: 1,
    },
    '&:hover $content': {
      color: 'white',
    },
    '&:hover $button': {
      marginBottom: 0,
      opacity: 1,
    },
  },
  graphic: {
    position: 'absolute',
    zIndex: 1,
    width: 80,
    height: 80,
    top: theme.spacing(3.5),
    left: 0,
    right: 0,
    margin: '0 auto',
    borderRadius: '50%',
    border: 'none',
    backgroundColor: theme.palette.grey[50],
    objectFit: 'cover',
    transition: 'opacity 0.1s ease-in-out, transform 0.1s ease-in-out',
  },
  earnCount: {
    zIndex: 2,
    marginTop: 108,
    marginBottom: theme.spacing(1),
    width: '100%',
    height: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    opacity: 1,
    transition:
      'opacity 0.2s ease-in-out, margin-top 0.2s ease-in-out, margin-bottom 0.2s ease-in-out, transform 0.2s ease-in-out',
    color: theme.palette.grey[300],
  },
  title: {
    zIndex: 2,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(0.5),
    transition: 'color 0.3s ease-in-out, margin-bottom 0.2s ease-in-out',
    width: '100%',
    minHeight: 23,
    lineHeight: '23px',
  },
  nextSteps: {
    marginBottom: theme.spacing(0.5),
    transform: 'scale(1, 0)',
    transition:
      'opacity 0.3s ease-in-out, margin-bottom 0.2s ease-in-out, transform 0.2s ease-in-out',
  },
  exp: {
    padding: `4px 10px`,
    borderRadius: 30,
    backgroundColor: fade(theme.palette.secondary.main, 0.12),
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
  icon: (props) => ({
    color: props.locked
      ? grey[300]
      : {
          STATUS_NONE: grey[300],
          STATUS_SUBMITTED: yellow[700],
          STATUS_ACTION_REQUIRED: red[300],
          STATUS_EARNED: green[300],
        }[props.status],
    transition: 'opacity 0.1s ease-in-out, transform 0.1s ease-in-out',
  }),
  content: {
    display: 'block',
    zIndex: 2,
    overflow: 'hidden',
    transition: 'top 0.2s ease-in-out, color 0.3s ease-in-out',
    pointerEvents: 'none',
  },
  button: (props) => {
    const color = {
      STATUS_NONE: 'white',
      STATUS_SUBMITTED: yellow[700],
      STATUS_ACTION_REQUIRED: red[300],
      STATUS_EARNED: green[500],
    }[props.status];
    const borderColor =
      props.status === 'STATUS_NONE' ? 'white' : 'transparent';

    return {
      zIndex: 2,
      opacity: 0,
      transition: 'margin-bottom 0.22s ease-in-out, opacity 0.22s ease-in-out',
      margin: 'auto auto 0',
      marginBottom: -(theme.spacing(3.5) + 36),
      color,
      borderColor,
      backgroundColor: 'transparent',
      '&:disabled': {
        color,
        borderColor,
      },
    };
  },
}));

const AchievementCard = (props) => {
  const {
    className,
    id,
    courseId,
    locked,
    status,
    graphic,
    exp,
    countEarned,
    title,
    description,
    requirements,
  } = props;

  const hoverRef = useRef();
  const [slickGlitch, setSlickGlitch] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hover = useHover(hoverRef, { exitDelay: 10 }, [slickGlitch]);
  const styles = useStyles({ status, locked });

  const StatusIcon = locked
    ? LockIcon
    : {
        STATUS_NONE: CheckCircleIcon,
        STATUS_SUBMITTED: PendingIcon,
        STATUS_ACTION_REQUIRED: ErrorIcon,
        STATUS_EARNED: CheckCircleIcon,
      }[status];

  const ButtonIcon = {
    STATUS_NONE: AddIcon,
    STATUS_SUBMITTED: PendingIcon,
    STATUS_ACTION_REQUIRED: ErrorIcon,
    STATUS_EARNED: CheckCircleIcon,
  }[status];

  const text = {
    STATUS_NONE: 'Earn Yours',
    STATUS_SUBMITTED: 'Under Review',
    STATUS_ACTION_REQUIRED: 'Action Required',
    STATUS_EARNED: 'Completed',
  }[status];

  const nextSteps = {
    STATUS_NONE: requirements.text,
    STATUS_SUBMITTED:
      'Please wait 3-5 business days so that we can review your submission.',
    STATUS_ACTION_REQUIRED:
      'Please check your email. We have found an issue with your submission.',
    STATUS_EARNED:
      'Congratulations! Give yourself a small reward and continue working on your business.',
  }[status];

  const [showModal, hideModal] = useModal(
    () => (
      <SubmitAchievementPictureDialog
        id={id}
        courseId={courseId}
        title={title}
        description={description}
        requirements={requirements}
        // eslint-disable-next-line react/jsx-no-bind
        onClose={() => {
          hideModal();
          // fixes (another) glitch with react-slick
          setSlickGlitch(slickGlitch + 1);
        }}
      />
    ),
    [id, courseId, title, description, requirements, slickGlitch],
  );

  return (
    <div className={className} key={slickGlitch.toString()}>
      <ButtonBase
        data-testid="achievementCard"
        onClick={status === 'STATUS_NONE' ? showModal : noop}
        component={Paper}
        className={clsx(styles.root, !locked && styles.rootUnlocked)}
        ref={hoverRef}
        disabled={locked}
      >
        {graphic ? (
          <img
            key={graphic.url}
            src={dynamicImageUrl(graphic, {
              resize: {
                width: 80,
                height: 80,
                fit: 'cover',
              },
            })}
            className={styles.graphic}
            alt="achievement"
          />
        ) : (
          <div className={styles.graphic} />
        )}
        <div className={styles.earnCount}>
          <Typography variant="caption" color="textPrimary">
            {locked
              ? 'Locked'
              : countEarned
              ? numberWithCommas(countEarned) + ' Earned'
              : 'Be the first!'}
          </Typography>
          {status !== 'STATUS_EARNED' && exp ? (
            <Typography
              className={styles.exp}
              variant="caption"
              color="secondary"
            >
              +
              {exp > 1000000
                ? floor(exp / 1000000, 1) + 'M '
                : exp > 1000
                ? floor(exp / 1000, 1) + 'K '
                : exp}
              XP
            </Typography>
          ) : null}
          <StatusIcon className={styles.icon} />
        </div>
        <Typography className={styles.title} variant="subtitle1">
          {title}
        </Typography>
        <div className={styles.content}>
          {!hover ? (
            <Dotdotdot
              // disable native clamp if behavior is clunky
              clamp={4}
              useNativeClamp
            >
              <Typography variant="body2" color="textSecondary">
                {description.text}
              </Typography>
            </Dotdotdot>
          ) : (
            <>
              <Typography
                className={clsx(styles.title, styles.nextSteps)}
                variant="subtitle1"
              >
                Next Steps
              </Typography>
              <Dotdotdot
                // disable native clamp if behavior is clunky
                clamp={4}
                useNativeClamp
              >
                <Typography variant="body2">{nextSteps}</Typography>
              </Dotdotdot>
            </>
          )}
        </div>
        <Button
          disabled
          className={styles.button}
          variant={status === 'STATUS_NONE' ? 'outlined' : 'text'}
          startIcon={<ButtonIcon />}
        >
          {text}
        </Button>
      </ButtonBase>
    </div>
  );
};

AchievementCard.propTypes = {
  id: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  status: PropTypes.oneOf([
    'STATUS_NONE',
    'STATUS_SUBMITTED',
    'STATUS_EARNED',
    'STATUS_ACTION_REQUIRED',
  ]),
  graphic: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  exp: PropTypes.number,
  countEarned: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string,
  }).isRequired,
  requirements: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string,
  }).isRequired,
};

export default AchievementCard;
