import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import formatError from 'Util/format-error';
import BugGraphic from 'Components/pages/error/bug-graphic';

import {
  Cached as CachedIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  Button,
  Typography,
  Collapse,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('lg')]: {
      maxWidth: 430,
      flexBasis: '58.14%',
      alignItems: 'flex-start',
    },
  },
  rightSide: {
    maxWidth: 350,
    width: '100%',
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      flexBasis: '37.71%',
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  showDetails: {
    ...theme.typography.body2,
  },
  details: {
    marginBottom: theme.spacing(4),
  },
  text: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(3) + 5,
    },
  },
}));

const ErrorDetailsWithGraphic = (props) => {
  const { error } = props;
  const styles = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const maybeCenterAlign = isLgUp ? 'left' : 'center';

  const handleShowDetailsToggle = useCallback(
    () => setShowDetails((value) => !value),
    [],
  );

  const handleReload = useCallback(() => {
    if (window) {
      window.location.reload();
    }
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.leftSide}>
        <Typography
          variant="h3"
          color="primary"
          align={maybeCenterAlign}
          className={styles.title}
        >
          Something Went Wrong
        </Typography>
        <div className={styles.content}>
          <Typography
            gutterBottom
            color="textSecondary"
            variant="body1"
            align={maybeCenterAlign}
          >
            Sorry, we were unable to load the page you’re looking for.
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
            align={maybeCenterAlign}
          >
            To reload the page click on the button below.
          </Typography>
        </div>
        <Button
          startIcon={
            isLgUp ? (
              showDetails ? (
                <KeyboardArrowDownIcon />
              ) : (
                <ChevronRightIcon />
              )
            ) : null
          }
          className={styles.showDetails}
          size="small"
          onClick={handleShowDetailsToggle}
        >
          <Typography variant="body2" color="textSecondary">
            Show Error Details
          </Typography>
        </Button>
        <div className={styles.details}>
          <Collapse in={showDetails}>
            <Typography
              variant="body2"
              color="textSecondary"
              align={maybeCenterAlign}
              className={styles.text}
            >
              {formatError(error)}
            </Typography>
          </Collapse>
        </div>
        <Button
          startIcon={<CachedIcon />}
          color="primary"
          variant="contained"
          size="large"
          onClick={handleReload}
        >
          Reload
        </Button>
      </div>
      <div className={styles.rightSide}>
        <BugGraphic />
      </div>
    </div>
  );
};

ErrorDetailsWithGraphic.propTypes = {
  error: PropTypes.any,
};

export default ErrorDetailsWithGraphic;
