import { put, call, select } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { fetchUser, selectUser } from './user';
import { enqueueSnackbar } from './snackbar';
import { fetchMe, selectMe } from './me';

const MODULE = 'cancel-trials';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CANCEL_TRIALS_REQUEST = Exec.requestConstantCreator(MODULE);
export const CANCEL_TRIALS_SINGLE_SUCCESS = 'cancel-trials-single-success';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const cancelTrials = Exec.requestActionCreator(MODULE);
export const cancelTrialsLoading = Exec.loadingActionCreator(MODULE);
export const cancelTrialsSuccess = Exec.successActionCreator(MODULE);
export const cancelTrialsError = Exec.errorActionCreator(MODULE);
export const cancelTrialsSingleSuccess = (payload) => {
  return {
    type: CANCEL_TRIALS_SINGLE_SUCCESS,
    payload,
  };
};

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCancelTrialsRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doCancelTrials = function* ({ payload, meta }) {
  const me = yield select(selectMe);
  const user = yield select(selectUser);

  const { trialIds, userId } = payload;
  try {
    yield put(cancelTrialsLoading());

    for (const trialId of trialIds) {
      const userIdOrMe = userId || 'me';

      const { data } = yield call(Api.cancelTrial, userIdOrMe, trialId);
      yield put(cancelTrialsSingleSuccess({ data }));

      if (data.courseId && me && userIdOrMe === 'me') {
        yield put(fetchMe());
      }

      if (data.courseId && user && userIdOrMe === user.id) {
        yield put(fetchUser({ userId: user.id }));
      }
    }

    yield put(cancelTrialsSuccess(null, meta));
  } catch (err) {
    yield put(cancelTrialsError(err, meta));
    yield put(
      enqueueSnackbar(CANCEL_TRIALS_REQUEST, {
        message:
          err.code === 'ERROR_CODE_PARTIAL_FAILURE'
            ? 'The trials could not be canceled. Please visit stripe dashboard and issue cancels manually'
            : err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCancelTrials);
