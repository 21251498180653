import PropTypes from 'prop-types';
import clsx from 'clsx';

import WistiaVideo from 'Components/shared/wistia-video';
import Divider from 'Components/shared/divider';

import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3.5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3.5, 3),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4.5, 4),
    },
  },
  divider: {
    margin: theme.spacing(2, 0, 4),
  },
  video: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  button: {
    backgroundColor: theme.palette.success.light,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
    color: 'white',
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const HelpVideo = (props) => {
  const { className, video, onClose, ...rest } = props;
  const styles = useStyles();
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <div className={clsx(styles.root, className)} {...rest}>
      {isSmUp && (
        <IconButton
          data-testid="hideSmall"
          size="small"
          className={styles.closeIcon}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Typography variant="h5" className={styles.title} align="center">
        How Mentorship Calls Work
      </Typography>
      <Divider className={styles.divider} />
      <WistiaVideo className={styles.video} video={video} />
      <Button
        variant="contained"
        startIcon={<DoneIcon />}
        className={styles.button}
        onClick={onClose}
        data-testid="hideBig"
      >
        Okay, got it
      </Button>
    </div>
  );
};

HelpVideo.propTypes = {
  video: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

export default HelpVideo;
