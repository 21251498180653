import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Error as ErrorIcon, Launch as LaunchIcon } from '@material-ui/icons';
import {
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2, 3),
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  icon: {
    color: theme.palette.error.main,
    marginRight: 10,
  },
  iconLarge: {
    fontSize: 32,
    marginRight: theme.spacing(2),
  },
  text: {
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  button: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },

    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
}));

const StripeConnectionError = (props) => {
  const { className, ConnectButtonProps } = props;
  const styles = useStyles();
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <div className={clsx(styles.root, className)}>
      {isSmUp ? (
        <ErrorIcon className={clsx(styles.icon, styles.iconLarge)} />
      ) : null}
      <div className={styles.text}>
        <Typography className={styles.title} variant="subtitle1">
          {!isSmUp ? <ErrorIcon className={styles.icon} /> : null}
          Stripe Connection Error
        </Typography>
        <Typography variant="body2">
          We are unable to show this user’s financial information without a
          stripe integration.
        </Typography>
      </div>
      <Button
        className={styles.button}
        variant="contained"
        endIcon={<LaunchIcon />}
        {...ConnectButtonProps}
      >
        Connect
      </Button>
    </div>
  );
};

StripeConnectionError.propTypes = {
  ConnectButtonProps: PropTypes.any,
};

export default StripeConnectionError;
