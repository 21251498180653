const countries = [
  //
  { name: 'United States', code: 'US', numberPrefix: '1' },
  { name: 'Australia', code: 'AU', numberPrefix: '61' },
  { name: 'Canada', code: 'CA', numberPrefix: '1' },
  { name: 'United Kingdom', code: 'GB', numberPrefix: '44' },
  //
  { name: 'Afghanistan', code: 'AF', numberPrefix: '93' },
  { name: 'Åland Islands', code: 'AX', numberPrefix: '358' },
  { name: 'Albania', code: 'AL', numberPrefix: '355' },
  { name: 'Algeria', code: 'DZ', numberPrefix: '213' },
  { name: 'American Samoa', code: 'AS', numberPrefix: '1' },
  { name: 'Andorra', code: 'AD', numberPrefix: '376' },
  { name: 'Angola', code: 'AO', numberPrefix: '244' },
  { name: 'Anguilla', code: 'AI', numberPrefix: '1' },
  { name: 'Antigua and Barbuda', code: 'AG', numberPrefix: '1' },
  { name: 'Argentina', code: 'AR', numberPrefix: '54' },
  { name: 'Armenia', code: 'AM', numberPrefix: '374' },
  { name: 'Aruba', code: 'AW', numberPrefix: '297' },
  { name: 'Austria', code: 'AT', numberPrefix: '43' },
  { name: 'Azerbaijan', code: 'AZ', numberPrefix: '994' },
  { name: 'Bahamas', code: 'BS', numberPrefix: '1' },
  { name: 'Bahrain', code: 'BH', numberPrefix: '973' },
  { name: 'Bangladesh', code: 'BD', numberPrefix: '880' },
  { name: 'Barbados', code: 'BB', numberPrefix: '1' },
  { name: 'Belarus', code: 'BY', numberPrefix: '375' },
  { name: 'Belgium', code: 'BE', numberPrefix: '32' },
  { name: 'Belize', code: 'BZ', numberPrefix: '501' },
  { name: 'Benin', code: 'BJ', numberPrefix: '229' },
  { name: 'Bermuda', code: 'BM', numberPrefix: '1' },
  { name: 'Bhutan', code: 'BT', numberPrefix: '975' },
  { name: 'Bolivia', code: 'BO', numberPrefix: '591' },
  { name: 'Bosnia and Herzegovina', code: 'BA', numberPrefix: '387' },
  { name: 'Botswana', code: 'BW', numberPrefix: '267' },
  { name: 'Brazil', code: 'BR', numberPrefix: '55' },
  { name: 'British Indian Ocean Territory', code: 'IO', numberPrefix: '246' },
  { name: 'Brunei Darussalam', code: 'BN', numberPrefix: '673' },
  { name: 'Bulgaria', code: 'BG', numberPrefix: '359' },
  { name: 'Burkina Faso', code: 'BF', numberPrefix: '226' },
  { name: 'Burundi', code: 'BI', numberPrefix: '257' },
  { name: 'Cambodia', code: 'KH', numberPrefix: '855' },
  { name: 'Cameroon', code: 'CM', numberPrefix: '237' },
  { name: 'Cape Verde', code: 'CV', numberPrefix: '238' },
  { name: 'Cayman Islands', code: 'KY', numberPrefix: '1' },
  { name: 'Central African Republic', code: 'CF', numberPrefix: '236' },
  { name: 'Chad', code: 'TD', numberPrefix: '235' },
  { name: 'Chile', code: 'CL', numberPrefix: '56' },
  { name: 'China', code: 'CN', numberPrefix: '86' },
  { name: 'Christmas Island', code: 'CX', numberPrefix: '61' },
  { name: 'Cocos (Keeling) Islands', code: 'CC', numberPrefix: '61' },
  { name: 'Colombia', code: 'CO', numberPrefix: '57' },
  { name: 'Comoros', code: 'KM', numberPrefix: '269' },
  { name: 'Congo', code: 'CG', numberPrefix: '242' },
  {
    name: 'Congo, Democratic Republic of the Congo',
    code: 'CD',
    numberPrefix: '243',
  },
  { name: 'Cook Islands', code: 'CK', numberPrefix: '682' },
  { name: 'Costa Rica', code: 'CR', numberPrefix: '506' },
  { name: "Cote D'Ivoire", code: 'CI', numberPrefix: '225' },
  { name: 'Croatia', code: 'HR', numberPrefix: '385' },
  { name: 'Cuba', code: 'CU', numberPrefix: '53' },
  { name: 'Cyprus', code: 'CY', numberPrefix: '357' },
  { name: 'Czech Republic', code: 'CZ', numberPrefix: '420' },
  { name: 'Denmark', code: 'DK', numberPrefix: '45' },
  { name: 'Djibouti', code: 'DJ', numberPrefix: '253' },
  { name: 'Dominica', code: 'DM', numberPrefix: '1' },
  { name: 'Dominican Republic', code: 'DO', numberPrefix: '1' },
  { name: 'Ecuador', code: 'EC', numberPrefix: '593' },
  { name: 'Egypt', code: 'EG', numberPrefix: '20' },
  { name: 'El Salvador', code: 'SV', numberPrefix: '503' },
  { name: 'Equatorial Guinea', code: 'GQ', numberPrefix: '240' },
  { name: 'Eritrea', code: 'ER', numberPrefix: '291' },
  { name: 'Estonia', code: 'EE', numberPrefix: '372' },
  { name: 'Ethiopia', code: 'ET', numberPrefix: '251' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK', numberPrefix: '500' },
  { name: 'Faroe Islands', code: 'FO', numberPrefix: '298' },
  { name: 'Fiji', code: 'FJ', numberPrefix: '679' },
  { name: 'Finland', code: 'FI', numberPrefix: '358' },
  { name: 'France', code: 'FR', numberPrefix: '33' },
  { name: 'French Guiana', code: 'GF', numberPrefix: '594' },
  { name: 'French Polynesia', code: 'PF', numberPrefix: '689' },
  { name: 'Gabon', code: 'GA', numberPrefix: '241' },
  { name: 'Gambia', code: 'GM', numberPrefix: '220' },
  { name: 'Georgia', code: 'GE', numberPrefix: '995' },
  { name: 'Germany', code: 'DE', numberPrefix: '49' },
  { name: 'Ghana', code: 'GH', numberPrefix: '233' },
  { name: 'Gibraltar', code: 'GI', numberPrefix: '350' },
  { name: 'Greece', code: 'GR', numberPrefix: '30' },
  { name: 'Greenland', code: 'GL', numberPrefix: '299' },
  { name: 'Grenada', code: 'GD', numberPrefix: '1' },
  { name: 'Guadeloupe', code: 'GP', numberPrefix: '590' },
  { name: 'Guam', code: 'GU', numberPrefix: '1' },
  { name: 'Guatemala', code: 'GT', numberPrefix: '502' },
  { name: 'Guernsey', code: 'GG', numberPrefix: '44' },
  { name: 'Guinea', code: 'GN', numberPrefix: '224' },
  { name: 'Guinea-Bissau', code: 'GW', numberPrefix: '245' },
  { name: 'Guyana', code: 'GY', numberPrefix: '592' },
  { name: 'Haiti', code: 'HT', numberPrefix: '509' },
  { name: 'Holy See (Vatican City State)', code: 'VA', numberPrefix: '39' },
  { name: 'Honduras', code: 'HN', numberPrefix: '504' },
  { name: 'Hong Kong', code: 'HK', numberPrefix: '852' },
  { name: 'Hungary', code: 'HU', numberPrefix: '36' },
  { name: 'Iceland', code: 'IS', numberPrefix: '354' },
  { name: 'India', code: 'IN', numberPrefix: '91' },
  { name: 'Indonesia', code: 'ID', numberPrefix: '62' },
  { name: 'Iran, Islamic Republic Of Iran', code: 'IR', numberPrefix: '98' },
  { name: 'Iraq', code: 'IQ', numberPrefix: '964' },
  { name: 'Ireland', code: 'IE', numberPrefix: '353' },
  { name: 'Isle of Man', code: 'IM', numberPrefix: '44' },
  { name: 'Israel', code: 'IL', numberPrefix: '972' },
  { name: 'Italy', code: 'IT', numberPrefix: '39' },
  { name: 'Jamaica', code: 'JM', numberPrefix: '1' },
  { name: 'Japan', code: 'JP', numberPrefix: '81' },
  { name: 'Jersey', code: 'JE', numberPrefix: '44' },
  { name: 'Jordan', code: 'JO', numberPrefix: '962' },
  { name: 'Kazakhstan', code: 'KZ', numberPrefix: '7' },
  { name: 'Kenya', code: 'KE', numberPrefix: '254' },
  { name: 'Kiribati', code: 'KI', numberPrefix: '686' },
  {
    name: "Korea, Democratic People's Republic of",
    code: 'KP',
    numberPrefix: '850',
  },
  { name: 'Korea, South Korea', code: 'KR', numberPrefix: '82' },
  { name: 'Kuwait', code: 'KW', numberPrefix: '965' },
  { name: 'Kyrgyzstan', code: 'KG', numberPrefix: '996' },
  { name: "Lao People's Democratic Republic", code: 'LA', numberPrefix: '856' },
  { name: 'Latvia', code: 'LV', numberPrefix: '371' },
  { name: 'Lebanon', code: 'LB', numberPrefix: '961' },
  { name: 'Lesotho', code: 'LS', numberPrefix: '266' },
  { name: 'Liberia', code: 'LR', numberPrefix: '231' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY', numberPrefix: '218' },
  { name: 'Liechtenstein', code: 'LI', numberPrefix: '423' },
  { name: 'Lithuania', code: 'LT', numberPrefix: '370' },
  { name: 'Luxembourg', code: 'LU', numberPrefix: '352' },
  { name: 'Macao', code: 'MO', numberPrefix: '853' },
  { name: 'Serbia', code: 'RS', numberPrefix: '381' },
  { name: 'Montenegro', code: 'ME', numberPrefix: '382' },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
    numberPrefix: '389',
  },
  { name: 'Madagascar', code: 'MG', numberPrefix: '261' },
  { name: 'Malawi', code: 'MW', numberPrefix: '265' },
  { name: 'Malaysia', code: 'MY', numberPrefix: '60' },
  { name: 'Maldives', code: 'MV', numberPrefix: '960' },
  { name: 'Mali', code: 'ML', numberPrefix: '223' },
  { name: 'Malta', code: 'MT', numberPrefix: '356' },
  { name: 'Marshall Islands', code: 'MH', numberPrefix: '692' },
  { name: 'Martinique', code: 'MQ', numberPrefix: '596' },
  { name: 'Mauritania', code: 'MR', numberPrefix: '222' },
  { name: 'Mauritius', code: 'MU', numberPrefix: '230' },
  { name: 'Mayotte', code: 'YT', numberPrefix: '262' },
  { name: 'Mexico', code: 'MX', numberPrefix: '52' },
  { name: 'Micronesia, Federated States of', code: 'FM', numberPrefix: '691' },
  { name: 'Moldova, Republic of', code: 'MD', numberPrefix: '373' },
  { name: 'Monaco', code: 'MC', numberPrefix: '377' },
  { name: 'Mongolia', code: 'MN', numberPrefix: '976' },
  { name: 'Montserrat', code: 'MS', numberPrefix: '1' },
  { name: 'Morocco', code: 'MA', numberPrefix: '212' },
  { name: 'Mozambique', code: 'MZ', numberPrefix: '258' },
  { name: 'Myanmar', code: 'MM', numberPrefix: '95' },
  { name: 'Namibia', code: 'NA', numberPrefix: '264' },
  { name: 'Nauru', code: 'NR', numberPrefix: '674' },
  { name: 'Nepal', code: 'NP', numberPrefix: '977' },
  { name: 'Netherlands', code: 'NL', numberPrefix: '31' },
  { name: 'New Caledonia', code: 'NC', numberPrefix: '687' },
  { name: 'New Zealand', code: 'NZ', numberPrefix: '64' },
  { name: 'Nicaragua', code: 'NI', numberPrefix: '505' },
  { name: 'Niger', code: 'NE', numberPrefix: '227' },
  { name: 'Nigeria', code: 'NG', numberPrefix: '234' },
  { name: 'Niue', code: 'NU', numberPrefix: '683' },
  { name: 'Norfolk Island', code: 'NF', numberPrefix: '672' },
  { name: 'Northern Mariana Islands', code: 'MP', numberPrefix: '1' },
  { name: 'Norway', code: 'NO', numberPrefix: '47' },
  { name: 'Oman', code: 'OM', numberPrefix: '968' },
  { name: 'Pakistan', code: 'PK', numberPrefix: '92' },
  { name: 'Palau', code: 'PW', numberPrefix: '680' },
  { name: 'Palestinian Territory, Occupied', code: 'PS', numberPrefix: '970' },
  { name: 'Panama', code: 'PA', numberPrefix: '507' },
  { name: 'Papua New Guinea', code: 'PG', numberPrefix: '675' },
  { name: 'Paraguay', code: 'PY', numberPrefix: '595' },
  { name: 'Peru', code: 'PE', numberPrefix: '51' },
  { name: 'Philippines', code: 'PH', numberPrefix: '63' },
  { name: 'Poland', code: 'PL', numberPrefix: '48' },
  { name: 'Portugal', code: 'PT', numberPrefix: '351' },
  { name: 'Puerto Rico', code: 'PR', numberPrefix: '1' },
  { name: 'Qatar', code: 'QA', numberPrefix: '974' },
  { name: 'Reunion', code: 'RE', numberPrefix: '262' },
  { name: 'Romania', code: 'RO', numberPrefix: '40' },
  { name: 'Russian Federation', code: 'RU', numberPrefix: '7' },
  { name: 'Rwanda', code: 'RW', numberPrefix: '250' },
  { name: 'Saint Helena', code: 'SH', numberPrefix: '290' },
  { name: 'Saint Kitts and Nevis', code: 'KN', numberPrefix: '1' },
  { name: 'Saint Lucia', code: 'LC', numberPrefix: '1' },
  { name: 'Saint Pierre and Miquelon', code: 'PM', numberPrefix: '508' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', numberPrefix: '1' },
  { name: 'Samoa', code: 'WS', numberPrefix: '685' },
  { name: 'San Marino', code: 'SM', numberPrefix: '378' },
  { name: 'Sao Tome and Principe', code: 'ST', numberPrefix: '239' },
  { name: 'Saudi Arabia', code: 'SA', numberPrefix: '966' },
  { name: 'Senegal', code: 'SN', numberPrefix: '221' },
  { name: 'Seychelles', code: 'SC', numberPrefix: '248' },
  { name: 'Sierra Leone', code: 'SL', numberPrefix: '232' },
  { name: 'Singapore', code: 'SG', numberPrefix: '65' },
  { name: 'Slovakia', code: 'SK', numberPrefix: '421' },
  { name: 'Slovenia', code: 'SI', numberPrefix: '386' },
  { name: 'Solomon Islands', code: 'SB', numberPrefix: '677' },
  { name: 'Somalia', code: 'SO', numberPrefix: '252' },
  { name: 'South Africa', code: 'ZA', numberPrefix: '27' },
  { name: 'Spain', code: 'ES', numberPrefix: '34' },
  { name: 'Sri Lanka', code: 'LK', numberPrefix: '94' },
  { name: 'Sudan', code: 'SD', numberPrefix: '249' },
  { name: 'Suriname', code: 'SR', numberPrefix: '597' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', numberPrefix: '47' },
  { name: 'Swaziland', code: 'SZ', numberPrefix: '268' },
  { name: 'Sweden', code: 'SE', numberPrefix: '46' },
  { name: 'Switzerland', code: 'CH', numberPrefix: '41' },
  { name: 'Syrian Arab Republic', code: 'SY', numberPrefix: '963' },
  { name: 'Taiwan, Province of China', code: 'TW', numberPrefix: '886' },
  { name: 'Tajikistan', code: 'TJ', numberPrefix: '992' },
  { name: 'Tanzania, United Republic of', code: 'TZ', numberPrefix: '255' },
  { name: 'Thailand', code: 'TH', numberPrefix: '66' },
  { name: 'Timor-Leste', code: 'TL', numberPrefix: '670' },
  { name: 'Togo', code: 'TG', numberPrefix: '228' },
  { name: 'Tokelau', code: 'TK', numberPrefix: '690' },
  { name: 'Tonga', code: 'TO', numberPrefix: '676' },
  { name: 'Trinidad and Tobago', code: 'TT', numberPrefix: '1' },
  { name: 'Tunisia', code: 'TN', numberPrefix: '216' },
  { name: 'Turkey', code: 'TR', numberPrefix: '90' },
  { name: 'Turkmenistan', code: 'TM', numberPrefix: '993' },
  { name: 'Turks and Caicos Islands', code: 'TC', numberPrefix: '1' },
  { name: 'Tuvalu', code: 'TV', numberPrefix: '688' },
  { name: 'Uganda', code: 'UG', numberPrefix: '256' },
  { name: 'Ukraine', code: 'UA', numberPrefix: '380' },
  { name: 'United Arab Emirates', code: 'AE', numberPrefix: '971' },
  { name: 'Uruguay', code: 'UY', numberPrefix: '598' },
  { name: 'Uzbekistan', code: 'UZ', numberPrefix: '998' },
  { name: 'Vanuatu', code: 'VU', numberPrefix: '678' },
  { name: 'Venezuela', code: 'VE', numberPrefix: '58' },
  { name: 'Viet Nam', code: 'VN', numberPrefix: '84' },
  { name: 'Virgin Islands, British', code: 'VG', numberPrefix: '1' },
  { name: 'Virgin Islands, U.S.', code: 'VI', numberPrefix: '1' },
  { name: 'Wallis and Futuna', code: 'WF', numberPrefix: '681' },
  { name: 'Western Sahara', code: 'EH', numberPrefix: '212' },
  { name: 'Yemen', code: 'YE', numberPrefix: '967' },
  { name: 'Zambia', code: 'ZM', numberPrefix: '260' },
  { name: 'Zimbabwe', code: 'ZW', numberPrefix: '263' },
];

export default countries;
