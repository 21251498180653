import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { get, find, noop } from 'lodash';
import clsx from 'clsx';

import {
  selectAchievementLevelsRequest,
  selectAchievementLevels,
  selectUpdateAchievementLevelRequest,
  selectUploadAchievementLevelPictureRequest,
} from 'Redux/selectors';
import { fetchAchievementLevels, updateAchievementLevel } from 'Redux/actions';
import ErrorPage from 'Pages/error';
import FileUploadIcon from 'Icons/file-upload';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import UploadAchievementLevelPictureDialog from 'Dialogs/upload-achievement-level-picture-dialog';
import ConfirmDeleteAchievementLevelPictureDialog from 'Dialogs/confirm-delete-achievement-level-picture-dialog';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import UploadFileGroup from 'Components/pages/admin/upload-file-group';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import { Save as SaveIcon } from '@material-ui/icons';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  InputAdornment,
} from '@material-ui/core';

const schema = yup.object().shape({
  title: yup.string().required('Field Required.'),
  slug: yup
    .string()
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g, 'Only Letters, Numbers and Dashes.')
    .required('Field Required.'),
  requiredExp: yup.number().min(0, 'Must Be >0').required('Field Required.'),
});

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  input: {
    width: '100%',
  },
  exp: {
    maxWidth: 160,
  },
  upload: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const AdminUpdateAchievementLevelPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { achievementLevelId } = useParams();

  const achievementLevels = useSelector(selectAchievementLevels);
  const achievementLevel = find(achievementLevels, ['id', achievementLevelId]);

  const { isLoading, error } = useCombinedRequestsSelector(
    selectAchievementLevelsRequest,
  );
  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectUpdateAchievementLevelRequest,
    selectUploadAchievementLevelPictureRequest,
  );

  useScopedDispatchEffect(() => {
    if (!achievementLevel) {
      fetchAchievementLevels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, achievementLevelId]);

  const handleFormSubmit = useCallback(
    (data) => {
      dispatch(
        updateAchievementLevel(
          { achievementLevelId, ...data },
          { isPromise: true },
        ),
      )
        .then(() => navigate('/admin/achievement-levels'))
        .catch(noop);
    },
    [achievementLevelId, dispatch, navigate],
  );

  const [
    showUploadAchievementLevelPictureDialog,
    hideUploadAchievementLevelPictureDialog,
  ] = useModal(
    () => (
      <UploadAchievementLevelPictureDialog
        achievementLevelId={achievementLevelId}
        onClose={hideUploadAchievementLevelPictureDialog}
      />
    ),
    [achievementLevelId],
  );
  const [
    showDeleteAchievementLevelPictureDialog,
    hideDeleteAchievementLevelPictureDialog,
  ] = useModal(
    () => (
      <ConfirmDeleteAchievementLevelPictureDialog
        achievementLevelId={achievementLevelId}
        onClose={hideDeleteAchievementLevelPictureDialog}
      />
    ),
    [achievementLevelId],
  );

  if (!isLoading && !achievementLevel) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { control, errors, handleSubmit } = useForm({
          defaultValues: {
            ...achievementLevel,
            requiredExp: achievementLevel.requiredExp + '',
          },
          validationSchema: schema,
          reValidateMode: 'onChange',
        });

        return (
          <UserDetailsLayout>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={[
                { href: '/admin/achievement-levels', label: 'Levels' },
                {
                  label: achievementLevel.title,
                },
              ]}
            />

            <form
              className={styles.form}
              noValidate
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <TitleAndAction
                className={styles.titleAndAction}
                title={
                  <Typography variant="h4">Edit Achievement Level</Typography>
                }
              />

              <Grid container spacing={2} direction="column">
                <Grid item container spacing={2} direction="row">
                  <Grid item sm={6} xs={12}>
                    <Input
                      data-testid="title"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Title"
                      name="title"
                      errorText={get(errors, 'title.message')}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Input
                      data-testid="slug"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Slug"
                      name="slug"
                      errorText={get(errors, 'slug.message')}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <Input
                    data-testid="requiredExp"
                    control={control}
                    className={clsx(styles.input, styles.exp)}
                    disabled={isLoadingMutation}
                    label="XP Required"
                    name="requiredExp"
                    type="number"
                    errorText={get(errors, 'requiredExp.message')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="body2" color="textSecondary">
                            XP
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item container direction="column">
                  <Typography variant="h6" gutterBottom>
                    Graphic
                  </Typography>
                  <UploadFileGroup
                    className={styles.upload}
                    preview={achievementLevel.graphic}
                    onUpload={showUploadAchievementLevelPictureDialog}
                    onDelete={showDeleteAchievementLevelPictureDialog}
                    disabled={isLoadingMutation}
                    uploadButtonLabel="Upload Image"
                    uploadButtonIcon={<FileUploadIcon />}
                  />
                </Grid>

                <Grid item xs={10} sm={6} md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={isLoadingMutation}
                  >
                    Save Level
                  </Button>
                </Grid>
              </Grid>
            </form>
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminUpdateAchievementLevelPage;
