import { put, call, all, take, race } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import {
  fetchAffiliateStats,
  FETCH_AFFILIATE_STATS_SUCCESS,
  FETCH_AFFILIATE_STATS_ERROR,
} from './affiliate-stats';
import {
  fetchAffiliateRedeemRequests,
  FETCH_AFFILIATE_REDEEM_REQUESTS_SUCCESS,
  FETCH_AFFILIATE_REDEEM_REQUESTS_ERROR,
} from './affiliate-redeem-requests';

const MODULE = 'create-affiliate-redeem-request';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CREATE_AFFILIATE_REDEEM_REQUEST_REQUEST =
  Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const createAffiliateRedeemRequest = Exec.requestActionCreator(MODULE);
export const createAffiliateRedeemRequestLoading =
  Exec.loadingActionCreator(MODULE);
export const createAffiliateRedeemRequestSuccess =
  Exec.successActionCreator(MODULE);
export const createAffiliateRedeemRequestError =
  Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCreateAffiliateRedeemRequestRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doCreateAffiliateRedeemRequest = function* ({ meta }) {
  try {
    yield put(closeSnackbar(CREATE_AFFILIATE_REDEEM_REQUEST_REQUEST));
    yield put(createAffiliateRedeemRequestLoading());
    const { data } = yield call(Api.createAffiliateRedeemRequest);
    yield put(fetchAffiliateRedeemRequests({ page: 0 }));
    yield put(fetchAffiliateStats());
    yield all([
      race([
        take(FETCH_AFFILIATE_STATS_SUCCESS),
        take(FETCH_AFFILIATE_STATS_ERROR),
      ]),
      race([
        take(FETCH_AFFILIATE_REDEEM_REQUESTS_SUCCESS),
        take(FETCH_AFFILIATE_REDEEM_REQUESTS_ERROR),
      ]),
    ]);
    yield put(createAffiliateRedeemRequestSuccess({ data }, meta));
  } catch (err) {
    yield put(createAffiliateRedeemRequestError(err, meta));
    yield put(
      enqueueSnackbar(CREATE_AFFILIATE_REDEEM_REQUEST_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.message || err.detail,
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCreateAffiliateRedeemRequest);
