import { createSelector } from 'reselect';
import { put, call } from 'redux-saga/effects';
import { filter, find, findLast, groupBy, orderBy } from 'lodash';
import { isAfter } from 'date-fns';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPLOAD_COURSE_GRAPHIC_SUCCESS } from './upload-course-graphic';
import { UPLOAD_COURSE_BACKGROUND_SUCCESS } from './upload-course-background';
import { UPDATE_COURSE_SUCCESS } from './update-course';
import { selectSubscriptionSteps } from './subscription-steps';
import { SORT_COURSES_ERROR, SORT_COURSES_SUCCESS } from './sort-courses';
import { selectModules } from './modules';
import { selectMe } from './me';
import { selectLessons } from './lessons';
import { CREATE_COURSE_BACKUP_SUCCESS } from './create-course-backup';
import { CREATE_COURSE_SUCCESS } from './create-course';
import { selectAchievements } from './achievements';

const MODULE = 'courses';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////

export const FETCH_COURSES_ERROR = Exec.errorConstantCreator(MODULE);
export const FETCH_COURSES_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchCourses = Exec.requestActionCreator(MODULE);
export const fetchCoursesLoading = Exec.loadingActionCreator(MODULE);
export const fetchCoursesSuccess = Exec.successActionCreator(MODULE);
export const fetchCoursesError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);
export const reducer = (state, action) => {
  switch (action.type) {
    case UPLOAD_COURSE_GRAPHIC_SUCCESS:
    case UPLOAD_COURSE_BACKGROUND_SUCCESS:
    case CREATE_COURSE_BACKUP_SUCCESS:
    case UPDATE_COURSE_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((v) => (v.id === data.id ? data : v)),
      };
    }
    case CREATE_COURSE_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };
    }
    case SORT_COURSES_ERROR: {
      if (!state || !state.data || !action.payload.error.data) {
        return state;
      }
      return {
        ...state,
        data: action.payload.error.data,
      };
    }
    case SORT_COURSES_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: action.payload.data,
      };
    }
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCoursesRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectCourses = Exec.dataSelectorCreator(MODULE);
export const selectCoursesWithGraph = createSelector(
  selectCourses,
  selectModules,
  selectLessons,
  selectAchievements,
  (courses, modules, lessons, achievements) =>
    courses &&
    courses.map((course) => {
      const courseModules = filter(modules, ['courseId', course.id]);
      const moduleLessons = groupBy(lessons, 'moduleId');
      const moduleAchievements = groupBy(achievements, 'moduleId');

      return {
        ...course,
        modules: courseModules.map((m, index) => ({
          ...m,
          index,
          lessons: moduleLessons[m.id] || null,
          achievements: moduleAchievements[m.id] || null,
        })),
      };
    }),
);
export const selectLastWatchedCourse = createSelector(
  selectCourses,
  (courses) =>
    orderBy(
      courses && courses.filter((c) => Boolean(c.lastWatchedAt)),
      'lastWatchedAt',
      'DESC',
    )[0] || null,
);
export const selectCoursesWithSubscriptionStatus = createSelector(
  selectCourses,
  selectMe,
  selectSubscriptionSteps,
  (courses, me, subscriptionSteps) =>
    courses &&
    courses.map((course) => {
      const courseCopy = { ...course };

      const enrollStatus = find(me?.enrolledCourseStatus, [
        'courseId',
        courseCopy.id,
      ]);

      const courseSubscription = find(subscriptionSteps, [
        'courseId',
        courseCopy.id,
      ]);

      const isTrial =
        findLast(courseSubscription?.steps, (s) =>
          isAfter(new Date(), new Date(s.activateAt)),
        )?.type === 'trial';

      const mentorshipActive = Boolean(
        enrollStatus && enrollStatus.hasMentorship,
      );

      if (courseCopy.upgradeUrl && courseSubscription?.couponId) {
        const upgradeUrl = new URL(courseCopy.upgradeUrl);
        upgradeUrl.searchParams.append('coupon', courseSubscription.couponId);
        courseCopy.upgradeUrl = upgradeUrl.href;
      }

      return {
        ...courseCopy,
        subscriptionStatus: {
          enrolled: Boolean(enrollStatus),
          hasMentorshipUpsell: Boolean(
            courseSubscription &&
              courseSubscription.hasMentorshipUpsell &&
              false, // disable mentorship upsells
          ),
          mentorshipPurchased: Boolean(
            (courseSubscription && courseSubscription.hasMentorshipIncluded) ||
              mentorshipActive,
          ),
          mentorshipActive,
          isTrial,
        },
      };
    }),
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchCourses = function* ({ meta }) {
  try {
    const { data } = yield call(Api.getCourses);
    yield put(
      fetchCoursesSuccess(
        { data: data.filter((c) => ['COURSE', 'BLUEPRINT'].includes(c.type)) },
        meta,
      ),
    );
  } catch (err) {
    yield put(fetchCoursesError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchCourses);
