import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useCallback, useMemo } from 'react';
import { find } from 'lodash';

import {
  selectAchievements,
  selectAchievementStats,
  selectAchievementStatsRequest,
  selectCoursesWithGraph,
  selectCoursesRequest,
  selectModulesRequest,
  selectAchievementsRequest,
  selectMeRequest,
} from 'Redux/selectors';
import {
  fetchCourses,
  fetchAchievements,
  fetchModules,
  fetchAchievementStats,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import StickyTabs from 'Components/shared/sticky-tabs';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import Divider from 'Components/shared/divider';
import CourseHeader from 'Components/shared/course-header';
import CourseCardSmall from 'Components/shared/course-card-small';
import Carousel from 'Components/shared/carousel';
import AchievementCard from 'Components/shared/achievement-card';
import Boxes from 'Components/pages/achievements/boxes';

import { makeStyles, Tab, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  courseTitle: {
    marginBottom: theme.spacing(5),
    color: theme.palette.secondary.main,
  },
  boxes: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    marginLeft: theme.spacing(-1),
    marginBottom: theme.spacing(10),

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  tabs: {
    marginBottom: theme.spacing(4.5),
    [theme.breakpoints.down('xs')]: {
      top: 79,
    },
  },
  moduleContainer: {
    padding: theme.spacing(5.5, 0),
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    textTransform: 'capitalize',
  },
  divider: {
    margin: '0 auto',
    marginBottom: theme.spacing(4),
  },
  noAchievements: {
    margin: theme.spacing(5.5, 0, 1.5),
  },
  course: {
    marginTop: theme.spacing(5),
    maxWidth: 278,
  },
}));

const CourseAchievementsPage = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { courseSlug } = useParams();
  const styles = useStyles();
  const dispatch = useDispatch();

  const { isLoading, error } = useCombinedRequestsSelector(
    selectMeRequest,
    selectCoursesRequest,
    selectModulesRequest,
    selectAchievementsRequest,
    selectAchievementStatsRequest,
  );

  const courses = useSelector(selectCoursesWithGraph);
  const stats = useSelector(selectAchievementStats);
  const achievements = useSelector(selectAchievements);

  const course = find(courses, ['slug', courseSlug]);

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    dispatch(fetchModules({ courseId: courseSlug }));
    dispatch(fetchAchievements({ courseId: courseSlug }));
    dispatch(fetchAchievementStats());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug]);

  const handleChange = useCallback(
    (event, newValue) => setCurrentTab(newValue),
    [],
  );

  const statsArray = useMemo(
    () =>
      stats && achievements
        ? [
            { label: 'Current Course', value: achievements.length },
            { label: 'Earned', value: stats.userEarnedCount },
            {
              label: 'Missing',
              value: stats.totalCount - stats.userEarnedCount,
            },
            { label: 'Total', value: stats.totalCount },
          ]
        : null,
    [achievements, stats],
  );

  if (!isLoading && (!course || !course.hasAchievements)) {
    return <ErrorPage notFound />;
  }

  const moduleAchievements = isLoading
    ? []
    : currentTab === 0
    ? course.modules
    : course.modules.filter(
        (module) =>
          module.achievements &&
          module.achievements.find((a) => Boolean(a.earnedAt)),
      );

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <CourseHeader course={course} />
          <PageContainer maxWidth={1400}>
            <Typography variant="h4" align="center" className={styles.title}>
              Achievements
            </Typography>
            <Typography
              variant="h5"
              align="center"
              className={styles.courseTitle}
            >
              {course.title}
            </Typography>
            {statsArray && (
              <Boxes className={styles.boxes} stats={statsArray} />
            )}
            <StickyTabs
              className={styles.tabs}
              value={currentTab}
              onChange={handleChange}
            >
              <Tab data-testid="totalTab" label="Total" />
              <Tab data-testid="earnedTab" label="Earned" />
            </StickyTabs>
            {moduleAchievements.length ? (
              moduleAchievements
                .filter((module) => Boolean(module.achievements))
                .map((module, index) => (
                  <div className={styles.moduleContainer} key={index}>
                    <Typography align="center" variant="h6">
                      {module.title}
                    </Typography>
                    <Typography
                      align="center"
                      variant="subtitle1"
                      className={styles.subtitle}
                    >
                      {module.summary}
                    </Typography>
                    <Divider className={styles.divider} />
                    <Carousel
                      component={AchievementCard}
                      data={
                        currentTab === 1
                          ? module.achievements.filter((a) =>
                              Boolean(a.earnedAt),
                            )
                          : module.achievements
                      }
                      slideWidth={268}
                    />
                  </div>
                ))
            ) : (
              <>
                <Typography
                  className={styles.noAchievements}
                  align="center"
                  variant="h5"
                >
                  No achievements earned
                </Typography>
                <Typography align="center" variant="body1">
                  Start learning now to earn your first achievement.
                </Typography>
                <CourseCardSmall
                  className={styles.course}
                  ButtonProps={{
                    'data-testid': 'courseLink',
                    to: '/learn/' + course.slug,
                  }}
                  {...course}
                />
              </>
            )}
          </PageContainer>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default CourseAchievementsPage;
