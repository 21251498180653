import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectDeleteEventRequest } from 'Redux/selectors';
import { deleteEvent, enqueueSnackbar } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmDeleteEventDialog = (props) => {
  const { onClose, eventId } = props;
  const { isLoading } = useSelector(selectDeleteEventRequest);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    dispatch(deleteEvent({ eventId }, { isPromise: true }))
      .then(() => {
        dispatch(
          enqueueSnackbar('DELETE_EVENT', {
            variant: 'success',
            message: 'Event deleted successfully',
          }),
        );
        navigate('/admin/mentorship-calls');
        onClose && onClose();
      })
      .catch(noop);
  }, [dispatch, navigate, onClose, eventId]);

  return (
    <Dialog
      title="Delete Event"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleDelete}>
          Delete
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to delete this event? This is a permanent change
        and cannot be reverted.
      </Typography>
    </Dialog>
  );
};

ConfirmDeleteEventDialog.propTypes = {
  eventId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmDeleteEventDialog;
