import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { find, findIndex } from 'lodash';
import { format } from 'date-fns';

import {
  selectCoursesWithGraph,
  selectCoursesRequest,
  selectModulesRequest,
  selectLessonsRequest,
  selectAchievementsRequest,
  selectCreateCourseBackupRequest,
} from 'Redux/selectors';
import {
  closeSnackbar,
  enqueueSnackbar,
  fetchAchievements,
  createCourseBackup,
  fetchCourses,
  fetchLessons,
  fetchModules,
  sortModules,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCopyToClipboard from 'Hooks/use-copy-to-clipboard';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import RichText from 'Components/shared/rich-text';
import PageLoader from 'Components/shared/page-loader';
import WideButton from 'Components/pages/admin/wide-button';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import SectionTitle from 'Components/pages/admin/section-title';
import ModuleItem from 'Components/pages/admin/module-item';
import ContentPaper from 'Components/pages/admin/content-paper';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import { Alert } from '@material-ui/lab';
import {
  Edit as EditIcon,
  YouTube as YouTubeIcon,
  Add as AddIcon,
  SaveAlt as SaveIcon,
  Link as LinkIcon,
  Done as DoneIcon,
  Replay as ReplayIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  container: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    width: '100%',
  },
  courseTitle: (props) => ({
    color: props.color,
    marginBottom: theme.spacing(-0.5),
  }),
  button: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  buttons: {
    marginLeft: -19,
  },
  sectionTitle: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  addButton: (props) => ({
    width: '100%',
    marginTop: props.hasModules ? theme.spacing(2) : 'initial',
  }),
  backupAlert: {
    marginTop: theme.spacing(2),
    alignSelf: 'flex-start',
  },
}));

const AdminCourseOverview = () => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const [copied, copyToClipboard] = useCopyToClipboard();

  const courses = useSelector(selectCoursesWithGraph);
  const course = find(courses, ['id', courseId]);

  const styles = useStyles(
    course
      ? {
          color: course.color,
          hasModules: course.modules && course.modules.length,
        }
      : {},
  );

  const backupInProgress =
    course &&
    course.lastBackup &&
    !course.lastBackup.url &&
    !course.lastBackup.error;

  const handleCopyLastBackupUrl = useCallback(async () => {
    dispatch(closeSnackbar('COPY'));

    if (await copyToClipboard(course.lastBackup.url)) {
      dispatch(
        enqueueSnackbar('COPY', {
          message: 'URL copied to clipboard',
          variant: 'success',
        }),
      );
    }
  }, [course, copyToClipboard, dispatch]);

  const handleCreateBackup = useCallback(async () => {
    dispatch(closeSnackbar('BACKUP'));

    dispatch(createCourseBackup({ courseId }, { isPromise: true })).then(() =>
      dispatch(
        enqueueSnackbar('BACKUP', {
          message: 'Course backup started!',
          variant: 'success',
        }),
      ),
    );
  }, [courseId, dispatch]);

  const { isLoading: isCreatingCourseBackup } = useSelector(
    selectCreateCourseBackupRequest,
  );
  const { isLoading, error } =
    useCombinedRequestsSelector(selectCoursesRequest);
  const { isLoading: isLoadingRelated, error: errorRelated } =
    useCombinedRequestsSelector(
      selectModulesRequest,
      selectLessonsRequest,
      selectAchievementsRequest,
    );

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    dispatch(fetchModules({ courseId, skipAccessCheck: true }));
    dispatch(fetchLessons({ courseId, skipAccessCheck: true }));
    dispatch(fetchAchievements({ courseId, skipAccessCheck: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId]);

  useEffect(() => {
    if (backupInProgress) {
      const intervalId = setInterval(() => dispatch(fetchCourses()), 5000);
      return () => clearInterval(intervalId);
    }
  }, [backupInProgress, dispatch]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const from = findIndex(course.modules, ['id', active.id]);
        const to = findIndex(course.modules, ['id', over.id]);
        const moved = arrayMove(course.modules, from, to);
        dispatch(
          sortModules({
            courseId: course.id,
            sortConfig: moved.map((m, idx) => ({ id: m.id, seqNo: idx + 1 })),
          }),
        );
      }
    },
    [course, dispatch],
  );

  if (!isLoading && !course) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader
      loading={isLoading || isLoadingRelated}
      error={
        error ||
        (errorRelated && errorRelated.code !== 'ERROR_CODE_NOT_FOUND'
          ? errorRelated
          : null)
      }
    >
      {() => (
        <UserDetailsLayout>
          <Breadcrumbs
            className={styles.breadcrumbs}
            links={[
              { href: '/admin/courses', label: 'Courses' },
              { label: course.title },
            ]}
          />

          <div className={styles.container}>
            <TitleAndAction
              className={styles.titleAndAction}
              title={<Typography variant="h4">Course Overview</Typography>}
            />

            <ContentPaper
              className={styles.paper}
              actions={
                <IconButton
                  component={Link}
                  to={'/admin/courses/' + courseId + '/edit'}
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <Typography variant="h5" className={styles.courseTitle}>
                {course.title}
              </Typography>
              <Typography
                component="div"
                variant="body1"
                color="textSecondary"
                gutterBottom
              >
                <RichText>
                  {course.description.html || course.description}
                </RichText>
              </Typography>

              <Grid container spacing={2} className={styles.buttons}>
                <Grid item>
                  <Button
                    className={styles.button}
                    variant="text"
                    size="large"
                    color="primary"
                    component="a"
                    href={course.salesPageUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Sales Page
                  </Button>
                </Grid>
                {course.communityUrl ? (
                  <Grid item>
                    <Button
                      className={styles.button}
                      variant="text"
                      size="large"
                      color="primary"
                      component="a"
                      href={course.communityUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Community
                    </Button>
                  </Grid>
                ) : null}
                <Grid item>
                  <Button
                    className={styles.button}
                    variant="text"
                    size="large"
                    color="primary"
                    onClick={handleCreateBackup}
                    disabled={backupInProgress || isCreatingCourseBackup}
                    startIcon={
                      backupInProgress ? (
                        <CircularProgress size={18} color="inherit" />
                      ) : null
                    }
                  >
                    {backupInProgress ? 'Backing up...' : 'Create backup'}
                  </Button>
                </Grid>
              </Grid>
            </ContentPaper>

            {course.lastBackup ? (
              <Alert
                className={styles.backupAlert}
                severity={
                  course.lastBackup.url
                    ? 'success'
                    : course.lastBackup.error
                    ? 'error'
                    : 'info'
                }
              >
                {course.lastBackup.url ? (
                  <Grid container spacing={1} direction="column">
                    <Grid item>
                      Last backup created at:{' '}
                      <strong>
                        {format(
                          new Date(course.lastBackup.createdAt),
                          'MMM do yyyy',
                        )}
                      </strong>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={copied ? <DoneIcon /> : <LinkIcon />}
                          onClick={handleCopyLastBackupUrl}
                        >
                          {copied ? 'Copied!' : 'Copy link'}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          component="a"
                          href={course.lastBackup.url}
                          target="_blank"
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<SaveIcon />}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : course.lastBackup.error ? (
                  <Grid container spacing={1} direction="column">
                    <Grid item>
                      Last backup failed with a message:{' '}
                      <strong>{course.lastBackup.error}</strong>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<ReplayIcon />}
                          onClick={handleCreateBackup}
                          disabled={isCreatingCourseBackup}
                        >
                          Try again
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <>Course backup is in progress...</>
                )}
              </Alert>
            ) : null}

            <SectionTitle
              className={styles.sectionTitle}
              icon={<YouTubeIcon />}
            >
              Modules
            </SectionTitle>

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToWindowEdges]}
              onDragEnd={handleDragEnd}
            >
              <Grid container spacing={2}>
                <SortableContext
                  items={course.modules || []}
                  strategy={verticalListSortingStrategy}
                >
                  {(course.modules || []).map((m) => (
                    <Grid key={m.id} item container>
                      <ModuleItem
                        {...m}
                        courseId={courseId}
                        achievementsCount={
                          m.achievements ? m.achievements.length : 0
                        }
                        lessonsCount={m.lessons ? m.lessons.length : 0}
                      />
                    </Grid>
                  ))}
                </SortableContext>

                <Grid item container>
                  <WideButton
                    color="primary"
                    startIcon={<AddIcon />}
                    className={styles.addButton}
                    component={Link}
                    to={`/admin/courses/${courseId}/modules/create`}
                  >
                    Add New Module
                  </WideButton>
                </Grid>
              </Grid>
            </DndContext>
          </div>
        </UserDetailsLayout>
      )}
    </PageLoader>
  );
};

export default AdminCourseOverview;
