import { put, call, select } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { selectUser, fetchUserSuccess } from './user';
import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { selectMe, fetchMeSuccess } from './me';

const MODULE = 'update-user';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPDATE_USER_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPDATE_USER_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const updateUser = Exec.requestActionCreator(MODULE);
export const updateUserLoading = Exec.loadingActionCreator(MODULE);
export const updateUserSuccess = Exec.successActionCreator(MODULE);
export const updateUserError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpdateUserRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpdateUser = function* ({ payload, meta }) {
  const { userId, ...attributes } = payload;
  const me = yield select(selectMe);
  const user = yield select(selectUser);

  try {
    yield put(closeSnackbar(UPDATE_USER_REQUEST));
    yield put(updateUserLoading());
    const { data } = yield call(Api.updateUser, userId, attributes);
    if (me && userId === me.id) {
      yield put(fetchMeSuccess({ data }));
    }
    if (user && userId === user.id) {
      yield put(fetchUserSuccess({ data }));
    }
    yield put(updateUserSuccess({ data }, meta));
  } catch (err) {
    yield put(updateUserError(err, meta));
    yield put(
      enqueueSnackbar(UPDATE_USER_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpdateUser);
