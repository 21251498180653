import { put, call } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import { Link } from 'react-router-dom';

import * as Exec from 'Redux/creators';
import SnackbarAction from 'Components/shared/snackbar-action';
import * as Api from 'Api';

import { Button } from '@material-ui/core';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { fetchMeSuccess } from './me';

const MODULE = 'confirm-email-change';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CONFIRM_EMAIL_CHANGE_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const confirmEmailChange = Exec.requestActionCreator(MODULE);
export const confirmEmailChangeLoading = Exec.loadingActionCreator(MODULE);
export const confirmEmailChangeSuccess = Exec.successActionCreator(MODULE);
export const confirmEmailChangeError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectConfirmEmailChangeRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const renderTryAgain = (id) => (
  <SnackbarAction
    id={id}
    render={
      <Button color="inherit" component={Link} to="/account/me">
        TRY AGAIN
      </Button>
    }
  />
);
const doConfirmEmailChange = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(CONFIRM_EMAIL_CHANGE_REQUEST));
    yield put(confirmEmailChangeLoading());
    const { data } = yield call(Api.confirmEmailChange, payload.code);
    yield put(
      batchActions([
        fetchMeSuccess({ data }),
        confirmEmailChangeSuccess(null, meta),
      ]),
    );
  } catch (err) {
    yield put(confirmEmailChangeError(err, meta));
    yield put(
      enqueueSnackbar(CONFIRM_EMAIL_CHANGE_REQUEST, {
        message:
          err.code === 'ERROR_CODE_VERIFY_TOKEN_INVALID'
            ? 'The code you used is no longer valid'
            : err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : 'There was an error, try again later',
        variant: 'error',
        action:
          err.code === 'ERROR_CODE_VERIFY_TOKEN_INVALID'
            ? renderTryAgain
            : null,
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doConfirmEmailChange);
