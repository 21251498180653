import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'flag-icon-css/css/flag-icon.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import './wdyr';

import ReactDOM from 'react-dom';

import createStore from 'Redux/store';

import reportWebVitals from './report-web-vitals';
import createBrowserHistory from './create-browser-history';
import App from './app';

const { REACT_APP_REPORT_WEB_VITALS } = process.env;

const history = createBrowserHistory();
const store = createStore({});
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App store={store} history={history} />, rootElement);
} else {
  ReactDOM.render(<App store={store} history={history} />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(REACT_APP_REPORT_WEB_VITALS === 'yes' ? console.log : null);
