/* eslint-disable react/prop-types */
import { Navigate, useLocation } from 'react-router';
import { useStore, useDispatch } from 'react-redux';
import {
  useCallback,
  memo,
  createContext,
  useContext,
  useEffect,
  Suspense,
  useRef,
} from 'react';
import { isFunction } from 'lodash';
import { setPageSuspended } from 'Redux/actions';

const FallbackElementRegisterContext = createContext();
const FallbackContext = createContext();

// It would be too much effor to maintain the elementRef in the react tree
// so I made a global. We only have one PageLoaderSuspense instance anyway
const elementRef = { current: null };

const PageLoaderFallback = (props) => {
  const { default: defaultElement } = props;
  const dispatch = useDispatch();
  const [element] = useContext(FallbackElementRegisterContext);

  useEffect(() => {
    dispatch(setPageSuspended(true));
    return () => dispatch(setPageSuspended(false));
  }, [dispatch]);

  return (
    <FallbackContext.Provider value={{ inFallback: true, defaultElement }}>
      {element ? element : defaultElement}
    </FallbackContext.Provider>
  );
};

export const PageLoaderSuspense = (props) => {
  const { children, fallback } = props;

  const registerFallbackElement = useCallback(() => {
    elementRef.current = children;
  }, [children]);

  const resetFallbackElement = useCallback(() => {
    elementRef.current = null;
  }, []);

  return (
    <FallbackElementRegisterContext.Provider
      value={[
        elementRef.current,
        registerFallbackElement,
        resetFallbackElement,
      ]}
    >
      <Suspense fallback={<PageLoaderFallback default={fallback} />}>
        {children}
      </Suspense>
    </FallbackElementRegisterContext.Provider>
  );
};

export const PageLoaderReset = (props) => {
  const { children } = props;

  const [, , reset] = useContext(FallbackElementRegisterContext);
  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export const useInFallback = () => {
  const { inFallback } = useContext(FallbackContext) || {};
  return inFallback;
};

const PageLoader = (props) => {
  const { children, loading, error } = props;
  const store = useStore();
  const { pathname, search } = useLocation();
  const [, register] = useContext(FallbackElementRegisterContext);
  const { inFallback, defaultElement } = useContext(FallbackContext) || {};
  const scrollRef = useRef(false);

  //
  // This effect will only be executed if the child renderer
  // doesn't throw suspensful promise and it's safe from being
  // called in the suspense fallback.
  //
  // Because the page might be mounted few times during this process
  // we have moved scroll-to-top action here to be executed once
  // the page is actually loaded
  //
  useEffect(() => {
    if (!inFallback) {
      register();

      if (window && !scrollRef.current) {
        scrollRef.current = true;
        window.scrollTo(0, 0);
      }
    }
  }, [inFallback, register]);

  if (loading && !inFallback) {
    throw new Promise((resolve) => {
      const unsubscribe = store.subscribe(() => {
        resolve();
        unsubscribe();
      });
    });
  }

  if (loading && inFallback) {
    return defaultElement || null;
  }

  if (error) {
    if (
      ['ERROR_CODE_AUTH_TOKEN_INVALID', 'ERROR_CODE_UNAUTHORIZED'].includes(
        error.code,
      )
    ) {
      return (
        <Navigate
          replace
          to={'/signin?redirectTo=' + pathname + encodeURIComponent(search)}
        />
      );
    }
    throw error;
  }

  return isFunction(children) ? children() : children;
};

export default memo(PageLoader);
