import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useCallback, useMemo, useState } from 'react';
import { get, filter, sumBy, max, uniqBy, capitalize, orderBy } from 'lodash';
import { format } from 'date-fns';
import clsx from 'clsx';

import exportTransactionsUrl from 'Util/export-transactions-url';
import ensurePrice from 'Util/ensure-price';
import countries from 'Util/countries';
import {
  selectCoursesRequest,
  selectMeRequest,
  selectPaymentMethods,
  selectPaymentMethodsRequest,
  selectTransactions,
  selectTransactionsRequest,
  selectTrialsRequest,
  selectUser,
  selectUserRequest,
} from 'Redux/selectors';
import {
  fetchUser,
  fetchCourses,
  fetchPaymentMethods,
  fetchTransactions,
  fetchMe,
  fetchTrials,
} from 'Redux/actions';
import FilterAltIcon from 'Icons/filter-alt';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import RefundTransactionsDialog from 'Dialogs/refund-transactions-dialog';
import CreatePaymentDialog from 'Dialogs/create-payment-dialog';
import CancelTrialsDialog from 'Dialogs/cancel-trials-dialog';
import PageLoader from 'Components/shared/page-loader';
import OptionListItem from 'Components/shared/option-list-item';
import DropdownMenu from 'Components/shared/dropdown-menu';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TrialsTable from 'Components/pages/admin/trials-table';
import TransactionsTable from 'Components/pages/admin/transactions-table';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Tabs from 'Components/pages/admin/tabs';
import StripeConnectionError from 'Components/pages/admin/stripe-connection-error';
import StripeConnection from 'Components/pages/admin/stripe-connection';
import MoneyBox from 'Components/pages/admin/money-box';
import FinancialInfoTable from 'Components/pages/admin/financial-info-table';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import {
  Create as CreateIcon,
  GetApp as GetAppIcon,
  Add as AddIcon,
  Undo as UndoIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import {
  List,
  ListItem,
  Button,
  makeStyles,
  Typography,
  Checkbox,
  Grid,
  MenuItem,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3.5),
  },
  tabs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  cards: {
    width: '100%',
    alignSelf: 'flex-start',
    display: 'grid',
    gap: theme.spacing(3) + 'px',
    alignItems: 'start',
    justifyItems: 'start',
    gridTemplateColumns: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  list: {
    width: '100%',
    flexGrow: 1,
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(3),
  },
  listNoPadding: {
    paddingLeft: 0,
  },
  listFull: {
    [theme.breakpoints.up('md')]: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2.5, 3, 2, 0),
    minHeight: 73,
  },
  headerWithLeftPadding: {
    paddingLeft: theme.spacing(3),
  },
  transactionsTitleAndButtons: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  title: {
    flexGrow: 1,
  },
  buttons: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      alignSelf: 'flex-end',
      marginTop: 0,
    },
  },
  outlinedButton: {
    color: theme.palette.text.secondary,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  gridItem: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,

    '&:nth-child(-n + 1)': {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    '&:nth-child(odd)': {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
    '&:nth-child(-n + 2)': {
      borderTop: 'none',
    },
    '&:first-child, &:nth-child(2n + 1)': {
      borderLeft: 'none',
    },
  },
  creditAccount: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  creditAccountItem: {
    flex: 1,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    [theme.breakpoints.up('sm')]: {
      '&:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: 'none',
      },
    },
  },
}));

const AdminUserFinancials = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [filters, setFilters] = useState(['succeeded', 'refunded', 'failed']);
  const [selectedTransations, setSelectedTransations] = useState([]);
  const [selectedTrials, setSelectedTrials] = useState([]);
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const user = useSelector(selectUser);
  const paymentMethods = useSelector(selectPaymentMethods);
  const transactions = useSelector(selectTransactions);

  const stripeCustomerId = user ? user.stripeCustomerId : null;
  const nextSubscriptionPaymentAt = transactions
    ? orderBy(
        transactions.map((t) => t.nextSubscriptionPaymentAt).filter(Boolean),
        'asc',
      )[0]
    : [];

  const { isRequested: isMeRequested } = useSelector(selectMeRequest);
  const { isLoading: isLoadingBasics, error: errorBasics } =
    useCombinedRequestsSelector(
      selectMeRequest,
      selectCoursesRequest,
      selectUserRequest,
    );
  const { isLoading: isLoadingData, error: errorData } =
    useCombinedRequestsSelector(
      selectPaymentMethodsRequest,
      selectTransactionsRequest,
      selectTrialsRequest,
    );
  const isLoading =
    isLoadingBasics || (stripeCustomerId ? isLoadingData : false);
  const error = errorBasics || (stripeCustomerId ? errorData : null);

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchUser({ userId }));
  }, [dispatch, userId]);

  useScopedDispatchEffect(() => {
    if (stripeCustomerId) {
      dispatch(fetchPaymentMethods({ userId }));
      dispatch(fetchTransactions({ userId }));
      dispatch(fetchTrials({ userId }));
    }
  }, [dispatch, stripeCustomerId, userId]);

  const handleToggleFilter = useMemoizedCallback(
    (id) => () => {
      if (filters.includes(id)) {
        setFilters(filters.filter((f) => f !== id));
      } else {
        setFilters([...filters, id]);
      }
    },
    [filters],
  );
  const handleExport = useCallback(() => {
    // Here, we need to fetch some user first so that, when we navigate to
    // the export URL, the token will be refreshed (if previously expired)
    dispatch(fetchMe(null, { isPromise: true })).then(() => {
      window.open(exportTransactionsUrl({ userId }));
    });
  }, [dispatch, userId]);

  const [showRefundTransactionsDialog, hideRefundTransactionsDialog] = useModal(
    () => (
      <RefundTransactionsDialog
        transactionIds={selectedTransations}
        // eslint-disable-next-line react/jsx-no-bind
        onClose={(success) => {
          if (success) {
            setSelectedTransations([]);
          }
          hideRefundTransactionsDialog();
        }}
      />
    ),
    [selectedTransations],
  );
  const [showCreatePaymentDialog, hideCreatePaymentDialog] = useModal(() => (
    <CreatePaymentDialog onClose={hideCreatePaymentDialog} />
  ));
  const [showCancelTrialsDialog, hideCancelTrialsDialog] = useModal(
    () => (
      <CancelTrialsDialog
        trialIds={selectedTrials}
        // eslint-disable-next-line react/jsx-no-bind
        onClose={(success) => {
          if (success) {
            setSelectedTrials([]);
          }
          hideCancelTrialsDialog();
        }}
      />
    ),
    [selectedTrials],
  );

  const paymentMethod = useMemo(
    () => get(paymentMethods, '0') || {},
    [paymentMethods],
  );
  const paymentMethodCountry = useMemo(
    () =>
      paymentMethod &&
      paymentMethod.card &&
      countries.find((c) => c.code === paymentMethod.card.country),
    [paymentMethod],
  );

  const aggregate = useMemo(() => {
    const successfulTransactions = filter(transactions, [
      'status',
      'succeeded',
    ]);
    // TODO: fix this: send priceId with transaction, group by courseId & priceId
    // and then sum all and multiply with installments
    const groupedUnique = uniqBy(successfulTransactions, 'courseId').map(
      (t) => ({
        ...t,
        grossRevenue: t.grossAmount * t.installmentCount,
        netRevenue: t.netAmount * t.installmentCount,
      }),
    );

    const grossRevenue = sumBy(groupedUnique, 'grossRevenue');
    const netRevenue = sumBy(groupedUnique, 'netRevenue');
    const cashCollected = sumBy(successfulTransactions, 'grossAmount');
    const netCashCollected = sumBy(successfulTransactions, 'netAmount');

    return {
      grossRevenue: max([0, grossRevenue]),
      netRevenue: max([0, netRevenue]),
      cashCollected: max([0, cashCollected]),
      netCashCollected: max([0, netCashCollected]),
    };
  }, [transactions]);

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <UserDetailsLayout>
          <Breadcrumbs
            className={styles.breadcrumbs}
            links={[
              { href: '/admin/users', label: 'Users' },
              { label: 'Financials' },
            ]}
          />
          <TitleAndAction
            className={styles.titleAndAction}
            title={
              <Typography variant="h4">
                {user.firstName + ' ' + user.lastName}
              </Typography>
            }
            button={
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/admin/users/${userId}/edit`}
                startIcon={<CreateIcon />}
              >
                Edit
              </Button>
            }
          />
          <Tabs className={styles.tabs} userId={userId} value={2} />

          <div className={styles.cards}>
            {!stripeCustomerId ? (
              <StripeConnectionError
                ConnectButtonProps={{
                  component: Link,
                  to: `/admin/users/${userId}/edit`,
                }}
                className={styles.listFull}
              />
            ) : (
              <>
                <List
                  disablePadding
                  className={clsx(styles.list, styles.listNoPadding)}
                >
                  <ListItem
                    className={clsx(
                      styles.header,
                      styles.headerWithLeftPadding,
                    )}
                    disableGutters
                    divider
                  >
                    <Typography className={styles.title} variant="h6">
                      Financial Info
                    </Typography>
                  </ListItem>

                  <div className={styles.grid}>
                    <MoneyBox
                      className={styles.gridItem}
                      data-testid="grossRevenue"
                      title="Gross Revenue"
                      value={ensurePrice(aggregate.grossRevenue)}
                    />
                    <MoneyBox
                      className={styles.gridItem}
                      data-testid="netRevenue"
                      title="Net Revenue"
                      value={ensurePrice(aggregate.netRevenue)}
                    />
                    <MoneyBox
                      className={styles.gridItem}
                      data-testid="cashCollected"
                      title="Cash Collected"
                      value={ensurePrice(aggregate.cashCollected)}
                    />
                    <MoneyBox
                      className={styles.gridItem}
                      data-testid="netCashCollected"
                      title="Net Cash Collected"
                      value={ensurePrice(aggregate.netCashCollected)}
                    />
                    <MoneyBox
                      className={styles.gridItem}
                      data-testid="transactions"
                      title="Transactions"
                      value={
                        transactions.length ? `${transactions.length}` : '-'
                      }
                    />
                    <MoneyBox
                      className={styles.gridItem}
                      data-testid="failedTransactions"
                      title="Failed Transactions"
                      value={
                        transactions.length
                          ? `${
                              transactions.filter((t) => t.status === 'failed')
                                .length
                            }`
                          : '-'
                      }
                    />
                  </div>
                </List>
                <List disablePadding className={styles.list}>
                  <ListItem className={styles.header} disableGutters divider>
                    <Typography className={styles.title} variant="h6">
                      Payment Info
                    </Typography>
                  </ListItem>
                  <OptionListItem
                    data-testid="paymentMethodType"
                    title="Payment Method"
                    compact
                    divider
                    value={
                      paymentMethod.type === 'card' ? (
                        <Typography variant="body1">Credit Card</Typography>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          Unknown.
                        </Typography>
                      )
                    }
                  />
                  {paymentMethod.type === 'card' ? (
                    <OptionListItem
                      data-testid="ccnumber"
                      title="Credit Card #"
                      compact
                      divider
                      value={
                        <Typography variant="body1">
                          ●●●● ●●●● ●●●● {paymentMethod.card.last4}
                        </Typography>
                      }
                    />
                  ) : null}
                  <OptionListItem
                    data-testid="name"
                    title="Full Name"
                    compact
                    divider
                    value={
                      <Typography variant="body1">
                        {paymentMethod.name}
                      </Typography>
                    }
                  />
                  <OptionListItem
                    data-testid="country"
                    title="Payment Country"
                    compact
                    value={
                      paymentMethodCountry ? (
                        <Typography variant="body1">
                          {paymentMethodCountry.name}
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          Unknown.
                        </Typography>
                      )
                    }
                  />
                </List>
                <List
                  disablePadding
                  className={clsx(
                    styles.list,
                    styles.listNoPadding,
                    styles.listFull,
                  )}
                >
                  <ListItem
                    className={clsx(
                      styles.header,
                      styles.headerWithLeftPadding,
                      styles.transactionsTitleAndButtons,
                    )}
                    disableGutters
                    divider
                  >
                    <Typography className={styles.title} variant="h6">
                      Transactions
                    </Typography>
                    <div className={styles.buttons}>
                      {selectedTransations.length ? (
                        <Button
                          className={styles.outlinedButton}
                          variant="outlined"
                          startIcon={<UndoIcon />}
                          onClick={showRefundTransactionsDialog}
                        >
                          Refund
                        </Button>
                      ) : (
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          wrap={isSmUp ? 'nowrap' : 'wrap'}
                        >
                          <Grid item>
                            <DropdownMenu
                              keepOpen
                              menuClassName={styles.menu}
                              control={
                                isSmUp ? (
                                  <Button
                                    data-testid="toggleShowFilters"
                                    className={styles.outlinedButton}
                                    variant="outlined"
                                    startIcon={<FilterAltIcon />}
                                  >
                                    Filter
                                  </Button>
                                ) : (
                                  <IconButton
                                    data-testid="toggleShowFilters"
                                    size="small"
                                  >
                                    <FilterAltIcon />
                                  </IconButton>
                                )
                              }
                            >
                              {['succeeded', 'refunded', 'failed'].map(
                                (option) => (
                                  <MenuItem
                                    key={option}
                                    className={styles.menuItem}
                                    onClick={handleToggleFilter(option)}
                                  >
                                    <Checkbox
                                      data-testid={'selectOption-' + option}
                                      color="primary"
                                      checked={filters.includes(option)}
                                      onClick={handleToggleFilter(option)}
                                    />
                                    {capitalize(option)}
                                  </MenuItem>
                                ),
                              )}
                            </DropdownMenu>
                          </Grid>
                          <Grid item>
                            {isSmUp ? (
                              <Button
                                className={styles.outlinedButton}
                                variant="outlined"
                                startIcon={<GetAppIcon />}
                                disabled={isMeRequested}
                                onClick={handleExport}
                              >
                                Export
                              </Button>
                            ) : (
                              <IconButton
                                size="small"
                                disabled={isMeRequested}
                                onClick={handleExport}
                              >
                                <GetAppIcon />
                              </IconButton>
                            )}
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<AddIcon />}
                              onClick={showCreatePaymentDialog}
                            >
                              Create Payment
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  </ListItem>
                  <TransactionsTable
                    filters={filters}
                    selected={selectedTransations}
                    onSelect={setSelectedTransations}
                  />
                </List>
                <List
                  disablePadding
                  className={clsx(
                    styles.list,
                    styles.listNoPadding,
                    styles.listFull,
                  )}
                >
                  <ListItem
                    className={clsx(
                      styles.header,
                      styles.headerWithLeftPadding,
                    )}
                    disableGutters
                    divider
                  >
                    <Typography className={styles.title} variant="h6">
                      Detailed Financial Info
                    </Typography>
                  </ListItem>
                  <FinancialInfoTable />
                </List>
                <List
                  disablePadding
                  className={clsx(
                    styles.list,
                    styles.listNoPadding,
                    styles.listFull,
                  )}
                >
                  <ListItem
                    className={clsx(
                      styles.header,
                      styles.headerWithLeftPadding,
                    )}
                    disableGutters
                    divider
                  >
                    <Typography className={styles.title} variant="h6">
                      Credit Account
                    </Typography>
                  </ListItem>

                  <div className={styles.creditAccount}>
                    <MoneyBox
                      className={styles.creditAccountItem}
                      data-testid="accountStanding"
                      title="Account Standing"
                      value={capitalize(user.paymentAccountStatus)}
                    />
                    <MoneyBox
                      className={styles.creditAccountItem}
                      data-testid="balanceOwed"
                      title="Balance Owed"
                      value={ensurePrice(
                        max([
                          0,
                          aggregate.grossRevenue - aggregate.cashCollected,
                        ]),
                      )}
                    />
                    <MoneyBox
                      className={styles.creditAccountItem}
                      data-testid="nextSubscriptionPaymentAt"
                      title="Next Payment Date"
                      value={
                        nextSubscriptionPaymentAt
                          ? format(
                              new Date(nextSubscriptionPaymentAt),
                              'MMM d, yyyy',
                            )
                          : '-'
                      }
                    />
                  </div>
                </List>
                <List
                  disablePadding
                  className={clsx(
                    styles.list,
                    styles.listNoPadding,
                    styles.listFull,
                  )}
                >
                  <ListItem
                    className={clsx(
                      styles.header,
                      styles.headerWithLeftPadding,
                      styles.transactionsTitleAndButtons,
                    )}
                    disableGutters
                    divider
                  >
                    <Typography className={styles.title} variant="h6">
                      Trials
                    </Typography>
                    <div className={styles.buttons}>
                      {selectedTrials.length ? (
                        <Button
                          className={styles.outlinedButton}
                          variant="outlined"
                          startIcon={<CloseIcon />}
                          onClick={showCancelTrialsDialog}
                        >
                          Cancel
                        </Button>
                      ) : null}
                    </div>
                  </ListItem>
                  <TrialsTable
                    selected={selectedTrials}
                    onSelect={setSelectedTrials}
                  />
                </List>
                <StripeConnection
                  className={styles.listFull}
                  customerId={stripeCustomerId}
                />
              </>
            )}
          </div>
        </UserDetailsLayout>
      )}
    </PageLoader>
  );
};

export default AdminUserFinancials;
