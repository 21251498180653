import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPLOAD_ACHIEVEMENT_GRAPHIC_SUCCESS } from './upload-achievement-graphic';
import { UPDATE_ACHIEVEMENT_SUCCESS } from './update-achievement';
import { SUBMIT_ACHIEVEMENT_SUCCESS } from './submit-achievement';
import {
  SORT_ACHIEVEMENTS_ERROR,
  SORT_ACHIEVEMENTS_SUCCESS,
} from './sort-achievements';
import { CREATE_ACHIEVEMENT_SUCCESS } from './create-achievement';

const MODULE = 'achievements';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchAchievements = Exec.requestActionCreator(MODULE);
export const fetchAchievementsLoading = Exec.loadingActionCreator(MODULE);
export const fetchAchievementsSuccess = Exec.successActionCreator(MODULE);
export const fetchAchievementsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);

export const reducer = (state, action) => {
  switch (action.type) {
    case UPLOAD_ACHIEVEMENT_GRAPHIC_SUCCESS:
    case UPDATE_ACHIEVEMENT_SUCCESS:
    case SUBMIT_ACHIEVEMENT_SUCCESS:
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((v) => (v.id === data.id ? data : v)),
      };
    case CREATE_ACHIEVEMENT_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };
    }
    case SORT_ACHIEVEMENTS_ERROR: {
      if (!state || !state.data || !action.payload.error.data) {
        return state;
      }
      return {
        ...state,
        data: action.payload.error.data,
      };
    }
    case SORT_ACHIEVEMENTS_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: action.payload.data,
      };
    }
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////

export const selectAchievementsRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectAchievements = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchAchievements = function* ({ payload, meta }) {
  try {
    const { data } = yield call(
      Api.getAchievements,
      payload.courseId,
      payload.skipAccessCheck,
    );
    yield put(fetchAchievementsSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchAchievementsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchAchievements);
