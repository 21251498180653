import PageContainer from 'Components/shared/page-container';
import LogoLarge from 'Components/shared/logo-large';

import { makeStyles, CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    minHeight: '100%',
  },
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 360,
    boxShadow: theme.shadows[24],
    borderRadius: 20,
    backgroundColor: 'white',
    padding: theme.spacing(10, 2, 4),
  },
  loader: {
    top: -50,
    width: 100,
    height: 100,
    borderRadius: '50%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    boxShadow: theme.shadows[8],
  },
  progress: {
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  bottom: {
    opacity: 0.1,
  },
  circle: {
    strokeLinecap: 'round',
  },
  loading: {
    color: theme.palette.primary.light,
  },
  logo: {
    width: '100%',
    height: '100%',
    color: 'black',
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 200,
  },
}));

const LoadingPage = () => {
  const styles = useStyles();

  return (
    <PageContainer className={styles.root}>
      <div className={styles.paper}>
        <div className={styles.loader}>
          <CircularProgress
            variant="determinate"
            className={styles.bottom}
            size={80}
            thickness={4}
            value={100}
          />
          <CircularProgress
            thickness={4}
            className={styles.progress}
            size={80}
            classes={{ circle: styles.circle }}
          />
        </div>
        <Typography variant="h6" className={styles.loading}>
          Loading
        </Typography>
        <LogoLarge className={styles.logo} />
      </div>
    </PageContainer>
  );
};

export default LoadingPage;
