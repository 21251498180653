import { matchPath, useLocation, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { trimStart } from 'lodash';
import clsx from 'clsx';

import ensurePrice from 'Util/ensure-price';
import {
  selectCoursesRequest,
  selectMe,
  selectMeRequest,
  selectAffiliateStats,
  selectAffiliateStatsRequest,
  selectCourses,
  selectUpdateMeRequest,
} from 'Redux/selectors';
import { fetchCourses, fetchAffiliateStats, updateMe } from 'Redux/actions';
import PaypalIcon from 'Icons/paypal';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import UpdateMeFieldDialog from 'Dialogs/update-me-field-dialog';
import GetReferralLinkDialog from 'Dialogs/get-referral-link-dialog';
import ConfirmCollectEarningsDialog from 'Dialogs/confirm-collect-earnings-dialog';
import StickyTabs from 'Components/shared/sticky-tabs';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import CourseCard from 'Components/shared/course-card';
import Redeem from 'Components/pages/referrals/redeem';
import Earnings from 'Components/pages/referrals/earnings';
import EarningBox from 'Components/pages/referrals/earning-box';

import { Skeleton } from '@material-ui/lab';
import { Add as AddIcon } from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Typography,
  Tab,
  Grid,
  Link as MuiLink,
  Button,
} from '@material-ui/core';

const { REACT_APP_STATIC_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  topSection: {
    paddingBottom: theme.spacing(3),
  },
  earningsSection: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(8),
  },
  contentSection: {
    width: '100%',
    backgroundColor: theme.palette.grey[50],
    paddingTop: theme.spacing(1),
  },
  contentContainer: {
    paddingBottom: theme.spacing(10),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    maxWidth: 768,
    margin: theme.spacing(8, 0, 3),
  },
  noCourses: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.background.paper,
    maxWidth: 728,
    width: `calc(100% + ${theme.spacing(2)}px)`,
    margin: theme.spacing(0, -1),
    marginTop: theme.spacing(6),
    borderRadius: 16,
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
  },
  boxes: {
    display: 'grid',
    gap: theme.spacing(1.5) + 'px',
    width: '100%',

    [theme.breakpoints.up('xs')]: {
      width: 'auto',
      gridTemplateColumns: 'repeat(2, 154px)',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(4, 180px)',
    },
    [theme.breakpoints.up('lg')]: {
      gap: theme.spacing(2.5) + 'px',
      gridTemplateColumns: 'repeat(4, 224px)',
    },
  },
  highlight: {
    width: '100%',
  },
  tabs: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      top: 79,
    },
  },
  yourEarningsTitle: {
    marginBottom: theme.spacing(3),
  },
  pendingEarnings: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 450,
    width: `calc(100% + ${theme.spacing(2)}px)`,
    padding: theme.spacing(1),
    margin: theme.spacing(0, -1),
    marginTop: theme.spacing(6),
    borderRadius: 16,

    [theme.breakpoints.up('sm')]: {
      width: '100%',
      padding: theme.spacing(4, 3),
      boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    },
  },
  estimatedTotal: {
    fontWeight: 400,
  },
  green: {
    color: theme.palette.success.main,
  },
  collectEarningsButton: {
    marginTop: theme.spacing(2),
  },
  successButton: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  paypal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.background.paper,
    maxWidth: 728,
    width: `calc(100% + ${theme.spacing(2)}px)`,
    margin: theme.spacing(0, -1),
    marginTop: theme.spacing(6),

    [theme.breakpoints.up('sm')]: {
      width: '100%',
      borderRadius: 16,
      boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    },
  },
  paypalRow: {
    height: 140,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  paypalTitle: {
    marginBottom: theme.spacing(1),
  },
  paypalIcon: {
    fontSize: 60,
    marginRight: theme.spacing(3),
  },
  paypalDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    cursor: 'pointer',
    alignSelf: 'flex-start',
  },
}));

const ReferralsPage = () => {
  const contentRef = useRef(null);

  const styles = useStyles();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [copyUrl, setCopyUrl] = useState(null);

  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const dispatch = useDispatch();

  const stats = useSelector(selectAffiliateStats);
  const me = useSelector(selectMe);
  const courses = useSelector(selectCourses);

  const { isLoading: baseIsLoading, error: baseError } =
    useCombinedRequestsSelector(
      selectAffiliateStatsRequest,
      selectMeRequest,
      selectCoursesRequest,
    );

  const { isLoading: updateMeIsLoading, error: updateMeError } = useSelector(
    selectUpdateMeRequest,
  );

  const isLoading = me?.isAffiliate
    ? baseIsLoading
    : baseIsLoading || updateMeIsLoading;
  const error = me?.isAffiliate ? baseError : baseError || updateMeError;

  const coursesWithLinks = useMemo(
    () =>
      courses
        ? courses
            .filter((c) => c.affiliateUrlPath && c.affiliateDescription)
            .map((course) => ({
              ...course,
              description: {
                ...course.affiliateDescription,
                text: course.affiliateDescription.text
                  .split('\n')
                  .filter(Boolean)
                  .map((item, idx, arr) => {
                    return (
                      <span key={idx}>
                        {item}
                        {arr.length - 1 === idx ? null : <br />}
                      </span>
                    );
                  }),
              },
              subtitle: {
                COURSE: 'Course',
                BLUEPRINT: 'Blueprint',
              }[course.type],
              ButtonProps: {
                'data-testid': 'courseLink',
                label: 'Get Referral Link',
                component: 'button',
                onClick: () =>
                  setCopyUrl(
                    new URL(
                      '/i/' + trimStart(course.affiliateUrlPath, '/'),
                      REACT_APP_STATIC_URL,
                    ).href,
                  ),
              },
            }))
        : [],
    [courses],
  );

  useEffect(() => {
    dispatch(fetchAffiliateStats());
    dispatch(fetchCourses());
  }, [dispatch]);

  useEffect(() => {
    if (me && !me.isAffiliate) {
      dispatch(updateMe({ isAffiliate: true }));
    }
  }, [dispatch, me]);

  const handleChangeTab = useCallback(
    (event, newValue) => navigate(['#/rewards', '#/withdrawals'][newValue]),
    [navigate],
  );

  const match = matchPath('/#/:path/*', '/' + hash);
  const hasMatch = Boolean(match);

  const currentTab =
    {
      rewards: 0,
      withdrawals: 1,
    }[match && match.params.path] || 0;

  useEffect(() => {
    if (hasMatch) {
      contentRef.current &&
        contentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
    }
  }, [hasMatch, currentTab]);

  const handleCloseGetReferralLinkDialog = useCallback(
    () => setCopyUrl(null),
    [],
  );

  const [showConnectPaypalAccountModal, hideConnectPaypalAccountModal] =
    useModal(() => (
      <UpdateMeFieldDialog
        required
        variant="input"
        name="paypalAccount"
        actionName="Connect"
        title="PayPal Account"
        message="Use your PayPal email here. WARNING: Make sure the email you enter is correct!"
        onClose={hideConnectPaypalAccountModal}
      />
    ));

  const [showConfirmCollectEarningsDialog, hideConfirmCollectEarningsDialog] =
    useModal(() => (
      <ConfirmCollectEarningsDialog
        onClose={hideConfirmCollectEarningsDialog}
      />
    ));

  return (
    <PageLoader loading={isLoading || !me.isAffiliate} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          {copyUrl ? (
            <GetReferralLinkDialog
              url={copyUrl}
              onClose={handleCloseGetReferralLinkDialog}
            />
          ) : null}
          <PageContainer
            maxWidth={1024}
            component="section"
            className={styles.topSection}
          >
            <Typography variant="h4" align="center" className={styles.title}>
              Refer A Friend
            </Typography>
            <Typography variant="body1" align="center">
              Refer Freedom.co to your friends and family and get them to join
              the freedom movement.
              <br />
              <strong>Make money</strong> referring your friends while also
              giving them an <strong>exclusive discount</strong>.
            </Typography>

            <div className={styles.cards}>
              <Grid
                container
                spacing={isLgUp ? 6 : isMdUp ? 3 : 2}
                justify="center"
              >
                {coursesWithLinks.map((course, index) => (
                  <Grid key={index} item xs={12} sm={6}>
                    <CourseCard elevation={8} {...course} />
                  </Grid>
                ))}
              </Grid>
              {!coursesWithLinks.length ? (
                <div className={styles.noCourses}>
                  <Typography variant="h5" align="center" gutterBottom>
                    You have to purchase a course to be able to refer it.
                  </Typography>
                  <Button
                    color="primary"
                    component={Link}
                    to="/"
                    variant="contained"
                  >
                    See Courses
                  </Button>
                </div>
              ) : null}
            </div>
          </PageContainer>

          <PageContainer
            component="section"
            maxWidth={currentTab === 2 ? 1400 : 1104}
            className={styles.earningsSection}
          >
            <Typography
              variant="h5"
              align="center"
              className={styles.yourEarningsTitle}
            >
              Your Earnings
            </Typography>

            <div className={styles.boxes}>
              <EarningBox
                className={styles.highlight}
                label="Pending"
                value={isLoading ? <Skeleton /> : ensurePrice(stats.pending)}
              />
              <EarningBox
                className={styles.highlight}
                label="In Review"
                value={isLoading ? <Skeleton /> : ensurePrice(stats.inReview)}
              />
              <EarningBox
                className={styles.highlight}
                label="Paid Out"
                value={isLoading ? <Skeleton /> : ensurePrice(stats.paidOut)}
              />
              <EarningBox
                className={styles.highlight}
                label="Total Earnings"
                value={
                  isLoading ? (
                    <Skeleton />
                  ) : (
                    ensurePrice(stats.pending + stats.inReview + stats.paidOut)
                  )
                }
              />
            </div>

            <div className={styles.pendingEarnings}>
              <Typography variant="h5" align="center">
                Your Next Payout
              </Typography>
              <Typography
                className={styles.estimatedTotal}
                variant="h5"
                align="center"
                gutterBottom={!me.paypalAccount && stats.pending}
              >
                Estimated Total:{' '}
                <span className={clsx(stats.pending && styles.green)}>
                  <strong>{ensurePrice(stats.pending)}</strong>
                </span>
              </Typography>

              {me.paypalAccount || !stats.pending ? null : (
                <Typography variant="body1" align="center">
                  Connect your paypal account to collect your balance.
                </Typography>
              )}

              <Button
                variant={
                  me.paypalAccount || !stats.pending ? 'contained' : 'outlined'
                }
                className={clsx(styles.collectEarningsButton, {
                  [styles.successButton]: stats.pending && me.paypalAccount,
                })}
                disabled={!stats.pending}
                startIcon={
                  me.paypalAccount || !stats.pending ? null : (
                    <AddIcon fontSize="large" />
                  )
                }
                onClick={
                  me.paypalAccount
                    ? showConfirmCollectEarningsDialog
                    : showConnectPaypalAccountModal
                }
              >
                <Typography variant="h6">
                  {stats.pending
                    ? me.paypalAccount
                      ? 'Collect Earnings'
                      : 'Connect Paypal'
                    : 'No Pending Earnings'}
                </Typography>
              </Button>
            </div>
          </PageContainer>

          <section ref={contentRef} className={styles.contentSection}>
            <PageContainer className={styles.contentContainer} maxWidth={1400}>
              <StickyTabs
                className={styles.tabs}
                value={currentTab}
                onChange={handleChangeTab}
                variant="scrollable"
                scrollButtons={isSmUp ? 'off' : 'on'}
              >
                <Tab label="Rewards" />
                <Tab label="Withdrawals" />
              </StickyTabs>
              {currentTab === 0 && <Earnings />}
              {currentTab === 1 && <Redeem />}

              {me.paypalAccount ? (
                <div className={styles.paypal}>
                  <Typography
                    variant="h5"
                    className={styles.paypalTitle}
                    align="center"
                  >
                    Payment Method
                  </Typography>

                  <div className={styles.paypalRow}>
                    <PaypalIcon className={styles.paypalIcon} />
                    <div className={styles.paypalDetails}>
                      <Typography variant="body1">
                        {me.paypalAccount}
                      </Typography>
                      <MuiLink
                        className={styles.link}
                        onClick={showConnectPaypalAccountModal}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Change Account
                        </Typography>
                      </MuiLink>
                    </div>
                  </div>
                </div>
              ) : null}
            </PageContainer>
          </section>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default ReferralsPage;
