import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import dynamicImageUrl from 'Util/dynamic-image-url';
import LogoLarge from 'Components/shared/logo-large';

import {
  Lock as LockIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@material-ui/icons';
import { blue, green, grey, purple } from '@material-ui/core/colors';
import { makeStyles, Button, ButtonBase, Typography } from '@material-ui/core';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

const useStyles = makeStyles((theme) => {
  const mediaSuperSmall = '@media only screen and (max-width: 320px)';

  return {
    root: (props) => ({
      touchAction: 'manipulation',
      position: 'relative',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      height: props.withDescription ? 360 : 200,
      padding: theme.spacing(3, 2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${props.imageUrl}")`,
      boxShadow: theme.shadows[props.elevation],
      backgroundSize: 'auto, cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      cursor: props.isReleased ? 'pointer' : 'default',
      ...(props.selectable
        ? {
            filter: 'grayscale(1)',
            boxShadow: 'none',
            transition: 'filter 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            '&:hover': {
              filter: 'grayscale(0)',
              boxShadow: theme.shadows[props.elevation],
            },
          }
        : {}),
      ...(props.selected
        ? {
            filter: 'grayscale(0)',
            boxShadow: theme.shadows[props.elevation],
          }
        : {}),
    }),
    logo: {
      position: 'absolute',
      color: 'white',
      left: 0,
      right: 0,
      backgroundColor: '#02060E',
      borderRadius: 4,
      boxShadow: theme.shadows[16],
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 214,
      height: 56,
      [mediaSuperSmall]: {
        width: 190,
        height: 48,
      },
    },
    iconContainer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      marginBottom: 36,
      left: 0,
      right: 0,
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      pointerEvents: 'none',
    },
    icon: {
      width: 40,
      height: 40,
      color: 'white',
    },
    text: (props) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      userSelect: 'none',
      opacity: props.locked ? 0.6 : 1,
      color: 'white',
      margin: 'auto',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: theme.spacing(3),
      right: theme.spacing(3),
    }),
    title: (props) => ({
      fontFamily: 'din-2014',
      color: 'white',
      textShadow: `${props.color} 0px 0px 2px, ${props.color} 0px 0px 2px, ${props.color} 0px 0px 2px, ${props.color} 0px 0px 2px, ${props.color} 0px 0px 2px, ${props.color} 0px 0px 2px`,
      textAlign: 'center',
      [mediaSuperSmall]: {
        fontSize: 28,
      },
      lineHeight: '43px',
      fontWeight: 800,
      fontSize: 31,
    }),
    subtitle: {
      fontFamily: 'din-2014',
      lineHeight: '43px',
      fontSize: 24,
    },
    description: {
      marginTop: theme.spacing(1.5),
    },
    button: (props) => ({
      position: 'absolute',
      left: theme.spacing(3),
      right: theme.spacing(3),
      bottom: theme.spacing(3),
      width: `calc(100% - ${theme.spacing(6)}px)`,
      color: 'white',
      ...(props.isReleased
        ? {
            backgroundColor: props.locked
              ? props.unlockAfterTrustpilotReview
                ? purple[500]
                : props.unlockAfterTrustpilotReviewInDays
                ? grey[500]
                : green[500]
              : blue[500],
            '&:hover': {
              backgroundColor: props.locked
                ? props.unlockAfterTrustpilotReview
                  ? purple[700]
                  : props.unlockAfterTrustpilotReviewInDays
                  ? grey[700]
                  : green[700]
                : blue[700],
            },
          }
        : {
            cursor: 'default',
            backgroundColor: grey[400],
          }),
    }),
    radio: (props) => ({
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      ...(props.selected
        ? {
            color: theme.palette.info.main,
          }
        : {
            color: 'white',
          }),
    }),
  };
});

const CourseCard = (props) => {
  const {
    className,
    elevation,
    isReleased,
    graphic,
    color,
    locked,
    unlockAfterTrustpilotReview,
    unlockAfterTrustpilotReviewInDays,
    title,
    selectable,
    selected,
    subtitle,
    noLogo,
    description,
    ButtonProps,
  } = props;
  const buttonLabel = ButtonProps ? ButtonProps.label : null;

  const styles = useStyles({
    imageUrl: dynamicImageUrl(graphic, {
      resize: {
        width: 360,
        height: 360,
        fit: 'cover',
      },
    }),
    selectable,
    selected,
    elevation,
    color,
    locked,
    unlockAfterTrustpilotReview,
    unlockAfterTrustpilotReviewInDays,
    isReleased: isReleased || !locked, // treat unlocked course as released
    withDescription: Boolean(description),
  });

  const { active, attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: active && active.id === props.id ? 10 : undefined,
  };

  return (
    <ButtonBase
      className={clsx(styles.root, className)}
      disableRipple
      disableTouchRipple
      component={Link}
      to=""
      {...ButtonProps}
      disabled={Boolean(active && active.id === props.id)}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {!noLogo ? (
        <div className={styles.logo}>
          <LogoLarge />
        </div>
      ) : null}

      {selectable && !ButtonProps?.disabled ? (
        selected ? (
          <RadioButtonCheckedIcon fontSize="large" className={styles.radio} />
        ) : (
          <RadioButtonUncheckedIcon fontSize="large" className={styles.radio} />
        )
      ) : null}

      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
        {description ? (
          <Typography
            className={styles.description}
            align="center"
            variant="body1"
          >
            {description.text}
          </Typography>
        ) : null}
      </div>
      {locked && isReleased && (
        <div className={styles.iconContainer}>
          <LockIcon className={styles.icon} />
        </div>
      )}
      {buttonLabel ? (
        <Button
          className={styles.button}
          variant="contained"
          size="medium"
          component={Link}
          to=""
          {...ButtonProps}
        >
          {buttonLabel}
        </Button>
      ) : null}
    </ButtonBase>
  );
};

CourseCard.propTypes = {
  id: PropTypes.string,
  elevation: PropTypes.number.isRequired,
  graphic: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  locked: PropTypes.bool.isRequired,
  unlockAfterTrustpilotReview: PropTypes.bool,
  unlockAfterTrustpilotReviewInDays: PropTypes.number,
  isReleased: PropTypes.bool,
  noLogo: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.shape({
    text: PropTypes.any,
    html: PropTypes.string,
  }),
  ButtonProps: PropTypes.any,
};

CourseCard.defaultProps = {
  elevation: 2,
  color: '#FFFFFF',
  locked: false,
  unlockAfterTrustpilotReview: false,
  unlockAfterTrustpilotReviewInDays: 0,
  noLogo: false,
};

export default CourseCard;
