import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectDeletePaymentMethodRequest } from 'Redux/selectors';
import { deletePaymentMethod, enqueueSnackbar } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmDeletePaymentMethodDialog = (props) => {
  const { paymentMethodId, onClose } = props;
  const { isLoading } = useSelector(selectDeletePaymentMethodRequest);
  const dispatch = useDispatch();

  const handleDelete = useCallback(() => {
    dispatch(deletePaymentMethod({ paymentMethodId }, { isPromise: true }))
      .then(() => {
        dispatch(
          enqueueSnackbar('DELETE_PAYMENT_METHOD', {
            variant: 'success',
            message: 'Payment method deleted successfully',
          }),
        );
        onClose && onClose();
      })
      .catch(noop);
  }, [dispatch, onClose, paymentMethodId]);

  return (
    <Dialog
      title="Delete Card"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleDelete}>
          Delete
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to delete this card? This is a permanent change
        and cannot be reverted.
      </Typography>
    </Dialog>
  );
};

ConfirmDeletePaymentMethodDialog.propTypes = {
  paymentMethodId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmDeletePaymentMethodDialog;
