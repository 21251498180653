import PropTypes from 'prop-types';
import clsx from 'clsx';

import dynamicImageUrl from 'Util/dynamic-image-url';
// import RichText from 'Components/shared/rich-text';
import LogoLarge from 'Components/shared/logo-large';

import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    ...(props.imageUrl
      ? {
          '&:before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundImage: `url("${props.imageUrl}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
          '&:after': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            background: 'rgba(0, 0, 0, 0.7)',
          },
          backgroundColor: theme.palette.grey[100],
        }
      : {}),
    width: '100%',
    position: 'relative',
  }),
  container: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(5, 3, 0),
    height: 400,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 0,
      height: 336,
    },
  },
  logo: (props) => ({
    width: '100%',
    height: '100%',
    maxWidth: 540,
    maxHeight: 60,
    padding: 0,
    color: 'white',
    marginBottom: props.hasChildren ? 48 : 0,
    [theme.breakpoints.up('sm')]: {
      maxHeight: 118,
    },
  }),
  richDescription: {
    fontFamily: 'din-2014',
    fontSize: '28px',
    lineHeight: '36px',
    textAlign: 'center',
    maxWidth: 800,
    width: '100%',
    color: 'white',
    '& strong': {
      color: theme.palette.secondary.main,
      fontWeight: 500,
    },
  },
}));

const CourseHeader = (props) => {
  const { className, course, children } = props;

  const styles = useStyles({
    imageUrl: course
      ? dynamicImageUrl(course.background, {
          resize: {
            width: window.innerWidth,
            height: 400,
            fit: 'cover',
          },
        })
      : null,
    hasChildren: Boolean(children),
  });

  return (
    <section className={clsx(className, styles.root)}>
      <div className={styles.container}>
        {course ? (
          <>
            <LogoLarge className={styles.logo} />
            {/* <RichText className={styles.richDescription}>
              {course.description.html}
            </RichText> */}
          </>
        ) : (
          <>
            <Skeleton variant="rect" className={styles.logo} />
            <Skeleton variant="text" className={styles.richDescription} />
          </>
        )}
        {children}
      </div>
    </section>
  );
};

CourseHeader.propTypes = {
  backTo: PropTypes.string,
  course: PropTypes.shape({
    description: PropTypes.shape({
      html: PropTypes.string,
    }).isRequired,
    graphic: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    background: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default CourseHeader;
