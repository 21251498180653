import { isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { toString } from 'lodash';
import clsx from 'clsx';

import {
  makeStyles,
  Typography,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    minHeight: 56,
    padding: theme.spacing(1, 3, 1, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3, 2, 0),
    },
  },
  titleContainer: (props) => ({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    maxWidth: 'calc(100% - 32px)',
    ...(props.compact
      ? {}
      : {
          [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
          },
        }),
  }),
  title: (props) => ({
    ...(props.compact
      ? {
          marginBottom: theme.spacing(0.5),
        }
      : {
          [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(0.5),
            minWidth: 208,
          },
        }),
  }),
}));

const OptionListItem = (props) => {
  const {
    className,
    compact,
    'data-testid': testId,
    name,
    title,
    message,
    required,
    value,
    actionIcon,
    ActionButtonProps,
    onActionClick,
    ...rest
  } = props;
  const styles = useStyles({ compact });

  const renderedValue = isValidElement(value)
    ? cloneElement(value, {
        'data-testid': testId + 'Value',
      })
    : value;

  return (
    <ListItem
      data-testid={testId}
      className={clsx(styles.root, className)}
      disableGutters
      {...rest}
    >
      <div className={styles.titleContainer}>
        <Typography
          variant="overline"
          color="textSecondary"
          className={styles.title}
        >
          {title}
        </Typography>
        {renderedValue}
      </div>
      {actionIcon && (
        <ListItemSecondaryAction>
          <IconButton
            data-testid={testId + 'Action'}
            data-name={name}
            data-title={title}
            data-message={message}
            data-required={toString(Boolean(required))}
            onClick={onActionClick}
            {...ActionButtonProps}
          >
            {actionIcon}
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

OptionListItem.propTypes = {
  compact: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  message: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.element.isRequired,
  actionIcon: PropTypes.element,
  ActionButtonProps: PropTypes.any,
  onActionClick: PropTypes.func,
};

OptionListItem.defaultProps = {
  compact: false,
};

export default OptionListItem;
