import PropTypes from 'prop-types';
import clsx from 'clsx';

import OptionListItem from 'Components/shared/option-list-item';

import { Launch as LaunchIcon } from '@material-ui/icons';
import {
  List,
  ListItem,
  Button,
  makeStyles,
  Typography,
  Grid,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
  },
  noPadding: {
    padding: '0 !important',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2.5, 3, 2),
  },
  row: {
    padding: theme.spacing(2, 3.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  statusAndId: {
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  customerId: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(6),
    },
  },
  button: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },

    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
}));

const StripeConnection = (props) => {
  const { className, customerId } = props;
  const styles = useStyles();
  const isXsUp = useMediaQuery((theme) => theme.breakpoints.up('xs'));

  return (
    <List disablePadding className={clsx(styles.root, className)}>
      <ListItem className={styles.header} disableGutters divider>
        <Typography className={styles.title} variant="h6">
          Stripe Connection
        </Typography>
      </ListItem>

      <Grid container spacing={2} className={styles.row}>
        <Grid item className={styles.statusAndId}>
          <div>
            <OptionListItem
              data-testid="status"
              title="status"
              className={styles.noPadding}
              compact
              value={<Typography variant="subtitle1">Connected</Typography>}
            />
          </div>
          {isXsUp ? (
            <Typography className={styles.customerId} variant="body2">
              Customer ID: {customerId}
            </Typography>
          ) : null}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://dashboard.stripe.com/customers/${customerId}`}
            endIcon={<LaunchIcon />}
          >
            {isXsUp ? 'Open In Stripe Dashboard' : 'View On Stripe'}
          </Button>
        </Grid>
      </Grid>
    </List>
  );
};

StripeConnection.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default StripeConnection;
