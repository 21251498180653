import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { camelCase, findIndex, toNumber } from 'lodash';
import { format } from 'date-fns';

import {
  selectCourses,
  selectCoursesRequest,
  selectEvents,
  selectEventsRequest,
  selectSubscriptionStepsRequest,
} from 'Redux/selectors';
import {
  fetchCourses,
  fetchEvents,
  fetchSubscriptionSteps,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import StickyTabs from 'Components/shared/sticky-tabs';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import Divider from 'Components/shared/divider';
import Calendar from 'Components/shared/calendar';
import Card from 'Components/pages/mentorship-calls/card';

import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import {
  makeStyles,
  emphasize,
  useMediaQuery,
  Typography,
  Tab,
  Button,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backIcon: {
    position: 'absolute',
    left: theme.spacing(3),
    top: 80 + theme.spacing(3),
    width: 48,
    height: 48,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[6],
    backgroundColor: '#FFFFFF',
    '&:hover, &:focus': {
      backgroundColor: emphasize('#FFFFFF', 0.12),
    },
    marginRight: theme.spacing(1.5),
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  subtitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  moduleContainer: {
    padding: theme.spacing(10, 0),
  },
  divider: {
    margin: theme.spacing(2, 0, 4),
  },
  calendarWrapper: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      width: `calc(100% + ${theme.spacing(2)}px)`,
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(-1),
      overflow: 'hidden',
      marginBottom: theme.spacing(8),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 768,
    marginBottom: theme.spacing(1),
  },
  card: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  tabs: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      top: 79,
    },
  },
  noEventsTitle: {
    marginBottom: theme.spacing(1.5),
  },
  noEventsText: {
    marginBottom: theme.spacing(4),
  },
}));

const MentorshipCallsViewAllPage = () => {
  const styles = useStyles();
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug, year: year_, month: month_ } = useParams();
  const year = toNumber(year_);
  const month = toNumber(month_);

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectEventsRequest,
    selectSubscriptionStepsRequest,
  );

  const courses = useSelector(selectCourses);
  const events = useSelector(selectEvents);

  const groups = useMemo(
    () =>
      courses
        ? courses
            .filter((c) => Boolean(c.hasMentorshipCalls))
            .concat([{ title: 'Dan Vas', slug: 'dan-vas' }])
        : null,
    [courses],
  );

  const currentGroupIndex = findIndex(groups, ['slug', slug]);
  const group = currentGroupIndex !== -1 ? groups[currentGroupIndex] : null;

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchSubscriptionSteps());
    dispatch(
      fetchEvents({
        courseIdOrMentorSlug: slug,
        fromDate: new Date(year, month - 1, 1),
        toDate: new Date(year, month, 1),
        type: 'mentorship-call',
      }),
    );
  }, [slug, month, year, dispatch, navigate]);

  const handleTabChange = useCallback(
    (event, value) =>
      navigate(`/mentorship-calls/${groups[value].slug}/${year}/${month}`),
    [groups, month, year, navigate],
  );

  const handleDateChange = useCallback(
    (y, m) => navigate(`/mentorship-calls/${slug}/${y}/${m + 1}`),
    [slug, navigate],
  );

  if (!isLoading && slug && !group) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={!group || !events} error={error}>
      {() => (
        <PageLayout
          headerItems={<HeaderItems />}
          drawerItems={<DrawerItems />}
          waiting={isLoading}
        >
          <IconButton
            component={Link}
            to={`/mentorship-calls/${slug}`}
            data-testid="back"
            className={styles.backIcon}
          >
            <ArrowBackIcon />
          </IconButton>
          <PageContainer>
            <Typography className={styles.title} variant="h4" align="center">
              Mentorship Calls
            </Typography>
            <StickyTabs
              variant="scrollable"
              scrollButtons={isSmUp ? 'off' : 'on'}
              className={styles.tabs}
              value={currentGroupIndex < 0 ? 0 : currentGroupIndex}
              onChange={handleTabChange}
            >
              {groups.map((g) => (
                <Tab
                  data-testid={camelCase(g.title + 'Tab')}
                  key={g.slug}
                  label={g.title}
                />
              ))}
            </StickyTabs>
            <Typography variant="h5" align="center">
              {group.title}
            </Typography>
            <Divider className={styles.divider} />
            <div className={styles.calendarWrapper}>
              <Calendar
                month={month - 1}
                year={year}
                dates={events.map((e) => e.startsAt)}
                onChange={handleDateChange}
              />
            </div>
            <div className={styles.cards}>
              {events.length ? (
                events.map((event, index) => (
                  <Card
                    className={styles.card}
                    data-testid={`eventCardPrevious${index}`}
                    key={event.id}
                    {...event}
                  />
                ))
              ) : (
                <>
                  <Typography
                    className={styles.noEventsTitle}
                    variant="h5"
                    align="center"
                  >
                    No Events For{' '}
                    {format(new Date(year, month - 1), 'MMMM yyyy')}
                  </Typography>
                  <Typography
                    className={styles.noEventsText}
                    variant="body1"
                    align="center"
                  >
                    Try searching for different time.
                  </Typography>
                </>
              )}
            </div>
            <Button
              color="primary"
              variant="outlined"
              component={Link}
              to={`/mentorship-calls/${slug}`}
              startIcon={<ArrowBackIcon />}
              data-testid="goBack"
            >
              Go Back
            </Button>
          </PageContainer>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default MentorshipCallsViewAllPage;
