import { put, call, takeLeading } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'subscription-steps';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchSubscriptionSteps = Exec.requestActionCreator(MODULE);
export const fetchSubscriptionStepsLoading = Exec.loadingActionCreator(MODULE);
export const fetchSubscriptionStepsSuccess = Exec.successActionCreator(MODULE);
export const fetchSubscriptionStepsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSubscriptionStepsRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectSubscriptionSteps = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchSubscriptionSteps = function* ({ meta }) {
  try {
    const { data } = yield call(Api.getSubscriptionSteps);
    yield put(fetchSubscriptionStepsSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchSubscriptionStepsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(
  MODULE,
  doFetchSubscriptionSteps,
  takeLeading,
);
