import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: ({
    maxWidth,
    sidebarWidth,
    sidebarToggleBreakpoint,
    disableGutters,
  }) => ({
    maxWidth,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 'auto',
    padding: theme.spacing(7, disableGutters ? 0 : 1, 15),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(7, disableGutters ? 0 : 2, 15),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(7, disableGutters ? 0 : 3, 15),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(7, disableGutters ? 0 : 4, 15),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(7, disableGutters ? 0 : 5, 15),
    },
    [theme.breakpoints.up(sidebarToggleBreakpoint)]: {
      marginLeft: sidebarWidth
        ? `max(${sidebarWidth}px, calc((100% - ${maxWidth}px) / 2))`
        : 'auto',
    },
  }),
}));

const PageContainer = (props) => {
  const {
    className,
    maxWidth,
    sidebarWidth,
    sidebarToggleBreakpoint,
    disableGutters,
    ...rest
  } = props;
  const styles = useStyles({
    maxWidth,
    sidebarWidth,
    sidebarToggleBreakpoint,
    disableGutters,
  });

  return (
    <Container
      disableGutters
      className={clsx(className, styles.root)}
      {...rest}
    />
  );
};

PageContainer.propTypes = {
  maxWidth: PropTypes.number.isRequired,
  sidebarWidth: PropTypes.number,
  disableGutters: PropTypes.bool,
  sidebarToggleBreakpoint: PropTypes.oneOf([
    'xxs',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'xxl',
  ]).isRequired,
};

PageContainer.defaultProps = {
  maxWidth: 1024,
  sidebarToggleBreakpoint: 'lg',
};

export default PageContainer;
