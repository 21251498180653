import * as yup from 'yup';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';

import { selectChangePasswordRequest } from 'Redux/selectors';
import { changePassword } from 'Redux/actions';
import useNavigateExternal from 'Hooks/use-navigate-external';
import { PageLoaderReset } from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import Input from 'Components/shared/input';

import {
  ChevronRight as ChevronRightIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  Button,
  Grid,
  Typography,
  InputAdornment,
  Container,
  IconButton,
} from '@material-ui/core';

const schema = yup.object().shape({
  password: yup.string().min(6, 'Too Short.').required('Field Required.'),
  confirmPassword: yup
    .string()
    .required('Field Required.')
    .oneOf([yup.ref('password'), null], 'Password Mismatch.'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    paddingBottom: theme.spacing(1.5),
  },
  input: {
    width: '100%',
  },
  buttons: {
    justifyContent: 'space-between',
  },
  frame: {
    width: '100%',
    maxWidth: 340,
    margin: -1,
    padding: theme.spacing(3, 4),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      borderWidth: 0,
    },
  },
}));

const ChangePasswordPage = () => {
  const styles = useStyles();
  const [params] = useSearchParams();
  const code = params.get('code');
  const redirectTo = params.get('redirectTo');

  const navigate = useNavigateExternal();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectChangePasswordRequest);

  const [isPasswdVisible, setIsPasswdVisible] = useState(false);
  const handleToggleVisibility = useCallback(
    () => setIsPasswdVisible(!isPasswdVisible),
    [isPasswdVisible],
  );

  const handleFormSubmit = useCallback(
    (data) =>
      dispatch(
        changePassword({ password: data.password, code }, { isPromise: true }),
      )
        .then(() => navigate(redirectTo || '/'))
        .catch(console.warn),
    [dispatch, code, navigate, redirectTo],
  );

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
  });

  useEffect(() => {
    if (!code) {
      navigate(
        !redirectTo
          ? '/verify-email?intent=account-recovery'
          : '/verify-email?intent=account-recovery&redirectTo=' + redirectTo,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLoaderReset>
      <PageLayout>
        <Container className={styles.root} disableGutters>
          <Grid
            container
            spacing={2}
            direction="column"
            className={styles.frame}
            component="form"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Grid item>
              <Typography align="center" variant="h5" className={styles.title}>
                Password Reset
              </Typography>
              <Typography align="center" variant="body1" color="textSecondary">
                Enter a new password for your account.
              </Typography>
            </Grid>
            <Grid item>
              <Input
                data-testid="password"
                control={control}
                className={styles.input}
                disabled={isLoading}
                label="Password"
                name="password"
                type={isPasswdVisible ? 'text' : 'password'}
                errorText={get(errors, 'password.message')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleToggleVisibility}>
                        {isPasswdVisible ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Input
                data-testid="confirmPassword"
                control={control}
                className={styles.input}
                disabled={isLoading}
                label="Confirm Password"
                name="confirmPassword"
                type={isPasswdVisible ? 'text' : 'password'}
                errorText={get(errors, 'confirmPassword.message')}
              />
            </Grid>
            <Grid container item className={styles.buttons}>
              <Grid item>
                <Button
                  data-testid="back"
                  color="primary"
                  disabled={isLoading}
                  variant="text"
                  component={Link}
                  to="/account-recovery"
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  data-testid="submit"
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  endIcon={<ChevronRightIcon />}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </PageLayout>
    </PageLoaderReset>
  );
};

export default ChangePasswordPage;
