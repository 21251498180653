import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { find, get, map, groupBy, orderBy, noop, toLower } from 'lodash';
import clsx from 'clsx';

import states from 'Util/states';
import countries from 'Util/countries';
import {
  selectCourses,
  selectCoursesRequest,
  selectMeRequest,
  selectRoles,
  selectRolesRequest,
  selectUpdateUserRequest,
  selectCreateUserRequest,
  selectUser,
  selectUserRequest,
} from 'Redux/selectors';
import {
  fetchRoles,
  fetchUser,
  updateUser,
  createUser,
  fetchCourses,
} from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import Select from 'Components/shared/select';
import RadioGroup from 'Components/shared/radio-group';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import Checkbox from 'Components/shared/checkbox';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import { Save as SaveIcon } from '@material-ui/icons';
import {
  Button,
  FormControl,
  FormControlLabel,
  Collapse,
  Radio,
  List,
  ListItem,
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core';

const statesCanadaOptions = states
  .filter((s) => s.countryCode === 'CA')
  .map((s) => ({ value: s.name, label: s.name }));

const calculateUserCourseAccess = (user, courses) => {
  return courses.reduce((acc, curr) => {
    if (!user) {
      return {
        ...acc,
        [curr.id]: {
          enrolled: false,
          hasMentorship: false,
          isDripFed: 'full-access',
          lockToModuleIds: [],
        },
      };
    }

    const status = find(user.enrolledCourseStatus, ['courseId', curr.id]);

    const enrolled = Boolean(status);
    const isDripFed = get(status, 'isDripFed');
    const hasMentorship = get(status, 'hasMentorship', false);
    const lockToModuleIds = get(status, 'lockToModuleIds', []);

    return {
      ...acc,
      [curr.id]: {
        enrolled,
        isDripFed: isDripFed
          ? 'drip-fed'
          : lockToModuleIds.length
          ? 'lock-to-modules'
          : 'full-access',
        hasMentorship,
        lockToModuleIds,
      },
    };
  }, {});
};

const options = countries.map((c) => ({ value: c.code, label: c.name }));

const schema = yup.object().shape({
  firstName: yup.string().required('Field Required.'),
  lastName: yup.string().required('Field Required.'),
  email: yup.string().email('Invalid Email.').required('Field Required.'),
  country: yup.string().required('Field Required.'),
  phoneNumber: yup.string().required('Field Required.'),
});

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  list: {
    width: '100%',
    flexGrow: 1,
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(3),
  },
  inlineList: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1.5, 3, 2, 0),
    marginBottom: theme.spacing(1.5),
  },
  headerInputs: {
    marginBottom: theme.spacing(3),
  },
  title: {
    width: '100%',
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  input: {
    width: '100%',
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  whiteInput: {
    backgroundColor: 'white',
  },
  checkboxItem: {
    width: `calc(100% + ${theme.spacing(3)}px)`,
    marginLeft: theme.spacing(-3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    minHeight: 48,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  accessControlCollapse: {
    width: `calc(100% + ${theme.spacing(3)}px)`,
    marginLeft: theme.spacing(-3),
  },
  accessControlRoot: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[50],
  },
  accessControl: {
    paddingLeft: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    width: '100%',
  },
  accessControlMentorship: {
    width: `calc(100% - ${theme.spacing(5) - 3})`,
    marginLeft: theme.spacing(5) - 3,
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    minHeight: 48,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
  },
  selectModulesToUnlock: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-1),
    marginRight: theme.spacing(2),
  },
}));

const AdminCreateOrUpdateUser = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();

  const user = useSelector(selectUser);
  const courses = useSelector(selectCourses);
  const roles = useSelector(selectRoles);

  const { isLoading: isLoadingFragments, error: errorFragments } =
    useCombinedRequestsSelector(
      selectMeRequest,
      selectCoursesRequest,
      selectRolesRequest,
    );
  const { isLoading: isLoadingUser, error: errorUser } =
    useSelector(selectUserRequest);

  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectUpdateUserRequest,
    selectCreateUserRequest,
  );

  const isLoading = userId
    ? isLoadingFragments || isLoadingUser
    : isLoadingFragments;
  const error = userId ? errorFragments || errorUser : errorFragments;

  useScopedDispatchEffect(() => {
    if (userId) {
      dispatch(fetchUser({ userId }));
    }
    dispatch(fetchCourses());
    dispatch(fetchRoles());
  }, [dispatch, userId]);

  const handleFormSubmit = useCallback(
    async (data) => {
      const courseAccess = data.courseAccess
        ? map(data.courseAccess, (value, courseId) =>
            value && value.enrolled
              ? {
                  courseId,
                  isDripFed: value.isDripFed === 'drip-fed',
                  hasMentorship: Boolean(value.hasMentorship),
                  lockToModuleIds:
                    value.isDripFed === 'lock-to-modules'
                      ? value.lockToModuleIds
                      : [],
                }
              : null,
          ).filter(Boolean)
        : null;

      const action = userId
        ? updateUser({ ...data, userId, courseAccess }, { isPromise: true })
        : createUser({ ...data, courseAccess }, { isPromise: true });

      dispatch(action)
        .then((payload) => navigate('/admin/users/' + payload.data.id))
        .catch(noop);
    },
    [dispatch, navigate, userId],
  );

  const courseGroups = useMemo(
    () =>
      orderBy(
        map(groupBy(courses, 'type'), (items, type) => ({
          name: {
            BLUEPRINT: 'Blueprints',
            COURSE: 'Courses',
          }[type],
          items,
        })),
        ['name'],
        ['desc'],
      ),
    [courses],
  );

  const existingCanadaState = Boolean(
    user &&
      user.country === 'CA' &&
      statesCanadaOptions.find((o) => toLower(o.label) === toLower(user.state)),
  );

  const statesCanadaOptionsExtended = useMemo(
    () =>
      [
        user && !existingCanadaState
          ? {
              label: user.state + ' (current)',
              value: user.state,
              isPlaceholder: true,
            }
          : null,
        ...statesCanadaOptions,
      ].filter(Boolean),
    [existingCanadaState, user],
  );

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => {
        const { control, watch, errors, setValue, clearError, handleSubmit } =
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useForm({
            defaultValues:
              userId && user
                ? {
                    ...user,
                    stateCanada: user.state,
                    courseAccess: calculateUserCourseAccess(user, courses),
                  }
                : {
                    country: options[0].value,
                    roleId: 'ROLE_USER',
                    courseAccess: calculateUserCourseAccess(user, courses),
                  },
            validationSchema: schema,
            reValidateMode: 'onChange',
          });

        const { country, stateCanada, courseAccess } = watch([
          'country',
          'stateCanada',
          'courseAccess',
        ]);
        const form = watch({ nest: true });

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (country === 'CA') {
            setValue('state', stateCanada);
            clearError('state');
          }
        }, [country, stateCanada, setValue, clearError]);

        return (
          <UserDetailsLayout>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={
                userId
                  ? [
                      { href: '/admin/users', label: 'Users' },
                      { href: '/admin/users/' + userId, label: 'Details' },
                      { label: user.firstName + ' ' + user.lastName },
                    ]
                  : [
                      { href: '/admin/users', label: 'Users' },
                      { label: 'Create' },
                    ]
              }
            />

            <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
              <TitleAndAction
                className={styles.titleAndAction}
                title={
                  <Typography variant="h4">
                    {userId ? 'Edit User' : 'New User'}
                  </Typography>
                }
                button={
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    type="submit"
                  >
                    Save
                  </Button>
                }
              />

              <Grid container spacing={3} className={styles.cards}>
                <Grid item xs={12} md={6}>
                  <List className={styles.list}>
                    <ListItem
                      className={clsx(styles.header, styles.headerInputs)}
                      disableGutters
                      divider
                    >
                      <Typography className={styles.title} variant="h6">
                        General Stats
                      </Typography>
                    </ListItem>
                    <Input
                      data-testid="firstName"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="First Name"
                      name="firstName"
                      errorText={get(errors, 'firstName.message')}
                    />
                    <Input
                      data-testid="lastName"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Last Name"
                      name="lastName"
                      errorText={get(errors, 'lastName.message')}
                    />
                    <Input
                      data-testid="email"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Email Address"
                      name="email"
                      type="email"
                      errorText={get(errors, 'email.message')}
                    />
                    <Input
                      data-testid="phoneNumber"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Phone Number"
                      name="phoneNumber"
                      format="phoneNumber"
                      locale={country}
                      errorText={get(errors, 'phoneNumber.message')}
                    />
                    <Select
                      data-testid="roleId"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Role"
                      name="roleId"
                      options={roles.map((r) => ({
                        value: r.id,
                        label: r.title,
                      }))}
                    />
                    {/* TODO: add spoken languages here */}
                  </List>
                </Grid>
                <Grid item xs={12} md={6}>
                  <List className={styles.list}>
                    <ListItem
                      className={clsx(styles.header, styles.headerInputs)}
                      disableGutters
                      divider
                    >
                      <Typography className={styles.title} variant="h6">
                        Address
                      </Typography>
                    </ListItem>
                    <Input
                      data-testid="addressLine"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Address Line"
                      name="addressLine"
                      errorText={get(errors, 'addressLine.message')}
                    />
                    <Input
                      data-testid="city"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="City"
                      name="city"
                      errorText={get(errors, 'city.message')}
                    />
                    <Input
                      data-testid="zipCode"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Zip Code"
                      name="zipCode"
                      errorText={get(errors, 'zipCode.message')}
                    />
                    <Select
                      data-testid="stateCanada"
                      className={clsx(
                        styles.input,
                        country !== 'CA' && styles.hidden,
                      )}
                      control={control}
                      label="State/Province"
                      name="stateCanada"
                      disabled={isLoadingMutation}
                      errorText={get(errors, 'state.message')}
                      options={statesCanadaOptionsExtended}
                    />
                    <Input
                      data-testid="state"
                      className={clsx(
                        styles.input,
                        country === 'CA' && styles.hidden,
                      )}
                      control={control}
                      label="State/Province"
                      name="state"
                      autoComplete="address-level1"
                      disabled={isLoadingMutation}
                      errorText={get(errors, 'state.message')}
                    />
                    <Select
                      data-testid="country"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Country"
                      name="country"
                      options={options}
                      errorText={get(errors, 'country.message')}
                    />
                  </List>
                </Grid>
                <Grid item xs={12} md={6}>
                  <List className={styles.list}>
                    <ListItem
                      className={clsx(styles.header, styles.headerInputs)}
                      disableGutters
                      divider
                    >
                      <Typography className={styles.title} variant="h6">
                        Financial Info
                      </Typography>
                    </ListItem>
                    <Typography variant="body1" className={styles.subtitle}>
                      Update Stripe Connection
                    </Typography>
                    <Input
                      data-testid="stripeCustomerId"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Stripe Customer ID"
                      name="stripeCustomerId"
                      errorText={get(errors, 'stripeCustomerId.message')}
                    />
                  </List>
                </Grid>
                <Grid item xs={12} md={6}>
                  <List className={styles.list}>
                    {courseGroups.map(({ name, items }) => (
                      <Fragment key={name}>
                        <ListItem
                          className={styles.header}
                          disableGutters
                          divider
                        >
                          <Typography className={styles.title} variant="h6">
                            {name}
                          </Typography>
                        </ListItem>
                        {items.map((course) => (
                          <Fragment key={course.id}>
                            <FormControlLabel
                              className={styles.checkboxItem}
                              control={
                                <Checkbox
                                  data-testid={'course-' + course.id}
                                  control={control}
                                  disabled={isLoadingMutation}
                                  name={`courseAccess[${course.id}].enrolled`}
                                  color="primary"
                                />
                              }
                              label={course.title}
                            />
                            <Collapse
                              in={get(
                                form,
                                ['courseAccess', course.id, 'enrolled'],
                                false,
                              )}
                              unmountOnExit={false}
                              className={styles.accessControlCollapse}
                            >
                              <FormControl
                                component="fieldset"
                                className={styles.accessControlRoot}
                              >
                                <RadioGroup
                                  className={styles.accessControl}
                                  control={control}
                                  name={`courseAccess[${course.id}].isDripFed`}
                                >
                                  <FormControlLabel
                                    value="drip-fed"
                                    control={
                                      <Radio
                                        disabled={isLoadingMutation}
                                        data-testid={
                                          'course-' + course.id + '-drip'
                                        }
                                        color="primary"
                                      />
                                    }
                                    label="Drip Fed"
                                  />
                                  <FormControlLabel
                                    value="full-access"
                                    control={
                                      <Radio
                                        disabled={isLoadingMutation}
                                        data-testid={
                                          'course-' + course.id + '-full'
                                        }
                                        color="primary"
                                      />
                                    }
                                    label="Full Access"
                                  />
                                  {course.modules &&
                                  course.modules.length > 1 ? (
                                    <FormControlLabel
                                      value="lock-to-modules"
                                      control={
                                        <Radio
                                          disabled={isLoadingMutation}
                                          data-testid={
                                            'course-' + course.id + '-lock'
                                          }
                                          color="primary"
                                        />
                                      }
                                      label="Unlock Some Modules"
                                    />
                                  ) : null}
                                  <Select
                                    multiple
                                    data-testid={
                                      'course-' + course.id + '-lock'
                                    }
                                    className={clsx(
                                      styles.selectModulesToUnlock,
                                      courseAccess[course.id].isDripFed !==
                                        'lock-to-modules'
                                        ? styles.hidden
                                        : null,
                                    )}
                                    control={control}
                                    label="Unlocked Modules"
                                    name={`courseAccess[${course.id}].lockToModuleIds`}
                                    options={
                                      course.modules
                                        ? course.modules
                                            .filter((m) => m.isVisible)
                                            .map((m) => ({
                                              value: m.id,
                                              label: m.title,
                                            }))
                                        : []
                                    }
                                    errorText={get(errors, 'reason.message')}
                                  />
                                </RadioGroup>
                                {course.isMentorshipEnabled ? (
                                  <FormControlLabel
                                    className={styles.accessControlMentorship}
                                    control={
                                      <Checkbox
                                        data-testid={
                                          'course-mentorship-' + course.id
                                        }
                                        control={control}
                                        disabled={isLoadingMutation}
                                        name={`courseAccess[${course.id}].hasMentorship`}
                                        color="primary"
                                      />
                                    }
                                    label="With Mentorship"
                                  />
                                ) : null}
                              </FormControl>
                            </Collapse>
                          </Fragment>
                        ))}
                      </Fragment>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </form>
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminCreateOrUpdateUser;
