import { put, call, select } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { selectUser, fetchUserSuccess } from './user';
import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { selectMe, fetchMeSuccess } from './me';

const MODULE = 'revoke-user-suspension';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const REVOKE_USER_SUSPENSION = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const revokeUserSuspension = Exec.requestActionCreator(MODULE);
export const revokeUserSuspensionLoading = Exec.loadingActionCreator(MODULE);
export const revokeUserSuspensionSuccess = Exec.successActionCreator(MODULE);
export const revokeUserSuspensionError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectRevokeUserSuspensionRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doRevokeUserSuspension = function* ({ payload, meta }) {
  const { userId, message } = payload;
  const me = yield select(selectMe);
  const user = yield select(selectUser);

  try {
    yield put(closeSnackbar(REVOKE_USER_SUSPENSION));
    yield put(revokeUserSuspensionLoading());
    const { data } = yield call(Api.revokeUserSuspension, userId, message);
    if (me && userId === me.id) {
      yield put(fetchMeSuccess({ data }));
    }
    if (user && userId === user.id) {
      yield put(fetchUserSuccess({ data }));
    }
    yield put(revokeUserSuspensionSuccess({ data }, meta));
  } catch (err) {
    yield put(revokeUserSuspensionError(err, meta));
    yield put(
      enqueueSnackbar(REVOKE_USER_SUSPENSION, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doRevokeUserSuspension);
