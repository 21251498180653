import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectUpdateEventRequest } from 'Redux/selectors';
import { updateEvent } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmDeleteEventRecordingDialog = (props) => {
  const { eventId, onClose } = props;
  const { isLoading } = useSelector(selectUpdateEventRequest);
  const dispatch = useDispatch();

  const handleDelete = useCallback(() => {
    dispatch(updateEvent({ eventId, recording: null }, { isPromise: true }))
      .then(onClose)
      .catch(noop);
  }, [dispatch, onClose, eventId]);

  return (
    <Dialog
      title="Delete Event Recording"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleDelete}>
          Delete
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to delete the recording?
      </Typography>
    </Dialog>
  );
};

ConfirmDeleteEventRecordingDialog.propTypes = {
  eventId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmDeleteEventRecordingDialog;
