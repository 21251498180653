import PropTypes from 'prop-types';
import { castArray } from 'lodash';

import Dialog from 'Components/shared/dialog';

import { makeStyles, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  image: {
    display: 'block',
    width: '100%',
    maxWidth: 260,
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contain',
    padding: theme.spacing(1),
  },
}));

const TooltipDialog = (props) => {
  const { title, message, learnMoreUrl, image, onClose } = props;
  const styles = useStyles();

  return (
    <Dialog
      title={title}
      onClose={onClose}
      cancelButton={
        learnMoreUrl ? (
          <Button
            data-testid="learnMore"
            component="a"
            href={learnMoreUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn More
          </Button>
        ) : null
      }
      submitButton={
        learnMoreUrl ? (
          <Button data-testid="submit" onClick={onClose}>
            Okay
          </Button>
        ) : null
      }
    >
      {castArray(message).map((line, index) => (
        <Typography variant="body1" gutterBottom key={index}>
          {line}
        </Typography>
      ))}
      {image && (
        <img className={styles.image} src={image} alt="where to find asin" />
      )}
    </Dialog>
  );
};

export const propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  learnMoreUrl: PropTypes.string,
  image: PropTypes.string,
};

TooltipDialog.propTypes = {
  ...propTypes,
  onClose: PropTypes.func.isRequired,
};

export default TooltipDialog;
