import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';

import toStringBytes from 'Util/to-string-bytes';
import {
  selectSubmitAchievementRequest,
  selectUploadFileRequest,
} from 'Redux/selectors';
import {
  submitAchievement,
  submitAchievementReset,
  uploadFileCancel,
} from 'Redux/actions';
import UploadDropzone from 'Components/shared/upload-dropzone';
import RichText from 'Components/shared/rich-text';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  requirements: {
    marginBottom: theme.spacing(1),
  },
  dropzone: {
    marginTop: theme.spacing(3),
    overflow: 'unset',
  },
}));

const SubmitAchievementPictureDialog = (props) => {
  const { onClose, id, courseId, title, description, requirements } = props;
  const dispatch = useDispatch();
  const styles = useStyles();

  const [fileRejections, setFileRejections] = useState([]);
  const [successText, setSuccessText] = useState(null);
  const [helperText, setHelperText] = useState(null);

  const { error, isLoading } = useSelector(selectSubmitAchievementRequest);
  const { progress } = useSelector(selectUploadFileRequest);
  useEffect(() => () => dispatch(submitAchievementReset()), [dispatch]);

  const handleDrop = useCallback(
    (accepted, rejections) => {
      setHelperText(null);

      if (rejections.length) {
        setFileRejections(rejections);
      } else {
        dispatch(
          submitAchievement(
            { id, courseId, file: accepted[0] },
            { isPromise: true },
          ),
        )
          .then(() =>
            setSuccessText(
              'Thanks for uploading a screenshot of your results. We’ll reach out soon with the verdict.',
            ),
          )
          .catch(noop);
      }
    },
    [courseId, dispatch, id],
  );

  const handleReset = useCallback(() => {
    setFileRejections([]);
    dispatch(submitAchievementReset());
  }, [dispatch]);

  const handleSetHelperText = useCallback(
    () => setHelperText('You have to pick an image.'),
    [],
  );
  const handleCancel = useCallback(() => {
    dispatch(uploadFileCancel());
    onClose();
  }, [dispatch, onClose]);

  const fileTooLarge =
    get(fileRejections, '0.errors.0.code') === 'file-too-large';
  const hasError = Boolean(error) || fileRejections.length;

  return (
    <Dialog
      interruptible
      title={title}
      loading={isLoading}
      onClose={onClose}
      cancelButton={
        <Button data-testid="cancel" onClick={handleCancel}>
          Cancel
        </Button>
      }
      submitButton={
        <Button
          data-testid="submit"
          onClick={
            hasError ? handleReset : successText ? onClose : handleSetHelperText
          }
        >
          {hasError ? 'Retry' : successText ? 'Finish' : 'Submit'}
        </Button>
      }
    >
      <Typography gutterBottom variant="body1">
        <RichText>{description.html}</RichText>
      </Typography>
      <Typography
        variant="h6"
        color="textPrimary"
        className={styles.requirements}
      >
        Requirements
      </Typography>

      <Typography variant="body1">
        <RichText>{requirements.html}</RichText>
      </Typography>

      <UploadDropzone
        className={styles.dropzone}
        loading={isLoading}
        progress={progress}
        DropzoneProps={{
          onDrop: handleDrop,
          accept: 'image/*',
          multiple: false,
          maxSize: 52428800,
        }}
        errorText={
          hasError
            ? fileTooLarge
              ? `File is too large! Maximum allowed file size is ${toStringBytes(
                  52428800,
                )}.`
              : error && error.code === 'ERROR_CODE_NETWORK_ERROR'
              ? 'Please check your internet connection.'
              : 'Sorry about that. Something happened on our end and we were unable to finish the upload.'
            : null
        }
        successText={successText}
        helperText={helperText}
      />
    </Dialog>
  );
};

SubmitAchievementPictureDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string,
  }).isRequired,
  requirements: PropTypes.shape({
    html: PropTypes.string,
  }).isRequired,
};

export default SubmitAchievementPictureDialog;
