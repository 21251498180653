import 'Util/phone-number-formatter';

import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { getExampleNumber } from 'libphonenumber-js/min';
import examples from 'libphonenumber-js/examples.mobile.json';
import Cleave from 'cleave.js/react';

import withController from 'Util/with-controller';
import ensurePrice from 'Util/ensure-price';
import { enusreNationalPhoneNumber } from 'Util/ensure-phone-number';
import countries from 'Util/countries';

import { TextField, InputAdornment } from '@material-ui/core';

const localePhonePrefixMap = countries.reduce(
  (prev, cur) => ({
    ...prev,
    [cur.code]: '+' + cur.numberPrefix,
  }),
  {},
);

const CleaveInput = (props) => {
  // eslint-disable-next-line react/prop-types
  const { inputRef, locale: maybeLocale, format, placeholder, ...rest } = props;
  const locale = maybeLocale || 'US';

  return (
    <Cleave
      {...rest}
      key={locale}
      htmlRef={inputRef}
      options={{
        // if phone
        phone: format === 'phoneNumber',
        phoneRegionCode: locale,
        // if price
        numeral: format === 'price',
        stripLeadingZeroes: false,
      }}
      placeholder={
        format === 'phoneNumber'
          ? getExampleNumber(locale, examples)
              .formatInternational()
              .replace(localePhonePrefixMap[locale] + ' ', '')
          : '0.00'
      }
    />
  );
};

const Input = (props) => {
  const {
    className,
    errorText,
    helperText,
    format,
    locale,
    onChange,
    onBlur,
    InputProps,
    defaultValue, // probably shouldn't be applied to input? Causes react warning when used withFieldArray()
    ...rest
  } = props;

  const handleChange = useCallback(
    (event) => {
      const value = format
        ? event.currentTarget.rawValue
        : event.currentTarget.value;

      onChange(value);
    },
    [format, onChange],
  );
  const handleBlur = useCallback(
    (event) => {
      const value = format
        ? event.currentTarget.rawValue
        : event.currentTarget.value;

      const nextValue = value
        ? format === 'price'
          ? ensurePrice(value, { country: locale, compact: false }).slice(1)
          : format === 'phoneNumber'
          ? enusreNationalPhoneNumber(value, locale)
          : value
        : '';

      onBlur(nextValue);
      onChange(nextValue);
    },
    [format, locale, onBlur, onChange],
  );

  const inputProps = format
    ? {
        ...InputProps,
        inputComponent: CleaveInput,
        startAdornment: (
          <InputAdornment position="start">
            {format === 'price'
              ? ensurePrice(0, { country: locale })[0]
              : localePhonePrefixMap[locale]}
          </InputAdornment>
        ),
      }
    : InputProps;

  return (
    <TextField
      variant="outlined"
      className={className}
      InputProps={inputProps}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(errorText)}
      helperText={errorText || helperText || ' '}
      inputProps={{ locale, format }}
      {...rest}
    />
  );
};

Input.propTypes = {
  format: PropTypes.oneOf(['phoneNumber', 'price']),
  locale: PropTypes.string,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  InputProps: PropTypes.any,
  defaultValue: PropTypes.any,
};

Input.defaultProps = {
  onChange: noop,
  onBlur: noop,
};

export default withController(Input);
