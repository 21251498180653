import { useEffect, useState } from 'react';

const usePeriodRerender = (periodMs, enabled) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (enabled) {
      const id = setInterval(
        () => setCount((current) => current + 1),
        periodMs,
      );
      return () => clearInterval(id);
    }
  }, [enabled, periodMs]);

  return count;
};

export default usePeriodRerender;
