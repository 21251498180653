import { createSelector } from 'reselect';
import { put, call, takeLeading } from 'redux-saga/effects';
import { get, compact, isString, flatMap, uniq, map, find } from 'lodash';
import { addSeconds, isAfter, isBefore, parseISO } from 'date-fns';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPDATE_EVENT_SUCCESS } from './update-event';
import { DELETE_EVENT_SUCCESS } from './delete-event';

const MODULE = 'events';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
const defaultFetchEvents = Exec.requestActionCreator(MODULE);
export const fetchEvents = (payload, options) => {
  const offset = get(payload, 'offset', 0);
  return defaultFetchEvents(payload, {
    isAppending: offset > 0,
    ...options,
  });
};
export const fetchEventsLoading = Exec.loadingActionCreator(MODULE);
export const fetchEventsSuccess = Exec.successActionCreator(MODULE);
export const fetchEventsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);
export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_EVENT_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((v) => (v.id === data.id ? data : v)),
      };
    }
    case DELETE_EVENT_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.filter((v) => v.id !== data.id),
      };
    }
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectEventsRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectEvents = Exec.dataSelectorCreator(MODULE);

export const selectEventsTotalCount = (store) =>
  store.events.pageInfo ? store.events.pageInfo.totalCount : 0;

export const selectUpcomingEvents = createSelector(selectEvents, (events) =>
  events
    ? events.filter(({ startsAt, duration }) => {
        const startsAtAsDate = isString(startsAt)
          ? parseISO(startsAt)
          : startsAt;
        const endsAt = addSeconds(startsAtAsDate, duration);
        return isAfter(endsAt, new Date());
      })
    : null,
);
export const selectPastEvents = createSelector(selectEvents, (events) =>
  events
    ? events.filter(({ startsAt, duration }) => {
        const startsAtAsDate = isString(startsAt)
          ? parseISO(startsAt)
          : startsAt;
        const endsAt = addSeconds(startsAtAsDate, duration);
        return isBefore(endsAt, new Date());
      })
    : null,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchEvents = function* ({ payload, meta }) {
  try {
    const { data, __meta = {} } = yield call(Api.getEvents, payload);
    const mentors = yield Promise.all(
      compact(uniq(flatMap(data, 'mentorIds'))).map((id) =>
        Api.getUser(id).then((res) => res.data),
      ),
    );

    for (const event of data) {
      event.mentors = map(event.mentorIds, (id) => find(mentors, ['id', id]));
      delete event.mentorIds;
    }

    yield put(fetchEventsSuccess({ data, pageInfo: __meta.pageInfo }, meta));
  } catch (err) {
    yield put(fetchEventsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchEvents, takeLeading);
