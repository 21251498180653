import { withStyles, Button as MuiButton } from '@material-ui/core';

const UnderlineButton = withStyles((theme) => ({
  label: (props) => ({
    position: 'relative',
    fontSize: props.size === 'large' ? 20 : 16,
    '&:before': {
      position: 'absolute',
      content: '" "',
      width: 'auto',
      height: 3,
      left: -4,
      right: -4,
      bottom: -4,
      borderRadius: 4,
      backgroundColor:
        {
          primary: theme.palette.primary.main,
          secondary: theme.palette.secondary.main,
        }[props.color] || theme.palette.text.primary,
    },
  }),
}))(MuiButton);

export default UnderlineButton;
