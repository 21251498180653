import { put, take, call, race } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import {
  uploadFile,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_CANCEL,
  UPLOAD_FILE_ERROR,
} from './upload-file';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'upload-achievement-graphic';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPLOAD_ACHIEVEMENT_GRAPHIC_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPLOAD_ACHIEVEMENT_GRAPHIC_SUCCESS =
  Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const uploadAchievementGraphic = Exec.requestActionCreator(MODULE);
export const uploadAchievementGraphicLoading =
  Exec.loadingActionCreator(MODULE);
export const uploadAchievementGraphicSuccess =
  Exec.successActionCreator(MODULE);
export const uploadAchievementGraphicError = Exec.errorActionCreator(MODULE);
export const uploadAchievementGraphicReset = Exec.resetActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUploadAchievementGraphicRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUploadAchievementGraphic = function* ({ payload, meta }) {
  const { courseId, moduleId, achievementId, file } = payload || {};

  try {
    yield put(closeSnackbar(UPLOAD_ACHIEVEMENT_GRAPHIC_REQUEST));

    yield put(uploadAchievementGraphicLoading());

    const {
      data: { url: signedUrl },
    } = yield call(Api.createSignedUrl, 'assets', file.type);

    yield put(
      uploadFile({
        method: 'PUT',
        url: signedUrl,
        data: file,
        headers: {
          'Content-Type': file.type,
        },
      }),
    );

    const [, cancel, error] = yield race([
      take(UPLOAD_FILE_SUCCESS),
      take(UPLOAD_FILE_CANCEL),
      take(UPLOAD_FILE_ERROR),
    ]);

    if (error) {
      throw error.payload.error;
    }

    if (cancel) {
      return;
    }

    const url = new URL(signedUrl);
    url.search = '';

    const { data } = yield call(
      Api.updateAchievement,
      courseId,
      moduleId,
      achievementId,
      {
        graphic: {
          url: url.href,
          fileName: file.name,
        },
      },
    );

    yield put(uploadAchievementGraphicSuccess({ data }, meta));
  } catch (err) {
    yield put(uploadAchievementGraphicError(err, meta));

    yield put(
      enqueueSnackbar(UPLOAD_ACHIEVEMENT_GRAPHIC_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUploadAchievementGraphic);
