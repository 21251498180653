import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import PageNotFoundGraphic from 'Components/pages/error/page-not-found-graphic';

import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { makeStyles, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  up: {
    maxWidth: 420,
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  down: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 520,
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  content: {
    marginBottom: theme.spacing(4),
  },
}));

const PageNotFound = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className={styles.root}>
      <div className={styles.up}>
        <PageNotFoundGraphic />
      </div>
      <div className={styles.down}>
        <Typography
          variant="h4"
          color="primary"
          align="center"
          className={styles.title}
        >
          Page Not Found
        </Typography>
        <div className={styles.content}>
          <Typography color="textSecondary" variant="body1" align="center">
            Sorry, we can’t find the page you were looking for.
          </Typography>
          <Typography color="textSecondary" variant="body1" align="center">
            To return to the previous page click on the button below.
          </Typography>
        </div>
        <Button
          startIcon={<ArrowBackIcon />}
          color="primary"
          variant="contained"
          size="large"
          onClick={handleBack}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;
