import Slider from 'react-slick';
import { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { min } from 'lodash';
import clsx from 'clsx';

import ArrowButton from 'Components/shared/arrow-button';

import { useMediaQuery, useTheme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  grid: (props) => ({
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2) + 'px',
    gridTemplateColumns: `1fr 1fr`,
    [theme.breakpoints.up(props.breakpoint)]: {
      gridTemplateColumns: '40px auto 40px',
    },
  }),
  arrow: (props) => ({
    gridRow: 2,
    [theme.breakpoints.up(props.breakpoint)]: {
      gridRow: 1,
    },
  }),
  arrowLeft: {
    justifySelf: 'flex-end',
    gridColumn: 1,
  },
  arrowRight: (props) => ({
    justifySelf: 'flex-start',
    gridColumn: 2,
    [theme.breakpoints.up(props.breakpoint)]: {
      gridColumn: 3,
    },
  }),
  sliderWrapper: (props) => ({
    justifySelf: 'center',
    width: '100%',
    overflow: 'hidden',
    gridColumnStart: 1,
    gridColumnEnd: 3,
    [theme.breakpoints.up(props.breakpoint)]: {
      gridColumn: 2,
    },
  }),
  slider: {
    height: '100%',
    width: '100%',
  },
  card: {
    padding: theme.spacing(1),
    '&:first-child': {
      marginLeft: 'auto',
    },
    '&:last-child': {
      marginRight: 'auto',
    },
  },
}));

const Carousel = (props) => {
  const {
    className,
    breakpoint,
    data,
    component: Component,
    slideWidth,
    slidesToShow: slidesToShow_,
  } = props;
  const styles = useStyles({ breakpoint });

  const sliderRef = useRef();
  const [index, setIndex] = useState(0);

  const isXsUp = useMediaQuery((theme) => theme.breakpoints.up('xs'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isXlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));
  const isXxlUp = useMediaQuery((theme) => theme.breakpoints.up('xxl'));
  const isLargerThanWindow = useMediaQuery(
    `@media only screen and (max-width: ${slideWidth}px)`,
  );
  const theme = useTheme();

  const slidesToShow__ =
    slidesToShow_ || (isXxlUp ? 4 : isXlUp ? 3 : isMdUp ? 2 : 1);
  const slidesToShow = min([
    slidesToShow__,
    !data.length ? slidesToShow__ : data.length,
  ]);
  const width =
    isLargerThanWindow || !isXsUp
      ? '100%'
      : slideWidth * slidesToShow + theme.spacing(slidesToShow * 2);

  const wrapperStyle = useMemo(() => ({ width }), [width]);

  useEffect(() => {
    if (index > data.length - slidesToShow) {
      sliderRef.current.slickGoTo(data.length - slidesToShow, true);
    }
  }, [index, data.length, slidesToShow]);

  const handlePrev = useCallback(() => sliderRef.current.slickPrev(), []);
  const handleNext = useCallback(() => sliderRef.current.slickNext(), []);
  const handleSetIndex = useCallback((prev, index) => setIndex(index), []);

  return (
    <div className={clsx(styles.grid, className)}>
      <ArrowButton
        className={clsx(styles.arrow, styles.arrowLeft)}
        orientation="left"
        onClick={handlePrev}
        disabled={index === 0}
      />
      <div className={styles.sliderWrapper} style={wrapperStyle}>
        <Slider
          infinite={false}
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          className={styles.slider}
          ref={sliderRef}
          arrows={false}
          dots={false}
          draggable={false}
          beforeChange={handleSetIndex}
        >
          {data.map((card, key) => (
            <div className={styles.card} key={key}>
              <Component {...card} />
            </div>
          ))}
        </Slider>
      </div>
      <ArrowButton
        className={clsx(styles.arrow, styles.arrowRight)}
        orientation="right"
        onClick={handleNext}
        disabled={
          data.length < slidesToShow || index === data.length - slidesToShow
        }
      />
    </div>
  );
};

Carousel.propTypes = {
  breakpoint: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'])
    .isRequired,
  data: PropTypes.arrayOf(PropTypes.any),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  slidesToShow: PropTypes.number,
  slideWidth: PropTypes.number.isRequired,
};

Carousel.defaultProps = {
  breakpoint: 'sm',
  data: [],
  component: 'div',
};

export default Carousel;
