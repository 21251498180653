import ReactPlayer from 'react-player/wistia';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    // paddingTop: 'calc(100% * (9 / 16))', // new approach?
    '&:before': {
      display: 'block',
      content: '" "',
      width: '100%',
      paddingTop: 'calc(100% * (9 / 16))',
    },
  },
  wrapper: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  background: {
    backgroundColor: 'black',
  },
  progress: {
    color: 'white',
  },
  error: {
    color: 'white',
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(7, 1.5, 4),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(7, 5, 4),
      alignItems: 'flex-start',
    },
  },
  errorTitle: {
    marginBottom: theme.spacing(1.5),
  },
}));

const WistiaVideo = (props) => {
  const { className, loading, errorText, video, config, ...rest } = props;
  const styles = useStyles();

  return (
    <div className={clsx(styles.root, className)}>
      <div className={clsx(styles.wrapper, styles.background)}>
        {loading ? (
          <CircularProgress className={styles.progress} size={56} />
        ) : errorText ? (
          <div className={styles.error}>
            <Typography variant="h5" className={styles.errorTitle}>
              Error!
            </Typography>
            <Typography variant="body1">{errorText}</Typography>
          </div>
        ) : (
          <ReactPlayer
            controls
            width="100%"
            height="100%"
            playsinline
            {...rest}
            url={video ? video.url : null}
            config={{ wistia: { options: config } }}
          />
        )}
      </div>
    </div>
  );
};

WistiaVideo.propTypes = {
  loading: PropTypes.bool,
  errorText: PropTypes.string,
  video: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  config: PropTypes.object,
};

export default WistiaVideo;
