import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { find } from 'lodash';

import formatError from 'Util/format-error';
import {
  selectEvent,
  selectEventRequest,
  selectEvents,
  selectEventVideo,
  selectEventVideoRequest,
} from 'Redux/selectors';
import { fetchEventVideo, fetchEvent } from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import VideoContainer from 'Components/shared/video-container';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import Card from 'Components/pages/mentorship-calls/card';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import {
  makeStyles,
  withStyles,
  AccordionDetails,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  Paper,
} from '@material-ui/core';

const Accordion = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    overflow: 'hidden',
    '&$expanded': {
      margin: 'auto',
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[1],
    flexDirection: 'row-reverse',
    height: 84,
    paddingLeft: theme.spacing(3.5),
    marginTop: 1,
  },
  expanded: {},
  expandIcon: {
    marginRight: 0,
    marginLeft: -12,
    transform: 'rotate(-90deg)',
    '&$expanded': {
      transform: 'rotate(0deg)',
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
}))(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
  },
  container: {
    width: '100%',
    paddingBottom: theme.spacing(1),
  },
  details: {
    maxWidth: 1024,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2.5, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 5, 7),
    },
  },
  noPadding: {
    padding: 0,
  },
  card: {
    border: 'none',
    borderRadius: '0 !important',
    backgroundColor: 'transparent',
    width: '100%',
    paddingTop: theme.spacing(1.25),
    '&:hover': {
      boxShadow: 'initial !important',
    },
  },
}));

const WatchMentorshipCallPage = () => {
  const { eventId } = useParams();

  const styles = useStyles();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(true);

  const { isLoading, error } = useCombinedRequestsSelector(selectEventRequest);

  const { isLoading: isLoadingVideo, error: videoError } = useSelector(
    selectEventVideoRequest,
  );

  const events = useSelector(selectEvents);
  const event_ = useSelector(selectEvent);
  const eventVideo = useSelector(selectEventVideo);

  const event =
    event_ && event_.id === eventId ? event_ : find(events, ['id', eventId]);

  useScopedDispatchEffect(() => {
    dispatch(fetchEvent({ eventId }));
    dispatch(fetchEventVideo({ eventId, recordingOnly: true }));
  }, [dispatch, eventId]);

  const handleChange = useCallback(
    (event, value) => setExpanded(Boolean(value)),
    [],
  );

  const wistiaVideoProps = useMemo(
    () => ({
      video: eventVideo,
      loading: isLoadingVideo,
      config: {
        silentAutoPlay: 'allow',
      },
      errorText: formatError(videoError),
    }),
    [isLoadingVideo, eventVideo, videoError],
  );

  if (!isLoading && !event) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout
          headerItems={<HeaderItems />}
          drawerItems={<DrawerItems />}
          className={styles.root}
        >
          <div className={styles.container}>
            <VideoContainer
              loading={isLoadingVideo}
              WistiaVideoProps={wistiaVideoProps}
              title={event ? event.title : null}
              subtitle={event ? event.variantTitle : null}
            />
            <div className={styles.details}>
              <Paper elevation={1}>
                <Accordion
                  data-testid="expandDetails"
                  expanded={expanded}
                  onChange={handleChange}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={styles.noPadding}>
                    <Card
                      previewMode
                      {...event}
                      title={null}
                      className={styles.card}
                    />
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </div>
          </div>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default WatchMentorshipCallPage;
