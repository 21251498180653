import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectRestoreOfferRequest } from 'Redux/selectors';
import { restoreOffer, enqueueSnackbar } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmRestoreOfferDialog = (props) => {
  const { onClose, offerId } = props;
  const { isLoading } = useSelector(selectRestoreOfferRequest);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRestore = useCallback(() => {
    dispatch(restoreOffer({ offerId }, { isPromise: true }))
      .then(() => {
        dispatch(
          enqueueSnackbar('SUCCESS', {
            variant: 'success',
            message: 'Offer restored successfully',
          }),
        );
        navigate('/admin/offers');
        onClose && onClose();
      })
      .catch(noop);
  }, [dispatch, navigate, offerId, onClose]);

  return (
    <Dialog
      title="Restore Offer"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleRestore}>
          Restore
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to restore this offer?
      </Typography>
    </Dialog>
  );
};

ConfirmRestoreOfferDialog.propTypes = {
  offerId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmRestoreOfferDialog;
