import { Link, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { padStart, toNumber } from 'lodash';
import { format, formatDuration, intervalToDuration, isBefore } from 'date-fns';

import {
  selectCoursesRequest,
  selectPaymentLinks,
  selectPaymentLinksRequest,
  selectPaymentLinksRowsPerPage,
  selectPaymentLinksTotalCount,
} from 'Redux/selectors';
import {
  closeSnackbar,
  enqueueSnackbar,
  fetchCourses,
  fetchPaymentLinks,
  fetchPaymentLinksSetRowsPerPage,
} from 'Redux/actions';
import useScrollToTop from 'Hooks/use-scroll-to-top';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import useLocalStorageState from 'Hooks/use-local-storage-state';
import useCopyToClipboard from 'Hooks/use-copy-to-clipboard';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import TableSortLabel from 'Components/shared/table-sort-label';
import StatusChip from 'Components/shared/status-chip';
import SearchInput from 'Components/shared/search-input';
import Scrollable from 'Components/shared/scrollable';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderAdminItems from 'Components/shared/header-admin-items';
import DrawerAdminItems from 'Components/shared/drawer-admin-items';
import TitleAndAction from 'Components/pages/admin/title-and-action';

import {
  Add as AddIcon,
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  Typography,
  Paper,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Link as MuiLink,
} from '@material-ui/core';

const { REACT_APP_STATIC_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: 0,
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'calc(100% - 280px)',
      marginLeft: 280,
    },
  },
  drawer: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    position: 'fixed',
    marginTop: 1, // divider offset
    width: 280,
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  title: {
    height: 40,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  export: {
    color: theme.palette.text.secondary,
  },
  list: {
    width: '100%',
  },
  searchAndFilter: {
    padding: theme.spacing(3, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  input: {
    flexGrow: 1,
  },
  showFiltersButton: {
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  pagination: {
    paddingLeft: 0,
  },
  scrollable: {
    padding: 0,
  },
  table: {
    minWidth: 1205,
  },
  fullWidthColumn: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  editButton: {
    paddingLeft: 4,
    paddingRight: 4,
    opacity: 0,
  },
  firstColumn: {
    paddingLeft: 0,
    whiteSpace: 'nowrap',
  },
  urlColumn: {
    paddingRight: 8,
    '& > button': {
      whiteSpace: 'nowrap',
      flexWrap: 'nowrap',
      height: 32,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1.5, 0, 1.5),
      border: '1px solid',
      borderColor: theme.palette.grey[300],
      borderRadius: 4,
      marginRight: 22,
      '& > code': {
        color: theme.palette.grey[800],
        fontSize: 13,
      },
      '& > svg': {
        color: theme.palette.grey[800],
        width: 14,
        height: 14,
        marginLeft: 8,
        display: 'none',
      },
    },
  },
  usedColumn: {},
  noWrap: {
    whiteSpace: 'nowrap',
  },
  tableRow: {
    '&:hover $urlColumn > button': {
      marginRight: 0,
      padding: theme.spacing(0, 1, 0, 1.5),
      '& > svg': {
        display: 'block',
      },
    },
    '&:hover $editButton': {
      opacity: 1,
    },
  },
  tableBody: {
    '& .MuiTableRow-hover': {
      cursor: 'pointer',
    },
  },
}));

const AdminCheckoutLinksPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [, copyToClipboard] = useCopyToClipboard();
  const [, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const id = setInterval(() => setCurrentTime(new Date()), 500);
    return () => clearInterval(id);
  }, []);

  const [orderBy, setOrderBy] = useLocalStorageState(
    'adminPaymentLinksPageOrderBy',
    'createdAt',
  );
  const [orderDirection, setOrderDirection] = useLocalStorageState(
    'adminPaymentLinksPageOrderDirection',
    'desc',
  );

  const page = toNumber(params.get('page') || 0);
  const query = params.get('query');

  const rowsPerPage = useSelector(selectPaymentLinksRowsPerPage);
  const totalCount = useSelector(selectPaymentLinksTotalCount);
  const paymentLinks = useSelector(selectPaymentLinks);

  const { isLoading, isRequested, error } = useCombinedRequestsSelector(
    selectPaymentLinksRequest,
    selectCoursesRequest,
  );

  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const isXlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  const navigateReplace = useCallback(
    (to) => {
      navigate(to, { replace: true });
    },
    [navigate],
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      dispatch(fetchPaymentLinksSetRowsPerPage(toNumber(event.target.value)));
    },
    [dispatch],
  );
  const handleChangePage = useCallback(
    (event, value) => {
      if (query) {
        navigateReplace(
          '/admin/payment-links?query=' + query + '&page=' + value,
        );
      } else {
        navigateReplace('/admin/payment-links?page=' + value);
      }
    },
    [navigateReplace, query],
  );
  const handleSearch = useCallback(
    (query) => {
      if (query) {
        navigateReplace('/admin/payment-links?query=' + query);
      } else {
        navigateReplace('/admin/payment-links');
      }
    },
    [navigateReplace],
  );
  const handleOpenPaymentLink = useCallback(
    (event) => {
      const paymentLinkId = event.currentTarget.getAttribute('data-value');
      navigate('/admin/payment-links/' + paymentLinkId + '/edit');
    },
    [navigate],
  );
  const handleCopyLastBackupUrl = useMemoizedCallback(
    (url) => async () => {
      dispatch(closeSnackbar('COPY'));

      if (await copyToClipboard(url)) {
        dispatch(
          enqueueSnackbar('COPY', {
            message: 'URL copied to clipboard',
            variant: 'success',
          }),
        );
      }
    },
    [copyToClipboard, dispatch],
  );
  const handleSortLabel = useCallback(
    (name, direction) => {
      setOrderBy(name);
      setOrderDirection(direction);
    },
    [setOrderDirection, setOrderBy],
  );

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchPaymentLinks({ page, query, orderBy, orderDirection }));
  }, [dispatch, page, query, rowsPerPage, orderDirection, orderBy]);

  useScrollToTop([page, query, rowsPerPage]);

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout
          waiting={isRequested}
          headerItems={<HeaderAdminItems />}
          drawerItems={<DrawerAdminItems />}
          drawerBreakpoint="xl"
        >
          <div className={styles.root}>
            {isXlUp && <DrawerAdminItems className={styles.drawer} />}

            <PageContainer
              className={styles.container}
              maxWidth={1200}
              sidebarWidth={280}
              sidebarToggleBreakpoint="xl"
            >
              <TitleAndAction
                className={styles.titleAndAction}
                title={<Typography variant="h4">Payment Links</Typography>}
                button={
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    component={Link}
                    to="/admin/payment-links/create"
                  >
                    Create Link
                  </Button>
                }
              />

              <Paper elevation={1} className={styles.list}>
                <div className={styles.searchAndFilter}>
                  <SearchInput
                    className={styles.input}
                    placeholder="Search for a payment link"
                    onSubmit={handleSearch}
                    defaultValue={query}
                  />
                </div>
                <Divider />
                <TableContainer component="div">
                  <Scrollable className={styles.scrollable}>
                    <div>
                      <Table
                        size="small"
                        aria-label="table of payment links"
                        className={styles.table}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className={styles.editButton}
                            ></TableCell>
                            <TableCell className={styles.firstColumn}>
                              <TableSortLabel
                                name="name"
                                disabled={Boolean(query)}
                                active={orderBy === 'name'}
                                direction={orderDirection}
                                onClick={handleSortLabel}
                              >
                                Name
                              </TableSortLabel>
                            </TableCell>
                            <TableCell className={styles.noWrap}>URL</TableCell>
                            <TableCell>
                              <TableSortLabel
                                name="createdAt"
                                disabled={Boolean(query)}
                                active={orderBy === 'createdAt'}
                                direction={orderDirection}
                                onClick={handleSortLabel}
                              >
                                Created
                              </TableSortLabel>
                            </TableCell>
                            <TableCell className={styles.noWrap}>
                              Sales Agent
                            </TableCell>
                            <TableCell className={styles.noWrap}>
                              <TableSortLabel
                                name="expiresAt"
                                disabled={Boolean(query)}
                                active={orderBy === 'expiresAt'}
                                direction={orderDirection}
                                onClick={handleSortLabel}
                              >
                                Expires in
                              </TableSortLabel>
                            </TableCell>
                            <TableCell>
                              <TableSortLabel
                                name="usedAt"
                                disabled={Boolean(query)}
                                active={orderBy === 'usedAt'}
                                direction={orderDirection}
                                onClick={handleSortLabel}
                              >
                                Used
                              </TableSortLabel>
                            </TableCell>
                            <TableCell className={styles.noWrap}>
                              Affiliate
                            </TableCell>
                            <TableCell className={styles.noWrap}>
                              Status
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={styles.tableBody}>
                          {paymentLinks.length ? (
                            paymentLinks.map((link) => {
                              const {
                                id,
                                name,
                                createdAt,
                                expiresAt,
                                usedAt,
                                usedBy,
                                affiliate,
                                salesAgent,
                                slug,
                              } = link;

                              const url = new URL(
                                '/pl/' + slug,
                                REACT_APP_STATIC_URL,
                              ).href;

                              const expired =
                                !usedBy &&
                                isBefore(new Date(expiresAt), new Date());

                              const formatted = formatDuration(
                                intervalToDuration({
                                  start: new Date(expiresAt),
                                  end: new Date(),
                                }),
                                {
                                  zero: true,
                                  delimiter: ':',
                                  format: ['hours', 'minutes', 'seconds'],
                                  locale: {
                                    formatDistance: (_token, count) =>
                                      padStart(count, 2, '0'),
                                  },
                                },
                              );

                              return (
                                <TableRow className={styles.tableRow} key={id}>
                                  <TableCell className={styles.editButton}>
                                    <IconButton
                                      data-value={id}
                                      onClick={handleOpenPaymentLink}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </TableCell>
                                  <TableCell className={styles.firstColumn}>
                                    {name}
                                  </TableCell>
                                  <TableCell className={styles.urlColumn}>
                                    <button
                                      onClick={handleCopyLastBackupUrl(url)}
                                    >
                                      <code>{url}</code>
                                      <FileCopyIcon />
                                    </button>
                                  </TableCell>
                                  <TableCell className={styles.noWrap}>
                                    {format(new Date(createdAt), 'MMM d, yyyy')}
                                  </TableCell>
                                  <TableCell className={styles.noWrap}>
                                    {salesAgent ? (
                                      <MuiLink
                                        component={Link}
                                        color="secondary"
                                        to={`/admin/users/${salesAgent.id}`}
                                      >
                                        {salesAgent.firstName}{' '}
                                        {salesAgent.lastName}
                                      </MuiLink>
                                    ) : (
                                      <code>--</code>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <code>
                                      {expired || (usedBy && usedAt)
                                        ? '--'
                                        : formatted}
                                    </code>
                                  </TableCell>
                                  <TableCell className={styles.noWrap}>
                                    {usedBy && usedAt ? (
                                      <>
                                        {format(
                                          new Date(createdAt),
                                          'MMM d, yyyy',
                                        )}{' '}
                                        by{' '}
                                        <MuiLink
                                          component={Link}
                                          color="secondary"
                                          to={`/admin/users/${usedBy.id}`}
                                        >
                                          {usedBy.firstName} {usedBy.lastName}
                                        </MuiLink>
                                      </>
                                    ) : (
                                      <code>--</code>
                                    )}
                                  </TableCell>
                                  <TableCell className={styles.noWrap}>
                                    {affiliate ? (
                                      <MuiLink
                                        component={Link}
                                        color="secondary"
                                        to={`/admin/users/${affiliate.id}`}
                                      >
                                        {affiliate.firstName}{' '}
                                        {affiliate.lastName}
                                      </MuiLink>
                                    ) : (
                                      <code>--</code>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <StatusChip
                                      variant={
                                        expired
                                          ? 'error'
                                          : usedBy
                                          ? 'success'
                                          : 'invalid'
                                      }
                                    >
                                      {expired
                                        ? 'EXPIRED'
                                        : usedBy
                                        ? 'USED'
                                        : 'PENDING'}
                                    </StatusChip>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell
                                colSpan={8}
                                className={styles.fullWidthColumn}
                              >
                                <Typography variant="h6" align="center">
                                  Create your first Payment Link by clicking
                                  "Create Link" button in the top right corner
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  </Scrollable>
                  <TablePagination
                    component="div"
                    size="small"
                    SelectProps={{
                      'data-testid': 'paginationSelect',
                    }}
                    classes={{ toolbar: styles.pagination }}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={isSmUp ? [5, 10, 25, 50, 100] : []}
                  />
                </TableContainer>
              </Paper>
            </PageContainer>
          </div>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default AdminCheckoutLinksPage;
