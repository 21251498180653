import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { get, find, noop, omit } from 'lodash';

import {
  selectWebhooks,
  selectWebhooksRequest,
  selectUpdateWebhookRequest,
  selectCreateWebhookRequest,
} from 'Redux/selectors';
import { createWebhook, fetchWebhooks, updateWebhook } from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import Switch from 'Components/shared/switch';
import Select from 'Components/shared/select';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import { Save as SaveIcon } from '@material-ui/icons';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  FormControlLabel,
} from '@material-ui/core';

const triggerTypes = [
  { value: 'cancel-free-trial', label: 'On Cancel Free Trial' },
  {
    value: 'confirm-boxset-shipping-address',
    label: 'On Confirm Boxset Shipping Address',
  },
  { value: 'course-purchase', label: 'On Course Purchase' },
  { value: 'user-signup', label: 'On User Signup' },
];

const schemaFields = {
  title: yup.string().required('Field Required.'),
  targetUrl: yup.string().required('Field Required.'),
  triggerType: yup.string().required('Field Required.'),
  enabled: yup.boolean().required('Field Required.'),
};
const updateWebhookSchema = yup.object().shape(schemaFields);
const createWebhookSchema = yup.object().shape(omit(schemaFields, ['enabled']));

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  input: {
    width: '100%',
  },
  switch: {
    marginBottom: theme.spacing(2.5),
  },
}));

const AdminCreateOrUpdateWebhookPage = () => {
  const styles = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { webhookId } = useParams();

  const webhooks = useSelector(selectWebhooks);
  const webhook = find(webhooks, ['id', webhookId]);

  const { isLoading, error } = useSelector(selectWebhooksRequest);
  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectUpdateWebhookRequest,
    selectCreateWebhookRequest,
  );

  useScopedDispatchEffect(() => {
    if (!webhook) {
      dispatch(fetchWebhooks());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, webhookId]);

  const handleFormSubmit = useCallback(
    async (data) => {
      const action = webhookId
        ? updateWebhook({ ...data, webhookId }, { isPromise: true })
        : createWebhook({ ...data }, { isPromise: true });

      dispatch(action)
        .then(() => navigate('/admin/webhooks'))
        .catch(noop);
    },
    [webhookId, dispatch, navigate],
  );

  if (!isLoading && !webhook && webhookId) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { control, errors, formState, handleSubmit } = useForm({
          defaultValues: webhook ? webhook : {},
          validationSchema: webhook ? updateWebhookSchema : createWebhookSchema,
          reValidateMode: 'onChange',
        });

        const edited = formState.dirty;

        return (
          <UserDetailsLayout>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={[
                { href: '/admin/webhooks', label: 'Webhooks' },
                {
                  label: webhook ? webhook.title : 'Webhook',
                },
              ]}
            />

            <form
              className={styles.form}
              noValidate
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <TitleAndAction
                className={styles.titleAndAction}
                title={
                  <Typography variant="h4">
                    {webhook ? 'Edit Webhook' : 'Create New Webhook'}
                  </Typography>
                }
              />

              <Grid container spacing={2} direction="column">
                <Grid item lg={10}>
                  <Input
                    data-testid="title"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Title"
                    name="title"
                    errorText={get(errors, 'title.message')}
                  />
                </Grid>

                <Grid item lg={10}>
                  <Input
                    data-testid="targetUrl"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Target Url"
                    name="targetUrl"
                    errorText={get(errors, 'targetUrl.message')}
                  />
                </Grid>

                <Grid item xs={12} sm={9} md={7} lg={6}>
                  <Select
                    data-testid="triggerType"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Trigger Event"
                    name="triggerType"
                    options={triggerTypes}
                    errorText={get(errors, 'triggerType.message')}
                  />
                </Grid>

                {webhook ? (
                  <Grid item xs={8} sm={4} md={3}>
                    <FormControlLabel
                      className={styles.switch}
                      control={
                        <Switch
                          data-testid="enabled"
                          control={control}
                          disabled={isLoadingMutation}
                          name="enabled"
                          color="primary"
                        />
                      }
                      label="Enabled"
                    />
                  </Grid>
                ) : null}

                <Grid item xs={10} sm={6} md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={!edited}
                  >
                    Save Webhook
                  </Button>
                </Grid>
              </Grid>
            </form>
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminCreateOrUpdateWebhookPage;
