import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import clsx from 'clsx';

import Highlight from 'Components/shared/highlight';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: 'grid',
    gap: theme.spacing(2) + 'px',
    columnGap: theme.spacing(3) + 'px',

    ...(props.size === 4
      ? {
          [theme.breakpoints.up('xs')]: {
            maxWidth: 324,
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
          [theme.breakpoints.up('sm')]: {
            maxWidth: 'initial',
            gridTemplateColumns: 'repeat(2, 154px)',
          },
          [theme.breakpoints.up('md')]: {
            columnGap: theme.spacing(4) + 'px',
            gridTemplateColumns: 'repeat(4, 154px)',
          },
        }
      : {
          [theme.breakpoints.up('xs')]: {
            maxWidth: 324,
          },
          [theme.breakpoints.up('sm')]: {
            maxWidth: 'initial',
            gridTemplateColumns: 'repeat(3, 154px)',
          },
        }),
  }),
}));

const Boxes = (props) => {
  const { className, stats } = props;
  const styles = useStyles({ size: stats.length });

  return (
    <div className={clsx(styles.root, className)}>
      {stats.map((stat, index) => (
        <Highlight
          data-testid={camelCase(stat.label) + 'Box'}
          key={index}
          align="center"
          {...stat}
        />
      ))}
    </div>
  );
};

Boxes.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default Boxes;
