import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { min } from 'lodash';
import { addSeconds, format, isAfter, isBefore, parseISO } from 'date-fns';
import clsx from 'clsx';

import {
  selectEvents,
  selectEventsRequest,
  selectCourses,
  selectCoursesRequest,
  selectEventsTotalCount,
} from 'Redux/selectors';
import { fetchEvents } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import PageLoader from 'Components/shared/page-loader';
import WideButton from 'Components/pages/admin/wide-button';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import ContentPaper from 'Components/pages/admin/content-paper';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Refresh as RefreshIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons';
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  container: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    width: '100%',
  },
  loadMoreButton: {
    width: '100%',
    border: 0,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  status: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  chip: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(-1, 2, -1, 0),
    borderRadius: 70,
    border: '1px solid',
    display: 'inline-flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 24,
    marginRight: theme.spacing(1),
  },
  success: {
    background: 'rgba(56, 142, 60, 0.1)',
    borderColor: 'rgba(56, 142, 60, 0.2)',
    color: '#388E3C',
  },
  error: {
    background: 'rgba(211, 47, 47, 0.1)',
    opacity: 0.8,
    borderColor: 'rgba(211, 47, 47, 0.2)',
    color: '#D32F2F',
  },
}));

const AdminMentorshipCallsPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const courses = useSelector(selectCourses);
  const events = useSelector(selectEvents);
  const totalCount = useSelector(selectEventsTotalCount);

  const count = events ? events.length : 0;

  const { isRequested, error } = useCombinedRequestsSelector(
    selectEventsRequest,
    selectCoursesRequest,
  );
  const { isLoading } = useSelector(selectEventsRequest);

  useScopedDispatchEffect(() => {
    dispatch(
      fetchEvents({
        type: 'mentorship-call',
        limit: 10,
        offset: 0,
      }),
    );
  }, [dispatch]);

  const handleLoadMore = useCallback(() => {
    dispatch(
      fetchEvents({
        type: 'mentorship-call',
        limit: 10,
        offset: count,
      }),
    );
  }, [dispatch, count]);

  return (
    <PageLoader loading={isRequested} error={error}>
      {() => (
        <UserDetailsLayout>
          <div className={styles.container}>
            <TitleAndAction
              className={styles.titleAndAction}
              title={<Typography variant="h4">Mentorship Calls</Typography>}
              button={
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  component={Link}
                  to="/admin/mentorship-calls/create"
                >
                  Create Mentorship Call
                </Button>
              }
            />

            <Grid container spacing={1}>
              {!events || !events.length ? (
                <Typography variant="h6">No events found</Typography>
              ) : null}

              {(events || []).map((e) => (
                <Grid key={e.id} item container>
                  <ContentPaper
                    className={styles.paper}
                    containerClassName={styles.content}
                    actions={
                      <IconButton
                        component={Link}
                        to={`/admin/mentorship-calls/${e.id}/edit`}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                  >
                    <Typography variant="h6" className={styles.title}>
                      {e.title}
                    </Typography>
                    <Typography variant="body1" className={styles.status}>
                      {!e.hasRecording &&
                      isAfter(
                        new Date(),
                        addSeconds(parseISO(e.startsAt), e.duration),
                      ) ? (
                        <Typography
                          component="span"
                          variant="subtitle1"
                          className={clsx(styles.chip, styles.error)}
                        >
                          <ErrorOutlineIcon className={styles.icon} />
                          Recording missing
                        </Typography>
                      ) : null}
                      {isBefore(new Date(), parseISO(e.startsAt)) ? (
                        <Typography
                          component="span"
                          variant="subtitle1"
                          className={clsx(styles.chip, styles.success)}
                        >
                          Scheduled
                        </Typography>
                      ) : null}
                      {format(parseISO(e.startsAt), 'MMM dd, yyyy @ h:mm a')}
                      {e.mentors && e.mentors.length ? '  •  ' : ''}
                      {(e.mentors || [])
                        .map((m) => m.firstName + ' ' + m.lastName)
                        .join(', ')}
                      {e.courseIds && e.courseIds.length
                        ? '  •  ' +
                          e.courseIds
                            .map((id) => courses.find((c) => c.id === id))
                            .filter(Boolean)
                            .map((c) => c.code)
                            .join(', ')
                        : ''}
                    </Typography>
                  </ContentPaper>
                </Grid>
              ))}

              {totalCount > count ? (
                <Grid item container>
                  <WideButton
                    color="primary"
                    startIcon={<RefreshIcon />}
                    className={styles.loadMoreButton}
                    onClick={handleLoadMore}
                    disabled={isLoading}
                  >
                    Load {min([10, totalCount - count])} More
                  </WideButton>
                </Grid>
              ) : null}
            </Grid>
          </div>
        </UserDetailsLayout>
      )}
    </PageLoader>
  );
};

export default AdminMentorshipCallsPage;
