import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'me';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchMe = Exec.requestActionCreator(MODULE);
export const fetchMeLoading = Exec.loadingActionCreator(MODULE);
export const fetchMeSuccess = Exec.successActionCreator(MODULE);
export const fetchMeError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectMeRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectMe = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchMe = function* ({ meta }) {
  try {
    const { data } = yield call(Api.getMe);
    yield put(fetchMeSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchMeError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchMe);
