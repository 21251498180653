import PropTypes from 'prop-types';

import dynamicImageUrl from 'Util/dynamic-image-url';

import { Close as CloseIcon } from '@material-ui/icons';
import { IconButton, makeStyles, Typography, Grid } from '@material-ui/core';

import WideButton from './wide-button';
import ContentPaper from './content-paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    minHeight: 80,
    overflow: 'hidden',
    paddingLeft: 0,
    padding: theme.spacing(2, 3, 2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 4, 2, 2),
    },
  },
  button: {
    width: '100%',
  },
  item: {
    width: `calc(100% + ${theme.spacing(2)}px)`,
  },
  graphic: {
    width: 64,
    height: 64,
    border: 'none',
    backgroundColor: theme.palette.grey[50],
    objectFit: 'cover',
    margin: theme.spacing(-2, 2, -2, 0),
  },
  content: {
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

const UploadFileGroup = (props) => {
  const {
    className,
    preview,
    onDelete,
    onUpload,
    disabled,
    uploadButtonLabel,
    uploadButtonIcon,
  } = props;
  const styles = useStyles();

  return (
    <Grid className={className} container direction="column" spacing={2}>
      {preview ? (
        <Grid item className={styles.item}>
          <ContentPaper
            className={styles.paper}
            containerClassName={styles.content}
            actions={
              onDelete ? (
                <IconButton onClick={onDelete}>
                  <CloseIcon />
                </IconButton>
              ) : null
            }
          >
            <img
              src={dynamicImageUrl(preview, {
                resize: {
                  width: 80,
                  height: 80,
                  fit: 'cover',
                },
              })}
              className={styles.graphic}
              alt="img"
            />
            <Typography variant="body1">
              {preview.fileName || 'unnamed'}
            </Typography>
          </ContentPaper>
        </Grid>
      ) : null}
      <Grid item className={styles.item}>
        <WideButton
          component="div"
          className={styles.button}
          color="primary"
          startIcon={uploadButtonIcon}
          disabled={disabled}
          onClick={onUpload}
        >
          {uploadButtonLabel}
        </WideButton>
      </Grid>
    </Grid>
  );
};

UploadFileGroup.propTypes = {
  preview: PropTypes.shape({
    url: PropTypes.string.isRequired,
    fileName: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  onUpload: PropTypes.func,
  disabled: PropTypes.bool,
  uploadButtonLabel: PropTypes.string.isRequired,
  uploadButtonIcon: PropTypes.element.isRequired,
};

export default UploadFileGroup;
