import { useSelector } from 'react-redux';
import { useState, useCallback } from 'react';
import { findIndex } from 'lodash';
import clsx from 'clsx';

import calculateUserLevel from 'Util/calculate-user-level';
import { selectAchievementLevels, selectMe } from 'Redux/selectors';

import ArrowButton from 'Components/shared/arrow-button';
import StepIcon from 'Components/pages/achievements/step-icon';
import StepConnector from 'Components/pages/achievements/step-connector';
import MobileStepper from 'Components/pages/achievements/mobile-stepper';
import Level from 'Components/pages/achievements/level';

import {
  makeStyles,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  levels: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
    gap: theme.spacing(3) + 'px',
    marginBottom: theme.spacing(4),
  },
  groupWithButtons: {
    display: 'grid',
    alignItems: 'flex-end',
    justifyContent: 'center',
    gridTemplateColumns: 'auto auto auto',
    gap: theme.spacing(0.75) + 'px',
    marginBottom: theme.spacing(4),
  },
  arrow: {
    marginBottom: 28,
  },
  stepper: {
    width: '100%',
    maxWidth: 680,
  },
  mobileStepper: {
    maxWidth: 328,
  },
}));

const AchievementLevels = (props) => {
  const { className } = props;
  const styles = useStyles();

  const me = useSelector(selectMe);
  const levels = useSelector(selectAchievementLevels);

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const level = me && levels ? calculateUserLevel(me.experience, levels) : null;
  const levelIdx = findIndex(levels, (l) => level && l.id === level.id);
  const nextLevel = levels ? levels[levelIdx + 1] : null;
  const precentComplete = level
    ? nextLevel
      ? ((me.experience - level.requiredExperience) /
          (nextLevel.requiredExperience - level.requiredExperience)) *
        100
      : 100
    : 0;

  const [step, setStep] = useState(levelIdx);
  const handleStepPrev = useCallback(() => setStep(step - 1), [step]);
  const handleStepNext = useCallback(() => setStep(step + 1), [step]);

  return isMdUp ? (
    <div className={clsx(styles.root, className)}>
      <div className={styles.levels}>
        {levels.map((level) => (
          <Level key={level.id} user={me} {...level} />
        ))}
      </div>
      <Stepper
        className={styles.stepper}
        alternativeLabel
        activeStep={levelIdx + 1}
        connector={<StepConnector progress={precentComplete} />}
      >
        {levels.map((level, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={StepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  ) : (
    <div className={clsx(styles.root, className)}>
      <div className={styles.groupWithButtons}>
        <ArrowButton
          orientation="left"
          className={styles.arrow}
          onClick={handleStepPrev}
          disabled={step === 0}
        />
        <Level user={me} {...levels[step]} />
        <ArrowButton
          orientation="right"
          className={styles.arrow}
          onClick={handleStepNext}
          disabled={step === levels.length - 1}
        />
      </div>
      <MobileStepper
        className={clsx(styles.stepper, styles.mobileStepper)}
        activeStep={levelIdx + 1}
        currentStep={step}
        count={levels.length}
      />
    </div>
  );
};

export default AchievementLevels;
