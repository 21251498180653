import { batch } from 'react-redux';
import { useEffect } from 'react';
import { useInFallback } from 'Components/shared/page-loader';

const useScopedDispatchEffect = (cb, deps) => {
  const inFallback = useInFallback();
  useEffect(
    () => {
      if (!inFallback) {
        return batch(cb);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inFallback, ...deps],
  );
};

export default useScopedDispatchEffect;
