import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { memo } from 'react';

import { selectMe } from 'Redux/selectors';
import ConfirmSignOutDialog from 'Dialogs/confirm-sign-out-dialog';

import {
  Apps as AppsIcon,
  AccountCircle as AccountCircleIcon,
  Face as FaceIcon,
  EmojiEvents as EmojiEventsIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  MenuItem,
  Grid,
  IconButton,
} from '@material-ui/core';

import DropdownMenu from './dropdown-menu';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const HeaderAdminItems = (props) => {
  const { className } = props;

  const styles = useStyles();
  const me = useSelector(selectMe);

  const [showSignoutModal, hideSignoutModal] = useModal(() => (
    <ConfirmSignOutDialog onClose={hideSignoutModal} />
  ));

  const isXsUp = useMediaQuery((theme) => theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  if (!isXsUp) {
    return null;
  }

  return (
    <Grid
      className={className}
      container
      direction="row"
      wrap="nowrap"
      spacing={2}
      alignItems="center"
      justify="flex-end"
    >
      <Grid item>
        <DropdownMenu
          control={
            isSmUp ? (
              <Button
                aria-label="account of current user"
                color="inherit"
                startIcon={<AccountCircleIcon />}
                data-testid="account"
              >
                {me
                  ? me.firstName.toLowerCase() + ' ' + me.lastName.toLowerCase()
                  : ''}
              </Button>
            ) : (
              <IconButton
                edge="end"
                aria-label="account of current user"
                color="inherit"
                data-testid="account"
              >
                <AccountCircleIcon />
              </IconButton>
            )
          }
        >
          <MenuItem component={Link} to="/profile" data-testid="myProfile">
            <FaceIcon className={styles.icon} />
            Your Profile
          </MenuItem>
          <MenuItem
            component={Link}
            to="/achievements"
            data-testid="achievements"
          >
            <EmojiEventsIcon className={styles.icon} />
            Your Rewards
          </MenuItem>
          <MenuItem component={Link} to="/account" data-testid="settings">
            <SettingsIcon className={styles.icon} />
            Account Settings
          </MenuItem>
          <MenuItem onClick={showSignoutModal} data-testid="signout">
            <ExitToAppIcon className={styles.icon} />
            Sign Out
          </MenuItem>
          <MenuItem component={Link} to="/" data-testid="members">
            <AppsIcon className={styles.icon} />
            Members Area
          </MenuItem>
        </DropdownMenu>
      </Grid>
    </Grid>
  );
};

HeaderAdminItems.whyDidYouRender = true;

export default memo(HeaderAdminItems);
