import { createSelector } from 'reselect';
import { put, call } from 'redux-saga/effects';
import {
  flatMap,
  groupBy,
  map,
  find,
  uniq,
  get,
  omit,
  uniqBy,
  flatMapDeep,
  sortBy,
} from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPLOAD_LESSON_BOXSET_PDF_SUCCESS } from './upload-lesson-boxset-pdf';
import { UPDATE_RESOURCE_SUCCESS } from './update-resource';
import { UPDATE_QUESTION_SUCCESS } from './update-question';
import { UPDATE_LESSON_SUCCESS } from './update-lesson';
import { SORT_LESSONS_ERROR, SORT_LESSONS_SUCCESS } from './sort-lessons';
import {
  SORT_LESSON_RESOURCES_ERROR,
  SORT_LESSON_RESOURCES_SUCCESS,
} from './sort-lesson-resources';
import {
  SORT_LESSON_QUESTIONS_ERROR,
  SORT_LESSON_QUESTIONS_SUCCESS,
} from './sort-lesson-questions';
import { RECORD_LESSON_PROGRESS_SUCCESS } from './record-lesson-progress';
import { selectModules } from './modules';
import { CREATE_LESSON_SUCCESS } from './create-lesson';

const MODULE = 'lessons';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchLessons = Exec.requestActionCreator(MODULE);
export const fetchLessonsLoading = Exec.loadingActionCreator(MODULE);
export const fetchLessonsSuccess = Exec.successActionCreator(MODULE);
export const fetchLessonsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_LESSON_SUCCESS:
    case UPLOAD_LESSON_BOXSET_PDF_SUCCESS:
    case RECORD_LESSON_PROGRESS_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((l) => (l.id === data.id ? data : l)),
      };
    }
    case CREATE_LESSON_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };
    }
    case SORT_LESSONS_ERROR: {
      if (!state || !state.data || !action.payload.error.data) {
        return state;
      }
      return {
        ...state,
        data: action.payload.error.data,
      };
    }
    case SORT_LESSONS_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case SORT_LESSON_QUESTIONS_ERROR: {
      if (!state || !state.data || !action.payload.error.data) {
        return state;
      }
      return {
        ...state,
        data: state.data.map((lesson) =>
          lesson.id === action.payload.error.lessonId
            ? {
                ...lesson,
                questions: action.payload.error.data,
              }
            : lesson,
        ),
      };
    }
    case SORT_LESSON_QUESTIONS_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: state.data.map((lesson) =>
          lesson.id === action.payload.lessonId
            ? {
                ...lesson,
                questions: action.payload.data,
              }
            : lesson,
        ),
      };
    }
    case SORT_LESSON_RESOURCES_ERROR: {
      if (!state || !state.data || !action.payload.error.data) {
        return state;
      }
      return {
        ...state,
        data: state.data.map((lesson) =>
          lesson.id === action.payload.error.lessonId
            ? {
                ...lesson,
                resources: action.payload.error.data,
              }
            : lesson,
        ),
      };
    }
    case SORT_LESSON_RESOURCES_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: state.data.map((lesson) =>
          lesson.id === action.payload.lessonId
            ? {
                ...lesson,
                resources: action.payload.data,
              }
            : lesson,
        ),
      };
    }
    case UPDATE_QUESTION_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((l) => ({
          ...l,
          questions: l.questions.map((q) => (q.id === data.id ? data : q)),
        })),
      };
    }
    case UPDATE_RESOURCE_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((l) => ({
          ...l,
          resources: l.resources.map((r) => (r.id === data.id ? data : r)),
        })),
      };
    }
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectLessonsRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectLessons = Exec.dataSelectorCreator(MODULE);
export const selectLessonQuestionsWithTags = createSelector(
  selectLessons,
  selectModules,
  (lessons, modules) => {
    if (!lessons || !modules) {
      return null;
    }
    const questions = flatMap(lessons, (l) =>
      l.questions.map((q) => ({
        ...q,
        moduleSlug: get(find(modules, ['id', l.moduleId]), 'slug'),
      })),
    );
    const grouped = groupBy(questions, 'id');
    return map(grouped, (group) => {
      const one = group[0];
      return {
        ...omit(one, 'moduleSlug'),
        tags: uniq([
          ...map(group, 'moduleSlug'),
          ...flatMap(group, 'topics').map((t) => t.slug),
        ]),
      };
    });
  },
);
export const selectLessonQuestionTopics = createSelector(
  selectLessons,
  (lessons) => {
    if (!lessons) {
      return null;
    }

    const flattened = flatMapDeep(lessons, (l) => map(l.questions, 'topics'));
    const sorted = sortBy(flattened, 'seqNo', 'ASC');

    return uniqBy(sorted, 'slug');
  },
);
export const selectLessonVaultResources = createSelector(
  selectLessons,
  selectModules,
  (lessons, modules) => {
    if (!lessons || !modules) {
      return null;
    }

    const resources = uniqBy(flatMap(lessons, 'resources'), 'id');
    const topics = uniqBy(flatMap(resources, 'topics'), 'slug');

    const mapped = topics
      .map((topic) => {
        return {
          ...topic,
          links: resources.filter(
            (r) => r.showInVault && find(r.topics, ['slug', topic.slug]),
          ),
        };
      })
      .filter((t) => Boolean(t.links.length));

    return sortBy(mapped, 'seqNo', 'ASC');
  },
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchLessons = function* ({ payload, meta }) {
  try {
    const { data } = yield call(
      Api.getLessons,
      payload.courseId,
      payload.skipAccessCheck,
    );
    yield put(fetchLessonsSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchLessonsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchLessons);
