import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { find, get } from 'lodash';

const useCombinedRequestsSelector = (...selectors) => {
  const selector = useMemo(
    () =>
      createSelector(...selectors, (...requests) => {
        const isLoading = Boolean(find(requests, 'isLoading'));
        const isRequested = Boolean(find(requests, 'isRequested'));
        return {
          isLoading,
          isRequested,
          error: isLoading ? null : get(find(requests, 'error'), 'error'),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...selectors],
  );

  return useSelector(selector);
};

export default useCombinedRequestsSelector;
