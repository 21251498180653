import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectDeleteAchievementLevelPictureRequest } from 'Redux/selectors';
import { deleteAchievementLevelPicture } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmDeleteAchievementLevelPictureDialog = (props) => {
  const { achievementLevelId, onClose } = props;
  const { isLoading } = useSelector(selectDeleteAchievementLevelPictureRequest);
  const dispatch = useDispatch();

  const handleDelete = useCallback(() => {
    dispatch(
      deleteAchievementLevelPicture(
        { achievementLevelId },
        { isPromise: true },
      ),
    )
      .then(onClose)
      .catch(noop);
  }, [dispatch, onClose, achievementLevelId]);

  return (
    <Dialog
      title="Delete Achievement Level Picture"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleDelete}>
          Delete
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to delete this achievement level picture?
      </Typography>
    </Dialog>
  );
};

ConfirmDeleteAchievementLevelPictureDialog.propTypes = {
  achievementLevelId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmDeleteAchievementLevelPictureDialog;
