import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3.5, 1.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    lineHeight: '16px',
  },
  value: {
    lineHeight: '25.5px',
  },
}));

const MoneyBox = (props) => {
  const { className, title, value, ...rest } = props;
  const styles = useStyles();

  return (
    <div className={clsx(className, styles.root)} {...rest}>
      <Typography className={styles.title} variant="overline" align="center">
        {title}
      </Typography>
      <Typography className={styles.value} variant="subtitle1" align="center">
        {value}
      </Typography>
    </div>
  );
};

MoneyBox.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default MoneyBox;
