import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { trimEnd, upperFirst } from 'lodash';
import clsx from 'clsx';

import { selectWebhooks, selectWebhooksRequest } from 'Redux/selectors';
import { fetchWebhooks } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import PageLoader from 'Components/shared/page-loader';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import ContentPaper from 'Components/pages/admin/content-paper';

import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    width: '100%',
  },
  content: {
    maxWidth: 'calc(100% - 36px)',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  chip: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(-1, 2, -1, 0),
    borderRadius: 70,
    border: '1px solid',
    display: 'inline-flex',
    alignItems: 'center',
  },
  enabled: {
    background: 'rgba(56, 142, 60, 0.1)',
    borderColor: 'rgba(56, 142, 60, 0.2)',
    color: '#388E3C',
  },
  disabled: {
    background: theme.palette.grey[50],
    borderColor: theme.palette.grey[200],
    color: theme.palette.grey[600],
  },
  url: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const AdminWebhooksPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const webhooks = useSelector(selectWebhooks);
  const { isLoading, error } = useSelector(selectWebhooksRequest);

  useScopedDispatchEffect(() => {
    dispatch(fetchWebhooks());
  }, [dispatch]);

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <UserDetailsLayout>
          <div className={styles.container}>
            <TitleAndAction
              className={styles.titleAndAction}
              title={<Typography variant="h4">Webhooks Overview</Typography>}
              button={
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  component={Link}
                  to="/admin/webhooks/create"
                >
                  Create Webhook
                </Button>
              }
            />

            <Grid container spacing={1}>
              {!webhooks.length ? (
                <Typography variant="h6">No webhooks found</Typography>
              ) : null}

              {(webhooks || []).map((webhook) => {
                const { id, title, enabled, targetUrl, triggerType } = webhook;

                return (
                  <Grid key={id} item container>
                    <ContentPaper
                      className={styles.paper}
                      containerClassName={styles.content}
                      actions={
                        <IconButton
                          component={Link}
                          to={`/admin/webhooks/${id}/edit`}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <Typography variant="caption" color="textSecondary">
                        {'On ' +
                          triggerType.split('-').map(upperFirst).join(' ')}
                      </Typography>
                      <Typography variant="h6" className={styles.title}>
                        {title}
                      </Typography>
                      <Box display="flex" alignItems="center" flexWrap="nowrap">
                        <Typography
                          component="div"
                          variant="subtitle1"
                          className={clsx(
                            styles.chip,
                            enabled ? styles.enabled : styles.disabled,
                          )}
                        >
                          {enabled ? 'Enabled' : 'Disabled'}
                        </Typography>
                        <Typography
                          className={styles.url}
                          variant="body1"
                          color="textSecondary"
                        >
                          {trimEnd(targetUrl, '/')}
                        </Typography>
                      </Box>
                    </ContentPaper>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </UserDetailsLayout>
      )}
    </PageLoader>
  );
};

export default AdminWebhooksPage;
