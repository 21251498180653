import { useDispatch } from 'react-redux';
import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { enqueueSnackbar, closeSnackbar } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import { makeStyles, Button, IconButton, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fakeInput: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    height: 56,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(3),
  },
  url: {
    ...theme.typography.body2,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(2.25, 1.75),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
}));

const GetReferralLinkDialog = (props) => {
  const { onClose, url } = props;

  const inputRef = useRef(null);
  const styles = useStyles();
  const dispatch = useDispatch();

  const handleCopy = useCallback(() => {
    if (inputRef.current) {
      dispatch(closeSnackbar('COPY'));

      inputRef.current.select();
      document.execCommand('copy');

      dispatch(
        enqueueSnackbar('COPY', {
          message: 'URL copied to clipboard',
          variant: 'success',
        }),
      );
    }
  }, [dispatch]);

  return (
    <Dialog
      title="Your Referral Link"
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Close</Button>}
    >
      <div className={styles.fakeInput}>
        <input ref={inputRef} className={styles.url} value={url} readOnly />
        <IconButton onClick={handleCopy}>
          <FileCopyIcon />
        </IconButton>
      </div>

      <Typography variant="body1" gutterBottom>
        Make sure to share this link to earn commission and also to give the
        other person a discount.
      </Typography>
      <Typography variant="body1">
        <strong>Note</strong>: Your friend will know its the right link if it
        mentions your name at the top.
      </Typography>
    </Dialog>
  );
};

GetReferralLinkDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default GetReferralLinkDialog;
