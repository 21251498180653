import { call, put, select } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { selectMe } from './me';

const MODULE = 'subscribe-to-list';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SUBSCRIBE_TO_LIST_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const subscribeToList = Exec.requestActionCreator(MODULE);
export const subscribeToListLoading = Exec.loadingActionCreator(MODULE);
export const subscribeToListSuccess = Exec.successActionCreator(MODULE);
export const subscribeToListError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSubscribeToListRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSubscribeToList = function* ({ payload, meta }) {
  const { listId } = payload;
  const me = yield select(selectMe);

  try {
    yield put(closeSnackbar(SUBSCRIBE_TO_LIST_REQUEST));
    yield put(subscribeToListLoading());
    yield call(Api.subscribeToList, {
      firstName: me.firstName,
      email: me.email,
      listId,
    });
    yield put(subscribeToListSuccess(null, meta));
  } catch (err) {
    yield put(subscribeToListError(err, meta));
    yield put(
      enqueueSnackbar(SUBSCRIBE_TO_LIST_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSubscribeToList);
