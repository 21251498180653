import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { selectCreateTopicRequest } from 'Redux/selectors';
import { createTopic } from 'Redux/actions';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button } from '@material-ui/core';

const schema = yup.object().shape({
  title: yup.string().required('Field Required.'),
  slug: yup
    .string()
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g, 'Only Letters, Numbers and Dashes.')
    .required('Field Required.'),
});

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
}));

const AddTopicDialog = (props) => {
  const { defaultTitle, onClose } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectCreateTopicRequest);

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      title: defaultTitle,
    },
    validationSchema: schema,
  });

  const handleUpdate = useCallback(
    (data) => {
      dispatch(createTopic(data, { isPromise: true })).then((res) =>
        onClose(res.data),
      );
    },
    [dispatch, onClose],
  );

  return (
    <Dialog
      title="Add Topic"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleUpdate)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">Add</Button>}
    >
      <Input
        data-testid="title"
        className={styles.input}
        control={control}
        name="title"
        label="Title"
        errorText={get(errors, 'title.message')}
      />
      <Input
        data-testid="slug"
        className={styles.input}
        control={control}
        name="slug"
        label="Slug"
        errorText={get(errors, 'slug.message')}
      />
    </Dialog>
  );
};

AddTopicDialog.propTypes = {
  defaultTitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddTopicDialog;
