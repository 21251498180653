import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Search as SearchIcon } from '@material-ui/icons';
import {
  makeStyles,
  TextField,
  Button,
  InputAdornment,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: 'white',
    borderRadius: 4,
  },
  searchButton: {
    minWidth: 48,
    height: 48,
    padding: 0,
    borderRadius: 4,
  },
}));

const SearchInput = (props) => {
  const { className, onSubmit, ButtonProps, ...rest } = props;

  const inputRef = useRef(null);
  const styles = useStyles();

  const handleSubmit = useCallback(() => {
    const query = inputRef.current ? inputRef.current.value : null;
    onSubmit && onSubmit(query || '');
  }, [onSubmit]);

  const handleBlur = useCallback(() => {
    // Submit on blur when the query is empty, kinda gives it natural feeling
    // of clearing the input
    const query = inputRef.current ? inputRef.current.value : null;
    if (!query) {
      onSubmit && onSubmit('');
    }
  }, [onSubmit]);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleSubmit();
        event.target.blur();
      }
    },
    [handleSubmit],
  );

  return (
    <TextField
      {...rest}
      className={clsx(className, styles.input)}
      inputRef={inputRef}
      variant="outlined"
      onSubmit={handleSubmit}
      onKeyPress={handleKeyPress}
      onBlur={handleBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button
              variant="contained"
              {...ButtonProps}
              className={styles.searchButton}
              onClick={handleSubmit}
            >
              <SearchIcon />
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchInput.propTypes = {
  onSubmit: PropTypes.func,
  ButtonProps: PropTypes.any,
};

export default SearchInput;
