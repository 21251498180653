import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useMemo, useCallback } from 'react';
import { compact, includes, capitalize, find } from 'lodash';
import { format, formatDistance } from 'date-fns';
import clsx from 'clsx';

import exportUsersUrl from 'Util/export-users-url';
import { ensureInternationalPhoneNumber } from 'Util/ensure-phone-number';
import countries from 'Util/countries';
import {
  selectCourses,
  selectCoursesRequest,
  selectMe,
  selectMeRequest,
  selectRoles,
  selectRolesRequest,
  selectUpdateUserRequest,
  selectUser,
  selectUserRequest,
  selectUserStats,
  selectUserStatsRequest,
} from 'Redux/selectors';
import {
  fetchMe,
  fetchRoles,
  fetchUser,
  fetchUserStats,
  updateUser,
  enqueueSnackbar,
  fetchCourses,
} from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import VerifyUserDialog from 'Dialogs/verify-user-dialog';
import UpdateUserPasswordDialog from 'Dialogs/update-user-password-dialog';
import SuspendUserDialog from 'Dialogs/suspend-user-dialog';
import RevokeUserSuspensionDialog from 'Dialogs/revoke-user-suspension-dialog';
import ConfirmDeleteUserDialog from 'Dialogs/confirm-delete-user-dialog';
import PageLoader from 'Components/shared/page-loader';
import OptionListItem from 'Components/shared/option-list-item';
import DropdownMenu from 'Components/shared/dropdown-menu';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Tabs from 'Components/pages/admin/tabs';
import StatusChip from 'Components/pages/admin/status-chip';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import {
  ErrorOutline as ErrorOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Block as BlockIcon,
  GetApp as GetAppIcon,
  VpnKey as VpnKeyIcon,
  AccountCircle as AccountCircleIcon,
  Delete as DeleteIcon,
  Create as CreateIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  Replay as ReplayIcon,
} from '@material-ui/icons';
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Radio,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

const locationToCountry = (location) => {
  if (!location) {
    return null;
  }

  if (location.country === 'LOCALHOST') {
    return 'Localhost';
  }

  const found = countries.find((c) => c.code === location.country);

  return found ? found.name : null;
};

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3.5),
  },
  tabs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  cards: {
    width: '100%',
    alignSelf: 'flex-start',
    display: 'grid',
    gap: theme.spacing(3) + 'px',
    alignItems: 'start',
    justifyItems: 'start',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  textValue: {
    alignItems: 'center',
    display: 'inline-flex',
  },
  list: {
    width: '100%',
    flexGrow: 1,
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(3),
  },
  thinListItemTop: {
    paddingTop: theme.spacing(1),
  },
  thinListItemBottom: {
    paddingBottom: theme.spacing(1),
  },
  general: {
    [theme.breakpoints.up('md')]: {
      gridRowStart: 1,
      gridRowEnd: 4,
    },
  },
  actions: {
    [theme.breakpoints.up('md')]: {
      gridColumn: 2,
      gridRow: 2,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2.5, 3, 2, 0),
  },
  title: {
    width: '100%',
  },
  actionsItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 3, 2.5, 0),
  },
  button: {
    color: theme.palette.text.secondary,
  },
  deleteButton: {
    margin: theme.spacing(2, 0),
    color: 'white',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  deleteWarning: {
    color: theme.palette.error.main,
  },
  dropdownMenuItem: {
    minWidth: 260,
  },
  inlineButton: {
    margin: theme.spacing(-0.75, 0),
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
  progress: {
    width: '100%',
    alignSelf: 'center',
  },
}));

const AdminUserStats = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const me = useSelector(selectMe);
  const user = useSelector(selectUser);
  const userStats = useSelector(selectUserStats);
  const courses = useSelector(selectCourses);
  const roles = useSelector(selectRoles);

  const { isLoading, error } = useCombinedRequestsSelector(
    selectMeRequest,
    selectCoursesRequest,
    selectUserRequest,
    selectUserStatsRequest,
    selectRolesRequest,
  );
  const { isLoading: isLoadingUpdateUser } = useSelector(
    selectUpdateUserRequest,
  );
  const { isRequested: isMeRequested } = useSelector(selectMeRequest);

  useScopedDispatchEffect(() => {
    dispatch(fetchUser({ userId }));
    dispatch(fetchUserStats({ userId }));
    dispatch(fetchCourses());
    dispatch(fetchRoles());
  }, [dispatch, userId]);

  const handleToggleTrustpilotVerify = useCallback(() => {
    dispatch(
      updateUser(
        { userId, receivedTrustpilotReview: !user.receivedTrustpilotReview },
        { isPromise: true },
      ),
    ).then(() =>
      dispatch(
        enqueueSnackbar('UPDATE_USER', {
          variant: 'success',
          message: `User Trustpilot review ${
            user.receivedTrustpilotReview ? 'unverified' : 'verified'
          }`,
        }),
      ),
    );
  }, [dispatch, user, userId]);

  const handleExport = useCallback(() => {
    // Here, we need to fetch some user first so that, when we navigate to
    // the export URL, the token will be refreshed (if previously expired)
    dispatch(fetchMe(null, { isPromise: true })).then(() => {
      window.open(exportUsersUrl({ userId }));
    });
  }, [dispatch, userId]);
  const handleChangeUserRole = useMemoizedCallback(
    (roleId) => () => {
      if (roleId !== user.roleId) {
        dispatch(updateUser({ userId, roleId }, { isPromise: true }))
          .then(() => {
            dispatch(
              enqueueSnackbar('UPDATE_USER', {
                variant: 'success',
                message: 'User role changed',
              }),
            );
          })
          .catch(() => {});
      }
    },
    [dispatch, user, userId],
  );
  const [showResetPasswordModal, hideResetPasswordModal] = useModal(() => (
    <UpdateUserPasswordDialog onClose={hideResetPasswordModal} />
  ));
  const [showDeleteUserDialog, hideDeleteUserDialog] = useModal(() => (
    <ConfirmDeleteUserDialog onClose={hideDeleteUserDialog} />
  ));
  const [showSuspendUserDialog, hideSuspendUserDialog] = useModal(() => (
    <SuspendUserDialog onClose={hideSuspendUserDialog} />
  ));
  const [showRevokeUserSuspensionDialog, hideRevokeUserSuspensionDialog] =
    useModal(() => (
      <RevokeUserSuspensionDialog onClose={hideRevokeUserSuspensionDialog} />
    ));
  const [showVerifyUserDialog, hideVerifyUserDialog] = useModal(() => (
    <VerifyUserDialog onClose={hideVerifyUserDialog} />
  ));

  const country = useMemo(
    () => user && countries.find((c) => c.code === user.country),
    [user],
  );
  const role = useMemo(
    () => user && roles && roles.find((r) => r.id === user.roleId),
    [user, roles],
  );

  const parsedUserStats = useMemo(() => {
    if (!userStats) {
      return null;
    }

    return {
      signUpIp: userStats.signUpLocation ? userStats.signUpLocation.ip : null,
      signupCountry: locationToCountry(userStats.signUpLocation),
      lastLoginCountry: locationToCountry(userStats.lastLoginLocation),
      lastActiveLocations: userStats.lastActiveLocations
        ? userStats.lastActiveLocations
            .filter((location) => location && location.ip)
            .map((location) => {
              const country = locationToCountry(location) || 'Earth';
              return location.city
                ? `${location.ip} ${location.city}, ${country}`
                : `${location.ip} ${country}`;
            })
        : [],
      lastLoginClient: userStats.lastLoginLocation
        ? userStats.lastLoginLocation.client
        : null,
      lastLoginAt: userStats.lastLoginLocation
        ? userStats.lastLoginLocation.createdAt
        : null,
    };
  }, [userStats]);

  const addressCityPart =
    user && compact([user.addressLine, user.zipCode, user.city]).join(' ');
  const addressCountryPart =
    user && compact([user.state, country ? country.name : 'Earth']).join(' ');

  const accountStatus = user && user.suspension ? 'suspended' : 'active';

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <UserDetailsLayout>
          <Breadcrumbs
            className={styles.breadcrumbs}
            links={[
              { href: '/admin/users', label: 'Users' },
              { label: 'Details' },
            ]}
          />
          <TitleAndAction
            className={styles.titleAndAction}
            title={
              <Typography variant="h4">
                {user.firstName + ' ' + user.lastName}
              </Typography>
            }
            button={
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/admin/users/${userId}/edit`}
                startIcon={<CreateIcon />}
              >
                Edit
              </Button>
            }
          />
          <Tabs className={styles.tabs} userId={userId} value={1} />

          <div className={styles.cards}>
            <List disablePadding className={clsx(styles.list, styles.general)}>
              <ListItem className={styles.header} disableGutters divider>
                <Typography className={styles.title} variant="h6">
                  General Stats
                </Typography>
              </ListItem>
              <OptionListItem
                data-testid="fullName"
                title="Full Name"
                compact
                divider
                value={
                  <Typography variant="body1">
                    {user.firstName + ' ' + user.lastName}
                  </Typography>
                }
              />
              <OptionListItem
                data-testid="address"
                title="Address"
                compact
                divider
                value={
                  <Typography variant="body1">
                    {addressCityPart}
                    {addressCityPart ? ', ' : ''}
                    {addressCountryPart}
                  </Typography>
                }
              />
              <OptionListItem
                data-testid="email"
                title="Email Address"
                compact
                divider
                value={
                  <Grid
                    container
                    alignItems="flex-start"
                    direction="column"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body1">{user.email}</Typography>
                    </Grid>
                    {!user.isEmailVerified ? (
                      <Grid
                        container
                        item
                        wrap="nowrap"
                        alignItems={isMdUp ? 'center' : 'flex-start'}
                        direction={isMdUp ? 'row' : 'column'}
                        spacing={isMdUp ? 2 : 1}
                      >
                        <Grid item>
                          <StatusChip
                            icon={<ErrorOutlineIcon />}
                            variant="error"
                          >
                            Unverified
                          </StatusChip>
                        </Grid>
                        <Grid item>
                          <Button
                            className={styles.inlineButton}
                            startIcon={
                              <CheckCircleOutlineIcon fontSize="small" />
                            }
                            onClick={showVerifyUserDialog}
                          >
                            Verify
                          </Button>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                }
              />
              <OptionListItem
                data-testid="phoneNumber"
                title="Phone Number"
                compact
                divider
                value={
                  <Typography variant="body1">
                    {ensureInternationalPhoneNumber(
                      user.phoneNumber,
                      user.country,
                    )}
                  </Typography>
                }
              />
              <OptionListItem
                data-testid="signedUpFrom"
                title="Trustpilot Verified Reviewer"
                compact
                divider
                value={
                  <Grid
                    container
                    item
                    wrap="nowrap"
                    alignItems="center"
                    spacing={1}
                  >
                    {user.receivedTrustpilotReview ? (
                      <>
                        <Grid item>
                          <StatusChip
                            icon={<CheckCircleOutlineIcon />}
                            variant="success"
                          >
                            Verified
                          </StatusChip>
                        </Grid>
                        <Grid item>
                          <IconButton
                            disabled={isLoadingUpdateUser}
                            data-testid="unverifyTrustpilot"
                            size="small"
                            className={styles.closeIcon}
                            onClick={handleToggleTrustpilotVerify}
                          >
                            <ReplayIcon />
                          </IconButton>
                        </Grid>
                      </>
                    ) : (
                      <Grid item>
                        <Button
                          disabled={isLoadingUpdateUser}
                          data-testid="verifyTrustpilot"
                          variant="contained"
                          color="primary"
                          onClick={handleToggleTrustpilotVerify}
                          startIcon={
                            <CheckCircleOutlineIcon fontSize="small" />
                          }
                        >
                          Verify
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                }
              />
              <OptionListItem
                data-testid="signedUpFrom"
                title="Signed Up From"
                compact
                divider
                value={
                  parsedUserStats.signupCountry ? (
                    <Typography variant="body1">
                      {parsedUserStats.signupCountry}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Set.
                    </Typography>
                  )
                }
              />
              <OptionListItem
                data-testid="enrollments"
                title="Enrolled In"
                compact
                divider
                value={
                  courses &&
                  user.enrolledCourseIds &&
                  user.enrolledCourseIds.length ? (
                    <Grid container direction="column" spacing={2}>
                      {courses
                        .filter((course) =>
                          includes(user.enrolledCourseIds, course.id),
                        )
                        .map((course) => {
                          const progress = find(userStats?.courseProgress, [
                            'courseId',
                            course.id,
                          ]);
                          return {
                            ...course,
                            progress: progress ? progress.value : 0,
                          };
                        })
                        .map((course, index) => (
                          <Grid key={index} container item direction="column">
                            <Grid item>
                              <Typography variant="body1">
                                {course.title}
                                {course.type === 'BLUEPRINT'
                                  ? ' Blueprint '
                                  : ' '}
                                {course.isMentorshipEnabled &&
                                find(
                                  user.enrolledCourseStatus,
                                  (status) =>
                                    status.courseId === course.id &&
                                    status.hasMentorship,
                                )
                                  ? '+ Mentorship'
                                  : ''}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              container
                              spacing={1}
                              direction="row"
                              wrap="nowrap"
                            >
                              <Grid item xs={10} container>
                                <LinearProgress
                                  className={styles.progress}
                                  variant="determinate"
                                  value={course.progress * 100}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="body2">
                                  {(course.progress * 100).toFixed(1)} %
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Enrolled In Any Course.
                    </Typography>
                  )
                }
              />
              <OptionListItem
                data-testid="lastLoginFrom"
                title="Last Login From"
                compact
                divider
                value={
                  parsedUserStats.lastLoginCountry ? (
                    <Typography variant="body1">
                      {parsedUserStats.lastLoginCountry}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Set.
                    </Typography>
                  )
                }
              />
              <OptionListItem
                data-testid="role"
                title="Role"
                compact
                divider
                value={
                  role ? (
                    <Typography variant="body1">{role.title}</Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Set.
                    </Typography>
                  )
                }
              />
              <OptionListItem
                data-testid="createdAt"
                title="Account Created At"
                compact
                divider
                value={
                  <Typography variant="body1">
                    {format(new Date(user.createdAt), 'MMMM d, yyyy')}
                  </Typography>
                }
              />
              <OptionListItem
                data-testid="firstEnrollment"
                title="Enrolled Into First Paid Program"
                compact
                value={
                  userStats.firstEnrolledAt ? (
                    <Typography variant="body1">
                      {format(
                        new Date(userStats.firstEnrolledAt),
                        'MMMM d, yyyy',
                      )}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Set.
                    </Typography>
                  )
                }
              />
            </List>
            <List disablePadding className={styles.list}>
              <ListItem className={styles.header} disableGutters divider>
                <Typography className={styles.title} variant="h6">
                  Account Stats
                </Typography>
              </ListItem>
              <OptionListItem
                className={clsx({
                  [styles.thinListItemBottom]: accountStatus !== 'active',
                })}
                title="Status"
                compact
                divider={accountStatus === 'active'}
                value={
                  <Grid
                    container
                    wrap="nowrap"
                    alignItems={isMdUp ? 'center' : 'flex-start'}
                    direction={isMdUp ? 'row' : 'column'}
                    spacing={isMdUp ? 3 : 1}
                  >
                    <Grid item>
                      <StatusChip
                        icon={
                          accountStatus === 'active' ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <ErrorOutlineIcon />
                          )
                        }
                        variant={
                          accountStatus === 'active' ? 'success' : 'warning'
                        }
                      >
                        {capitalize(accountStatus)}
                      </StatusChip>
                    </Grid>
                    {user.suspension ? (
                      <Grid item>
                        <Button
                          className={styles.inlineButton}
                          startIcon={
                            <RemoveCircleOutlineIcon fontSize="small" />
                          }
                          onClick={showRevokeUserSuspensionDialog}
                        >
                          Remove Suspension
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                }
              />
              {accountStatus === 'suspended' ? (
                <>
                  <OptionListItem
                    className={clsx(
                      styles.thinListItemTop,
                      styles.thinListItemBottom,
                    )}
                    title="Suspension reason"
                    compact
                    value={
                      <Typography variant="body1">
                        {user.suspension.reason}
                      </Typography>
                    }
                  />
                  {user.suspension.suspendedByUser ? (
                    <OptionListItem
                      className={clsx(
                        styles.thinListItemTop,
                        styles.thinListItemBottom,
                      )}
                      title="Suspended By"
                      compact
                      value={
                        <Typography variant="body1">
                          {user.suspension.suspendedByUser.firstName}{' '}
                          {user.suspension.suspendedByUser.lastName}
                        </Typography>
                      }
                    />
                  ) : null}
                  <OptionListItem
                    className={clsx(styles.thinListItemTop)}
                    title="Suspension duration"
                    compact
                    divider
                    value={
                      user.suspension.expiresAt ? (
                        <>
                          <Typography variant="body1">
                            {formatDistance(
                              new Date(user.suspension.expiresAt),
                              new Date(user.suspension.createdAt),
                            )}
                          </Typography>
                          <Typography variant="body1">
                            {format(
                              new Date(user.suspension.createdAt),
                              'MMMM d, yyyy',
                            ) +
                              ' - ' +
                              format(
                                new Date(user.suspension.expiresAt),
                                'MMMM d, yyyy',
                              )}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant="body1">Forever</Typography>
                          <Typography variant="body1">
                            Since{' '}
                            {format(
                              new Date(user.suspension.createdAt),
                              'MMMM d, yyyy',
                            )}
                          </Typography>
                        </>
                      )
                    }
                  />
                </>
              ) : null}
              <OptionListItem
                data-testid="activeLoginCount"
                title="Number Of Logins"
                compact
                divider
                value={
                  <Typography variant="body1">
                    {userStats.activeLoginCount}
                  </Typography>
                }
              />
              <OptionListItem
                data-testid="signUpIp"
                title="Sign Up Ip"
                compact
                divider
                value={
                  parsedUserStats.signUpIp ? (
                    <Typography variant="body1">
                      {parsedUserStats.signUpIp}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Set.
                    </Typography>
                  )
                }
              />
              <OptionListItem
                data-testid="signUpSource"
                title="Sign Up Source"
                compact
                divider
                value={
                  userStats.signUpSource ? (
                    <Typography variant="body1">
                      {userStats.signUpSource}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Set.
                    </Typography>
                  )
                }
              />
              <OptionListItem
                data-testid="client"
                title="Client Used"
                compact
                divider
                value={
                  parsedUserStats.lastLoginClient ? (
                    <Typography variant="body1">
                      {parsedUserStats.lastLoginClient}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Set.
                    </Typography>
                  )
                }
              />
              <OptionListItem
                data-testid="lastLoginAt"
                title="Last Login Date/Time"
                compact
                divider
                value={
                  parsedUserStats.lastLoginAt ? (
                    <Typography variant="body1">
                      {format(
                        new Date(parsedUserStats.lastLoginAt),
                        "MMMM d, yyyy '@' h:mm a z",
                      )}
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Set.
                    </Typography>
                  )
                }
              />
              <OptionListItem
                data-testid="lastActiveLocations"
                title="Last 5 Active Logins"
                compact
                value={
                  parsedUserStats.lastActiveLocations.length ? (
                    <>
                      {parsedUserStats.lastActiveLocations.map((val, index) => (
                        <Typography key={index} variant="body1">
                          {val}
                        </Typography>
                      ))}
                    </>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Set.
                    </Typography>
                  )
                }
              />
            </List>
            <List disablePadding className={clsx(styles.list, styles.actions)}>
              <ListItem className={styles.header} disableGutters divider>
                <Typography className={styles.title} variant="h6">
                  Actions
                </Typography>
              </ListItem>
              <ListItem className={styles.actionsItems} disableGutters>
                {accountStatus !== 'suspended' ? (
                  <Button
                    className={styles.button}
                    startIcon={<BlockIcon />}
                    onClick={showSuspendUserDialog}
                    disabled={me.id === user.id}
                  >
                    Suspend Account
                  </Button>
                ) : null}
                <Button
                  className={styles.button}
                  startIcon={<GetAppIcon />}
                  onClick={handleExport}
                  disabled={isMeRequested}
                >
                  Export Data
                </Button>
                <Button
                  className={styles.button}
                  startIcon={<VpnKeyIcon />}
                  onClick={showResetPasswordModal}
                >
                  Reset Password
                </Button>
                <DropdownMenu
                  control={
                    <Button
                      disabled={isLoadingUpdateUser}
                      className={styles.button}
                      startIcon={<AccountCircleIcon />}
                    >
                      Change User Role
                    </Button>
                  }
                >
                  {roles.map((role) => (
                    <MenuItem
                      button
                      key={role.id}
                      className={styles.dropdownMenuItem}
                      data-testid={'setRole-' + role.id}
                      onClick={handleChangeUserRole(role.id)}
                    >
                      <Radio checked={user.roleId === role.id} />
                      {role.title}
                    </MenuItem>
                  ))}
                </DropdownMenu>
                <Button
                  variant="contained"
                  className={styles.deleteButton}
                  startIcon={<DeleteIcon />}
                  onClick={showDeleteUserDialog}
                  disabled={me.id === user.id}
                >
                  Delete Account
                </Button>
                <Typography className={styles.deleteWarning} variant="body2">
                  Deleting this user’s account will permanently erase any data
                  associated with them from the system.
                </Typography>
              </ListItem>
            </List>
          </div>
        </UserDetailsLayout>
      )}
    </PageLoader>
  );
};

export default AdminUserStats;
