import { createSelector } from 'reselect';
///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SET_REMEMBER_ME = 'set-remember-me';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const setRememberMe = (rememberMe) => {
  return {
    type: SET_REMEMBER_ME,
    payload: {
      rememberMe,
    },
  };
};

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultState = localStorage.getItem('remember-me') ? true : false;

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_REMEMBER_ME:
      const value = action.payload.rememberMe;
      if (value) {
        localStorage.setItem('remember-me', 'true');
      } else {
        localStorage.removeItem('remember-me');
      }
      return value;
    default:
      return state;
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectRememberMe = (store) => store.rememberMe || false;
export const selectRememberMeAsSessionType = createSelector(
  selectRememberMe,
  (rememberMe) => (rememberMe ? 'session_long' : 'session_short'),
);
