import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { RECORD_LESSON_PROGRESS_SUCCESS } from './record-lesson-progress';

const MODULE = 'last-lesson';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchLastLesson = Exec.requestActionCreator(MODULE);
export const fetchLastLessonLoading = Exec.loadingActionCreator(MODULE);
export const fetchLastLessonSuccess = Exec.successActionCreator(MODULE);
export const fetchLastLessonError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);

export const reducer = (state, action) => {
  switch (action.type) {
    case RECORD_LESSON_PROGRESS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload.data };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectLastLessonRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectLastLesson = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchLastLesson = function* ({ payload, meta }) {
  try {
    const { data } = yield call(Api.getLastLesson, payload.courseId);
    yield put(fetchLastLessonSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchLastLessonError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchLastLesson);
