import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'update-course';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPDATE_COURSE_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPDATE_COURSE_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const updateCourse = Exec.requestActionCreator(MODULE);
export const updateCourseLoading = Exec.loadingActionCreator(MODULE);
export const updateCourseSuccess = Exec.successActionCreator(MODULE);
export const updateCourseError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpdateCourseRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpdateCourse = function* ({ payload, meta }) {
  const { courseId, ...attributes } = payload;

  try {
    yield put(closeSnackbar(UPDATE_COURSE_REQUEST));
    yield put(updateCourseLoading());
    const { data } = yield call(Api.updateCourse, courseId, attributes);
    yield put(updateCourseSuccess({ data }, meta));
  } catch (err) {
    yield put(updateCourseError(err, meta));
    yield put(
      enqueueSnackbar(UPDATE_COURSE_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpdateCourse);
