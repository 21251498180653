import { Link } from 'react-router-dom';

import VideoCallIcon from 'Icons/video-call';
import LifeSaverIcon from 'Icons/life-saver';
import FacebookIcon from 'Icons/facebook';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import NavigationCard from 'Components/pages/get-help/navigation-card';

import { makeStyles, Button, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(10),
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    maxWidth: 892,
    marginBottom: theme.spacing(6),
  },
  item: {
    margin: theme.spacing(0, 0, 2),
  },
  card: {
    width: '100%',
    height: '100%',
  },
}));

const GetHelpPage = () => {
  const styles = useStyles();

  const cards = [
    {
      title: 'Facebook Groups',
      body: 'Join our private Facebook communities for our Freedom.co courses.',
      icon: <FacebookIcon />,
      button: (
        <Button
          size="large"
          component={Link}
          to="/facebook-groups"
          color="primary"
        >
          Join the group
        </Button>
      ),
    },
    {
      title: 'Mentorship Calls',
      body: 'Attend our weekly / bi-weekly live Q&A mentorship calls with our team of million dollar sellers and coaches.',
      icon: <VideoCallIcon />,
      button: (
        <Button
          size="large"
          component={Link}
          to="/mentorship-calls"
          color="primary"
        >
          Join next call
        </Button>
      ),
    },
    {
      title: 'Help Center',
      body: 'Search FAQs, answers to common questions and problems.',
      icon: <LifeSaverIcon />,
      button: (
        <Button size="large" component={Link} to="/help" color="primary">
          Find your answer
        </Button>
      ),
    },
  ];

  return (
    <PageLoader>
      <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
        <PageContainer>
          <Typography variant="h4" align="center" className={styles.title}>
            How To Get Help
          </Typography>
          <Typography
            variant="body1"
            align="center"
            className={styles.subtitle}
          >
            We’ve got you covered! Simply choose from one of the options below:
          </Typography>
          <div className={styles.group}>
            <Grid container spacing={2} justify="center">
              {cards.map((card, index) => (
                <Grid key={index} className={styles.item} item sm={12} md={4}>
                  <NavigationCard
                    className={styles.card}
                    {...card}
                    key={index}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </PageContainer>
      </PageLayout>
    </PageLoader>
  );
};

export default GetHelpPage;
