import { Link, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { toNumber, toUpper, includes } from 'lodash';
import { format } from 'date-fns';

import exportUsersUrl from 'Util/export-users-url';
import { ensureInternationalPhoneNumber } from 'Util/ensure-phone-number';
import {
  selectCourses,
  selectCoursesRequest,
  selectMeRequest,
  selectUsers,
  selectUsersRequest,
  selectUsersRowsPerPage,
  selectUsersTotalCount,
} from 'Redux/selectors';
import {
  fetchCourses,
  fetchMe,
  fetchUsers,
  fetchUsersSetRowsPerPage,
} from 'Redux/actions';
import useScrollToTop from 'Hooks/use-scroll-to-top';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useLocalStorageState from 'Hooks/use-local-storage-state';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import TableSortLabel from 'Components/shared/table-sort-label';
import StatusChip from 'Components/shared/status-chip';
import SearchInput from 'Components/shared/search-input';
import Scrollable from 'Components/shared/scrollable';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderAdminItems from 'Components/shared/header-admin-items';
import DrawerAdminItems from 'Components/shared/drawer-admin-items';

import { GetApp as GetAppIcon, Add as AddIcon } from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  Typography,
  Grid,
  Paper,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: 0,
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'calc(100% - 280px)',
      marginLeft: 280,
    },
  },
  drawer: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    position: 'fixed',
    marginTop: 1, // divider offset
    width: 280,
  },
  titleAndActions: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  title: {
    height: 40,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  export: {
    color: theme.palette.text.secondary,
  },
  list: {
    width: '100%',
  },
  searchAndFilter: {
    padding: theme.spacing(3, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  input: {
    flexGrow: 1,
  },
  showFiltersButton: {
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  pagination: {
    paddingLeft: 0,
  },
  scrollable: {
    padding: 0,
  },
  table: {
    minWidth: 1205,
  },
  firstColumn: {
    paddingLeft: theme.spacing(3),
    whiteSpace: 'nowrap',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  tableBody: {
    '& .MuiTableRow-hover': {
      cursor: 'pointer',
    },
  },
}));

const AdminUsersPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [orderBy, setOrderBy] = useLocalStorageState(
    'adminUsersPageOrderBy',
    'createdAt',
  );
  const [orderDirection, setOrderDirection] = useLocalStorageState(
    'adminUsersPageOrderDirection',
    'desc',
  );

  const page = toNumber(params.get('page') || 0);
  const query = params.get('query');

  const rowsPerPage = useSelector(selectUsersRowsPerPage);
  const totalCount = useSelector(selectUsersTotalCount);
  const users = useSelector(selectUsers);
  const courses = useSelector(selectCourses);

  const { isLoading, isRequested, error } = useCombinedRequestsSelector(
    selectMeRequest,
    selectUsersRequest,
    selectCoursesRequest,
  );

  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const isXlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  const navigateReplace = useCallback(
    (to) => {
      navigate(to, { replace: true });
    },
    [navigate],
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      dispatch(fetchUsersSetRowsPerPage(toNumber(event.target.value)));
    },
    [dispatch],
  );
  const handleChangePage = useCallback(
    (event, value) => {
      if (query) {
        navigateReplace('/admin/users?query=' + query + '&page=' + value);
      } else {
        navigateReplace('/admin/users?page=' + value);
      }
    },
    [navigateReplace, query],
  );
  const handleSearch = useCallback(
    (query) => {
      if (query) {
        navigateReplace('/admin/users?query=' + query);
      } else {
        navigateReplace('/admin/users');
      }
    },
    [navigateReplace],
  );
  const handleOpenUser = useCallback(
    (event) => {
      const userId = event.currentTarget.getAttribute('data-value');
      navigate('/admin/users/' + userId);
    },
    [navigate],
  );
  const handleExport = useCallback(() => {
    // Here, we need to fetch some user first so that, when we navigate to
    // the export URL, the token will be refreshed (if previously expired)
    dispatch(fetchMe(null, { isPromise: true })).then(() => {
      window.open(exportUsersUrl({ query, orderBy, orderDirection }));
    });
  }, [dispatch, orderBy, orderDirection, query]);
  const handleSortLabel = useCallback(
    (name, direction) => {
      setOrderBy(name);
      setOrderDirection(direction);
    },
    [setOrderDirection, setOrderBy],
  );

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchUsers({ page, query, orderBy, orderDirection }));
  }, [dispatch, page, query, rowsPerPage, orderDirection, orderBy]);

  useScrollToTop([page, query, rowsPerPage]);

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout
          waiting={isRequested}
          headerItems={<HeaderAdminItems />}
          drawerItems={<DrawerAdminItems />}
          drawerBreakpoint="xl"
        >
          <div className={styles.root}>
            {isXlUp && <DrawerAdminItems className={styles.drawer} />}

            <PageContainer
              className={styles.container}
              maxWidth={1200}
              sidebarWidth={280}
              sidebarToggleBreakpoint="xl"
            >
              <div className={styles.titleAndActions}>
                <Typography variant="h4" className={styles.title}>
                  All Users
                </Typography>
                <div>
                  <Grid
                    container
                    spacing={3}
                    direction={isSmUp ? 'row' : 'row-reverse'}
                  >
                    <Grid item>
                      <Button
                        className={styles.export}
                        startIcon={<GetAppIcon />}
                        onClick={handleExport}
                        disabled={isRequested}
                      >
                        Export
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        component={Link}
                        to="/admin/users/create"
                      >
                        New User
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <Paper elevation={1} className={styles.list}>
                <div className={styles.searchAndFilter}>
                  <SearchInput
                    className={styles.input}
                    placeholder="Search for a user"
                    onSubmit={handleSearch}
                    defaultValue={query}
                  />
                </div>
                <Divider />
                <TableContainer component="div">
                  <Scrollable className={styles.scrollable}>
                    <div>
                      <Table
                        size="small"
                        aria-label="table of users"
                        className={styles.table}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className={styles.firstColumn}>
                              <TableSortLabel
                                name="firstName"
                                disabled={Boolean(query)}
                                active={orderBy === 'firstName'}
                                direction={orderDirection}
                                onClick={handleSortLabel}
                              >
                                Name
                              </TableSortLabel>
                            </TableCell>
                            <TableCell>
                              <TableSortLabel
                                name="email"
                                disabled={Boolean(query)}
                                active={orderBy === 'email'}
                                direction={orderDirection}
                                onClick={handleSortLabel}
                              >
                                Email
                              </TableSortLabel>
                            </TableCell>
                            <TableCell className={styles.noWrap}>
                              Phone Number
                            </TableCell>
                            <TableCell>
                              <TableSortLabel
                                name="createdAt"
                                disabled={Boolean(query)}
                                active={orderBy === 'createdAt'}
                                direction={orderDirection}
                                onClick={handleSortLabel}
                              >
                                Joined
                              </TableSortLabel>
                            </TableCell>
                            <TableCell className={styles.noWrap}>
                              Course Enrollment
                            </TableCell>
                            <TableCell className={styles.noWrap}>
                              Affiliate Sale
                            </TableCell>
                            <TableCell className={styles.noWrap}>
                              Account Standing
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={styles.tableBody}>
                          {users.map((user) => {
                            const {
                              id,
                              firstName,
                              lastName,
                              email,
                              createdAt,
                              enrolledCourseIds,
                              referedBy,
                              paymentAccountStatus,
                            } = user;

                            return (
                              <TableRow
                                key={id}
                                hover
                                data-value={id}
                                onClick={handleOpenUser}
                              >
                                <TableCell className={styles.firstColumn}>
                                  {firstName} {lastName}
                                </TableCell>
                                <TableCell>{email}</TableCell>
                                <TableCell className={styles.noWrap}>
                                  {ensureInternationalPhoneNumber(
                                    user.phoneNumber,
                                    user.country,
                                  )}
                                </TableCell>
                                <TableCell className={styles.noWrap}>
                                  {format(new Date(createdAt), 'MMM d, yyyy')}
                                </TableCell>
                                <TableCell>
                                  {courses &&
                                    courses
                                      .filter((course) =>
                                        includes(enrolledCourseIds, course.id),
                                      )
                                      .map((v) => v.code)
                                      .join(',')}
                                </TableCell>
                                <TableCell>
                                  {referedBy ? 'Yes' : 'No'}
                                </TableCell>
                                <TableCell>
                                  <StatusChip
                                    variant={
                                      paymentAccountStatus === 'good'
                                        ? 'success'
                                        : paymentAccountStatus ===
                                          'disconnected'
                                        ? 'invalid'
                                        : 'error'
                                    }
                                  >
                                    {toUpper(paymentAccountStatus)}
                                  </StatusChip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  </Scrollable>
                  <TablePagination
                    component="div"
                    size="small"
                    SelectProps={{
                      'data-testid': 'paginationSelect',
                    }}
                    classes={{ toolbar: styles.pagination }}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={isSmUp ? [5, 10, 25, 50, 100] : []}
                  />
                </TableContainer>
              </Paper>
            </PageContainer>
          </div>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default AdminUsersPage;
