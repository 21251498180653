import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { SET_DEFAULT_PAYMENT_METHOD_SUCCESS } from './set-default-payment-method';
import { DELETE_PAYMENT_METHOD_SUCCESS } from './delete-payment-method';
import { CREATE_PAYMENT_METHOD_SUCCESS } from './create-payment-method';

const MODULE = 'payment-methods';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchPaymentMethods = Exec.requestActionCreator(MODULE);
export const fetchPaymentMethodsLoading = Exec.loadingActionCreator(MODULE);
export const fetchPaymentMethodsSuccess = Exec.successActionCreator(MODULE);
export const fetchPaymentMethodsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);
export const reducer = (state, action) => {
  switch (action.type) {
    case DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        data: state.data
          ? state.data.filter(
              (paymentMethod) => paymentMethod.id !== action.payload.data.id,
            )
          : null,
      };
    case SET_DEFAULT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        data: state.data
          ? state.data.map((paymentMethod) => ({
              ...paymentMethod,
              isDefault: paymentMethod.id === action.payload.data.id,
            }))
          : null,
      };
    case CREATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        data: state.data
          ? [
              action.payload.data,
              ...state.data.map((p) =>
                // unset default flag from other payment methods if the newly
                // created one is the default
                action.payload.data.isDefault ? { ...p, isDefault: false } : p,
              ),
            ]
          : [action.payload.data],
      };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectPaymentMethodsRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectPaymentMethods = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchPaymentMethods = function* ({ payload, meta }) {
  try {
    const { data } = yield call(
      Api.getPaymentMethods,
      payload && payload.userId ? payload.userId : 'me',
    );
    yield put(fetchPaymentMethodsSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchPaymentMethodsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchPaymentMethods);
