import { put, call, takeLeading } from 'redux-saga/effects';
import { get } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPDATE_RESOURCE_SUCCESS } from './update-resource';
import { CREATE_RESOURCE_SUCCESS } from './create-resource';

const MODULE = 'resources';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
const defaultFetchResources = Exec.requestActionCreator(MODULE);
export const fetchResources = (payload, options) => {
  const offset = get(payload, 'offset', 0);
  return defaultFetchResources(payload, {
    isAppending: offset > 0,
    ...options,
  });
};
export const fetchResourcesLoading = Exec.loadingActionCreator(MODULE);
export const fetchResourcesSuccess = Exec.successActionCreator(MODULE);
export const fetchResourcesError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);
export const reducer = (state, action) => {
  switch (action.type) {
    case CREATE_RESOURCE_SUCCESS:
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      };
    case UPDATE_RESOURCE_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((q) => (q.id === data.id ? data : q)),
      };
    }
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectResourcesRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectResources = Exec.dataSelectorCreator(MODULE);

export const selectResourcesTotalCount = (store) =>
  store.resources.pageInfo ? store.resources.pageInfo.totalCount : 0;

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchResources = function* ({ payload, meta }) {
  try {
    const { data, __meta = {} } = yield call(Api.getResources, payload);

    yield put(fetchResourcesSuccess({ data, pageInfo: __meta.pageInfo }, meta));
  } catch (err) {
    yield put(fetchResourcesError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchResources, takeLeading);
