import { useModal } from 'react-modal-hook';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import TooltipDialog, { propTypes } from 'Dialogs/tooltip-dialog';

import { Info as InfoIcon } from '@material-ui/icons';
import { makeStyles, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.grey[500],
  },
}));

const TooltipButton = (props) => {
  const { className, component: Component, ...rest } = props;

  const styles = useStyles();
  const [showModal, hideModal] = useModal(() => (
    <Component onClose={hideModal} {...rest} />
  ));

  return (
    <IconButton
      className={clsx(styles.button, className)}
      aria-label="show information about this element"
      onClick={showModal}
    >
      <InfoIcon />
    </IconButton>
  );
};

TooltipButton.propTypes = {
  component: PropTypes.elementType,
  ...propTypes,
};

TooltipButton.defaultProps = {
  component: TooltipDialog,
};

export default TooltipButton;
