import {
  useLocation,
  useNavigate,
  matchPath,
  useParams,
  Link,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { get, find, findIndex } from 'lodash';
import clsx from 'clsx';

import {
  selectCoursesRequest,
  selectCoursesWithGraph,
  selectLessonQuestionsWithTags,
  selectLessonQuestionTopics,
  selectLessonsRequest,
  selectModulesRequest,
} from 'Redux/selectors';
import { fetchCourses, fetchLessons, fetchModules } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import NavTabs from 'Components/shared/nav-tabs';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import CourseHeader from 'Components/shared/course-header';
import QuestionAccordion from 'Components/pages/help/question-accordion';
import ModuleAccordion from 'Components/pages/help/module-accordion';
import HeaderNavigation from 'Components/pages/help/header-navigation';
import Cards from 'Components/pages/help/cards';

import { makeStyles, Typography, Tab } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(10),
      paddingTop: theme.spacing(10),
    },
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    marginBottom: theme.spacing(4),
  },
  tabs: {
    marginBottom: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  sectionTabs: {
    [theme.breakpoints.up('md')]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  sectionTabsAlwaysRounded: {
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },
  courseTabs: {
    marginTop: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginTop: 0,
    },
  },
  container: {
    width: '100%',
  },
  innerContainer: {
    width: '100%',
    marginBottom: -1,
  },
}));

const HelpFaqPage = () => {
  const styles = useStyles();
  const { hash } = useLocation();
  const { courseSlug } = useParams();
  const dispatch = useDispatch();

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectModulesRequest,
    selectLessonsRequest,
  );
  const courses = useSelector(selectCoursesWithGraph);

  const course = find(courses, ['slug', courseSlug]);
  const modules = course ? course.modules : null;
  const questions = useSelector(selectLessonQuestionsWithTags);
  const topics = useSelector(selectLessonQuestionTopics);

  const navigate = useNavigate();
  const match =
    matchPath('/#/:tab/:moduleOrTopicSlug/:questionSlug', '/' + hash) ||
    matchPath('/#/:tab/:moduleOrTopicSlug', '/' + hash) ||
    matchPath('/#/:tab', '/' + hash);

  const handleNavigate = useCallback(
    (event, value) => navigate(`./#/${['module-faq', 'topic-faq'][value]}`),
    [navigate],
  );

  const params = get(match, 'params', {});
  const tab = params.tab || 'module-faq';
  const moduleOrTopicSlug =
    params.moduleOrTopicSlug ||
    get(tab === 'module-faq' ? modules : topics, '0.slug');
  const questionSlug =
    params.questionSlug ||
    get(
      find(questions, (q) => q.tags.includes(moduleOrTopicSlug)),
      'slug',
    );

  const visibleQuestions = useMemo(
    () =>
      questions && questions.filter((q) => q.tags.includes(moduleOrTopicSlug)),
    [moduleOrTopicSlug, questions],
  );

  const group = tab === 'module-faq' ? modules : topics;

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    dispatch(fetchModules({ courseId: courseSlug }));
    dispatch(fetchLessons({ courseId: courseSlug }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug]);

  useEffect(() => {
    if (!isLoading && !error) {
      const timeout = setTimeout(() => {
        const element = document.getElementById(hash.slice(1));
        if (element) {
          element.scrollIntoView({ block: 'center' });
        }
      }, 500);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  const coursesWithResourceVault = courses
    ? courses.filter(
        // (c) => c.purchasedAt && ['amazon-fba', 'shopify'].includes(c.slug),
        (c) => c.purchasedAt && ['amazon-fba'].includes(c.slug),
      )
    : [];

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <CourseHeader course={course}>
            <HeaderNavigation variant="vault" option="faq" />
          </CourseHeader>
          <PageContainer component="section" className={styles.root}>
            <Typography variant="h3" className={styles.title} align="center">
              Frequently Asked Questions
            </Typography>

            <div className={styles.tabs}>
              <NavTabs
                className={clsx(
                  styles.sectionTabs,
                  coursesWithResourceVault.length < 2
                    ? styles.sectionTabsAlwaysRounded
                    : null,
                )}
                value={{ 'module-faq': 0, 'topic-faq': 1 }[tab] || 0}
                onChange={handleNavigate}
              >
                <Tab label="By Module" />
                <Tab label="By Topic" />
              </NavTabs>

              {coursesWithResourceVault.length > 1 ? (
                <NavTabs
                  className={styles.courseTabs}
                  value={findIndex(
                    coursesWithResourceVault,
                    (c) => c.slug === courseSlug,
                  )}
                >
                  {coursesWithResourceVault.map((c) => (
                    <Tab
                      key={c.slug}
                      label={c.title}
                      component={Link}
                      to={'/help/faq/' + c.slug}
                    />
                  ))}
                </NavTabs>
              ) : null}
            </div>

            <div className={styles.container}>
              {(group || []).map((line) => (
                <ModuleAccordion
                  key={line.slug}
                  id={`/${tab}/${line.slug}`}
                  href={`./#/${tab}/${line.slug}`}
                  expanded={moduleOrTopicSlug === line.slug}
                  title={line.title}
                >
                  <div className={styles.innerContainer}>
                    {(visibleQuestions || []).map((question) => (
                      <QuestionAccordion
                        key={question.slug}
                        id={`/${tab}/${line.slug}/${question.slug}`}
                        href={`./#/${tab}/${line.slug}/${question.slug}`}
                        expanded={questionSlug === question.slug}
                        title={question.title}
                        answer={question.answer}
                      />
                    ))}
                  </div>
                </ModuleAccordion>
              ))}
            </div>
          </PageContainer>
          <Cards />
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default HelpFaqPage;
