import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { selectRevokeUserSuspensionRequest, selectUser } from 'Redux/selectors';
import { revokeUserSuspension } from 'Redux/actions';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  input: {
    width: '100%',
  },
});

const RevokeUserSuspensionDialog = (props) => {
  const { onClose } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { isLoading } = useSelector(selectRevokeUserSuspensionRequest);

  const { control, handleSubmit } = useForm();

  const handleRevoke = useCallback(
    (data) => {
      const { message } = data;

      dispatch(
        revokeUserSuspension({ userId: user.id, message }, { isPromise: true }),
      ).then(onClose);
    },
    [dispatch, onClose, user.id],
  );

  return (
    <Dialog
      title="Remove Suspension"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleRevoke)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">Confirm</Button>}
    >
      <Typography variant="body1" align="center" gutterBottom>
        Add a note explaining why you are lifting {user.firstName}{' '}
        {user.lastName}'s suspension down below (optional):
      </Typography>
      <Input
        className={styles.input}
        control={control}
        label="Notes"
        name="message"
        multiline
        rows={5}
        rowsMax={5}
      />
    </Dialog>
  );
};

RevokeUserSuspensionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default RevokeUserSuspensionDialog;
