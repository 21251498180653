import { createBrowserHistory as defaultCreateBrowserHistory } from 'history';

const createBrowserHistory = (options) => {
  const history = defaultCreateBrowserHistory(options);

  let transitions = 0;
  history.listen((location, action) => {
    if (action === 'PUSH') {
      transitions++;
    } else if (action === 'POP') {
      transitions--;
    }
  });
  // prevent navigation further back than the initial point
  history.goBack = () => {
    if (!transitions) {
      history.replace('/');
    } else {
      history.go(-1);
    }
  };
  return history;
};

export default createBrowserHistory;
