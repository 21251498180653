import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Typography, ListItem, Collapse } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    minHeight: 56,
    padding: theme.spacing(2.25, 4, 2.25, 0),
  },
  collapsable: {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  title: {
    marginRight: theme.spacing(2),
  },
  titleCollapsable: {
    marginBottom: theme.spacing(0.5),
  },
}));

const CardListItem = (props) => {
  const { className, title, value, collapsable, in: isIn, ...rest } = props;
  const styles = useStyles();

  if (collapsable) {
    return (
      <Collapse in={isIn} timeout="auto" unmountOnExit>
        <ListItem
          className={clsx(styles.root, styles.collapsable, className)}
          disableGutters
          {...rest}
        >
          <Typography
            variant="subtitle1"
            className={clsx(styles.title, styles.titleCollapsable)}
          >
            {title}
          </Typography>
          {value}
        </ListItem>
      </Collapse>
    );
  }

  return (
    <ListItem className={clsx(styles.root, className)} disableGutters {...rest}>
      <Typography variant="subtitle1" className={styles.title}>
        {title}:
      </Typography>
      {value}
    </ListItem>
  );
};

CardListItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  collapsable: PropTypes.bool,
  in: PropTypes.bool,
};

export default CardListItem;
