import { isMobile } from 'react-device-detect';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { indexOf, find, get } from 'lodash';

import withController from 'Util/with-controller';

import {
  makeStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core';

const useStyles = makeStyles({
  text: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 34,
  },
});

const Select = (props) => {
  const {
    className,
    name,
    value: allValues,
    label: selectLabel,
    options,
    errorText,
    disableErrors,
    multiple,
    renderValue,
    renderMenuItemText,
    ...rest
  } = props;
  const labelId = name + '-label';
  const styles = useStyles();

  const handleRenderValue = useCallback(
    (selected) => {
      if (renderValue) {
        return renderValue(selected);
      }
      if (multiple) {
        const remaining = selected.length - 2;
        return (
          selected
            .slice(0, 2)
            .map((s) => get(find(options, ['value', s]), 'label'))
            .join(', ') + (remaining > 0 ? ` +${remaining} more` : '')
        );
      }
      return get(find(options, ['value', selected]), 'label');
    },
    [multiple, options, renderValue],
  );

  return (
    <FormControl
      variant="outlined"
      className={className}
      error={Boolean(errorText)}
    >
      <InputLabel id={labelId} htmlFor={name}>
        {selectLabel}
      </InputLabel>
      <MuiSelect
        native={isMobile && !multiple}
        labelId={labelId}
        inputProps={{ id: name }}
        label={selectLabel}
        multiple={multiple}
        renderValue={handleRenderValue}
        value={allValues}
        {...rest}
      >
        {options.map(({ value, label, isPlaceholder }) =>
          isMobile && !multiple ? (
            <option key={value} value={value} disabled={isPlaceholder}>
              {label}
            </option>
          ) : (
            <MenuItem
              key={value}
              value={value}
              disabled={isPlaceholder}
              divider={isPlaceholder}
            >
              {renderMenuItemText ? (
                renderMenuItemText(value)
              ) : (
                <>
                  {multiple && (
                    <Checkbox checked={indexOf(allValues, value) > -1} />
                  )}
                  <ListItemText className={styles.text} primary={label} />
                </>
              )}
            </MenuItem>
          ),
        )}
      </MuiSelect>
      {!disableErrors ? (
        <FormHelperText>{errorText || ' '}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

Select.propTypes = {
  renderValue: PropTypes.func,
  renderMenuItemText: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  errorText: PropTypes.string,
  disableErrors: PropTypes.bool,
  onChange: PropTypes.func,
};

export default withController(Select, ({ target }) => target.value);
