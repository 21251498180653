import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { fetchPaymentMethods } from './payment-methods';

const MODULE = 'delete-payment-method';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const DELETE_PAYMENT_METHOD_REQUEST = Exec.requestConstantCreator(MODULE);
export const DELETE_PAYMENT_METHOD_SUCCESS =
  Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const deletePaymentMethod = Exec.requestActionCreator(MODULE);
export const deletePaymentMethodLoading = Exec.loadingActionCreator(MODULE);
export const deletePaymentMethodSuccess = Exec.successActionCreator(MODULE);
export const deletePaymentMethodError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectDeletePaymentMethodRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doDeletePaymentMethod = function* ({ payload, meta }) {
  const { paymentMethodId } = payload || {};

  try {
    yield put(closeSnackbar(DELETE_PAYMENT_METHOD_REQUEST));
    yield put(deletePaymentMethodLoading());
    const { data } = yield call(Api.deletePaymentMethod, paymentMethodId);
    yield put(deletePaymentMethodSuccess({ data }, meta));
    yield put(fetchPaymentMethods()); // for best user experience, refetch everything
  } catch (err) {
    yield put(deletePaymentMethodError(err, meta));
    yield put(
      enqueueSnackbar(DELETE_PAYMENT_METHOD_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail ||
              err.message ||
              'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doDeletePaymentMethod);
