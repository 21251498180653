import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { get, find, trimStart, lowerCase, noop, omit, filter } from 'lodash';
import clsx from 'clsx';

import {
  selectActiveCampaignLists,
  selectActiveCampaignListsRequest,
  selectCourses,
  selectCoursesRequest,
  selectCreateCourseRequest,
  selectOffers,
  selectOffersRequest,
  selectUpdateCourseRequest,
} from 'Redux/selectors';
import {
  createCourse,
  fetchActiveCampaignLists,
  fetchCourses,
  fetchOffers,
  updateCourse,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import UploadCourseGraphicDialog from 'Dialogs/upload-course-graphic-dialog';
import UploadCourseBackgroundDialog from 'Dialogs/upload-course-background-dialog';
import Switch from 'Components/shared/switch';
import Select from 'Components/shared/select';
import RichTextEditor from 'Components/shared/rich-text-editor';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import UploadFileGroup from 'Components/pages/admin/upload-file-group';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import {
  Save as SaveIcon,
  Add as AddIcon,
  Launch as LaunchIcon,
} from '@material-ui/icons';
import {
  Button,
  Grid,
  InputAdornment,
  FormControlLabel,
  makeStyles,
  Typography,
  useMediaQuery,
  Box,
} from '@material-ui/core';

const schemaFields = {
  title: yup.string().required('Field Required.'),
  slug: yup
    .string()
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g, 'Only Letters, Numbers and Dashes.')
    .required('Field Required.'),
  code: yup
    .string()
    .max(8, 'Max 8 Chars.')
    .matches(/[A-Z0-9]*/g, 'Only Uppercase Letters and Numbers.')
    .required('Field Required.'),
  salesPageUrl: yup.string().required('Field Required.'),
  communityUrl: yup
    .string()
    .url('Invalid URL.')
    .default(null)
    .nullable()
    .transform((value) => (!value ? null : value)),
  upgradeUrl: yup
    .string()
    .default(null)
    .nullable()
    .transform((value) => (!value ? null : value)),
  hasBoxset: yup.boolean().required('Field Required.'),
  isMentorshipEnabled: yup.boolean().required('Field Required.'),
  isPublic: yup.boolean().required('Field Required.'),
  isReleased: yup.boolean().required('Field Required.'),
  isVisible: yup.boolean().when('isReleased', {
    is: (v) => !Boolean(v),
    then: (schema) => schema.required('Field Required.'),
    otherwise: (schema) => schema.transform(() => true),
  }),
  defaultOfferSlug: yup.string().nullable(),
  description: yup.string().required('Field Required.'),
  unlockAfterTrustpilotReview: yup
    .boolean()
    .transform((value) => Boolean(value))
    .required('Field Required.'),
  trustpilotReviewCooldownDays: yup
    .number()
    .when('unlockAfterTrustpilotReview', {
      is: (v) => Boolean(v),
      then: (schema) =>
        schema
          .min(1, 'At Least 1.')
          .max(100, 'Max 100.')
          .default(null)
          .nullable()
          .transform((value) => (isNaN(value) || !value ? undefined : value))
          .typeError('Not a Number.')
          .required('Field Required.'),
      otherwise: (schema) => schema.nullable().transform(() => null),
    }),
  supportsAffiliateLinks: yup.boolean().when('defaultOfferSlug', {
    is: (v) => Boolean(v),
    then: (schema) => schema.required('Field Required.'),
    otherwise: (schema) => schema.transform(() => false),
  }),
  affiliateDescription: yup.string().when('supportsAffiliateLinks', {
    is: (v) => Boolean(v),
    then: (schema) =>
      schema
        .default(null)
        .nullable()
        .transform((value) => (!value ? undefined : value))
        .required('Field Required.'),
    otherwise: (schema) => schema.nullable().transform(() => null),
  }),
  setupFailedPayments: yup.boolean().required('Field Required.'),
  successfulPaymentCollectionListId: yup.string().when('setupFailedPayments', {
    is: (v) => Boolean(v),
    then: (schema) =>
      schema
        .default(null)
        .nullable()
        .transform((value) => (!value ? undefined : value))
        .required('Field Required.'),
    otherwise: (schema) => schema.nullable().transform(() => null),
  }),
  failedPaymentListId: yup.string().when('setupFailedPayments', {
    is: (v) => Boolean(v),
    then: (schema) =>
      schema
        .default(null)
        .nullable()
        .transform((value) => (!value ? undefined : value))
        .required('Field Required.'),
    otherwise: (schema) => schema.nullable().transform(() => null),
  }),
  failedPaymentFieldPrefix: yup.string().when('setupFailedPayments', {
    is: (v) => Boolean(v),
    then: (schema) =>
      schema
        .default(null)
        .nullable()
        .transform((value) => (!value ? undefined : value))
        .required('Field Required.'),
    otherwise: (schema) => schema.nullable().transform(() => null),
  }),
  type: yup.string().required('Field Required.'),
  color: yup
    .string()
    .matches(/^([0-9a-f]{6}|[0-9a-f]{3})$/gi, 'Not a HEX color.')
    .required('Field Required.'),
};
const updateCourseSchema = yup.object().shape(schemaFields);
const createCourseSchema = yup
  .object()
  .shape(
    omit(schemaFields, [
      'isReleased',
      'isVisible',
      'isPublic',
      'supportsAffiliateLinks',
      'unlockAfterTrustpilotReview',
    ]),
  );

const types = [
  { value: 'course', label: 'Course' },
  { value: 'blueprint', label: 'Blueprint' },
];

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  input: {
    width: '100%',
  },
  colorPreview: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  switch: {
    marginBottom: theme.spacing(2.5),
  },
  upload: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  hidden: {
    opacity: 0,
    display: 'none',
  },
}));

const AdminCreateOrUpdateCourse = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId } = useParams();

  const offers = useSelector(selectOffers);
  const courses = useSelector(selectCourses);
  const activeCampaignLists = useSelector(selectActiveCampaignLists);
  const course = find(courses, ['id', courseId]);
  const courseOffers = filter(
    offers,
    (o) => course && o.courseId === course.id,
  );

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectOffersRequest,
    selectActiveCampaignListsRequest,
  );

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectUpdateCourseRequest,
    selectCreateCourseRequest,
  );

  useScopedDispatchEffect(() => {
    if (courseId) {
      dispatch(fetchCourses());
    }
    dispatch(fetchOffers());
    dispatch(fetchActiveCampaignLists());
  }, [dispatch, courseId]);

  const handleFormSubmit = useCallback(
    async (data) => {
      delete data.setupFailedPayments;

      const action = courseId
        ? updateCourse(
            { ...data, color: '#' + data.color, courseId },
            { isPromise: true },
          )
        : createCourse(
            { ...data, color: '#' + data.color },
            { isPromise: true },
          );

      dispatch(action)
        .then((payload) =>
          navigate('/admin/courses/' + payload.data.id + '/overview'),
        )
        .catch(noop);
    },
    [dispatch, navigate, courseId],
  );

  const [showUploadCourseGraphicDialog, hideUploadCourseGraphicDialog] =
    useModal(
      () => (
        <UploadCourseGraphicDialog
          courseId={courseId}
          onClose={hideUploadCourseGraphicDialog}
        />
      ),
      [courseId],
    );

  const [showUploadCourseBackgroundDialog, hideUploadCourseBackgroundDialog] =
    useModal(
      () => (
        <UploadCourseBackgroundDialog
          courseId={courseId}
          onClose={hideUploadCourseBackgroundDialog}
        />
      ),
      [courseId],
    );

  if (!isLoading && !course && courseId) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { control, watch, errors, handleSubmit, formState } = useForm({
          defaultValues:
            courseId && course
              ? {
                  ...course,
                  unlockAfterTrustpilotReview:
                    course.unlockAfterTrustpilotReviewAdmin,
                  setupFailedPayments: Boolean(
                    course.successfulPaymentCollectionListId ||
                      course.failedPaymentListId,
                  ),
                  color: trimStart(course.color, '#'),
                  type: lowerCase(course.type), // Backwards compatibility
                  description: course.description
                    ? course.description.html
                    : null,
                  affiliateDescription: course.affiliateDescription
                    ? course.affiliateDescription.html
                    : null,
                }
              : {
                  type: 'course',
                  color: 'FF0012',
                  isMentorshipEnabled: false,
                  setupFailedPayments: false,
                  hasBoxset: false,
                },
          validationSchema: courseId ? updateCourseSchema : createCourseSchema,
          reValidateMode: 'onChange',
        });

        const {
          color,
          unlockAfterTrustpilotReview,
          isReleased,
          isPublic,
          supportsAffiliateLinks,
          setupFailedPayments,
          defaultOfferSlug,
        } = watch({ nest: true });

        const edited = formState.dirty;

        return (
          <UserDetailsLayout>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={
                courseId && course
                  ? [
                      { href: '/admin/courses', label: 'Courses' },
                      {
                        href: '/admin/courses/' + course.id + '/overview',
                        label: course.title,
                      },
                      { label: 'Edit' },
                    ]
                  : [
                      { href: '/admin/courses', label: 'Courses' },
                      { label: 'Create' },
                    ]
              }
            />

            <form
              className={styles.form}
              noValidate
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <TitleAndAction
                className={styles.titleAndAction}
                title={
                  <Typography variant="h4">
                    {courseId ? 'Edit Course' : 'Create New Course'}
                  </Typography>
                }
              />

              <Grid container spacing={2} direction="column">
                <Grid item container spacing={isMdUp ? 4 : 2} direction="row">
                  <Grid item xs={12} md={4}>
                    <Input
                      data-testid="title"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Course Title"
                      name="title"
                      errorText={get(errors, 'title.message')}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Input
                      data-testid="slug"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Slug"
                      name="slug"
                      errorText={get(errors, 'slug.message')}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Input
                      data-testid="code"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Code"
                      name="code"
                      errorText={get(errors, 'code.message')}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <RichTextEditor
                    data-testid="description"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Description"
                    name="description"
                    errorText={get(errors, 'description.message')}
                  />
                </Grid>

                <Grid item>
                  <FormControlLabel
                    className={styles.switch}
                    control={
                      <Switch
                        data-testid="isPublic"
                        control={control}
                        disabled={isLoadingMutation}
                        name="isPublic"
                        color="primary"
                      />
                    }
                    label="Public (can be viewed without a purchase)"
                  />
                </Grid>

                <Grid item>
                  <Input
                    data-testid="salesPageUrl"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Sales Page URL"
                    name="salesPageUrl"
                    errorText={get(errors, 'salesPageUrl.message')}
                  />
                </Grid>

                {courseId ? (
                  <>
                    <Grid
                      item
                      className={clsx(!courseOffers.length && styles.hidden)}
                      xs={10}
                      sm={6}
                      md={4}
                    >
                      <Select
                        data-testid="defaultOfferSlug"
                        control={control}
                        className={styles.input}
                        disabled={isLoadingMutation}
                        label="Default Offer"
                        name="defaultOfferSlug"
                        options={courseOffers.map((o) => ({
                          value: o.slug,
                          label: o.title,
                        }))}
                        errorText={get(errors, 'defaultOfferSlug.message')}
                      />
                    </Grid>

                    {!courseOffers.length ? (
                      <Grid item xs={10} sm={6} md={4}>
                        <Box marginBottom={2}>
                          <Button
                            component={Link}
                            to="/admin/offers/create"
                            variant="contained"
                            color="primary"
                            endIcon={<LaunchIcon />}
                          >
                            Create An Offer For This Course
                          </Button>
                        </Box>
                      </Grid>
                    ) : null}

                    <Grid
                      item
                      className={clsx(!defaultOfferSlug && styles.hidden)}
                    >
                      <FormControlLabel
                        className={styles.switch}
                        control={
                          <Switch
                            data-testid="supportsAffiliateLinks"
                            control={control}
                            disabled={isLoadingMutation}
                            name="supportsAffiliateLinks"
                            color="primary"
                          />
                        }
                        label="Enable Affiliate Links"
                      />
                    </Grid>

                    <Grid
                      item
                      className={clsx(
                        (!supportsAffiliateLinks || !defaultOfferSlug) &&
                          styles.hidden,
                      )}
                    >
                      <RichTextEditor
                        data-testid="affiliateDescription"
                        control={control}
                        className={styles.input}
                        disabled={isLoadingMutation}
                        label="Description For Affiliate Offer"
                        name="affiliateDescription"
                        errorText={get(errors, 'affiliateDescription.message')}
                      />
                    </Grid>

                    <Grid item>
                      <FormControlLabel
                        className={styles.switch}
                        control={
                          <Switch
                            data-testid="unlockAfterTrustpilotReview"
                            control={control}
                            disabled={isLoadingMutation}
                            name="unlockAfterTrustpilotReview"
                            color="primary"
                          />
                        }
                        label="Require Trustpilot Review"
                      />
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={clsx(
                        !unlockAfterTrustpilotReview && styles.hidden,
                      )}
                    >
                      <Input
                        data-testid="trustpilotReviewCooldownDays"
                        control={control}
                        className={styles.input}
                        label="Truspilot Review Cooldown Days"
                        name="trustpilotReviewCooldownDays"
                        type="number"
                        errorText={get(
                          errors,
                          'trustpilotReviewCooldownDays.message',
                        )}
                      />
                    </Grid>

                    <Grid item className={clsx(isPublic && styles.hidden)}>
                      <Input
                        data-testid="upgradeUrl"
                        control={control}
                        className={styles.input}
                        disabled={isLoadingMutation}
                        label="Early Upgrade URL"
                        name="upgradeUrl"
                        errorText={get(errors, 'upgradeUrl.message')}
                      />
                    </Grid>
                  </>
                ) : null}

                <Grid item>
                  <Input
                    data-testid="communityUrl"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Community URL"
                    name="communityUrl"
                    errorText={get(errors, 'communityUrl.message')}
                  />
                </Grid>

                <Grid item xs={8} sm={4} md={3}>
                  <Select
                    data-testid="type"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Type"
                    name="type"
                    options={types}
                    errorText={get(errors, 'type.message')}
                  />
                </Grid>

                <Grid item xs={10} sm={6} md={4}>
                  <Input
                    data-testid="color"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Hex Color Code"
                    name="color"
                    errorText={get(errors, 'color.message')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">#</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment>
                          <div
                            className={styles.colorPreview}
                            style={{
                              backgroundColor: '#' + trimStart(color, '#'),
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item>
                  <FormControlLabel
                    className={styles.switch}
                    control={
                      <Switch
                        data-testid="setupFailedPayments"
                        control={control}
                        disabled={isLoadingMutation}
                        name="setupFailedPayments"
                        color="primary"
                      />
                    }
                    label="Capture Failed Payments"
                  />
                </Grid>

                <Grid
                  item
                  container
                  spacing={2}
                  direction="row"
                  className={clsx(!setupFailedPayments && styles.hidden)}
                >
                  <Grid item xs={10} sm={6} md={4}>
                    <Select
                      data-testid="successfulPaymentCollectionListId"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Successful Payments List"
                      name="successfulPaymentCollectionListId"
                      options={activeCampaignLists.map((l) => ({
                        value: l.id,
                        label: l.name,
                      }))}
                      errorText={get(
                        errors,
                        'successfulPaymentCollectionListId.message',
                      )}
                    />
                  </Grid>
                  <Grid item xs={10} sm={6} md={4}>
                    <Select
                      data-testid="failedPaymentListId"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Failed Payments List"
                      name="failedPaymentListId"
                      options={activeCampaignLists.map((l) => ({
                        value: l.id,
                        label: l.name,
                      }))}
                      errorText={get(errors, 'failedPaymentListId.message')}
                    />
                  </Grid>
                  <Grid item xs={10} sm={6} md={4}>
                    <Input
                      data-testid="failedPaymentFieldPrefix"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Custom Field Prefix"
                      name="failedPaymentFieldPrefix"
                      errorText={get(
                        errors,
                        'failedPaymentFieldPrefix.message',
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={10} sm={6} md={4}>
                  <FormControlLabel
                    className={styles.switch}
                    control={
                      <Switch
                        data-testid="isMentorshipEnabled"
                        control={control}
                        disabled={isLoadingMutation}
                        name="isMentorshipEnabled"
                        color="primary"
                      />
                    }
                    label="Enable Mentorship"
                  />
                </Grid>

                <Grid item xs={10} sm={6} md={4}>
                  <FormControlLabel
                    className={styles.switch}
                    control={
                      <Switch
                        data-testid="hasBoxset"
                        control={control}
                        disabled={isLoadingMutation}
                        name="hasBoxset"
                        color="primary"
                      />
                    }
                    label="Collect Address For Boxset"
                  />
                </Grid>

                {courseId ? (
                  <>
                    <Grid item container direction="column">
                      <Typography variant="body1" gutterBottom>
                        Background
                      </Typography>
                      <Grid item>
                        <UploadFileGroup
                          className={styles.upload}
                          preview={course.background}
                          onUpload={showUploadCourseBackgroundDialog}
                          disabled={isLoadingMutation}
                          uploadButtonLabel={
                            course.background
                              ? 'Replace Background'
                              : 'Add Background'
                          }
                          uploadButtonIcon={<AddIcon />}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container direction="column">
                      <Typography variant="body1" gutterBottom>
                        Graphic
                      </Typography>
                      <Grid item>
                        <UploadFileGroup
                          className={styles.upload}
                          preview={course.graphic}
                          onUpload={showUploadCourseGraphicDialog}
                          disabled={isLoadingMutation}
                          uploadButtonLabel={
                            course.graphic ? 'Replace Graphic' : 'Add Graphic'
                          }
                          uploadButtonIcon={<AddIcon />}
                        />
                      </Grid>
                    </Grid>

                    <Grid item>
                      <FormControlLabel
                        className={styles.switch}
                        control={
                          <Switch
                            data-testid="isReleased"
                            control={control}
                            disabled={isLoadingMutation}
                            name="isReleased"
                            color="primary"
                          />
                        }
                        label="Released"
                      />
                    </Grid>

                    <Grid item className={clsx(isReleased && styles.hidden)}>
                      <FormControlLabel
                        className={styles.switch}
                        control={
                          <Switch
                            data-testid="isVisible"
                            control={control}
                            disabled={isLoadingMutation}
                            name="isVisible"
                            color="primary"
                          />
                        }
                        label="Visible for users"
                      />
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={10} sm={6} md={4}>
                  <Button
                    className={styles.button}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={isLoadingMutation || (courseId && !edited)}
                  >
                    Save Course
                  </Button>
                </Grid>
              </Grid>
            </form>
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminCreateOrUpdateCourse;
