import { useSelector, useDispatch } from 'react-redux';

import {
  selectCoursesWithSubscriptionStatus,
  selectCoursesRequest,
  selectMeRequest,
  selectSubscriptionStepsRequest,
  selectUpgradeCourseTrialRequest,
} from 'Redux/selectors';
import {
  fetchCourses,
  fetchSubscriptionSteps,
  upgradeCourseTrial,
} from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import CourseCard from 'Components/shared/course-card';

import {
  makeStyles,
  useMediaQuery,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(9),
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(7),
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    maxWidth: 768,
    marginBottom: theme.spacing(6),
  },
  item: {
    margin: theme.spacing(0, 0, 4),
  },
  progress: {
    color: 'white',
  },
}));

const FacebookGroupsPage = () => {
  const styles = useStyles();
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const dispatch = useDispatch();

  const { isLoading, error } = useSelector(
    selectCoursesRequest,
    selectMeRequest,
    selectSubscriptionStepsRequest,
  );

  const { isLoading: isUpgradingEarly } = useSelector(
    selectUpgradeCourseTrialRequest,
  );

  const courses = useSelector(selectCoursesWithSubscriptionStatus);

  const handleUpgradeEarly = useMemoizedCallback(
    (courseId) => (ev) => {
      ev.stopPropagation();
      ev.preventDefault();

      if (isUpgradingEarly) {
        return;
      }
      dispatch(upgradeCourseTrial({ courseId }, { isPromise: true }))
        .then(({ data: upgrade }) => {
          window.location.href = upgrade.hostedInvoiceUrl;
        })
        .catch(console.error);
    },
    [dispatch, isUpgradingEarly],
  );

  const mappedCourses =
    courses &&
    courses
      .filter((course) => course.communityUrl && course.subscriptionStatus)
      .map((course) => {
        const { subscriptionStatus } = course;

        return {
          ...course,
          locked: !subscriptionStatus.mentorshipActive,
          subtitle: { COURSE: 'Course', BLUEPRINT: 'Blueprint' }[course.type],
          ButtonProps: !subscriptionStatus.enrolled
            ? {
                label: 'Enroll',
                component: 'a',
                href: course.salesPageUrl,
              }
            : subscriptionStatus.mentorshipActive
            ? {
                label: 'Join Now',
                component: 'a',
                href: course.communityUrl,
              }
            : subscriptionStatus.mentorshipPurchased
            ? {
                component: 'button',
                onClick: handleUpgradeEarly(course.id),
                label: isUpgradingEarly ? (
                  <CircularProgress size={24} className={styles.progress} />
                ) : (
                  'Upgrade Early'
                ),
              }
            : !subscriptionStatus.isTrial
            ? {
                component: 'a',
                label: 'Contact Us',
                href: 'mailto:support@ecomfreedom.com',
              }
            : {
                label: 'Upgrade Early',
                component: 'a',
                href: course.upgradeUrl,
              },
        };
      });

  useScopedDispatchEffect(() => {
    dispatch(fetchSubscriptionSteps());
    dispatch(fetchCourses());
  }, [dispatch]);

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <PageContainer className={styles.root}>
            <Typography variant="h3" align="center">
              Join Our Community
            </Typography>
            <Typography variant="body1" align="center" className={styles.title}>
              Share your success, ask questions, and create relationships that
              will change your life.
            </Typography>
            <div className={styles.group}>
              <Grid
                container
                spacing={isLgUp ? 6 : isMdUp ? 3 : 2}
                justify="center"
              >
                {mappedCourses.map((product, index) => (
                  <Grid key={index} className={styles.item} item xs={12} sm={6}>
                    <CourseCard
                      elevation={8}
                      {...product}
                      ButtonProps={{
                        ...product.ButtonProps,
                        'data-testid': 'courseLink',
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </PageContainer>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default FacebookGroupsPage;
