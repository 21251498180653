import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useEffect, useMemo } from 'react';
// import { trim } from 'lodash';

import languages from 'Util/languages';
import { selectMe, selectMeRequest } from 'Redux/selectors';
import { enqueueSnackbar, closeSnackbar } from 'Redux/actions';
// import FacebookIcon from 'Icons/facebook';
import UploadProfilePictureDialog from 'Dialogs/upload-profile-picture-dialog';
import UpdateMeFieldDialog from 'Dialogs/update-me-field-dialog';
import UpdateLocationDialog from 'Dialogs/update-location-dialog';
import ConfirmDeleteProfilePictureDialog from 'Dialogs/confirm-delete-profile-picture-dialog';
import ChangeGoalDialog from 'Dialogs/change-goal-dialog';
import UserLocation from 'Components/shared/user-location';
import UserAvatar from 'Components/shared/user-avatar';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import OptionListItem from 'Components/shared/option-list-item';
import OptionList from 'Components/shared/option-list';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';

import {
  EmojiObjects as EmojiObjectsIcon,
  Edit as EditIcon,
  Add as AddIcon,
  DeleteOutline as DeleteOutlineIcon,
  // Instagram as InstagramIcon,
  // Twitter as TwitterIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';

// const { REACT_APP_CONNECT_URL = '' } = process.env;

const options = languages.map((c) => ({ value: c.code, label: c.name }));

const timePeriodString = () => {
  const hours = new Date().getHours();

  if (hours < 12) {
    return 'Morning';
  } else if (hours < 18) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3.5),
  },
  info: {
    width: '100%',
    padding: theme.spacing(3.5, 3, 4),
  },
  greeting: {
    marginBottom: theme.spacing(1.5),
  },
  body: {
    marginBottom: theme.spacing(4),
  },
  tip: {
    display: 'flex',
    width: '100%',
    minHeight: 24,
    alignItems: 'center',
    color: theme.palette.secondary.dark,
  },
  tipIcon: {
    marginRight: theme.spacing(1),
  },
  optionList: {
    marginBottom: theme.spacing(4),
  },
  avatarWithButton: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  deleteAvatarButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  textValue: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    display: 'flex',
    alignItems: 'center',
  },
  facebookIcon: {
    color: '#3B5998',
    marginRight: 6,
  },
  instagramIcon: {
    color: '#BC2A8D',
    marginRight: 6,
  },
  twitterIcon: {
    color: '#1DA1F2',
    marginRight: 6,
  },
  iconInactive: {
    marginRight: 6,
  },
}));

const ProfilePage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [params] = useSearchParams();
  const connectError = params.get('connectError');
  const connectSuccess = params.get('connectSuccess');

  const me = useSelector(selectMe);
  const { isLoading } = useSelector(selectMeRequest);

  const spokenLanguages = useMemo(
    () =>
      me && me.spokenLanguages
        ? languages
            .filter((lang) => me.spokenLanguages.find((l) => l === lang.code))
            .map((lang) => lang.name)
        : ['English'],
    [me],
  );
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const [showChangeGoalModal, hideChangeGoalModal] = useModal(() => (
    <ChangeGoalDialog onClose={hideChangeGoalModal} />
  ));

  const [showUpdateBioModal, hideUpdateBioModal] = useModal(
    () => (
      <UpdateMeFieldDialog
        variant="input"
        name="bio"
        title="Your Bio"
        onClose={hideUpdateBioModal}
      />
    ),
    [me && me.bio],
  );

  const [showUpdateSpokenLanguagesModal, hideUpdateSpokenLanguagesModal] =
    useModal(() => (
      <UpdateMeFieldDialog
        variant="select"
        name="spokenLanguages"
        title="Spoken Languages"
        options={options}
        multiple
        onClose={hideUpdateSpokenLanguagesModal}
      />
    ));

  const [showUpdateLocationModal, hideUpdateLocationModal] = useModal(() => (
    <UpdateLocationDialog onClose={hideUpdateLocationModal} />
  ));

  const [showUploadProfilePictureModal, hideUploadProfilePictureModal] =
    useModal(() => (
      <UploadProfilePictureDialog onClose={hideUploadProfilePictureModal} />
    ));
  const [showDeleteProfilePictureModal, hideDeleteProfilePictureModal] =
    useModal(() => (
      <ConfirmDeleteProfilePictureDialog
        onClose={hideDeleteProfilePictureModal}
      />
    ));

  useEffect(() => {
    if (connectError) {
      dispatch(
        enqueueSnackbar('CONNECT_STATUS', {
          message:
            'Sorry about that! We failed to connect your account, try again later',
          variant: 'error',
        }),
      );
    } else if (connectSuccess) {
      dispatch(
        enqueueSnackbar('CONNECT_STATUS', {
          message: 'Successfully connected your account!',
          variant: 'success',
        }),
      );
    }
    return () => dispatch(closeSnackbar('CONNECT_STATUS'));
  }, [connectError, connectSuccess, dispatch]);

  // const socialFields = ['facebook', 'instagram', 'twitter']; // disabled temporary because facebook
  // const socialFields = ['twitter'];

  // const socialIcons = {
  //   facebook: FacebookIcon,
  //   instagram: InstagramIcon,
  //   twitter: TwitterIcon,
  // };

  return (
    <PageLoader loading={isLoading}>
      {() =>
        me && (
          <PageLayout
            headerItems={<HeaderItems />}
            drawerItems={<DrawerItems />}
          >
            <PageContainer>
              <Typography variant="h4" align="center" className={styles.title}>
                My Profile
              </Typography>
              <div className={styles.info}>
                <Typography variant="h6" className={styles.greeting}>
                  Good {timePeriodString()}, {me.firstName}!
                </Typography>
                <Typography variant="body1" className={styles.body}>
                  Let’s customize your profile so that people know who you are.
                  Don’t forget to reach out to other students from our
                  community.
                </Typography>
                <div className={styles.tip}>
                  <EmojiObjectsIcon className={styles.tipIcon} />
                  <span>
                    <strong>Tip</strong>: Introduce yourself to one student
                    after every mentorship call that you attend.
                  </span>
                </div>
              </div>
              <OptionList className={styles.optionList} title="Personal Info">
                <OptionListItem
                  data-testid="image"
                  name="image"
                  title="Profile Picture"
                  value={
                    me.image ? (
                      <div className={styles.avatarWithButton}>
                        <UserAvatar user={me} size={60} />
                        {isSmUp ? (
                          <Button
                            data-testid="deleteImage"
                            className={styles.deleteAvatarButton}
                            startIcon={<DeleteOutlineIcon />}
                            onClick={showDeleteProfilePictureModal}
                          >
                            Delete
                          </Button>
                        ) : (
                          <IconButton
                            data-testid="deleteImage"
                            onClick={showDeleteProfilePictureModal}
                          >
                            <DeleteOutlineIcon fontSize="small" />
                          </IconButton>
                        )}
                      </div>
                    ) : (
                      <Typography
                        variant="body2"
                        className={styles.textValue}
                        color="textSecondary"
                      >
                        Add an image.
                      </Typography>
                    )
                  }
                  actionIcon={
                    me.image ? (
                      <EditIcon fontSize="small" />
                    ) : (
                      <AddIcon fontSize="small" />
                    )
                  }
                  onActionClick={showUploadProfilePictureModal}
                  divider
                />
                <OptionListItem
                  data-testid="bio"
                  title="Bio"
                  value={
                    me.bio ? (
                      <Typography variant="body2" className={styles.textValue}>
                        {me.bio}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        className={styles.textValue}
                        color="textSecondary"
                      >
                        Add bio.
                      </Typography>
                    )
                  }
                  actionIcon={
                    me.bio ? (
                      <EditIcon fontSize="small" />
                    ) : (
                      <AddIcon fontSize="small" />
                    )
                  }
                  onActionClick={showUpdateBioModal}
                  divider
                />
                <OptionListItem
                  data-testid="goal"
                  title="My Long-Term Goal"
                  value={
                    me.goal ? (
                      <Typography variant="body2" className={styles.textValue}>
                        {me.goal}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        className={styles.textValue}
                        color="textSecondary"
                      >
                        Add long-term goal.
                      </Typography>
                    )
                  }
                  actionIcon={
                    me.goal ? (
                      <EditIcon fontSize="small" />
                    ) : (
                      <AddIcon fontSize="small" />
                    )
                  }
                  onActionClick={showChangeGoalModal}
                  divider
                />
                <OptionListItem
                  data-testid="location"
                  title="Location"
                  value={
                    <UserLocation
                      variant="body2"
                      className={styles.textValue}
                      user={me}
                    />
                  }
                  actionIcon={<EditIcon fontSize="small" />}
                  onActionClick={showUpdateLocationModal}
                  divider
                />
                <OptionListItem
                  data-testid="spokenLanguages"
                  title="Spoken Languages"
                  value={
                    <Typography variant="body2" className={styles.textValue}>
                      {spokenLanguages.join(', ')}
                    </Typography>
                  }
                  actionIcon={<EditIcon fontSize="small" />}
                  onActionClick={showUpdateSpokenLanguagesModal}
                />
              </OptionList>
              {/* <OptionList className={styles.optionList} title="Contact Info">
                {socialFields.map((name, index) => {
                  const value = me[name];
                  const Icon = socialIcons[name];

                  const connectUrl = `${trim(
                    REACT_APP_CONNECT_URL,
                    '/',
                  )}/${name}?userId=${me.id}&continueTo=${
                    window.location.href
                  }`;

                  return (
                    <OptionListItem
                      key={name}
                      name={name}
                      title={name}
                      value={
                        value ? (
                          <Typography
                            variant="body2"
                            className={styles.textValue}
                          >
                            <Icon className={styles[name + 'Icon']} />
                            {value.displayName || value.username}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            className={styles.textValue}
                            color="textSecondary"
                          >
                            <Icon className={styles.iconInactive} />
                            Add {name}.
                          </Typography>
                        )
                      }
                      ActionButtonProps={{
                        component: 'a',
                        href: connectUrl,
                      }}
                      actionIcon={
                        value ? (
                          <EditIcon fontSize="small" />
                        ) : (
                          <AddIcon fontSize="small" />
                        )
                      }
                      divider={socialFields.length - 1 !== index}
                    />
                  );
                })}
              </OptionList> */}
            </PageContainer>
          </PageLayout>
        )
      }
    </PageLoader>
  );
};

export default ProfilePage;
