import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';

import countries from 'Util/countries';
import { selectMe, selectUpdateMeRequest } from 'Redux/selectors';
import { updateMe } from 'Redux/actions';
import Select from 'Components/shared/select';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button } from '@material-ui/core';

const options = countries.map((c) => ({ value: c.code, label: c.name }));

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  select: {
    width: '100%',
  },
}));

const UpdateLocationDialog = (props) => {
  const { onClose } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const { isLoading } = useSelector(selectUpdateMeRequest);

  const { control, handleSubmit } = useForm({
    defaultValues: me,
  });

  const handleUpdate = useCallback(
    (data) => {
      dispatch(updateMe(data, { isPromise: true })).then(onClose);
    },
    [dispatch, onClose],
  );

  return (
    <Dialog
      title="Change Location"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleUpdate)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">Change</Button>}
    >
      <Input
        data-testid="city"
        autoFocus
        className={styles.input}
        control={control}
        label="City"
        name="city"
      />
      <Select
        data-testid="country"
        className={styles.select}
        control={control}
        label="Country"
        name="country"
        options={options}
      />
    </Dialog>
  );
};

UpdateLocationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UpdateLocationDialog;
