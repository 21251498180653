import { useDispatch } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useCallback, useRef } from 'react';

import { closeSnackbar, enqueueSnackbar } from 'Redux/actions';
import RecaptchaDialog from 'Dialogs/recaptcha-dialog';

const useRecaptcha = () => {
  const promiseRef = useRef(null);
  const dispatch = useDispatch();

  const handleSuccess = useCallback(
    (response) => {
      promiseRef.current.resolve(response);
      promiseRef.current = null;
      dispatch(closeSnackbar('NO_ROBOTS'));
    },
    [dispatch],
  );

  const handleFailure = useCallback(() => {
    promiseRef.current.resolve(null);
    promiseRef.current = null;
    dispatch(
      enqueueSnackbar('NO_ROBOTS', {
        variant: 'error',
        message:
          "Couldn't figure out if you are a robot or not; please try again",
      }),
    );
  }, [dispatch]);

  const [showModal, hideModal] = useModal(
    () => (
      <RecaptchaDialog
        onSuccess={handleSuccess}
        onFailure={handleFailure}
        onClose={hideModal}
      />
    ),
    [handleSuccess, handleFailure],
  );

  return useCallback(() => {
    let resolve;
    promiseRef.current = new Promise((resolve_) => {
      resolve = resolve_;
    });
    promiseRef.current.resolve = resolve;
    showModal();
    dispatch(closeSnackbar('NO_ROBOTS'));
    return promiseRef.current;
  }, [dispatch, showModal]);
};

export default useRecaptcha;
