import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectDeleteProfilePictureRequest } from 'Redux/selectors';
import { deleteProfilePicture } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmDeleteProfilePictureDialog = (props) => {
  const { userId, onClose } = props;
  const { isLoading } = useSelector(selectDeleteProfilePictureRequest);
  const dispatch = useDispatch();

  const handleDelete = useCallback(() => {
    dispatch(deleteProfilePicture({ userId }, { isPromise: true }))
      .then(onClose)
      .catch(noop);
  }, [dispatch, onClose, userId]);

  return (
    <Dialog
      title="Delete Profile Picture"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleDelete}>
          Delete
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to delete {userId ? "user's" : 'your'} profile
        picture?
      </Typography>
    </Dialog>
  );
};

ConfirmDeleteProfilePictureDialog.propTypes = {
  userId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmDeleteProfilePictureDialog;
