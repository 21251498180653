import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ModalProvider } from 'react-modal-hook';

import PropTypes from 'prop-types';

import SnackbarProvider from 'Components/shared/snackbar-provider';
import CourseThemeProvider from 'Components/shared/course-theme-provider';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';

import theme from './theme';
import Router from './router';

const App = ({ store, history }) => (
  <ThemeProvider theme={theme}>
    <BrowserRouter history={history}>
      <ReduxProvider store={store}>
        <CourseThemeProvider>
          <ModalProvider>
            <SnackbarProvider maxSnack={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <CssBaseline />
                <Router />
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </ModalProvider>
        </CourseThemeProvider>
      </ReduxProvider>
    </BrowserRouter>
  </ThemeProvider>
);

App.propTypes = {
  store: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
};

export default App;
