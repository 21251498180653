import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderAdminItems from 'Components/shared/header-admin-items';
import DrawerAdminItems from 'Components/shared/drawer-admin-items';

import { makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'calc(100% - 280px)',
      marginLeft: 280,
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  drawer: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    position: 'fixed',
    marginTop: 1, // divider offset
    width: 280,
  },
}));

const UserDetailsLayout = (props) => {
  const { children } = props;
  const styles = useStyles();

  const isXlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  return (
    <PageLayout
      headerItems={<HeaderAdminItems />}
      drawerItems={<DrawerAdminItems />}
      drawerBreakpoint="xl"
    >
      <div className={styles.root}>
        {isXlUp && <DrawerAdminItems className={styles.drawer} />}

        <PageContainer
          className={styles.container}
          maxWidth={1200}
          sidebarWidth={280}
          sidebarToggleBreakpoint="xl"
        >
          {children}
        </PageContainer>
      </div>
    </PageLayout>
  );
};
export default UserDetailsLayout;
