import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'create-payment-link';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CREATE_PAYMENT_LINK_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const createPaymentLink = Exec.requestActionCreator(MODULE);
export const createPaymentLinkLoading = Exec.loadingActionCreator(MODULE);
export const createPaymentLinkSuccess = Exec.successActionCreator(MODULE);
export const createPaymentLinkError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCreatePaymentLinkRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doCreatePaymentLink = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(CREATE_PAYMENT_LINK_REQUEST));
    yield put(createPaymentLinkLoading());
    const { data } = yield call(Api.createPaymentLink, payload);
    yield put(createPaymentLinkSuccess({ data }, meta));
  } catch (err) {
    yield put(createPaymentLinkError(err, meta));
    yield put(
      enqueueSnackbar(CREATE_PAYMENT_LINK_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCreatePaymentLink);
