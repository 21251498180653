import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { filter, isString } from 'lodash';
import {
  format,
  parseISO,
  isAfter,
  isWithinInterval,
  addSeconds,
  subMinutes,
} from 'date-fns';
import clsx from 'clsx';

import downloadDataAsFile from 'Util/download-data-as-file';
import createIscEvent from 'Util/create-ics-event';
import {
  selectCoursesWithSubscriptionStatus,
  selectUpgradeCourseTrialRequest,
} from 'Redux/selectors';
import { fetchEventVideo, upgradeCourseTrial } from 'Redux/actions';
import usePeriodRerender from 'Hooks/use-period-rerender';
import useMountedState from 'Hooks/use-mounted-state';
import AttendeeListDialog from 'Dialogs/attendee-list-dialog';
import UserAvatar from 'Components/shared/user-avatar';
import UnderlineButton from 'Components/shared/underline-button';
import RichText from 'Components/shared/rich-text';

import {
  Add as AddIcon,
  Remove as RemoveIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  VideoCall as VideoCallIcon,
  Event as EventIcon,
  PeopleOutline as PeopleOutlineIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Typography,
  List,
  Grid,
  Button,
  LinearProgress,
} from '@material-ui/core';

import CardListItem from './card-list-item';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 0, 1.25),
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3.5, 0, 1.25),
    },
    '&:hover': {
      boxShadow: theme.shadows[4],
    },
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  title: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  list: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
    },
  },
  mentor: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(2.25, 0),
  },
  topics: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  greenButton: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

const Card = (props) => {
  const {
    className,
    id,
    title,
    variantTitle,
    startsAt,
    duration,
    mentors,
    topics,
    previewMode,
    hasAccess,
    courseIds,
    ...rest
  } = props;

  const isMounted = useMountedState();

  const courses = useSelector(selectCoursesWithSubscriptionStatus);
  const { isLoading: isUpgradingEarly } = useSelector(
    selectUpgradeCourseTrialRequest,
  );

  const firstRelevantCourse = filter(courses, (course) =>
    courseIds.includes(course.id),
  ).find((course) => !course.subscriptionStatus.mentorshipActive);

  const styles = useStyles();
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [showMoreInfo, setShowMoreInfo] = useState(previewMode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [showAttendeeList, hideAttendeeList] = useModal(
    () => <AttendeeListDialog eventId={id} onClose={hideAttendeeList} />,
    [id],
  );

  const handleToggleShowMoreInfo = useCallback(
    () => setShowMoreInfo((prev) => !prev),
    [],
  );

  const startsAtAsDate = isString(startsAt) ? parseISO(startsAt) : startsAt;
  const endsAt = addSeconds(startsAtAsDate, duration);
  const upcoming = isAfter(startsAtAsDate, new Date());
  const active =
    isAfter(endsAt, startsAtAsDate) &&
    isWithinInterval(new Date(), {
      start: subMinutes(startsAtAsDate, 5),
      end: endsAt,
    });
  const completed = !upcoming && !active;
  const canShowInfo = (completed || previewMode) && topics;

  // rerender periodically if the card contains active or upcoming event
  usePeriodRerender(60 * 1000, !completed);

  const handleAddToCalendar = useCallback(() => {
    // This may not work with DST!
    const event = createIscEvent(
      title,
      variantTitle,
      window.location.href,
      startsAtAsDate,
      endsAt,
    );
    const blob = new Blob([event], { type: 'text/calendar' });
    downloadDataAsFile(blob, 'invite.ics');
  }, [endsAt, startsAtAsDate, title, variantTitle]);

  const handleJoinCall = useCallback(() => {
    setLoading(true);
    dispatch(fetchEventVideo({ eventId: id }, { isPromise: true }))
      .then(({ data: video }) => {
        if (isMounted()) {
          if (video.isOwned) {
            navigate(`/mentorship-calls/watch/${id}`);
          } else {
            window.open(video.url, '_blank');
          }
        }
      })
      .catch(console.error)
      .finally(() => {
        if (isMounted()) {
          setLoading(false);
        }
      });
  }, [id, dispatch, isMounted, navigate]);

  const handleUpgradeEarly = useCallback(() => {
    if (isUpgradingEarly) {
      return;
    }
    dispatch(
      upgradeCourseTrial(
        { courseId: firstRelevantCourse.id },
        { isPromise: true },
      ),
    )
      .then(({ data: upgrade }) => {
        window.location.href = upgrade.hostedInvoiceUrl;
      })
      .catch(console.error);
  }, [dispatch, firstRelevantCourse, isUpgradingEarly]);

  const actionButtonPropsWithAccess = completed
    ? { component: Link, to: `/mentorship-calls/watch/${id}` }
    : active
    ? { onClick: handleJoinCall }
    : { onClick: handleAddToCalendar };

  const actionButtonLabelWithAccess = active
    ? 'Join Now'
    : upcoming
    ? 'Add To Calendar'
    : 'Watch Now';

  const actionButtonPropsForbidden = firstRelevantCourse
    ? !firstRelevantCourse.subscriptionStatus.enrolled
      ? { component: 'a', href: firstRelevantCourse.salesPageUrl }
      : firstRelevantCourse.subscriptionStatus.mentorshipPurchased
      ? { onClick: handleUpgradeEarly, disabled: isUpgradingEarly }
      : firstRelevantCourse?.subscriptionStatus.isTrial
      ? { component: 'a', href: firstRelevantCourse.upgradeUrl }
      : { component: 'a', href: 'mailto:support@ecomfreedom.com' }
    : null;

  const actionButtonLabelForbidden = firstRelevantCourse
    ? !firstRelevantCourse.subscriptionStatus.enrolled
      ? 'Enroll Now'
      : firstRelevantCourse.subscriptionStatus.mentorshipPurchased
      ? 'Upgrade Early'
      : firstRelevantCourse?.subscriptionStatus.isTrial
      ? 'Upgrade Early'
      : 'Contact Us'
    : null;

  const actionButtonProps =
    hasAccess || !firstRelevantCourse
      ? actionButtonPropsWithAccess
      : {
          startIcon: null,
          className: styles.greenButton,
          ...actionButtonPropsForbidden,
        };

  const actionButtonLabel =
    hasAccess || !firstRelevantCourse
      ? actionButtonLabelWithAccess
      : actionButtonLabelForbidden;

  return (
    <div className={clsx(styles.root, className)} {...rest}>
      {loading && (
        <LinearProgress
          aria-busy
          color="primary"
          className={styles.loader}
          variant="indeterminate"
          data-testid="progress"
        />
      )}
      {title && (
        <Typography variant="h5" className={styles.title}>
          {title}
        </Typography>
      )}
      <List disablePadding className={styles.list}>
        <CardListItem
          divider
          title="Date"
          value={
            <Typography variant="body1">
              {format(startsAtAsDate, 'PPP')}
            </Typography>
          }
        />
        <CardListItem
          divider
          title="Time"
          value={
            <Typography variant="body1">
              {format(startsAtAsDate, 'p')}
            </Typography>
          }
        />
        <CardListItem
          divider
          title="Mentor"
          value={mentors.map((mentor) => (
            <div key={mentor.id} className={styles.mentor}>
              <UserAvatar size={38} className={styles.avatar} user={mentor} />
              <Typography variant="body1">
                {mentor.firstName} {mentor.lastName}
              </Typography>
            </div>
          ))}
        />
        {!previewMode && (
          <CardListItem
            divider={showMoreInfo}
            title="Type"
            value={<Typography variant="body1">{variantTitle}</Typography>}
          />
        )}
        {canShowInfo && (
          <CardListItem
            collapsable
            in={showMoreInfo}
            title="Topics"
            value={
              <>
                <Typography component="div" variant="body1">
                  <RichText className={styles.topics}>{topics.html}</RichText>
                  <UnderlineButton
                    startIcon={<PeopleOutlineIcon />}
                    onClick={showAttendeeList}
                    data-testid="seeAttendees"
                  >
                    See Attendees
                  </UnderlineButton>
                </Typography>
              </>
            }
          />
        )}
      </List>
      {!previewMode && (
        <Grid
          container
          spacing={isSmUp ? 3 : 1}
          justify="center"
          className={styles.actions}
        >
          {canShowInfo && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleToggleShowMoreInfo}
                startIcon={showMoreInfo ? <RemoveIcon /> : <AddIcon />}
                data-testid="toggleMoreInfo"
              >
                {showMoreInfo ? 'Less Info' : 'More Info'}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={active && loading}
              data-testid="actionButton"
              startIcon={
                active ? (
                  <VideoCallIcon />
                ) : upcoming ? (
                  <EventIcon />
                ) : (
                  <PlayCircleOutlineIcon />
                )
              }
              {...actionButtonProps}
            >
              {actionButtonLabel}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  variantTitle: PropTypes.string.isRequired,
  startsAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  mentors: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        isResizable: PropTypes.bool,
      }),
    }),
  ).isRequired,
  topics: PropTypes.shape({
    html: PropTypes.string,
  }),
  duration: PropTypes.number.isRequired,
  previewMode: PropTypes.bool,
  hasAccess: PropTypes.bool,
  courseIds: PropTypes.arrayOf(PropTypes.string),
};

Card.defaultProps = {
  previewMode: false,
};

export default Card;
