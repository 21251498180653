import { Link } from 'react-router-dom';
import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  makeStyles,
  withStyles,
  ButtonBase,
  LinearProgress,
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
} from '@material-ui/core';

import LogoLarge from './logo-large';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  toolbar: {
    position: 'relative',
    minHeight: 80,
  },
  logo: {
    height: 48,
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      display: 'initial',
    },
  },
  grow: {
    display: 'flex',
    flexGrow: 1,
  },
  title: {
    marginLeft: theme.spacing(8),
    marginRight: 'auto',
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 80,
  },
}));

const AppBar = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.common.black,
  },
}))(MuiAppBar);

const Toolbar = withStyles((theme) => ({
  gutters: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
}))(MuiToolbar);

const Header = (props) => {
  const { className, startAction, endAction, showLogo, showLoader } = props;

  const styles = useStyles();

  return (
    <AppBar
      position="sticky"
      className={clsx(styles.root, className)}
      classes={{ colorPrimary: styles.appBar }}
      elevation={0}
    >
      <Toolbar className={styles.toolbar}>
        {startAction}
        {showLogo && (
          <ButtonBase className={styles.logo} component={Link} to="/">
            <LogoLarge aria-hidden />
          </ButtonBase>
        )}
        {endAction}
        {showLoader && <LinearProgress aria-busy className={styles.loader} />}
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  startAction: PropTypes.node,
  endAction: PropTypes.node,
  showLogo: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  showLogo: true,
  showLoader: false,
};

Header.whyDidYouRender = true;

export default memo(Header);
