import { put, call, select } from 'redux-saga/effects';
import { filter } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { fetchUserSuccess, selectUser } from './user';
import { enqueueSnackbar } from './snackbar';
import { fetchMeSuccess, selectMe } from './me';

const MODULE = 'refund-transactions';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const REFUND_TRANSACTIONS_REQUEST = Exec.requestConstantCreator(MODULE);
export const REFUND_TRANSACTIONS_SINGLE_SUCCESS =
  'refund-transactions-single-success';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const refundTransactions = Exec.requestActionCreator(MODULE);
export const refundTransactionsLoading = Exec.loadingActionCreator(MODULE);
export const refundTransactionsSuccess = Exec.successActionCreator(MODULE);
export const refundTransactionsError = Exec.errorActionCreator(MODULE);
export const refundTransactionsSingleSuccess = (payload) => {
  return {
    type: REFUND_TRANSACTIONS_SINGLE_SUCCESS,
    payload,
  };
};

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectRefundTransactionsRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doRefundTransactions = function* ({ payload, meta }) {
  const me = yield select(selectMe);
  const user = yield select(selectUser);

  const { transactionIds, userId, reason } = payload;
  try {
    yield put(refundTransactionsLoading());

    for (const transactionId of transactionIds) {
      const { data } = yield call(
        Api.refundTransaction,
        userId,
        transactionId,
        reason,
      );
      yield put(refundTransactionsSingleSuccess({ data }));

      if (data.courseId && me && userId === me.id) {
        yield put(
          fetchMeSuccess({
            data: {
              ...me,
              enrolledCourseIds: filter(
                me.enrolledCourseIds,
                (id) => id !== data.courseId,
              ),
            },
          }),
        );
      }
      if (data.courseId && user && userId === user.id) {
        yield put(
          fetchUserSuccess({
            data: {
              ...user,
              enrolledCourseIds: filter(
                user.enrolledCourseIds,
                (id) => id !== data.courseId,
              ),
            },
          }),
        );
      }
    }

    yield put(refundTransactionsSuccess(null, meta));
  } catch (err) {
    yield put(refundTransactionsError(err, meta));
    yield put(
      enqueueSnackbar(REFUND_TRANSACTIONS_REQUEST, {
        message:
          err.code === 'ERROR_CODE_PARTIAL_FAILURE'
            ? 'The refund could not be completed. Please visit stripe dashboard and issue refunds manually'
            : err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doRefundTransactions);
