import { Link } from 'react-router-dom';

import PageContainer from 'Components/shared/page-container';
import Divider from 'Components/shared/divider';

import { makeStyles, Typography, Button } from '@material-ui/core';

import Card from './card';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.grey[50],
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    marginTop: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(7),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  cards: {
    display: 'grid',
    gap: theme.spacing(6) + 'px',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  card: {
    maxWidth: 452,
    boxShadow: theme.shadows[2],
  },
}));

const Cards = () => {
  const styles = useStyles();

  return (
    <section className={styles.root}>
      <PageContainer className={styles.container}>
        <Typography variant="h4" align="center">
          Still Need Help?
        </Typography>
        <Divider className={styles.divider} />
        <div className={styles.cards}>
          <Card
            className={styles.card}
            title="Contact Support"
            body="Our support team is here to help with your problems. Feel free to reach out and we’ll get back to you within 48 hours."
            button={
              <Button
                size="large"
                component="a"
                href="mailto:support@ecomfreedom.com"
                color="primary"
              >
                Get Help Now
              </Button>
            }
          />
          <Card
            className={styles.card}
            title="Ask The Community"
            body="Get help from your mentors and fellow students with any business problems that come up along the journey."
            button={
              <Button
                size="large"
                component={Link}
                to="/help/community"
                color="primary"
              >
                Chat With Students
              </Button>
            }
          />
        </div>
      </PageContainer>
    </section>
  );
};

export default Cards;
