import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { noop, ary, uniq } from 'lodash';

import {
  selectUser,
  selectCourses,
  selectTrials,
  selectCancelTrialsRequest,
} from 'Redux/selectors';
import { cancelTrials, enqueueSnackbar } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const CancelTrialsDialog = (props) => {
  const { trialIds, onClose } = props;

  const user = useSelector(selectUser);
  const courses = useSelector(selectCourses);
  const trials = useSelector(selectTrials);
  const { isLoading } = useSelector(selectCancelTrialsRequest);
  const dispatch = useDispatch();

  const filtered = useMemo(
    () =>
      trials
        .filter((t) => trialIds.includes(t.id))
        .map((t) => ({
          ...t,
          course:
            (courses &&
              courses
                .filter((c) => c.id === t.courseId)
                .map((c) => c.title)[0]) ||
            'Unknown',
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [courses, trialIds],
  );

  const handleConfirm = useCallback(() => {
    dispatch(
      cancelTrials(
        {
          userId: user.id,
          trialIds,
        },
        { isPromise: true },
      ),
    )
      .then(() => {
        dispatch(
          enqueueSnackbar('CANCEL_TRIALS', {
            variant: 'success',
            message: 'Trials cancelled successfully',
          }),
        );
        onClose && onClose(true);
      })
      .catch(noop);
  }, [dispatch, user, trialIds, onClose]);

  return (
    <Dialog
      title="Cancel trials"
      loading={isLoading}
      onClose={ary(onClose, 0)}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleConfirm}>
          Confirm
        </Button>
      }
    >
      <Typography variant="body1" align="center" gutterBottom>
        {`Are you sure you want to cancel ${
          user.firstName
        }'s free trial to ${uniq(filtered.map((t) => t.course)).join(' & ')}?`}
      </Typography>
    </Dialog>
  );
};

CancelTrialsDialog.propTypes = {
  trialIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CancelTrialsDialog;
