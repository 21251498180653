import qs from 'qs';

import instance from 'Api/instance';

export const getEvents = ({
  courseIdOrMentorSlug,
  fromDate,
  toDate,
  type,
  limit,
  offset,
} = {}) =>
  instance.get(
    `/events?` +
      qs.stringify({
        courseIdOrMentorSlug,
        fromDate,
        toDate,
        type,
        limit,
        offset,
      }),
  );

export const getEvent = (eventId) => instance.get(`/events/${eventId}`);

export const updateEvent = (eventId, attributes) =>
  instance.patch('/events/' + eventId, { data: { attributes } });

export const createEvent = (attributes) =>
  instance.post('/events', { data: { attributes } });

export const deleteEvent = (eventId) => instance.delete('/events/' + eventId);
