import { put, call, select } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import { Link } from 'react-router-dom';

import * as Exec from 'Redux/creators';
import SnackbarAction from 'Components/shared/snackbar-action';
import * as Api from 'Api';

import { Button } from '@material-ui/core';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { selectRememberMeAsSessionType } from './remember-me';
import { fetchMeSuccess } from './me';

const MODULE = 'change-password';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CHANGE_PASSWORD_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const changePassword = Exec.requestActionCreator(MODULE);
export const changePasswordLoading = Exec.loadingActionCreator(MODULE);
export const changePasswordSuccess = Exec.successActionCreator(MODULE);
export const changePasswordError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectChangePasswordRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const renderTryAgain = (id) => (
  <SnackbarAction
    id={id}
    render={
      <Button color="inherit" component={Link} to="/account-recovery">
        TRY AGAIN
      </Button>
    }
  />
);
const doChangePassword = function* ({ payload, meta }) {
  try {
    const sessionType = yield select(selectRememberMeAsSessionType);

    yield put(closeSnackbar(CHANGE_PASSWORD_REQUEST));
    yield put(changePasswordLoading());
    const { data } = yield call(
      Api.changePassword,
      payload.password,
      payload.code,
    );
    yield call(Api.signin, data.email, payload.password, sessionType);
    yield put(
      batchActions([
        Exec.resetGlobalFetch(),
        fetchMeSuccess({ data }),
        changePasswordSuccess(null, meta),
      ]),
    );
  } catch (err) {
    yield put(changePasswordError(err, meta));
    yield put(
      enqueueSnackbar(CHANGE_PASSWORD_REQUEST, {
        message:
          err.code === 'ERROR_CODE_VERIFY_TOKEN_INVALID'
            ? 'The code you used is no longer valid'
            : err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : 'There was an error, try again later',
        variant: 'error',
        action:
          err.code === 'ERROR_CODE_VERIFY_TOKEN_INVALID'
            ? renderTryAgain
            : null,
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doChangePassword);
