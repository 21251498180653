/* eslint-disable react/prop-types */
import ReactQuill from 'react-quill';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import withController from 'Util/with-controller';

import { TextField as MuiTextField, withStyles } from '@material-ui/core';

import 'react-quill/dist/quill.snow.css';

const TextField = withStyles((theme) => {
  const withSpacing = (style, spacing) => ({
    ...style,
    marginTop: theme.spacing(spacing),
    marginBottom: theme.spacing(spacing),
  });
  const withImportantFontSize = (style) => ({
    ...style,
    fontSize: style.fontSize + ' !important',
  });

  return {
    root: {
      '& .MuiOutlinedInput-root': {
        padding: theme.spacing(1, 0),
      },
      '& .ql-toolbar': {
        border: 'none',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,

        '& .ql-picker-label': theme.typography.body2,

        '& .ql-picker-item[data-value="4"]::before': withImportantFontSize(
          theme.typography.h4,
        ),
        '& .ql-picker-item[data-value="5"]::before': withImportantFontSize(
          theme.typography.h5,
        ),
        '& .ql-picker-item[data-value="6"]::before': withImportantFontSize(
          theme.typography.h6,
        ),
        '& .ql-picker-item::before': withImportantFontSize(
          theme.typography.body1,
        ),
      },
      '& .ql-container': {
        border: 'none',

        ...theme.typography.body1,
        '& h1': withSpacing(theme.typography.h1, 1),
        '& h2': withSpacing(theme.typography.h2, 1),
        '& h3': withSpacing(theme.typography.h3, 1),
        '& h4': withSpacing(theme.typography.h4, 1),
        '& h5': withSpacing(theme.typography.h5, 1),
        '& h6': withSpacing(theme.typography.h6, 1),
        '& p': withSpacing(theme.typography.body1, 1),
        '& ul': {
          paddingLeft: theme.spacing(3.5),
          margin: theme.spacing(1, 0),
        },
        '& ul:first-child': {
          marginTop: 0,
        },
        '& ul:last-child': {
          marginBottom: 0,
        },
        '& li:not(:last-child)': {
          marginBottom: theme.spacing(1),
        },
        '& img': withSpacing({ maxWidth: '100%' }, 1),
      },
      '& .ql-tooltip': {
        boxShadow: theme.shadows[2],
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        zIndex: 9,
        ...theme.typography.body2,
      },
    },
  };
})(MuiTextField);

const DraftJsComponent = forwardRef((props, ref) => {
  const { component: Component, editorRef, ...rest } = props;

  const modules = useMemo(
    () => ({
      toolbar: [
        [{ header: [4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        // ['link', 'image'],
        ['link'], // image disabled right now. Can be easily added later
      ],
    }),
    [],
  );

  const formats = useMemo(
    () => [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
    ],
    [],
  );

  useImperativeHandle(ref, () => ({
    // this is where one would use REFs
  }));

  return (
    <Component modules={modules} formats={formats} {...rest} ref={editorRef} />
  );
});

const RichTextEditor = (props) => {
  const { editorRef, errorText, value, onChange, ...rest } = props;

  // const [value, setValue] = useState('');

  return (
    <TextField
      variant="outlined"
      {...rest}
      error={Boolean(errorText)}
      helperText={errorText || ' '}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputProps: {
          component: ReactQuill,
          editorRef,
          value,
          onChange,
        },
        inputComponent: DraftJsComponent,
      }}
    />
  );
};

export default withController(RichTextEditor);
