import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    backgroundColor: 'transparent',
    zIndex: 1,
    color: theme.palette.text.secondary,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.grey[300]}`,
    userSelect: 'none',
  },
  active: {
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.main,
  },
  completed: {
    color: 'white',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
}));

const StepIcon = (props) => {
  const styles = useStyles();
  const { active, completed, icon } = props;

  return (
    <div
      className={clsx(styles.root, {
        [styles.active]: active,
        [styles.completed]: completed,
      })}
    >
      {icon}
    </div>
  );
};

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

export default StepIcon;
