import { takeLatest } from 'redux-saga/effects';
import hash from 'object-hash';
import { camelCase, castArray, get, isArray, kebabCase } from 'lodash';

const RESET_GLOBAL_FETCH = 'reset-global-fetch';

export const CACHE_CONTROL_STALE_WHILE_REVALIDATE =
  'CACHE_CONTROL_STALE_WHILE_REVALIDATE';
export const CACHE_CONTROL_NO_CACHE = 'CACHE_CONTROL_NO_CACHE';

export const requestConstantCreator = (name) => kebabCase(name + '-request');
export const loadingConstantCreator = (name) => kebabCase(name + '-loading');
export const successConstantCreator = (name) => kebabCase(name + '-success');
export const errorConstantCreator = (name) => kebabCase(name + '-error');
export const resetConstantCreator = (name) => kebabCase(name + '-reset');
export const progressConstantCreator = (name) => kebabCase(name + '-progress');

export const requestActionCreator = (name) => {
  return (payload, options) => {
    const { isPromise = false, cacheControl, isAppending = false } =
      options || {};
    return {
      type: requestConstantCreator(name),
      payload,
      meta: {
        cacheControl,
        appending: isAppending,
        thunk: isPromise,
      },
    };
  };
};

export const loadingActionCreator = (name) => {
  return () => {
    return {
      type: loadingConstantCreator(name),
    };
  };
};

export const successActionCreator = (name) => {
  return (payload, meta) => {
    return {
      type: successConstantCreator(name),
      payload,
      meta,
    };
  };
};

export const errorActionCreator = (name) => {
  return (error, meta) => {
    return {
      type: errorConstantCreator(name),
      payload: {
        error,
      },
      error: true,
      meta,
    };
  };
};

export const resetActionCreator = (name) => {
  return () => {
    return {
      type: resetConstantCreator(name),
    };
  };
};

export const progressActionCreator = (name) => {
  return (progress) => {
    return {
      type: progressConstantCreator(name),
      payload: {
        progress,
      },
    };
  };
};

export const resetGlobalFetch = requestActionCreator(RESET_GLOBAL_FETCH);

export const fetchReducerCreator = (name, options) => {
  const {
    cacheControl = CACHE_CONTROL_STALE_WHILE_REVALIDATE,
    defaultState,
    cacheKeyFunction = hash,
  } = options || {};

  const validMode = [
    CACHE_CONTROL_STALE_WHILE_REVALIDATE,
    CACHE_CONTROL_NO_CACHE,
  ].includes(cacheControl);

  if (!validMode) {
    throw new Error('unknown cacheControl mode: ' + cacheControl);
  }

  const initialState = {
    data: null,
    isLoading: true,
    isRequested: false,
    error: null,
    ...defaultState,
  };

  const resetGlobalFetchAction = requestConstantCreator(RESET_GLOBAL_FETCH);

  const requestAction = requestConstantCreator(name);
  const loadingAction = loadingConstantCreator(name);
  const errorAction = errorConstantCreator(name);
  const successAction = successConstantCreator(name);
  const resetAction = resetConstantCreator(name);

  return (state = initialState, action) => {
    const cacheControl_ = get(action, 'meta.cacheControl', cacheControl);
    const appending = get(action, 'meta.appending', false);
    switch (action.type) {
      case resetGlobalFetchAction:
      case resetAction:
        return initialState;
      case requestAction:
        if (appending) {
          // bypass cache control
          return { ...state, error: null, isLoading: true };
        }

        if (cacheControl_ === CACHE_CONTROL_NO_CACHE) {
          return { ...state, isLoading: true, data: null, error: null };
        }

        // SWR cache control, don't show loading indicator while
        // data is being refreshed. User can request cache invalidation
        // by passing different cacheControl in.
        //
        // Also, calculate payload cache key so that the user can know
        // that the data is being reloaded

        const cacheKey = cacheKeyFunction(action.payload || {});
        const { cacheKey: oldCacheKey } = state;

        return {
          ...state,
          error: null,
          cacheKey,
          flushLoadingCache: cacheKey !== oldCacheKey,
          isLoading: cacheKey !== oldCacheKey || !Boolean(state.data),
          isRequested: true,
        };
      case errorAction:
        return {
          ...state,
          data: null,
          isLoading: false,
          isRequested: false,
          error: action.payload.error,
        };
      case successAction:
        if (appending && state && state.data && isArray(state.data)) {
          return {
            ...state,
            error: null,
            ...action.payload,
            isLoading: false,
            isRequested: false,
            data: [...state.data, ...castArray(action.payload.data)],
          };
        }
        return {
          ...state,
          data: null,
          ...action.payload,
          error: null,
          isLoading: false,
          isRequested: false,
        };
      case loadingAction: // Loading state is controlled by request action
      default:
        return state;
    }
  };
};

export const mutateReducerCreator = (name, options) => {
  const { defaultState } = options || {};

  const initialState = { isLoading: false, error: null, defaultState };

  const requestAction = requestConstantCreator(name);
  const loadingAction = loadingConstantCreator(name);
  const errorAction = errorConstantCreator(name);
  const successAction = successConstantCreator(name);
  const resetAction = resetConstantCreator(name);
  const progressAction = progressConstantCreator(name);

  return (state = initialState, action) => {
    switch (action.type) {
      case resetAction:
        return initialState;
      case requestAction:
        return { ...state, isLoading: false, error: null };
      case loadingAction:
        return { ...state, isLoading: true };
      case errorAction:
        return { ...state, isLoading: false, error: action.payload.error };
      case successAction:
        return { ...state, ...action.payload, isLoading: false };
      case progressAction:
        return { ...state, progress: action.payload.progress };
      default:
        return state;
    }
  };
};

export const fetchRequestSelectorCreator = (name) => {
  name = camelCase(name);
  return (store) => {
    const { isLoading, isRequested, flushLoadingCache, data, error, progress } =
      store[name] || {};
    return {
      error,
      isLoading: flushLoadingCache ? isLoading : isLoading && !data,
      isRequested,
      progress,
    };
  };
};

export const mutateRequestSelectorCreator = (name) => {
  name = camelCase(name);
  return (store) => {
    const { isLoading, error, progress } = store[name] || {};
    return {
      error,
      isLoading,
      progress,
    };
  };
};

export const dataSelectorCreator = (name) => {
  name = camelCase(name);
  return (store) => {
    const { data } = store[name] || {};
    return data || null;
  };
};

export const sagaCreator = (name, effectHandler, effect = takeLatest) => {
  const requestAction = requestConstantCreator(name);
  return function* () {
    yield effect(requestAction, effectHandler);
  };
};
