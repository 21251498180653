import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { toNumber, get } from 'lodash';
import { addWeeks } from 'date-fns';

import { selectSuspendUserRequest, selectUser } from 'Redux/selectors';
import { suspendUser } from 'Redux/actions';
import Select from 'Components/shared/select';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button, Typography } from '@material-ui/core';

const reasonOptions = [
  {
    value: 'Sharing inappropriate links',
    label: 'Sharing inappropriate links',
  },
  {
    value: 'Selling their services to students',
    label: 'Selling their services to students',
  },
  {
    value: 'Reselling our courses',
    label: 'Reselling our courses',
  },
];
const durationOptions = [
  { value: '1', label: '1 Week' },
  { value: '2', label: '2 Weeks' },
  { value: '4', label: '4 Weeks' },
  { value: '8', label: '8 Weeks' },
  { value: '12', label: '12 Weeks' },
  { value: '0', label: 'Forever' },
];

const schema = yup.object().shape({
  reason: yup.string().required('Please select the reason for suspension.'),
  duration: yup.string().required('Required.'),
});

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
  },
  selectLeft: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '62.1%',
      marginRight: theme.spacing(3),
    },
  },
  selectRight: {
    flexGrow: 1,
  },
  button: {
    color: theme.palette.grey[500],
  },
}));

const SuspendUserDialog = (props) => {
  const { onClose } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { isLoading } = useSelector(selectSuspendUserRequest);

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
  });

  const handleSuspend = useCallback(
    (data) => {
      const { reason, duration } = data;
      const expiresAt = toNumber(duration)
        ? addWeeks(new Date(), toNumber(duration))
        : null;

      dispatch(
        suspendUser(
          { userId: user.id, reason, expiresAt },
          { isPromise: true },
        ),
      ).then(onClose);
    },
    [dispatch, onClose, user.id],
  );

  return (
    <Dialog
      title="Suspend Account"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleSuspend)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">Suspend</Button>}
    >
      <Typography variant="body1" align="center">
        Please fill in the information about why you are suspending{' '}
        {user.firstName} {user.lastName} down below:
      </Typography>
      <div className={styles.container}>
        <Select
          data-testid="reason"
          className={styles.selectLeft}
          control={control}
          label="Reason"
          name="reason"
          options={reasonOptions}
          errorText={get(errors, 'reason.message')}
        />
        <Select
          data-testid="duration"
          className={styles.selectRight}
          control={control}
          label="Duration"
          name="duration"
          options={durationOptions}
          errorText={get(errors, 'duration.message')}
        />
      </div>
    </Dialog>
  );
};

SuspendUserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SuspendUserDialog;
