import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPDATE_PAYMENT_LINK_SUCCESS } from './update-payment-link';

const MODULE = 'payment-link';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchPaymentLink = Exec.requestActionCreator(MODULE);
export const fetchPaymentLinkLoading = Exec.loadingActionCreator(MODULE);
export const fetchPaymentLinkSuccess = Exec.successActionCreator(MODULE);
export const fetchPaymentLinkError = Exec.errorActionCreator(MODULE);
export const fetchPaymentLinkReset = Exec.resetActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);
export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_LINK_SUCCESS:
      if (!state || !state.data) {
        return state;
      }

      const { data } = action.payload;

      if (data.id !== state.data.id) {
        return state;
      }

      return { ...state, data };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectPaymentLinkRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectPaymentLink = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchPaymentLink = function* ({ payload, meta }) {
  try {
    const { data } = yield call(Api.getPaymentLink, payload.paymentLinkId);
    yield put(fetchPaymentLinkSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchPaymentLinkError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchPaymentLink);
