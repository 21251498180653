import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(2),
  },
  value: {
    lineHeight: '51px',
    fontWeight: 700,
  },
}));

const EarningBox = (props) => {
  const { className, label, value } = props;
  const styles = useStyles();

  return (
    <div className={clsx(className, styles.root)}>
      <Typography variant="body2" align="center" noWrap>
        {label}
      </Typography>
      <Typography className={styles.value} variant="h5" align="center" noWrap>
        {value}
      </Typography>
    </div>
  );
};

EarningBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default EarningBox;
