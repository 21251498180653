import { get, isEmpty } from 'lodash';
import { Deserializer } from 'jsonapi-serializer';
import { create } from 'axios';

import { refreshToken } from './resources/oauth';

const { REACT_APP_API_URL } = process.env;

const instance = create({
  baseURL: REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'X-Api-Origin': 'PLATFORM',
    'X-Api-Version': 1,
  },
});

// import { setup } from 'axios-cache-adapter';
// const instance = setup({
// baseURL: REACT_APP_API_URL,
// withCredentials: true,
// headers: {
//   'X-Api-Origin': 'PLATFORM',
//   'X-Api-Version': 1,
// },
//   cache: {
//     maxAge: 60 * 1000,
//     readHeaders: true,
//     exclude: {
//       filter({ url }) {
//         return ['/products/search'].includes(url);
//       },
//     },
//   },
// });

const failureInterceptor = async (err) => {
  const errors = get(err, 'response.data.errors');
  const url = get(err, 'response.config.url', 'net://error');

  if (errors && errors.length) {
    const isTokenExpired =
      ['ERROR_CODE_AUTH_TOKEN_INVALID', 'ERROR_CODE_UNAUTHORIZED'].includes(
        errors[0].code,
      ) && url !== '/oauth/token';

    if (isTokenExpired) {
      console.info('[AUTH] trying to refresh token...');

      const sessionType = localStorage.getItem('remember-me')
        ? 'session_long'
        : 'session_short';

      return refreshToken(sessionType)
        .then(() => instance.request(err.config))
        .catch(() => Promise.reject(errors[0]));
    }

    return Promise.reject(errors[0]);
  } else {
    const detail =
      err.message === 'Network Error'
        ? 'Service temporarily unavailable, try later'
        : err.message;
    return Promise.reject({
      status: -1,
      code: 'ERROR_CODE_NETWORK_ERROR',
      title: err.message || 'Network Error',
      detail,
      source: {
        pointer: url,
      },
    });
  }
};

const successInterceptor = async (res) => {
  const { data: payload } = res;

  const type =
    get(payload, 'data.type') || get(payload, 'data.0.type') || 'unknown';

  const meta = {
    __type: type,
    __links: payload.links || null,
    __meta: payload.meta || {},
  };

  if (isEmpty(payload.data) || type === 'unknown') {
    return {
      ...meta,
      ...res,
      data: isEmpty(payload.data) ? [] : payload,
    };
  }

  const data = await new Deserializer({
    keyForAttribute: 'camelCase',
  }).deserialize(payload);

  return {
    ...meta,
    ...res,
    data,
  };
};

instance.interceptors.response.use(successInterceptor, failureInterceptor);

export default instance;
