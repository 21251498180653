import * as yup from 'yup';
import { useTimer } from 'react-timer-hook';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { ary, get, padStart } from 'lodash';
import { add } from 'date-fns';

import {
  selectConfirmEmailChangeRequest,
  selectConfirmRegisterRequest,
  selectRegisterLastPayload,
  selectResetPasswordLastPayload,
} from 'Redux/selectors';
import {
  confirmRegister,
  confirmEmailChange,
  resetPassword,
  register,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useRecaptcha from 'Hooks/use-recaptcha';
import useNavigateExternal from 'Hooks/use-navigate-external';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import { PageLoaderReset } from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import Input from 'Components/shared/input';

import { ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import {
  makeStyles,
  Button,
  Grid,
  Typography,
  Container,
} from '@material-ui/core';

const schema = yup.object().shape({
  code: yup.string().trim().required('Field Required.'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    paddingBottom: theme.spacing(1.5),
  },
  input: {
    width: '100%',
  },
  buttons: {
    justifyContent: 'space-between',
  },
  frame: {
    width: '100%',
    maxWidth: 360,
    margin: -1,
    padding: theme.spacing(3, 4),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      borderWidth: 0,
    },
  },
}));

const VerifyEmailPage = () => {
  const styles = useStyles();
  const navigate = useNavigateExternal();
  const dispatch = useDispatch();

  const [params] = useSearchParams();
  const intent = params.get('intent');
  const code = params.get('code');
  const redirectTo = params.get('redirectTo');

  const { isLoading } = useCombinedRequestsSelector(
    selectConfirmRegisterRequest,
    selectConfirmEmailChangeRequest,
  );
  const lastPayload = useSelector(
    intent === 'register'
      ? selectRegisterLastPayload
      : selectResetPasswordLastPayload,
  );

  const recaptcha = useRecaptcha();

  const { minutes, seconds, isRunning, restart } = useTimer({
    expiryTimestamp: add(new Date(), { minutes: 2 }),
  });

  const resendTime = `${minutes}:${padStart(seconds, 2, '0')}`;

  const handleFormSubmit = useCallback(
    (data) => {
      if (intent === 'register') {
        dispatch(confirmRegister({ code: data.code }, { isPromise: true }))
          .then(() => navigate(redirectTo || '/'))
          .catch(console.warn);
      } else if (intent === 'account-recovery') {
        navigate(
          !redirectTo
            ? `/change-password?code=${data.code}`
            : `/change-password?code=${data.code}&redirectTo=${redirectTo}`,
        );
      } else if (intent === 'email-change') {
        dispatch(confirmEmailChange({ code: data.code }, { isPromise: true }))
          .then(() => navigate('/account/me'))
          .catch(console.warn);
      }
    },
    [intent, redirectTo, dispatch, navigate],
  );
  const handleResend = useCallback(
    async (recaptchaResponse) => {
      restart(add(new Date(), { minutes: 2 }));

      try {
        if (intent === 'register') {
          await dispatch(
            register(
              { ...lastPayload, recaptchaResponse },
              { isPromise: true },
            ),
          );
        } else if (intent === 'account-recovery') {
          await dispatch(
            resetPassword(
              { ...lastPayload, recaptchaResponse },
              { isPromise: true },
            ),
          );
        }
      } catch (err) {
        if (err.error.code === 'ERROR_CODE_TOO_MANY_REQUESTS') {
          recaptchaResponse = await recaptcha();
          if (recaptchaResponse) {
            return handleResend(recaptchaResponse);
          }
        }
      }
    },
    [intent, restart, dispatch, lastPayload, recaptcha],
  );

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: { code },
  });

  useEffect(() => {
    // Trigger submit automatically if the code is present
    if (code) {
      handleFormSubmit({ code });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!['register', 'account-recovery', 'email-change'].includes(intent)) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoaderReset>
      <PageLayout>
        <Container className={styles.root} disableGutters>
          <Grid
            container
            spacing={2}
            direction="column"
            className={styles.frame}
            component="form"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Grid item>
              <Typography align="center" variant="h5" className={styles.title}>
                Email Verification
              </Typography>
              <Typography align="center" variant="body1" color="textSecondary">
                An email was just sent with your unique code.
              </Typography>
            </Grid>
            <Grid item>
              <Input
                data-testid="code"
                control={control}
                className={styles.input}
                disabled={isLoading}
                label="Enter Code"
                name="code"
                errorText={get(errors, 'code.message')}
              />
            </Grid>
            {lastPayload && (
              <Grid
                container
                item
                alignItems="center"
                className={styles.buttons}
              >
                <Grid item>
                  <Typography variant="subtitle2" color="textSecondary">
                    Didn't get an email?
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    data-testid="resend"
                    color="primary"
                    disabled={isRunning}
                    variant="text"
                    onClick={ary(handleResend, 0)}
                  >
                    Resend {isRunning ? resendTime : ''}
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid container item className={styles.buttons}>
              <Grid item>
                <Button
                  data-testid="back"
                  color="primary"
                  disabled={isLoading}
                  variant="text"
                  component={Link}
                  to="/signin"
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  data-testid="submit"
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  endIcon={<ChevronRightIcon />}
                >
                  Verify Code
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </PageLayout>
    </PageLoaderReset>
  );
};

export default VerifyEmailPage;
