import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const UserIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path
        d="M24.167 26.667c-1.25-.667-.583-1.917 0-2.5.75-.75 1.667-3.333 1.667-3.333 1.5-.667 1.668-1.75 1.833-2.5.667-2.167-1-2.5-1-2.5s1.333-3.582.25-6.332C25.5 5.919 19.75 4.584 18.75 7.919c-6.833-1.502-5.417 7.914-5.417 7.914s-1.667.333-1 2.5c.167.75.333 1.833 1.833 2.5 0 0 .917 2.583 1.667 3.333.583.582 1.25 1.832 0 2.5-2.5 1.332-10 1.667-10 7.5h28.333c.001-5.833-7.499-6.166-9.999-7.499z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default UserIcon;
