import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Divider, Tabs as MuiTabs, Tab } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  tab: {
    ...theme.typography.subtitle2,
  },
  divider: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    opacity: 0.1,
  },
}));

const Tabs = (props) => {
  const { className, userId, ...rest } = props;
  const styles = useStyles();

  return (
    <div className={clsx(styles.root, className)}>
      <MuiTabs {...rest}>
        <Tab
          label="Profile"
          component={Link}
          to={'/admin/users/' + userId}
          className={styles.tab}
        />
        <Tab
          label="Stats"
          component={Link}
          to={'/admin/users/' + userId + '/stats'}
          className={styles.tab}
        />
        <Tab
          label="Financials"
          component={Link}
          to={'/admin/users/' + userId + '/financials'}
          className={styles.tab}
        />
      </MuiTabs>
      <Divider variant="fullWidth" className={styles.divider} />
    </div>
  );
};

Tabs.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default Tabs;
