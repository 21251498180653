import { call, put } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'submit-tool-problem';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SUBMIT_TOOL_PROBLEM_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const submitToolProblem = Exec.requestActionCreator(MODULE);
export const submitToolProblemLoading = Exec.loadingActionCreator(MODULE);
export const submitToolProblemSuccess = Exec.successActionCreator(MODULE);
export const submitToolProblemError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSubmitToolProblemRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const subjectMap = {
  'amazon-fba-profit-calculator': 'Amazon FBA Profit Calculator Feedback',
  'ecom-radar': 'Ecom Radar Feedback',
};
const tagMap = {
  'amazon-fba-profit-calculator': ['platform/amazon-fba-calculator'],
  'ecom-radar': ['platform/ecom-radar'],
};
const doSubmitToolProblem = function* ({ payload, meta }) {
  const { tool, problem } = payload;

  const subject = subjectMap[tool];
  const tags = tagMap[tool];

  try {
    yield put(closeSnackbar(SUBMIT_TOOL_PROBLEM_REQUEST));
    yield put(submitToolProblemLoading());
    yield call(Api.submitToolProblem, subject, problem, tags);
    yield put(submitToolProblemSuccess(null, meta));

    yield put(
      enqueueSnackbar(SUBMIT_TOOL_PROBLEM_REQUEST, {
        message: 'Thank you for the feedback! We will get back to you shortly.',
        variant: 'success',
      }),
    );
  } catch (err) {
    yield put(submitToolProblemError(err, meta));
    yield put(
      enqueueSnackbar(SUBMIT_TOOL_PROBLEM_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSubmitToolProblem);
