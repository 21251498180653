import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { get, differenceBy, includes, noop } from 'lodash';
import { addHours, addSeconds, parseISO, differenceInSeconds } from 'date-fns';

import {
  selectCoursesRequest,
  selectCourses,
  selectEvent,
  selectEventVideo,
  selectEventRequest,
  selectUsers,
  selectUsersRequest,
  selectEventVideoRequest,
  selectCreateEventRequest,
  selectUpdateEventRequest,
} from 'Redux/selectors';
import {
  createEvent,
  fetchCourses,
  fetchEvent,
  fetchEventReset,
  fetchEventVideo,
  fetchUsers,
  updateEvent,
  enqueueSnackbar,
  closeSnackbar,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import UploadEventRecordingDialog from 'Dialogs/upload-event-recording-dialog';
import ConfirmDeleteEventRecordingDialog from 'Dialogs/confirm-delete-event-recording-dialog';
import ConfirmDeleteEventDialog from 'Dialogs/confirm-delete-event-dialog';
import AddEventRecordingDialog from 'Dialogs/add-event-recording-dialog';
import RichTextEditor from 'Components/shared/rich-text-editor';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import DateTimePicker from 'Components/shared/date-time-picker';
import CourseCard from 'Components/shared/course-card';
import AutocompleteInput from 'Components/shared/autocomplete-input';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import UploadVideoGroup from 'Components/pages/admin/upload-video-group';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import {
  Save as SaveIcon,
  DeleteOutline as DeleteOutlineIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { Grid, Button, makeStyles, Typography, Box } from '@material-ui/core';

const schema = yup.object().shape({
  title: yup.string().required('Field Required.'),
  variantTitle: yup.string().required('Field Required.'),
  startsAt: yup.date().required('Field Required.'),
  endsAt: yup
    .date()
    .when('startsAt', (startsAt) =>
      yup.date().min(startsAt, 'Must Be After Start Time.'),
    )
    .required('Field Required.'),
  liveCallUrl: yup.string().required('Field Required.'),
  mentors: yup
    .array(
      yup.object().shape({
        id: yup.string(),
        firstName: yup.string(),
        lastName: yup.string(),
      }),
    )
    .transform((value) => (!value ? [] : value))
    .min(1, 'Mentors Required.'),
  topics: yup.string().required('Field Required.'),
  courseIds: yup.array(yup.string()).min(1, 'Select One Course.'),
});

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  input: {
    width: '100%',
  },
  exp: {
    maxWidth: 160,
  },
  colorPreview: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  switch: {
    marginBottom: theme.spacing(2.5),
  },
  upload: {
    width: '100%',
  },
  topics: {
    marginBottom: theme.spacing(3),
  },
  course: {
    width: '100%',
    height: 280,
  },
  buttons: {
    paddingTop: theme.spacing(3) + 'px !important',
  },
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

const AdminCreateOrUpdateMentorshipCallPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [mentorSearchText, setMentorSearchText] = useState('');

  const courses = useSelector(selectCourses);
  const event = useSelector(selectEvent);
  const video = useSelector(selectEventVideo);
  const users = useSelector(selectUsers);

  const { isLoading, error } = useSelector(selectCoursesRequest);
  const { isLoading: isLoadingEvent, error: errorEvent } =
    useSelector(selectEventRequest);
  const { isLoading: isLoadingVideo } = useSelector(selectEventVideoRequest);
  const { isLoading: isLoadingUsers } = useSelector(selectUsersRequest);

  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectUpdateEventRequest,
    selectCreateEventRequest,
  );

  useEffect(() => () => dispatch(fetchEventReset()), [dispatch]);

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());

    if (eventId) {
      dispatch(fetchEvent({ eventId }));
      dispatch(fetchEventVideo({ eventId, recordingOnly: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, eventId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (mentorSearchText) {
        dispatch(fetchUsers({ query: mentorSearchText, limit: 5 }));
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [dispatch, mentorSearchText]);

  const [showUploadEventRecordingModal, hideUploadEventRecordingModal] =
    useModal(
      () =>
        eventId ? (
          <UploadEventRecordingDialog
            eventId={eventId}
            onClose={hideUploadEventRecordingModal}
          />
        ) : null,
      [eventId],
    );
  const [showAddEventRecordingModal, hideAddEventRecordingModal] = useModal(
    () =>
      eventId ? (
        <AddEventRecordingDialog
          eventId={eventId}
          onClose={hideAddEventRecordingModal}
        />
      ) : null,
    [eventId],
  );
  const [showDeleteEventRecordingModal, hideDeleteEventRecordingModal] =
    useModal(
      () =>
        eventId ? (
          <ConfirmDeleteEventRecordingDialog
            eventId={eventId}
            onClose={hideDeleteEventRecordingModal}
          />
        ) : null,
      [eventId],
    );
  const [showDeleteEventModal, hideDeleteEventModal] = useModal(
    () =>
      eventId ? (
        <ConfirmDeleteEventDialog
          eventId={eventId}
          onClose={hideDeleteEventModal}
        />
      ) : null,
    [eventId],
  );

  const getOptionSelected = useCallback(
    (option, value) => option.id === value.id,
    [],
  );
  const handleRenderMentorOption = useCallback(
    (user) =>
      user ? `${user.firstName} ${user.lastName} (${user.email})` : null,
    [],
  );

  if (!isLoadingEvent && !event && eventId) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader
      loading={
        isLoading || (eventId && isLoadingEvent) || (eventId && isLoadingVideo)
      }
      error={error || (eventId ? errorEvent : null)}
    >
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [courseIds, setCourseIds] = useState(
          eventId && event ? event.courseIds : [],
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const handleFormSubmit = useCallback(
          async (data) => {
            dispatch(closeSnackbar('SUCCESS'));

            const { mentors, endsAt, ...options } = data;

            options.duration = differenceInSeconds(endsAt, options.startsAt);
            options.mentorIds = mentors.map((m) => m.id);
            options.courseIds = courseIds;
            options.type = 'mentorship-call';

            const action = eventId
              ? updateEvent({ ...options, eventId }, { isPromise: true })
              : createEvent(options, { isPromise: true });

            dispatch(action)
              .then(() => {
                dispatch(
                  enqueueSnackbar('SUCCESS', {
                    message: eventId ? 'Changes saved' : 'Event created',
                    variant: 'success',
                  }),
                );
                if (!eventId) {
                  navigate(`/admin/mentorship-calls`);
                }
              })
              .catch(noop);
          },
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [dispatch, navigate, eventId, courseIds],
        );

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [changedCourses, setChangedCourses] = useState(false);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { control, errors, formState, watch, handleSubmit } = useForm({
          defaultValues:
            eventId && event
              ? {
                  ...event,
                  endsAt: addSeconds(parseISO(event.startsAt), event.duration),
                  topics: event.topics ? event.topics.html : null,
                }
              : {
                  startsAt: new Date(),
                  endsAt: addHours(new Date(), 1),
                },
          validationSchema: schema,
          reValidateMode: 'onChange',
        });

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const handleCourseClick = useMemoizedCallback(
          (courseId) => () => {
            setChangedCourses(true);

            if (includes(courseIds, courseId)) {
              setCourseIds(courseIds.filter((id) => id !== courseId));
            } else {
              setCourseIds([...courseIds, courseId]);
            }
          },
          [courseIds],
        );

        const mentors = watch('mentors');

        const edited = formState.dirty || changedCourses;

        return (
          <UserDetailsLayout>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={[
                {
                  href: '/admin/mentorship-calls',
                  label: 'Mentorship Calls',
                },
                { label: eventId && event ? event.title : 'Create' },
              ]}
            />

            {!courses.filter((c) => c.isMentorshipEnabled).length ? (
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Typography component={Box} paddingTop="24px" variant="h6">
                    First, create a course with mentorship enabled
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    component={Link}
                    to="/admin/courses/create"
                  >
                    Create Course
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <form
                className={styles.form}
                noValidate
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <TitleAndAction
                  className={styles.titleAndAction}
                  title={
                    <Typography variant="h4">
                      {eventId
                        ? 'Edit Mentorship Call'
                        : 'Create New Mentorship Call'}
                    </Typography>
                  }
                />

                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <Input
                      data-testid="title"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Title"
                      name="title"
                      errorText={get(errors, 'title.message')}
                    />
                  </Grid>

                  <Grid item>
                    <Input
                      data-testid="variantTitle"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Type"
                      name="variantTitle"
                      errorText={get(errors, 'variantTitle.message')}
                    />
                  </Grid>

                  <Grid item container spacing={2} direction="row">
                    <Grid item sm={6} xs={12}>
                      <DateTimePicker
                        data-testid="startsAt"
                        control={control}
                        className={styles.input}
                        disabled={isLoadingMutation}
                        label="Start Time"
                        name="startsAt"
                        errorText={get(errors, 'startsAt.message')}
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <DateTimePicker
                        data-testid="endsAt"
                        control={control}
                        className={styles.input}
                        disabled={isLoadingMutation}
                        label="End Time"
                        name="endsAt"
                        errorText={get(errors, 'endsAt.message')}
                      />
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Input
                      data-testid="liveCallUrl"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Live Call Url"
                      name="liveCallUrl"
                      errorText={get(errors, 'liveCallUrl.message')}
                    />
                  </Grid>

                  <Grid item>
                    <AutocompleteInput
                      multiple
                      limitTags={2}
                      data-testid="mentors"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      defaultValue={mentors || []} // REQUIRED!
                      loading={Boolean(mentorSearchText && isLoadingUsers)}
                      options={
                        mentorSearchText
                          ? differenceBy(users, mentors, 'id') || []
                          : []
                      }
                      label="Mentors"
                      name="mentors"
                      noOptionsText={
                        mentorSearchText
                          ? 'Users not found'
                          : 'Search users by name or email'
                      }
                      getOptionSelected={getOptionSelected}
                      renderOption={handleRenderMentorOption}
                      onTextChange={setMentorSearchText}
                      errorText={get(errors, 'mentors.message')}
                    />
                  </Grid>

                  <Grid item>
                    <RichTextEditor
                      data-testid="topics"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Topics"
                      name="topics"
                      errorText={get(errors, 'topics.message')}
                    />
                  </Grid>

                  {eventId ? (
                    <>
                      <Grid item>
                        <Typography variant="h6">Recording</Typography>
                      </Grid>

                      <Grid item>
                        <UploadVideoGroup
                          className={styles.upload}
                          video={video}
                          onDelete={showDeleteEventRecordingModal}
                          onPasteUrl={showAddEventRecordingModal}
                          onUpload={showUploadEventRecordingModal}
                          disabled={isLoadingMutation}
                          uploadButtonLabel="Upload Recording"
                          pasteUrlButtonLabel="Paste Wistia Url"
                        />
                      </Grid>
                    </>
                  ) : null}

                  <Grid item>
                    <Box width="100%" marginTop={2}>
                      <Typography variant="h6">Courses</Typography>
                    </Box>
                  </Grid>

                  <Grid item container spacing={3}>
                    {courses
                      .filter((c) => c.isMentorshipEnabled)
                      .map((course, index) => (
                        <Grid key={index} item xs={12} md={6} lg={4}>
                          <CourseCard
                            className={styles.course}
                            elevation={8}
                            ButtonProps={{
                              'data-testid': 'courseLink',
                              component: 'button',
                              onClick: handleCourseClick(course.id),
                            }}
                            subtitle={
                              { COURSE: 'Course', BLUEPRINT: 'Blueprint' }[
                                course.type
                              ]
                            }
                            noLogo
                            selectable
                            selected={includes(courseIds, course.id)}
                            {...course}
                          />
                        </Grid>
                      ))}
                  </Grid>

                  <Grid
                    className={styles.buttons}
                    item
                    container
                    spacing={1}
                    justify="space-between"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        type="submit"
                        disabled={isLoadingMutation || (eventId && !edited)}
                      >
                        Save Call
                      </Button>
                    </Grid>
                    {eventId ? (
                      <Grid item>
                        <Button
                          variant="outlined"
                          startIcon={<DeleteOutlineIcon />}
                          type="button"
                          className={styles.deleteButton}
                          onClick={showDeleteEventModal}
                        >
                          Delete Call
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </form>
            )}
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminCreateOrUpdateMentorshipCallPage;
