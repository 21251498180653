import instance from 'Api/instance';

export const getAchievementLevels = () => instance.get('/achievement-levels');

export const updateAchievementLevel = (id, options) =>
  instance.patch('/achievement-levels/' + id, {
    data: {
      attributes: options,
    },
  });

export const deleteAchievementLevelPicture = (id) =>
  instance.delete('/achievement-levels/' + id + '/graphic');
