import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const FilterAlt = (props) => {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path d="M3.186 4.207L7.499 9.75v3.75c0 .825.675 1.5 1.5 1.5s1.5-.675 1.5-1.5V9.75s2.572-3.308 4.312-5.543a.749.749 0 00-.6-1.207H3.779a.748.748 0 00-.593 1.207z" />
    </SvgIcon>
  );
};

export default FilterAlt;
