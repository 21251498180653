import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { format } from 'date-fns';

import ensurePrice from 'Util/ensure-price';
import { selectCourses, selectTransactions } from 'Redux/selectors';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import Scrollable from 'Components/shared/scrollable';

import {
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  scrollable: {
    padding: 0,
    '& > :last-child': {
      paddingRight: 0,
    },
  },
  table: {
    minWidth: 920,
  },
  firstColumn: {
    paddingLeft: theme.spacing(3),
  },
  row: {
    '&:last-child > .MuiTableCell-root': {
      borderBottom: 'none !important',
    },
  },
  noData: {
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const TransactionsTable = (props) => {
  const { className, filters, selected, onSelect } = props;
  const styles = useStyles();

  const courses = useSelector(selectCourses);
  const transactions = useSelector(selectTransactions);

  const filteredTransactions = useMemo(
    () =>
      transactions
        ? transactions.filter((t) => filters.includes(t.status))
        : [],
    [filters, transactions],
  );
  const allSelected = filteredTransactions.every((t) =>
    selected.includes(t.id),
  );

  const handleToggleSelection = useMemoizedCallback(
    (id) => () => {
      if (selected.includes(id)) {
        onSelect(selected.filter((f) => f !== id));
      } else {
        onSelect([...selected, id]);
      }
    },
    [onSelect, selected],
  );
  const handleToggleSelectAll = useCallback(() => {
    if (allSelected) {
      onSelect([]);
    } else {
      onSelect(filteredTransactions.map((t) => t.id));
    }
  }, [allSelected, filteredTransactions, onSelect]);

  return (
    <TableContainer component="div" className={className}>
      <Scrollable className={styles.scrollable}>
        <div>
          <Table
            size="small"
            aria-label="table of transactions"
            className={styles.table}
          >
            <TableHead>
              <TableRow>
                <TableCell className={styles.firstColumn}>
                  <Checkbox
                    data-testid="selectAll"
                    color="primary"
                    checked={allSelected}
                    onClick={handleToggleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Amount</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Status</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Product</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Type</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Payment Method</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Time</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTransactions.length
                ? filteredTransactions.map((transaction, index) => {
                    const {
                      id,
                      grossAmount,
                      status,
                      courseId,
                      type,
                      paymentMethod,
                      createdAt,
                    } = transaction;

                    const dateCreatedAt = new Date(createdAt);

                    return (
                      <TableRow key={id} className={styles.row} hover>
                        <TableCell className={styles.firstColumn}>
                          <Checkbox
                            data-testid={'select-' + index}
                            color="primary"
                            checked={selected.includes(id)}
                            onClick={handleToggleSelection(id)}
                          />
                        </TableCell>
                        <TableCell>{ensurePrice(grossAmount)}</TableCell>
                        <TableCell>{capitalize(status)}</TableCell>
                        <TableCell>
                          {(courses &&
                            courses
                              .filter((c) => c.id === courseId)
                              .map((c) => c.title)[0]) ||
                            'Unknown'}
                        </TableCell>
                        <TableCell>
                          {type === 'FULL' ? 'Full Pay' : 'Installment'}
                        </TableCell>
                        <TableCell>
                          {paymentMethod && paymentMethod.card
                            ? `CC ${paymentMethod.card.last4}`
                            : 'Unknown'}
                        </TableCell>
                        <TableCell>
                          {format(dateCreatedAt, 'MMM d/yy')}
                        </TableCell>
                        <TableCell>{format(dateCreatedAt, 'h:mm a')}</TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
          {!filteredTransactions.length ? (
            <Typography
              className={styles.noData}
              align="center"
              variant="subtitle2"
            >
              No data to be shown
            </Typography>
          ) : null}
        </div>
      </Scrollable>
    </TableContainer>
  );
};

TransactionsTable.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func,
};

export default TransactionsTable;
