import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'create-lesson';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CREATE_LESSON_REQUEST = Exec.requestConstantCreator(MODULE);
export const CREATE_LESSON_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const createLesson = Exec.requestActionCreator(MODULE);
export const createLessonLoading = Exec.loadingActionCreator(MODULE);
export const createLessonSuccess = Exec.successActionCreator(MODULE);
export const createLessonError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCreateLessonRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doCreateLesson = function* ({ payload, meta }) {
  const { courseId, moduleId, ...fields } = payload;
  try {
    yield put(closeSnackbar(CREATE_LESSON_REQUEST));
    yield put(createLessonLoading());
    const { data } = yield call(Api.createLesson, courseId, moduleId, fields);
    yield put(createLessonSuccess({ data }, meta));
  } catch (err) {
    yield put(createLessonError(err, meta));
    yield put(
      enqueueSnackbar(CREATE_LESSON_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCreateLesson);
