import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { groupBy, map } from 'lodash';

import { selectCourses, selectCoursesRequest } from 'Redux/selectors';
import { fetchCourses } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import TrustpilotUnlockDialog from 'Dialogs/trustpilot-unlock-dialog';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import Divider from 'Components/shared/divider';
import CourseCard from 'Components/shared/course-card';
import SearchHeader from 'Components/pages/help/search-header';
import HeaderNavigation from 'Components/pages/help/header-navigation';
import Cards from 'Components/pages/help/cards';

import { LockOpen as LockOpenIcon } from '@material-ui/icons';
import { makeStyles, useMediaQuery, Grid, Typography } from '@material-ui/core';

const headingImage = {
  url: 'https://ecomfreedom-public-assets.s3.amazonaws.com/app/help-center-cover.jpg',
  isResizable: true,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingBottom: 0,
    paddingTop: theme.spacing(11.5),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(10),
    },
    flexGrow: 1,
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    maxWidth: 768,
    marginBottom: theme.spacing(6),
  },
  item: {
    margin: theme.spacing(0, 0, 4),
  },
  divider: {
    margin: theme.spacing(2, 0, 4),
  },
}));

const HelpCoursesPage = () => {
  const styles = useStyles();
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const [showUnlockTrustpilotModalTitle, setUnlockTrustpilotModalTitle] =
    useState(null);

  const { isLoading, error } = useSelector(selectCoursesRequest);
  const courses = useSelector(selectCourses);

  const handleShowUnlockTrustpilotModal = useMemoizedCallback(
    (title) => () => setUnlockTrustpilotModalTitle(title),
    [],
  );
  const handleHideUnlockTrustpilotDialog = useCallback(
    () => setUnlockTrustpilotModalTitle(null),
    [],
  );

  const mappedCourses =
    courses &&
    courses.map((course) => {
      const locked = !Boolean(course.purchasedAt);
      return {
        ...course,
        locked,
        subtitle: { COURSE: 'Course', BLUEPRINT: 'Blueprint' }[course.type],
        ButtonProps: locked
          ? course.isReleased
            ? course.unlockAfterTrustpilotReview
              ? {
                  'data-testid': 'showUnlockModal',
                  label: 'Unlock Now!',
                  startIcon: <LockOpenIcon />,
                  component: 'button',
                  onClick: handleShowUnlockTrustpilotModal(course.title),
                }
              : course.unlockAfterTrustpilotReviewInDays
              ? {
                  label: `Unlocks in ${
                    course.unlockAfterTrustpilotReviewInDays
                  } day${
                    course.unlockAfterTrustpilotReviewInDays === 1 ? '' : 's'
                  }`,
                  component: 'div',
                }
              : {
                  'data-testid': 'courseLink',
                  label: 'Enroll',
                  component: 'a',
                  href: course.salesPageUrl,
                }
            : {
                label: 'Coming Soon',
                disabled: true,
              }
          : {
              'data-testid': 'courseLink',
              label: 'Get Help',
              to: '/help/courses/' + course.slug,
            },
      };
    });

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groups = useMemo(
    () =>
      map(groupBy(mappedCourses, 'type'), (items, type) => ({
        name: {
          BLUEPRINT: 'Blueprints',
          COURSE: 'Courses',
        }[type],
        items,
      })),
    [mappedCourses],
  );

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <SearchHeader image={headingImage}>
            <HeaderNavigation variant="search" option="courses" />
          </SearchHeader>
          <PageContainer component="section" className={styles.root}>
            {showUnlockTrustpilotModalTitle ? (
              <TrustpilotUnlockDialog
                title={showUnlockTrustpilotModalTitle}
                onClose={handleHideUnlockTrustpilotDialog}
              />
            ) : null}
            {groups.map((group) => (
              <div className={styles.group} key={group.name}>
                <Typography align="center" variant="h4">
                  {group.name}
                </Typography>
                <Divider className={styles.divider} />
                <Grid
                  container
                  spacing={isLgUp ? 6 : isMdUp ? 3 : 2}
                  justify="center"
                >
                  {group.items.map((course, index) => (
                    <Grid
                      key={index}
                      className={styles.item}
                      container
                      item
                      xs={12}
                      sm={6}
                      justify="center"
                      alignItems="center"
                    >
                      <CourseCard elevation={8} {...course} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </PageContainer>
          <Cards />
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default HelpCoursesPage;
