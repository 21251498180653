import instance from 'Api/instance';

export const getAchievementStats = () => instance.get('/achievements/stats');

export const getAchievements = (courseId, skipAccessCheck) =>
  instance.get('/courses/' + courseId + '/achievements', {
    params: { skipAccessCheck },
  });

export const submitAchievement = (courseId, achievementId, imageUrl) =>
  instance.post(
    '/courses/' + courseId + '/achievements/' + achievementId + '/submit',
    {
      data: {
        attributes: {
          image: {
            url: imageUrl,
          },
        },
      },
    },
  );

export const createAchievement = (courseId, moduleId, attributes) =>
  instance.post(`/courses/${courseId}/modules/${moduleId}/achievements`, {
    data: { attributes },
  });

export const updateAchievement = (
  courseId,
  moduleId,
  achievementId,
  attributes,
) =>
  instance.patch(
    `/courses/${courseId}/modules/${moduleId}/achievements/${achievementId}`,
    {
      data: { attributes },
    },
  );

export const sortAchievements = (courseId, attributes) =>
  instance.post(`/courses/${courseId}/achievements/sort`, {
    data: { attributes },
  });
