import { Link } from 'react-router-dom';
import { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { castArray } from 'lodash';

import { Menu as MenuIcon } from '@material-ui/icons';

import {
  makeStyles,
  Container,
  Drawer as MuiDrawer,
  Divider,
  ButtonBase,
  IconButton,
} from '@material-ui/core';

import LogoLarge from './logo-large';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
    overflowX: 'hidden',
  },
  logo: {
    height: 48,
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.1,
  },
  container: {
    width: '100%',
    display: 'flex',
    height: 80,
    alignItems: 'center',
    padding: theme.spacing(0, 3.5),
    backgroundColor: 'black',
    color: 'white',
  },
}));

const Drawer = (props) => {
  const { items, onClose, ...rest } = props;
  const styles = useStyles();

  return (
    <MuiDrawer onClose={onClose} {...rest}>
      <div className={styles.list} role="presentation">
        <Container className={styles.container}>
          <IconButton edge="start" color="inherit" onClick={onClose}>
            <MenuIcon />
          </IconButton>
          <ButtonBase className={styles.logo} component={Link} to="/">
            <LogoLarge aria-hidden />
          </ButtonBase>
        </Container>
        {castArray(items).map(
          (items_, index) =>
            items_ && (
              <Fragment key={index}>
                <Divider className={styles.divider} />
                {items_}
              </Fragment>
            ),
        )}
      </div>
    </MuiDrawer>
  );
};

Drawer.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onClose: PropTypes.func,
};

Drawer.whyDidYouRender = true;

export default memo(Drawer);
