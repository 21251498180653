import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { CANCEL_TRIALS_SINGLE_SUCCESS } from './cancel-trials';

const MODULE = 'trials';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchTrials = Exec.requestActionCreator(MODULE);
export const fetchTrialsLoading = Exec.loadingActionCreator(MODULE);
export const fetchTrialsSuccess = Exec.successActionCreator(MODULE);
export const fetchTrialsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);

export const reducer = (state, action) => {
  switch (action.type) {
    case CANCEL_TRIALS_SINGLE_SUCCESS:
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.filter((t) => t.id !== data.id),
      };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectTrialsRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectTrials = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchTrials = function* ({ payload, meta }) {
  try {
    const { data } = yield call(
      Api.getTrials,
      payload && payload.userId ? payload.userId : 'me',
    );

    yield put(fetchTrialsSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchTrialsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchTrials);
