import instance from 'Api/instance';

const { REACT_APP_OAUTH_CLIENT_ID } = process.env;

const getCurrentSourceUrl = () => {
  if (typeof window === 'undefined') {
    return 'https://ecomfreedom.com';
  }

  const queryWhitelist = ['redirectTo'];

  const url = new URL(window.location.href);

  url.searchParams.forEach((value, key) => {
    if (!queryWhitelist.includes(key)) {
      url.searchParams.delete(key);
    }
  });

  return url.href;
};

export const getMe = () => instance.get('/users/me');

export const getUser = (userId) => instance.get('/users/' + userId);

export const getUsers = (searchOptions, limit = 10, offset = 0) =>
  instance.get('/users', { params: { limit, offset, ...searchOptions } });

export const getEventAttendees = (eventId, limit = 10, offset = 0) =>
  instance.get(`/events/${eventId}/attendees`, {
    params: { limit, offset },
  });

export const updateMe = (attributes, recaptchaResponse) =>
  instance.patch(
    '/users/me',
    {
      data: {
        attributes: {
          sourceUrl: getCurrentSourceUrl(),
          ...attributes,
        },
      },
    },
    {
      headers: recaptchaResponse
        ? {
            'X-Recaptcha-Response': recaptchaResponse,
          }
        : undefined,
    },
  );

export const deleteUser = (userId) => instance.delete('/users/' + userId);

export const deleteMeProfilePicture = () =>
  instance.delete('/users/me/profile-picture');

export const deleteUserProfilePicture = (userId) =>
  instance.delete(`/users/${userId}/profile-picture`);

export const updateUser = (userId, attributes) =>
  instance.patch('/users/' + userId, { data: { attributes } });

export const createUser = (attributes) =>
  instance.post('/users', { data: { attributes } });

export const register = (
  firstName,
  lastName,
  email,
  password,
  country,
  phoneNumber,
  recaptchaResponse,
) =>
  instance.post(
    '/users/register',
    {
      data: {
        attributes: {
          sourceUrl: getCurrentSourceUrl(),
          firstName,
          lastName,
          email,
          password,
          country,
          phoneNumber,
        },
      },
    },
    {
      headers: recaptchaResponse
        ? {
            'X-Recaptcha-Response': recaptchaResponse,
          }
        : undefined,
    },
  );

export const resetPassword = (email, recaptchaResponse) =>
  instance.post(
    '/users/reset-password',
    {
      data: {
        attributes: {
          email,
          sourceUrl: getCurrentSourceUrl(),
        },
      },
    },
    {
      headers: recaptchaResponse
        ? {
            'X-Recaptcha-Response': recaptchaResponse,
          }
        : undefined,
    },
  );

export const changePassword = (password, code) =>
  instance.patch('/users/password', {
    data: { attributes: { password, token: code } },
  });

export const confirmEmailChange = (code) =>
  instance.patch('/users/email', {
    data: { attributes: { token: code } },
  });

export const verifyEmail = (code, sessionType = 'session_short') =>
  instance.post('/users/verify-email', {
    data: {
      attributes: {
        token: code,
        clientId: REACT_APP_OAUTH_CLIENT_ID,
        sessionType,
      },
    },
  });

export const suspendUser = (userId, reason, expiresAt) =>
  instance.post('/users/' + userId + '/suspension', {
    data: {
      attributes: {
        reason,
        expiresAt,
      },
    },
  });

export const revokeUserSuspension = (userId, message) =>
  instance.delete(
    '/users/' + userId + '/suspension',
    message ? { data: { data: { attributes: { message } } } } : null,
  );

export const verifyUser = (userId) =>
  instance.post('/users/' + userId + '/verify-email');
