import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { find, noop } from 'lodash';

import { selectLessons, selectUpdateLessonRequest } from 'Redux/selectors';
import { updateLesson } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmDeleteLessonQuestionDialog = (props) => {
  const { courseId, moduleId, lessonId, questionId, title, onClose } = props;
  const lessons = useSelector(selectLessons);
  const { isLoading } = useSelector(selectUpdateLessonRequest);
  const dispatch = useDispatch();

  const lesson = find(lessons, ['id', lessonId]);

  const handleDelete = useCallback(() => {
    dispatch(
      updateLesson(
        {
          courseId,
          moduleId,
          lessonId,
          questions: lesson.questions
            .map((q) => q.id)
            .filter((id) => id !== questionId),
        },
        { isPromise: true },
      ),
    )
      .then(onClose)
      .catch(noop);
  }, [dispatch, onClose, courseId, moduleId, lessonId, questionId, lesson]);

  return (
    <Dialog
      title="Delete Question"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleDelete}>
          Delete
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to delete '{title}'?
      </Typography>
    </Dialog>
  );
};

ConfirmDeleteLessonQuestionDialog.propTypes = {
  courseId: PropTypes.string,
  moduleId: PropTypes.string,
  lessonId: PropTypes.string,
  questionId: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmDeleteLessonQuestionDialog;
