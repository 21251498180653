import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'delete-event';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const DELETE_EVENT_REQUEST = Exec.requestConstantCreator(MODULE);
export const DELETE_EVENT_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const deleteEvent = Exec.requestActionCreator(MODULE);
export const deleteEventLoading = Exec.loadingActionCreator(MODULE);
export const deleteEventSuccess = Exec.successActionCreator(MODULE);
export const deleteEventError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectDeleteEventRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doDeleteEvent = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(DELETE_EVENT_REQUEST));
    yield put(deleteEventLoading());
    const { data } = yield call(Api.deleteEvent, payload.eventId);
    yield put(deleteEventSuccess({ data }, meta));
  } catch (err) {
    yield put(deleteEventError(err, meta));
    yield put(
      enqueueSnackbar(DELETE_EVENT_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail ||
              err.message ||
              'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doDeleteEvent);
