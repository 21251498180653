import { AsYouType } from 'libphonenumber-js/min';
import metadata from 'libphonenumber-js/metadata.min.json';
import Cleave from 'cleave.js';

class AsYouTypeFormatter extends AsYouType {
  constructor(locale) {
    super(locale);
    this.locale = locale;
    const localeMeta = metadata.countries[locale];
    this.countryCode = localeMeta[0];
    this.trunk = localeMeta[5];
  }

  clear() {
    this.reset();
  }

  inputDigit(text) {
    if (text === '+') {
      return '';
    }

    // Some locales have () in their output format
    const rc = this.input(text).replace(/[()]/g, '');

    if (!this.trunk) {
      return rc;
    }

    if (!rc.startsWith(this.trunk) && text !== this.trunk) {
      this.reset();
      this.input(this.trunk);
      this.input(text);
      return text;
    }

    // force cleave.js to validate this number by prepending () to the string
    return '()' + rc.replace(this.trunk, '').trim();
  }
}

Cleave.AsYouTypeFormatter = AsYouTypeFormatter;
