import qs from 'qs';
import instance from 'Api/instance';

export const subscribeToList = ({ firstName, email, listId }) =>
  instance.post(
    '/mailing-lists/subscribe',
    qs.stringify({
      firstName,
      email,
      listId,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
    },
  );
