import instance from 'Api/instance';

export const createSignedUrl = (namespace, contentType) =>
  instance.post('/upload/create-signed-url', {
    data: {
      attributes: {
        namespace,
        contentType,
      },
    },
  });
