import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'record-lesson-progress';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
export const RECORD_LESSON_PROGRESS_SUCCESS = Exec.successConstantCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const recordLessonProgress = Exec.requestActionCreator(MODULE);
export const recordLessonProgressLoading = Exec.loadingActionCreator(MODULE);
export const recordLessonProgressSuccess = Exec.successActionCreator(MODULE);
export const recordLessonProgressError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectRecordLessonProgressRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doRecordLessonProgress = function* ({ payload, meta }) {
  try {
    yield put(recordLessonProgressLoading());
    const { data } = yield call(
      Api.recordLessonProgress,
      payload.courseId,
      payload.moduleId,
      payload.lessonId,
      payload.timestamp,
    );
    yield put(recordLessonProgressSuccess({ data }, meta));
  } catch (err) {
    yield put(recordLessonProgressError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doRecordLessonProgress);
