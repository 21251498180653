import { call, put, select } from 'redux-saga/effects';

import { find, findIndex, get, sortBy } from 'lodash';
import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { selectLessons } from './lessons';

const MODULE = 'sort-lesson-questions';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SORT_LESSON_QUESTIONS_REQUEST = Exec.requestConstantCreator(MODULE);
export const SORT_LESSON_QUESTIONS_SUCCESS =
  Exec.successConstantCreator(MODULE);
export const SORT_LESSON_QUESTIONS_ERROR = Exec.errorConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const sortLessonQuestions = Exec.requestActionCreator(MODULE);
export const sortLessonQuestionsLoading = Exec.loadingActionCreator(MODULE);
export const sortLessonQuestionsSuccess = Exec.successActionCreator(MODULE);
export const sortLessonQuestionsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSortLessonQuestionsRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSortLessonQuestions = function* ({ payload, meta }) {
  const { lessonId, sortConfig } = payload;
  const lessons = yield select(selectLessons);
  const questions = get(find(lessons, ['id', lessonId]), 'questions', []);

  try {
    yield put(closeSnackbar(SORT_LESSON_QUESTIONS_REQUEST));
    yield put(
      sortLessonQuestionsSuccess({
        lessonId,
        data: sortBy(questions, (m) => findIndex(sortConfig, ['id', m.id])),
      }),
    );
    yield put(sortLessonQuestionsLoading());

    const { data } = yield call(Api.sortLessonQuestions, lessonId, {
      sortConfig,
    });

    yield put(sortLessonQuestionsSuccess({ lessonId, data }, meta));
  } catch (err) {
    yield put(
      sortLessonQuestionsError({ ...err, lessonId, data: questions }, meta),
    );
    yield put(
      enqueueSnackbar(SORT_LESSON_QUESTIONS_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSortLessonQuestions);
