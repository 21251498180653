import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useCallback, useEffect } from 'react';
import { find, findIndex, first, get } from 'lodash';
import clsx from 'clsx';

import dynamicImageUrl from 'Util/dynamic-image-url';
import {
  selectCoursesWithGraph,
  selectEvents,
  selectEventsRequest,
  selectLastLesson,
  selectLessonVideo,
} from 'Redux/selectors';
import { fetchEvents, fetchLessonVideo } from 'Redux/actions';
import Calendar from 'Components/shared/calendar';

import {
  ChevronRight as ChevronRightIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  Typography,
  fade,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    ...(props.imageUrl
      ? {
          '&:before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundImage: `url("${props.imageUrl}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
          '&:after': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            opacity: 0.7,
          },
          backgroundColor: theme.palette.grey[100],
        }
      : {}),
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  }),
  container: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(10, 0),
    maxWidth: 1280,
    margin: '0 auto',
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(10, 1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(10, 6),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(10),
    },
  },
  text: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('lg')]: {
      flexBasis: '45%',
      padding: 0,
    },
  },
  moduleMeta: {
    textTransform: 'capitalize',
    marginBottom: theme.spacing(1.5),
  },
  moduleName: {
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2.5),
    ...theme.typography.h4,
    [theme.breakpoints.up('md')]: {
      ...theme.typography.h3,
    },
  },
  calendar: {
    alignSelf: 'center',
    marginTop: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      minWidth: 360,
      marginTop: 0,
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 'unset',
      flexBasis: '43.75%',
    },
  },
  videoPreview: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    '&:before': {
      display: 'block',
      content: '" "',
      width: '100%',
      paddingTop: 'calc(100% * (9 / 16))',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 'unset',
      flexBasis: '60%',
    },
  },
  videoPreviewWrapper: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'black',
    padding: 0,
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'unset',
    },
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  playButton: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    width: 128,
    height: 128,
    borderRadius: 9999,
    backgroundColor: fade(theme.palette.secondary.main, 0.75),
  },
  playIcon: {
    width: 92,
    height: 92,
    color: 'white',
  },
}));

const HeadingSection = () => {
  const { courseSlug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading: isLoadingEvents } = useSelector(selectEventsRequest);

  const lastLesson = useSelector(selectLastLesson);
  const courses = useSelector(selectCoursesWithGraph);
  const events = useSelector(selectEvents);
  const lessonVideo = useSelector(selectLessonVideo);

  const course = find(courses, ['slug', courseSlug]);
  const unlockedModules =
    course && course.modules ? course.modules.filter((m) => !m.isLocked) : [];

  const lesson =
    lastLesson && unlockedModules.find((m) => lastLesson.moduleId === m.id)
      ? lastLesson
      : get(first(unlockedModules), 'lessons.0');
  const module =
    lesson && course ? find(course.modules, ['id', lesson.moduleId]) : null;

  const lessonIndex =
    module && module.lessons
      ? findIndex(module.lessons, (l) => l.id === lesson.id) + 1
      : 1;

  const styles = useStyles({
    imageUrl: course
      ? dynamicImageUrl(course.background, {
          resize: {
            width: window.innerWidth,
            height: 600,
            fit: 'cover',
          },
        })
      : null,
  });

  const [date, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const handleDateChange = useCallback(
    (year, month) => setDate({ year, month }),
    [],
  );

  useEffect(() => {
    const { year, month } = date;
    dispatch(
      fetchEvents({
        courseIdOrMentorSlug: courseSlug,
        fromDate: new Date(year, month, 1),
        toDate: new Date(year, month + 1, 0),
        type: 'mentorship-call',
      }),
    );
  }, [courseSlug, date, dispatch]);

  useEffect(() => {
    if (course && module && lesson && !course.isMentorshipEnabled) {
      dispatch(
        fetchLessonVideo({
          courseId: course.id,
          moduleId: module.id,
          lessonId: lesson.id,
        }),
      );
    }
  }, [dispatch, course, lesson, module]);

  const handleOpenVideo = useCallback(() => {
    navigate(`/learn/${courseSlug}/watch/${module.slug}/${lesson.slug}`);
  }, [courseSlug, lesson, module, navigate]);

  const isXsUp = useMediaQuery((theme) => theme.breakpoints.up('xs'));
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isXlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  const size = isXlUp
    ? 'large'
    : isLgUp
    ? 'medium'
    : isSmUp
    ? 'large'
    : isXsUp
    ? 'medium'
    : 'small';

  if (!module || !lesson) {
    return null;
  }

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.text}>
          <Typography variant="h5" className={styles.moduleMeta}>
            {module.title}: Lesson {lessonIndex}
          </Typography>
          <Typography variant="h3" className={styles.moduleName}>
            {lesson.title}
          </Typography>
          <Button
            endIcon={<ChevronRightIcon />}
            variant="contained"
            color="secondary"
            size="large"
            component={Link}
            to={`/learn/${courseSlug}/watch/${module.slug}/${lesson.slug}`}
            data-testid="lastLesson"
          >
            {lesson === lastLesson ? 'Resume Lesson' : 'Watch Now'}
          </Button>
        </div>
        {course.isMentorshipEnabled ? (
          <Calendar
            button
            loading={isLoadingEvents}
            ButtonProps={{
              'data-testid': 'calendarAction',
              component: Link,
              to: `/mentorship-calls/${courseSlug}/${date.year}/${
                date.month + 1
              }`,
            }}
            className={styles.calendar}
            size={size}
            dates={(events || []).map((e) => e.startsAt)}
            {...date}
            onChange={handleDateChange}
          />
        ) : (
          <div className={clsx(styles.calendar, styles.videoPreview)}>
            <button
              onClick={handleOpenVideo}
              className={styles.videoPreviewWrapper}
            >
              {lessonVideo && lessonVideo.wistiaMetadata ? (
                <img
                  src={lessonVideo.wistiaMetadata.thumbnail_url}
                  alt="preview"
                />
              ) : null}
              <div className={styles.playButton}>
                <PlayArrowIcon className={styles.playIcon} />
              </div>
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default HeadingSection;
