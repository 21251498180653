import { Link } from 'react-router-dom';

import WistiaVideo from 'Components/shared/wistia-video';
import UnderlineButton from 'Components/shared/underline-button';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import Divider from 'Components/shared/divider';
import SearchHeader from 'Components/pages/help/search-header';
// import MentorCard from 'Components/pages/help/mentor-card';
import HeaderNavigation from 'Components/pages/help/header-navigation';
import Cards from 'Components/pages/help/cards';

import { makeStyles, Typography } from '@material-ui/core';

const headingImage = {
  url: 'https://ecomfreedom-public-assets.s3.amazonaws.com/app/help-center-cover.jpg',
  isResizable: true,
};

const tutorialVideo = {
  url: 'https://support.wistia.com/medias/26sk4lmiix',
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingBottom: 0,
    paddingTop: theme.spacing(10.5),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(10),
    },
    flexGrow: 1,
  },
  grid: {
    display: 'grid',
    rowGap: theme.spacing(4) + 'px',
    width: '100%',
    margin: theme.spacing(1, 0, 5),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      width: 'auto',
      columnGap: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      columnGap: theme.spacing(6),
    },
  },
  divider: {
    margin: theme.spacing(2, 0, 4),
  },
  body: {
    maxWidth: 928,
    marginBottom: theme.spacing(2),
  },
  meetYourMentors: {
    marginTop: theme.spacing(10),
  },
  underlineButton: {
    marginBottom: theme.spacing(10),
  },
  howItWorks: {
    marginTop: theme.spacing(10),
  },
  video: {
    width: '100%',
    maxWidth: 928,
    marginBottom: theme.spacing(10),
  },
}));

const HelpMentorshipPage = () => {
  const styles = useStyles();

  const mentors = [
    {
      position: 'Head of Amazon',
      description: 'Million dollar Amazon FBA brand Owner',
      user: {
        firstName: 'Jeff',
        lastName: 'Greenslade',
        image: {
          url: 'https://ecomfreedom-public-assets.s3.amazonaws.com/app/jeff-greenslade.jpg',
          isResizable: true,
        },
      },
    },
    {
      position: 'Head of Shopify',
      description: 'Million dollar Shopify brand Owner',
      user: {
        firstName: 'Josh',
        lastName: 'Bak',
        image: {
          url: 'https://ecomfreedom-public-assets.s3.amazonaws.com/app/josh-bak.jpg',
          isResizable: true,
        },
      },
    },
  ];

  return (
    <PageLoader>
      <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
        <SearchHeader image={headingImage}>
          <HeaderNavigation variant="search" option="mentorship" />
        </SearchHeader>
        <PageContainer component="section" className={styles.root}>
          <Typography variant="h4" align="center">
            About Our Mentorship
          </Typography>
          <Divider className={styles.divider} />
          <Typography className={styles.body} variant="body1" align="center">
            There is no such thing as a great online business course that does
            not include mentorship. That's why our programs have unlimited, 1 on
            1 mentorship with mentors who run their own successful eCommerce
            businesses, are in the trenches, and are growing their businesses
            just like you every day.
          </Typography>
          <Typography className={styles.body} variant="body1" align="center">
            Our mentorship includes unlimited 1 on 1 access via private Facebook
            chat with a successful million dollar eCommerce brand owner.
          </Typography>
          <Typography className={styles.body} variant="body1" align="center">
            You can message them at anytime privately to get answers to your
            questions, get help with checking if a product is good or not,
            seeing if you have enough profit margin for your product, getting
            help with choosing suppliers, etc.
          </Typography>
          <Typography className={styles.body} variant="body1" align="center">
            Any questions at all - they are here for you. They started their
            journey exactly where you are today, and in just a few short years
            have managed to build million dollar brands.
          </Typography>
          <Typography className={styles.body} variant="body1" align="center">
            You can also ask questions publicly in the Facebook Community and
            tag the Head of Shopify or Head of Amazon.
          </Typography>
          {/* 
          <Typography
            className={styles.meetYourMentors}
            variant="h4"
            align="center"
          >
            Meet Your Mentors
          </Typography>
          <Divider className={styles.divider} />

          <div className={styles.grid}>
            {mentors.map((mentor, index) => (
              <MentorCard key={index} {...mentor} />
            ))}
          </div>
          <Typography className={styles.body} variant="body1" align="center">
            <strong>Both mentors actively sell on Amazon and Shopify.</strong>
          </Typography>
          <Typography className={styles.body} variant="body1" align="center">
            They run weekly live Q&amp;A mentorship calls which you can access
            at the following:
          </Typography> */}

          <UnderlineButton
            component={Link}
            to="/mentorship-calls"
            size="large"
            color="primary"
            className={styles.underlineButton}
          >
            Weekly Mentorship Calls
          </UnderlineButton>

          {false && (
            <>
              <Typography
                className={styles.howItWorks}
                variant="h4"
                align="center"
              >
                How The Mentorhip Works
              </Typography>
              <Divider className={styles.divider} />
              <WistiaVideo className={styles.video} video={tutorialVideo} />
            </>
          )}
        </PageContainer>
        <Cards />
      </PageLayout>
    </PageLoader>
  );
};

export default HelpMentorshipPage;
