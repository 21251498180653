import { put, call } from 'redux-saga/effects';
import { get, toNumber } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'affiliate-earnings';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const FETCH_PAGE_SIZE = 5; // this can be dynamic

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
const defaultFetchAffiliateEarnings = Exec.requestActionCreator(MODULE);
export const fetchAffiliateEarnings = (payload, options) => {
  const page = get(payload, 'page', 0);
  return defaultFetchAffiliateEarnings(payload, {
    isAppending: page > 0,
    ...options,
  });
};
export const fetchAffiliateEarningsLoading = Exec.loadingActionCreator(MODULE);
export const fetchAffiliateEarningsSuccess = Exec.successActionCreator(MODULE);
export const fetchAffiliateEarningsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectAffiliateEarningsRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectAffiliateEarnings = Exec.dataSelectorCreator(MODULE);

export const selectAffiliateEarningsRowsPerPage = () => FETCH_PAGE_SIZE;
export const selectAffiliateEarningsTotalCount = (store) =>
  store.affiliateEarnings.pageInfo
    ? store.affiliateEarnings.pageInfo.totalCount
    : 0;

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchAffiliateEarnings = function* ({ payload, meta }) {
  const offset = FETCH_PAGE_SIZE * toNumber(payload ? payload.page : 0);

  try {
    const { data, __meta = {} } = yield call(
      Api.getAffiliateEarnings,
      FETCH_PAGE_SIZE,
      offset,
    );
    yield put(
      fetchAffiliateEarningsSuccess({ data, pageInfo: __meta.pageInfo }, meta),
    );
  } catch (err) {
    yield put(fetchAffiliateEarningsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchAffiliateEarnings);
