import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import clsx from 'clsx';

import {
  selectCourses,
  selectCoursesRequest,
  selectOffers,
  selectOffersRequest,
} from 'Redux/selectors';
import { fetchOffers } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import ConfirmRestoreOfferDialog from 'Dialogs/confirm-restore-offer-dialog';
import PageLoader from 'Components/shared/page-loader';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import ContentPaper from 'Components/pages/admin/content-paper';

import {
  Add as AddIcon,
  Edit as EditIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon,
} from '@material-ui/icons';
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  container: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  status: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  chip: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(-1, 2, -1, 0),
    borderRadius: 70,
    border: '1px solid',
    display: 'inline-flex',
    alignItems: 'center',
  },
  active: {
    background: 'rgba(56, 142, 60, 0.1)',
    borderColor: 'rgba(56, 142, 60, 0.2)',
    color: '#388E3C',
  },
  archived: {
    background: theme.palette.grey[50],
    borderColor: theme.palette.grey[200],
    color: theme.palette.grey[600],
  },
}));

const AdminOffersPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [restoreOfferId, setRestoreOfferId] = useState(null);

  const courses = useSelector(selectCourses);
  const offers = useSelector(selectOffers);

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectOffersRequest,
  );

  useScopedDispatchEffect(() => {
    dispatch(fetchOffers());
  }, [dispatch]);

  const showRestoreOfferDialog = useMemoizedCallback(
    (offerId) => () => setRestoreOfferId(offerId),
    [],
  );
  const hideRestoreOfferDialog = useCallback(() => setRestoreOfferId(null), []);

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <UserDetailsLayout>
          {restoreOfferId ? (
            <ConfirmRestoreOfferDialog
              offerId={restoreOfferId}
              onClose={hideRestoreOfferDialog}
            />
          ) : null}

          <div className={styles.container}>
            <TitleAndAction
              className={styles.titleAndAction}
              title={<Typography variant="h4">Offers Overview</Typography>}
              button={
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  component={Link}
                  to="/admin/offers/create"
                >
                  Create Offer
                </Button>
              }
            />

            <Grid container spacing={1}>
              {!offers.length ? (
                <Typography variant="h6">No offers found</Typography>
              ) : null}

              {(offers || []).map((offer) => {
                const {
                  id,
                  title,
                  isArchived,
                  prices,
                  freeTrialDays,
                  courseId,
                  bonusCourseIds,
                } = offer;
                const courseIds = [courseId, ...bonusCourseIds];
                return (
                  <Grid key={id} item container>
                    <ContentPaper
                      className={styles.paper}
                      containerClassName={styles.content}
                      actions={
                        isArchived ? (
                          <IconButton
                            title="Restore"
                            onClick={showRestoreOfferDialog(id)}
                          >
                            <UnarchiveOutlinedIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            component={Link}
                            to={`/admin/offers/${id}/edit`}
                          >
                            <EditIcon />
                          </IconButton>
                        )
                      }
                    >
                      <Typography variant="h6" className={styles.title}>
                        {title}
                      </Typography>
                      <Typography variant="body1" className={styles.status}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          className={clsx(
                            styles.chip,
                            isArchived ? styles.archived : styles.active,
                          )}
                        >
                          {isArchived ? 'Archived' : 'Active'}
                        </Typography>
                        {(prices ? prices.length : 0) + ' payment options'}
                        {freeTrialDays ? '  •  Free Trial' : ''}
                        {courseIds.length
                          ? '  •  ' +
                            courseIds
                              .map((id) => courses.find((c) => c.id === id))
                              .filter(Boolean)
                              .map((c) => c.code)
                              .join(', ')
                          : ''}
                      </Typography>
                    </ContentPaper>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </UserDetailsLayout>
      )}
    </PageLoader>
  );
};

export default AdminOffersPage;
