import { trimStart } from 'lodash';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';

export const enusreNationalPhoneNumber = (value, locale) => {
  const number = parsePhoneNumberFromString(value, locale);
  return number ? trimStart(number.formatNational(), '0') : value;
};

export const ensureInternationalPhoneNumber = (value, locale) => {
  const number = parsePhoneNumberFromString(value, locale);
  return number ? number.formatInternational() : value;
};
