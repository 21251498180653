import { useRef, useState, useEffect, useCallback } from 'react';

const useHover = (ref, { enterDelay = 0, exitDelay = 0 } = {}, deps = []) => {
  const op = useRef(null);
  const [hovering, setHovering] = useState(false);

  const hoverOp = useCallback(
    (value) => {
      clearTimeout(op.current);

      if ((value && enterDelay) || (!value && exitDelay)) {
        op.current = setTimeout(
          () => {
            setHovering(value);
          },
          value ? enterDelay : exitDelay,
        );
      } else {
        setHovering(value);
      }
    },
    [enterDelay, exitDelay],
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const node = ref.current;

    const on = () => {
      hoverOp(true);
    };

    const off = () => {
      hoverOp(false);
    };

    const outsideOff = (e) => {
      if (node && !node.contains(e.target)) {
        hoverOp(false);
      }
    };

    node.addEventListener('mouseenter', on);
    node.addEventListener('mouseleave', off);
    node.addEventListener('mousemove', on);
    node.addEventListener('focus', on);
    node.addEventListener('blur', off);
    document.addEventListener('mousemove', outsideOff);

    return () => {
      node.removeEventListener('mouseenter', on);
      node.removeEventListener('mouseleave', off);
      node.removeEventListener('mousemove', on);
      node.removeEventListener('focus', on);
      node.removeEventListener('blur', off);
      document.removeEventListener('mousemove', outsideOff);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoverOp, ...deps]);

  return hovering;
};

export default useHover;
