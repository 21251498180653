import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { fetchLessonVideoSuccess } from './lesson-video';

const MODULE = 'update-lesson';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPDATE_LESSON_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPDATE_LESSON_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const updateLesson = Exec.requestActionCreator(MODULE);
export const updateLessonLoading = Exec.loadingActionCreator(MODULE);
export const updateLessonSuccess = Exec.successActionCreator(MODULE);
export const updateLessonError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpdateLessonRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpdateLesson = function* ({ payload, meta }) {
  const { courseId, moduleId, lessonId, ...attributes } = payload;

  try {
    yield put(closeSnackbar(UPDATE_LESSON_REQUEST));
    yield put(updateLessonLoading());
    const { data } = yield call(
      Api.updateLesson,
      courseId,
      moduleId,
      lessonId,
      attributes,
    );

    if ('video' in attributes) {
      if (attributes.video) {
        const { data: lessonVideo } = yield call(
          Api.getLessonVideo,
          courseId,
          moduleId,
          lessonId,
          true,
        );
        yield put(fetchLessonVideoSuccess({ data: lessonVideo }));
      } else {
        yield put(fetchLessonVideoSuccess({ data: null }));
      }
    }

    yield put(updateLessonSuccess({ data }, meta));
  } catch (err) {
    yield put(updateLessonError(err, meta));
    yield put(
      enqueueSnackbar(UPDATE_LESSON_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpdateLesson);
