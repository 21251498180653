import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      height: '6px',
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        height: '6px',
        backgroundColor: 'rgba(0, 0, 0, .05)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, .2)',
      },
    },
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 5),
    },

    '& > :last-child': {
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(2.5),
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(4),
      },
      [theme.breakpoints.up('xl')]: {
        paddingRight: 0,
      },
    },
  },
}));

const Scrollable = (props) => {
  const { className, component: Component, ...rest } = props;
  const styles = useStyles();

  return <Component className={clsx(className, styles.root)} {...rest} />;
};

Scrollable.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
    .isRequired,
};

Scrollable.defaultProps = {
  component: 'div',
};

export default Scrollable;
