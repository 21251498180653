///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SET_PAGE_SUSPENDED = 'set-page-suspended';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const setPageSuspended = (pageSuspended) => {
  return {
    type: SET_PAGE_SUSPENDED,
    payload: {
      pageSuspended,
    },
  };
};

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultState = false;

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_PAGE_SUSPENDED:
      return action.payload.pageSuspended;
    default:
      return state;
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectPageSuspended = (store) => store.pageSuspended;
