import { call, put, select } from 'redux-saga/effects';

import { findIndex, sortBy } from 'lodash';
import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { selectLessons } from './lessons';

const MODULE = 'sort-lessons';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SORT_LESSONS_REQUEST = Exec.requestConstantCreator(MODULE);
export const SORT_LESSONS_SUCCESS = Exec.successConstantCreator(MODULE);
export const SORT_LESSONS_ERROR = Exec.errorConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const sortLessons = Exec.requestActionCreator(MODULE);
export const sortLessonsLoading = Exec.loadingActionCreator(MODULE);
export const sortLessonsSuccess = Exec.successActionCreator(MODULE);
export const sortLessonsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSortLessonsRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSortLessons = function* ({ payload, meta }) {
  const { courseId, sortConfig } = payload;
  const lessons = yield select(selectLessons);

  try {
    yield put(closeSnackbar(SORT_LESSONS_REQUEST));
    yield put(
      sortLessonsSuccess({
        data: sortBy(lessons, (m) => findIndex(sortConfig, ['id', m.id])),
      }),
    );
    yield put(sortLessonsLoading());

    const { data } = yield call(Api.sortLessons, courseId, { sortConfig });

    yield put(sortLessonsSuccess({ data }, meta));
  } catch (err) {
    yield put(sortLessonsError({ ...err, data: lessons }, meta));
    yield put(
      enqueueSnackbar(SORT_LESSONS_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSortLessons);
