import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static propTypes = {
    fallback: PropTypes.element.isRequired,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    // logErrorToThatErrorService(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return React.cloneElement(this.props.fallback, this.state);
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
