import { put, call, select } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { fetchSubscriptionSteps } from './subscription-steps';
import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { setRememberMe, selectRememberMeAsSessionType } from './remember-me';
import { fetchMeSuccess } from './me';
import { fetchAchievementLevels } from './achievement-levels';

const MODULE = 'signin';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SIGNIN_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const signin = Exec.requestActionCreator(MODULE);
export const signinLoading = Exec.loadingActionCreator(MODULE);
export const signinSuccess = Exec.successActionCreator(MODULE);
export const signinError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSigninRequest = Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSignin = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(SIGNIN_REQUEST));
    yield put(signinLoading());
    yield put(setRememberMe(payload.rememberMe || false));
    const sessionType = yield select(selectRememberMeAsSessionType);
    yield call(Api.signin, payload.email, payload.password, sessionType);
    const { data } = yield call(Api.getMe);
    yield put(fetchAchievementLevels());
    yield put(fetchSubscriptionSteps());
    yield put(
      batchActions([
        Exec.resetGlobalFetch(),
        fetchMeSuccess({ data }),
        signinSuccess(null, meta),
      ]),
    );
  } catch (err) {
    yield put(signinError(err, meta));
    yield put(
      enqueueSnackbar(SIGNIN_REQUEST, {
        message:
          err.code === 'ERROR_CODE_EMAIL_PASSWORD_MISMATCH'
            ? 'No such email and password combination found'
            : err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSignin);
