import { useSelector } from 'react-redux';
import { sumBy, max, groupBy, pick, map } from 'lodash';
import ensurePrice from 'Util/ensure-price';

import { selectCourses, selectTransactions } from 'Redux/selectors';
import Scrollable from 'Components/shared/scrollable';

import {
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  scrollable: {
    padding: 0,
    '& > :last-child': {
      paddingRight: 0,
    },
  },
  table: {
    minWidth: 1400,
  },
  firstColumn: {
    height: 54,
    paddingLeft: theme.spacing(3),
  },
  row: {
    '&:last-child > .MuiTableCell-root': {
      borderBottom: 'none !important',
    },
  },
  noData: {
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const FinancialInfoTable = (props) => {
  const { className } = props;
  const styles = useStyles();

  const courses = useSelector(selectCourses);
  const transactions = useSelector(selectTransactions);

  const aggregatedTransactions = transactions
    ? map(
        groupBy(
          transactions.filter((t) => t.status === 'succeeded'),
          'courseId',
        ),
        (group) => ({
          ...pick(group[0], [
            'id',
            'courseId',
            'grossAmount',
            'netAmount',
            'installmentCount',
          ]),
          cashCollected: sumBy(group, 'grossAmount'),
        }),
      )
    : [];

  return (
    <TableContainer component="div" className={className}>
      <Scrollable className={styles.scrollable}>
        <div>
          <Table
            size="small"
            aria-label="detailed table of transactions"
            className={styles.table}
          >
            <TableHead>
              <TableRow>
                <TableCell className={styles.firstColumn}>
                  <Typography variant="subtitle2">Course Purchased</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">
                    Gross Revenue (AGTP)
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Net Revenue</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">
                    Payment Plan (Gross)
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">
                    Payment Plan (Net)
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Cash Collected</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">Balance Owed</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aggregatedTransactions.length
                ? aggregatedTransactions.map((transaction) => {
                    const {
                      id,
                      courseId,
                      grossAmount,
                      netAmount,
                      installmentCount,
                      cashCollected,
                    } = transaction;

                    const grossRevenue = grossAmount * installmentCount;
                    const netRevenue = netAmount * installmentCount;

                    return (
                      <TableRow key={id} className={styles.row} hover>
                        <TableCell className={styles.firstColumn}>
                          {(courses &&
                            courses
                              .filter((c) => c.id === courseId)
                              .map((c) => c.title)[0]) ||
                            'Unknown'}
                        </TableCell>
                        <TableCell>{ensurePrice(grossRevenue)}</TableCell>
                        <TableCell>{ensurePrice(netRevenue)}</TableCell>
                        <TableCell>
                          {installmentCount} x {ensurePrice(grossAmount)}
                        </TableCell>
                        <TableCell>
                          {installmentCount} x {ensurePrice(netAmount)}
                        </TableCell>
                        <TableCell>{ensurePrice(cashCollected)}</TableCell>
                        <TableCell>
                          {ensurePrice(max([0, grossRevenue - cashCollected]))}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </div>
      </Scrollable>
      {!aggregatedTransactions.length ? (
        <Typography
          className={styles.noData}
          align="center"
          variant="subtitle2"
        >
          No data to be shown
        </Typography>
      ) : null}
    </TableContainer>
  );
};

export default FinancialInfoTable;
