import { isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, fade, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 1.25),
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
  },
  icon: {
    fontSize: 12,
    marginRight: theme.spacing(0.75),
  },
  success: {
    backgroundColor: fade(theme.palette.success.main, 0.1),
    color: theme.palette.success.main,
  },
  warning: {
    backgroundColor: fade(theme.palette.warning.main, 0.1),
    color: theme.palette.warning.main,
  },
  error: {
    backgroundColor: fade(theme.palette.error.main, 0.1),
    color: theme.palette.error.main,
  },
}));

const StatusChip = (props) => {
  const { className, variant, icon, children, ...rest } = props;
  const styles = useStyles();

  const renderedIcon = isValidElement(icon)
    ? cloneElement(icon, {
        className: clsx(icon.props.className, styles.icon),
      })
    : icon;

  return (
    <div className={clsx(className, styles.root, styles[variant])} {...rest}>
      {renderedIcon}
      <Typography variant="overline">{children}</Typography>
    </div>
  );
};

StatusChip.propTypes = {
  variant: PropTypes.oneOf(['success', 'warning', 'error']).isRequired,
  icon: PropTypes.element,
};

export default StatusChip;
