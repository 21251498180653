import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Typography, List } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleContainer: (props) => ({
    minHeight: props.subtitle ? 108 : 84,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3.5, 3, 2),
  }),
  subtitle: {
    marginTop: theme.spacing(1),
  },
  list: {
    paddingLeft: theme.spacing(3),
  },
}));

const OptionList = (props) => {
  const { className, title, subtitle, children } = props;
  const styles = useStyles({ subtitle: Boolean(subtitle) });

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.titleContainer}>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            className={styles.subtitle}
          >
            {subtitle}
          </Typography>
        )}
      </div>
      <List disablePadding className={styles.list}>
        {children}
      </List>
    </div>
  );
};

OptionList.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default OptionList;
