import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { castArray } from 'lodash';
import { addHours, isAfter } from 'date-fns';

import {
  selectMe,
  selectMeRequest,
  selectOnboardingPostponedAt,
} from 'Redux/selectors';
import NavigateExternal from 'Components/shared/navigate-external';

const AuthStateGuard = (props) => {
  const { allow, element } = props;

  const me = useSelector(selectMe);
  const onboardingPostponedAt = useSelector(selectOnboardingPostponedAt);
  const { isLoading, error } = useSelector(selectMeRequest);
  const { pathname, search } = useLocation();
  const [params] = useSearchParams();

  const redirectTo = params.get('redirectTo');

  const currentState = me
    ? me.roleId === 'ROLE_ADMIN'
      ? 'admin'
      : 'regular-user'
    : 'guest';
  const allowRegularUserWithAdmin =
    allow === 'regular-user' ? ['admin', 'regular-user'] : castArray(allow);

  const fallbackNavigateTo = me
    ? '/'
    : '/signin?redirectTo=' + pathname + encodeURIComponent(search);

  // Show user onboarding if they haven't onboarded yet
  if (
    me &&
    me.profileCompletionLevel < 1 &&
    !process.env.REACT_APP_DISABLE_ONBOARDING
  ) {
    // Check the cooldown period before showing onboarding again
    if (
      !onboardingPostponedAt ||
      isAfter(new Date(), addHours(onboardingPostponedAt, 12))
    ) {
      if (pathname === '/onboarding') {
        return element;
      }
      return (
        <Navigate
          replace
          to={'/onboarding?redirectTo=' + pathname + encodeURIComponent(search)}
        />
      );
    } else if (pathname === '/onboarding') {
      // In case we are already on the onboarding page, redirect to someplace else
      return <NavigateExternal replace to={redirectTo || fallbackNavigateTo} />;
    }
  }

  if (isLoading || allowRegularUserWithAdmin.includes(currentState)) {
    return element;
  }

  if (
    error &&
    !['ERROR_CODE_AUTH_TOKEN_INVALID', 'ERROR_CODE_UNAUTHORIZED'].includes(
      error.code,
    )
  ) {
    throw error;
  }

  return <NavigateExternal replace to={redirectTo || fallbackNavigateTo} />;
};

AuthStateGuard.propTypes = {
  allow: PropTypes.oneOf(['guest', 'regular-user', 'admin']).isRequired,
  element: PropTypes.element.isRequired,
};

export default memo(AuthStateGuard);
