import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useCallback } from 'react';
import { toNumber, times, capitalize, min } from 'lodash';
import { format } from 'date-fns';
import clsx from 'clsx';

import ensurePrice from 'Util/ensure-price';
import {
  selectCourses,
  selectAffiliateEarnings,
  selectAffiliateEarningsRequest,
  selectAffiliateEarningsRowsPerPage,
  selectAffiliateEarningsTotalCount,
} from 'Redux/selectors';
import { fetchAffiliateEarnings } from 'Redux/actions';
import Scrollable from 'Components/shared/scrollable';
import Highlight from 'Components/shared/highlight';

import { Skeleton } from '@material-ui/lab';
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    borderRadius: 16,
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
  },
  highlight: {
    display: 'inline-block',
    '& > h6': {
      fontWeight: 400,
    },
  },
  name: {
    height: 56,
  },
  scrollable: {
    width: '100%',
    padding: 0,
  },
  tableWrapper: {
    minWidth: 1320,
    borderRadius: theme.shape.borderRadius,
  },
  table: {
    minWidth: 1320,
    '& .MuiTableCell-root': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 200,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '& .MuiTableCell-root:first-child': {
      paddingLeft: theme.spacing(2.5),
    },
    '& .MuiTableCell-root:last-child': {
      paddingRight: theme.spacing(2.5),
    },
  },
  tableBody: {
    '&>:last-child td': {
      borderBottom: 0,
    },
  },
  pagination: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(4),
    },
  },
  noData: {
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Earnings = (props) => {
  const { className } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();

  const match = matchPath('/#/rewards/:page', '/' + hash);
  const currentPage = match ? toNumber(match.params.page) : 0;

  const courses = useSelector(selectCourses);
  const earnings = useSelector(selectAffiliateEarnings);
  const rowsPerPage = useSelector(selectAffiliateEarningsRowsPerPage);
  const totalCount = useSelector(selectAffiliateEarningsTotalCount);

  const expectedCount = totalCount
    ? min([totalCount - rowsPerPage * currentPage, rowsPerPage])
    : 3;

  const { isLoading } = useSelector(selectAffiliateEarningsRequest);

  const handleChangePage = useCallback(
    (event, value) => navigate('#/rewards/' + value),
    [navigate],
  );

  const showSkeletons = isLoading && (!earnings || !earnings.length);
  const earningsOrSkeletons = showSkeletons
    ? times(expectedCount)
    : earnings || [];

  useEffect(() => {
    dispatch(fetchAffiliateEarnings({ page: currentPage }));
  }, [dispatch, currentPage]);

  return (
    <>
      <div className={clsx(className, styles.root)}>
        <TableContainer component="div">
          <Scrollable className={styles.scrollable}>
            <div className={styles.tableWrapper}>
              <Table
                size="small"
                aria-label="table of earnings"
                className={styles.table}
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.name}>
                      <Typography variant="caption" color="textSecondary">
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption" color="textSecondary">
                        Email
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption" color="textSecondary">
                        Product
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption" color="textSecondary">
                        Sign Up Date
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption" color="textSecondary">
                        Commission
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption" color="textSecondary">
                        Paid Out Date
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption" color="textSecondary">
                        Status
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="caption" color="textSecondary">
                        Earnings
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={styles.tableBody}>
                  {earningsOrSkeletons.length
                    ? earningsOrSkeletons.map((earning, index) => {
                        if (showSkeletons) {
                          return (
                            <TableRow key={index}>
                              <TableCell colSpan={10}>
                                <Skeleton variant="rect" height={30} />
                              </TableCell>
                            </TableRow>
                          );
                        }

                        const {
                          id,
                          courseId,
                          status,
                          affiliateBonus,
                          affiliateDiscountType,
                          paidAt,
                          amount,
                          firstName,
                          lastName,
                          email,
                          createdAt,
                        } = earning;

                        const course = courses
                          ? courses.find((c) => c.id === courseId)
                          : null;

                        if (!course) {
                          return null;
                        }

                        const highlightColor =
                          status === 'EARNED'
                            ? 'success'
                            : status === 'IN_REVIEW'
                            ? 'warning'
                            : 'invalid';

                        return (
                          <TableRow key={id}>
                            <TableCell className={styles.name}>
                              {firstName} {lastName}
                            </TableCell>
                            <TableCell align="right">{email}</TableCell>
                            <TableCell align="right">{course.title}</TableCell>
                            <TableCell align="right">
                              {format(new Date(createdAt), 'MM/d/yyyy')}
                            </TableCell>
                            <TableCell align="right">
                              {affiliateDiscountType === 'percent'
                                ? '% '
                                : '$ '}
                              {affiliateBonus}
                            </TableCell>
                            <TableCell align="right">
                              {paidAt
                                ? format(new Date(paidAt), 'MM/d/yyyy')
                                : 'N/A'}
                            </TableCell>
                            <TableCell align="right">
                              <Highlight
                                className={styles.highlight}
                                size="small"
                                color={highlightColor}
                                value={status
                                  .split('_')
                                  .map(capitalize)
                                  .join(' ')}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Highlight
                                className={styles.highlight}
                                size="small"
                                color={highlightColor}
                                value={ensurePrice(amount)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </div>
          </Scrollable>
          {earningsOrSkeletons.length ? (
            <TablePagination
              component="div"
              classes={{ toolbar: styles.pagination }}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              rowsPerPageOptions={[]}
            />
          ) : (
            <Typography
              className={styles.noData}
              align="center"
              variant="subtitle2"
            >
              No earnings to be shown
            </Typography>
          )}
        </TableContainer>
      </div>

      <Box marginTop={3} maxWidth={768}>
        <Typography variant="subtitle2" align="center" color="textSecondary">
          NOTE: Earnings will be shown after referred user's free trial period
          has expired!
        </Typography>
      </Box>
    </>
  );
};

export default Earnings;
