import { useDispatch, useSelector } from 'react-redux';
import { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import dynamicImageUrl from 'Util/dynamic-image-url';
import calculateUserLevel from 'Util/calculate-user-level';
import {
  selectAchievementLevels,
  selectEventAttendees,
  selectEventAttendeesMaxPages,
  selectEventAttendeesRequest,
} from 'Redux/selectors';
import { fetchEventAttendees } from 'Redux/actions';
import FacebookIcon from 'Icons/facebook';
import UserLocation from 'Components/shared/user-location';
import UserAvatar from 'Components/shared/user-avatar';
import UnderlineButton from 'Components/shared/underline-button';
import Dialog from 'Components/shared/dialog';

import { Skeleton } from '@material-ui/lab';
import {
  ArrowBack as ArrowBackIcon,
  ChevronRight as ChevronRightIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  user: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  arrow: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  avatar: {
    marginBottom: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  level: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 2,

    '& img': {
      width: 18,
      height: 18,
      borderRadius: '50%',
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.grey[400],
    },
  },
  bio: {
    marginTop: theme.spacing(2),
  },
  social: {
    marginTop: theme.spacing(3),
  },
  socialButton: {
    margin: theme.spacing(0, 1),
  },
  socialButtonBox: {
    minWidth: 48,
    height: 48,
    padding: 0,
  },
  facebook: {
    color: '#3B5998',
    borderColor: '#3B5998',
  },
  instagram: {
    color: '#BC2A8D',
    borderColor: '#BC2A8D',
  },
  twitter: {
    color: '#1DA1F2',
    borderColor: '#1DA1F2',
  },
  errorOrEmpty: {
    height: 260,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorIcon: {
    width: 40,
    height: 40,
    marginBottom: theme.spacing(1.5),
    color: theme.palette.error.light,
  },
  errorTitle: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  errorText: {
    maxWidth: 320,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    height: 56,
  },
  socials: {
    width: 126,
  },
  spacing: {
    marginRight: 6,
  },
  showMore: {
    display: 'block',
    margin: theme.spacing(2, 'auto'),
  },
}));

const AttendeeListDialog = (props) => {
  const { eventId, onClose } = props;

  const pageRef = useRef(0);
  const styles = useStyles();
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();
  const attendees = useSelector(selectEventAttendees);
  const maxPages = useSelector(selectEventAttendeesMaxPages);
  const levels = useSelector(selectAchievementLevels);
  const { isLoading, error } = useSelector(selectEventAttendeesRequest);

  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const hasSocial = user
    ? user.facebook || user.instagram || user.twitter
    : false;

  const level =
    user && levels ? calculateUserLevel(user.experience, levels) : null;

  useEffect(() => {
    dispatch(fetchEventAttendees({ eventId, page: 0 }));
  }, [dispatch, eventId]);

  const handleShowMore = useCallback(() => {
    pageRef.current++;
    dispatch(fetchEventAttendees({ eventId, page: pageRef.current }));
  }, [dispatch, eventId]);

  const handleOpenUser = useCallback(
    (eventOrUndefined) => {
      if (eventOrUndefined) {
        const value = eventOrUndefined.currentTarget.getAttribute('data-value');
        setUser(value ? attendees[value] : null);
      } else {
        setUser(null);
      }
    },
    [attendees],
  );

  return (
    <Dialog title={user ? null : 'Attendees'} onClose={onClose}>
      {user ? (
        <div className={styles.user}>
          <IconButton className={styles.arrow} onClick={handleOpenUser}>
            <ArrowBackIcon />
          </IconButton>
          <UserAvatar className={styles.avatar} user={user} size={68} />
          <Typography className={styles.title} variant="h6" align="center">
            {user.firstName + ' ' + user.lastName}
          </Typography>
          {level && (
            <div className={styles.level}>
              <img
                alt="graphic"
                src={dynamicImageUrl(level.graphic, {
                  resize: {
                    width: 18,
                    height: 18,
                    fit: 'cover',
                  },
                })}
              />
              <Typography variant="subtitle2" align="center">
                {level.title}
              </Typography>
            </div>
          )}
          <UserLocation variant="body2" align="center" user={user} />
          {user.bio && (
            <Typography className={styles.bio} variant="body1" align="center">
              {user.bio}
            </Typography>
          )}
          {hasSocial && (
            <div className={styles.social}>
              {user.facebook && (
                <Button
                  variant="outlined"
                  className={clsx(
                    styles.facebook,
                    styles.socialButton,
                    !isSmUp && styles.socialButtonBox,
                  )}
                  startIcon={isSmUp ? <FacebookIcon /> : null}
                  component="a"
                  href={user.facebook.profileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {isSmUp ? 'Facebook' : <FacebookIcon />}
                </Button>
              )}
              {user.instagram && (
                <Button
                  variant="outlined"
                  className={clsx(
                    styles.instagram,
                    styles.socialButton,
                    !isSmUp && styles.socialButtonBox,
                  )}
                  startIcon={isSmUp ? <InstagramIcon /> : null}
                  component="a"
                  href={'https://www.instagram.com/' + user.instagram.username}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {isSmUp ? 'Instagram' : <InstagramIcon />}
                </Button>
              )}
              {user.twitter && (
                <Button
                  variant="outlined"
                  className={clsx(
                    styles.twitter,
                    styles.socialButton,
                    !isSmUp && styles.socialButtonBox,
                  )}
                  startIcon={isSmUp ? <TwitterIcon /> : null}
                  component="a"
                  href={'https://twitter.com/' + user.twitter.username}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {isSmUp ? 'Twitter' : <TwitterIcon />}
                </Button>
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          {error ? (
            <div className={styles.errorOrEmpty}>
              <ErrorIcon className={styles.errorIcon} />
              <Typography
                variant="subtitle1"
                align="center"
                className={styles.errorTitle}
              >
                Error!
              </Typography>
              <Typography
                variant="body2"
                align="center"
                color="textSecondary"
                className={styles.errorText}
              >
                {error.detail || error.message}
              </Typography>
            </div>
          ) : (
            <List disablePadding>
              {attendees && attendees.length
                ? attendees.map((user, index) => (
                    <ListItem key={user.id} className={styles.listItem}>
                      <ListItemAvatar className={styles.listItemIcon}>
                        <UserAvatar size={40} user={user} />
                      </ListItemAvatar>
                      <ListItemText>
                        <Typography variant="subtitle1">
                          {user.firstName + ' ' + user.lastName}
                        </Typography>
                      </ListItemText>
                      {isSmUp && (
                        <div className={styles.socials}>
                          {user.facebook && (
                            <IconButton
                              size="small"
                              className={clsx(styles.facebook, styles.spacing)}
                              component="a"
                              href={user.facebook.profileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FacebookIcon fontSize="small" />
                            </IconButton>
                          )}
                          {user.instagram && (
                            <IconButton
                              size="small"
                              className={clsx(styles.instagram, styles.spacing)}
                              component="a"
                              href={
                                'https://www.instagram.com/' +
                                user.instagram.username
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <InstagramIcon fontSize="small" />
                            </IconButton>
                          )}
                          {user.twitter && (
                            <IconButton
                              size="small"
                              className={styles.twitter}
                              component="a"
                              href={
                                'https://twitter.com/' + user.twitter.username
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TwitterIcon fontSize="small" />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <ListItemSecondaryAction>
                        <IconButton
                          data-value={index}
                          edge="end"
                          onClick={handleOpenUser}
                        >
                          <ChevronRightIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                : !isLoading && (
                    <div className={styles.errorOrEmpty}>
                      <Typography variant="h5" align="center">
                        Nobody joined this event
                      </Typography>
                    </div>
                  )}
              {isLoading ? (
                [1, 2, 3].map((index) => (
                  <ListItem key={index} className={styles.listItem}>
                    <Skeleton variant="rect" height="100%" width="100%" />
                  </ListItem>
                ))
              ) : maxPages > pageRef.current ? (
                <UnderlineButton
                  className={styles.showMore}
                  onClick={handleShowMore}
                >
                  Show More
                </UnderlineButton>
              ) : null}
            </List>
          )}
        </>
      )}
    </Dialog>
  );
};

AttendeeListDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  courseId: PropTypes.string,
  eventId: PropTypes.string,
};

export default AttendeeListDialog;
