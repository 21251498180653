const languages = [
  { name: 'English', code: 'ENG' },
  { name: 'Mandarin Chinese', code: 'CHI' },
  { name: 'Hindi', code: 'HIN' },
  { name: 'Spanish', code: 'SPA' },
  { name: 'French', code: 'FRE' },
  { name: 'Arabic', code: 'ARA' },
  { name: 'Bengali', code: 'BEN' },
  { name: 'Russian', code: 'RUS' },
  { name: 'Portuguese', code: 'POR' },
  { name: 'Indonesian', code: 'IND' },
  { name: 'Italian', code: 'ITA' },
  { name: 'German', code: 'GER' },
  { name: 'Other', code: 'OTHER' },
];

export default languages;
