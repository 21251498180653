import { Link } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import PropTypes from 'prop-types';

import ConfirmDeleteLessonResourceDialog from 'Dialogs/confirm-delete-lesson-resource-dialog';
import { Edit as EditIcon, Close as CloseIcon } from '@material-ui/icons';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

import ContentPaper from './content-paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
  },
}));

const ResourceItem = (props) => {
  const { id, courseId, moduleId, lessonId, title } = props;
  const styles = useStyles();

  const { active, attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: active && active.id === props.id ? 10 : undefined,
  };

  const [
    showConfirmDeleteLessonResourceModal,
    hideConfirmDeleteLessonResourceModal,
  ] = useModal(
    () => (
      <ConfirmDeleteLessonResourceDialog
        courseId={courseId}
        moduleId={moduleId}
        lessonId={lessonId}
        resourceId={id}
        title={title}
        onClose={hideConfirmDeleteLessonResourceModal}
      />
    ),
    [courseId, moduleId, lessonId, id],
  );

  return (
    <ContentPaper
      style={style}
      ref={setNodeRef}
      className={styles.paper}
      actions={
        <div>
          <IconButton
            component={Link}
            to={`/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/resources/${id}/edit`}
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={showConfirmDeleteLessonResourceModal}>
            <CloseIcon />
          </IconButton>
        </div>
      }
      {...listeners}
      {...attributes}
    >
      <Typography variant="h6">{title}</Typography>
    </ContentPaper>
  );
};

ResourceItem.propTypes = {
  id: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
  lessonId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ResourceItem;
