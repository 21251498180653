import { Link } from 'react-router-dom';
import Dotdotdot from 'react-dotdotdot';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Skeleton } from '@material-ui/lab';
import {
  ChevronRight as ChevronRightIcon,
  Launch as LaunchIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Typography,
  ButtonBase,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    transition: 'box-shadow 0.2s ease-in-out',
    padding: theme.spacing(2.5, 9.5, 3.5, 2.5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2.5, 10.5, 3.5, 3),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3.5, 12.5, 3.5, 4),
    },
    '&:hover': {
      boxShadow: theme.shadows[4],
      '& > $icon': {
        opacity: 1,
      },
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    position: 'absolute',
    justifySelf: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    right: 20,
    [theme.breakpoints.up('sm')]: {
      right: 28,
    },
    [theme.breakpoints.up('lg')]: {
      right: 48,
      opacity: 0,
    },
  },
}));

const SearchResult = (props) => {
  const { className, skeleton, title, description, href, target } = props;
  const styles = useStyles();
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  if (skeleton) {
    return (
      <Skeleton
        variant="rect"
        height={104}
        className={clsx(className, styles.root)}
      />
    );
  }

  const component = target === 'open' ? 'a' : Link;
  const componentProps =
    target === 'open'
      ? { href, rel: 'noopener noreferrer', target: '_blank' }
      : { to: href };

  const Icon = target === 'open' ? LaunchIcon : ChevronRightIcon;

  return (
    <ButtonBase
      className={clsx(className, styles.root)}
      component={component}
      {...componentProps}
    >
      <Dotdotdot className={styles.title} clamp={3} useNativeClamp>
        <Typography variant="h6">{title}</Typography>
      </Dotdotdot>
      <Dotdotdot clamp={isSmUp ? 1 : 2} useNativeClamp>
        <Typography variant="body1">{description}</Typography>
      </Dotdotdot>
      <Icon className={styles.icon} />
    </ButtonBase>
  );
};

SearchResult.propTypes = {
  skeleton: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.oneOf(['open', 'navigate']),
};

export default SearchResult;
