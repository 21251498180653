import { put, take, call, race } from 'redux-saga/effects';
import { get } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import {
  uploadFile,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_CANCEL,
  UPLOAD_FILE_ERROR,
} from './upload-file';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { fetchEventVideoSuccess } from './event-video';

const MODULE = 'upload-event-recording';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPLOAD_EVENT_RECORDING_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPLOAD_EVENT_RECORDING_SUCCESS =
  Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const uploadEventRecording = Exec.requestActionCreator(MODULE);
export const uploadEventRecordingLoading = Exec.loadingActionCreator(MODULE);
export const uploadEventRecordingSuccess = Exec.successActionCreator(MODULE);
export const uploadEventRecordingError = Exec.errorActionCreator(MODULE);
export const uploadEventRecordingReset = Exec.resetActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUploadEventRecordingRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUploadEventRecording = function* ({ payload, meta }) {
  const { eventId, file } = payload || {};

  try {
    yield put(closeSnackbar(UPLOAD_EVENT_RECORDING_REQUEST));

    yield put(uploadEventRecordingLoading());
    yield put(
      uploadFile({
        method: 'PUT',
        url: '/upload/wistia-video?fileName=' + file.name,
        data: file,
        headers: {
          'Content-Type': file.type,
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        axios: Api.axios,
      }),
    );
    const [success, cancel, error] = yield race([
      take(UPLOAD_FILE_SUCCESS),
      take(UPLOAD_FILE_CANCEL),
      take(UPLOAD_FILE_ERROR),
    ]);

    if (error) {
      throw error.payload.error;
    }

    if (cancel) {
      return;
    }

    const wistiaVideoUrl = get(success, 'payload.data.url');
    const { data } = yield call(Api.updateEvent, eventId, {
      recording: {
        url: wistiaVideoUrl,
        fileName: file.name,
      },
    });

    const { data: eventVideo } = yield call(Api.getEventVideo, eventId, true);
    yield put(fetchEventVideoSuccess({ data: eventVideo }));

    yield put(uploadEventRecordingSuccess({ data }, meta));
  } catch (err) {
    yield put(uploadEventRecordingError(err, meta));

    yield put(
      enqueueSnackbar(UPLOAD_EVENT_RECORDING_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUploadEventRecording);
