import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectSubscribeToListRequest } from 'Redux/selectors';
import { subscribeToList } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const { REACT_APP_TRUSTPILOT_UNLOCK_LIST_ID } = process.env;

const TrustpilotUnlockDialog = (props) => {
  const { title, onClose } = props;

  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectSubscribeToListRequest);

  const handleSendInstructions = useCallback(() => {
    dispatch(
      subscribeToList(
        { listId: REACT_APP_TRUSTPILOT_UNLOCK_LIST_ID },
        { isPromise: true },
      ),
    )
      .then(() => setSuccess(true))
      .catch(noop);
  }, [dispatch]);

  return (
    <Dialog
      title="Unlock Course"
      loading={isLoading}
      onClose={onClose}
      cancelButton={success ? null : <Button data-testid="cancel">No</Button>}
      submitButton={
        success ? (
          <Button data-testid="submit" onClick={onClose}>
            Close
          </Button>
        ) : (
          <Button data-testid="submit" onClick={handleSendInstructions}>
            Yes!
          </Button>
        )
      }
    >
      <Typography variant="body1" align="center">
        {success
          ? 'Great! We have sent you an email with instructions on how to access the course!'
          : `Would you like to access the ${title} Course?`}
      </Typography>
    </Dialog>
  );
};

TrustpilotUnlockDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TrustpilotUnlockDialog;
