import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
  Typography,
} from '@material-ui/core';

const Breadcrumbs = (props) => {
  const { links, ...rest } = props;

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      {...rest}
    >
      {links.map((link, index) =>
        link.href ? (
          <MuiLink key={index} color="inherit" component={Link} to={link.href}>
            <Typography variant="body2">{link.label}</Typography>
          </MuiLink>
        ) : (
          <Typography key={index} variant="body2" color="textPrimary">
            {link.label}
          </Typography>
        ),
      )}
    </MuiBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Breadcrumbs;
