import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const FileUploadIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 33 32" {...props}>
      <path
        d="M10.368 13.334h2.12V20c0 .733.6 1.334 1.333 1.334h5.334c.733 0 1.333-.6 1.333-1.334v-6.666h2.12c1.187 0 1.787-1.44.947-2.28l-6.12-6.12c-.52-.52-1.36-.52-1.88 0l-6.12 6.12c-.84.84-.254 2.28.933 2.28zm-3.2 12c0 .733.6 1.333 1.333 1.333h16c.734 0 1.334-.6 1.334-1.334 0-.733-.6-1.333-1.334-1.333h-16c-.733 0-1.333.6-1.333 1.334z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default FileUploadIcon;
