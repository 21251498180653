const { REACT_APP_API_URL } = process.env;

const exportUsersUrl = (params) => {
  const { userId, ...otherParams } = params;

  const url = userId
    ? new URL(`/api/v1/users/${userId}/export`, REACT_APP_API_URL)
    : new URL('/api/v1/users/export', REACT_APP_API_URL);

  for (const param in otherParams) {
    if (otherParams.hasOwnProperty(param) && otherParams[param]) {
      url.searchParams.set(param, otherParams[param]);
    }
  }

  return url.href;
};

export default exportUsersUrl;
