import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { map, find, orderBy } from 'lodash';

import {
  selectCourses,
  selectCoursesRequest,
  selectMeRequest,
  selectSubscriptionSteps,
  selectSubscriptionStepsRequest,
} from 'Redux/selectors';
import { fetchCourses, fetchSubscriptionSteps } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import DrawerAccountItems from 'Components/shared/drawer-account-items';
import SubscriptionSteps from 'Components/pages/account/subscription-steps';

import { makeStyles, useMediaQuery, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  drawer: {
    position: 'fixed',
    marginTop: 1, // divider offset
    width: 280,
  },
  title: {
    marginBottom: theme.spacing(7),
  },
  group: {
    marginBottom: theme.spacing(5),
  },
  groupEmpty: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(3.5),
  },
}));

const SubscriptionsPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { isLoading, error } = useCombinedRequestsSelector(
    selectMeRequest,
    selectCoursesRequest,
    selectSubscriptionStepsRequest,
  );
  const courses = useSelector(selectCourses);
  const subscriptionSteps = useSelector(selectSubscriptionSteps);

  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const subscriptionStepsWithCourses = useMemo(
    () =>
      orderBy(
        map(subscriptionSteps, (s) => ({
          ...s,
          course: find(courses, ['id', s.courseId]),
        })).filter((s) => s.course),
        ['steps.length'],
        ['desc'],
      ),
    [courses, subscriptionSteps],
  );

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchSubscriptionSteps());
  }, [dispatch]);

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout
          headerItems={<HeaderItems />}
          drawerItems={[<DrawerAccountItems />, <DrawerItems />]}
        >
          <div className={styles.root}>
            {isLgUp && <DrawerAccountItems className={styles.drawer} />}
            <PageContainer sidebarWidth={280}>
              <Typography variant="h4" className={styles.title}>
                Subscriptions
              </Typography>

              {subscriptionStepsWithCourses.length === 0 && (
                <Typography className={styles.groupEmpty} color="textSecondary">
                  No subscriptions to show.
                </Typography>
              )}

              {subscriptionStepsWithCourses.map((s) => (
                <SubscriptionSteps key={s.id} className={styles.group} {...s} />
              ))}
            </PageContainer>
          </div>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default SubscriptionsPage;
