import { isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    minWidth: 240,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  upper: {
    padding: theme.spacing(4.5, 3, 3.5),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 48,
  },
  button: {
    width: '62%',
  },
}));

const Card = (props) => {
  const { className, title, body, button } = props;
  const styles = useStyles();

  const renderedButton = isValidElement(button)
    ? cloneElement(button, {
        className: clsx(button.props.className, styles.button),
      })
    : button;

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.upper}>
        <Typography variant="h5" className={styles.title} align="center">
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center">
          {body}
        </Typography>
      </div>
      <div className={styles.buttonContainer}>{renderedButton}</div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  button: PropTypes.element.isRequired,
};

export default Card;
