import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar } from './snackbar';

const MODULE = 'upgrade-course-trial';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPGRADE_COURSE_TRIAL_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const upgradeCourseTrial = Exec.requestActionCreator(MODULE);
export const upgradeCourseTrialLoading = Exec.loadingActionCreator(MODULE);
export const upgradeCourseTrialSuccess = Exec.successActionCreator(MODULE);
export const upgradeCourseTrialError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpgradeCourseTrialRequest =
  Exec.mutateRequestSelectorCreator(MODULE);
export const selectUpgradeCourseTrial = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpgradeCourseTrial = function* ({ payload, meta }) {
  try {
    yield put(upgradeCourseTrialLoading());

    const { data } = yield call(Api.upgradeCourseTrial, payload.courseId);

    yield put(upgradeCourseTrialSuccess({ data }, meta));
  } catch (err) {
    yield put(upgradeCourseTrialError(err, meta));
    yield put(
      enqueueSnackbar(UPGRADE_COURSE_TRIAL_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpgradeCourseTrial);
