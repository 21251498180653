import { formatISO, parseISO } from 'date-fns';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SET_ONBOARDING_POSTPONED_AT = 'set-onboarding-postponed-at';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const setOnboardingPostponedAt = (onboardingPostponedAt) => {
  return {
    type: SET_ONBOARDING_POSTPONED_AT,
    payload: {
      onboardingPostponedAt,
    },
  };
};

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultState = localStorage.getItem('onboarding-postponed-at');

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ONBOARDING_POSTPONED_AT:
      const value = action.payload.onboardingPostponedAt;
      if (value) {
        localStorage.setItem('onboarding-postponed-at', formatISO(value));
      } else {
        localStorage.removeItem('onboarding-postponed-at');
      }
      return value;
    default:
      return state;
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectOnboardingPostponedAt = (store) => {
  try {
    return store.onboardingPostponedAt
      ? parseISO(store.onboardingPostponedAt)
      : null;
  } catch (err) {
    return null;
  }
};
