import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'update-payment-link';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPDATE_PAYMENT_LINK_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPDATE_PAYMENT_LINK_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const updatePaymentLink = Exec.requestActionCreator(MODULE);
export const updatePaymentLinkLoading = Exec.loadingActionCreator(MODULE);
export const updatePaymentLinkSuccess = Exec.successActionCreator(MODULE);
export const updatePaymentLinkError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpdatePaymentLinkRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpdatePaymentLink = function* ({ payload, meta }) {
  const { paymentLinkId, ...attributes } = payload;

  try {
    yield put(closeSnackbar(UPDATE_PAYMENT_LINK_REQUEST));
    yield put(updatePaymentLinkLoading());
    const { data } = yield call(
      Api.updatePaymentLink,
      paymentLinkId,
      attributes,
    );
    yield put(updatePaymentLinkSuccess({ data }, meta));
  } catch (err) {
    yield put(updatePaymentLinkError(err, meta));
    yield put(
      enqueueSnackbar(UPDATE_PAYMENT_LINK_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpdatePaymentLink);
