import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import {
  selectUploadFileRequest,
  selectUploadLessonBoxsetPdfRequest,
} from 'Redux/selectors';
import {
  uploadFileCancel,
  uploadLessonBoxsetPdf,
  uploadLessonBoxsetPdfReset,
} from 'Redux/actions';
import UploadDropzone from 'Components/shared/upload-dropzone';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    marginTop: theme.spacing(3),
  },
}));

const UploadLessonBoxsetPdfDialog = (props) => {
  const { courseId, moduleId, lessonId, onClose } = props;
  const [fileRejections, setFileRejections] = useState([]);
  const [helperText, setHelperText] = useState(null);

  const styles = useStyles();
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(selectUploadLessonBoxsetPdfRequest);
  const { progress } = useSelector(selectUploadFileRequest);
  useEffect(() => () => dispatch(uploadLessonBoxsetPdfReset()), [dispatch]);

  const handleDrop = useCallback(
    (accepted, rejections) => {
      setHelperText(null);

      if (rejections.length) {
        setFileRejections(rejections);
      } else {
        dispatch(
          uploadLessonBoxsetPdf(
            { courseId, moduleId, lessonId, file: accepted[0] },
            { isPromise: true },
          ),
        )
          .then(onClose)
          .catch(noop);
      }
    },
    [courseId, lessonId, moduleId, onClose, dispatch],
  );

  const handleReset = useCallback(() => {
    setFileRejections([]);
    dispatch(uploadLessonBoxsetPdfReset());
  }, [dispatch]);

  const handleSetHelperText = useCallback(
    () => setHelperText('You have to pick a PDF.'),
    [],
  );

  const handleCancel = useCallback(() => {
    dispatch(uploadFileCancel());
    onClose();
  }, [dispatch, onClose]);

  const hasError = Boolean(error) || fileRejections.length;

  return (
    <Dialog
      interruptible
      title="Upload Boxset PDF"
      loading={isLoading}
      onClose={onClose}
      cancelButton={
        <Button data-testid="cancel" onClick={handleCancel}>
          Cancel
        </Button>
      }
      submitButton={
        <Button
          data-testid="submit"
          onClick={hasError ? handleReset : handleSetHelperText}
        >
          {hasError ? 'Retry' : 'Change'}
        </Button>
      }
    >
      <UploadDropzone
        className={styles.dropzone}
        loading={isLoading}
        progress={progress}
        DropzoneProps={{
          onDrop: handleDrop,
          accept: ['application/pdf'],
          multiple: false,
        }}
        errorText={
          hasError
            ? error && error.code === 'ERROR_CODE_NETWORK_ERROR'
              ? 'Please check your internet connection.'
              : error.detail || error.title || error.message
            : null
        }
        helperText={helperText}
      />
    </Dialog>
  );
};

UploadLessonBoxsetPdfDialog.propTypes = {
  courseId: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
  lessonId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UploadLessonBoxsetPdfDialog;
