import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { makeStyles, Typography, ListItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    minHeight: 56,
    padding: theme.spacing(1, 3, 1, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3, 2, 0),
    },
  },
  titleContiner: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    maxWidth: 'calc(100% - 32px)',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      width: 208,
    },
  },
  textValue: {
    width: '100%',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  date: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
}));

const Transaction = (props) => {
  const { className, transaction, ...rest } = props;
  const styles = useStyles();

  const title =
    transaction.status === 'succeeded'
      ? 'payment'
      : transaction.status === 'refundeded'
      ? 'refund'
      : 'failed';

  return (
    <ListItem className={styles.root} disableGutters {...rest}>
      <div className={styles.titleContiner}>
        <Typography
          variant="overline"
          color="textSecondary"
          className={styles.title}
        >
          {title}
        </Typography>
        <div>
          <Typography variant="subtitle2" className={styles.textValue}>
            {transaction.course ? transaction.course.title : 'Unkown Product'}
          </Typography>
          <Typography variant="caption" className={styles.date}>
            {format(new Date(transaction.createdAt), 'M/d/yyyy')}
          </Typography>
        </div>
      </div>
    </ListItem>
  );
};

Transaction.propTypes = {
  transaction: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    course: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  }),
};

export default Transaction;
