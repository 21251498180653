import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { get, find, findIndex } from 'lodash';

import {
  selectCoursesRequest,
  selectCoursesWithGraph,
  selectLessonsRequest,
  selectModulesRequest,
} from 'Redux/selectors';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import {
  makeStyles,
  withStyles,
  List,
  Typography,
  Paper,
  AccordionDetails,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core';

import ModuleListItem from './module-list-item';

const Accordion = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    '&$expanded': {
      margin: 'auto',
      [theme.breakpoints.up('xl')]: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    '&:before': {
      display: 'none',
    },
  },
  rounded: {
    borderRadius: '0 !important',
    '&:last-child': {
      [theme.breakpoints.up('sm')]: {
        borderBottomLeftRadius: theme.shape.borderRadius + 'px !important',
        borderBottomRightRadius: theme.shape.borderRadius + 'px !important',
      },
    },
    '&:first-child': {
      [theme.breakpoints.up('sm')]: {
        borderTopLeftRadius: theme.shape.borderRadius + 'px !important',
        borderTopRightRadius: theme.shape.borderRadius + 'px !important',
      },
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 1),
    backgroundColor: theme.palette.grey[50],
  },
  expanded: {
    backgroundColor: 'white',
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
}))(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    minHeight: '100%',
    borderRadius: 0,
    [theme.breakpoints.up('sm')]: {
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1],
    },
    [theme.breakpoints.up('xl')]: {
      borderRadius: 0,
    },
  },
  details: {
    padding: 0,
  },
  text: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 72,
  },
  moduleTitle: {
    marginBottom: theme.spacing(1),
  },
  list: {
    width: '100%',
  },
}));

const ModuleSwitcher = () => {
  const { courseSlug, moduleSlug, lessonSlug } = useParams();
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const styles = useStyles();

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectModulesRequest,
    selectLessonsRequest,
  );

  const courses = useSelector(selectCoursesWithGraph);

  const course = find(courses, ['slug', courseSlug]);
  const defaultExpanded = course
    ? findIndex(course.modules, ['slug', moduleSlug])
    : -1;

  const handleChange = useMemoizedCallback(
    (index) => (event, expanded) => {
      if (expandedIndex !== index) {
        setExpandedIndex(expanded ? index : false);
      }
    },
    [expandedIndex],
  );

  if (error || (!isLoading && !course)) {
    return null;
  }

  return (
    <Paper className={styles.root}>
      {course.modules.map((module, index) => (
        <Accordion
          key={index}
          expanded={
            (expandedIndex === -1 ? defaultExpanded : expandedIndex) === index
          }
          onChange={handleChange(index)}
          data-testid={`expandModule-${index}`}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={styles.text}>
              <Typography className={styles.moduleTitle} variant="subtitle1">
                {module.title} - {module.summary}
              </Typography>
              <Typography variant="body2">
                {module.lessons ? module.lessons.length : 'No'} Lessons
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={styles.details}>
            <List
              data-testid={`module-${index}`}
              className={styles.list}
              disablePadding
            >
              {module.lessons
                ? module.lessons.map((lesson, index_) => (
                    <ModuleListItem
                      key={index_}
                      active={
                        moduleSlug === module.slug && lessonSlug === lesson.slug
                      }
                      completed={get(lesson, 'progress.isCompleted', false)}
                      locked={module.isLocked}
                      title={lesson.title}
                      duration={lesson.duration}
                      href={`/learn/${course.slug}/watch/${module.slug}/${lesson.slug}`}
                      data-testid={`lesson-${index_}`}
                    />
                  ))
                : null}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Paper>
  );
};

export default ModuleSwitcher;
