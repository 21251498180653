import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { get, noop } from 'lodash';
import { addHours } from 'date-fns';

import {
  selectCoursesRequest,
  selectOffers,
  selectOffersRequest,
  selectPaymentLink,
  selectPaymentLinkRequest,
  selectUpdatePaymentLinkRequest,
  selectCreatePaymentLinkRequest,
} from 'Redux/selectors';
import {
  enqueueSnackbar,
  closeSnackbar,
  fetchCourses,
  fetchOffers,
  fetchPaymentLinkReset,
  fetchPaymentLink,
  updatePaymentLink,
  createPaymentLink,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import ConfirmDeletePaymentLinkDialog from 'Dialogs/confirm-delete-payment-link-dialog';
import Select from 'Components/shared/select';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import DateTimePicker from 'Components/shared/date-time-picker';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import {
  Save as SaveIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import { Grid, Button, makeStyles, Typography } from '@material-ui/core';

const schema = yup.object().shape({
  name: yup.string().required('Field Required.'),
  email: yup.string().email('Invalid Email.').required('Field Required.'),
  offerId: yup
    .string()
    .default(null)
    .nullable()
    .transform((value) => (!value ? null : value)),
  affiliateId: yup
    .string()
    .default(null)
    .nullable()
    .transform((value) => (!value ? null : value)),
  expiresAt: yup
    .date()
    .min(new Date(), 'Must be in the future')
    .required('Field Required.'),
});

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  input: {
    width: '100%',
  },
  buttons: {
    paddingTop: theme.spacing(3) + 'px !important',
  },
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

const AdminCreateOrUpdatePaymentLinkPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { paymentLinkId } = useParams();

  const offers = useSelector(selectOffers);
  const paymentLink = useSelector(selectPaymentLink);

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectOffersRequest,
  );
  const { isLoading: isLoadingPaymentLink, error: errorPaymentLink } =
    useSelector(selectPaymentLinkRequest);

  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectUpdatePaymentLinkRequest,
    selectCreatePaymentLinkRequest,
  );

  useEffect(() => () => dispatch(fetchPaymentLinkReset()), [dispatch]);

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchOffers());

    if (paymentLinkId) {
      dispatch(fetchPaymentLink({ paymentLinkId }));
    }
  }, [dispatch, paymentLinkId]);

  const [showDeleteEventModal, hideDeleteEventModal] = useModal(
    () =>
      paymentLinkId ? (
        <ConfirmDeletePaymentLinkDialog
          paymentLinkId={paymentLinkId}
          onClose={hideDeleteEventModal}
        />
      ) : null,
    [paymentLinkId],
  );

  if (!isLoadingPaymentLink && !paymentLink && paymentLinkId) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader
      loading={isLoading || (paymentLinkId && isLoadingPaymentLink)}
      error={error || (paymentLinkId ? errorPaymentLink : null)}
    >
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const handleFormSubmit = useCallback(
          async (data) => {
            dispatch(closeSnackbar('SUCCESS'));

            const action = paymentLinkId
              ? updatePaymentLink(
                  { ...data, paymentLinkId },
                  { isPromise: true },
                )
              : createPaymentLink(data, { isPromise: true });

            dispatch(action)
              .then(() => {
                dispatch(
                  enqueueSnackbar('SUCCESS', {
                    message: paymentLinkId
                      ? 'Changes saved'
                      : 'Payment link created',
                    variant: 'success',
                  }),
                );
                if (!paymentLinkId) {
                  navigate(`/admin/payment-links`);
                }
              })
              .catch(noop);
          },
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [dispatch, navigate, paymentLinkId],
        );

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { control, errors, formState, handleSubmit } = useForm({
          defaultValues:
            paymentLinkId && paymentLink
              ? paymentLink
              : {
                  expiresAt: addHours(new Date(), 24),
                },
          validationSchema: schema,
          reValidateMode: 'onChange',
        });

        const edited = formState.dirty;

        return (
          <UserDetailsLayout>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={[
                {
                  href: '/admin/payment-links',
                  label: 'Payment Links',
                },
                { label: paymentLinkId && paymentLink ? 'Update' : 'Create' },
              ]}
            />

            <form
              className={styles.form}
              noValidate
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <TitleAndAction
                className={styles.titleAndAction}
                title={
                  <Typography variant="h4">
                    {paymentLinkId
                      ? 'Edit Payment Link'
                      : 'Create New Payment Link'}
                  </Typography>
                }
              />

              <Grid container spacing={2} direction="column">
                <Grid item container spacing={2} direction="row">
                  <Grid item md={4} xs={12}>
                    <Input
                      data-testid="name"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Customer Name"
                      name="name"
                      errorText={get(errors, 'name.message')}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Input
                      data-testid="email"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Customer Email"
                      name="email"
                      errorText={get(errors, 'email.message')}
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={2} direction="row">
                  <Grid item sm={6} xs={12}>
                    <DateTimePicker
                      data-testid="expiresAt"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Expires At"
                      name="expiresAt"
                      errorText={get(errors, 'expiresAt.message')}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Input
                      data-testid="affiliateId"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Affiliate User Id"
                      name="affiliateId"
                      errorText={get(errors, 'affiliateId.message')}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Select
                    data-testid="offerId"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Select Offer"
                    name="offerId"
                    options={offers
                      .filter((o) => !o.isArchived)
                      .map((o) => ({
                        value: o.id,
                        label: o.title,
                      }))}
                    errorText={get(errors, 'offerId.message')}
                  />
                </Grid>

                <Grid
                  className={styles.buttons}
                  item
                  container
                  spacing={1}
                  justify="space-between"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      type="submit"
                      disabled={isLoadingMutation || (paymentLinkId && !edited)}
                    >
                      Save Link
                    </Button>
                  </Grid>
                  {paymentLinkId ? (
                    <Grid item>
                      <Button
                        variant="outlined"
                        startIcon={<DeleteOutlineIcon />}
                        type="button"
                        className={styles.deleteButton}
                        onClick={showDeleteEventModal}
                      >
                        Delete Link
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminCreateOrUpdatePaymentLinkPage;
