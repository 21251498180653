import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const JcbCard = (props) => {
  return (
    <SvgIcon viewBox="0 0 750 471" {...props}>
      <defs>
        <linearGradient
          x1=".032%"
          y1="50%"
          x2="99.974%"
          y2="50%"
          id="jcb_svg__a"
        >
          <stop stopColor="#007B40" offset="0%" />
          <stop stopColor="#55B330" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".472%"
          y1="50%"
          x2="99.986%"
          y2="50%"
          id="jcb_svg__b"
        >
          <stop stopColor="#1D2970" offset="0%" />
          <stop stopColor="#006DBA" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".114%"
          y1="50.001%"
          x2="99.986%"
          y2="50.001%"
          id="jcb_svg__c"
        >
          <stop stopColor="#6E2B2F" offset="0%" />
          <stop stopColor="#E30138" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <rect fill="#0E4C96" width={750} height={471} rx={40} />
        <path
          d="M617.243 346.766c0 41.615-33.728 75.36-75.36 75.36H132.757V124.245c0-41.626 33.733-75.37 75.365-75.37l409.121-.001v297.892z"
          fill="#FFF"
        />
        <path
          d="M483.859 242.045c11.684.253 23.437-.516 35.077.4 11.787 2.2 14.628 20.043 4.156 25.888-7.141 3.85-15.633 1.432-23.379 2.113H483.86v-28.401zm41.833-32.145c2.596 9.165-6.238 17.392-15.066 16.13h-26.767c.185-8.642-.368-18.021.272-26.208 10.724.301 21.549-.616 32.21.48 4.58 1.15 8.413 4.916 9.35 9.598zM590.12 73.997c.498 17.501.071 35.927.214 53.783-.035 72.596.072 145.194-.055 217.79-.469 27.207-24.582 50.844-51.6 51.387-27.046.111-54.095.016-81.142.047v-109.75c29.47-.154 58.959.307 88.417-.232 13.667-.86 28.632-9.876 29.27-24.915 1.61-15.102-12.632-25.55-26.153-27.201-5.198-.135-5.044-1.515 0-2.117 12.892-2.787 23.02-16.133 19.226-29.499-3.236-14.058-18.773-19.499-31.697-19.472-26.351-.18-52.709-.026-79.063-.077.172-20.489-.354-41 .286-61.474 2.087-26.716 26.806-48.747 53.447-48.27h78.85z"
          fill="url(#jcb_svg__a)"
        />
        <path
          d="M159.74 125.04c.674-27.163 24.889-50.611 51.875-51.007 26.944-.083 53.891-.012 80.837-.036-.074 90.885.148 181.777-.112 272.658-1.038 26.834-24.99 49.834-51.679 50.307-26.996.099-53.995.014-80.992.042V283.551c26.223 6.194 53.722 8.832 80.473 4.721 15.993-2.575 33.488-10.424 38.902-27.014 3.986-14.192 1.742-29.126 2.334-43.692v-33.824h-46.297c-.208 22.37.426 44.78-.335 67.125-1.248 13.734-14.846 22.46-27.8 21.995-16.066.169-47.898-11.64-47.898-11.64-.08-41.917.466-94.408.692-136.182z"
          fill="url(#jcb_svg__b)"
        />
        <path
          d="M309.72 197.39c-2.434.517-.49-8.3-1.114-11.646.166-21.15-.346-42.323.284-63.458 2.083-26.829 26.991-48.916 53.739-48.288h78.766c-.074 90.884.147 181.775-.112 272.656-1.039 26.834-24.992 49.833-51.68 50.308-26.998.1-53.998.015-80.997.043V272.708c18.44 15.128 43.5 17.484 66.472 17.525 17.317-.006 34.534-2.675 51.35-6.67V260.79c-18.953 9.447-41.233 15.446-62.243 10.018-14.656-3.65-25.294-17.811-25.056-32.936-1.699-15.728 7.524-32.335 22.981-37.011 19.19-6.008 40.108-1.413 58.096 6.398 3.855 2.018 7.765 4.521 6.222-1.921v-17.9c-30.084-7.157-62.101-9.792-92.329-2.004-8.748 2.468-17.27 6.21-24.379 11.956z"
          fill="url(#jcb_svg__c)"
        />
      </g>
    </SvgIcon>
  );
};

export default JcbCard;
