import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { get, find, noop } from 'lodash';
import clsx from 'clsx';

import {
  selectCoursesWithGraph,
  selectCoursesRequest,
  selectModulesRequest,
  selectAchievementsRequest,
  selectCreateAchievementRequest,
  selectUpdateAchievementRequest,
} from 'Redux/selectors';
import {
  createAchievement,
  fetchAchievements,
  fetchCourses,
  fetchModules,
  updateAchievement,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import UploadAchievementGraphicDialog from 'Dialogs/upload-achievement-graphic-dialog';
import RichTextEditor from 'Components/shared/rich-text-editor';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import UploadFileGroup from 'Components/pages/admin/upload-file-group';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import { Save as SaveIcon, Add as AddIcon } from '@material-ui/icons';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  InputAdornment,
} from '@material-ui/core';

const schema = yup.object().shape({
  title: yup.string().required('Field Required.'),
  slug: yup
    .string()
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g, 'Only Letters, Numbers and Dashes.')
    .required('Field Required.'),
  description: yup.string().required('Field Required.'),
  requirements: yup.string().required('Field Required.'),
  exp: yup
    .number()
    .min(0, 'At Least 0.')
    .default(0)
    .nullable()
    .transform((value) => (isNaN(value) ? undefined : value))
    .typeError('Not a Number.'),
});

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  input: {
    width: '100%',
  },
  exp: {
    maxWidth: 160,
  },
  colorPreview: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  switch: {
    marginBottom: theme.spacing(2.5),
  },
  upload: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  topics: {
    marginBottom: theme.spacing(3),
  },
}));

const AdminCreateOrUpdateAchievementPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId, moduleId, achievementId } = useParams();

  const courses = useSelector(selectCoursesWithGraph);
  const course = find(courses, ['id', courseId]);
  const module = course ? find(course.modules, ['id', moduleId]) : null;
  const achievement = module
    ? find(module.achievements, ['id', achievementId])
    : null;

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectModulesRequest,
    selectAchievementsRequest,
  );

  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectCreateAchievementRequest,
    selectUpdateAchievementRequest,
  );

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    dispatch(fetchModules({ courseId, skipAccessCheck: true }));
    dispatch(fetchAchievements({ courseId, skipAccessCheck: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId]);

  const handleFormSubmit = useCallback(
    async (data) => {
      const action = achievementId
        ? updateAchievement(
            { courseId, moduleId, achievementId, ...data },
            { isPromise: true },
          )
        : createAchievement(
            { courseId, moduleId, ...data },
            { isPromise: true },
          );

      dispatch(action)
        .then(() =>
          navigate(`/admin/courses/${courseId}/modules/${moduleId}/overview`),
        )
        .catch(noop);
    },
    [courseId, achievementId, moduleId, dispatch, navigate],
  );

  const [
    showUploadAchievementGraphicDialog,
    hideUploadAchievementGraphicDialog,
  ] = useModal(
    () => (
      <UploadAchievementGraphicDialog
        courseId={courseId}
        moduleId={moduleId}
        achievementId={achievementId}
        onClose={hideUploadAchievementGraphicDialog}
      />
    ),
    [courseId, achievementId, moduleId],
  );

  if (!isLoading && !achievement && achievementId) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { control, errors, handleSubmit } = useForm({
          defaultValues: achievement
            ? {
                ...achievement,
                description: achievement.description
                  ? achievement.description.html
                  : null,
                requirements: achievement.requirements
                  ? achievement.requirements.html
                  : null,
                exp: achievement.exp || '0',
              }
            : {
                exp: '0',
              },
          validationSchema: schema,
          reValidateMode: 'onChange',
        });

        return (
          <UserDetailsLayout>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={[
                { href: '/admin/courses', label: 'Courses' },
                {
                  href: `/admin/courses/${courseId}/overview`,
                  label: course.title,
                },
                {
                  href: `/admin/courses/${courseId}/modules/${moduleId}/overview`,
                  label: module.title,
                },
                { label: achievement ? achievement.title : 'Achievement' },
              ]}
            />

            <form
              className={styles.form}
              noValidate
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <TitleAndAction
                className={styles.titleAndAction}
                title={
                  <Typography variant="h4">
                    {achievementId
                      ? 'Edit Achievement'
                      : 'Create New Achievement'}
                  </Typography>
                }
              />

              <Grid container spacing={2} direction="column">
                <Grid item container spacing={2} direction="row">
                  <Grid item xs={12} sm={6} md={5}>
                    <Input
                      data-testid="title"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Title"
                      name="title"
                      errorText={get(errors, 'title.message')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Input
                      data-testid="slug"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Slug"
                      name="slug"
                      errorText={get(errors, 'slug.message')}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <RichTextEditor
                    data-testid="description"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Description"
                    name="description"
                    errorText={get(errors, 'description.message')}
                  />
                </Grid>

                <Grid item>
                  <RichTextEditor
                    data-testid="requirements"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Requirements"
                    name="requirements"
                    errorText={get(errors, 'requirements.message')}
                  />
                </Grid>

                <Grid item>
                  <Input
                    data-testid="exp"
                    control={control}
                    className={clsx(styles.input, styles.exp)}
                    disabled={isLoadingMutation}
                    label="XP Earned"
                    name="exp"
                    type="number"
                    errorText={get(errors, 'exp.message')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="body2" color="textSecondary">
                            XP
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                {achievementId ? (
                  <Grid item container direction="column">
                    <Typography variant="body1" gutterBottom>
                      Graphic
                    </Typography>
                    <Grid item>
                      <UploadFileGroup
                        className={styles.upload}
                        preview={achievement.graphic}
                        onUpload={showUploadAchievementGraphicDialog}
                        disabled={isLoadingMutation}
                        uploadButtonLabel={
                          achievement.graphic
                            ? 'Replace Graphic'
                            : 'Upload Graphic'
                        }
                        uploadButtonIcon={<AddIcon />}
                      />
                    </Grid>
                  </Grid>
                ) : null}

                <Grid item xs={10} sm={6} md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    type="submit"
                  >
                    Save Achievement
                  </Button>
                </Grid>
              </Grid>
            </form>
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminCreateOrUpdateAchievementPage;
