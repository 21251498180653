import { put, call } from 'redux-saga/effects';
import { compact, find, flatMapDeep, map, orderBy, uniq } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPDATE_OFFER_SUCCESS } from './update-offer';
import { RESTORE_OFFER_SUCCESS } from './restore-offer';
import { CREATE_OFFER_SUCCESS } from './create-offer';
import { ARCHIVE_OFFER_SUCCESS } from './archive-offer';

const MODULE = 'offers';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchOffers = Exec.requestActionCreator(MODULE);
export const fetchOffersLoading = Exec.loadingActionCreator(MODULE);
export const fetchOffersSuccess = Exec.successActionCreator(MODULE);
export const fetchOffersError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);
export const reducer = (state, action) => {
  switch (action.type) {
    case ARCHIVE_OFFER_SUCCESS:
    case RESTORE_OFFER_SUCCESS:
    case UPDATE_OFFER_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: orderBy(
          state.data.map((v) => (v.id === data.id ? data : v)),
          ['isArchived', 'updatedAt'],
          ['asc', 'desc'],
        ),
      };
    }
    case CREATE_OFFER_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: orderBy(
          [...state.data, data].map((v) => (v.id === data.id ? data : v)),
          ['isArchived', 'updatedAt'],
          ['asc', 'desc'],
        ),
      };
    }
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectOffersRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectOffers = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchOffers = function* ({ meta }) {
  try {
    const { data } = yield call(Api.getOffers);

    const users = yield Promise.all(
      compact(
        uniq(
          flatMapDeep(data, (offer) =>
            flatMapDeep(offer.prices, (price) =>
              flatMapDeep(price.superAffiliateConfig, 'userIds'),
            ),
          ),
        ),
      ).map((id) => Api.getUser(id).then((res) => res.data)),
    );

    for (const offer of data) {
      for (const price of offer.prices) {
        for (const config of price.superAffiliateConfig) {
          config.users = map(config.userIds, (id) => find(users, ['id', id]));
          delete config.userIds;
        }
      }
    }

    yield put(fetchOffersSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchOffersError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchOffers);
