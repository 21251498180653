import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'update-offer';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPDATE_OFFER_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPDATE_OFFER_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const updateOffer = Exec.requestActionCreator(MODULE);
export const updateOfferLoading = Exec.loadingActionCreator(MODULE);
export const updateOfferSuccess = Exec.successActionCreator(MODULE);
export const updateOfferError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpdateOfferRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpdateOffer = function* ({ payload, meta }) {
  const { offerId, ...attributes } = payload;

  try {
    yield put(closeSnackbar(UPDATE_OFFER_REQUEST));
    yield put(updateOfferLoading());
    const { data } = yield call(Api.updateOffer, offerId, attributes);
    yield put(updateOfferSuccess({ data }, meta));
  } catch (err) {
    yield put(updateOfferError(err, meta));
    yield put(
      enqueueSnackbar(UPDATE_OFFER_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpdateOffer);
