import { put, take, call, cancelled, race } from 'redux-saga/effects';
import { buffers, eventChannel, END } from 'redux-saga';
import defaultAxios from 'axios';

import * as Exec from 'Redux/creators';

const CancelToken = defaultAxios.CancelToken;

const MODULE = 'upload-file';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////

export const UPLOAD_FILE_ERROR = Exec.errorConstantCreator(MODULE);
export const UPLOAD_FILE_SUCCESS = Exec.successConstantCreator(MODULE);
export const UPLOAD_FILE_CANCEL = 'upload-file-cancel';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const uploadFile = Exec.requestActionCreator(MODULE);
export const uploadFileLoading = Exec.loadingActionCreator(MODULE);
export const uploadFileSuccess = Exec.successActionCreator(MODULE);
export const uploadFileError = Exec.errorActionCreator(MODULE);
export const uploadFileReset = Exec.resetActionCreator(MODULE);
export const uploadFileProgress = Exec.progressActionCreator(MODULE);

export const uploadFileCancel = () => {
  return {
    type: UPLOAD_FILE_CANCEL,
  };
};

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUploadFileRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const createUploadFileChannel = ({ axios = defaultAxios, ...request }) => {
  return eventChannel((emitter) => {
    const source = CancelToken.source();
    const config = {
      ...request,
      cancelToken: source.token,
      onUploadProgress(progressEvent) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        emitter({ progress });
      },
    };

    axios
      .request(config)
      .then((response) => {
        emitter({ response });
        emitter(END);
      })
      .catch((error) => {
        emitter({ error });
        emitter(END);
      });

    return () => {
      source.cancel();
    };
  }, buffers.sliding(2));
};

const doUploadFileTask = function* ({ payload, meta }) {
  try {
    yield put(uploadFileLoading());

    const channel = yield call(createUploadFileChannel, payload);

    try {
      while (true) {
        const { progress = 0, error, response } = yield take(channel);

        if (error) {
          throw error;
        }

        if (response) {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }

          yield put(uploadFileProgress(null));
          yield put(uploadFileSuccess({ data: response.data }, meta));
          break;
        }

        yield put(uploadFileProgress(progress));
      }
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  } catch (err) {
    yield put(uploadFileError(err, meta));
  } finally {
    if (yield cancelled()) {
      yield put(uploadFileReset(meta));
    }
  }
};

const doUploadFile = function* (arg) {
  yield race([call(doUploadFileTask, arg), take(UPLOAD_FILE_CANCEL)]);
};

export const sagas = Exec.sagaCreator(MODULE, doUploadFile);
