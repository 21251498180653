import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPDATE_WEBHOOK_SUCCESS } from './update-webhook';
import { CREATE_WEBHOOK_SUCCESS } from './create-webhook';

const MODULE = 'webhooks';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchWebhooks = Exec.requestActionCreator(MODULE);
export const fetchWebhooksLoading = Exec.loadingActionCreator(MODULE);
export const fetchWebhooksSuccess = Exec.successActionCreator(MODULE);
export const fetchWebhooksError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);
export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_WEBHOOK_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((v) => (v.id === data.id ? data : v)),
      };
    }
    case CREATE_WEBHOOK_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      };
    }
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectWebhooksRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectWebhooks = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchWebhooks = function* ({ meta }) {
  try {
    const { data } = yield call(Api.getWebhooks);
    yield put(fetchWebhooksSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchWebhooksError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchWebhooks);
