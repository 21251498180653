import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { selectMe, selectUpdateMeRequest } from 'Redux/selectors';
import { updateMe } from 'Redux/actions';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import { EmojiObjects as EmojiObjectsIcon } from '@material-ui/icons';
import { makeStyles, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.grey[500],
  },
  input: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  bulbIcon: {
    marginRight: theme.spacing(1.5),
  },
  hint: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ChangeGoalDialog = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const [confirmed, setConfirmed] = useState(false);
  const me = useSelector(selectMe);
  const { isLoading } = useSelector(selectUpdateMeRequest);

  const styles = useStyles();

  const { control, handleSubmit, errors } = useForm({
    defaultValues: me,
  });

  const handleUpdate = useCallback(
    (data) => {
      if (!confirmed) {
        setConfirmed(true);
      } else {
        dispatch(updateMe(data, { isPromise: true })).then(onClose);
      }
    },
    [confirmed, dispatch, onClose],
  );

  return (
    <Dialog
      title="Change Your Goal"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleUpdate)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit">{confirmed ? 'Save' : "I'm sure"}</Button>
      }
    >
      {!confirmed ? (
        <>
          <Typography gutterBottom variant="body1">
            Your goal is not meant to be changed. Most people set goals, but
            never achieve them because they lose motivation or get lazy.
          </Typography>
          <Typography gutterBottom variant="body1">
            Really ask yourself why you're about to change your goal. Be honest.
            Is it because you want the easy way out? Or do you have a valid
            reason?
          </Typography>
          <Typography gutterBottom variant="body1">
            Will the goal that you have now bring you closer to freedom and your
            dream life?
          </Typography>
          <Typography variant="body1">
            Only change your goal if you're sure that you're not taking the easy
            way out and losing motivation.
          </Typography>
        </>
      ) : (
        <>
          <Input
            data-testid="input"
            className={styles.input}
            control={control}
            disabled={isLoading}
            label="Your Goal"
            name="goal"
            multiline
            rows={5}
            rowsMax={5}
            rules={{ required: true }}
            errorText={get(errors, 'goal') ? 'Field required.' : null}
          />
          <Typography
            variant="body1"
            color="secondary"
            className={styles.hint}
            component="div"
          >
            <EmojiObjectsIcon className={styles.bulbIcon} />
            <Typography variant="body2">
              <strong>Note:</strong> Make sure to set a goal that is optimistic,
              but realistic.
            </Typography>
          </Typography>
        </>
      )}
    </Dialog>
  );
};

ChangeGoalDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ChangeGoalDialog;
