import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'create-resource';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CREATE_RESOURCE_REQUEST = Exec.requestConstantCreator(MODULE);
export const CREATE_RESOURCE_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const createResource = Exec.requestActionCreator(MODULE);
export const createResourceLoading = Exec.loadingActionCreator(MODULE);
export const createResourceSuccess = Exec.successActionCreator(MODULE);
export const createResourceError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCreateResourceRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doCreateResource = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(CREATE_RESOURCE_REQUEST));
    yield put(createResourceLoading());
    const { data } = yield call(Api.createResource, payload);
    yield put(createResourceSuccess({ data }, meta));
  } catch (err) {
    yield put(createResourceError(err, meta));
    yield put(
      enqueueSnackbar(CREATE_RESOURCE_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCreateResource);
