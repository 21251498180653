import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Typography } from '@material-ui/core';

import StepIcon from './step-icon';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
      content: '" "',
      position: 'absolute',
      left: 0,
      width: 'calc(50% - 14px)',
      height: 3,
      background:
        'linear-gradient(90deg, rgba(224, 224, 224, 0) 0%, #E0E0E0 100%)',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      right: 0,
      width: 'calc(50% - 14px)',
      height: 3,
      background:
        'linear-gradient(90deg, #E0E0E0 0%, rgba(224, 224, 224, 0) 100%)',
    },
  },
  leftCompleted: {
    '&:before': {
      background: `linear-gradient(270deg, ${theme.palette.primary.main} 0%, rgba(237, 193, 44, 0) 100%)`,
    },
  },
  rightCompleted: {
    '&:after': {
      background: `linear-gradient(270deg, rgba(237, 193, 44, 0) 0%, ${theme.palette.primary.main} 100%)`,
    },
  },
  completed: {
    color: 'white',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
}));

const MobileStepper = (props) => {
  const styles = useStyles();
  const { className, activeStep, currentStep, count } = props;

  const active = currentStep === activeStep;
  const completed = currentStep < activeStep;

  return (
    <div
      className={clsx(className, styles.root, {
        [styles.leftCompleted]: currentStep === 0 || completed || active,
        [styles.rightCompleted]: activeStep === count || completed,
      })}
    >
      <StepIcon
        active={active}
        completed={completed}
        icon={<Typography variant="subtitle2">{currentStep + 1}</Typography>}
      />
    </div>
  );
};

MobileStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default MobileStepper;
