import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import dynamicImageUrl from 'Util/dynamic-image-url';
import useNavigateQuery from 'Hooks/use-navigate-query';
import SearchInput from 'Components/shared/search-input';

import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import {
  makeStyles,
  emphasize,
  useMediaQuery,
  Typography,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    ...(props.imageUrl
      ? {
          '&:before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundImage: `url("${props.imageUrl}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
          '&:after': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            background:
              'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(37, 50, 71, 0.7) 32.81%, #253247 100%)',
          },
        }
      : {}),
    width: '100%',
    backgroundColor: theme.palette.grey[50],
    position: 'relative',
  }),
  container: (props) => ({
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(5, 3, 0),
    height: props.searchResults ? 256 : 400,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 0,
      height: props.searchResults ? 216 : 336,
    },
  }),
  backIcon: {
    position: 'absolute',
    left: theme.spacing(3),
    top: theme.spacing(3),
    width: 48,
    height: 48,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.grey[300],
    '&:hover, &:focus': {
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
    marginRight: theme.spacing(1.5),
  },
  title: {
    color: 'white',
    textTransform: 'capitalize',
    marginBottom: theme.spacing(3),
  },
  input: {
    boxShadow: theme.shadows[8],
    width: '100%',
    maxWidth: 522,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 690,
    },
  },
}));

const SearchHeader = (props) => {
  const { className, image = {}, withBackButton, children } = props;

  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const navigateQuery = useNavigateQuery();

  const query = params.get('q');
  const backTo = params.get('backTo');

  const searchResults = Boolean(query);

  const styles = useStyles({
    imageUrl: dynamicImageUrl(image, {
      resize: {
        width: window.innerWidth,
        height: 400,
        fit: 'cover',
      },
    }),
    searchResults,
  });
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const handleBack = useCallback(() => {
    navigate(backTo ? backTo : -1);
  }, [backTo, navigate]);

  const handleSearch = useCallback(
    (query) => {
      if (pathname === '/help/search') {
        navigateQuery({ q: query });
      } else {
        navigateQuery('/help/search', { q: query, backTo: pathname });
      }
    },
    [navigateQuery, pathname],
  );

  return (
    <section className={clsx(className, styles.root)}>
      <div className={styles.container}>
        {withBackButton && (
          <IconButton onClick={handleBack} className={styles.backIcon}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {!searchResults && (
          <Typography
            variant={isSmUp ? 'h3' : 'h4'}
            align="center"
            className={styles.title}
          >
            How can we help you today?
          </Typography>
        )}
        <SearchInput
          className={styles.input}
          placeholder="Search the Help Center"
          defaultValue={query}
          onSubmit={handleSearch}
          ButtonProps={{
            color: 'primary',
          }}
        />
        {children}
      </div>
    </section>
  );
};

SearchHeader.propTypes = {
  withBackButton: PropTypes.bool,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default SearchHeader;
