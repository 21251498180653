//
// :: Theme for MUI
//
import { createMuiTheme, fade } from '@material-ui/core';

const createTheme = (palette) => {
  const theme = createMuiTheme({ shape: { borderRadius: 8 }, palette });

  return createMuiTheme({
    palette,
    shape: {
      borderRadius: 8,
    },
    breakpoints: {
      keys: ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'],
      values: {
        xxs: 0,
        xs: 340,
        sm: 600,
        md: 768,
        lg: 1024,
        xl: 1200,
        xxl: 1400,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 80,
      },
    },
    props: {
      MuiAppBar: {
        elevation: 0,
      },
      MuiButton: {
        disableElevation: true,
      },
      MuiDialog: {
        fullWidth: true,
      },
      MuiDialogTitle: {
        disableTypography: true,
      },
      MuiSnackbarContent: {
        elevation: 0,
      },
      MuiUseMediaQuery: {
        noSsr: true, // TODO: if using SSR switch to false
      },
    },
    typography: {
      fontFamily: [
        'Poppins',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h1: {
        fontWeight: 300,
        fontSize: '6rem',
        lineHeight: '112px',
        letterSpacing: '-1.5px',
      },
      h2: {
        fontWeight: 300,
        fontSize: '3.75rem',
        lineHeight: '72px',
        letterSpacing: '-0.5px',
      },
      h3: {
        fontWeight: 700,
        fontSize: '3rem',
        lineHeight: '62px',
      },
      h4: {
        fontWeight: 700,
        fontSize: '2.125rem',
        lineHeight: '51px',
        letterSpacing: '0.25px',
      },
      h5: {
        fontWeight: 500,
        fontSize: '1.5rem',
        lineHeight: '36px',
      },
      h6: {
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: '30px',
        letterSpacing: '0.15px',
      },
      body1: {
        fontWeight: 400,
        fontSize: '1.125rem',
        lineHeight: '27px',
        letterSpacing: '0.5px',
      },
      body2: {
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '21px',
        letterSpacing: '0.25px',
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '27px',
        letterSpacing: '0.5px',
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '21px',
        letterSpacing: '0.25px',
      },
      button: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '24px',
        letterSpacing: '0.75px',
      },
      caption: {
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: '18px',
        letterSpacing: '0.4px',
      },
      overline: {
        fontWeight: 500,
        fontSize: '0.625rem',
        lineHeight: '15px',
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [
            {
              fontFamily: '"Noto Color Emoji"',
              src: 'url(https://gitcdn.xyz/repo/googlefonts/noto-emoji/master/fonts/NotoColorEmoji.ttf)',
            },
          ],
          button: {
            border: 'none',
            outlineStyle: 'none',
            boxShadow: 'none',
            borderColor: 'transparent',
            resize: 'none',
            backgroundColor: 'transparent',
          },
          html: {
            height: '100%',
            minHeight: '100%',
            outline: 'none',
          },
          body: {
            height: '100%',
            minHeight: '100%',
          },
          '#root': {
            height: '100%',
            minHeight: '100%',
          },
          '.carousel__slide-focus-ring': {
            display: 'none !important',
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: 4,
        },
        label: {
          whiteSpace: 'nowrap',
          textTransform: 'capitalize',
        },
        textSizeLarge: {
          fontWeight: 500,
          fontSize: '1.25rem',
          lineHeight: '24px',
          letterSpacing: '0.75px',
        },
        containedSizeLarge: {
          fontWeight: 500,
          fontSize: '1.25rem',
          lineHeight: '24px',
          letterSpacing: '0.75px',
        },
      },
      MuiContainer: {
        root: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8),
          },
        },
      },
      MuiOutlinedInput: {
        adornedEnd: {
          paddingRight: 4,
        },
        root: {
          borderRadius: 4,
          '&$disabled $notchedOutline': {
            borderColor: theme.palette.grey[300],
          },
        },
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: theme.palette.common.black,
        },
      },
      MuiToolbar: {
        gutters: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
          },
        },
      },
      MuiTypography: {
        gutterBottom: {
          marginBottom: theme.spacing(2),
        },
      },
      MuiTabs: {
        flexContainer: {
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      MuiTab: {
        root: {
          padding: theme.spacing(3, 2),
          minWidth: 0,
          textTransform: 'capitalize',
          fontWeight: 500,
          fontSize: '1.25rem',
          lineHeight: '24px',
          letterSpacing: '0.75px',
        },
      },
      MuiPickersModal: {
        dialogRoot: {
          width: 'unset',
        },
        dialogRootWider: {
          minWidth: 350,
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          maxWidth: 'unset',
        },
      },
    },
  });
};

export default createTheme({
  common: {
    black: '#02060e',
    white: '#fff',
  },
  type: 'light',
  background: {
    paper: '#fff',
    default: '#fff',
  },
  text: {
    primary: fade('#000000', 0.87),
    secondary: fade('#000000', 0.7),
  },
  primary: {
    dark: '#33364F',
    main: '#414561',
    light: '#75788D',
    contrastText: '#fff',
  },
  secondary: {
    dark: '#137CDC',
    main: '#1A8FE3',
    light: '#5FB1EB',
    contrastText: '#fff',
  },
});
