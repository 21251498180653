const { REACT_APP_DYNAMIC_IMAGE_URL = 'http://localhost' } = process.env;

const dynamicImageUrl = (image, edits) => {
  // This function expects image object returned by the API
  if (!image) {
    return null;
  }

  if (!image.isResizable) {
    return image.url;
  }

  const regex = /(?:\/\/s3\.amazonaws.com\/([^/]+)|:\/\/([^.]+)\.s3\.amazonaws\.com)\/([^?\n\r\s]+)/;
  const match = image.url.match(regex);

  if (!match) {
    return image.url;
  }

  const request = {
    bucket: match[2] || match[1],
    key: match[3],
    edits,
  };

  if (!request.bucket || !request.key) {
    return image.url;
  }

  const url = new URL(
    btoa(JSON.stringify(request)),
    REACT_APP_DYNAMIC_IMAGE_URL,
  );

  return url.href;
};

export default dynamicImageUrl;
