import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  const withSpacing = (style, spacing) => ({
    ...style,
    marginTop: theme.spacing(spacing),
    marginBottom: theme.spacing(spacing),
  });

  return {
    root: {
      ...theme.typography.body1,
      '& h1': withSpacing(theme.typography.h1, 1),
      '& h2': withSpacing(theme.typography.h2, 1),
      '& h3': withSpacing(theme.typography.h3, 1),
      '& h4': withSpacing(theme.typography.h4, 1),
      '& h5': withSpacing(theme.typography.h5, 1),
      '& h6': withSpacing(theme.typography.h6, 1),
      '& p': withSpacing(theme.typography.body1, 1),
      '& ul': {
        paddingLeft: theme.spacing(3.5),
        margin: theme.spacing(1, 0),
      },
      '& ul:first-child': {
        marginTop: 0,
      },
      '& ul:last-child': {
        marginBottom: 0,
      },
      '& li:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
      '& img': withSpacing({ maxWidth: '100%' }, 1),
    },
  };
});

const RichText = (props) => {
  const { className, children } = props;
  const styles = useStyles();

  return (
    <div
      className={clsx(styles.root, className)}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};

export default RichText;
