import PropTypes from 'prop-types';

import withController from 'Util/with-controller';

import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import { FormControl, FormHelperText } from '@material-ui/core';

const DateTimePicker = (props) => {
  const { className, errorText, ...rest } = props;

  return (
    <FormControl className={className} error={Boolean(errorText)}>
      <MuiDateTimePicker
        InputProps={{ error: Boolean(errorText) }}
        InputLabelProps={{ error: Boolean(errorText) }}
        inputVariant="outlined"
        {...rest}
      />
      <FormHelperText>{errorText || ' '}</FormHelperText>
    </FormControl>
  );
};

DateTimePicker.propTypes = {
  errorText: PropTypes.string,
};

export default withController(DateTimePicker);
