import { useCallback } from 'react';
import PropTypes from 'prop-types';

import withController from 'Util/with-controller';

import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';

const AutocompleteInput = (props) => {
  const {
    className,
    loading,
    label,
    errorText,
    renderOption,
    onTextChange,
    ...rest
  } = props;

  const getOptionLabel = useCallback(
    (option) => {
      // e.g value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      if (option.inputValue) {
        return option.inputValue;
      }
      return renderOption ? renderOption(option) : option;
    },
    [renderOption],
  );

  const renderInput = useCallback(
    (params) => {
      const handleChange = (event) => {
        const value = event.currentTarget.value;
        if (params.onChange) {
          params.onChange(event);
        }
        if (onTextChange) {
          onTextChange(value);
        }
      };

      return (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={Boolean(errorText)}
          helperText={errorText || ' '}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={handleChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      );
    },
    [errorText, label, loading, onTextChange],
  );

  return (
    <Autocomplete
      selectOnFocus
      clearOnBlur
      clearOnEscape
      handleHomeEndKeys
      {...rest}
      loading={loading}
      renderInput={renderInput}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
    />
  );
};

AutocompleteInput.propTypes = {
  errorText: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onTextChange: PropTypes.func,
  renderOption: PropTypes.func,
};

export default withController(
  AutocompleteInput,
  undefined,
  ([, second]) => second,
);
