import instance from 'Api/instance';

export const getModules = (courseId, skipAccessCheck) =>
  instance.get('/courses/' + courseId + '/modules', {
    params: { skipAccessCheck },
  });

export const createModule = (courseId, attributes) =>
  instance.post('/courses/' + courseId + '/modules', { data: { attributes } });

export const updateModule = (courseId, moduleId, attributes) =>
  instance.patch('/courses/' + courseId + '/modules/' + moduleId, {
    data: { attributes },
  });

export const sortModules = (courseId, attributes) =>
  instance.post('/courses/' + courseId + '/modules/sort', {
    data: { attributes },
  });
