import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import dynamicImageUrl from 'Util/dynamic-image-url';
import { Edit as EditIcon } from '@material-ui/icons';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

import ContentPaper from './content-paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  graphic: {
    width: 64,
    height: 64,
    borderRadius: '50%',
    border: 'none',
    backgroundColor: theme.palette.grey[50],
    objectFit: 'cover',
    margin: theme.spacing(-2, 2, -2, 0),
  },
}));

const AchievementItem = (props) => {
  const { id, courseId, moduleId, title, graphic } = props;
  const styles = useStyles();

  const { active, attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: active && active.id === props.id ? 10 : undefined,
  };

  return (
    <ContentPaper
      style={style}
      ref={setNodeRef}
      className={styles.paper}
      containerClassName={styles.content}
      actions={
        <IconButton
          component={Link}
          to={`/admin/courses/${courseId}/modules/${moduleId}/achievements/${id}/edit`}
        >
          <EditIcon />
        </IconButton>
      }
      {...listeners}
      {...attributes}
    >
      <img
        src={dynamicImageUrl(graphic, {
          resize: {
            width: 80,
            height: 80,
            fit: 'cover',
          },
        })}
        className={styles.graphic}
        alt="img"
      />
      <Typography variant="h6">{title}</Typography>
    </ContentPaper>
  );
};

AchievementItem.propTypes = {
  id: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  graphic: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
};

export default AchievementItem;
