import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { get } from 'lodash';
import clsx from 'clsx';

import countries from 'Util/countries';
import { selectRegisterRequest, selectRememberMe } from 'Redux/selectors';
import { register, enqueueSnackbar, closeSnackbar } from 'Redux/actions';
import useRecaptcha from 'Hooks/use-recaptcha';
import Select from 'Components/shared/select';
import { PageLoaderReset } from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import LoginTabs from 'Components/shared/login-tabs';
import Input from 'Components/shared/input';
import Checkbox from 'Components/shared/checkbox';

import {
  ChevronRight as ChevronRightIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  InputAdornment,
  Container,
  Link,
  IconButton,
} from '@material-ui/core';

const options = countries.map((c) => ({ value: c.code, label: c.name }));

const schema = yup.object().shape({
  firstName: yup.string().required('Field Required.'),
  lastName: yup.string().required('Field Required.'),
  email: yup.string().email('Invalid Email.').required('Field Required.'),
  password: yup.string().min(6, 'Too Short.').required('Field Required.'),
  confirmPassword: yup
    .string()
    .required('Field Required.')
    .oneOf([yup.ref('password'), null], 'Password Mismatch.'),
  country: yup.string().required('Field Required.'),
  phoneNumber: yup.string().required('Field Required.'),
  legal: yup.boolean().oneOf([true], 'True Required'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    paddingBottom: theme.spacing(4),
  },
  link: {
    color: theme.palette.secondary.dark,
  },
  title: {
    alignSelf: 'center',
    paddingBottom: theme.spacing(1.5),
  },
  input: (props) => ({
    width: '100%',
    maxWidth: props.wide ? 300 : 260,
  }),
  inputHelperText: {
    '& .MuiFormHelperText-root': {
      marginTop: 12,
    },
  },
  email: {
    width: '100%',
  },
  buttons: {
    paddingTop: theme.spacing(4) + 'px !important',
    justifyContent: 'space-between',
  },
  frame: (props) => ({
    maxWidth: props.wide ? 380 : 340,
    margin: -1,
    marginTop: theme.spacing(2.5) - 1 + 'px',
    padding: theme.spacing(3, 4),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginTop: theme.spacing(2.5),
      borderWidth: 0,
      maxWidth: 312,
      padding: theme.spacing(3, 0),
    },
  }),
  legal: (props) => ({
    color: props.legalError
      ? theme.palette.error.main
      : theme.palette.text.primary,
  }),
  legalControl: {
    marginBottom: theme.spacing(1),
  },
}));

const RegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectRegisterRequest);
  const rememberMe = useSelector(selectRememberMe);
  const recaptcha = useRecaptcha();
  const [params] = useSearchParams();

  const redirectTo = params.get('redirectTo');

  const [currentStep, setCurrentStep] = useState(0);
  const [isPasswdVisible, setIsPasswdVisible] = useState(false);

  const handleFormSubmit = useCallback(
    async (data) => {
      try {
        await dispatch(register(data, { isPromise: true }));
        navigate(
          !redirectTo
            ? '/verify-email?intent=register'
            : '/verify-email?intent=register&redirectTo=' + redirectTo,
        );
      } catch (err) {
        if (err.error.code === 'ERROR_CODE_TOO_MANY_REQUESTS') {
          const recaptchaResponse = await recaptcha();
          if (recaptchaResponse) {
            return handleFormSubmit({ ...data, recaptchaResponse });
          }
        }
      }
    },
    [redirectTo, dispatch, navigate, recaptcha],
  );

  const { control, watch, errors, handleSubmit, triggerValidation } = useForm({
    defaultValues: { country: options[0].value, rememberMe, legal: false },
    validationSchema: schema,
    reValidateMode: 'onChange',
  });

  const styles = useStyles({
    wide: currentStep === 0,
    legalError: Boolean(get(errors, 'legal.message')),
  });
  const country = watch('country');

  const handleToggleVisibility = useCallback(
    () => setIsPasswdVisible(!isPasswdVisible),
    [isPasswdVisible],
  );

  const handleSecondaryButtonClick = useCallback(() => {
    if (currentStep === 0) {
      navigate(redirectTo ? '/signin?redirectTo=' + redirectTo : '/signin');
    } else {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep, redirectTo, navigate]);

  const handlePrimaryButtonClick = useCallback(
    (ev) => {
      if (currentStep !== 2) {
        ev.preventDefault();
        triggerValidation(
          [
            ['firstName', 'lastName', 'email'],
            ['password', 'confirmPassword'],
          ][currentStep],
        ).then((valid) => valid && setCurrentStep(currentStep + 1));
      } else {
        triggerValidation('legal').then((valid) => {
          if (!valid && errors.legal) {
            dispatch(closeSnackbar('REGISTER_LEGAL'));
            dispatch(
              enqueueSnackbar('REGISTER_LEGAL', {
                variant: 'error',
                message: 'Please read & accept our terms and privacy policy',
              }),
            );
          }
        });
      }
    },
    [currentStep, dispatch, triggerValidation, errors.legal],
  );

  return (
    <PageLoaderReset>
      <PageLayout>
        <Container className={styles.root}>
          <Grid
            className={styles.heading}
            container
            justify="center"
            direction="column"
          >
            <Grid item>
              <Typography align="center" variant="h4">
                Enroll In FREE 10K Course
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center" variant="h6" color="error">
                Available limited time
              </Typography>
            </Grid>
          </Grid>
          <LoginTabs value={1} />
          <Grid
            container
            spacing={2}
            direction="column"
            className={styles.frame}
            component="form"
            noValidate
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Typography variant="subtitle1" className={styles.title}>
              {
                [
                  'Create an account',
                  'Choose password',
                  'Personal information',
                ][currentStep]
              }
            </Typography>

            <Grid item hidden={currentStep !== 0}>
              <Input
                data-testid="firstName"
                control={control}
                className={styles.input}
                disabled={isLoading}
                label="First Name"
                name="firstName"
                errorText={get(errors, 'firstName.message')}
              />
            </Grid>

            <Grid item hidden={currentStep !== 0}>
              <Input
                data-testid="lastName"
                control={control}
                className={styles.input}
                disabled={isLoading}
                label="Last Name"
                name="lastName"
                errorText={get(errors, 'lastName.message')}
              />
            </Grid>

            <Grid item hidden={currentStep !== 0}>
              <Input
                data-testid="email"
                control={control}
                // className={styles.email}
                className={clsx(styles.email, styles.inputHelperText)}
                disabled={isLoading}
                label="Email Address"
                name="email"
                type="email"
                errorText={get(errors, 'email.message')}
                helperText="We won't spam you. Only a few emails here and there actually helping you achieve freedom."
              />
            </Grid>

            <Grid item hidden={currentStep !== 1}>
              <Input
                data-testid="password"
                control={control}
                className={styles.input}
                disabled={isLoading}
                label="Password"
                name="password"
                type={isPasswdVisible ? 'text' : 'password'}
                errorText={get(errors, 'password.message')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="toggleVisibility"
                        onClick={handleToggleVisibility}
                      >
                        {isPasswdVisible ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item hidden={currentStep !== 1}>
              <Input
                data-testid="confirmPassword"
                control={control}
                className={styles.input}
                disabled={isLoading}
                label="Confirm Password"
                name="confirmPassword"
                type={isPasswdVisible ? 'text' : 'password'}
                errorText={get(errors, 'confirmPassword.message')}
              />
            </Grid>

            <Grid item hidden={currentStep !== 2}>
              <Select
                data-testid="country"
                control={control}
                className={styles.input}
                disabled={isLoading}
                label="Country"
                name="country"
                options={options}
              />
            </Grid>

            <Grid item hidden={currentStep !== 2}>
              <Input
                data-testid="phoneNumber"
                control={control}
                className={clsx(styles.input, styles.inputHelperText)}
                disabled={isLoading}
                label="Phone Number"
                name="phoneNumber"
                format="phoneNumber"
                locale={country}
                errorText={get(errors, 'phoneNumber.message')}
                helperText="We'll never call you or send you annoying texts."
              />
            </Grid>

            <Grid
              container={currentStep === 2}
              item
              spacing={currentStep === 2 ? 1 : undefined}
              hidden={currentStep !== 2}
            >
              <Grid item className={styles.legalControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid="legal"
                      control={control}
                      disabled={isLoading}
                      name="legal"
                      color="secondary"
                      error={get(errors, 'legal.message')}
                    />
                  }
                  label={
                    <Typography variant="body2" className={styles.legal}>
                      <span>Accept </span>
                      <Link
                        className={styles.link}
                        target="_blank"
                        href="https://www.ecomfreedom.com/termsofservice"
                        rel="noopener noreferrer"
                      >
                        terms of service
                      </Link>
                      <span> and </span>
                      <Link
                        className={styles.link}
                        target="_blank"
                        href="https://www.ecomfreedom.com/privacypolicy"
                        rel="noopener noreferrer"
                      >
                        privacy policy
                      </Link>
                      .
                    </Typography>
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid="rememberMe"
                      control={control}
                      disabled={isLoading}
                      name="rememberMe"
                      color="secondary"
                    />
                  }
                  label={
                    <Typography variant="body2" className={styles.legal}>
                      Remember me
                    </Typography>
                  }
                />
              </Grid>
            </Grid>

            <Grid container item className={styles.buttons}>
              <Grid item>
                <Button
                  data-testid="cancel"
                  color="primary"
                  disabled={isLoading}
                  variant="text"
                  onClick={handleSecondaryButtonClick}
                >
                  {currentStep === 0 ? 'Login' : 'Back'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  data-testid="submit"
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  endIcon={<ChevronRightIcon />}
                  onClick={handlePrimaryButtonClick}
                >
                  {currentStep === 2 ? 'Register' : 'Continue'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </PageLayout>
    </PageLoaderReset>
  );
};

export default RegisterPage;
