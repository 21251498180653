import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { findIndex } from 'lodash';

import {
  selectCourses,
  selectCoursesRequest,
  selectMeRequest,
} from 'Redux/selectors';
import { fetchCourses, sortCourses } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderAdminItems from 'Components/shared/header-admin-items';
import DrawerAdminItems from 'Components/shared/drawer-admin-items';
import CourseCard from 'Components/shared/course-card';

import { Add as AddIcon } from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: 0,
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'calc(100% - 280px)',
      marginLeft: 280,
    },
  },
  drawer: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    position: 'fixed',
    marginTop: 1, // divider offset
    width: 280,
  },
  titleAndActions: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  title: {
    height: 40,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  gridAndTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  sectionTitle: {
    marginBottom: theme.spacing(3),
  },
  course: {
    width: '100%',
    height: 280,
  },
  card: {
    width: '100%',
  },
}));

const AdminContentPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const courses = useSelector(selectCourses);

  const { isLoading, isRequested, error } = useCombinedRequestsSelector(
    selectMeRequest,
    selectCoursesRequest,
  );

  const isXlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const from = findIndex(courses, ['id', active.id]);
        const to = findIndex(courses, ['id', over.id]);

        const moved = arrayMove(courses, from, to);

        dispatch(
          sortCourses({
            sortConfig: moved.map((c, idx) => ({ id: c.id, seqNo: idx + 1 })),
          }),
        );
      }
    },
    [courses, dispatch],
  );

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout
          waiting={isRequested}
          headerItems={<HeaderAdminItems />}
          drawerItems={<DrawerAdminItems />}
          drawerBreakpoint="xl"
        >
          <div className={styles.root}>
            {isXlUp && <DrawerAdminItems className={styles.drawer} />}

            <PageContainer
              className={styles.container}
              maxWidth={1200}
              sidebarWidth={280}
              sidebarToggleBreakpoint="xl"
            >
              <div className={styles.titleAndActions}>
                <Typography variant="h4" className={styles.title}>
                  Courses
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  component={Link}
                  to="/admin/courses/create"
                >
                  Create New Course
                </Button>
              </div>
              <div className={styles.gridAndTitle}>
                {!courses.length ? (
                  <Typography variant="h6">No courses created yet.</Typography>
                ) : null}
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  modifiers={[restrictToWindowEdges]}
                  onDragEnd={handleDragEnd}
                >
                  <Grid container spacing={3}>
                    <SortableContext
                      items={courses}
                      strategy={rectSortingStrategy}
                    >
                      {courses.map((course) => (
                        <Grid key={course.id} item xs={12} md={6} lg={4}>
                          <CourseCard
                            className={styles.course}
                            elevation={8}
                            subtitle={
                              { COURSE: 'Course', BLUEPRINT: 'Blueprint' }[
                                course.type
                              ]
                            }
                            ButtonProps={{
                              'data-testid': 'courseLink',
                              to: '/admin/courses/' + course.id + '/overview',
                            }}
                            noLogo
                            {...course}
                          />
                        </Grid>
                      ))}
                    </SortableContext>
                  </Grid>
                </DndContext>
              </div>
            </PageContainer>
          </div>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default AdminContentPage;
