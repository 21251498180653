import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'roles';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchRoles = Exec.requestActionCreator(MODULE);
export const fetchRolesLoading = Exec.loadingActionCreator(MODULE);
export const fetchRolesSuccess = Exec.successActionCreator(MODULE);
export const fetchRolesError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectRolesRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectRoles = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchRoles = function* ({ meta }) {
  try {
    const { data } = yield call(Api.getRoles);
    yield put(fetchRolesSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchRolesError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchRoles);
