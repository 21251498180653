import { Link, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import WebhookOutlineIcon from 'Icons/webhook-outline';
import {
  LocalOfferOutlined as LocalOfferOutlinedIcon,
  OndemandVideo as OndemandVideoIcon,
  AssessmentOutlined as AssessmentOutlinedIcon,
  ContactPhoneOutlined as ContactPhoneOutlinedIcon,
  PeopleOutlineOutlined as PeopleOutlineOutlinedIcon,
  LinkOutlined as LinkOutlinedIcon,
} from '@material-ui/icons';
import { List } from '@material-ui/core';

import DrawerItem from './drawer-item';

const DrawerAdminItems = (props) => {
  const { className, onClose } = props;
  const { pathname } = useLocation();

  return (
    <List className={className}>
      <DrawerItem
        onClick={onClose}
        component={Link}
        to="/admin/courses"
        text="Courses"
        selected={pathname.startsWith('/admin/courses')}
        icon={OndemandVideoIcon}
        data-testid="courses"
      />
      <DrawerItem
        onClick={onClose}
        component={Link}
        to="/admin/users"
        selected={pathname.startsWith('/admin/users')}
        text="Users"
        icon={PeopleOutlineOutlinedIcon}
        data-testid="users"
      />
      <DrawerItem
        onClick={onClose}
        component={Link}
        to="/admin/mentorship-calls"
        text="Mentorship Calls"
        selected={pathname.startsWith('/admin/mentorship-calls')}
        icon={ContactPhoneOutlinedIcon}
        data-testid="mentorship-calls"
      />
      <DrawerItem
        onClick={onClose}
        component={Link}
        to="/admin/offers"
        text="Offers"
        selected={pathname.startsWith('/admin/offers')}
        icon={LocalOfferOutlinedIcon}
        data-testid="offers"
      />
      <DrawerItem
        onClick={onClose}
        component={Link}
        to="/admin/payment-links"
        text="Payment Links"
        selected={pathname.startsWith('/admin/payment-links')}
        icon={LinkOutlinedIcon}
        data-testid="payment-links"
      />
      <DrawerItem
        onClick={onClose}
        component={Link}
        to="/admin/achievement-levels"
        text="Achievement Levels"
        selected={pathname.startsWith('/admin/achievement-levels')}
        icon={AssessmentOutlinedIcon}
        data-testid="achievement-levels"
      />
      <DrawerItem
        onClick={onClose}
        component={Link}
        to="/admin/webhooks"
        text="Webhooks"
        selected={pathname.startsWith('/admin/webhooks')}
        icon={WebhookOutlineIcon}
        data-testid="webhooks"
      />
    </List>
  );
};

DrawerAdminItems.propTypes = {
  onClose: PropTypes.func,
};

export default DrawerAdminItems;
