import { isMobile } from 'react-device-detect';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { times, isString, noop } from 'lodash';
import { parseISO } from 'date-fns';
import clsx from 'clsx';

import ArrowButton from 'Components/shared/arrow-button';

import { Today as TodayIcon } from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  LinearProgress,
  Paper,
  MenuItem,
  Select,
  Button,
  IconButton,
  ButtonBase,
} from '@material-ui/core';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const years = times(6, (n) => new Date().getFullYear() + (n - 3));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    alignItems: 'center',
    gap: theme.spacing(2.5) + 'px',
  },
  paper: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    gridRow: 1,
    gridColumnStart: 1,
    gridColumnEnd: 3,
    padding: theme.spacing(3, 2),
  },
  controls: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    height: 36,
    width: '100%',
  },
  calendar: (props) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    padding: theme.spacing(1.5, 0),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    '& > :nth-child(8)': {
      gridColumn: props.firstDay + 1,
    },
  }),
  date: {
    userSelect: 'none',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.typography.body1,
  },
  markedDate: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    margin: 2,
  },
  day: {
    color: theme.palette.text.secondary,
    ...theme.typography.subtitle1,
  },
  select: {
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& .MuiSelect-select': {
      [theme.breakpoints.up('xs')]: {
        paddingRight: theme.spacing(3),
      },
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(4),
      },
    },
    height: 36,
    ...theme.typography.h6,
  },
  selectMargin: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: 'auto',
  },
  arrow: {
    gridRow: 2,
  },
  arrowLeft: {
    justifySelf: 'flex-end',
    gridColumn: 1,
  },
  arrowRight: {
    justifySelf: 'flex-start',
    gridColumn: 2,
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },
  large: {
    '& $paper': {
      gridColumn: 2,
      width: 432,
      padding: theme.spacing(3.5, 3),
    },
    '& $calendar': {
      gap: theme.spacing(0.5) + 'px',
    },
    '& $date': {
      width: 48,
      height: 48,
    },
    '& $markedDate': {
      width: 44,
      height: 44,
    },
    '& $day': theme.typography.h6,
    '& $select': theme.typography.h5,
    '& $selectMargin': {
      marginRight: theme.spacing(4),
    },
    '& $arrow': {
      gridRow: 1,
    },
    '& $arrowRight': {
      gridColumn: 3,
    },
  },
  medium: {
    '& $date': {
      width: 46,
      height: 46,
    },
    '& $markedDate': {
      width: 42,
      height: 42,
    },
  },
  small: {
    '& $date': {
      width: 38,
      height: 38,
    },
    '& $markedDate': {
      width: 34,
      height: 34,
    },
  },
}));

const Calendar = (props) => {
  const {
    className,
    loading,
    size: size_,
    month,
    year,
    dates: dates_,
    onChange,
    button,
    ButtonProps,
  } = props;
  const dates = dates_.map((date) => (isString(date) ? parseISO(date) : date));

  const firstDay = new Date(year, month, 1).getDay();
  const monthDaysCount = new Date(year, month + 1, 0).getDate();

  const isXsUp = useMediaQuery((theme) => theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const size = size_ || (isSmUp ? 'large' : isXsUp ? 'medium' : 'small');
  const styles = useStyles({ firstDay });

  const handlePrevious = useCallback(
    () => (month === 0 ? onChange(year - 1, 11) : onChange(year, month - 1)),
    [month, year, onChange],
  );
  const handleNext = useCallback(
    () => (month === 11 ? onChange(year + 1, 0) : onChange(year, month + 1)),
    [month, year, onChange],
  );
  const handleToday = useCallback(
    () => onChange(new Date().getFullYear(), new Date().getMonth()),
    [onChange],
  );
  const handleMonth = useCallback(
    ({ target }) => onChange(year, target.value),
    [onChange, year],
  );
  const handleYear = useCallback(
    ({ target }) => onChange(target.value, month),
    [onChange, month],
  );

  const MenuComponent = isMobile ? 'option' : MenuItem;
  const CalendarComponent = button ? ButtonBase : 'div';

  return (
    <div className={clsx(className, styles.root, styles[size])}>
      <ArrowButton
        className={clsx(styles.arrow, styles.arrowLeft)}
        orientation="left"
        onClick={handlePrevious}
        data-testid="prevMonth"
      />
      <Paper elevation={4} className={styles.paper}>
        {loading && <LinearProgress aria-busy className={styles.loader} />}
        <div className={styles.controls}>
          <Select
            className={clsx(styles.select, styles.selectMargin)}
            native={isMobile}
            variant="standard"
            value={month}
            onChange={handleMonth}
          >
            {months.map((name, index) => (
              <MenuComponent key={index} value={index}>
                {name}
              </MenuComponent>
            ))}
          </Select>
          <Select
            className={styles.select}
            native={isMobile}
            variant="standard"
            value={year}
            onChange={handleYear}
          >
            {years.map((year) => (
              <MenuComponent key={year} value={year}>
                {year}
              </MenuComponent>
            ))}
          </Select>
          {isSmUp ? (
            <Button
              className={styles.button}
              variant="outlined"
              color="primary"
              onClick={handleToday}
              data-testid="today"
            >
              Today
            </Button>
          ) : (
            <IconButton
              className={styles.button}
              color="primary"
              onClick={handleToday}
            >
              <TodayIcon />
            </IconButton>
          )}
        </div>
        <CalendarComponent {...ButtonProps} className={styles.calendar}>
          {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
            <div key={index} className={clsx(styles.date, styles.day)}>
              {day}
            </div>
          ))}
          {times(monthDaysCount, (index) => {
            const active = Boolean(
              dates.find((date) => date.getDate() === index + 1),
            );
            return (
              <div
                key={index + '-' + month}
                className={clsx(styles.date, active && styles.markedDate)}
              >
                {index + 1}
              </div>
            );
          })}
        </CalendarComponent>
      </Paper>
      <ArrowButton
        className={clsx(styles.arrow, styles.arrowRight)}
        orientation="right"
        onClick={handleNext}
        data-testid="nextMonth"
      />
    </div>
  );
};

Calendar.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  dates: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  button: PropTypes.bool.isRequired,
  ButtonProps: PropTypes.any,
};

Calendar.defaultProps = {
  button: false,
  loading: false,
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
  dates: [],
  onChange: noop,
};

export default Calendar;
