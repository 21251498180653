import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'create-module';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CREATE_MODULE_REQUEST = Exec.requestConstantCreator(MODULE);
export const CREATE_MODULE_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const createModule = Exec.requestActionCreator(MODULE);
export const createModuleLoading = Exec.loadingActionCreator(MODULE);
export const createModuleSuccess = Exec.successActionCreator(MODULE);
export const createModuleError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCreateModuleRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doCreateModule = function* ({ payload, meta }) {
  const { courseId, ...fields } = payload;
  try {
    yield put(closeSnackbar(CREATE_MODULE_REQUEST));
    yield put(createModuleLoading());
    const { data } = yield call(Api.createModule, courseId, fields);
    yield put(createModuleSuccess({ data }, meta));
  } catch (err) {
    yield put(createModuleError(err, meta));
    yield put(
      enqueueSnackbar(CREATE_MODULE_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCreateModule);
