import { put, call, race, take } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { fetchMeSuccess } from './me';
import {
  fetchCourses,
  FETCH_COURSES_ERROR,
  FETCH_COURSES_SUCCESS,
} from './courses';

const MODULE = 'update-me';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPDATE_ME_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const updateMe = Exec.requestActionCreator(MODULE);
export const updateMeLoading = Exec.loadingActionCreator(MODULE);
export const updateMeSuccess = Exec.successActionCreator(MODULE);
export const updateMeError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpdateMeRequest = Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpdateMe = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(UPDATE_ME_REQUEST));
    yield put(updateMeLoading());

    const { recaptchaResponse, ...fields } = payload;
    const { data } = yield call(Api.updateMe, fields, recaptchaResponse);

    // If there's an 'isAffiliate' flag in payload, it means user is joining
    // refer a friend program and it only happens once
    if ('isAffiliate' in payload) {
      yield put(fetchCourses()); // get new affiliate links
      yield race([take(FETCH_COURSES_SUCCESS), take(FETCH_COURSES_ERROR)]);
    }

    yield put(fetchMeSuccess({ data }));
    yield put(updateMeSuccess(null, meta));

    if ('email' in payload) {
      yield put(
        enqueueSnackbar(UPDATE_ME_REQUEST, {
          message: 'We have sent you a verification email',
          variant: 'success',
        }),
      );
    }
  } catch (err) {
    yield put(updateMeError(err, meta));
    if (err.code !== 'ERROR_CODE_TOO_MANY_REQUESTS') {
      yield put(
        enqueueSnackbar(UPDATE_ME_REQUEST, {
          message:
            err.code === 'ERROR_CODE_NETWORK_ERROR'
              ? 'Network error'
              : err.detail || 'There was an error, try again later',
          variant: 'error',
        }),
      );
    }
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpdateMe);
