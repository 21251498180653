///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const ENQUEUE_SNACKBAR = 'enqueue-snackbar';
const CLOSE_SNACKBAR = 'close-snackbar';
const REMOVE_SNACKBAR = 'remove-snackbar';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
const keys = {};
const keyModifier = (key, mod = 0) => {
  return mod > 0
    ? key + (keys[key] = keys[key] ? keys[key] + mod : mod)
    : key + (keys[key] ? keys[key] : '');
};

export const enqueueSnackbar = (key, options) => {
  return {
    type: ENQUEUE_SNACKBAR,
    payload: {
      // create a key counter cache here
      key: keyModifier(key, 1) || new Date().getTime() + Math.random(),
      options,
    },
  };
};

export const closeSnackbar = (key) => {
  return {
    type: CLOSE_SNACKBAR,
    payload: {
      dismissAll: !key, // dismiss all if no key has been defined
      key: keyModifier(key),
    },
  };
};

export const removeSnackbar = (key) => {
  return {
    type: REMOVE_SNACKBAR,
    payload: {
      // Don't apply keyModifier here
      key,
    },
  };
};

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultState = [];

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return [
        ...state,
        {
          key: action.payload.key,
          ...action.payload.options,
        },
      ];

    case CLOSE_SNACKBAR:
      return state.map((notification) =>
        action.payload.dismissAll || notification.key === action.payload.key
          ? { ...notification, dismissed: true }
          : { ...notification },
      );

    case REMOVE_SNACKBAR:
      return state.filter(
        (notification) => notification.key !== action.payload.key,
      );

    default:
      return state;
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSnackbarNotifications = (store) => store.snackbar;
