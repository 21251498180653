import { formatISO } from 'date-fns';

const SEPARATOR =
  navigator &&
  navigator.appVersion &&
  navigator.appVersion.indexOf('Win') !== -1
    ? '\r\n'
    : '\n';

const createIcsEvent = (subject, description, location, startsAt, endsAt) => {
  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    'CLASS:PUBLIC',
    'DESCRIPTION:' + description,
    'DTSTART;VALUE=DATE:' +
      formatISO(startsAt, { format: 'basic' }).split('+')[0],
    'DTEND;VALUE=DATE:' + formatISO(endsAt, { format: 'basic' }).split('+')[0],
    'LOCATION:' + location,
    'SUMMARY;LANGUAGE=en-us:' + subject,
    'TRANSP:TRANSPARENT',
    'END:VEVENT',
    'END:VCALENDAR',
  ].join(SEPARATOR);
};

export default createIcsEvent;
