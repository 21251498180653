import instance from 'Api/instance';

export const getAffiliateStats = () => instance.get('/affiliates/stats');

export const getAffiliateEarnings = (limit = 10, offset = 0) =>
  instance.get(`/affiliates/earnings`, { params: { limit, offset } });

export const getAffiliateRedeemRequests = (limit = 10, offset = 0) =>
  instance.get(`/affiliates/redeem-requests`, { params: { limit, offset } });

export const createAffiliateRedeemRequest = () =>
  instance.post(`/affiliates/redeem-requests`);
