import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop, ary } from 'lodash';

import { selectCancelTrialsRequest } from 'Redux/selectors';
import {
  cancelTrials,
  enqueueSnackbar,
  fetchSubscriptionSteps,
} from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography, makeStyles, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: `calc(100% - ${theme.spacing(4)}px) !important`,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '910px !important',
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  breakAll: {
    wordBreak: 'break-all',
    '@media only screen and (min-width: 360px)': {
      wordBreak: 'break-word',
    },
  },
}));

const CancelSelfTrialDialog = (props) => {
  const { trialId, onClose } = props;

  const styles = useStyles();
  const { isLoading } = useSelector(selectCancelTrialsRequest);
  const dispatch = useDispatch();

  const handleConfirm = useCallback(() => {
    dispatch(cancelTrials({ trialIds: [trialId] }, { isPromise: true }))
      .then(() => {
        dispatch(fetchSubscriptionSteps());
        dispatch(
          enqueueSnackbar('CANCEL_TRIALS', {
            variant: 'success',
            message: 'Your trial has been cancelled',
          }),
        );
        onClose && onClose(true);
      })
      .catch(noop);
  }, [dispatch, trialId, onClose]);

  return (
    <Dialog
      scroll="body"
      className={styles.root}
      title="Cancel Free Trial"
      loading={isLoading}
      onClose={ary(onClose, 0)}
      cancelButton={
        <Button
          className={styles.cancelButton}
          data-testid="cancel"
          variant="contained"
        >
          Go Back
        </Button>
      }
      submitButton={
        <Button color="default" data-testid="submit" onClick={handleConfirm}>
          Cancel Trial
        </Button>
      }
    >
      <Typography gutterBottom variant="body1">
        Hey!
      </Typography>
      <Typography gutterBottom variant="body1">
        Dan here. Before you go, I want to make sure you are making the right
        decision…
      </Typography>
      <Typography gutterBottom variant="subtitle1">
        Do you still want to achieve financial freedom? Freedom over your time
        and location?
      </Typography>
      <Typography gutterBottom variant="body1">
        Here at Freedom.co, we have helped thousands of students quit their jobs
        and create long-term sustainable income online.{' '}
        <strong>There is no reason you can’t be one of them…</strong>
      </Typography>
      <Typography gutterBottom variant="body1">
        You have already taken a step in the right direction, all you need to do
        is commit…
      </Typography>
      <Typography gutterBottom variant="body1">
        There is no better business to start <strong>RIGHT NOW</strong> than an
        e-commerce business.
      </Typography>
      <Typography gutterBottom variant="body1">
        We are early adopters of this business model, and the window to becoming
        a big brand is slowly closing.
      </Typography>
      <Typography gutterBottom variant="body1">
        The biggest thing I fear is regret, and the future you will regret not
        committing today, trust me.
      </Typography>
      <Typography gutterBottom variant="body1">
        Imagine where you could be one year from now making an additional
        10k/month online…
      </Typography>
      <Typography gutterBottom variant="body1">
        You joined this course for one reason,{' '}
        <strong>to achieve freedom…</strong>
      </Typography>
      <Typography gutterBottom variant="body1">
        You have a real shot here at taking complete control over your life,{' '}
        <strong>so why give up now?</strong>
      </Typography>

      <Typography gutterBottom align="center" variant="subtitle1">
        “Most people fail not because of a lack of desire, but because of a lack
        of commitment.” - Vince Lombardi
      </Typography>
      <Typography gutterBottom variant="body1">
        If there is anything we can do to improve your experience here, please
        let us know by sending an email to Adam at{' '}
        <Link className={styles.breakAll} href="mailto:support@ecomfreedom.com">
          support@ecomfreedom.com
        </Link>
        . We want nothing more than for you to become successful.
      </Typography>
      <Typography variant="body1">
        If you do wish to continue with the subscription cancellation process,
        please let us know why you wish to cancel...
      </Typography>
    </Dialog>
  );
};

CancelSelfTrialDialog.propTypes = {
  trialId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CancelSelfTrialDialog;
