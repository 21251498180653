import { createSelector } from 'reselect';
import { put, call } from 'redux-saga/effects';
import { floor, get, toNumber } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'event-attendees';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const FETCH_PAGE_SIZE = 10; // this can be dynamic

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
const defaultFetchEventAttendees = Exec.requestActionCreator(MODULE);
export const fetchEventAttendees = (payload, options) => {
  const page = get(payload, 'page', 0);
  return defaultFetchEventAttendees(payload, {
    isAppending: page > 0,
    ...options,
  });
};
export const fetchEventAttendeesLoading = Exec.loadingActionCreator(MODULE);
export const fetchEventAttendeesSuccess = Exec.successActionCreator(MODULE);
export const fetchEventAttendeesError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectEventAttendeesRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectEventAttendees = Exec.dataSelectorCreator(MODULE);
export const selectEventAttendeesRowsPerPage = () => FETCH_PAGE_SIZE;
export const selectEventAttendeesTotalCount = (store) =>
  store.eventAttendees.pageInfo ? store.eventAttendees.pageInfo.totalCount : 0;

export const selectEventAttendeesMaxPages = createSelector(
  selectEventAttendeesRowsPerPage,
  selectEventAttendeesTotalCount,
  (perPage, totalCount) => floor(totalCount / perPage),
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchEventAttendees = function* ({ payload, meta }) {
  const offset = FETCH_PAGE_SIZE * toNumber(payload.page || 0);
  try {
    const { data, __meta = {} } = yield call(
      Api.getEventAttendees,
      payload.eventId,
      FETCH_PAGE_SIZE,
      offset,
    );

    yield put(
      fetchEventAttendeesSuccess({ data, pageInfo: __meta.pageInfo }, meta),
    );
  } catch (err) {
    yield put(fetchEventAttendeesError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchEventAttendees);
