import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import RichText from 'Components/shared/rich-text';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import {
  makeStyles,
  AccordionDetails,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  withStyles,
} from '@material-ui/core';

const Accordion = withStyles((theme) => ({
  root: {
    margin: 0,
    overflow: 'hidden',
    '&$expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  rounded: {
    borderRadius: '0 !important',
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    minHeight: 72,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: 1,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,

    '&$expanded': {
      minHeight: 72,
    },
  },
  expanded: {
    minHeight: 72,
  },
  expandIcon: {
    width: 48,
    maxHeight: 48,
    minHeight: 48,
    transform: 'rotate(-90deg)',
    '&$expanded': {
      transform: 'rotate(0deg)',
    },
  },
  content: {
    margin: 0,
    alignItems: 'center',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&$expanded': {
      margin: 0,
    },
  },
}))(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  details: {
    padding: theme.spacing(0, 4, 2, 4),
  },
  content: {
    width: '100%',
  },
}));

const QuestionAccordion = (props) => {
  const { href, title, answer, ...rest } = props;
  const styles = useStyles();

  return (
    <Accordion {...rest}>
      <AccordionSummary
        component={Link}
        to={href}
        expandIcon={<ExpandMoreIcon fontSize="large" />}
      >
        <Typography variant="subtitle1">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.details}>
        <RichText className={styles.content}>{answer.html}</RichText>
      </AccordionDetails>
    </Accordion>
  );
};

QuestionAccordion.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  answer: PropTypes.shape({
    html: PropTypes.string,
  }).isRequired,
};

export default QuestionAccordion;
