import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import WistiaVideo from 'Components/shared/wistia-video';

import { Skeleton } from '@material-ui/lab';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  video: {
    width: '100%',
    maxHeight: 'calc(100vh - 292px)',
    backgroundColor: 'black',
  },
  titles: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(5),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

const VideoContainer = (props) => {
  const { className, classes, loading, title, subtitle, WistiaVideoProps } =
    props;
  const styles = useStyles();

  return (
    <div className={clsx(styles.root, className)}>
      <WistiaVideo playing className={styles.video} {...WistiaVideoProps} />
      <div className={clsx(styles.titles, classes && classes.titles)}>
        {loading ? (
          <>
            <Typography variant="h5" className={styles.title}>
              <Skeleton width={120} />
            </Typography>
            <Typography variant="body1">
              <Skeleton width={200} />
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h5" className={styles.title}>
              {title}
            </Typography>
            <Typography variant="body1">{subtitle}</Typography>
          </>
        )}
      </div>
    </div>
  );
};

VideoContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  WistiaVideoProps: PropTypes.any,
};

VideoContainer.defaultProps = {
  loading: false,
};

export default memo(VideoContainer);
