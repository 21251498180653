import { Link, useSearchParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  makeStyles,
  withStyles,
  AppBar,
  Tabs as MuiTabs,
  Tab,
  Typography,
} from '@material-ui/core';

const Tabs = withStyles((theme) => ({
  flexContainer: {
    justifyContent: 'center',
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
}))(MuiTabs);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    height: 48,
    zIndex: 1099,
  },
  tab: {
    textTransform: 'unset',
    flexGrow: 'unset',
    minWidth: 'unset',
    width: 120,
    height: 48,
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const LoginTabs = ({ className, value }) => {
  const styles = useStyles();
  const [params] = useSearchParams();

  const redirectTo = params.get('redirectTo');

  return (
    <AppBar position="sticky" className={clsx(styles.root, className)}>
      <Tabs value={value}>
        <Tab
          label={<Typography variant="h6">Sign in</Typography>}
          disableRipple
          component={Link}
          to={redirectTo ? '/signin?redirectTo=' + redirectTo : '/signin'}
          className={styles.tab}
        />
        <Tab
          label={<Typography variant="h6">Register</Typography>}
          disableRipple
          component={Link}
          to={redirectTo ? '/register?redirectTo=' + redirectTo : '/register'}
          className={styles.tab}
        />
      </Tabs>
    </AppBar>
  );
};

LoginTabs.propTypes = {
  value: PropTypes.number.isRequired,
};

export default LoginTabs;
