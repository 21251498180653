const { REACT_APP_API_URL } = process.env;

const exportTransactionsUrl = (params) => {
  const { userId, ...otherParams } = params;

  const url = new URL(
    `/api/v1/users/${userId}/transactions/export`,
    REACT_APP_API_URL,
  );

  for (const param in otherParams) {
    if (otherParams.hasOwnProperty(param) && otherParams[param]) {
      url.searchParams.set(param, otherParams[param]);
    }
  }

  return url.href;
};

export default exportTransactionsUrl;
