import { put, call, delay } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { fetchMeSuccess } from './me';

const MODULE = 'signout';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SIGNOUT_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const signout = Exec.requestActionCreator(MODULE);
export const signoutLoading = Exec.loadingActionCreator(MODULE);
export const signoutSuccess = Exec.successActionCreator(MODULE);
export const signoutError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSignoutRequest = Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSignout = function* ({ meta }) {
  try {
    yield put(closeSnackbar(SIGNOUT_REQUEST));
    yield put(signoutLoading());
    yield call(Api.signout);
    yield delay(500); // wait for cookies to clear; some browsers need this
    yield put(signoutSuccess(null, meta));
    yield put(Exec.resetGlobalFetch());
    yield put(fetchMeSuccess(null));
  } catch (err) {
    yield put(signoutError(err, meta));
    yield put(
      enqueueSnackbar(SIGNOUT_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSignout);
