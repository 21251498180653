import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { floor } from 'lodash';
import clsx from 'clsx';

import { makeStyles, Typography } from '@material-ui/core';

import ArrowButton from './arrow-button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    alignItems: 'center',
    columnGap: theme.spacing(3) + 'px',
    alignSelf: 'center',
    padding: theme.spacing(0.5),
  },
}));

const Pagination = (props) => {
  const { className, onChange, current, total, pageSize } = props;
  const styles = useStyles();

  const handlePrevious = useCallback(() => {
    onChange && onChange(current - 1);
  }, [current, onChange]);

  const handleNext = useCallback(() => {
    onChange && onChange(current + 1);
  }, [current, onChange]);

  return (
    <div className={clsx(styles.root, className)}>
      <ArrowButton disabled orientation="left" onClick={handlePrevious} />
      <Typography variant="body1">
        {current} of {floor(total / pageSize)}
      </Typography>
      <ArrowButton orientation="right" onClick={handleNext} />
    </div>
  );
};

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

export default Pagination;
