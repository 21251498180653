import ReCAPTCHA from 'react-google-recaptcha';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ary } from 'lodash';

import Dialog from 'Components/shared/dialog';

import { makeStyles } from '@material-ui/core';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 78 + 24,
  },
});

const RecaptchaDialog = (props) => {
  const { onSuccess, onFailure, onClose } = props;

  const styles = useStyles();

  const handleChange = useCallback(
    (value) => {
      if (value) {
        onSuccess(value);
      } else {
        onFailure();
      }
      onClose();
    },
    [onFailure, onSuccess, onClose],
  );

  return (
    <Dialog
      title="Prove You Are Not A Robot"
      loading={false}
      onClose={ary(handleChange, 0)}
    >
      <div className={styles.container}>
        <ReCAPTCHA
          sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleChange}
        />
      </div>
    </Dialog>
  );
};

RecaptchaDialog.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RecaptchaDialog;
