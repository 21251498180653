import withController from 'Util/with-controller';

import { withStyles, Checkbox as MuiCheckbox } from '@material-ui/core';

const Checkbox = withStyles((theme) => ({
  root: (props) => ({
    color: props.error ? theme.palette.error.main : undefined,
    marginTop: -9,
    marginBottom: -9,
  }),
}))(MuiCheckbox);

export default withController(Checkbox, undefined, ([, second]) => second);
