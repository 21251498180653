const calculateUserLevel = (experience, levels) => {
  if (!experience) {
    return levels[0];
  }

  for (let i = 1; i < levels.length; i++) {
    if (levels[i].requiredExperience > experience) {
      return levels[i - 1];
    }
  }

  return levels[levels.length - 1];
};

export default calculateUserLevel;
