import { put, select, call } from 'redux-saga/effects';
import { find, get } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { selectUsers } from './users';

const MODULE = 'user';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const FETCH_USER_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchUser = Exec.requestActionCreator(MODULE);
export const fetchUserLoading = Exec.loadingActionCreator(MODULE);
export const fetchUserSuccess = Exec.successActionCreator(MODULE);
export const fetchUserError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);

export const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      const { data } = action.payload;

      if (
        data &&
        data.suspension &&
        data.suspension.suspendedByUserId &&
        !data.suspension.suspendedByUser
      ) {
        return defaultReducer(state, {
          ...action,
          payload: {
            ...action.payload,
            data: {
              ...data,
              suspension: {
                ...data.suspension,
                suspendedByUser: get(state, 'data.suspension.suspendedByUser'),
              },
            },
          },
        });
      }

      return defaultReducer(state, action);
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUserRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectUser = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchUser = function* ({ payload, meta }) {
  const users = yield select(selectUsers);

  try {
    const existing = find(users, ['id', payload.userId]);

    const { data } = existing
      ? { data: existing }
      : yield call(Api.getUser, payload.userId);

    // Please note: data.suspension.suspendedByUserId can be NULL!
    if (data && data.suspension && data.suspension.suspendedByUserId) {
      const { data: suspendedByUser } = yield call(
        Api.getUser,
        data.suspension.suspendedByUserId,
      );

      data.suspension.suspendedByUser = suspendedByUser;
    }

    yield put(fetchUserSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchUserError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchUser);
