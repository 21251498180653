import { isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  button: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
}));

const TitleAndAction = (props) => {
  const { title, button, className } = props;
  const styles = useStyles();

  const renderedButton = isValidElement(button)
    ? cloneElement(button, {
        className: clsx(button.props.className, styles.button),
      })
    : button;

  return (
    <div className={clsx(styles.root, className)}>
      {title}
      {renderedButton}
    </div>
  );
};

TitleAndAction.propTypes = {
  title: PropTypes.node,
  button: PropTypes.element,
};

export default TitleAndAction;
