import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'update-webhook';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPDATE_WEBHOOK_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPDATE_WEBHOOK_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const updateWebhook = Exec.requestActionCreator(MODULE);
export const updateWebhookLoading = Exec.loadingActionCreator(MODULE);
export const updateWebhookSuccess = Exec.successActionCreator(MODULE);
export const updateWebhookError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpdateWebhookRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpdateWebhook = function* ({ payload, meta }) {
  const { webhookId, ...attributes } = payload;

  try {
    yield put(closeSnackbar(UPDATE_WEBHOOK_REQUEST));
    yield put(updateWebhookLoading());
    const { data } = yield call(Api.updateWebhook, webhookId, attributes);
    yield put(updateWebhookSuccess({ data }, meta));
  } catch (err) {
    yield put(updateWebhookError(err, meta));
    yield put(
      enqueueSnackbar(UPDATE_WEBHOOK_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpdateWebhook);
