import { put, call } from 'redux-saga/effects';

import { omit } from 'lodash';
import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'reset-password';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const RESET_PASSWORD_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const resetPassword = Exec.requestActionCreator(MODULE);
export const resetPasswordLoading = Exec.loadingActionCreator(MODULE);
export const resetPasswordSuccess = Exec.successActionCreator(MODULE);
export const resetPasswordError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.mutateReducerCreator(MODULE);

export const reducer = (state, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      const res = defaultReducer(state, action);
      return { ...res, payload: omit(action.payload, 'recaptchaResponse') };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectResetPasswordRequest =
  Exec.mutateRequestSelectorCreator(MODULE);
export const selectResetPasswordLastPayload = (store) =>
  store.resetPassword.payload;

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doResetPassword = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(RESET_PASSWORD_REQUEST));
    yield put(resetPasswordLoading());
    yield call(Api.resetPassword, payload.email, payload.recaptchaResponse);
    yield put(resetPasswordSuccess(null, meta));
  } catch (err) {
    yield put(resetPasswordError(err, meta));
    if (err.code !== 'ERROR_CODE_TOO_MANY_REQUESTS') {
      yield put(
        enqueueSnackbar(RESET_PASSWORD_REQUEST, {
          message:
            err.code === 'ERROR_CODE_BAD_REQUEST'
              ? err.detail
              : err.code === 'ERROR_CODE_NETWORK_ERROR'
              ? 'Network error'
              : 'There was an error, try again later',
          variant: 'error',
        }),
      );
    }
  }
};

export const sagas = Exec.sagaCreator(MODULE, doResetPassword);
