import { call, put, select } from 'redux-saga/effects';

import { findIndex, sortBy } from 'lodash';
import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { selectAchievements } from './achievements';

const MODULE = 'sort-achievements';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SORT_ACHIEVEMENTS_REQUEST = Exec.requestConstantCreator(MODULE);
export const SORT_ACHIEVEMENTS_SUCCESS = Exec.successConstantCreator(MODULE);
export const SORT_ACHIEVEMENTS_ERROR = Exec.errorConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const sortAchievements = Exec.requestActionCreator(MODULE);
export const sortAchievementsLoading = Exec.loadingActionCreator(MODULE);
export const sortAchievementsSuccess = Exec.successActionCreator(MODULE);
export const sortAchievementsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSortAchievementsRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSortAchievements = function* ({ payload, meta }) {
  const { courseId, sortConfig } = payload;
  const achievements = yield select(selectAchievements);

  try {
    yield put(closeSnackbar(SORT_ACHIEVEMENTS_REQUEST));
    yield put(
      sortAchievementsSuccess({
        data: sortBy(achievements, (a) => findIndex(sortConfig, ['id', a.id])),
      }),
    );
    yield put(sortAchievementsLoading());

    const { data } = yield call(Api.sortAchievements, courseId, {
      sortConfig,
    });

    yield put(sortAchievementsSuccess({ data }, meta));
  } catch (err) {
    yield put(sortAchievementsError({ ...err, data: achievements }, meta));
    yield put(
      enqueueSnackbar(SORT_ACHIEVEMENTS_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSortAchievements);
