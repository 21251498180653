import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { CREATE_TOPIC_SUCCESS } from './create-topic';

const MODULE = 'topics';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchTopics = Exec.requestActionCreator(MODULE);
export const fetchTopicsLoading = Exec.loadingActionCreator(MODULE);
export const fetchTopicsSuccess = Exec.successActionCreator(MODULE);
export const fetchTopicsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);

export const reducer = (state, action) => {
  switch (action.type) {
    case CREATE_TOPIC_SUCCESS:
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectTopicsRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectTopics = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchTopics = function* ({ meta }) {
  try {
    const { data } = yield call(Api.getTopics);
    yield put(fetchTopicsSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchTopicsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchTopics);
