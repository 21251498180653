import { Link } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { padStart } from 'lodash';
import clsx from 'clsx';

import {
  CheckCircle as CheckCircleIcon,
  Timelapse as TimelapseIcon,
  Lock as LockIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  fade,
} from '@material-ui/core';

const durationToString = (duration) => {
  const hours = padStart(Math.floor(duration / 3600), 2, '0');
  const minutes = padStart(Math.floor((duration - hours * 3600) / 60), 2, '0');
  const seconds = padStart(duration - hours * 3600 - minutes * 60, 2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

const useStyles = makeStyles((theme) => ({
  icon: {
    right: theme.spacing(3),
  },
  listItem: {
    padding: theme.spacing(2, 7.5, 1.5, 3),
  },
  listItemTitle: {
    marginBottom: theme.spacing(1),
  },
  listItemTitleActive: {
    fontWeight: 500,
  },
  duration: {
    marginLeft: theme.spacing(1),
  },
  timelapseAndDuration: {
    height: 24,
    display: 'flex',
    alignItems: 'center',
  },
  iconActive: {
    color: theme.palette.secondary.light,
  },
  iconGrey: {
    color: theme.palette.grey[500],
  },
  iconInactive: {
    color: fade('#000000', 0.54),
  },
}));

const ModuleListItem = (props) => {
  const { active, completed, locked, title, duration, href, ...rest } = props;
  const styles = useStyles();
  const ref = useRef(null);
  const isXlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  useEffect(() => {
    if (active && ref.current) {
      if (isXlUp) {
        ref.current.scrollIntoView({
          block: 'center',
        });
      }
      window && window.scrollTo({ top: 0 });
    }
  }, [active, isXlUp]);

  return (
    <ListItem
      ref={ref}
      className={styles.listItem}
      divider
      button
      component={active ? 'button' : Link}
      to={href}
      disabled={locked}
      {...rest}
    >
      <ListItemText>
        <Typography
          variant="body1"
          className={clsx(
            styles.listItemTitle,
            active && styles.listItemTitleActive,
          )}
        >
          {title}
        </Typography>
        <div className={styles.timelapseAndDuration}>
          <TimelapseIcon
            className={completed ? styles.iconActive : styles.iconGrey}
            fontSize="small"
          />
          <Typography
            variant="body2"
            className={styles.duration}
            color="textSecondary"
          >
            {durationToString(duration)}
          </Typography>
        </div>
      </ListItemText>
      <ListItemSecondaryAction className={styles.icon}>
        {locked ? (
          <LockIcon className={styles.iconInactive} />
        ) : (
          <CheckCircleIcon
            className={completed ? styles.iconActive : styles.iconGrey}
          />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

ModuleListItem.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  href: PropTypes.string.isRequired,
};

export default ModuleListItem;
