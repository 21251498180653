import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'set-default-payment-method';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SET_DEFAULT_PAYMENT_METHOD_REQUEST = Exec.requestConstantCreator(MODULE);
export const SET_DEFAULT_PAYMENT_METHOD_SUCCESS = Exec.successConstantCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const setDefaultPaymentMethod = Exec.requestActionCreator(MODULE);
export const setDefaultPaymentMethodLoading = Exec.loadingActionCreator(MODULE);
export const setDefaultPaymentMethodSuccess = Exec.successActionCreator(MODULE);
export const setDefaultPaymentMethodError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSetDefaultPaymentMethodRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSetDefaultPaymentMethod = function* ({ payload, meta }) {
  const { paymentMethodId } = payload || {};
  try {
    yield put(closeSnackbar(SET_DEFAULT_PAYMENT_METHOD_REQUEST));
    yield put(setDefaultPaymentMethodLoading());

    const { data } = yield call(Api.setDefaultPaymentMethod, paymentMethodId);
    yield put(setDefaultPaymentMethodSuccess({ data }, meta));
  } catch (err) {
    yield put(setDefaultPaymentMethodError(err, meta));
    yield put(
      enqueueSnackbar(SET_DEFAULT_PAYMENT_METHOD_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.message || err.detail,
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSetDefaultPaymentMethod);
