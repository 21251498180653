/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { differenceBy, get } from 'lodash';
import clsx from 'clsx';
import { unformat } from 'accounting';
import ensurePrice from 'Util/ensure-price';
import { selectUsers, selectUsersRequest } from 'Redux/selectors';
import { fetchUsers } from 'Redux/actions';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import Input from 'Components/shared/input';
import AutocompleteInput from 'Components/shared/autocomplete-input';

import {
  DeleteOutline as DeleteOutlineIcon,
  ChevronRight as ChevronRightIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  Collapse,
  InputAdornment,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    padding: theme.spacing(2, 2, 1, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 3, 2, 3),
    },
    position: 'relative',
  },
  input: {
    maxWidth: 200,
  },
  firstRow: {
    paddingRight: '48px !important',
    marginBottom: '-14px !important',
  },
  deleteButton: {
    position: 'absolute',
    top: 24,
    right: 8,
  },
  gridTopSpacing: {
    marginTop: theme.spacing(2),
  },
  total: {
    marginBottom: 18,
  },
  chevron: {
    transition: 'transform 0.3s ease-in-out',
    transform: 'rotate(90deg)',
  },
  chevronDown: {
    transform: 'rotate(0deg)',
  },
  lastButton: {
    marginTop: '-6px !important',
  },
  subtitle: {
    marginBottom: 8,
  },
}));

const UserSearchInput = (props) => {
  const { name, ...rest } = props;

  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(null);

  const { isLoading: isLoadingUsers } = useSelector(selectUsersRequest);
  const users = useSelector(selectUsers);

  const { watch } = useFormContext();
  const live = watch(name);

  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchText) {
        dispatch(fetchUsers({ query: searchText, limit: 5 }));
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [dispatch, searchText]);

  const getOptionSelected = useCallback(
    (option, value) => option.id === value.id,
    [],
  );
  const handleRenderMentorOption = useCallback(
    (user) =>
      user
        ? isSmUp
          ? `${user.firstName} ${user.lastName} (${user.email})`
          : `${user.firstName} ${user.lastName}`
        : null,
    [isSmUp],
  );

  return (
    <AutocompleteInput
      {...rest}
      name={name}
      multiple
      limitTags={2}
      loading={Boolean(searchText && isLoadingUsers)}
      options={searchText ? differenceBy(users, live, 'id') || [] : []}
      noOptionsText={
        searchText ? 'Users not found' : 'Search users by name or email'
      }
      getOptionSelected={getOptionSelected}
      renderOption={handleRenderMentorOption}
      onTextChange={setSearchText}
    />
  );
};

const PaymentOption = (props) => {
  const {
    className,
    index,
    isLoading,
    onDelete,

    installmentCount,
    amount,
    affiliateDiscount,
    affiliateBonus,
    affiliateDiscountType,
  } = props;

  const styles = useStyles();
  const [expanded, setExpanded] = useState(false);

  const { control, errors, watch } = useFormContext();
  const live = watch('prices.' + index);

  const makeName = (suffix) => `prices.${index}.${suffix}`;

  const {
    fields: superAffiliateConfigs,
    append,
    remove,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useFieldArray({
    control,
    name: makeName('superAffiliateConfig'),
    keyName: 'key',
  });

  const handleDelete = useCallback(() => onDelete(index), [onDelete, index]);
  const handleExpand = useCallback(() => setExpanded((prev) => !prev), []);

  const handleAddSuperAffiliateConfig = useCallback(
    () =>
      append({
        triggerAfter: 0,
        affiliateBonus: 0,
        affiliateDiscount: 0,
        users: [],
      }),
    [append],
  );
  const handleRemoveSuperAffiliateConfig = useMemoizedCallback(
    (index) => () => remove(index),
    [remove],
  );

  const inputType = affiliateDiscountType === 'percent' ? 'number' : undefined;
  const inputFormat = affiliateDiscountType === 'amount' ? 'price' : undefined;

  const InputEndAdorment = () =>
    affiliateDiscountType === 'percent' ? (
      <InputAdornment position="start">%</InputAdornment>
    ) : null;

  return (
    <Paper elevation={0} className={clsx(styles.root, className)}>
      <IconButton className={styles.deleteButton} onClick={handleDelete}>
        <DeleteOutlineIcon />
      </IconButton>

      <Grid direction="column" container spacing={1}>
        <Grid
          className={styles.firstRow}
          item
          container
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Input
              className={styles.input}
              data-testid={makeName('installmentCount')}
              control={control}
              defaultValue={installmentCount}
              disabled={isLoading}
              label={'# of payments'}
              name={makeName('installmentCount')}
              errorText={get(errors, makeName('installmentCount.message'))}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item>
            <Input
              className={styles.input}
              data-testid={makeName('amount')}
              control={control}
              defaultValue={amount}
              disabled={isLoading}
              label="Amount per"
              format="price"
              name={makeName('amount')}
              errorText={get(errors, makeName('amount.message'))}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item>
            <Typography className={styles.total} variant="subtitle1" noWrap>
              Total:{' '}
              {live
                ? ensurePrice(unformat(amount) * installmentCount, {
                    compact: false,
                  })
                : 0}
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Button
            onClick={handleExpand}
            variant="text"
            endIcon={
              <ChevronRightIcon
                className={clsx(
                  styles.chevron,
                  !expanded && styles.chevronDown,
                )}
              />
            }
          >
            Show affiliate settings
          </Button>
          <Collapse in={expanded}>
            <Grid
              className={styles.gridTopSpacing}
              container
              spacing={1}
              direction="column"
            >
              <Grid className={styles.firstRow} item container spacing={2}>
                <Grid item>
                  <Input
                    className={styles.input}
                    data-testid={makeName('affiliateDiscount')}
                    control={control}
                    defaultValue={affiliateDiscount}
                    disabled={isLoading}
                    label="Discount"
                    name={makeName('affiliateDiscount')}
                    type={inputType}
                    format={inputFormat}
                    errorText={get(
                      errors,
                      makeName('affiliateDiscount.message'),
                    )}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ endAdornment: <InputEndAdorment /> }}
                  />
                </Grid>
                <Grid item>
                  <Input
                    className={styles.input}
                    data-testid={makeName('affiliateBonus')}
                    control={control}
                    defaultValue={affiliateBonus}
                    disabled={isLoading}
                    label="Bonus"
                    name={makeName('affiliateBonus')}
                    type={inputType}
                    format={inputFormat}
                    errorText={get(errors, makeName('affiliateBonus.message'))}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ endAdornment: <InputEndAdorment /> }}
                  />
                </Grid>
              </Grid>

              {superAffiliateConfigs && superAffiliateConfigs.length ? (
                <Grid item>
                  <Typography variant="subtitle1" className={styles.subtitle}>
                    Super Affiliates Setup
                  </Typography>
                </Grid>
              ) : null}

              {superAffiliateConfigs && superAffiliateConfigs.length
                ? superAffiliateConfigs.map((config, index) => {
                    const makeName_ = (suffix) =>
                      makeName(`superAffiliateConfig.${index}.${suffix}`);

                    return (
                      <Grid key={config.key} item>
                        <Paper
                          elevation={0}
                          className={clsx(styles.root, className)}
                        >
                          <IconButton
                            className={styles.deleteButton}
                            onClick={handleRemoveSuperAffiliateConfig(index)}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>

                          <Grid direction="column" container spacing={1}>
                            <Grid
                              className={styles.firstRow}
                              item
                              container
                              spacing={2}
                            >
                              <Grid item>
                                <Input
                                  className={styles.input}
                                  data-testid={makeName_('triggerAfter')}
                                  control={control}
                                  defaultValue={config.triggerAfter}
                                  disabled={isLoading}
                                  label="Trigger after"
                                  name={makeName_('triggerAfter')}
                                  type="number"
                                  errorText={get(
                                    errors,
                                    makeName_('triggerAfter.message'),
                                  )}
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    endAdornment: <InputEndAdorment />,
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Input
                                  className={styles.input}
                                  data-testid={makeName_('affiliateDiscount')}
                                  control={control}
                                  defaultValue={config.affiliateDiscount}
                                  disabled={isLoading}
                                  label="Discount"
                                  name={makeName_('affiliateDiscount')}
                                  type={inputType}
                                  format={inputFormat}
                                  errorText={get(
                                    errors,
                                    makeName_('affiliateDiscount.message'),
                                  )}
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    endAdornment: <InputEndAdorment />,
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Input
                                  className={styles.input}
                                  data-testid={makeName_('affiliateBonus')}
                                  control={control}
                                  defaultValue={config.affiliateBonus}
                                  disabled={isLoading}
                                  label="Bonus"
                                  name={makeName_('affiliateBonus')}
                                  type={inputType}
                                  format={inputFormat}
                                  errorText={get(
                                    errors,
                                    makeName_('affiliateBonus.message'),
                                  )}
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    endAdornment: <InputEndAdorment />,
                                  }}
                                />
                              </Grid>
                            </Grid>

                            <Grid item>
                              <UserSearchInput
                                data-testid={makeName_('users')}
                                control={control}
                                disabled={isLoading}
                                defaultValue={config.users || []} // REQUIRED!
                                label="Apply to users"
                                name={makeName_('users')}
                                errorText={get(errors, 'users.message')}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })
                : null}

              <Grid item>
                <Button
                  onClick={handleAddSuperAffiliateConfig}
                  variant="text"
                  startIcon={<AddIcon />}
                >
                  Add super affiliate setup
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
};

PaymentOption.propTypes = {
  index: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,

  installmentCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  affiliateDiscount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  affiliateBonus: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  affiliateDiscountType: PropTypes.string.isRequired,
};

export default PaymentOption;
