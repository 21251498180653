import PropTypes from 'prop-types';
import clsx from 'clsx';

import FileUploadIcon from 'Icons/file-upload';

import { Close as CloseIcon, Link as LinkIcon } from '@material-ui/icons';
import {
  makeStyles,
  IconButton,
  Typography,
  Grid,
  useMediaQuery,
} from '@material-ui/core';

import WideButton from './wide-button';
import ContentPaper from './content-paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxHeight: 80,
    minHeight: 80,
    overflow: 'hidden',
    paddingLeft: 0,
    padding: theme.spacing(2, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 4),
    },
  },
  button: {
    width: '100%',
  },
  buttonMargin: {
    marginTop: theme.spacing(2),
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

const UploadVideoGroup = (props) => {
  const {
    className,
    video,
    onDelete,
    onUpload,
    onPasteUrl,
    disabled,
    uploadButtonLabel,
    pasteUrlButtonLabel,
    ...rest
  } = props;

  const styles = useStyles();
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Grid container spacing={2} {...rest}>
      {video ? (
        <Grid item xs={12} sm={12}>
          <ContentPaper
            className={styles.paper}
            containerClassName={styles.content}
            actions={
              <IconButton onClick={onDelete}>
                <CloseIcon />
              </IconButton>
            }
          >
            <Typography variant="h6">
              {video.fileName || 'Unnamed Video'}
            </Typography>
          </ContentPaper>
        </Grid>
      ) : null}

      <Grid item xs={12} sm={6}>
        <WideButton
          component="div"
          className={clsx(styles.button, video && styles.buttonMargin)}
          color="primary"
          startIcon={<FileUploadIcon />}
          disabled={disabled}
          onClick={onUpload}
        >
          {uploadButtonLabel}
        </WideButton>
      </Grid>

      <Grid item xs={12} sm={6}>
        <WideButton
          color="primary"
          startIcon={<LinkIcon />}
          className={clsx(
            styles.button,
            isSmUp && video && styles.buttonMargin,
          )}
          disabled={disabled}
          onClick={onPasteUrl}
        >
          {pasteUrlButtonLabel}
        </WideButton>
      </Grid>
    </Grid>
  );
};

UploadVideoGroup.propTypes = {
  video: PropTypes.shape({
    url: PropTypes.string.isRequired,
    fileName: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  onUpload: PropTypes.func,
  onPasteUrl: PropTypes.func,
  disabled: PropTypes.bool,
  uploadButtonLabel: PropTypes.string.isRequired,
  pasteUrlButtonLabel: PropTypes.string.isRequired,
};

export default UploadVideoGroup;
