import instance from 'Api/instance';

export const getPaymentMethod = (paymentMethodId) =>
  instance.get(`/payment-methods/` + paymentMethodId);

export const getPaymentMethods = (userId) =>
  instance.get(`/users/` + userId + '/payment-methods');

export const deletePaymentMethod = (paymentMethodId) =>
  instance.delete(`/payment-methods/` + paymentMethodId);

export const createPaymentMethod = (paymentMethodId, isDefault) =>
  instance.post(`/payment-methods`, {
    data: { attributes: { paymentMethodId, isDefault } },
  });

export const setDefaultPaymentMethod = (paymentMethodId) =>
  instance.post(`/payment-methods/` + paymentMethodId + '/set-default');
