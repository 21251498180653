import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import countries from 'Util/countries';
import {
  selectMe,
  selectConfirmBoxsetShippingAddressRequest,
  selectCourses,
} from 'Redux/selectors';
import { confirmBoxsetShippingAddress } from 'Redux/actions';
import Select from 'Components/shared/select';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button, Grid, Typography, Link } from '@material-ui/core';

const options = countries.map((c) => ({ value: c.code, label: c.name }));

const schema = yup.object().shape({
  firstName: yup.string().trim().required('Field Required.'),
  lastName: yup.string().trim().required('Field Required.'),
  zipCode: yup.string().trim().required('Field Required.'),
  addressLine: yup.string().trim().required('Field Required.'),
  addressLine2: yup.string().trim(),
  city: yup.string().trim().required('Field Required.'),
  country: yup.string().trim().required('Field Required.'),
  phoneNumber: yup.string().trim().required('Field Required.'),
  state: yup.string().trim(),
});

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    overflowX: 'hidden',
  },
  actions: {
    marginTop: 0,
  },
  nameInput: {
    width: '100%',
    marginBottom: -18,

    [theme.breakpoints.up('md')]: {
      maxWidth: 280,
    },
  },
  phoneNumberInput: {
    width: '100%',
    marginBottom: -18,
    [theme.breakpoints.up('md')]: {
      maxWidth: 360,
    },
  },
  cityInput: {
    width: '100%',
    marginBottom: -18,
    [theme.breakpoints.up('md')]: {
      maxWidth: 380,
    },
  },
  inputLong: {
    width: '100%',
    marginBottom: -18,
    [theme.breakpoints.up('md')]: {
      // maxWidth: 380,
    },
  },
  stateLine: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 460,
    },
  },
  breakAll: {
    wordBreak: 'break-all',
    '@media only screen and (min-width: 360px)': {
      wordBreak: 'break-word',
    },
  },
}));

const ConfirmShippingAddressDialog = (props) => {
  const { courseId, onClose } = props;

  const styles = useStyles();
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const courses = useSelector(selectCourses);
  const { isLoading } = useSelector(selectConfirmBoxsetShippingAddressRequest);

  const course = courses.find((c) => courseId === c.id);

  const { control, handleSubmit, errors, watch } = useForm({
    defaultValues: me,
    validationSchema: schema,
  });
  const country = watch('country');

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose(submitted);
    }
  }, [onClose, submitted]);

  const handleUpdate = useCallback(
    (data) => {
      data.phoneNumber =
        '+' +
        countries.find((c) => c.code === data.country).numberPrefix +
        data.phoneNumber;

      dispatch(
        confirmBoxsetShippingAddress(
          { courseId, ...data },
          { isPromise: true },
        ),
      ).then(() => setSubmitted(true));
    },
    [dispatch, courseId],
  );

  if (!course) {
    return null;
  }

  return (
    <Dialog
      actionsClassName={styles.actions}
      contentClassName={styles.content}
      title={course.title + ' Boxset Ready to Ship!'}
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleUpdate)}
      onClose={handleClose}
      cancelButton={
        <Button data-testid="cancel">{submitted ? 'Close' : 'Later'}</Button>
      }
      submitButton={
        submitted ? null : <Button data-testid="submit">Confirm</Button>
      }
    >
      {submitted ? (
        <>
          <Typography gutterBottom className={styles.title} variant="body1">
            Shipping address is now updated! You will receive a tracking number
            once the Boxset ships out.
          </Typography>
          <Typography className={styles.title} variant="body1">
            If you have any questions, feel free to reach out to{' '}
            <Link
              className={styles.breakAll}
              href="mailto:support@ecomfreedom.com"
            >
              support@ecomfreedom.com
            </Link>
            .
          </Typography>
        </>
      ) : (
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography className={styles.title} variant="body1">
              Please confirm your shipping address is correct.
            </Typography>
            <Typography
              gutterBottom
              className={styles.title}
              variant="subtitle1"
            >
              The Boxset will be shipped using the information bellow.
            </Typography>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={12} sm={6} lg={6}>
              <Input
                data-testid="firstName"
                className={styles.nameInput}
                control={control}
                label="First Name"
                name="firstName"
                autoComplete="given-name"
                disabled={isLoading}
                errorText={errors?.firstName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <Input
                data-testid="lastName"
                className={styles.nameInput}
                control={control}
                label="Last Name"
                autoComplete="family-name"
                name="lastName"
                disabled={isLoading}
                errorText={errors?.lastName?.message}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Input
              data-testid="addressLine"
              className={styles.inputLong}
              control={control}
              label="Address Line 1"
              name="addressLine"
              autoComplete="street-address"
              disabled={isLoading}
              errorText={errors?.addressLine?.message}
            />
          </Grid>
          <Grid item>
            <Input
              data-testid="addressLine2"
              className={styles.inputLong}
              control={control}
              label="Address Line 2"
              name="addressLine2"
              autoComplete="street-address"
              disabled={isLoading}
              errorText={errors?.addressLine2?.message}
            />
          </Grid>
          <Grid item>
            <Input
              data-testid="city"
              className={styles.cityInput}
              control={control}
              label="City Name"
              name="city"
              autoComplete="address-level2"
              disabled={isLoading}
              errorText={errors?.city?.message}
            />
          </Grid>
          <Grid item container spacing={3} className={styles.stateLine}>
            <Grid item xs={12} sm={8} md={12} lg={8}>
              <Input
                data-testid="state"
                className={styles.inputLong}
                control={control}
                label="State/Province"
                name="state"
                autoComplete="address-level1"
                disabled={isLoading}
                errorText={errors?.state?.message}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={12} lg={4}>
              <Input
                data-testid="zipCode"
                className={styles.inputLong}
                control={control}
                label="Zip Code"
                name="zipCode"
                autoComplete="postal-code"
                disabled={isLoading}
                errorText={errors?.zipCode?.message}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Select
              data-testid="country"
              control={control}
              className={styles.inputLong}
              label="Country"
              name="country"
              autoComplete="country"
              disabled={isLoading}
              options={options}
            />
          </Grid>
          <Grid item>
            <Input
              data-testid="phoneNumber"
              className={styles.phoneNumberInput}
              control={control}
              label="Phone Number"
              name="phoneNumber"
              format="phoneNumber"
              autoComplete="tel"
              disabled={isLoading}
              locale={country}
              errorText={errors?.phoneNumber?.message}
            />
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

ConfirmShippingAddressDialog.propTypes = {
  courseId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmShippingAddressDialog;
