import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { filter, find, min, noop } from 'lodash';

import {
  selectCoursesWithGraph,
  selectCoursesRequest,
  selectModulesRequest,
  selectLessonsRequest,
  selectQuestions,
  selectQuestionsRequest,
  selectQuestionsTotalCount,
  selectUpdateLessonRequest,
} from 'Redux/selectors';
import {
  fetchCourses,
  fetchLessons,
  fetchModules,
  fetchQuestions,
  updateLesson,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import PageLoader from 'Components/shared/page-loader';
import WideButton from 'Components/pages/admin/wide-button';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import SectionTitle from 'Components/pages/admin/section-title';
import ContentPaper from 'Components/pages/admin/content-paper';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import {
  Link as LinkIcon,
  Refresh as RefreshIcon,
  HelpOutline as HelpOutlineIcon,
} from '@material-ui/icons';
import { makeStyles, Typography, Grid, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  container: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    width: '100%',
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  loadMoreButton: {
    width: '100%',
    border: 0,
  },
}));

const AdminConnectLessonQuestionPage = () => {
  const dispatch = useDispatch();
  const { courseId, moduleId, lessonId } = useParams();
  const [omitIds, setOmitIds] = useState([]);

  const questionsAll = useSelector(selectQuestions);
  const totalCount = useSelector(selectQuestionsTotalCount);

  const count = questionsAll ? questionsAll.length : 0;

  const courses = useSelector(selectCoursesWithGraph);
  const course = find(courses, ['id', courseId]);
  const module = course ? find(course.modules, ['id', moduleId]) : null;
  const lesson = module ? find(module.lessons, ['id', lessonId]) : null;

  const questions = useMemo(
    () =>
      questionsAll && lesson
        ? filter(
            questionsAll,
            (q) => !lesson.questions.map((q) => q.id).includes(q.id),
          )
        : questionsAll,
    [questionsAll, lesson],
  );

  const styles = useStyles();

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectModulesRequest,
    selectLessonsRequest,
  );
  const { isRequested: isLoadingQuestions, error: questionsError } =
    useSelector(selectQuestionsRequest);

  const { isLoading: isLoadingMutation } = useSelector(
    selectUpdateLessonRequest,
  );

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    if (!module) {
      dispatch(fetchModules({ courseId, skipAccessCheck: true }));
    }
    if (!lesson) {
      dispatch(
        fetchLessons({ courseId, skipAccessCheck: true }, { isPromise: true }),
      )
        .then((payload) => {
          const found = find(payload.data, ['id', lessonId]);
          if (found) {
            const ids = found.questions.map((q) => q.id);
            dispatch(fetchQuestions({ omitIds: ids }));
            setOmitIds(ids);
          }
        })
        .catch(noop);
    } else {
      const ids = lesson.questions.map((q) => q.id);
      dispatch(fetchQuestions({ omitIds: ids }));
      setOmitIds(ids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId, lessonId]);

  const handleLoadMore = useCallback(() => {
    dispatch(
      fetchQuestions({
        omitIds,
        limit: 100,
        offset: count,
      }),
    );
  }, [dispatch, omitIds, count]);

  const handleLink = useMemoizedCallback(
    (questionId) => () => {
      dispatch(
        updateLesson({
          courseId,
          moduleId,
          lessonId,
          questions: [...lesson.questions.map((l) => l.id), questionId],
        }),
      );
      //
    },
    [dispatch, courseId, lesson, lessonId, moduleId],
  );

  if (
    !isLoading &&
    !isLoadingQuestions &&
    (!course || !module || !lesson || !questions)
  ) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader
      loading={isLoading || isLoadingQuestions}
      error={error || questionsError}
    >
      {() => (
        <UserDetailsLayout>
          <Breadcrumbs
            className={styles.breadcrumbs}
            links={[
              { href: '/admin/courses', label: 'Courses' },
              {
                href: `/admin/courses/${courseId}/overview`,
                label: course.title,
              },
              {
                href: `/admin/courses/${courseId}/modules/${moduleId}/overview`,
                label: module.title,
              },
              {
                href: `/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/overview`,
                label: lesson.title,
              },
              { label: 'Questions' },
            ]}
          />

          <div className={styles.container}>
            <TitleAndAction
              className={styles.titleAndAction}
              title={
                <Typography variant="h4">Connect Existing Question</Typography>
              }
            />

            <SectionTitle
              className={styles.sectionTitle}
              icon={<HelpOutlineIcon />}
            >
              Questions
            </SectionTitle>

            <Grid container spacing={1}>
              {!questions.length ? (
                <Grid item>
                  <Typography variant="h6">No Questions Found</Typography>
                </Grid>
              ) : null}

              {questions.map((r) => (
                <Grid key={r.id} item container>
                  <ContentPaper
                    className={styles.paper}
                    actions={
                      <IconButton
                        disabled={isLoadingMutation}
                        onClick={handleLink(r.id)}
                      >
                        <LinkIcon />
                      </IconButton>
                    }
                  >
                    <Typography variant="h6">{r.title}</Typography>
                  </ContentPaper>
                </Grid>
              ))}

              {totalCount > count ? (
                <Grid item container>
                  <WideButton
                    color="primary"
                    startIcon={<RefreshIcon />}
                    className={styles.loadMoreButton}
                    onClick={handleLoadMore}
                    disabled={isLoading}
                  >
                    Load {min([100, totalCount - count])} More
                  </WideButton>
                </Grid>
              ) : null}
            </Grid>
          </div>
        </UserDetailsLayout>
      )}
    </PageLoader>
  );
};

export default AdminConnectLessonQuestionPage;
