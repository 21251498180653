import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPDATE_MODULE_SUCCESS } from './update-module';
import { SORT_MODULES_ERROR, SORT_MODULES_SUCCESS } from './sort-modules';
import { CREATE_MODULE_SUCCESS } from './create-module';

const MODULE = 'modules';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchModules = Exec.requestActionCreator(MODULE);
export const fetchModulesLoading = Exec.loadingActionCreator(MODULE);
export const fetchModulesSuccess = Exec.successActionCreator(MODULE);
export const fetchModulesError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);
export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_MODULE_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((v) => (v.id === data.id ? data : v)),
      };
    }
    case CREATE_MODULE_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };
    }
    case SORT_MODULES_ERROR: {
      if (!state || !state.data || !action.payload.error.data) {
        return state;
      }
      return {
        ...state,
        data: action.payload.error.data,
      };
    }
    case SORT_MODULES_SUCCESS: {
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: action.payload.data,
      };
    }
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectModulesRequest = Exec.fetchRequestSelectorCreator(MODULE);
export const selectModules = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchModules = function* ({ payload, meta }) {
  try {
    const { data } = yield call(
      Api.getModules,
      payload.courseId,
      payload.skipAccessCheck,
    );
    yield put(fetchModulesSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchModulesError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchModules);
