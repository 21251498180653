import { put, call } from 'redux-saga/effects';
import { get, toNumber } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'affiliate-redeem-requests';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const FETCH_PAGE_SIZE = 5; // this can be dynamic
export const FETCH_AFFILIATE_REDEEM_REQUESTS_SUCCESS =
  Exec.successConstantCreator(MODULE);
export const FETCH_AFFILIATE_REDEEM_REQUESTS_ERROR =
  Exec.errorConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
const defaultFetchAffiliateRedeemRequests = Exec.requestActionCreator(MODULE);
export const fetchAffiliateRedeemRequests = (payload, options) => {
  const page = get(payload, 'page', 0);
  return defaultFetchAffiliateRedeemRequests(payload, {
    isAppending: page > 0,
    ...options,
  });
};
export const fetchAffiliateRedeemRequestsLoading =
  Exec.loadingActionCreator(MODULE);
export const fetchAffiliateRedeemRequestsSuccess =
  Exec.successActionCreator(MODULE);
export const fetchAffiliateRedeemRequestsError =
  Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectAffiliateRedeemRequestsRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectAffiliateRedeemRequests = Exec.dataSelectorCreator(MODULE);

export const selectAffiliateRedeemRequestsRowsPerPage = () => FETCH_PAGE_SIZE;
export const selectAffiliateRedeemRequestsTotalCount = (store) =>
  store.affiliateRedeemRequests.pageInfo
    ? store.affiliateRedeemRequests.pageInfo.totalCount
    : 0;

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchAffiliateRedeemRequests = function* ({ payload, meta }) {
  const offset = FETCH_PAGE_SIZE * toNumber(payload ? payload.page : 0);

  try {
    const { data, __meta = {} } = yield call(
      Api.getAffiliateRedeemRequests,
      FETCH_PAGE_SIZE,
      offset,
    );
    yield put(
      fetchAffiliateRedeemRequestsSuccess(
        { data, pageInfo: __meta.pageInfo },
        meta,
      ),
    );
  } catch (err) {
    yield put(fetchAffiliateRedeemRequestsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchAffiliateRedeemRequests);
