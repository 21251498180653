import PropTypes from 'prop-types';
import { toLower } from 'lodash';
import clsx from 'clsx';

import UserAvatar from 'Components/shared/user-avatar';

import {
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: theme.shadows[4],
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  item: {
    minHeight: 104,
    padding: theme.spacing(2.5, 0),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(5),
    },
  },
  text: {
    '& strong': {
      fontWeight: 500,
    },
    '& strong:last-child': {
      color: theme.palette.secondary.main,
    },
  },
  flag: {
    marginLeft: theme.spacing(4),
    minWidth: 40,
    minHeight: 40,
    borderRadius: '50%',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const AchievementList = (props) => {
  const { className, achievements } = props;

  const styles = useStyles();

  return (
    <List
      className={clsx(styles.root, className)}
      disablePadding
      component={Paper}
    >
      {achievements.map(({ id, title, user }, index) => (
        <ListItem
          key={id}
          divider={achievements.length > index + 1}
          className={styles.item}
        >
          <ListItemAvatar>
            <UserAvatar size={48} user={user} />
          </ListItemAvatar>
          <ListItemText>
            <Typography
              className={styles.text}
              variant="body1"
              component="span"
            >
              <strong>{user.firstName + ' ' + user.lastName}</strong> has earned
              the <strong>{title}</strong> achievement
            </Typography>
          </ListItemText>
          <div
            className={clsx(
              'flag-icon-background',
              'flag-icon-squared',
              'flag-icon-' + toLower(user.country),
              styles.flag,
            )}
          />
        </ListItem>
      ))}
    </List>
  );
};

AchievementList.propTypes = {
  achievements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
    }),
  ),
};

export default AchievementList;
