import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { fetchSubscriptionStepsSuccess } from './subscription-steps';
import { enqueueSnackbar } from './snackbar';

const MODULE = 'confirm-boxset-shipping-address';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CONFIRM_BOXSET_SHIPPING_ADDRESS_REQUEST =
  Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const confirmBoxsetShippingAddress = Exec.requestActionCreator(MODULE);
export const confirmBoxsetShippingAddressLoading =
  Exec.loadingActionCreator(MODULE);
export const confirmBoxsetShippingAddressSuccess =
  Exec.successActionCreator(MODULE);
export const confirmBoxsetShippingAddressError =
  Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectConfirmBoxsetShippingAddressRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doConfirmBoxsetShippingAddress = function* ({ payload, meta }) {
  const { courseId, ...shippingAddress } = payload;
  try {
    yield put(confirmBoxsetShippingAddressLoading());

    const { data } = yield call(
      Api.confirmBoxsetShippingAddress,
      courseId,
      shippingAddress,
    );

    yield put(fetchSubscriptionStepsSuccess({ data }));
    yield put(confirmBoxsetShippingAddressSuccess(null, meta));
  } catch (err) {
    yield put(confirmBoxsetShippingAddressError(err, meta));
    yield put(
      enqueueSnackbar(CONFIRM_BOXSET_SHIPPING_ADDRESS_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : 'There was an error, try again later or email support@ecomfreedom.com',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doConfirmBoxsetShippingAddress);
