import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { memo } from 'react';

import {
  selectAchievementLevels,
  selectCourses,
  selectMe,
} from 'Redux/selectors';
import ToolIcon from 'Icons/tool';
import SupportIcon from 'Icons/support';
import HelpCenterIcon from 'Icons/help-center';
import FacebookIcon from 'Icons/facebook';
import ConstructionIcon from 'Icons/construction';
import CalculateIcon from 'Icons/calculate';
import ConfirmSignOutDialog from 'Dialogs/confirm-sign-out-dialog';

import {
  Apps as AppsIcon,
  AccountCircle as AccountCircleIcon,
  QuestionAnswer as QuestionAnswerIcon,
  HelpOutline as HelpOutlineIcon,
  Explore as ExploreIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Sports as SportsIcon,
  Face as FaceIcon,
  EmojiEvents as EmojiEventsIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
  Dashboard as DashboardIcon,
  GroupAdd as GroupAddIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  MenuItem,
  Grid,
  IconButton,
} from '@material-ui/core';

import DropdownMenu from './dropdown-menu';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const HeaderItems = (props) => {
  const { className } = props;

  const styles = useStyles();
  const me = useSelector(selectMe);
  const courses = useSelector(selectCourses);
  const levels = useSelector(selectAchievementLevels);

  const [showSignoutModal, hideSignoutModal] = useModal(() => (
    <ConfirmSignOutDialog onClose={hideSignoutModal} />
  ));

  const isXsUp = useMediaQuery((theme) => theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isXlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  if (!isXsUp) {
    return null;
  }

  const coursesWithResourceVault = courses
    ? courses
        .filter(
          (c) => c.purchasedAt && ['amazon-fba', 'shopify'].includes(c.slug),
        )
        .map((c) => c.slug)
    : [];

  const coursesWithMentorship = courses
    ? courses.filter((c) => c.isMentorshipEnabled)
    : [];

  const coursesWithAffiliateLinks = courses
    ? courses.filter((c) => c.supportsAffiliateLinks)
    : [];

  return (
    <Grid
      className={className}
      container
      direction="row"
      wrap="nowrap"
      spacing={2}
      alignItems="center"
      justify="flex-end"
    >
      <Grid item>
        {isLgUp ? (
          <Button
            aria-label="courses"
            color="inherit"
            startIcon={<AppsIcon />}
            component={Link}
            to="/"
            data-testid="myProducts"
          >
            Courses
          </Button>
        ) : (
          <IconButton
            edge="end"
            aria-label="courses"
            component={Link}
            to="/"
            color="inherit"
            data-testid="myProducts"
          >
            <AppsIcon />
          </IconButton>
        )}
      </Grid>
      {isSmUp && coursesWithResourceVault.length ? (
        <Grid item>
          {isLgUp ? (
            <Button
              aria-label="resource vaults"
              color="inherit"
              startIcon={<ToolIcon />}
              data-testid="resources"
              component={Link}
              to={`/help/resource-vault/${coursesWithResourceVault[0]}`}
            >
              resources
            </Button>
          ) : (
            <IconButton
              edge="end"
              aria-label="resource vaults"
              color="inherit"
              data-testid="resources"
              component={Link}
              to={`/help/resource-vault/${coursesWithResourceVault[0]}`}
            >
              <ToolIcon />
            </IconButton>
          )}
        </Grid>
      ) : null}
      {isSmUp && coursesWithMentorship.length ? (
        <Grid item>
          <DropdownMenu
            control={
              isLgUp ? (
                <Button
                  aria-label="mentorship options"
                  color="inherit"
                  startIcon={<QuestionAnswerIcon />}
                  data-testid="community"
                >
                  mentorship
                </Button>
              ) : (
                <IconButton
                  edge="end"
                  aria-label="mentorship options"
                  color="inherit"
                  data-testid="community"
                >
                  <QuestionAnswerIcon />
                </IconButton>
              )
            }
          >
            <MenuItem
              component={Link}
              to="/help/mentorship"
              data-testid="getMentorship"
            >
              <SupervisorAccountIcon className={styles.icon} />
              How To Get Mentorship
            </MenuItem>
            <MenuItem
              component={Link}
              to="/facebook-groups"
              data-testid="facebookGroups"
            >
              <FacebookIcon className={styles.icon} />
              Facebook Groups
            </MenuItem>
            {me?.enrolledCourseIds?.length ? (
              <MenuItem
                component={Link}
                to="/mentorship-calls"
                data-testid="mentorshipCalls"
              >
                <SportsIcon className={styles.icon} />
                Live Mentorship Calls
              </MenuItem>
            ) : null}
          </DropdownMenu>
        </Grid>
      ) : null}
      {isMdUp && !process.env.REACT_APP_DISABLE_TOOLS ? (
        <Grid item>
          <DropdownMenu
            control={
              isXlUp ? (
                <Button
                  aria-label="tools"
                  color="inherit"
                  startIcon={<ConstructionIcon />}
                  data-testid="tools"
                >
                  tools
                </Button>
              ) : (
                <IconButton
                  aria-label="tools"
                  edge="end"
                  color="inherit"
                  data-testid="tools"
                >
                  <ConstructionIcon />
                </IconButton>
              )
            }
          >
            <MenuItem
              component="a"
              href="https://www.ecomfreedom.com/profitcalculator"
              data-testid="profitCalculator"
            >
              <CalculateIcon className={styles.icon} />
              Profit Calculator
            </MenuItem>
            <MenuItem
              component="a"
              href="https://www.ecomfreedom.com/ecomradar"
              data-testid="ecomRadar"
            >
              <ExploreIcon className={styles.icon} />
              Ecom Radar
            </MenuItem>
          </DropdownMenu>
        </Grid>
      ) : null}
      {isSmUp &&
      me?.enrolledCourseIds?.length &&
      coursesWithAffiliateLinks.length ? (
        <Grid item>
          {isXlUp ? (
            <Button
              aria-label="Refer a friend"
              color="inherit"
              startIcon={<GroupAddIcon />}
              component={Link}
              to="/referrals"
              data-testid="referAFriend"
            >
              Refer a friend
            </Button>
          ) : (
            <IconButton
              edge="end"
              aria-label="Refer a friend"
              component={Link}
              to="/referrals"
              color="inherit"
              data-testid="referAFriend"
            >
              <GroupAddIcon />
            </IconButton>
          )}
        </Grid>
      ) : null}
      {isMdUp && !process.env.REACT_APP_DISABLE_SUPPORT ? (
        <Grid item>
          <DropdownMenu
            control={
              isXlUp ? (
                <Button
                  aria-label="help options"
                  color="inherit"
                  startIcon={<HelpOutlineIcon />}
                  data-testid="support"
                >
                  support
                </Button>
              ) : (
                <IconButton
                  edge="end"
                  aria-label="help options"
                  color="inherit"
                  data-testid="support"
                >
                  <HelpOutlineIcon />
                </IconButton>
              )
            }
          >
            <MenuItem component={Link} to="/get-help" data-testid="getHelp">
              <SupportIcon className={styles.icon} />
              How To Get Help
            </MenuItem>
            <MenuItem component={Link} to="/help" data-testid="helpCenter">
              <HelpCenterIcon className={styles.icon} />
              Help Center
            </MenuItem>
          </DropdownMenu>
        </Grid>
      ) : null}
      <Grid item>
        <DropdownMenu
          control={
            isLgUp ? (
              <Button
                aria-label="account of current user"
                color="inherit"
                startIcon={<AccountCircleIcon />}
                data-testid="account"
              >
                hi {me ? me.firstName.toLowerCase() : ''}!
              </Button>
            ) : (
              <IconButton
                edge="end"
                aria-label="account of current user"
                color="inherit"
                data-testid="account"
              >
                <AccountCircleIcon />
              </IconButton>
            )
          }
        >
          <MenuItem component={Link} to="/profile" data-testid="myProfile">
            <FaceIcon className={styles.icon} />
            Your Profile
          </MenuItem>
          {levels && levels.length ? (
            <MenuItem
              component={Link}
              to="/achievements"
              data-testid="achievements"
            >
              <EmojiEventsIcon className={styles.icon} />
              Your Rewards
            </MenuItem>
          ) : null}
          <MenuItem component={Link} to="/account" data-testid="settings">
            <SettingsIcon className={styles.icon} />
            Account Settings
          </MenuItem>
          <MenuItem onClick={showSignoutModal} data-testid="signout">
            <ExitToAppIcon className={styles.icon} />
            Sign Out
          </MenuItem>
          {me && me.roleId === 'ROLE_ADMIN' && (
            <MenuItem component={Link} to="/admin" data-testid="admin">
              <DashboardIcon className={styles.icon} />
              Admin
            </MenuItem>
          )}
        </DropdownMenu>
      </Grid>
    </Grid>
  );
};

HeaderItems.whyDidYouRender = true;

export default memo(HeaderItems);
