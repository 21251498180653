import instance from 'Api/instance';

export const getLessons = (courseId, skipAccessCheck) =>
  instance.get('/courses/' + courseId + '/lessons', {
    params: { skipAccessCheck },
  });

export const getLastLesson = (courseId) =>
  instance.get('/courses/' + courseId + '/lessons/last-viewed');

export const recordLessonProgress = (courseId, moduleId, lessonId, timestamp) =>
  instance.post(
    `/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/record-progress`,
    {
      data: { attributes: { timestamp } },
    },
  );

export const createLesson = (courseId, moduleId, attributes) =>
  instance.post('/courses/' + courseId + '/modules/' + moduleId + '/lessons', {
    data: { attributes },
  });

export const updateLesson = (courseId, moduleId, lessonId, attributes) =>
  instance.patch(
    '/courses/' + courseId + '/modules/' + moduleId + '/lessons/' + lessonId,
    {
      data: { attributes },
    },
  );

export const sortLessons = (courseId, attributes) =>
  instance.post('/courses/' + courseId + '/lessons/sort', {
    data: { attributes },
  });
