import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Edit as EditIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

import ContentPaper from './content-paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
  },
  moduleTitle: {
    marginBottom: theme.spacing(1),
  },
  visibilityIcon: {
    color: theme.palette.warning.dark,
    fontSize: 20,
    marginLeft: 8,
    marginBottom: -3,
  },
}));

const ModuleItem = (props) => {
  const {
    id,
    courseId,
    isVisible,
    title,
    summary,
    lessonsCount,
    achievementsCount,
    daysToUnlock,
  } = props;
  const styles = useStyles();

  const { active, attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: active && active.id === props.id ? 10 : undefined,
  };

  return (
    <ContentPaper
      style={style}
      ref={setNodeRef}
      className={styles.paper}
      actions={
        <IconButton
          component={Link}
          to={`/admin/courses/${courseId}/modules/${id}/overview`}
        >
          <EditIcon />
        </IconButton>
      }
      {...listeners}
      {...attributes}
    >
      <Typography variant="h6" className={styles.moduleTitle}>
        {title} - {summary}
        {!isVisible ? (
          <VisibilityOffIcon className={styles.visibilityIcon} />
        ) : null}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {lessonsCount ? lessonsCount : 'No'} Lessons
        {'  •  '}
        {achievementsCount ? achievementsCount : 'No'} Achievements
        {daysToUnlock
          ? `  •  Unlocks after ${daysToUnlock} day${
              daysToUnlock > 1 ? 's' : ''
            }`
          : ''}
      </Typography>
    </ContentPaper>
  );
};

ModuleItem.propTypes = {
  id: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  lessonsCount: PropTypes.number.isRequired,
  achievementsCount: PropTypes.number.isRequired,
  daysToUnlock: PropTypes.number.isRequired,
};

export default ModuleItem;
