import { useCallback, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { Close as CloseIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

const SnackbarAction = ({ id, render }) => {
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [closeSnackbar, id]);

  if (render) {
    return isValidElement(render)
      ? cloneElement(render, { onClick: handleDismiss })
      : render;
  }

  return (
    <IconButton color="inherit" onClick={handleDismiss}>
      <CloseIcon />
    </IconButton>
  );
};

SnackbarAction.propTypes = {
  id: PropTypes.string,
  render: PropTypes.any,
};

export default SnackbarAction;
