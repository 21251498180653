import { isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import clsx from 'clsx';

import { Close as CloseIcon } from '@material-ui/icons';
import {
  makeStyles,
  Dialog as MuiDialog,
  Typography,
  IconButton,
  darken,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(10, 0, 6),
    padding: theme.spacing(4, 0),
    borderRadius: 16,
    overflowY: 'inherit',
    position: 'relative',
    width: `100%`,
  },
  paperMaxHeight: {
    maxHeight: `calc(100% - ${theme.spacing(16)}px)`,
  },
  widthSm: {
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 560,
    },
  },
  title: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 4),
    },
  },
  content: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: 'rgba(0, 0, 0, .05)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, .2)',
    },
    padding: theme.spacing(1, 2, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 4, 0),
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 4),
    },
  },
  button: {
    margin: theme.spacing(0, 1),
  },
  floatingButton: {
    zIndex: 99999,
    position: 'absolute',
    right: 0,
    top: -48 - theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: darken(theme.palette.background.paper, 0.1),
    },
  },
}));

const Dialog = (props) => {
  const {
    interruptible,
    title,
    component,
    ComponentProps,
    loading,
    onClose,
    cancelButton,
    submitButton,
    children,
    className,
    contentClassName,
    actionsClassName,
    scroll,
    ...rest
  } = props;
  const styles = useStyles();

  const renderedCancelButton = isValidElement(cancelButton)
    ? cloneElement(cancelButton, {
        type: 'button',
        onClick: cancelButton.props.onClick || onClose,
        disabled: interruptible ? cancelButton.props.disabled : loading,
        variant: 'outlined',
        color: 'primary',
        className: clsx(cancelButton.props.className, styles.button),
      })
    : cancelButton;

  const renderedSubmitButton = isValidElement(submitButton)
    ? cloneElement(submitButton, {
        type: 'submit',
        disabled: loading,
        variant: 'contained',
        color: submitButton.props.color || 'primary',
        className: clsx(submitButton.props.className, styles.button),
      })
    : submitButton;

  return (
    <MuiDialog
      open
      scroll={scroll}
      onClose={loading && !interruptible ? noop : onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: clsx(
          styles.paper,
          scroll === 'paper' ? styles.paperMaxHeight : null,
          className,
        ),
        paperWidthSm: styles.widthSm,
      }}
      PaperProps={{ component, ...rest }}
    >
      <IconButton
        data-testid="closeDialog"
        className={styles.floatingButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      {title ? (
        <Typography
          id="alert-dialog-title"
          variant="h6"
          align="center"
          className={styles.title}
        >
          {title}
        </Typography>
      ) : null}
      <div
        id="alert-dialog-description"
        className={clsx(styles.content, contentClassName)}
      >
        {children}
      </div>
      {renderedCancelButton || renderedSubmitButton ? (
        <div className={clsx(styles.actions, actionsClassName)}>
          {renderedCancelButton}
          {renderedSubmitButton}
        </div>
      ) : null}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  contentClassName: PropTypes.string,
  actionsClassName: PropTypes.string,
  interruptible: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  ComponentProps: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  cancelButton: PropTypes.element,
  submitButton: PropTypes.element,
  scroll: PropTypes.oneOf(['paper', 'body']),
};

Dialog.defaultProps = {
  scroll: 'paper',
};

export default Dialog;
