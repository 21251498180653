import instance from 'Api/instance';

export const getPaymentLink = (paymentLinkId) =>
  instance.get(`/payment-links/${paymentLinkId}`);

export const getPaymentLinks = (searchOptions, limit = 10, offset = 0) =>
  instance.get('/payment-links', {
    params: { limit, offset, ...searchOptions },
  });

export const deletePaymentLink = (paymentLinkId) =>
  instance.delete('/payment-links/' + paymentLinkId);

export const updatePaymentLink = (paymentLinkId, attributes) =>
  instance.patch('/payment-links/' + paymentLinkId, { data: { attributes } });

export const createPaymentLink = (attributes) =>
  instance.post('/payment-links', { data: { attributes } });
