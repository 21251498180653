/* eslint-disable react/prop-types */
import { isValidElement, cloneElement, forwardRef } from 'react';
import clsx from 'clsx';

import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 84,
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexWrap: 'nowrap',
    textDecoration: 'none',

    padding: theme.spacing(3, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 4),
    },
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  actions: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    marginRight: theme.spacing(-0.5),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(-1.5),
    },
  },
}));

const ContentPaper = (props, ref) => {
  const { className, containerClassName, children, actions, ...rest } = props;

  const styles = useStyles();

  const renderedActions = isValidElement(actions)
    ? cloneElement(actions, {
        className: clsx(actions.props.className, styles.actions),
      })
    : actions;

  return (
    <Paper ref={ref} className={clsx(styles.root, className)} {...rest}>
      <div className={clsx(styles.container, containerClassName)}>
        {children}
      </div>
      {renderedActions}
    </Paper>
  );
};

export default forwardRef(ContentPaper);
