import { useSelector, useDispatch } from 'react-redux';
import { useMemo } from 'react';

import {
  selectAchievementLevelsRequest,
  selectAchievementStats,
  selectAchievementStatsRequest,
  selectCourses,
  selectCoursesRequest,
  selectMeRequest,
} from 'Redux/selectors';
import { fetchAchievementStats, fetchCourses } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import Divider from 'Components/shared/divider';
import CourseCardSmall from 'Components/shared/course-card-small';
import AchievementLevels from 'Components/shared/achievement-levels';
import Boxes from 'Components/pages/achievements/boxes';

import { makeStyles, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(7),
  },
  levels: {
    marginBottom: theme.spacing(6),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  boxes: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    marginLeft: theme.spacing(-1),
    marginBottom: theme.spacing(8),

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  courses: {
    width: `calc(100% + ${theme.spacing(2)}px)`,
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  course: {
    maxWidth: 328,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AchievementsPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { isLoading, error } = useCombinedRequestsSelector(
    selectMeRequest,
    selectCoursesRequest,
    selectAchievementStatsRequest,
    selectAchievementLevelsRequest,
  );

  const courses = useSelector(selectCourses);
  const stats = useSelector(selectAchievementStats);

  useScopedDispatchEffect(() => {
    if (!courses || !courses.length) {
      dispatch(fetchCourses());
    }
    dispatch(fetchAchievementStats());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statsArray = useMemo(
    () =>
      stats
        ? [
            { label: 'Earned', value: stats.userEarnedCount },
            {
              label: 'Missing',
              value: stats.totalCount - stats.userEarnedCount,
            },
            { label: 'Total', value: stats.totalCount },
          ]
        : null,
    [stats],
  );

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <PageContainer>
            <Typography variant="h4" align="center" className={styles.title}>
              Achievements
            </Typography>

            <AchievementLevels className={styles.levels} />

            <Typography variant="h5" align="center">
              Total Achievements
            </Typography>
            <Divider className={styles.divider} />
            {statsArray && (
              <Boxes className={styles.boxes} stats={statsArray} />
            )}
            <Typography variant="h5" align="center" className={styles.subtitle}>
              Select A Course
            </Typography>
            <Typography variant="body1" align="center">
              Find out how you are progressing through the course.
            </Typography>
            <Divider className={styles.divider} />
            <Grid
              className={styles.courses}
              container
              spacing={3}
              justify="center"
            >
              {courses
                .filter((course) => Boolean(course.hasAchievements))
                .map((course, index) => {
                  const locked = !Boolean(course.purchasedAt);
                  return (
                    <Grid
                      key={index}
                      className={styles.course}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <CourseCardSmall
                        ButtonProps={{
                          'data-testid': 'courseLink',
                          to: '/achievements/' + course.slug,
                          disabled: locked,
                        }}
                        locked={locked}
                        {...course}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </PageContainer>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default AchievementsPage;
