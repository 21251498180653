import { trimEnd } from 'lodash';

const formatError = (error) => {
  if (!error) {
    return null;
  }

  if (error.message) {
    return `Error: '${error.message}'.`;
  }

  const { code, status, detail, title } = error;

  const codePart = code ? code : '';
  const statusPart = status ? ` (${status})` : '';
  const separator = codePart || statusPart ? ': ' : '';
  const detailPart = trimEnd(detail || title || 'Unkown Error', '.');

  return `${codePart}${statusPart}${separator}${detailPart}.`;
};

export default formatError;
