import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import { makeStyles, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: 'rgba(249, 249, 249)',
    },
    '&:disabled': {
      backgroundColor: 'white',
    },
    width: 40,
    height: 40,
  },
}));

const ArrowButton = (props) => {
  const { className, orientation, ...rest } = props;
  const styles = useStyles();

  return (
    <IconButton className={clsx(className, styles.root)} {...rest}>
      {orientation === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  );
};

ArrowButton.propTypes = {
  orientation: PropTypes.oneOf(['left', 'right']).isRequired,
};

ArrowButton.defaultProps = {
  orientation: 'left',
};

export default ArrowButton;
