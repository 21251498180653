import instance from 'Api/instance';

export const getTransactions = (userId) =>
  instance.get(`/users/${userId}/transactions`);

export const refundTransaction = (userId, transactionId, reason) =>
  instance.post(`/users/${userId}/transactions/${transactionId}/refund`, {
    data: {
      attributes: { reason },
    },
  });

export const createPayment = (userId, data) =>
  instance.post(`/users/${userId}/transactions`, {
    data: { attributes: data },
  });
