import instance from 'Api/instance';

export const getLessonVideo = (courseId, moduleId, lessonId, skipAccessCheck) =>
  instance.get(
    `/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/video`,
    { params: { skipAccessCheck } },
  );

export const getEventVideo = (eventId, recordingOnly) =>
  instance.get(`/events/${eventId}/video`, { params: { recordingOnly } });
