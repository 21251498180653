import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { selectCourses, selectCoursesRequest } from 'Redux/selectors';
import { fetchCourses } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import WistiaVideo from 'Components/shared/wistia-video';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import Divider from 'Components/shared/divider';
import CourseCard from 'Components/shared/course-card';
import SearchHeader from 'Components/pages/help/search-header';
import HeaderNavigation from 'Components/pages/help/header-navigation';
import Cards from 'Components/pages/help/cards';

import { makeStyles, useMediaQuery, Grid, Typography } from '@material-ui/core';

const headingImage = {
  url: 'https://ecomfreedom-public-assets.s3.amazonaws.com/app/help-center-cover.jpg',
  isResizable: true,
};

const tutorialVideo = {
  url: 'https://support.wistia.com/medias/26sk4lmiix',
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingBottom: 0,
    paddingTop: theme.spacing(10.5),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(9),
    },
    flexGrow: 1,
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    maxWidth: 768,
    marginBottom: theme.spacing(6),
  },
  item: {
    margin: theme.spacing(0, 0, 4),
  },
  divider: {
    margin: theme.spacing(2, 0, 4),
  },
  body: {
    maxWidth: 928,
    marginBottom: theme.spacing(2),
  },
  last: {
    marginBottom: theme.spacing(10),
  },
  howToUse: {
    marginTop: theme.spacing(10),
  },
  video: {
    width: '100%',
    maxWidth: 928,
    marginBottom: theme.spacing(10),
  },
}));

const HelpCommunityPage = () => {
  const styles = useStyles();
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const dispatch = useDispatch();

  const { isLoading, error } = useSelector(selectCoursesRequest);
  const courses = useSelector(selectCourses);

  const communities =
    courses &&
    courses
      .filter((course) => course.hasCommunity)
      .map((course) => {
        const locked = !Boolean(course.purchasedAt);
        return {
          ...course,
          locked,
          subtitle: { COURSE: 'Course', BLUEPRINT: 'Blueprint' }[course.type],
          ButtonProps: locked
            ? {
                'data-testid': 'courseLink',
                label: 'Enroll',
                component: 'a',
                href: course.salesPageUrl,
              }
            : {
                'data-testid': 'courseLink',
                label: 'Join Community',
                target: '_blank',
                rel: 'noopener noreferrer',
                component: 'a',
                href: course.communityUrl,
              },
        };
      });

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <SearchHeader image={headingImage}>
            <HeaderNavigation variant="search" option="community" />
          </SearchHeader>
          <PageContainer component="section" className={styles.root}>
            <div className={styles.group}>
              <Grid
                container
                spacing={isLgUp ? 6 : isMdUp ? 3 : 2}
                justify="center"
              >
                {communities.map((community, index) => (
                  <Grid
                    key={index}
                    className={styles.item}
                    container
                    item
                    xs={12}
                    sm={6}
                    justify="center"
                    alignItems="center"
                  >
                    <CourseCard elevation={8} {...community} />
                  </Grid>
                ))}
              </Grid>
            </div>
            <Typography variant="h4" align="center">
              About Our Communities
            </Typography>
            <Divider className={styles.divider} />
            <Typography className={styles.body} variant="body1" align="center">
              The Freedom.co community is your #1 resource for getting your
              questions answered about starting your successful eCommerce
              business and achieving freedom.
            </Typography>
            <Typography className={styles.body} variant="body1" align="center">
              We have two Freedom.co communities: one for the Freedom.co Amazon
              FBA program and one for the Freedom.co Shopify program.
            </Typography>
            <Typography
              className={clsx(styles.body, styles.last)}
              variant="body1"
              align="center"
            >
              Each one has active members from all over the whole who are
              like-minded, are working towards the same goals of freedom and
              success, and are at various levels in their online business
              journeys.
            </Typography>
            {false && (
              <>
                <Typography
                  className={styles.howToUse}
                  variant="h4"
                  align="center"
                >
                  How To Use The Freedom.co Community
                </Typography>
                <Divider className={styles.divider} />
                <WistiaVideo className={styles.video} video={tutorialVideo} />
              </>
            )}
          </PageContainer>
          <Cards />
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default HelpCommunityPage;
