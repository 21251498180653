import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'update-achievement';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPDATE_ACHIEVEMENT_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPDATE_ACHIEVEMENT_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const updateAchievement = Exec.requestActionCreator(MODULE);
export const updateAchievementLoading = Exec.loadingActionCreator(MODULE);
export const updateAchievementSuccess = Exec.successActionCreator(MODULE);
export const updateAchievementError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpdateAchievementRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpdateAchievement = function* ({ payload, meta }) {
  const { courseId, moduleId, achievementId, ...attributes } = payload;

  try {
    yield put(closeSnackbar(UPDATE_ACHIEVEMENT_REQUEST));
    yield put(updateAchievementLoading());
    const { data } = yield call(
      Api.updateAchievement,
      courseId,
      moduleId,
      achievementId,
      attributes,
    );
    yield put(updateAchievementSuccess({ data }, meta));
  } catch (err) {
    yield put(updateAchievementError(err, meta));
    yield put(
      enqueueSnackbar(UPDATE_ACHIEVEMENT_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpdateAchievement);
