import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { find, findLast, findLastIndex } from 'lodash';
import { format, isAfter } from 'date-fns';
import clsx from 'clsx';

import ensurePrice from 'Util/ensure-price';
import {
  selectMe,
  selectUpgradeCourseMentorshipRequest,
  selectUpgradeCourseTrialRequest,
} from 'Redux/selectors';
import { upgradeCourseTrial } from 'Redux/actions';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import ConfirmShippingAddressDialog from 'Dialogs/confirm-shipping-address-dialog';
import CancelSelfTrialDialog from 'Dialogs/cancel-self-trial-dialog';

import {
  Button,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleContainer: {
    minHeight: 84,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 3, 4),
  },
  stepper: {
    padding: 0,
    justifyContent: 'center',
    margin: theme.spacing(1, 3, 3),
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'unset',
    },
  },
  step: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 160,
    },
  },
  stepLabelSpacing: {
    '&.MuiStepLabel-alternativeLabel': {
      marginTop: theme.spacing(1),
    },
  },
  buttons: {
    padding: theme.spacing(3, 1),
  },
  button: {
    '& .MuiButton-label': {
      wordBreak: 'break-word',
      whiteSpace: 'unset !important',
      textAlign: 'center',
    },
  },
  buttonGreen: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

const MaybeBr = () => {
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  return isSmUp ? <br /> : ' - ';
};

const StepTextFreeTrial = () => {
  return 'Free Trial';
};
const StepTextInstallment = (props) => {
  return (
    <>
      {/* eslint-disable-next-line react/prop-types */}
      Payment {props.index}
      <MaybeBr />
      {/* eslint-disable-next-line react/prop-types */}
      {ensurePrice(props.amount, { compact: false })}
      <MaybeBr />
      {/* eslint-disable-next-line react/prop-types */}
      {format(new Date(props.activateAt), 'M/d/yyyy')}
    </>
  );
};
const BoxsetShipment = (props) => {
  return (
    <>
      Boxset
      <MaybeBr />
      Ships Out!
    </>
  );
};

const components = {
  trial: StepTextFreeTrial,
  installment: StepTextInstallment,
  'boxset-shipment': BoxsetShipment,
};

const SubscriptionSteps = (props) => {
  const {
    id,
    className,
    hasMentorshipUpsell,
    hasMentorshipIncluded,
    couponId,
    steps,
    course,
  } = props;
  const styles = useStyles();
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const dispatch = useDispatch();

  const me = useSelector(selectMe);
  const mentorshipActive = Boolean(
    find(me?.enrolledCourseStatus, ['courseId', course.id])?.hasMentorship,
  );

  const { isLoading } = useCombinedRequestsSelector(
    selectUpgradeCourseTrialRequest,
    selectUpgradeCourseMentorshipRequest,
  );

  const activeStep = findLast(steps, (s) =>
    isAfter(new Date(), new Date(s.activateAt)),
  );
  const [showCancelTrialModal, hideCancelTrialModal] = useModal(
    () => <CancelSelfTrialDialog trialId={id} onClose={hideCancelTrialModal} />,
    [id],
  );
  const [showConfirmShippingAddressModal, hideConfirmShippingAddressModal] =
    useModal(
      () => (
        <ConfirmShippingAddressDialog
          courseId={course.id}
          onClose={hideConfirmShippingAddressModal}
        />
      ),
      [course],
    );

  const handleUpgradeEarly = useCallback(() => {
    if (!hasMentorshipIncluded && course.upgradeUrl) {
      const upgradeUrl = new URL(course.upgradeUrl);
      if (couponId) {
        upgradeUrl.searchParams.append('coupon', couponId);
      }
      window.location.href = upgradeUrl.href;
      return;
    }
    dispatch(upgradeCourseTrial({ courseId: course.id }, { isPromise: true }))
      .then(({ data: upgrade }) => {
        window.location.href = upgrade.hostedInvoiceUrl;
      })
      .catch(console.error);
  }, [couponId, course, dispatch, hasMentorshipIncluded]);

  const isTrial = activeStep && activeStep.type === 'trial';

  const buttonType = isTrial ? 'trial' : 'review';
  const buttonProps = isTrial
    ? {
        onClick: showCancelTrialModal,
      }
    : {
        href: 'https://www.trustpilot.com/evaluate/ecomfreedom.com',
        target: '_blank',
        rel: 'noopener noreferrer',
        color: 'primary',
      };

  const shouldConfirmShipping =
    activeStep &&
    activeStep.type === 'boxset-shipment' &&
    !activeStep.submittedAddress;

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.titleContainer}>
        <Typography variant="h5" align="center">
          {course.title}
          {course.type === 'BLUEPRINT' ? ' Blueprint' : ''}
        </Typography>
        {course.type === 'COURSE' ? (
          <Typography variant="body2" align="center">
            Course{' '}
            {hasMentorshipIncluded || mentorshipActive
              ? '+ Mentorship'
              : 'Only'}
          </Typography>
        ) : null}
      </div>

      <Stepper
        className={styles.stepper}
        activeStep={findLastIndex(steps, (s) =>
          isAfter(new Date(), new Date(s.activateAt)),
        )}
        alternativeLabel={isSmUp}
        orientation={isSmUp ? 'horizontal' : 'vertical'}
      >
        {steps.map((step, index) => {
          const Component = components[step.type];
          if (!Component) {
            return null;
          }

          return (
            <Step
              key={index}
              className={styles.step}
              completed={
                isAfter(new Date(), new Date(step.activateAt)) &&
                (steps.length - 1 === index ||
                  isAfter(new Date(), new Date(steps[index + 1].activateAt))) &&
                (step.type !== 'boxset-shipment' || step.submittedAddress)
              }
            >
              <StepLabel classes={{ label: styles.stepLabelSpacing }}>
                <Component {...step} />
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={1}
        className={styles.buttons}
      >
        {shouldConfirmShipping ? (
          <Grid item>
            <Button
              className={styles.button}
              size="large"
              color="primary"
              variant="contained"
              onClick={showConfirmShippingAddressModal}
            >
              Confirm Shipping Address
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Button
                className={styles.button}
                size="large"
                variant="contained"
                disabled={isLoading}
                {...buttonProps}
              >
                {buttonType === 'trial'
                  ? 'Cancel Free Trial'
                  : 'Leave a Review'}
              </Button>
            </Grid>
            {isTrial ? (
              <Grid item>
                <Button
                  className={styles.button}
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  onClick={handleUpgradeEarly}
                >
                  Upgrade Early
                </Button>
              </Grid>
            ) : null}
          </>
        )}
        {!isTrial && !mentorshipActive && hasMentorshipUpsell ? (
          <Grid item>
            <Button
              className={clsx(styles.button, styles.buttonGreen)}
              size="large"
              variant="contained"
              href="mailto:support@ecomfreedom.com"
            >
              Contact Us For Mentorhsip Upgrade
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

SubscriptionSteps.propTypes = {
  id: PropTypes.string.isRequired,
  hasMentorshipIncluded: PropTypes.bool,
  hasMentorshipUpsell: PropTypes.bool,
  mentorshipActive: PropTypes.bool,
  couponId: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['trial', 'installment', 'boxset-shipment'])
        .isRequired,
      activateAt: PropTypes.string.isRequired,
      amount: PropTypes.number,
      index: PropTypes.number,
    }),
  ).isRequired,
  course: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    upgradeUrl: PropTypes.string,
  }).isRequired,
};

export default SubscriptionSteps;
