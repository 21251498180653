import { Controller } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, identity, first } from 'lodash';

const withController = (
  Component,
  mapValue = identity,
  mapEventArgs = first,
) => {
  const ComponentWithController = (props) => {
    const { name, control, rules, onBlur, onChange, ...rest } = props;
    const { onBlur: onControlBlur, onChange: onControlChange } = control || {};

    const handleBlur = useCallback(
      (args) => {
        const value = mapEventArgs(args);

        onBlur && onBlur(value);
        onControlBlur && onControlBlur(value);

        return mapValue(value);
      },
      [onBlur, onControlBlur],
    );

    const handleChange = useCallback(
      (args) => {
        const value = mapEventArgs(args);

        onChange && onChange(value);
        onControlChange && onControlChange(value);

        return mapValue(value);
      },
      [onChange, onControlChange],
    );

    if (!control) {
      return (
        <Component
          checked={false}
          {...rest}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
        />
      );
    }

    const defaultValue =
      'defaultValue' in props && props.defaultValue !== null
        ? props.defaultValue
        : get(control.defaultValuesRef.current, name) || '';

    return (
      <Controller
        as={<Component checked={false} {...rest} name={name} />}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    );
  };

  ComponentWithController.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.shape({
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
      defaultValuesRef: PropTypes.any,
    }),
    defaultValue: PropTypes.any,
    rules: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  };

  return ComponentWithController;
};

export default withController;
