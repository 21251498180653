import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectDeleteUserRequest, selectUser } from 'Redux/selectors';
import { deleteUser, enqueueSnackbar } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmDeleteUserDialog = (props) => {
  const { onClose } = props;
  const { id: userId } = useSelector(selectUser);
  const { isLoading } = useSelector(selectDeleteUserRequest);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    dispatch(deleteUser({ userId }, { isPromise: true }))
      .then(() => {
        dispatch(
          enqueueSnackbar('UPDATE_USER', {
            variant: 'success',
            message: 'User deleted successfully',
          }),
        );
        navigate('/admin/users');
        onClose && onClose();
      })
      .catch(noop);
  }, [dispatch, navigate, onClose, userId]);

  return (
    <Dialog
      title="Delete Account"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleDelete}>
          Delete
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to delete this account? This is a permanent change
        and cannot be reverted.
      </Typography>
    </Dialog>
  );
};

ConfirmDeleteUserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ConfirmDeleteUserDialog;
