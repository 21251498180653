import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { format } from 'date-fns';
import clsx from 'clsx';
import VisaCardIcon from 'Icons/visa-card';
import UnknownCardIcon from 'Icons/unknown-card';
import UnionpayCardIcon from 'Icons/unionpay-card';
import MastercardCardIcon from 'Icons/mastercard-card';
import JcbCardIcon from 'Icons/jcb-card';
import DiscoverCardIcon from 'Icons/discover-card';
import DinersCardIcon from 'Icons/diners-card';
import AmexCardIcon from 'Icons/amex-card';
import { makeStyles, Typography, Button, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    minHeight: 56,
    minWidth: 240,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  left: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexWrap: 'nowrap',
    flexGrow: 1,
    alignItems: 'center',
    marginRight: 'auto',
  },
  icon: {
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    '& > svg': {
      width: '100%',
      height: 'auto',
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
  },
  right: {
    width: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 3, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3),
      marginRight: 'inherit',
    },
  },
  button: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const PaymentMethodCard = (props) => {
  const {
    className,
    disabled,
    paymentMethod,
    // onDelete,
    onReplace,
  } = props;
  const styles = useStyles();

  // const handleDelete = useCallback(
  //   () => onDelete(paymentMethod),
  //   [onDelete, paymentMethod],
  // );
  const handleReplace = useCallback(
    () => onReplace(paymentMethod),
    [onReplace, paymentMethod],
  );

  const CardIcon =
    {
      amex: AmexCardIcon,
      diners: DinersCardIcon,
      discover: DiscoverCardIcon,
      jcb: JcbCardIcon,
      mastercard: MastercardCardIcon,
      unionpay: UnionpayCardIcon,
      visa: VisaCardIcon,
    }[get(paymentMethod, 'card.brand')] || UnknownCardIcon;

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.left}>
        <div className={styles.icon}>
          <CardIcon />
        </div>

        <div className={styles.text}>
          <Typography variant="subtitle2">
            {paymentMethod.name || 'Card'}
          </Typography>
          <Typography variant="caption">
            ●●●● {paymentMethod.card.last4}
            {' | '}
            {format(
              new Date(
                paymentMethod.card.expYear,
                paymentMethod.card.expMonth - 1,
                1,
              ),
              'MMM yyyy',
            )}
          </Typography>
        </div>
      </div>
      <Grid
        container
        spacing={1}
        justify="center"
        align="center"
        direction="row"
        wrap="nowrap"
        className={styles.right}
      >
        {/* <Grid item className={styles.button}>
          <Button
            className={styles.errorButton}
            disabled={disabled}
            onClick={handleDelete}
            variant="contained"
          >
            Delete
          </Button>
        </Grid> */}
        <Grid item className={styles.button}>
          <Button
            disabled={disabled}
            onClick={handleReplace}
            variant="contained"
            color="primary"
          >
            Change
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

PaymentMethodCard.propTypes = {
  disabled: PropTypes.bool,
  paymentMethod: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['card']).isRequired,
    name: PropTypes.string,
    isDefault: PropTypes.bool,
    card: PropTypes.shape({
      brand: PropTypes.oneOf([
        'amex',
        'diners',
        'discover',
        'jcb',
        'mastercard',
        'unionpay',
        'visa',
        'unknown',
      ]).isRequired,
      last4: PropTypes.string.isRequired,
      expMonth: PropTypes.number.isRequired,
      expYear: PropTypes.number.isRequired,
    }).isRequired,
  }),
  onReplace: PropTypes.func,
  onDelete: PropTypes.func,
};

export default PaymentMethodCard;
