import PropTypes from 'prop-types';
import clsx from 'clsx';

import dynamicImageUrl from 'Util/dynamic-image-url';
import { makeStyles, fade, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 142,
  },
  graphic: {
    width: 80,
    height: 80,
    borderRadius: '50%',
    border: 'none',
    backgroundColor: theme.palette.grey[50],
    objectFit: 'cover',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1.125),
    whiteSpace: 'pre-line',
  },
  exp: {
    padding: `4px 10px`,
    borderRadius: 30,
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '18px',
  },
  noExp: {
    height: 26,
    width: 1,
  },
}));

const Level = (props) => {
  const { className, graphic, title, requiredExperience, user } = props;
  const styles = useStyles();

  const exp =
    user && user.experience < requiredExperience
      ? requiredExperience - user.experience
      : null;

  return (
    <div className={clsx(styles.group, className)}>
      {graphic ? (
        <img
          key={graphic.url}
          src={dynamicImageUrl(graphic, {
            resize: {
              width: 80,
              height: 80,
              fit: 'cover',
            },
          })}
          className={styles.graphic}
          alt="achievement"
        />
      ) : (
        <div className={styles.graphic} />
      )}
      <Typography className={styles.title} variant="subtitle1" align="center">
        {title.split(' ').join('\n')}
      </Typography>
      {exp ? (
        <Typography className={styles.exp} align="center" color="primary">
          +{exp} XP
        </Typography>
      ) : (
        <div className={styles.noExp} />
      )}
    </div>
  );
};

Level.propTypes = {
  title: PropTypes.string.isRequired,
  requiredExperience: PropTypes.number.isRequired,
  graphic: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    experience: PropTypes.number.isRequired,
  }).isRequired,
};

export default Level;
