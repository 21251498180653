import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';

import {
  makeStyles,
  withStyles,
  ButtonBase,
  Collapse,
  List,
  ListItem as MuiListItem,
  Typography,
  fade,
} from '@material-ui/core';

const ListItem = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  gutters: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))(MuiListItem);

const useStyles = makeStyles((theme) => ({
  inlineList: {
    paddingLeft: theme.spacing(2),
  },
  itemContent: {
    height: 48,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    borderRadius: 4,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  itemContentSelected: {
    color: theme.palette.primary.dark,
    backgroundColor: fade(theme.palette.primary.dark, 0.12),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.dark, 0.12),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  text: {
    fontWeight: 400,
    marginRight: theme.spacing(1),
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
  },
  dropdown: {
    transition: 'transform 0.2s ease-in-out',
  },
  dropdownSelected: {
    transform: 'rotate(180deg)',
  },
}));

const DrawerItem = (props) => {
  const {
    'data-testid': testId,
    selected,
    text,
    icon: Icon,
    items,
    ...rest
  } = props;

  const styles = useStyles();

  const [open, setOpen] = useState(Boolean(selected));
  const toggleOpen = useCallback(() => {
    if (items) {
      setOpen(!open);
    }
  }, [items, open]);

  return (
    <>
      <ListItem onClick={toggleOpen} {...rest}>
        <ButtonBase
          data-testid={testId}
          className={clsx(styles.itemContent, {
            [styles.itemContentSelected]: selected,
          })}
        >
          <Icon className={styles.icon} />
          <Typography className={styles.text} variant="subtitle2">
            {text}
          </Typography>
          {items && (
            <ArrowDropDownIcon
              className={clsx(styles.dropdown, {
                [styles.dropdownSelected]: open,
              })}
              fontSize="small"
            />
          )}
        </ButtonBase>
      </ListItem>
      {items && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List className={styles.inlineList} component="div" disablePadding>
            {items}
          </List>
        </Collapse>
      )}
    </>
  );
};

DrawerItem.propTypes = {
  open: PropTypes.bool,
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  items: PropTypes.element,
};

export default DrawerItem;
