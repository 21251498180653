import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'active-campaign-lists';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchActiveCampaignLists = Exec.requestActionCreator(MODULE);
export const fetchActiveCampaignListsLoading =
  Exec.loadingActionCreator(MODULE);
export const fetchActiveCampaignListsSuccess =
  Exec.successActionCreator(MODULE);
export const fetchActiveCampaignListsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////

export const selectActiveCampaignListsRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectActiveCampaignLists = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchAchievements = function* ({ meta }) {
  try {
    const { data } = yield call(Api.getActiveCampaignLists);
    yield put(fetchActiveCampaignListsSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchActiveCampaignListsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchAchievements);
