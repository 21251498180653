import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop, get, find } from 'lodash';
import { unformat } from 'accounting';

import {
  selectUser,
  selectCreatePaymentRequest,
  selectPaymentMethods,
} from 'Redux/selectors';
import { enqueueSnackbar, createPayment } from 'Redux/actions';
import VisaCardIcon from 'Icons/visa-card';
import UnknownCardIcon from 'Icons/unknown-card';
import UnionpayCardIcon from 'Icons/unionpay-card';
import MastercardCardIcon from 'Icons/mastercard-card';
import JcbCardIcon from 'Icons/jcb-card';
import DiscoverCardIcon from 'Icons/discover-card';
import DinersCardIcon from 'Icons/diners-card';
import AmexCardIcon from 'Icons/amex-card';
import Select from 'Components/shared/select';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button } from '@material-ui/core';

const schema = yup.object().shape({
  amount: yup.number().min(0.5).required('Field Required.'),
  paymentMethodId: yup.string().required('Field Required.'),
  statement: yup.string().required('Field Required.'),
  description: yup.string().optional(),
});

const pmString = (p) =>
  p.card
    ? `●●●● ${p.card.last4} ${p.card.expMonth}/${p.card.expYear}`
    : 'Unknown payment method';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 400,
  },
  amount: {
    width: '100%',
    maxWidth: 200,
    marginTop: theme.spacing(3),
  },
  input: {
    marginTop: theme.spacing(1.5),
    width: '100%',
  },
  paymentMethod: {
    display: 'flex',
    alignItems: 'center',
  },
  cardIcon: {
    marginRight: theme.spacing(1),
  },
}));

const CreatePaymentDialog = (props) => {
  const { onClose } = props;
  const styles = useStyles();

  const user = useSelector(selectUser);
  const paymentMethods = useSelector(selectPaymentMethods);
  const { isLoading } = useSelector(selectCreatePaymentRequest);
  const dispatch = useDispatch();

  const handleRenderPaymentMethod = useCallback(
    (selected) => {
      const paymentMethod = find(paymentMethods, ['id', selected]);

      const CardIcon =
        {
          amex: AmexCardIcon,
          diners: DinersCardIcon,
          discover: DiscoverCardIcon,
          jcb: JcbCardIcon,
          mastercard: MastercardCardIcon,
          unionpay: UnionpayCardIcon,
          visa: VisaCardIcon,
        }[get(paymentMethod, 'card.brand')] || UnknownCardIcon;

      return (
        <div className={styles.paymentMethod}>
          <CardIcon className={styles.cardIcon} /> {pmString(paymentMethod)}
        </div>
      );
    },
    [paymentMethods, styles],
  );

  const handleCreate = useCallback(
    (data) => {
      dispatch(
        createPayment(
          {
            userId: user.id,
            ...data,
            description: data.description || null,
            amount: unformat(data.amount),
          },
          { isPromise: true },
        ),
      )
        .then(() => {
          dispatch(
            enqueueSnackbar('CREATE_PAYMENT', {
              variant: 'success',
              message: 'Payment created successfully',
            }),
          );
          onClose && onClose();
        })
        .catch(noop);
    },
    [dispatch, user, onClose],
  );

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      statement: 'ECOMFREEDOM.COM',
      paymentMethodId: get(find(paymentMethods, 'isDefault'), 'id'),
    },
    validationSchema: schema,
  });

  return (
    <Dialog
      className={styles.paper}
      title="Create Payment"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleCreate)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">Create</Button>}
    >
      <Input
        data-testid="price"
        className={styles.amount}
        format="price"
        locale="US"
        control={control}
        label="Amount"
        name="amount"
        errorText={get(errors, 'amount.message')}
      />
      <Select
        data-testid="paymentMethodId"
        className={styles.input}
        control={control}
        label="Payment method"
        name="paymentMethodId"
        options={paymentMethods.map((p) => ({
          value: p.id,
          label: pmString(p),
        }))}
        renderValue={handleRenderPaymentMethod}
        renderMenuItemText={handleRenderPaymentMethod}
      />
      <Input
        data-testid="statement"
        className={styles.input}
        control={control}
        label="Statement descriptor"
        name="statement"
      />
      <Input
        data-testid="description"
        className={styles.input}
        control={control}
        label="Description (optional)"
        name="description"
      />
    </Dialog>
  );
};

CreatePaymentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CreatePaymentDialog;
