import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { find, findIndex } from 'lodash';
import clsx from 'clsx';

import {
  selectCoursesWithGraph,
  selectCoursesRequest,
  selectModulesRequest,
  selectLessonsRequest,
  selectAchievementsRequest,
} from 'Redux/selectors';
import {
  fetchAchievements,
  fetchCourses,
  fetchLessons,
  fetchModules,
  sortAchievements,
  sortLessons,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import PageLoader from 'Components/shared/page-loader';
import WideButton from 'Components/pages/admin/wide-button';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import SectionTitle from 'Components/pages/admin/section-title';
import LessonItem from 'Components/pages/admin/lesson-item';
import ContentPaper from 'Components/pages/admin/content-paper';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';
import AchievementItem from 'Components/pages/admin/achievement-item';

import {
  Edit as EditIcon,
  YouTube as YouTubeIcon,
  Add as AddIcon,
  EmojiEvents as EmojiEventsIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { makeStyles, Typography, Grid, IconButton } from '@material-ui/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  container: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    width: '100%',
  },
  sectionTitle: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  addButton: {
    width: '100%',
  },
  buttonMargin: {
    marginTop: theme.spacing(2),
  },
  visibilityIcon: {
    color: theme.palette.warning.main,
    fontSize: 24,
    marginRight: 10,
    marginBottom: -2,
  },
}));

const AdminModuleOverviewPage = () => {
  const dispatch = useDispatch();
  const { courseId, moduleId } = useParams();

  const courses = useSelector(selectCoursesWithGraph);
  const course = find(courses, ['id', courseId]);
  const module = course ? find(course.modules, ['id', moduleId]) : null;

  const styles = useStyles();

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectModulesRequest,
    selectLessonsRequest,
    selectAchievementsRequest,
  );

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    if (!module) {
      dispatch(fetchModules({ courseId, skipAccessCheck: true }));
    }
    dispatch(fetchLessons({ courseId, skipAccessCheck: true }));
    dispatch(fetchAchievements({ courseId, skipAccessCheck: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEndLesson = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const from = findIndex(module.lessons, ['id', active.id]);
        const to = findIndex(module.lessons, ['id', over.id]);
        const moved = arrayMove(module.lessons, from, to);
        dispatch(
          sortLessons({
            courseId: course.id,
            sortConfig: moved.map((m, idx) => ({ id: m.id, seqNo: idx + 1 })),
          }),
        );
      }
    },
    [course, module, dispatch],
  );

  const handleDragEndAchievements = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const from = findIndex(module.achievements, ['id', active.id]);
        const to = findIndex(module.achievements, ['id', over.id]);
        const moved = arrayMove(module.achievements, from, to);
        dispatch(
          sortAchievements({
            courseId: course.id,
            sortConfig: moved.map((m, idx) => ({ id: m.id, seqNo: idx + 1 })),
          }),
        );
      }
    },
    [course, module, dispatch],
  );

  if (!isLoading && (!course || !module)) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <UserDetailsLayout>
          <Breadcrumbs
            className={styles.breadcrumbs}
            links={[
              { href: '/admin/courses', label: 'Courses' },
              {
                href: `/admin/courses/${courseId}/overview`,
                label: course.title,
              },
              { label: module.title },
            ]}
          />

          <div className={styles.container}>
            <TitleAndAction
              className={styles.titleAndAction}
              title={<Typography variant="h4">Module Overview</Typography>}
            />

            <ContentPaper
              className={styles.paper}
              actions={
                <IconButton
                  component={Link}
                  to={`/admin/courses/${courseId}/modules/${moduleId}/edit`}
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <Typography variant="h5">
                {!module.isVisible ? (
                  <VisibilityOffIcon className={styles.visibilityIcon} />
                ) : null}
                {module.title} - {module.summary}
              </Typography>
              {module.daysToUnlock ? (
                <Typography variant="body1" color="textSecondary">
                  {`Unlocks after ${module.daysToUnlock} day${
                    module.daysToUnlock > 1 ? 's' : ''
                  }`}
                </Typography>
              ) : null}
            </ContentPaper>

            <SectionTitle
              className={styles.sectionTitle}
              icon={<YouTubeIcon />}
            >
              Lessons
            </SectionTitle>

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToWindowEdges]}
              onDragEnd={handleDragEndLesson}
            >
              <Grid container spacing={2}>
                <SortableContext
                  items={module.lessons || []}
                  strategy={verticalListSortingStrategy}
                >
                  {(module.lessons || []).map((l, index) => (
                    <Grid key={l.id} item container>
                      <LessonItem
                        {...l}
                        courseId={courseId}
                        moduleId={moduleId}
                      />
                    </Grid>
                  ))}
                </SortableContext>

                <Grid item container>
                  <WideButton
                    component={Link}
                    to={`/admin/courses/${courseId}/modules/${moduleId}/lessons/create`}
                    color="primary"
                    startIcon={<AddIcon />}
                    className={clsx(
                      styles.addButton,
                      module.lessons &&
                        module.lessons.length &&
                        styles.buttonMargin,
                    )}
                  >
                    Add New Lesson
                  </WideButton>
                </Grid>
              </Grid>
            </DndContext>

            <SectionTitle
              className={styles.sectionTitle}
              icon={<EmojiEventsIcon />}
            >
              Achievements
            </SectionTitle>

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToWindowEdges]}
              onDragEnd={handleDragEndAchievements}
            >
              <Grid container spacing={2}>
                <SortableContext
                  items={module.achievements || []}
                  strategy={verticalListSortingStrategy}
                >
                  {(module.achievements || []).map((a) => (
                    <Grid key={a.id} item container>
                      <AchievementItem
                        {...a}
                        courseId={courseId}
                        moduleId={moduleId}
                      />
                    </Grid>
                  ))}
                </SortableContext>

                <Grid item container>
                  <WideButton
                    component={Link}
                    to={`/admin/courses/${courseId}/modules/${moduleId}/achievements/create`}
                    color="primary"
                    startIcon={<AddIcon />}
                    className={clsx(
                      styles.addButton,
                      module.achievements &&
                        module.achievements.length &&
                        styles.buttonMargin,
                    )}
                  >
                    Add New Achievement
                  </WideButton>
                </Grid>
              </Grid>
            </DndContext>
          </div>
        </UserDetailsLayout>
      )}
    </PageLoader>
  );
};

export default AdminModuleOverviewPage;
