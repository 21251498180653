import { put, call, select } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { selectUser, fetchUserSuccess } from './user';
import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { selectMe, fetchMeSuccess } from './me';

const MODULE = 'suspend-user';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SUSPEND_USER_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const suspendUser = Exec.requestActionCreator(MODULE);
export const suspendUserLoading = Exec.loadingActionCreator(MODULE);
export const suspendUserSuccess = Exec.successActionCreator(MODULE);
export const suspendUserError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSuspendUserRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSuspendUser = function* ({ payload, meta }) {
  const { userId, reason, expiresAt } = payload;
  const me = yield select(selectMe);
  const user = yield select(selectUser);

  try {
    yield put(closeSnackbar(SUSPEND_USER_REQUEST));
    yield put(suspendUserLoading());

    const { data } = yield call(Api.suspendUser, userId, reason, expiresAt);

    if (data && data.suspension && data.suspension.suspendedByUserId) {
      data.suspension.suspendedByUser = me;
    }

    if (me && userId === me.id) {
      yield put(fetchMeSuccess({ data }));
    }
    if (user && userId === user.id) {
      yield put(fetchUserSuccess({ data }));
    }
    yield put(suspendUserSuccess({ data }, meta));
  } catch (err) {
    yield put(suspendUserError(err, meta));
    yield put(
      enqueueSnackbar(SUSPEND_USER_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSuspendUser);
