import instance from 'Api/instance';

export const getOffers = () => instance.get('/offers?expand=prices');

export const archiveOffer = (offerId) => instance.delete('/offers/' + offerId);

export const restoreOffer = (offerId) =>
  instance.post('/offers/' + offerId + '/restore');

export const createOffer = (attributes) =>
  instance.post('/offers', { data: { attributes } });

export const updateOffer = (offerId, attributes) =>
  instance.patch('/offers/' + offerId, { data: { attributes } });
