import { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  makeStyles,
  TableSortLabel as MuiTableSortLabel,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const TableSortLabel = (props) => {
  const {
    active,
    disabled,
    name,
    direction,
    onClick,
    children,
    ...rest
  } = props;

  const styles = useStyles();
  const handleToggle = useCallback(() => {
    if (!onClick) {
      return;
    }
    if (active) {
      onClick(name, direction === 'asc' ? 'desc' : 'asc');
    } else {
      onClick(name, 'asc');
    }
  }, [active, direction, name, onClick]);

  if (disabled) {
    return children;
  }

  return (
    <MuiTableSortLabel
      active={active}
      direction={direction}
      onClick={handleToggle}
      {...rest}
    >
      {children}
      {active ? (
        <span className={styles.visuallyHidden}>
          {direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </span>
      ) : null}
    </MuiTableSortLabel>
  );
};

TableSortLabel.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onClick: PropTypes.func,
};

export default TableSortLabel;
