import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useCallback } from 'react';
import { find, findIndex } from 'lodash';
import clsx from 'clsx';

import {
  selectCoursesWithGraph,
  selectCoursesRequest,
  selectModulesRequest,
  selectLessonsRequest,
  selectLessonVideoRequest,
  selectLessonVideo,
} from 'Redux/selectors';
import {
  fetchCourses,
  fetchLessons,
  fetchModules,
  fetchLessonVideo,
  sortLessonQuestions,
  sortLessonResources,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import ToolIcon from 'Icons/tool';
import FileUploadIcon from 'Icons/file-upload';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import UploadLessonVideoDialog from 'Dialogs/upload-lesson-video-dialog';
import UploadLessonBoxsetPdfDialog from 'Dialogs/upload-lesson-boxset-pdf-dialog';
import PasteLessonVideoUrlDialog from 'Dialogs/paste-lesson-video-url-dialog';
import ConfirmDeleteLessonBoxsetPdfDialog from 'Dialogs/confirm-delete-lesson-boxset-pdf-dialog';
import PageLoader from 'Components/shared/page-loader';
import WideButton from 'Components/pages/admin/wide-button';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import SectionTitle from 'Components/pages/admin/section-title';
import ResourceItem from 'Components/pages/admin/resource-item';
import QuestionItem from 'Components/pages/admin/question-item';
import ContentPaper from 'Components/pages/admin/content-paper';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import {
  Edit as EditIcon,
  YouTube as YouTubeIcon,
  Bookmark as BookmarkIcon,
  Add as AddIcon,
  HelpOutline as HelpOutlineIcon,
  Link as LinkIcon,
  Close as CloseIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  Typography,
  Grid,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  container: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    width: '100%',
  },
  sectionTitle: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  addButton: {
    width: '100%',
  },
  buttonMargin: {
    marginTop: theme.spacing(2),
  },
  visibilityIcon: {
    color: theme.palette.warning.dark,
    fontSize: 24,
    marginLeft: 12,
    marginBottom: -3,
  },
}));

const AdminLessonOverviewPage = () => {
  const dispatch = useDispatch();
  const { courseId, moduleId, lessonId } = useParams();

  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const video = useSelector(selectLessonVideo);
  const courses = useSelector(selectCoursesWithGraph);
  const course = find(courses, ['id', courseId]);
  const module = course ? find(course.modules, ['id', moduleId]) : null;
  const lesson = module ? find(module.lessons, ['id', lessonId]) : null;

  const styles = useStyles();

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectModulesRequest,
    selectLessonsRequest,
  );
  const { isLoading: isLoadingRelated, error: errorRelated } =
    useCombinedRequestsSelector(selectLessonVideoRequest);

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    if (!module) {
      dispatch(fetchModules({ courseId, skipAccessCheck: true }));
    }
    if (!lesson) {
      dispatch(fetchLessons({ courseId, skipAccessCheck: true }));
    }
    dispatch(
      fetchLessonVideo({
        courseId,
        moduleId,
        lessonId,
        skipAccessCheck: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId, moduleId, lessonId]);

  const [showUploadLessonVideoModal, hideUploadLessonVideoModal] = useModal(
    () => (
      <UploadLessonVideoDialog
        courseId={courseId}
        moduleId={moduleId}
        lessonId={lessonId}
        onClose={hideUploadLessonVideoModal}
      />
    ),
    [courseId, moduleId, lessonId],
  );
  const [showPasteLessonVideoUrlModal, hidePasteLessonVideoUrlModal] = useModal(
    () => (
      <PasteLessonVideoUrlDialog
        courseId={courseId}
        moduleId={moduleId}
        lessonId={lessonId}
        onClose={hidePasteLessonVideoUrlModal}
      />
    ),
    [courseId, moduleId, lessonId],
  );
  const [showUploadLessonBoxsetPdfModal, hideUploadLessonBoxsetPdfModal] =
    useModal(
      () => (
        <UploadLessonBoxsetPdfDialog
          courseId={courseId}
          moduleId={moduleId}
          lessonId={lessonId}
          onClose={hideUploadLessonBoxsetPdfModal}
        />
      ),
      [courseId, moduleId, lessonId],
    );
  const [
    showConfirmDeleteLessonBoxsetPdfModal,
    hideConfirmDeleteLessonBoxsetPdfModal,
  ] = useModal(
    () => (
      <ConfirmDeleteLessonBoxsetPdfDialog
        courseId={courseId}
        moduleId={moduleId}
        lessonId={lessonId}
        onClose={hideConfirmDeleteLessonBoxsetPdfModal}
      />
    ),
    [courseId, moduleId, lessonId],
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEndQuestion = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const from = findIndex(lesson.questions, ['id', active.id]);
        const to = findIndex(lesson.questions, ['id', over.id]);
        const moved = arrayMove(lesson.questions, from, to);
        dispatch(
          sortLessonQuestions({
            lessonId: lesson.id,
            sortConfig: moved.map((m, idx) => ({ id: m.id, seqNo: idx + 1 })),
          }),
        );
      }
    },
    [lesson, dispatch],
  );

  const handleDragEndResource = useCallback(
    (event) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const from = findIndex(lesson.resources, ['id', active.id]);
        const to = findIndex(lesson.resources, ['id', over.id]);
        const moved = arrayMove(lesson.resources, from, to);
        dispatch(
          sortLessonResources({
            lessonId: lesson.id,
            sortConfig: moved.map((m, idx) => ({ id: m.id, seqNo: idx + 1 })),
          }),
        );
      }
    },
    [lesson, dispatch],
  );

  if (!isLoading && (!course || !module || !lesson)) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader
      loading={isLoading || isLoadingRelated}
      error={
        error ||
        (errorRelated && errorRelated.code !== 'ERROR_CODE_NOT_FOUND'
          ? errorRelated
          : null)
      }
    >
      {() => (
        <UserDetailsLayout>
          <Breadcrumbs
            className={styles.breadcrumbs}
            links={[
              { href: '/admin/courses', label: 'Courses' },
              {
                href: `/admin/courses/${courseId}/overview`,
                label: course.title,
              },
              {
                href: `/admin/courses/${courseId}/modules/${moduleId}/overview`,
                label: module.title,
              },
              { label: lesson.title },
            ]}
          />

          <div className={styles.container}>
            <TitleAndAction
              className={styles.titleAndAction}
              title={<Typography variant="h4">Lesson Overview</Typography>}
            />

            <ContentPaper
              className={styles.paper}
              actions={
                <IconButton
                  component={Link}
                  to={`/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/edit`}
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <Typography variant="h5">
                {lesson.title}
                {!lesson.isVisible ? (
                  <VisibilityOffIcon className={styles.visibilityIcon} />
                ) : null}
              </Typography>
            </ContentPaper>

            <SectionTitle
              className={styles.sectionTitle}
              icon={<YouTubeIcon />}
            >
              Video
            </SectionTitle>

            <Grid container spacing={1}>
              {video ? (
                <Grid item container>
                  <ContentPaper className={styles.paper}>
                    <Typography variant="h6">{video.fileName}</Typography>
                  </ContentPaper>
                </Grid>
              ) : null}

              <Grid item container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <WideButton
                    color="primary"
                    startIcon={<FileUploadIcon />}
                    className={clsx(
                      styles.addButton,
                      video && styles.buttonMargin,
                    )}
                    onClick={showUploadLessonVideoModal}
                  >
                    Upload Video
                  </WideButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <WideButton
                    color="primary"
                    startIcon={<LinkIcon />}
                    className={clsx(
                      styles.addButton,
                      isSmUp && video && styles.buttonMargin,
                    )}
                    onClick={showPasteLessonVideoUrlModal}
                  >
                    Paste Wistia Link
                  </WideButton>
                </Grid>
              </Grid>
            </Grid>

            <SectionTitle
              className={styles.sectionTitle}
              icon={<BookmarkIcon />}
            >
              Boxset PDF
            </SectionTitle>

            <Grid container spacing={1}>
              {lesson.pdf ? (
                <Grid item container>
                  <ContentPaper
                    className={styles.paper}
                    actions={
                      <IconButton
                        onClick={showConfirmDeleteLessonBoxsetPdfModal}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                  >
                    <Typography variant="h6">{lesson.pdf.fileName}</Typography>
                  </ContentPaper>
                </Grid>
              ) : null}

              <Grid item container>
                <WideButton
                  color="primary"
                  startIcon={<FileUploadIcon />}
                  className={clsx(
                    styles.addButton,
                    lesson.pdf && styles.buttonMargin,
                  )}
                  onClick={showUploadLessonBoxsetPdfModal}
                >
                  Upload Boxset PDF
                </WideButton>
              </Grid>
            </Grid>

            <SectionTitle
              className={styles.sectionTitle}
              icon={<HelpOutlineIcon />}
            >
              Questions
            </SectionTitle>

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToWindowEdges]}
              onDragEnd={handleDragEndQuestion}
            >
              <Grid container spacing={2}>
                <SortableContext
                  items={lesson.questions || []}
                  strategy={verticalListSortingStrategy}
                >
                  {(lesson.questions || []).map((q) => (
                    <Grid key={q.id} item container>
                      <QuestionItem
                        courseId={courseId}
                        moduleId={moduleId}
                        lessonId={lessonId}
                        {...q}
                      />
                    </Grid>
                  ))}
                </SortableContext>

                <Grid item container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <WideButton
                      component={Link}
                      to={`/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/questions/create`}
                      color="primary"
                      startIcon={<AddIcon />}
                      className={clsx(
                        styles.addButton,
                        video && styles.buttonMargin,
                      )}
                    >
                      Add New Question
                    </WideButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <WideButton
                      component={Link}
                      to={`/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/questions/connect`}
                      color="primary"
                      startIcon={<LinkIcon />}
                      className={clsx(
                        styles.addButton,
                        isSmUp && video && styles.buttonMargin,
                      )}
                    >
                      Connect Existing Question
                    </WideButton>
                  </Grid>
                </Grid>
              </Grid>
            </DndContext>

            <SectionTitle className={styles.sectionTitle} icon={<ToolIcon />}>
              Resources
            </SectionTitle>

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToWindowEdges]}
              onDragEnd={handleDragEndResource}
            >
              <Grid container spacing={2}>
                <SortableContext
                  items={lesson.resources || []}
                  strategy={verticalListSortingStrategy}
                >
                  {(lesson.resources || []).map((r) => (
                    <Grid key={r.id} item container>
                      <ResourceItem
                        courseId={courseId}
                        moduleId={moduleId}
                        lessonId={lessonId}
                        {...r}
                      />
                    </Grid>
                  ))}
                </SortableContext>

                <Grid item container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <WideButton
                      component={Link}
                      to={`/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/resources/create`}
                      color="primary"
                      startIcon={<AddIcon />}
                      className={clsx(
                        styles.addButton,
                        video && styles.buttonMargin,
                      )}
                    >
                      Add New Resource
                    </WideButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <WideButton
                      component={Link}
                      to={`/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/resources/connect`}
                      color="primary"
                      startIcon={<LinkIcon />}
                      className={clsx(
                        styles.addButton,
                        isSmUp && video && styles.buttonMargin,
                      )}
                    >
                      Connect Existing Resource
                    </WideButton>
                  </Grid>
                </Grid>
              </Grid>
            </DndContext>
          </div>
        </UserDetailsLayout>
      )}
    </PageLoader>
  );
};

export default AdminLessonOverviewPage;
