import { loadStripe } from '@stripe/stripe-js';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

class Singleton {
  instance() {
    return (
      this.stripe || (this.stripe = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY))
    );
  }
}

export default new Singleton();
