import * as React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logo: {
    padding: theme.spacing(1, 1.5),
    width: '100%',
    height: 48,
    [theme.breakpoints.up('sm')]: {
      width: 174,
      height: 48,
    },
  },
}));

const LogoLarge = ({ className }) => {
  const styles = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 300 75"
      className={clsx(styles.logo, className)}
    >
      <g>
        <path
          d="M231.5 59.8h-5.1v-5.1h5.1zM254.8 54.4c-1.6 3.6-4.6 5.3-8.7 5.3-6.8 0-10.7-5.1-10.7-14.1V41c0-9 4-13.9 10.7-13.9 3.7 0 6.7 1.7 8.6 4.9l-2.6 1.8c-1.3-2.4-3.6-3.5-6-3.5-4.7 0-7.2 3.8-7.2 10.7v4.7c0 6.3 1.9 10.8 7.2 10.8 2.5 0 4.8-1.1 6-3.9zM278.9 45.8c0 8.9-3.7 14-10.3 14s-10.3-5.1-10.3-14V41c0-8.9 3.7-13.9 10.3-13.9s10.3 5 10.3 13.9zm-3.5 0V41c0-6.7-1.9-10.7-6.8-10.7s-6.8 4-6.8 10.7v4.8c0 6.7 1.9 10.8 6.8 10.8s6.8-4.1 6.8-10.8M45.2 15.9v8.8H31.7v9h11.8v8.8H31.7v17.3H21.1V15.9zM62.8 37.8c-.6-1.1-1.3-2.4-2.6-2.4-1.8 0-2.6 1.9-2.6 6.2v18.1h-9.5V28h9.3v2.4c.8-1.4 2.2-3.1 5.3-3.1 2.9 0 5.5 1.7 7.1 5.2zM93.5 54.3c-2.1 3.9-5.2 5.5-10.2 5.5-9 0-12.7-6.5-12.7-14.4v-4.5c0-9.9 4.7-14.3 11.8-14.3 8.2 0 11.8 5.3 11.8 15.2v3.8H80.1c0 3.6.3 6.1 3.3 6.1 2.1 0 2.6-.9 3.4-2.2zm-13.3-15h4.5c0-3.4-.8-4.6-2.2-4.6-1.7 0-2.3 1.8-2.3 4.6M121 54.3c-2.1 3.9-5.2 5.5-10.2 5.5-9 0-12.7-6.5-12.7-14.4v-4.5c0-9.9 4.7-14.3 11.8-14.3 8.2 0 11.8 5.3 11.8 15.2v3.8h-14.1c0 3.6.3 6.1 3.3 6.1 2.1 0 2.6-.9 3.4-2.2zm-13.3-15h4.5c0-3.4-.8-4.6-2.2-4.6-1.7 0-2.3 1.8-2.3 4.6M125 41.1c0-9.2 3.9-14.4 10.7-14.4 2 0 3.4.4 4.6 1.3V15.2h9.5v43.9h-9.5v-1.6c-1 1.3-2.5 2.2-5 2.2-6 0-10.3-5-10.3-14.3zm9.6 3.6c0 4.7.4 7.1 2.9 7.1s2.9-2.4 2.9-7.1v-2.9c0-4.7-.4-7.1-2.9-7.1s-2.9 2.4-2.9 7.1zM166.1 59.8c-8.1 0-12.4-4.9-12.4-14.6v-3.8c0-9.7 4.3-14.6 12.4-14.6s12.4 4.9 12.4 14.6v3.8c0 9.7-4.3 14.6-12.4 14.6m0-25.1c-2.5 0-2.9 2.4-2.9 7.1v2.9c0 4.7.4 7.1 2.9 7.1s2.9-2.4 2.9-7.1v-2.9c0-4.7-.4-7.1-2.9-7.1M182.8 28h9.2v2.3c.9-1.4 2.8-2.9 5.6-2.9s5.2 1.3 6.9 4c1.7-2.2 4.2-4 7.7-4 6 0 9.9 4 9.9 12.5v20h-9.5V41.6c0-4.3-.6-6.2-2.7-6.2-1.8 0-2.7 1.4-2.7 5.5v18.8h-9.5V41.6c0-4.7-.9-6.2-2.7-6.2-1.7 0-2.6 1.5-2.6 6.1v18.2h-9.5V28z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default LogoLarge;
