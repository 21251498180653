import { put, call } from 'redux-saga/effects';
import { compact, flatMap, uniq, find } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { REFUND_TRANSACTIONS_SINGLE_SUCCESS } from './refund-transactions';
import { CREATE_PAYMENT_SUCCESS } from './create-payment';

const MODULE = 'transactions';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchTransactions = Exec.requestActionCreator(MODULE);
export const fetchTransactionsLoading = Exec.loadingActionCreator(MODULE);
export const fetchTransactionsSuccess = Exec.successActionCreator(MODULE);
export const fetchTransactionsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE);

export const reducer = (state, action) => {
  switch (action.type) {
    case REFUND_TRANSACTIONS_SINGLE_SUCCESS:
      if (!state || !state.data) {
        return state;
      }
      const { data } = action.payload;
      return {
        ...state,
        data: state.data.map((t) =>
          t.id === data.id ? { ...data, paymentMethod: t.paymentMethod } : t,
        ),
      };
    case CREATE_PAYMENT_SUCCESS:
      if (!state || !state.data) {
        return state;
      }
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectTransactionsRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectTransactions = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchTransactions = function* ({ payload, meta }) {
  try {
    const { data } = yield call(
      Api.getTransactions,
      payload && payload.userId ? payload.userId : 'me',
    );

    const paymentMethods = yield Promise.all(
      compact(uniq(flatMap(data, 'paymentMethodId')))
        .map((id) =>
          Api.getPaymentMethod(id)
            .then((res) => res.data)
            .catch(() => null),
        )
        .filter(Boolean),
    );

    for (const transaction of data) {
      transaction.paymentMethod = find(paymentMethods, [
        'id',
        transaction.paymentMethodId,
      ]);
      delete transaction.paymentMethodId;
    }

    yield put(fetchTransactionsSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchTransactionsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchTransactions);
