import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useMemo } from 'react';

import languages from 'Util/languages';
import calculateUserLevel from 'Util/calculate-user-level';
import {
  selectAchievementLevels,
  selectMeRequest,
  selectUser,
  selectUserRequest,
} from 'Redux/selectors';
import { fetchUser } from 'Redux/actions';
import FacebookIcon from 'Icons/facebook';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import UploadProfilePictureDialog from 'Dialogs/upload-profile-picture-dialog';
import ConfirmDeleteProfilePictureDialog from 'Dialogs/confirm-delete-profile-picture-dialog';
import UserLocation from 'Components/shared/user-location';
import UserAvatar from 'Components/shared/user-avatar';
import PageLoader from 'Components/shared/page-loader';
import OptionListItem from 'Components/shared/option-list-item';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Tabs from 'Components/pages/admin/tabs';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import {
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import {
  fade,
  List,
  ListItem,
  makeStyles,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3.5),
  },
  tabs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  cards: {
    width: '100%',
    alignSelf: 'flex-start',
    display: 'grid',
    gap: theme.spacing(3) + 'px',
    alignItems: 'start',
    justifyItems: 'start',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('xxl')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  textValue: {
    alignItems: 'center',
    display: 'inline-flex',
  },
  list: {
    width: '100%',
    flexGrow: 1,
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2.5, 3, 2, 0),
  },
  avatarAction: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 120,
    height: 120,
    left: 0,
    right: theme.spacing(3),
    margin: `0 auto`,
    zIndex: 2,
    '& .MuiIconButton-root': {
      display: 'none',
    },
    backgroundColor: fade(theme.palette.background.default, 0),
    '&:hover': {
      backgroundColor: fade(theme.palette.background.default, 0.86),

      '& .MuiIconButton-root': {
        display: 'initial',
      },
    },
  },
  title: {
    width: '100%',
  },
  name: {
    margin: theme.spacing(1, 0),
  },
  facebookIcon: {
    color: '#3B5998',
    marginRight: 6,
  },
  instagramIcon: {
    color: '#BC2A8D',
    marginRight: 6,
  },
  twitterIcon: {
    color: '#1DA1F2',
    marginRight: 6,
  },
  iconInactive: {
    marginRight: 6,
  },
}));

const AdminUserProfile = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const user = useSelector(selectUser);
  const levels = useSelector(selectAchievementLevels);

  const { isLoading, error } = useCombinedRequestsSelector(
    selectMeRequest,
    selectUserRequest,
  );

  useScopedDispatchEffect(() => {
    dispatch(fetchUser({ userId }));
  }, [dispatch, userId]);

  const spokenLanguages = useMemo(
    () =>
      user && user.spokenLanguages
        ? languages
            .filter((lang) => user.spokenLanguages.find((l) => l === lang.code))
            .map((lang) => lang.name)
        : ['English'],
    [user],
  );

  const socialFields = ['facebook', 'instagram', 'twitter'];

  const socialIcons = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    twitter: TwitterIcon,
  };

  const level =
    user && levels ? calculateUserLevel(user.experience, levels) : null;

  const [
    showUploadProfilePictureModal,
    hideUploadProfilePictureModal,
  ] = useModal(
    () => (
      <UploadProfilePictureDialog
        userId={userId}
        onClose={hideUploadProfilePictureModal}
      />
    ),
    [userId],
  );
  const [
    showDeleteProfilePictureModal,
    hideDeleteProfilePictureModal,
  ] = useModal(
    () => (
      <ConfirmDeleteProfilePictureDialog
        userId={userId}
        onClose={hideDeleteProfilePictureModal}
      />
    ),
    [userId],
  );

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <UserDetailsLayout>
          <Breadcrumbs
            className={styles.breadcrumbs}
            links={[
              { href: '/admin/users', label: 'Users' },
              { label: 'Details' },
            ]}
          />
          <TitleAndAction
            className={styles.titleAndAction}
            title={
              <Typography variant="h4">
                {user.firstName + ' ' + user.lastName}
              </Typography>
            }
            button={
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/admin/users/${userId}/edit`}
                startIcon={<CreateIcon />}
              >
                Edit
              </Button>
            }
          />
          <Tabs className={styles.tabs} userId={userId} value={0} />

          <div className={styles.cards}>
            <List disablePadding className={styles.list}>
              <ListItem className={styles.header} disableGutters divider>
                <UserAvatar disableOutline size={120} user={user} />
                <div
                  data-testid="avatarActionContainer"
                  className={styles.avatarAction}
                >
                  <IconButton
                    data-testid="avatarAction"
                    onClick={
                      user.image
                        ? showDeleteProfilePictureModal
                        : showUploadProfilePictureModal
                    }
                  >
                    {user.image ? <DeleteIcon /> : <AddIcon />}
                  </IconButton>
                </div>
                <Typography className={styles.name} variant="h6">
                  {user.firstName + ' ' + user.lastName}
                </Typography>
                {level && (
                  <Typography variant="subtitle2" align="center">
                    {level.title}
                  </Typography>
                )}
              </ListItem>
              <OptionListItem
                data-testid="location"
                title="Location"
                compact
                divider
                value={
                  <UserLocation variant="body1" flag={false} user={user} />
                }
              />
              <OptionListItem
                data-testid="spokenLanguages"
                title="Spoken Languages"
                compact
                value={
                  <Typography variant="body1">
                    {spokenLanguages.join(', ')}
                  </Typography>
                }
              />
            </List>
            <List disablePadding className={styles.list}>
              <ListItem className={styles.header} disableGutters divider>
                <Typography className={styles.title} variant="h6">
                  Contact Information
                </Typography>
              </ListItem>
              <OptionListItem
                data-testid="email"
                title="Email"
                compact
                divider
                value={<Typography variant="body1">{user.email}</Typography>}
              />
              {socialFields.map((name, index) => {
                const value = user[name];
                const Icon = socialIcons[name];

                return (
                  <OptionListItem
                    key={name}
                    name={name}
                    title={name}
                    compact
                    value={
                      value ? (
                        <Typography
                          variant="body1"
                          className={styles.textValue}
                        >
                          <Icon className={styles[name + 'Icon']} />
                          {value.displayName || value.username}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          className={styles.textValue}
                          color="textSecondary"
                        >
                          <Icon
                            fontSize="small"
                            className={styles.iconInactive}
                          />
                          Not Set.
                        </Typography>
                      )
                    }
                    divider={socialFields.length - 1 !== index}
                  />
                );
              })}
            </List>
            <List disablePadding className={styles.list}>
              <ListItem className={styles.header} disableGutters divider>
                <Typography className={styles.title} variant="h6">
                  Profile Details
                </Typography>
              </ListItem>
              <OptionListItem
                data-testid="bio"
                title="Bio"
                compact
                divider
                value={
                  user.bio ? (
                    <Typography variant="body1">{user.bio}</Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Set.
                    </Typography>
                  )
                }
              />
              <OptionListItem
                data-testid="goal"
                title="Long Term Goal"
                compact
                value={
                  user.goal ? (
                    <Typography variant="body1">{user.goal}</Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Not Set.
                    </Typography>
                  )
                }
              />
            </List>
          </div>
        </UserDetailsLayout>
      )}
    </PageLoader>
  );
};

export default AdminUserProfile;
