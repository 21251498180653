import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { selectMe, selectUpdateMeRequest } from 'Redux/selectors';
import { updateMe } from 'Redux/actions';
import useRecaptcha from 'Hooks/use-recaptcha';
import Switch from 'Components/shared/switch';
import Select from 'Components/shared/select';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import {
  makeStyles,
  Button,
  FormControlLabel,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: (props) => ({
    marginTop: props.message ? 0 : theme.spacing(3),
    ...(props.variant !== 'switch'
      ? {
          width: '100%',
        }
      : {}),
  }),
  switch: {
    margin: '0 auto',
  },
}));

const SwitchWithLabel = (props) => {
  const { value, ...rest } = props;
  const styles = useStyles({});

  return (
    <FormControlLabel
      className={styles.switch}
      control={<Switch {...rest} />}
      label={value ? 'On' : 'Off'}
    />
  );
};

const UpdateMeFieldDialog = (props) => {
  const {
    variant,
    name,
    actionName,
    title,
    message,
    required,
    validationSchema,
    onClose,
    ...rest
  } = props;

  const styles = useStyles({ variant, message });
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const { isLoading } = useSelector(selectUpdateMeRequest);
  const recaptcha = useRecaptcha();

  const { control, handleSubmit, errors, watch } = useForm({
    defaultValues: me,
    validationSchema,
  });
  const value = watch(name);

  const handleUpdate = useCallback(
    async (data) => {
      try {
        await dispatch(updateMe(data, { isPromise: true }));
        onClose();
      } catch (err) {
        if (err.error.code === 'ERROR_CODE_TOO_MANY_REQUESTS') {
          const recaptchaResponse = await recaptcha();
          if (recaptchaResponse) {
            return handleUpdate({ ...data, recaptchaResponse });
          }
        }
      }
    },
    [dispatch, onClose, recaptcha],
  );

  const hadValue = Boolean(variant === 'switch' || me[name]);
  const Component = {
    input: Input,
    select: Select,
    switch: SwitchWithLabel,
  }[variant];

  const otherProps =
    variant === 'switch'
      ? { value: Boolean(value) }
      : { errorText: get(errors[name], 'message', null) };

  const dialogTitle = actionName
    ? actionName + ' ' + title
    : (variant !== 'switch' ? (hadValue ? 'Change ' : 'Add ') : '') + title;

  const submitButtonTitle = actionName
    ? actionName
    : hadValue
    ? !value && !required && variant === 'input'
      ? 'Remove'
      : 'Change'
    : 'Add';

  return (
    <Dialog
      title={dialogTitle}
      component="form"
      fullWidth={variant !== 'switch'}
      loading={isLoading}
      onSubmit={handleSubmit(handleUpdate)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">{submitButtonTitle}</Button>}
    >
      {message && (
        <Typography
          className={styles.text}
          variant="body1"
          color="textPrimary"
          align="center"
          gutterBottom
        >
          {message}
        </Typography>
      )}
      <Component
        {...rest}
        {...otherProps}
        data-testid="input"
        autoFocus={variant === 'input'}
        className={styles.input}
        control={control}
        label={title}
        name={name}
      />
    </Dialog>
  );
};

SwitchWithLabel.propTypes = {
  value: PropTypes.bool.isRequired,
};

UpdateMeFieldDialog.propTypes = {
  variant: PropTypes.oneOf(['input', 'select', 'switch']).isRequired,
  name: PropTypes.string.isRequired,
  actionName: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  validationSchema: PropTypes.any,
};

UpdateMeFieldDialog.defaultProps = {
  variant: 'input',
  required: false,
};

export default UpdateMeFieldDialog;
