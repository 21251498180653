import { useSelector } from 'react-redux';
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { selectAnyLoading } from 'Redux/selectors';

import { Menu as MenuIcon } from '@material-ui/icons';
import { makeStyles, IconButton, useMediaQuery } from '@material-ui/core';

import Header from './header';
import Footer from './footer';
import Drawer from './drawer';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    ...(props.footer
      ? { minHeight: 'calc(100% + 100px)', paddingBottom: 100 }
      : { minHeight: '100%' }),
  }),
  header: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  footer: {
    position: 'absolute',
    bottom: 0,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const PageLayout = (props) => {
  const {
    className,
    children,
    footer,
    drawerBreakpoint,
    drawerItems,
    headerItems,
    waiting: defaultWaiting,
  } = props;

  const styles = useStyles({ footer });
  const waiting = useSelector(selectAnyLoading) || defaultWaiting;
  const isBreakpointUp = useMediaQuery((theme) =>
    theme.breakpoints.up(drawerBreakpoint),
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleShowDrawer = useCallback(() => setIsDrawerOpen(true), []);
  const handleCloseDrawer = useCallback(() => setIsDrawerOpen(false), []);

  return (
    <div className={clsx(className, styles.root)}>
      <Header
        className={styles.header}
        showLoader={waiting}
        startAction={
          drawerItems &&
          !isBreakpointUp && (
            <IconButton edge="start" color="inherit" onClick={handleShowDrawer}>
              <MenuIcon />
            </IconButton>
          )
        }
        endAction={headerItems}
      />
      {drawerItems && (
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={handleCloseDrawer}
          items={drawerItems}
        />
      )}
      {children}
      {footer && <Footer className={styles.footer} />}
    </div>
  );
};

PageLayout.propTypes = {
  footer: PropTypes.bool,
  waiting: PropTypes.bool,
  drawerBreakpoint: PropTypes.string,
  drawerItems: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  headerItems: PropTypes.element,
};

PageLayout.defaultProps = {
  footer: true,
  drawer: null,
  headerItems: null,
  drawerBreakpoint: 'lg',
};

export default PageLayout;
