import { call, put, select } from 'redux-saga/effects';

import { findIndex, sortBy } from 'lodash';
import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { selectModules } from './modules';

const MODULE = 'sort-modules';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const SORT_MODULES_REQUEST = Exec.requestConstantCreator(MODULE);
export const SORT_MODULES_SUCCESS = Exec.successConstantCreator(MODULE);
export const SORT_MODULES_ERROR = Exec.errorConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const sortModules = Exec.requestActionCreator(MODULE);
export const sortModulesLoading = Exec.loadingActionCreator(MODULE);
export const sortModulesSuccess = Exec.successActionCreator(MODULE);
export const sortModulesError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectSortModulesRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doSortModules = function* ({ payload, meta }) {
  const { courseId, sortConfig } = payload;
  const modules = yield select(selectModules);

  try {
    yield put(closeSnackbar(SORT_MODULES_REQUEST));
    yield put(
      sortModulesSuccess({
        data: sortBy(modules, (m) => findIndex(sortConfig, ['id', m.id])),
      }),
    );
    yield put(sortModulesLoading());

    const { data } = yield call(Api.sortModules, courseId, { sortConfig });

    yield put(sortModulesSuccess({ data }, meta));
  } catch (err) {
    yield put(sortModulesError({ ...err, data: modules }, meta));
    yield put(
      enqueueSnackbar(SORT_MODULES_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doSortModules);
