import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, difference, isEmpty, omit, uniq } from 'lodash';

import { selectCreatePaymentMethodRequest } from 'Redux/selectors';
import { createPaymentMethod } from 'Redux/actions';
import VisaCardIcon from 'Icons/visa-card';
import UnionpayCardIcon from 'Icons/unionpay-card';
import MastercardCardIcon from 'Icons/mastercard-card';
import JcbCardIcon from 'Icons/jcb-card';
import DiscoverCardIcon from 'Icons/discover-card';
import DinersCardIcon from 'Icons/diners-card';
import AmexCardIcon from 'Icons/amex-card';
import StripeInput from 'Components/shared/stripe-input';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  makeStyles,
  useMediaQuery,
  Button,
  InputAdornment,
  Typography,
  TextField,
} from '@material-ui/core';

const schema = yup.object().shape({
  name: yup.string().required('Field Required.'),
});

const useStyles = makeStyles((theme) => ({
  cardInfoGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridRowGap: theme.spacing(1),
    gridColumnGap: theme.spacing(1) + 'px',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'auto 120px 76px',
      gridColumnGap: theme.spacing(3) + 'px',
    },
  },
  title: {
    margin: theme.spacing(3, 0),
  },
  name: {
    maxWidth: 240,
    gridColumnStart: 1,
    gridColumnEnd: -1,
  },
  number: {
    gridColumnStart: 1,
    gridColumnEnd: -1,
    [theme.breakpoints.up('sm')]: {
      gridColumn: 1,
    },
  },
  expiryDate: {
    maxWidth: 120,
  },
  cvc: {
    maxWidth: 76,
  },
  billingGrid: {
    marginTop: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '114px auto',
    gridRowGap: theme.spacing(1),
    gridColumnGap: theme.spacing(3) + 'px',
  },
  title2: {
    marginTop: theme.spacing(1),
  },
  country: {
    width: '100%',
    maxWidth: 320,
    gridColumnStart: 1,
    gridColumnEnd: -1,
  },
  postalCode: {
    width: '100%',
    maxWidth: 200,
    gridColumnStart: 1,
    gridColumnEnd: -1,
  },
  addressLine: {
    width: '100%',
    maxWidth: 400,
    gridColumnStart: 1,
    gridColumnEnd: -1,
  },
  row: {
    gridColumnStart: 1,
    gridColumnEnd: -1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  state: {
    flex: 1,
    maxWidth: 240,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1.5),
    },
  },
  city: {
    flex: 1,
    maxWidth: 240,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1.5),
    },
  },
  cardIcon: {
    margin: theme.spacing(1),
    width: 48,
  },
  checkbox: {
    gridColumnStart: 1,
    gridColumnEnd: -1,
    marginLeft: 0,
    overflow: 'hidden',
  },
  overflow: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: 'rgba(0, 0, 0, .05)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, .2)',
    },
  },
}));

const MuiStripeInput = (props) => {
  const {
    name,
    errorText,
    component,
    onBrand,
    onError,
    onComplete,
    endAdornment,
    ...rest
  } = props;

  const handleChange = (event) => {
    if (onBrand) {
      onBrand(event.brand);
    }
    onError(name, event.error ? { message: 'Invalid input.' } : null);
    onComplete(name, event.complete);
  };

  return (
    <TextField
      data-testid={name}
      variant="outlined"
      name={name}
      error={Boolean(errorText)}
      helperText={errorText || ' '}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment,
        inputComponent: StripeInput,
        inputProps: {
          component,
          onChange: handleChange,
          options: { style: { invalid: { color: 'inherit' } } },
        },
      }}
      {...rest}
    />
  );
};

const AddPaymentMethodDialog = (props) => {
  const { onClose } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectCreatePaymentMethodRequest);
  const stripe = useStripe();
  const elements = useElements();
  const { control, errors, setError, handleSubmit } = useForm({
    defaultValues: { isDefault: true },
  });
  const [stripeErrors, setStripeErrors] = useState({});
  const [stripeCompletedFields, setStripeCompletedFields] = useState([]);
  const [cardBrand, setCardBrand] = useState('unknown');

  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const validateSchema = useCallback(
    (data) => {
      try {
        return schema.validateSync(data, {
          abortEarly: false,
          stripUnknown: true,
        });
      } catch (err) {
        if (err.inner) {
          setError(
            err.inner.map((error) => ({
              name: error.path,
              message: error.message,
            })),
          );
        }
      }
      return null;
    },
    [setError],
  );

  const validateStripeFields = useCallback(() => {
    if (stripeCompletedFields.length !== 3) {
      const missingFileds = difference(
        ['number', 'exp', 'cvc'],
        stripeCompletedFields,
      );

      setStripeErrors({
        ...missingFileds.reduce(
          (prev, curr) => ({
            ...prev,
            [curr]: { message: 'Field required.' },
          }),
          {},
        ),
        ...stripeErrors,
      });

      return true;
    }

    return !isEmpty(stripeErrors);
  }, [stripeCompletedFields, stripeErrors]);

  const onSubmit = useCallback(
    async (data) => {
      const values = validateSchema(data);
      const hasStripeErrors = validateStripeFields();

      if (hasStripeErrors || !values) {
        return;
      }

      const element = elements.getElement(CardNumberElement);
      const { isDefault, ...billingDetails } = values;

      dispatch(
        createPaymentMethod(
          {
            stripe,
            data: {
              type: 'card',
              card: element,
              billing_details: billingDetails,
            },
            isDefault: true, // ignores the setting
          },
          { isPromise: true },
        ),
      ).then(onClose);
    },
    [dispatch, elements, onClose, stripe, validateSchema, validateStripeFields],
  );

  const handleStripeCardBrand = useCallback(
    (next) => {
      if (next !== cardBrand) {
        setCardBrand(next);
      }
    },
    [cardBrand],
  );
  const handleStripeFieldError = useCallback(
    (name, error) => {
      if (error) {
        setStripeErrors({ ...stripeErrors, [name]: error });
      } else {
        setStripeErrors(omit(stripeErrors, name));
      }
    },
    [stripeErrors],
  );

  const handleStripeCompletedField = useCallback(
    (name, completed) => {
      if (completed) {
        setStripeCompletedFields(uniq([...stripeCompletedFields, name]));
      } else {
        setStripeCompletedFields(
          stripeCompletedFields.filter((v) => v !== name),
        );
      }
    },
    [stripeCompletedFields],
  );

  const CardIcon = {
    amex: AmexCardIcon,
    diners: DinersCardIcon,
    discover: DiscoverCardIcon,
    jcb: JcbCardIcon,
    mastercard: MastercardCardIcon,
    unionpay: UnionpayCardIcon,
    visa: VisaCardIcon,
  }[cardBrand];

  return (
    <Dialog
      title="New Payment Method"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">Update</Button>}
    >
      <Typography variant="subtitle1" className={styles.title} gutterBottom>
        Credit Card Details
      </Typography>

      <div className={styles.cardInfoGrid}>
        <MuiStripeInput
          className={styles.number}
          label="Credit Card Number"
          name="number"
          errorText={get(stripeErrors, 'number.message')}
          component={CardNumberElement}
          onBrand={handleStripeCardBrand}
          onError={handleStripeFieldError}
          onComplete={handleStripeCompletedField}
          endAdornment={
            isSmUp &&
            CardIcon && (
              <InputAdornment position="end">
                <CardIcon className={styles.cardIcon} />
              </InputAdornment>
            )
          }
        />
        <MuiStripeInput
          className={styles.expiryDate}
          label="Expiry Date"
          name="exp"
          errorText={get(stripeErrors, 'exp.message')}
          component={CardExpiryElement}
          onError={handleStripeFieldError}
          onComplete={handleStripeCompletedField}
        />
        <MuiStripeInput
          className={styles.cvc}
          label="CVC"
          name="cvc"
          errorText={get(stripeErrors, 'cvc.message')}
          component={CardCvcElement}
          onError={handleStripeFieldError}
          onComplete={handleStripeCompletedField}
        />
        <Input
          data-testid="name"
          className={styles.name}
          control={control}
          label="Card Holder Name"
          name="name"
          errorText={get(errors, 'name.message')}
        />
      </div>
    </Dialog>
  );
};

MuiStripeInput.propTypes = {
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  component: PropTypes.elementType,
  onBrand: PropTypes.func,
  onError: PropTypes.func,
  onComplete: PropTypes.func,
  endAdornment: PropTypes.node,
};

AddPaymentMethodDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddPaymentMethodDialog;
