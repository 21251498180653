import { useCallback, useState, useMemo } from 'react';
import { isFunction } from 'lodash';

const computeValue = (valueOrFunc, prev) => {
  if (isFunction(valueOrFunc)) {
    const computed = valueOrFunc(prev);
    return computed;
  }
  return valueOrFunc;
};

const useLocalStorageState = (key, defaultState) => {
  const memoizedItem = useMemo(() => {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (err) {
      return null;
    }
  }, [key]);

  const [state, setState] = useState(
    memoizedItem === null ? computeValue(defaultState) : memoizedItem,
  );

  const setStateProxy = useCallback(
    (value) =>
      setState((prev) => {
        const computed = computeValue(value, prev);
        if (computed === null) {
          console.warn(
            'Computed value should never be `null` when using `useLocalStorageState`. Null values cause ambigious bahavior which is a result of localStorage engine implementation',
          );
        }
        localStorage.setItem(key, JSON.stringify(computed));
        return computed;
      }),
    [key],
  );

  return [state, setStateProxy];
};

export default useLocalStorageState;
