import { put, select, call } from 'redux-saga/effects';
import hash from 'object-hash';
import { toNumber, omit } from 'lodash';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { UPDATE_PAYMENT_LINK_SUCCESS } from './update-payment-link';
import { DELETE_PAYMENT_LINK_SUCCESS } from './delete-payment-link';

const MODULE = 'payment-links';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const FETCH_PAYMENT_LINKS_SET_ROWS_PER_PAGE =
  'fetch-payment-links-set-rows-per-page';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchPaymentLinks = Exec.requestActionCreator(MODULE);
export const fetchPaymentLinksLoading = Exec.loadingActionCreator(MODULE);
export const fetchPaymentLinksSuccess = Exec.successActionCreator(MODULE);
export const fetchPaymentLinksError = Exec.errorActionCreator(MODULE);
export const fetchPaymentLinksSetRowsPerPage = (pageSize) => {
  return {
    type: FETCH_PAYMENT_LINKS_SET_ROWS_PER_PAGE,
    payload: {
      pageSize,
    },
  };
};

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.fetchReducerCreator(MODULE, {
  defaultState: { pageSize: 25 },
  cacheKeyFunction: (payload) =>
    hash(omit(payload, ['orderBy', 'orderDirection'])),
});
export const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_LINKS_SET_ROWS_PER_PAGE:
      return {
        ...state,
        pageSize: action.payload.pageSize,
      };
    case UPDATE_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        data: state.data
          ? state.data.map((link) =>
              link.id === action.payload.data.id ? action.payload.data : link,
            )
          : null,
      };
    case DELETE_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        data: state.data
          ? state.data.filter((link) => link.id !== action.payload.data.id)
          : null,
      };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectPaymentLinksRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectPaymentLinks = Exec.dataSelectorCreator(MODULE);

export const selectPaymentLinksRowsPerPage = (store) =>
  store.paymentLinks.pageSize;
export const selectPaymentLinksTotalCount = (store) =>
  store.paymentLinks.pageInfo ? store.paymentLinks.pageInfo.totalCount : 0;

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchPaymentLinks = function* ({ payload, meta }) {
  const { page = 0, ...searchOptions } = payload || {};
  const pageSize = yield select(selectPaymentLinksRowsPerPage);
  const offset = pageSize * toNumber(page);

  try {
    const { data, __meta = {} } = yield call(
      Api.getPaymentLinks,
      searchOptions,
      pageSize,
      offset,
    );

    yield put(
      fetchPaymentLinksSuccess({ data, pageInfo: __meta.pageInfo }, meta),
    );
  } catch (err) {
    yield put(fetchPaymentLinksError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchPaymentLinks);
