import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import UserIcon from 'Icons/user';
import PaymentIcon from 'Icons/payment';

import { Home as HomeIcon, Repeat as RepeatIcon } from '@material-ui/icons';
import { List } from '@material-ui/core';

import DrawerItem from './drawer-item';

const DrawerAccountItems = (props) => {
  const { className, onClose } = props;

  return (
    <List className={className}>
      <DrawerItem
        onClick={onClose}
        component={Link}
        to="/account"
        text="Home"
        icon={HomeIcon}
        data-testid="home"
      />
      <DrawerItem
        onClick={onClose}
        component={Link}
        to="/account/me"
        text="My Account"
        icon={UserIcon}
        data-testid="myAccount"
      />
      <DrawerItem
        onClick={onClose}
        component={Link}
        to="/account/payment"
        text="Payment Info"
        icon={PaymentIcon}
        data-testid="paymentInfo"
      />
      <DrawerItem
        onClick={onClose}
        component={Link}
        to="/account/subscriptions"
        text="My Subscriptions"
        icon={RepeatIcon}
        data-testid="subscriptions"
      />
    </List>
  );
};

DrawerAccountItems.propTypes = {
  onClose: PropTypes.func,
};

export default DrawerAccountItems;
