import { memo } from 'react';
import clsx from 'clsx';

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 100,
    backgroundColor: '#ececec',
    color: '#525252',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 145,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,
    },
  },
  title: {
    paddingLeft: 15,
    paddingTop: 4,
  },
}));

const Footer = ({ className }) => {
  const styles = useStyles();

  return (
    <footer className={clsx(styles.root, className)}>
      <Typography className={styles.title} variant="caption">
        Copyright © {new Date().getFullYear()} Freedom.co
      </Typography>
    </footer>
  );
};

Footer.whyDidYouRender = true;

export default memo(Footer);
