import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const PaypalIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 54 60" {...props}>
      <path
        d="M6.774 45.861h-4.14a.575.575 0 00-.569.484L.391 56.928a.344.344 0 00.341.397H2.71a.575.575 0 00.568-.485l.452-2.854a.574.574 0 01.568-.484h1.31c2.727 0 4.301-1.316 4.712-3.923.186-1.14.008-2.037-.527-2.664-.589-.69-1.632-1.054-3.018-1.054zm.478 3.865c-.227 1.481-1.362 1.481-2.46 1.481h-.624l.438-2.765a.345.345 0 01.34-.29h.287c.748 0 1.453 0 1.818.424.217.254.283.63.2 1.15zM19.146 49.68h-1.982a.345.345 0 00-.34.29l-.089.553-.138-.2c-.43-.621-1.387-.829-2.342-.829-2.19 0-4.062 1.654-4.426 3.974-.19 1.158.08 2.264.738 3.036.604.71 1.469 1.006 2.497 1.006 1.766 0 2.745-1.132 2.745-1.132l-.089.55a.344.344 0 00.34.398h1.786a.575.575 0 00.569-.485l1.071-6.764a.343.343 0 00-.34-.397zm-2.763 3.846c-.191 1.13-1.09 1.887-2.237 1.887-.576 0-1.036-.184-1.331-.532-.293-.347-.404-.84-.311-1.389.178-1.12 1.093-1.902 2.222-1.902.563 0 1.02.186 1.322.538.302.356.422.852.335 1.398zM29.71 49.678h-1.993a.578.578 0 00-.476.251l-2.748 4.035-1.165-3.877a.577.577 0 00-.552-.41H20.82a.344.344 0 00-.328.456l2.195 6.42-2.063 2.902a.344.344 0 00.281.544h1.99a.575.575 0 00.473-.247l6.626-9.534a.344.344 0 00-.284-.54z"
        fill="#253B80"
      />
      <path
        d="M36.301 45.861h-4.14a.575.575 0 00-.568.484l-1.675 10.583a.344.344 0 00.34.397h2.125a.402.402 0 00.398-.34l.475-2.999a.574.574 0 01.568-.484h1.31c2.727 0 4.3-1.316 4.712-3.923.186-1.14.007-2.037-.528-2.664-.588-.69-1.631-1.054-3.017-1.054zm.478 3.865c-.226 1.481-1.361 1.481-2.46 1.481h-.623l.439-2.765a.343.343 0 01.34-.29h.286c.747 0 1.453 0 1.817.424.218.254.284.63.2 1.15zM48.679 49.68h-1.981a.343.343 0 00-.34.29l-.088.553-.14-.2c-.429-.621-1.385-.829-2.34-.829-2.191 0-4.062 1.654-4.426 3.974-.189 1.158.08 2.264.738 3.036.605.71 1.468 1.006 2.497 1.006 1.765 0 2.744-1.132 2.744-1.132l-.089.55a.344.344 0 00.342.398h1.785a.574.574 0 00.568-.485l1.072-6.764a.345.345 0 00-.342-.397zm-2.764 3.846c-.19 1.13-1.09 1.887-2.236 1.887-.575 0-1.036-.184-1.331-.532-.293-.347-.404-.84-.312-1.389.18-1.12 1.093-1.902 2.222-1.902.563 0 1.02.186 1.322.538.303.356.423.852.336 1.398zM51.012 46.152l-1.7 10.776a.344.344 0 00.341.398h1.708a.574.574 0 00.569-.485l1.675-10.582a.344.344 0 00-.34-.398h-1.913a.345.345 0 00-.34.291z"
        fill="#179BD7"
      />
      <path
        d="M19.214 38.017l.687-4.347-1.53-.035h-7.312l5.08-32.077a.416.416 0 01.413-.35h12.329c4.092-.001 6.917.847 8.392 2.52.69.786 1.131 1.606 1.344 2.51.224.947.227 2.078.01 3.46l-.017.1v.884l.692.39c.582.308 1.045.66 1.4 1.063.591.671.974 1.524 1.135 2.536.167 1.04.112 2.278-.162 3.68-.315 1.612-.825 3.016-1.514 4.165a8.581 8.581 0 01-2.398 2.617c-.915.646-2.002 1.137-3.23 1.451-1.192.309-2.55.465-4.038.465h-.96c-.686 0-1.352.246-1.875.687a2.89 2.89 0 00-.978 1.738l-.072.39-1.215 7.662-.055.282c-.014.089-.04.133-.076.163a.204.204 0 01-.127.046h-5.923z"
        fill="#253B80"
      />
      <path
        d="M38.399 9.024a13.235 13.235 0 00-1.598-.346 20.809 20.809 0 00-3.221-.229h-9.764c-.24 0-.468.053-.673.15-.45.21-.785.626-.866 1.134L20.2 22.558l-.06.374a1.762 1.762 0 011.755-1.466h3.654c7.178 0 12.798-2.841 14.44-11.062.05-.243.091-.48.128-.712a8.884 8.884 0 00-1.35-.555c-.12-.04-.243-.077-.368-.113z"
        fill="#222D65"
      />
      <path
        d="M39.832 9.656c-.037.23-.079.466-.126.708-1.622 8.172-7.172 10.995-14.26 10.995h-3.609c-.866 0-1.597.618-1.732 1.457l-1.848 11.499-.523 3.26a.912.912 0 00.911 1.047h6.401c.758 0 1.402-.54 1.521-1.274l.063-.32 1.205-7.504.078-.412a1.53 1.53 0 011.521-1.276h.957c6.202 0 11.056-2.47 12.475-9.62.593-2.987.286-5.48-1.282-7.234-.475-.53-1.064-.968-1.752-1.326z"
        fill="#179BD7"
      />
      <path
        d="M22.399 10.043a1.525 1.525 0 011.513-1.292h9.598c1.138 0 2.199.074 3.168.23a12.734 12.734 0 011.934.46c.476.159.918.345 1.327.56.48-3.061-.004-5.146-1.66-7.033C36.451.89 33.154 0 28.936 0H16.691a1.75 1.75 0 00-1.73 1.478l-5.1 32.306c-.1.64.393 1.216 1.038 1.216h7.56l1.898-12.034L22.4 10.043z"
        fill="#253B80"
      />
    </SvgIcon>
  );
};

export default PaypalIcon;
