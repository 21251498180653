import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'update-achievment-level';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPDATE_ACHIEVEMENT_LEVEL_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPDATE_ACHIEVEMENT_LEVEL_SUCCESS =
  Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const updateAchievementLevel = Exec.requestActionCreator(MODULE);
export const updateAchievementLevelLoading = Exec.loadingActionCreator(MODULE);
export const updateAchievementLevelSuccess = Exec.successActionCreator(MODULE);
export const updateAchievementLevelError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpdateAchievementLevelRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpdateAchievementLevel = function* ({ payload, meta }) {
  const { achievementLevelId: id, ...options } = payload || {};
  try {
    yield put(closeSnackbar(UPDATE_ACHIEVEMENT_LEVEL_REQUEST));
    yield put(updateAchievementLevelLoading());

    const { data } = yield call(Api.updateAchievementLevel, id, options);
    yield put(updateAchievementLevelSuccess({ data }, meta));
  } catch (err) {
    yield put(updateAchievementLevelError(err, meta));
    yield put(
      enqueueSnackbar(UPDATE_ACHIEVEMENT_LEVEL_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.message || err.detail,
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpdateAchievementLevel);
