import * as yup from 'yup';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { get, noop, trim } from 'lodash';
import clsx from 'clsx';

import languages from 'Util/languages';
import countries from 'Util/countries';
import {
  selectMe,
  selectMeRequest,
  selectUpdateMeRequest,
} from 'Redux/selectors';
import {
  updateMe,
  enqueueSnackbar,
  closeSnackbar,
  setOnboardingPostponedAt,
} from 'Redux/actions';
import FacebookIcon from 'Icons/facebook';
import useNavigateExternal from 'Hooks/use-navigate-external';
import UploadProfilePictureDialog from 'Dialogs/upload-profile-picture-dialog';
import ConfirmDeleteProfilePictureDialog from 'Dialogs/confirm-delete-profile-picture-dialog';
import UserAvatar from 'Components/shared/user-avatar';
import Select from 'Components/shared/select';
import PageLoader from 'Components/shared/page-loader';
import NavigateExternal from 'Components/shared/navigate-external';
import LogoLarge from 'Components/shared/logo-large';
import Input from 'Components/shared/input';

import {
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  Close as CloseIcon,
  AddAPhoto as AddAPhotoIcon,
  Add as AddIcon,
  Done as DoneIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  IconButton,
  Typography,
  Paper,
  Grid,
  Stepper,
  Step,
  StepLabel,
  darken,
  ButtonBase,
  LinearProgress,
} from '@material-ui/core';

const { REACT_APP_CONNECT_URL = '' } = process.env;

const schemaFields = {
  city: yup.string().nullable().required('Field Required.'),
  country: yup.string().nullable().required('Field Required.'),
  goal: yup.string().nullable().required('Field Required.'),
  bio: yup.string().nullable(),
  spokenLanguages: yup
    .array()
    .of(yup.string())
    .min(1, 'Choose At Least One.')
    .required('Field Required.'),
};
const schema = yup.object().shape(schemaFields);
const schemaWithNames = yup.object().shape({
  ...schemaFields,
  firstName: yup.string().nullable().required('Field Required.'),
  lastName: yup.string().nullable().required('Field Required.'),
});

const countryOptions = countries.map((c) => ({ value: c.code, label: c.name }));
const languageOptions = languages.map((c) => ({
  value: c.code,
  label: c.name,
}));

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4, 0, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(9, 4.5),
    },
  },
  logo: {
    opacity: 0.6,
    width: 302,
    height: 82,
  },
  paper: {
    position: 'relative',
    marginTop: 50,
    maxWidth: 696,
    width: '100%',
    padding: theme.spacing(5, 3),
    borderRadius: '32px 32px 0 0',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6),
      borderRadius: 16,
    },
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflow: 'hidden',
    borderRadius: 32,
    [theme.breakpoints.up('sm')]: {
      borderRadius: 16,
    },
  },
  floatingButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: -48 - theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: darken(theme.palette.background.paper, 0.1),
    },
    boxShadow: theme.shadows[1],
    [theme.breakpoints.up('sm')]: {
      right: 0,
    },
  },
  grid: {
    overflow: 'hidden',
  },
  stepper: {
    padding: 0,
    justifyContent: 'center',
  },
  step: {
    maxWidth: 160,
  },
  inputLong: {
    flex: 1,
    marginBottom: -18, // ignore the error text height
  },
  inputShort: {
    flex: 1,
    marginBottom: -18, // ignore the error text height
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 220,
    },
  },
  stepLabelSpacing: {
    '&.MuiStepLabel-alternativeLabel': {
      marginTop: theme.spacing(1),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  avatarIcon: {
    width: 60,
    height: 60,
    borderWidth: 1,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[50],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  avatarWithButton: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  deleteAvatarButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  facebook: {
    color: '#3B5998',
  },
  instagram: {
    color: '#BC2A8D',
  },
  twitter: {
    color: '#1DA1F2',
  },
  connectButton: {
    width: '100%',
    borderRadius: 9,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: `1px solid ${theme.palette.grey[300]}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1.5, 3),
    },
  },
  connectIcon: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.grey[300]}`,
    width: 48,
    height: 48,
    [theme.breakpoints.up('sm')]: {
      width: 64,
      height: 64,
    },
  },
  paddedIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  success: {
    color: theme.palette.success.main,
  },
  neutral: {
    color: theme.palette.text.secondary,
  },
}));

const OnboardingPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { hash, search } = useLocation();
  const navigate = useNavigateExternal();

  const [params] = useSearchParams();
  const connectError = params.get('connectError');
  const connectSuccess = params.get('connectSuccess');
  const redirectTo = params.get('redirectTo');

  const me = useSelector(selectMe);
  const { isLoading } = useSelector(selectMeRequest);
  const { isLoading: isLoadingUpdate } = useSelector(selectUpdateMeRequest);

  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const currentStep = hash === '#contact-info' ? 1 : 0;

  const [
    showUploadProfilePictureModal,
    hideUploadProfilePictureModal,
  ] = useModal(() => (
    <UploadProfilePictureDialog onClose={hideUploadProfilePictureModal} />
  ));
  const [
    showDeleteProfilePictureModal,
    hideDeleteProfilePictureModal,
  ] = useModal(() => (
    <ConfirmDeleteProfilePictureDialog
      onClose={hideDeleteProfilePictureModal}
    />
  ));

  useEffect(() => {
    if (connectError) {
      dispatch(
        enqueueSnackbar('CONNECT_STATUS', {
          message:
            'Sorry about that! We failed to connect your account, try again later',
          variant: 'error',
        }),
      );
    } else if (connectSuccess) {
      dispatch(
        enqueueSnackbar('CONNECT_STATUS', {
          message: 'Successfully connected your account!',
          variant: 'success',
        }),
      );
    }
    return () => dispatch(closeSnackbar('CONNECT_STATUS'));
  }, [connectError, connectSuccess, dispatch]);

  const handleCancel = useCallback(() => {
    // Just doing this should redirect us to some other page
    // Check out AuthStateGuard for implementation details
    dispatch(setOnboardingPostponedAt(new Date()));
  }, [dispatch]);

  const handleBack = useCallback(() => {
    if (currentStep === 1) {
      navigate(search + '#');
    } else {
      handleCancel();
    }
  }, [currentStep, handleCancel, navigate, search]);

  const onSubmit = useCallback(
    (data) => {
      if (currentStep === 0) {
        dispatch(updateMe(data, { isPromise: true }))
          .then(() => navigate(search + '#contact-info'))
          .catch(noop);
      }
    },
    [currentStep, dispatch, navigate, search],
  );

  const handleSave = useCallback(() => {
    if (currentStep === 1) {
      dispatch(updateMe({ profileCompletionLevel: 1 }, { isPromise: true }))
        .then(() => navigate(redirectTo || '/'))
        .catch(noop);
    }
  }, [currentStep, dispatch, navigate, redirectTo]);

  // const socialButtons = ['facebook', 'instagram', 'twitter']; // disabled temporary because facebook
  const socialButtons = ['twitter'];
  const socialIcons = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    twitter: TwitterIcon,
  };

  if (me && me.profileCompletionLevel === 1) {
    return <NavigateExternal replace to={redirectTo || '/'} />;
  }

  return (
    <PageLoader loading={isLoading}>
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { control, handleSubmit, errors } = useForm({
          defaultValues: me,
          validationSchema:
            !me.firstName || !me.lastName ? schemaWithNames : schema,
        });

        if (!me) {
          return null;
        }

        return (
          <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
            <LogoLarge className={styles.logo} />
            <Paper elevation={12} className={styles.paper}>
              {isLoadingUpdate && (
                <div className={styles.loader}>
                  <LinearProgress aria-busy />
                </div>
              )}
              <IconButton
                data-testid="floatingButton"
                className={styles.floatingButton}
                onClick={handleCancel}
              >
                <CloseIcon />
              </IconButton>
              <Grid
                className={styles.grid}
                container
                direction="column"
                spacing={5}
              >
                <Grid item>
                  <Stepper
                    className={styles.stepper}
                    activeStep={currentStep}
                    alternativeLabel
                  >
                    <Step className={styles.step} completed={currentStep > 0}>
                      <StepLabel classes={{ label: styles.stepLabelSpacing }}>
                        My
                        <br />
                        Profile
                      </StepLabel>
                    </Step>
                    <Step className={styles.step} disabled={currentStep < 1}>
                      <StepLabel classes={{ label: styles.stepLabelSpacing }}>
                        Contact
                        <br />
                        Info
                      </StepLabel>
                    </Step>
                  </Stepper>
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={styles.title}>
                    Let’s Setup Your Profile!
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Fill in the information which you’d like to share with
                    fellow students (future friends, or even potential business
                    partners).
                  </Typography>
                </Grid>
                {currentStep === 0 ? (
                  <Grid item container direction="column" spacing={4}>
                    <Grid item>
                      {me.image ? (
                        <div className={styles.avatarWithButton}>
                          <UserAvatar user={me} size={60} />
                          {isSmUp ? (
                            <Button
                              className={styles.deleteAvatarButton}
                              startIcon={<DeleteOutlineIcon />}
                              onClick={showDeleteProfilePictureModal}
                            >
                              Delete
                            </Button>
                          ) : (
                            <IconButton onClick={showDeleteProfilePictureModal}>
                              <DeleteOutlineIcon fontSize="small" />
                            </IconButton>
                          )}
                        </div>
                      ) : (
                        <IconButton
                          type="button"
                          data-testid="addAvatar"
                          className={styles.avatarIcon}
                          onClick={showUploadProfilePictureModal}
                          disabled={isLoadingUpdate}
                        >
                          <AddAPhotoIcon />
                        </IconButton>
                      )}
                    </Grid>
                    {!me.firstName || !me.lastName ? (
                      <Grid
                        item
                        container
                        spacing={4}
                        direction={isSmUp ? 'row' : 'column'}
                      >
                        <Grid item>
                          <Input
                            data-testid="firstName"
                            className={styles.inputShort}
                            control={control}
                            disabled={isLoadingUpdate}
                            label="First Name"
                            name="firstName"
                            errorText={get(errors, 'firstName.message')}
                          />
                        </Grid>
                        <Grid item>
                          <Input
                            data-testid="lastName"
                            className={styles.inputShort}
                            control={control}
                            disabled={isLoadingUpdate}
                            label="Last Name"
                            name="lastName"
                            errorText={get(errors, 'lastName.message')}
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                    <Grid item container>
                      <Input
                        data-testid="bio"
                        className={styles.inputLong}
                        control={control}
                        disabled={isLoadingUpdate}
                        label="Bio"
                        name="bio"
                        multiline
                        rows={isSmUp ? 2 : 4}
                        rowsMax={4}
                        errorText={get(errors, 'bio.message')}
                      />
                    </Grid>
                    <Grid item container>
                      <Input
                        data-testid="goal"
                        className={styles.inputLong}
                        control={control}
                        disabled={isLoadingUpdate}
                        label="My Long Term Goal"
                        name="goal"
                        multiline
                        rows={isSmUp ? 3 : 5}
                        rowsMax={5}
                        errorText={get(errors, 'goal.message')}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      spacing={4}
                      direction={isSmUp ? 'row' : 'column'}
                    >
                      <Grid item>
                        <Input
                          data-testid="city"
                          className={styles.inputShort}
                          control={control}
                          disabled={isLoadingUpdate}
                          label="City"
                          name="city"
                          errorText={get(errors, 'city.message')}
                        />
                      </Grid>
                      <Grid item>
                        <Select
                          data-testid="country"
                          control={control}
                          className={styles.inputShort}
                          disabled={isLoadingUpdate}
                          label="Country"
                          name="country"
                          options={countryOptions}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Select
                        multiple
                        data-testid="spokenLanguages"
                        control={control}
                        className={styles.inputShort}
                        disabled={isLoadingUpdate}
                        label="Spoken Languages"
                        name="spokenLanguages"
                        options={languageOptions}
                        errorText={get(errors, 'spokenLanguages.message')}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container direction="column" spacing={2}>
                    {socialButtons.map((name) => {
                      const connected = Boolean(me[name]);
                      const Icon = socialIcons[name];

                      const connectUrl = `${trim(
                        REACT_APP_CONNECT_URL,
                        '/',
                      )}/${name}?userId=${me.id}&continueTo=${
                        window.location.href
                      }`;

                      return (
                        <Grid item key={name}>
                          <ButtonBase
                            type="button"
                            className={styles.connectButton}
                            href={connectUrl}
                            disabled={connected}
                          >
                            <div
                              className={clsx(styles.connectIcon, styles[name])}
                            >
                              <Icon />
                            </div>
                            {connected ? (
                              <DoneIcon
                                className={clsx(
                                  styles.paddedIcon,
                                  styles.success,
                                )}
                              />
                            ) : (
                              <AddIcon
                                className={clsx(
                                  styles.paddedIcon,
                                  styles.neutral,
                                )}
                              />
                            )}
                            <Typography
                              className={
                                connected ? styles.success : styles.neutral
                              }
                              variant={isSmUp ? 'body1' : 'body2'}
                            >
                              {connected
                                ? 'Account connected'
                                : `Connect account`}
                            </Typography>
                          </ButtonBase>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                <Grid
                  item
                  container
                  direction="row"
                  justify="center"
                  wrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      type="button"
                      variant="outlined"
                      color="primary"
                      onClick={handleBack}
                      disabled={isLoadingUpdate}
                    >
                      {currentStep === 0 ? 'Do it later' : 'Previous Step'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={isLoadingUpdate}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                    >
                      {currentStep === 0 ? 'Next Step' : 'Save Profile'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </form>
        );
      }}
    </PageLoader>
  );
};

export default OnboardingPage;
