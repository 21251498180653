import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useFieldArray, useForm, FormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import {
  find,
  toString,
  get,
  includes,
  orderBy,
  noop,
  uniqBy,
  isEqual,
  toNumber,
  filter,
  map,
} from 'lodash';
import clsx from 'clsx';
import { unformat } from 'accounting';

import ensurePrice from 'Util/ensure-price';
import {
  selectCoursesRequest,
  selectCourses,
  selectOffersRequest,
  selectOffers,
  selectCreateOfferRequest,
  selectUpdateOfferRequest,
} from 'Redux/selectors';
import {
  createOffer,
  enqueueSnackbar,
  fetchCourses,
  fetchOffers,
  updateOffer,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useMemoizedCallback from 'Hooks/use-memoized-callback';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import ConfirmArchiveOfferDialog from 'Dialogs/confirm-archive-offer-dialog';
import Switch from 'Components/shared/switch';
import Select from 'Components/shared/select';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import CourseCard from 'Components/shared/course-card';
import WideButton from 'Components/pages/admin/wide-button';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import PaymentOption from 'Components/pages/admin/payment-option';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import {
  Save as SaveIcon,
  Add as AddIcon,
  ArchiveOutlined as ArchiveOutlinedIcon,
} from '@material-ui/icons';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  FormControlLabel,
  useMediaQuery,
  Box,
} from '@material-ui/core';

const schema = yup.object().shape({
  title: yup.string().required('Field Required.'),
  slug: yup.string().required('Field Required.'),
  requireSignature: yup.boolean().required('Field Required.'),
  signNowTemplateId: yup.string().when('requireSignature', {
    is: (v) => Boolean(v),
    then: yup
      .string()
      .transform((value) => (!value ? undefined : value))
      .required('Field Required.'),
    otherwise: yup.string().transform(() => undefined),
  }),
  hasFreeTrial: yup.boolean().required('Field Required.'),
  hasMentorship: yup.boolean().required('Field Required.'),
  upsellsOfferId: yup.string().when('hasMentorship', {
    is: (v) => Boolean(v),
    then: yup
      .string()
      .default(null)
      .nullable()
      .transform((value) => (!value ? null : value)),
    otherwise: yup.string().transform(() => undefined),
  }),
  mentorshipUpgradePrice: yup.object().when('hasMentorship', {
    is: (v) => !Boolean(v),
    then: yup.object({
      amount: yup.mixed().transform((value) => {
        const asPrice = unformat(value);
        return isNaN(asPrice) ? undefined : asPrice;
      }),
    }),
    otherwise: yup.object().transform(() => undefined),
  }),
  upgradeAmount: yup
    .string()
    .transform((value) => (!value ? undefined : value))
    .when('hasFreeTrial', {
      is: (v) => Boolean(v),
      then: yup
        .string()
        .transform((value) => (!value ? undefined : value))
        .when('prices', {
          is: (v) => v && v.length,
          then: yup
            .string()
            .transform((value) => (!value ? undefined : value))
            .required('Field Required.'),
        }),
    }),
  freeTrialDuration: yup
    .number()
    .transform((value) => (isNaN(value) || !value ? undefined : value))
    .when('hasFreeTrial', {
      is: (v) => Boolean(v),
      then: yup
        .number()
        .transform((value) => (isNaN(value) || !value ? undefined : value))
        .typeError('Not a Number.')
        .min(1, 'Must be >= 1.')
        .required('Field Required.'),
    }),
  taxBehavior: yup
    .string()
    .oneOf(['inclusive', 'exclusive', 'unspecified'])
    .required(),
  affiliateDiscountType: yup.string().oneOf(['percent', 'amount']).required(),
  prices: yup.mixed().when('affiliateDiscountType', {
    is: (type) => type === 'percent',
    then: yup.array().of(
      yup.object().shape(
        {
          installmentCount: yup
            .number()
            .min(1, 'At Least 1.')
            .transform((value) => (isNaN(value) || !value ? undefined : value))
            .typeError('Not a Number.')
            .required('Field Required.'),
          amount: yup
            .mixed()
            .transform((value) => {
              const asPrice = unformat(value);
              return isNaN(asPrice) ? undefined : asPrice;
            })
            .required('Field Required.'),
          affiliateDiscount: yup
            .number()
            .transform((value) => (isNaN(value) || !value ? undefined : value))
            .when('affiliateBonus', {
              is: (v) => Boolean(v),
              then: yup
                .number()
                .min(1, 'At Least 1.')
                .max(100, 'Max 100.')
                .default(null)
                .nullable()
                .transform((value) =>
                  isNaN(value) || !value ? undefined : value,
                )
                .typeError('Not a Number.')
                .required('Field Required.'),
            }),
          affiliateBonus: yup
            .number()
            .transform((value) => (isNaN(value) || !value ? undefined : value))
            .when('affiliateDiscount', {
              is: (v) => Boolean(v),
              then: yup
                .number()
                .min(1, 'At Least 1.')
                .max(100, 'Max 100.')
                .default(null)
                .nullable()
                .transform((value) =>
                  isNaN(value) || !value ? undefined : value,
                )
                .typeError('Not a Number.')
                .required('Field Required.'),
            }),
          superAffiliateConfig: yup.array().of(
            yup.object().shape(
              {
                triggerAfter: yup
                  .number()
                  .min(0, 'Must Be >= 0.')
                  .typeError('Not a Number.')
                  .required('Field Required.'),
                affiliateDiscount: yup
                  .number()
                  .transform((value) =>
                    isNaN(value) || !value ? undefined : value,
                  )
                  .when('affiliateBonus', {
                    is: (v) => Boolean(v),
                    then: yup
                      .number()
                      .min(1, 'At Least 1.')
                      .max(100, 'Max 100.')
                      .default(null)
                      .nullable()
                      .transform((value) =>
                        isNaN(value) || !value ? undefined : value,
                      )
                      .typeError('Not a Number.')
                      .required('Field Required.'),
                  }),
                affiliateBonus: yup
                  .number()
                  .transform((value) =>
                    isNaN(value) || !value ? undefined : value,
                  )
                  .when('affiliateDiscount', {
                    is: (v) => Boolean(v),
                    then: yup
                      .number()
                      .min(1, 'At Least 1.')
                      .max(100, 'Max 100.')
                      .default(null)
                      .nullable()
                      .transform((value) =>
                        isNaN(value) || !value ? undefined : value,
                      )
                      .typeError('Not a Number.')
                      .required('Field Required.'),
                  }),
              },
              [['affiliateDiscount', 'affiliateBonus']],
            ),
          ),
        },
        [['affiliateDiscount', 'affiliateBonus']],
      ),
    ),
    otherwise: yup.array().of(
      yup.object().shape(
        {
          installmentCount: yup
            .number()
            .min(1, 'At Least 1.')
            .transform((value) => (isNaN(value) || !value ? undefined : value))
            .typeError('Not a Number.')
            .required('Field Required.'),
          amount: yup
            .mixed()
            .transform((value) => {
              const asPrice = unformat(value);
              return isNaN(asPrice) ? undefined : asPrice;
            })
            .required('Field Required.'),
          affiliateDiscount: yup
            .mixed()
            .transform((value) => {
              const asPrice = unformat(value);
              return isNaN(asPrice) ? undefined : asPrice;
            })
            .when('affiliateBonus', {
              is: (v) => Boolean(v),
              then: (schema) =>
                schema.default(null).nullable().required('Field Required.'),
            }),
          affiliateBonus: yup
            .mixed()
            .transform((value) => {
              const asPrice = unformat(value);
              return isNaN(asPrice) ? undefined : asPrice;
            })
            .when('affiliateDiscount', {
              is: (v) => Boolean(v),
              then: (schema) =>
                schema.default(null).nullable().required('Field Required.'),
            }),
          superAffiliateConfig: yup.array().of(
            yup.object().shape(
              {
                triggerAfter: yup
                  .number()
                  .min(0, 'Must Be >= 0.')
                  .typeError('Not a Number.')
                  .required('Field Required.'),
                affiliateDiscount: yup
                  .mixed()
                  .transform((value) => {
                    const asPrice = unformat(value);
                    return isNaN(asPrice) ? undefined : asPrice;
                  })
                  .when('affiliateBonus', {
                    is: (v) => Boolean(v),
                    then: (schema) =>
                      schema
                        .default(null)
                        .nullable()
                        .required('Field Required.'),
                  }),
                affiliateBonus: yup
                  .mixed()
                  .transform((value) => {
                    const asPrice = unformat(value);
                    return isNaN(asPrice) ? undefined : asPrice;
                  })
                  .when('affiliateDiscount', {
                    is: (v) => Boolean(v),
                    then: (schema) =>
                      schema
                        .default(null)
                        .nullable()
                        .required('Field Required.'),
                  }),
              },
              [['affiliateDiscount', 'affiliateBonus']],
            ),
          ),
        },
        [['affiliateDiscount', 'affiliateBonus']],
      ),
    ),
  }),
});

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  input: {
    width: '100%',
  },
  switch: {
    height: 58,
  },
  switchFalse: {
    marginBottom: 21.38,
  },
  paymentOptions: {
    marginTop: theme.spacing(3),
  },
  pricesTable: {
    width: '100%',
    '& td': {
      paddingTop: 18,
    },
  },
  newPrice: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  priceInstallments: {
    [theme.breakpoints.up('md')]: {
      width: '13%',
      minWidth: 124,
    },
  },
  priceAmount: {
    [theme.breakpoints.down('md')]: {
      minWidth: 124,
    },
  },
  priceTotal: {
    height: 58,
    width: '22%',
    padding: theme.spacing(0, 2, 2.25, 2),
  },
  priceTotalHeader: {
    padding: theme.spacing(0, 2),
  },
  priceRemove: {
    maxWidth: 60,
    paddingBottom: theme.spacing(2.25),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 2, 2.25, 2),
    },
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
  },
}));

const AdminCreateOrUpdateOfferPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { offerId } = useParams();

  const courses = useSelector(selectCourses);
  const offers = useSelector(selectOffers);

  const offer = find(offers, ['id', offerId]);

  const { isLoading, error } = useSelector(selectCoursesRequest);
  const { isLoading: isLoadingOffer, error: errorOffer } =
    useSelector(selectOffersRequest);

  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectCreateOfferRequest,
    selectUpdateOfferRequest,
  );

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchOffers());
  }, [dispatch]);

  const [showArchiveOfferModal, hideArchiveOfferModal] = useModal(
    () =>
      offerId ? (
        <ConfirmArchiveOfferDialog
          offerId={offerId}
          onClose={hideArchiveOfferModal}
        />
      ) : null,
    [offerId],
  );

  if (!isLoadingOffer && offerId && !offer) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader
      loading={isLoading || (offerId && isLoadingOffer)}
      error={error || (offerId ? errorOffer : null)}
    >
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [courseId, setCourseId] = useState(offer ? offer.courseId : null);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [bonusCourseIds, setBonusCourseIds] = useState(
          offer ? offer.bonusCourseIds : [],
        );

        const supportsMentorship = find(
          courses,
          (course) =>
            [courseId, ...bonusCourseIds].includes(course.id) &&
            course.isMentorshipEnabled,
        );

        const upsellOffers = filter(
          offers,
          (o) =>
            !o.hasMentorship && o.courseId === courseId && o.id !== offerId,
        );

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const handleFormSubmit = useCallback(
          async (data) => {
            if (!offerId && !courseId) {
              dispatch(
                enqueueSnackbar('FAILURE', {
                  message: 'Please choose at least one main course',
                  variant: 'error',
                }),
              );
              return;
            }

            if (!data.prices || !data.prices.length) {
              dispatch(
                enqueueSnackbar('FAILURE', {
                  message: 'Please create at least one price',
                  variant: 'error',
                }),
              );
              return;
            }

            if (
              data.mentorshipUpgradePrice &&
              !data.mentorshipUpgradePrice.amount
            ) {
              delete data.mentorshipUpgradePrice;
            }

            const mapAffiliateValues = (config) => ({
              affiliateDiscount:
                data.affiliateDiscountType === 'percent'
                  ? toNumber(config.affiliateDiscount)
                  : unformat(config.affiliateDiscount),
              affiliateBonus:
                data.affiliateDiscountType === 'percent'
                  ? toNumber(config.affiliateBonus)
                  : unformat(config.affiliateBonus),
            });
            const mapSuperAffiliateConfig = (config) => ({
              ...mapAffiliateValues(config),
              triggerAfter: toNumber(config.triggerAfter),
              userIds: map(config.users, (u) => u.id),
            });
            const mapPrice = (price) => ({
              ...mapAffiliateValues(price),
              installmentCount: toNumber(price.installmentCount),
              amount: unformat(price.amount),
              superAffiliateConfig: map(
                price.superAffiliateConfig,
                mapSuperAffiliateConfig,
              ),
            });

            if (offerId) {
              // Here we have to check if super affiliate configs are also changed
              const existingPrices = orderBy(
                offer.prices,
                'installmentCount',
                'asc',
              ).map(mapPrice);

              const newPrices = orderBy(
                data.prices,
                'installmentCount',
                'asc',
              ).map(mapPrice);

              if (!data.hasFreeTrial) {
                data.freeTrialDuration = 0;
              }

              const freeTrialDurationChanged =
                toNumber(offer.freeTrialDuration) !==
                toNumber(data.freeTrialDuration);
              const upgradeAmountChanged =
                toString(offer.upgradeAmount) !== toString(data.upgradeAmount);
              const pricesChanged = !isEqual(existingPrices, newPrices);
              const discountTypeChanged =
                (offer.prices?.[0]?.affiliateDiscountType || 'amount') !==
                data.affiliateDiscountType;
              const taxBehaviorChanged = offer.taxBehavior !== data.taxBehavior;

              // These fields when provided require updates on stripe.
              // We provide this optimization to prevent that.
              if (
                !upgradeAmountChanged &&
                !pricesChanged &&
                !freeTrialDurationChanged &&
                !discountTypeChanged &&
                !taxBehaviorChanged
              ) {
                delete data.prices;
                delete data.upgradeAmount;
                delete data.freeTrialDuration;
                delete data.taxBehavior;
              }
            }

            if (data.prices) {
              data.prices = data.prices.map((p) => ({
                ...mapPrice(p),
                affiliateDiscountType: data.affiliateDiscountType,
              }));
            }
            delete data.affiliateDiscountType;

            const action = offerId
              ? updateOffer({ ...data, offerId }, { isPromise: true })
              : createOffer(
                  { ...data, courseId, bonusCourseIds },
                  { isPromise: true },
                );

            dispatch(action)
              .then(() => {
                dispatch(
                  enqueueSnackbar('SUCCESS', {
                    message: offerId ? 'Changes saved' : 'Offer created',
                    variant: 'success',
                  }),
                );
                if (!offerId) {
                  navigate(`/admin/offers`);
                }
              })
              .catch(noop);
          },
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [dispatch, navigate, offer, offerId, courseId, bonusCourseIds],
        );

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const methods = useForm({
          defaultValues:
            offerId && offer
              ? {
                  ...offer,
                  hasFreeTrial: Boolean(offer.freeTrialDuration),
                  prices: orderBy(offer.prices, 'installmentCount', 'asc'),
                  upgradeAmount: offer.upgradeAmount
                    ? toString(offer.upgradeAmount)
                    : null,
                  affiliateDiscountType:
                    offer.prices?.[0]?.affiliateDiscountType || 'amount',
                }
              : {
                  prices: [],
                  hasFreeTrial: false,
                  hasMentorship: false,
                  requireSignature: false,
                  affiliateDiscountType: 'amount',
                  taxBehavior: 'exclusive',
                },
          validationSchema: schema,
          reValidateMode: 'onChange',
        });

        const { control, errors, handleSubmit, watch, setValue, getValues } =
          methods;

        const {
          fields: prices,
          append,
          remove,
          // eslint-disable-next-line react-hooks/rules-of-hooks
        } = useFieldArray({
          control,
          name: 'prices',
          keyName: 'key',
        });

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const handleMainCourseClick = useMemoizedCallback(
          (id) => () => setCourseId(courseId === id ? null : id),
          [courseId],
        );

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const handleBonusCourseClick = useMemoizedCallback(
          (id) => () => {
            if (includes(bonusCourseIds, id)) {
              setBonusCourseIds(bonusCourseIds.filter((bid) => bid !== id));
            } else {
              setBonusCourseIds([...bonusCourseIds, id]);
            }
          },
          [bonusCourseIds],
        );

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const handleAddPrice = useCallback(() => {
          append({
            installmentCount: 1,
            amount: ensurePrice(0),
            affiliateBonus: 0,
            affiliateDiscount: 0,
          });

          // HACK: resets the field values. NOTE: I forgot what this does
          setTimeout(() => {
            setValue('prices', getValues({ nest: true }).prices);
          }, 1);
        }, [append, getValues, setValue]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const handleDeletePrice = useCallback(
          (index) => {
            const { prices, upgradeAmount } = getValues({ nest: true });
            const removed = prices[index];

            remove(index);

            if (
              unformat(removed.amount) * removed.installmentCount ===
              unformat(upgradeAmount)
            ) {
              setValue('upgradeAmount', '', true);
            }

            // HACK: resets the field values. NOTE: I forgot what this does
            setTimeout(() => {
              setValue('prices', getValues({ nest: true }).prices);
            }, 1);
          },
          [getValues, remove, setValue],
        );

        const requireSignature = watch('requireSignature');
        const hasFreeTrial = watch('hasFreeTrial');
        const hasMentorship = watch('hasMentorship');
        const livePrices = watch('prices');
        const affiliateDiscountType = watch('affiliateDiscountType');

        return (
          <UserDetailsLayout>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={[
                {
                  href: '/admin/offers',
                  label: 'Offers',
                },
                { label: offerId && offer ? offer.title : 'Create' },
              ]}
            />

            {!courses.length ? (
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Typography component={Box} paddingTop="24px" variant="h6">
                    First, create a course
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    component={Link}
                    to="/admin/courses/create"
                  >
                    Create Course
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <FormContext {...methods}>
                <form
                  className={styles.form}
                  noValidate
                  onSubmit={handleSubmit(handleFormSubmit)}
                >
                  <TitleAndAction
                    className={styles.titleAndAction}
                    title={
                      <Typography variant="h4">
                        {offerId ? 'Edit Offer' : 'Create New Offer'}
                      </Typography>
                    }
                  />

                  <Grid container spacing={2} direction="column">
                    <Grid item container spacing={2} direction="row">
                      <Grid item sm={6} xs={12}>
                        <Input
                          data-testid="title"
                          control={control}
                          className={styles.input}
                          disabled={isLoadingMutation}
                          label="Title"
                          name="title"
                          errorText={get(errors, 'title.message')}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Input
                          data-testid="slug"
                          control={control}
                          className={styles.input}
                          disabled={isLoadingMutation}
                          label="Slug"
                          name="slug"
                          errorText={get(errors, 'slug.message')}
                        />
                      </Grid>
                    </Grid>
                    {!offerId ? (
                      <>
                        <Grid item>
                          <Typography variant="h6">Main Course</Typography>
                        </Grid>

                        <Grid item container spacing={3}>
                          {courses.map(({ description, ...course }, index) => (
                            <Grid key={index} item xs={12} md={6} lg={3}>
                              <CourseCard
                                elevation={8}
                                ButtonProps={{
                                  'data-testid': 'courseLink',
                                  component: 'button',
                                  onClick: handleMainCourseClick(course.id),
                                }}
                                subtitle={
                                  { COURSE: 'Course', BLUEPRINT: 'Blueprint' }[
                                    course.type
                                  ]
                                }
                                noLogo
                                selectable
                                selected={course.id === courseId}
                                {...course}
                              />
                            </Grid>
                          ))}
                        </Grid>

                        <Grid item>
                          <Typography variant="h6">Bonus Courses</Typography>
                        </Grid>

                        <Grid item container spacing={3}>
                          {courses.map(({ description, ...course }, index) => (
                            <Grid key={index} item xs={12} md={6} lg={3}>
                              <CourseCard
                                elevation={8}
                                ButtonProps={{
                                  'data-testid': 'courseLink',
                                  component: 'button',
                                  onClick: handleBonusCourseClick(course.id),
                                  disabled: course.id === courseId,
                                }}
                                subtitle={
                                  { COURSE: 'Course', BLUEPRINT: 'Blueprint' }[
                                    course.type
                                  ]
                                }
                                noLogo
                                selectable
                                selected={
                                  course.id !== courseId &&
                                  includes(bonusCourseIds, course.id)
                                }
                                {...course}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item>
                          <Typography gutterBottom variant="h6">
                            Courses
                          </Typography>
                          <Typography variant="body2">
                            <strong>NOTE:</strong> If you want to offer
                            different course set, please create a new offer!
                          </Typography>
                        </Grid>

                        <Grid item container spacing={3}>
                          {courses
                            .filter(
                              (course) =>
                                course.id === courseId ||
                                includes(bonusCourseIds, course.id),
                            )
                            .map(({ description, ...course }, index) => (
                              <Grid key={index} item xs={12} md={6} lg={4}>
                                <CourseCard
                                  elevation={8}
                                  ButtonProps={{
                                    disabled: true,
                                  }}
                                  subtitle={
                                    (course.id === courseId
                                      ? 'Main '
                                      : 'Bonus ') +
                                    {
                                      COURSE: 'Course',
                                      BLUEPRINT: 'Blueprint',
                                    }[course.type]
                                  }
                                  noLogo
                                  {...course}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </>
                    )}

                    <Grid
                      className={styles.paymentOptions}
                      item
                      container
                      alignItems="center"
                      justify="space-between"
                      spacing={2}
                    >
                      <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Typography variant="h6">Payment Options</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Select
                          disableErrors
                          data-testid="taxBehavior"
                          className={styles.input}
                          control={control}
                          label="Tax Behavior"
                          name="taxBehavior"
                          disabled={isLoadingMutation}
                          options={[
                            { value: 'inclusive', label: 'Inclusive' },
                            { value: 'exclusive', label: 'Exclusive' },
                            { value: 'unspecified', label: 'Stripe Default' },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Select
                          disableErrors
                          data-testid="affiliateDiscountType"
                          className={styles.input}
                          control={control}
                          label="Discount Type"
                          name="affiliateDiscountType"
                          disabled={isLoadingMutation}
                          options={[
                            { value: 'amount', label: 'Fixed amount' },
                            { value: 'percent', label: 'Percent' },
                          ]}
                        />
                      </Grid>
                    </Grid>

                    <Grid direction="column" container item spacing={2}>
                      {prices.map((price, index) => (
                        <Grid item key={price.key}>
                          <PaymentOption
                            {...price}
                            className={styles.paymentOption}
                            index={index}
                            isLoading={isLoadingMutation}
                            onDelete={handleDeletePrice}
                            affiliateDiscountType={affiliateDiscountType}
                          />
                        </Grid>
                      ))}
                      <Grid item>
                        <WideButton
                          color="primary"
                          disabled={isLoadingMutation}
                          startIcon={<AddIcon />}
                          className={styles.newPrice}
                          onClick={handleAddPrice}
                        >
                          {isSmUp ? 'Add New Payment Option' : 'Payment Option'}
                        </WideButton>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Typography variant="h6">Free Trial</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} xl={7} container spacing={1}>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          className={clsx(
                            styles.switch,
                            !hasFreeTrial && styles.switchFalse,
                          )}
                          control={
                            <Switch
                              data-testid="hasFreeTrial"
                              control={control}
                              disabled={isLoadingMutation}
                              name="hasFreeTrial"
                              color="primary"
                            />
                          }
                          label="Enabled"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        className={clsx(!hasFreeTrial && styles.hidden)}
                      >
                        <Input
                          data-testid="freeTrialDuration"
                          control={control}
                          className={styles.input}
                          disabled={isLoadingMutation || !hasFreeTrial}
                          label="Days"
                          name="freeTrialDuration"
                          errorText={get(errors, 'freeTrialDuration.message')}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        className={clsx(
                          !hasFreeTrial || !livePrices.length
                            ? styles.hidden
                            : null,
                        )}
                      >
                        <Select
                          data-testid="upgradeAmount"
                          className={styles.input}
                          control={control}
                          label="Upgrade Price"
                          name="upgradeAmount"
                          disabled={isLoadingMutation}
                          options={uniqBy(
                            livePrices.filter((p) => unformat(p.amount)),
                            (p) => unformat(p.amount) * p.installmentCount,
                          ).map((p) => ({
                            value: toString(
                              unformat(p.amount) * p.installmentCount,
                            ),
                            label: ensurePrice(
                              unformat(p.amount) * p.installmentCount,
                              {
                                compact: false,
                              },
                            ),
                          }))}
                          errorText={get(errors, 'upgradeAmount.message')}
                        />
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Typography variant="h6">signNow Signature</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} xl={7} container spacing={1}>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          className={clsx(
                            styles.switch,
                            !requireSignature && styles.switchFalse,
                          )}
                          control={
                            <Switch
                              data-testid="requireSignature"
                              control={control}
                              disabled={isLoadingMutation}
                              name="requireSignature"
                              color="primary"
                            />
                          }
                          label="Required"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={8}
                        className={clsx(!requireSignature && styles.hidden)}
                      >
                        <Input
                          data-testid="signNowTemplateId"
                          control={control}
                          className={styles.input}
                          disabled={isLoadingMutation || !requireSignature}
                          label="signNow Template Id"
                          name="signNowTemplateId"
                          errorText={get(errors, 'signNowTemplateId.message')}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      className={clsx(
                        !supportsMentorship ? styles.hidden : null,
                      )}
                    >
                      <Typography variant="h6">Mentorhip</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={8}
                      xl={7}
                      container
                      spacing={1}
                      className={clsx(
                        !supportsMentorship ? styles.hidden : null,
                      )}
                    >
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          className={clsx(
                            styles.switch,
                            !hasMentorship && styles.switchFalse,
                          )}
                          control={
                            <Switch
                              data-testid="hasMentorship"
                              control={control}
                              disabled={isLoadingMutation}
                              name="hasMentorship"
                              color="primary"
                            />
                          }
                          label="Enabled"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        className={clsx(
                          (!hasMentorship || !upsellOffers.length) &&
                            styles.hidden,
                        )}
                      >
                        <Select
                          data-testid="upsellsOfferId"
                          control={control}
                          className={styles.input}
                          disabled={isLoadingMutation}
                          label="Select Offer To Upsell"
                          name="upsellsOfferId"
                          options={upsellOffers.map((o) => ({
                            value: o.id,
                            label: o.title,
                          }))}
                          errorText={get(errors, 'upsellsOfferId.message')}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        className={clsx(hasMentorship ? styles.hidden : null)}
                      >
                        <Input
                          data-testid="mentorshipUpgradePrice.amount"
                          control={control}
                          disabled={isLoadingMutation}
                          className={styles.input}
                          label="Mentorship Purchase Price"
                          format="price"
                          name="mentorshipUpgradePrice.amount"
                          errorText={get(
                            errors,
                            'mentorshipUpgradePrice.amount.message',
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={1} justify="space-between">
                      <Grid item xs={12} sm={6} md={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<SaveIcon />}
                          type="submit"
                          disabled={isLoadingMutation}
                        >
                          Save Offer
                        </Button>
                      </Grid>

                      {offerId ? (
                        <Grid item>
                          <Button
                            variant="outlined"
                            startIcon={<ArchiveOutlinedIcon />}
                            type="button"
                            className={styles.deleteButton}
                            onClick={showArchiveOfferModal}
                          >
                            Archive Offer
                          </Button>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </form>
              </FormContext>
            )}
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminCreateOrUpdateOfferPage;
