import { useNavigate } from 'react-router';
import { useCallback } from 'react';
import { startsWith } from 'lodash';

const useNavigateExternal = () => {
  const navigate = useNavigate();

  const navigateExternal = useCallback(
    (to, options) => {
      if (startsWith(to, 'http://') || startsWith(to, 'https://')) {
        window.location.replace(to);
        return;
      }

      navigate(to, options);
    },
    [navigate],
  );

  return navigateExternal;
};

export default useNavigateExternal;
