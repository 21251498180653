import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { get, find, omit, noop } from 'lodash';

import {
  selectCoursesWithGraph,
  selectCoursesRequest,
  selectModulesRequest,
  selectLessonsRequest,
  selectCreateLessonRequest,
  selectUpdateLessonRequest,
  selectLessonVideo,
  selectLessonVideoRequest,
} from 'Redux/selectors';
import {
  createLesson,
  fetchCourses,
  fetchLessons,
  fetchLessonVideo,
  fetchModules,
  updateLesson,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import Switch from 'Components/shared/switch';
import RichTextEditor from 'Components/shared/rich-text-editor';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import { Save as SaveIcon } from '@material-ui/icons';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';

const schemaFields = {
  title: yup.string().required('Field Required.'),
  slug: yup
    .string()
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g, 'Only Letters, Numbers and Dashes.')
    .required('Field Required.'),
  notes: yup.string().nullable().optional(),
  isVisible: yup
    .boolean()
    .transform((v) => Boolean(v))
    .required('Field Required.'),
};
const updateLessonSchema = yup.object().shape(schemaFields);
const createLessonSchema = yup
  .object()
  .shape(omit(schemaFields, ['isVisible']));

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  input: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2.5),
  },
}));

const AdminCreateOrUpdateLessonPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId, moduleId, lessonId } = useParams();

  const courses = useSelector(selectCoursesWithGraph);
  const course = find(courses, ['id', courseId]);
  const module = course ? find(course.modules, ['id', moduleId]) : null;
  const lesson = module ? find(module.lessons, ['id', lessonId]) : null;
  const video = useSelector(selectLessonVideo);

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectModulesRequest,
    selectLessonsRequest,
  );
  const { isLoading: isLoadingRelated, error: errorRelated } =
    useCombinedRequestsSelector(selectLessonVideoRequest);

  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectUpdateLessonRequest,
    selectCreateLessonRequest,
  );

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    if (!module) {
      dispatch(fetchModules({ courseId, skipAccessCheck: true }));
    }
    if (!lesson) {
      dispatch(fetchLessons({ courseId, skipAccessCheck: true }));
    }
    if (lessonId) {
      dispatch(
        fetchLessonVideo({
          courseId,
          moduleId,
          lessonId,
          skipAccessCheck: true,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId, moduleId, lessonId]);

  const handleFormSubmit = useCallback(
    async (data) => {
      const action = lessonId
        ? updateLesson(
            { courseId, moduleId, lessonId, ...data },
            { isPromise: true },
          )
        : createLesson({ courseId, moduleId, ...data }, { isPromise: true });

      dispatch(action)
        .then((payload) =>
          navigate(
            `/admin/courses/${courseId}/modules/${moduleId}/lessons/${payload.data.id}/overview`,
          ),
        )
        .catch(noop);
    },
    [lessonId, courseId, moduleId, dispatch, navigate],
  );

  if (!isLoading && !lesson && lessonId) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader
      loading={isLoading}
      error={
        error ||
        (errorRelated && errorRelated.code !== 'ERROR_CODE_NOT_FOUND'
          ? errorRelated
          : null)
      }
    >
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { control, errors, handleSubmit, formState } = useForm({
          defaultValues:
            lessonId && lesson
              ? { ...lesson, notes: lesson.notes ? lesson.notes.html : null }
              : {},
          validationSchema:
            lessonId && lesson ? updateLessonSchema : createLessonSchema,
          reValidateMode: 'onChange',
        });

        const edited = formState.dirty;

        return (
          <UserDetailsLayout>
            <Breadcrumbs
              className={styles.breadcrumbs}
              links={
                lessonId && lesson
                  ? [
                      { href: '/admin/courses', label: 'Courses' },
                      {
                        href: `/admin/courses/${courseId}/overview`,
                        label: course.title,
                      },
                      {
                        href: `/admin/courses/${courseId}/modules/${moduleId}/overview`,
                        label: module.title,
                      },
                      {
                        href: `/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/overview`,
                        label: lesson.title,
                      },
                      { label: 'Edit' },
                    ]
                  : [
                      { href: '/admin/courses', label: 'Courses' },
                      {
                        href: `/admin/courses/${courseId}/overview`,
                        label: course.title,
                      },
                      {
                        href: `/admin/courses/${courseId}/modules/${moduleId}/overview`,
                        label: module.title,
                      },
                      { label: 'Create' },
                    ]
              }
            />

            <form
              className={styles.form}
              noValidate
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <TitleAndAction
                className={styles.titleAndAction}
                title={
                  <Typography variant="h4">
                    {lessonId ? 'Edit Lesson' : 'Create New Lesson'}
                  </Typography>
                }
              />

              <Grid container spacing={2} direction="column">
                <Grid item container spacing={2} direction="row">
                  <Grid item xs={12} sm={6} md={5}>
                    <Input
                      data-testid="title"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Title"
                      name="title"
                      errorText={get(errors, 'title.message')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Input
                      data-testid="slug"
                      control={control}
                      className={styles.input}
                      disabled={isLoadingMutation}
                      label="Slug"
                      name="slug"
                      errorText={get(errors, 'slug.message')}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <RichTextEditor
                    data-testid="notes"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Notes"
                    name="notes"
                    errorText={get(errors, 'notes.message')}
                  />
                </Grid>

                {lessonId ? (
                  <>
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              data-testid="isVisible"
                              control={control}
                              disabled={isLoadingMutation || !video}
                              name="isVisible"
                              color="primary"
                            />
                          }
                          label="Visible for users"
                        />
                      </Grid>
                      {!video && isLoadingRelated ? (
                        <Grid item>
                          <CircularProgress color="inherit" size={16} />
                        </Grid>
                      ) : null}
                    </Grid>
                    {!video && !isLoadingRelated ? (
                      <Grid item>
                        <Typography variant="body2" color="error">
                          To make the lesson visible for users, please upload
                          the video first.
                        </Typography>
                      </Grid>
                    ) : null}
                  </>
                ) : null}

                <Grid item xs={10} sm={6} md={4}>
                  <Button
                    className={styles.button}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={!edited}
                  >
                    Save Lesson
                  </Button>
                </Grid>
              </Grid>
            </form>
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminCreateOrUpdateLessonPage;
