import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useState, useCallback, useMemo } from 'react';
import { map, find } from 'lodash';

import stripe from 'Util/stripe-instance';
import {
  selectCourses,
  selectCoursesRequest,
  selectMeRequest,
  selectPaymentMethods,
  selectPaymentMethodsRequest,
  selectSetDefaultPaymentMethodRequest,
  selectTransactions,
  selectTransactionsRequest,
} from 'Redux/selectors';
import {
  fetchCourses,
  fetchPaymentMethods,
  fetchTransactions,
} from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import ConfirmDeletePaymentMethodDialog from 'Dialogs/confirm-delete-payment-method-dialog';
import AddPaymentMethodDialog from 'Dialogs/add-payment-method-dialog';
import PaymentMethodCard from 'Components/shared/payment-method-card';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import OptionList from 'Components/shared/option-list';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import DrawerAccountItems from 'Components/shared/drawer-account-items';
import Transaction from 'Components/pages/account/transaction';

import { Elements } from '@stripe/react-stripe-js';
import { Add as AddIcon } from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  Button,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  drawer: {
    position: 'fixed',
    marginTop: 1, // divider offset
    width: 280,
  },
  title: {
    marginBottom: theme.spacing(7),
  },
  paymentMethods: {
    marginBottom: theme.spacing(5),
    width: '100%',
  },
  group: {
    marginBottom: theme.spacing(5),
  },
  groupEmpty: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(3.5),
  },
  addPaymentMethodButtonContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    minHeight: 92,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const PaymentPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [deletePaymentMethodDialog, setDeletePaymentMethodDialog] =
    useState(null);
  const { isLoading, error } = useCombinedRequestsSelector(
    selectMeRequest,
    selectCoursesRequest,
    selectTransactionsRequest,
    selectPaymentMethodsRequest,
  );
  const { isLoading: isLoadingSetDefault } = useSelector(
    selectSetDefaultPaymentMethodRequest,
  );
  const courses = useSelector(selectCourses);
  const transactions = useSelector(selectTransactions);
  const paymentMethods = useSelector(selectPaymentMethods);
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const defaultPaymentMethods = paymentMethods
    ? paymentMethods.filter((p) => p.isDefault)
    : [];

  const transactionsWithCourses = useMemo(
    () =>
      transactions &&
      map(transactions, (t) => ({
        ...t,
        course: find(courses, ['id', t.courseId]),
      })),
    [courses, transactions],
  );

  useScopedDispatchEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchTransactions());
    dispatch(fetchPaymentMethods());
  }, [dispatch]);

  const [showAddPaymentMethodDialog, hideAddPaymentMethodDialog] = useModal(
    () => (
      <Elements stripe={stripe.instance()}>
        <AddPaymentMethodDialog onClose={hideAddPaymentMethodDialog} />
      </Elements>
    ),
  );

  const showDeletePaymentMethodDialog = useCallback(
    (paymentMethod) =>
      setDeletePaymentMethodDialog({ paymentMethodId: paymentMethod.id }),
    [],
  );
  const hideDeletePaymentMethodDialog = useCallback(
    () => setDeletePaymentMethodDialog(null),
    [],
  );

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout
          headerItems={<HeaderItems />}
          drawerItems={[<DrawerAccountItems />, <DrawerItems />]}
        >
          <div className={styles.root}>
            {isLgUp && <DrawerAccountItems className={styles.drawer} />}
            {deletePaymentMethodDialog && (
              <ConfirmDeletePaymentMethodDialog
                onClose={hideDeletePaymentMethodDialog}
                {...deletePaymentMethodDialog}
              />
            )}
            <PageContainer sidebarWidth={280}>
              <Typography variant="h4" className={styles.title}>
                Payment Info
              </Typography>

              <div className={styles.paymentMethods}>
                {defaultPaymentMethods.map((paymentMethod) => (
                  <PaymentMethodCard
                    key={paymentMethod.id}
                    paymentMethod={paymentMethod}
                    onDelete={showDeletePaymentMethodDialog}
                    onReplace={showAddPaymentMethodDialog}
                    disabled={isLoadingSetDefault}
                  />
                ))}
                {!defaultPaymentMethods.length ? (
                  <div className={styles.addPaymentMethodButtonContainer}>
                    <Button
                      className={styles.button}
                      startIcon={<AddIcon />}
                      color="primary"
                      onClick={showAddPaymentMethodDialog}
                    >
                      Add Credit Card
                    </Button>
                  </div>
                ) : null}
              </div>

              <OptionList className={styles.group} title="Recent Transactions">
                {transactionsWithCourses.length === 0 && (
                  <Typography
                    className={styles.groupEmpty}
                    color="textSecondary"
                  >
                    No transactions available.
                  </Typography>
                )}
                {transactionsWithCourses.map((transaction, index) => (
                  <Transaction
                    key={index}
                    transaction={transaction}
                    divider={transactionsWithCourses.length - 1 !== index}
                  />
                ))}
              </OptionList>
            </PageContainer>
          </div>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default PaymentPage;
