import qs from 'qs';

import instance from 'Api/instance';

export const getQuestions = ({ limit, offset, omitIds }) =>
  instance.get(
    `/questions?` +
      qs.stringify(
        {
          limit,
          offset,
          omitIds,
        },
        { arrayFormat: 'repeat' },
      ),
  );

export const createQuestion = (attributes) =>
  instance.post(`/questions`, { data: { attributes } });

export const updateQuestion = (questionId, attributes) =>
  instance.patch(`/questions/` + questionId, { data: { attributes } });

export const sortLessonQuestions = (lessonId, attributes) =>
  instance.post('/lessons/' + lessonId + '/questions/sort', {
    data: { attributes },
  });
