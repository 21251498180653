import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const ToolIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props}>
      <path
        d="M45.935 20.11l-3.36-.43a1.417 1.417 0 01-1.104-.96c-.384-1.152-.816-2.305-1.391-3.36a1.302 1.302 0 01.095-1.49l2.065-2.64c.288-.384.288-.96-.096-1.295l-4.08-4.129c-.384-.336-.911-.384-1.296-.096l-2.64 2.065a1.4 1.4 0 01-1.488.144 17.114 17.114 0 00-3.36-1.391c-.53-.191-.912-.576-.96-1.104l-.432-3.36c-.095-.48-.48-.865-.96-.865h-5.857a.983.983 0 00-.96.865l-.431 3.36c-.096.528-.431.96-.96 1.104-1.2.336-2.305.816-3.409 1.391-.48.289-1.056.24-1.44-.095l-2.689-2.065a.963.963 0 00-1.295.096l-4.08 4.129c-.385.335-.385.91-.096 1.295l2.064 2.64c.385.385.385.96.145 1.44-.576 1.056-1.01 2.209-1.392 3.36-.144.529-.575.912-1.104.96l-3.36.431c-.48.096-.864.48-.864.96v5.81c0 .528.384.91.864.96l3.36.43c.529.096.96.48 1.104.96.385 1.2.816 2.305 1.392 3.41.288.48.24 1.055-.096 1.44L5.76 36.72c-.289.384-.289.96.095 1.295l4.08 4.129c.385.336.912.384 1.296.096l2.689-2.065c.431-.335.96-.335 1.44-.095a16.376 16.376 0 003.409 1.39c.528.145.864.576.96 1.105l.43 3.36c.05.48.48.864.96.864h5.81c.48 0 .91-.384.96-.864l.43-3.36c.097-.529.432-.96.96-1.104.24-.096.432-.145.625-.24l-5.089-5.327c-.288.048-.528.048-.815.048a11.922 11.922 0 01-11.951-11.95c0-6.576 5.329-11.905 11.95-11.905 6.576 0 11.905 5.329 11.905 11.904 0 .29 0 .576-.049.816l5.425 5.038c.096-.191.144-.384.24-.624.144-.53.576-.912 1.104-.96l3.36-.432a.983.983 0 00.865-.96V21.07c-.049-.48-.433-.862-.913-.96z"
        fill="currentColor"
      />
      <path
        d="M31.249 26.494c.72-2.16.48-4.608-.769-6.575-1.824-2.784-4.895-3.936-7.775-3.456-.72.096-.96 1.01-.48 1.536l1.824 1.824c1.2 1.2 1.2 3.072 0 4.272-1.2 1.2-3.12 1.2-4.271 0l-1.825-1.825c-.48-.48-1.39-.24-1.535.48-.385 2.351.288 4.896 2.11 6.72 2.112 2.111 5.185 2.736 7.872 1.825l9.696 10.32c1.488 1.584 3.984 1.63 5.47.095 1.536-1.535 1.49-4.031-.095-5.47l-10.222-9.746z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ToolIcon;
