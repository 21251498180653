import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import clsx from 'clsx';

import { selectUpdateMeRequest } from 'Redux/selectors';
import { updateMe } from 'Redux/actions';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button } from '@material-ui/core';

const schema = yup.object().shape({
  oldPassword: yup.string().required('Field Required.'),
  password: yup
    .string()
    .min(6, 'Too Short.')
    .notOneOf([yup.ref('oldPassword')], 'Please create new password.')
    .required('Field Required.'),
  confirmPassword: yup
    .string()
    .required('Field Required.')
    .oneOf([yup.ref('password'), null], 'Password Mismatch.'),
});

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  first: {
    marginTop: theme.spacing(3),
  },
}));

const UpdateMePasswordDialog = (props) => {
  const { onClose } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectUpdateMeRequest);

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
  });

  const handleUpdate = useCallback(
    (data) => {
      dispatch(updateMe(data, { isPromise: true })).then(onClose);
    },
    [dispatch, onClose],
  );

  return (
    <Dialog
      title="Change Password"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleUpdate)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">Change</Button>}
    >
      <Input
        data-testid="oldPassword"
        className={clsx(styles.input, styles.first)}
        control={control}
        autoFocus={true}
        type="password"
        name="oldPassword"
        label="Old Password"
        errorText={get(errors, 'oldPassword.message')}
      />
      <Input
        data-testid="password"
        className={styles.input}
        control={control}
        type="password"
        name="password"
        label="New Password"
        errorText={get(errors, 'password.message')}
      />
      <Input
        data-testid="confirmPassword"
        className={styles.input}
        control={control}
        type="password"
        name="confirmPassword"
        label="Confirm New Password"
        errorText={get(errors, 'confirmPassword.message')}
      />
    </Dialog>
  );
};

UpdateMePasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UpdateMePasswordDialog;
