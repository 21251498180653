import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'delete-payment-link';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const DELETE_PAYMENT_LINK_REQUEST = Exec.requestConstantCreator(MODULE);
export const DELETE_PAYMENT_LINK_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const deletePaymentLink = Exec.requestActionCreator(MODULE);
export const deletePaymentLinkLoading = Exec.loadingActionCreator(MODULE);
export const deletePaymentLinkSuccess = Exec.successActionCreator(MODULE);
export const deletePaymentLinkError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectDeletePaymentLinkRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doDeletePaymentLink = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(DELETE_PAYMENT_LINK_REQUEST));
    yield put(deletePaymentLinkLoading());
    const { data } = yield call(Api.deletePaymentLink, payload.paymentLinkId);
    yield put(deletePaymentLinkSuccess({ data }, meta));
  } catch (err) {
    yield put(deletePaymentLinkError(err, meta));
    yield put(
      enqueueSnackbar(DELETE_PAYMENT_LINK_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail ||
              err.message ||
              'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doDeletePaymentLink);
