import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'update-module';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const UPDATE_MODULE_REQUEST = Exec.requestConstantCreator(MODULE);
export const UPDATE_MODULE_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const updateModule = Exec.requestActionCreator(MODULE);
export const updateModuleLoading = Exec.loadingActionCreator(MODULE);
export const updateModuleSuccess = Exec.successActionCreator(MODULE);
export const updateModuleError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectUpdateModuleRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doUpdateModule = function* ({ payload, meta }) {
  const { courseId, moduleId, ...attributes } = payload;

  try {
    yield put(closeSnackbar(UPDATE_MODULE_REQUEST));
    yield put(updateModuleLoading());
    const { data } = yield call(
      Api.updateModule,
      courseId,
      moduleId,
      attributes,
    );
    yield put(updateModuleSuccess({ data }, meta));
  } catch (err) {
    yield put(updateModuleError(err, meta));
    yield put(
      enqueueSnackbar(UPDATE_MODULE_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doUpdateModule);
