import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: 'inline-block',
    padding: theme.spacing(1),
    borderRadius: 4,
    color:
      props.variant === 'default'
        ? theme.palette.text.primary
        : theme.palette.secondary.contrastText,
    backgroundColor: {
      default: theme.palette.background.default,
      invalid: theme.palette.grey[500],
      success: theme.palette.success.main,
      warning: theme.palette.warning.main,
      error: theme.palette.error.main,
    }[props.variant],
  }),
}));

const StatusChip = (props) => {
  const { className, variant, ...rest } = props;

  const styles = useStyles(props);

  return (
    <div className={clsx(className, styles.root)}>
      <Typography className={styles.value} variant={'body2'} {...rest} />
    </div>
  );
};

StatusChip.propTypes = {
  variant: PropTypes.oneOf([
    'default',
    'invalid',
    'success',
    'warning',
    'error',
  ]).isRequired,
};

StatusChip.defaultProps = {
  variant: 'default',
};

export default StatusChip;
