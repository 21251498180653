import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'create-achievement';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CREATE_ACHIEVEMENT_REQUEST = Exec.requestConstantCreator(MODULE);
export const CREATE_ACHIEVEMENT_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const createAchievement = Exec.requestActionCreator(MODULE);
export const createAchievementLoading = Exec.loadingActionCreator(MODULE);
export const createAchievementSuccess = Exec.successActionCreator(MODULE);
export const createAchievementError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCreateAchievementRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doCreateAchievement = function* ({ payload, meta }) {
  const { courseId, moduleId, ...fields } = payload;
  try {
    yield put(closeSnackbar(CREATE_ACHIEVEMENT_REQUEST));
    yield put(createAchievementLoading());
    const { data } = yield call(
      Api.createAchievement,
      courseId,
      moduleId,
      fields,
    );
    yield put(createAchievementSuccess({ data }, meta));
  } catch (err) {
    yield put(createAchievementError(err, meta));
    yield put(
      enqueueSnackbar(CREATE_ACHIEVEMENT_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCreateAchievement);
