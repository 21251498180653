import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';

import toStringBytes from 'Util/to-string-bytes';
import {
  selectUploadFileRequest,
  selectUploadAchievementLevelPictureRequest,
} from 'Redux/selectors';
import {
  uploadFileCancel,
  uploadAchievementLevelPicture,
  uploadAchievementLevelPictureReset,
} from 'Redux/actions';
import UploadDropzone from 'Components/shared/upload-dropzone';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    marginTop: theme.spacing(3),
  },
}));

const UploadAchievementLevelPictureDialog = (props) => {
  const { achievementLevelId, onClose } = props;
  const [fileRejections, setFileRejections] = useState([]);
  const [helperText, setHelperText] = useState(null);

  const styles = useStyles();
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(
    selectUploadAchievementLevelPictureRequest,
  );
  const { progress } = useSelector(selectUploadFileRequest);
  useEffect(
    () => () => dispatch(uploadAchievementLevelPictureReset()),
    [dispatch],
  );

  const handleDrop = useCallback(
    (accepted, rejections) => {
      setHelperText(null);

      if (rejections.length) {
        setFileRejections(rejections);
      } else {
        dispatch(
          uploadAchievementLevelPicture(
            { achievementLevelId, file: accepted[0] },
            { isPromise: true },
          ),
        )
          .then(onClose)
          .catch(noop);
      }
    },
    [dispatch, onClose, achievementLevelId],
  );

  const handleReset = useCallback(() => {
    setFileRejections([]);
    dispatch(uploadAchievementLevelPictureReset());
  }, [dispatch]);

  const handleSetHelperText = useCallback(
    () => setHelperText('You have to pick an image.'),
    [],
  );

  const handleCancel = useCallback(() => {
    dispatch(uploadFileCancel());
    onClose();
  }, [dispatch, onClose]);

  const fileTooLarge =
    get(fileRejections, '0.errors.0.code') === 'file-too-large';
  const hasError = Boolean(error) || fileRejections.length;

  return (
    <Dialog
      interruptible
      title="Upload Achievement Level Picture"
      loading={isLoading}
      onClose={onClose}
      cancelButton={
        <Button data-testid="cancel" onClick={handleCancel}>
          Cancel
        </Button>
      }
      submitButton={
        <Button
          data-testid="submit"
          onClick={hasError ? handleReset : handleSetHelperText}
        >
          {hasError ? 'Retry' : 'Change'}
        </Button>
      }
    >
      <UploadDropzone
        className={styles.dropzone}
        loading={isLoading}
        progress={progress}
        DropzoneProps={{
          onDrop: handleDrop,
          accept: ['image/jpeg', 'image/png'],
          multiple: false,
          maxSize: 10485760,
        }}
        errorText={
          hasError
            ? fileTooLarge
              ? `File is too large! Maximum allowed file size is ${toStringBytes(
                  10485760,
                )}.`
              : error && error.code === 'ERROR_CODE_NETWORK_ERROR'
              ? 'Please check your internet connection.'
              : error.message
            : null
        }
        helperText={helperText}
      />
    </Dialog>
  );
};

UploadAchievementLevelPictureDialog.propTypes = {
  achievementLevelId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default UploadAchievementLevelPictureDialog;
