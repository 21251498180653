import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { find, findIndex } from 'lodash';

import formatError from 'Util/format-error';
import {
  selectCoursesRequest,
  selectCoursesWithGraph,
  selectLessonsRequest,
  selectLessonVideo,
  selectLessonVideoRequest,
  selectMeRequest,
  selectModulesRequest,
} from 'Redux/selectors';
import {
  fetchCourses,
  fetchModules,
  fetchLessons,
  fetchLessonVideo,
  recordLessonProgress,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import VideoContainer from 'Components/shared/video-container';
import StickyTabs from 'Components/shared/sticky-tabs';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import ModuleSwitcher from 'Components/pages/learn/module-swticher';
import LessonResources from 'Components/pages/learn/lesson-resources';

import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  Tab,
  Hidden,
  Paper,
  Button,
  Divider,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
  },
  grid: {
    width: '100%',
    display: 'grid',
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: '375px 1fr',
    },
  },
  moduleSwitcher: (props) => ({
    width: '100%',
    gridColumn: 1,
    gridRow: 4,
    display: props.currentTab !== 0 ? 'none' : 'block',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(5, 3),
    },
    [theme.breakpoints.up('xl')]: {
      backgroundColor: 'transparent',
      padding: 0,
      boxShadow:
        '0px -1px 1px -1px rgba(0,0,0,0.2), 0px -1px 1px 0px rgba(0,0,0,0.14), 0px -1px 3px 0px rgba(0,0,0,0.12)',
      width: 375,
      position: 'sticky',
      top: 80,
      bottom: 100,
      display: 'block',
      height: 'calc(100vh - 80px)',
      overflowY: 'overlay',
      fallbacks: {
        overflowY: 'scroll',
      },
      gridRowStart: 1,
      gridRowEnd: 5,
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: '0px',
      },
      '&:hover': {
        '&::-webkit-scrollbar': {
          width: '6px',
          backgroundColor: 'rgba(0, 0, 0, .05)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, .2)',
        },
      },
    },
  }),
  videoContainer: {
    gridColumn: 1,
    gridRow: 1,
    [theme.breakpoints.up('xl')]: {
      gridColumn: 2,
    },
  },
  tabs: {
    position: 'sticky',
    borderRadius: 0,
    margin: 0,
    zIndex: 1099,
    width: '100%',
    top: 79,
    gridColumn: 1,
    gridRow: 3,
    height: 80,
  },
  tab: {
    height: 80,
    flexGrow: 0,
    textTransform: 'capitalize',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '30px',
    '&:first-child': {
      marginLeft: 'auto',
    },
    '&:last-child': {
      marginRight: 'auto',
    },
  },
  lessonSwitcher: {
    height: 48,
    display: 'flex',
    gridColumn: 1,
    gridRow: 2,
    overflow: 'hidden',
    margin: theme.spacing(0, 3, 5),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(0, 5, 5),
      gridColumn: 2,
    },
  },
  button: {
    borderRadius: 0,
    flex: 1,
    paddingLeft: 12,
    paddingRight: 12,
  },
  buttonLabel: {
    flex: 1,
    fontWeight: 500,
  },
  lessonResources: (props) => ({
    gridColumn: 1,
    gridRow: 4,
    display: props.currentTab !== 1 ? 'none' : 'block',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(5, 3),
    },
    [theme.breakpoints.up('xl')]: {
      backgroundColor: 'transparent',
      padding: theme.spacing(0, 5, 5),
      display: 'block',
      gridRow: 3,
      gridColumn: 2,
    },
  }),
}));

const WatchLessonPage = () => {
  const { courseSlug, moduleSlug, lessonSlug } = useParams();

  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const styles = useStyles({ currentTab });
  const dispatch = useDispatch();

  const isXsUp = useMediaQuery((theme) => theme.breakpoints.up('xs'));
  const isCustomUp = useMediaQuery('@media only screen and (min-width: 460px)');

  const { isLoading, error } = useCombinedRequestsSelector(
    selectMeRequest,
    selectCoursesRequest,
    selectModulesRequest,
    selectLessonsRequest,
  );

  const { isLoading: isLoadingVideo, error: videoError } = useSelector(
    selectLessonVideoRequest,
  );

  const courses = useSelector(selectCoursesWithGraph);
  const lessonVideo = useSelector(selectLessonVideo);

  const course = find(courses, ['slug', courseSlug]);

  const moduleIdx = course
    ? findIndex(course.modules, ['slug', moduleSlug])
    : -1;
  const module = moduleIdx !== -1 ? course.modules[moduleIdx] : null;

  const lessonIdx = module
    ? findIndex(module.lessons, ['slug', lessonSlug])
    : -1;
  const lesson = lessonIdx !== -1 ? module.lessons[lessonIdx] : null;

  const nextModule =
    course && module && module.lessons && lessonIdx >= module.lessons.length - 1
      ? course.modules[moduleIdx + 1]
      : module;

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    if (!module) {
      dispatch(fetchModules({ courseId: courseSlug }));
    }
    if (!lesson) {
      dispatch(fetchLessons({ courseId: courseSlug }));
    }
    dispatch(
      fetchLessonVideo(
        {
          courseId: courseSlug,
          moduleId: moduleSlug,
          lessonId: lessonSlug,
        },
        { isPromise: true },
      ),
    ).catch((error) => {
      if (error?.error?.code === 'ERROR_CODE_FORBIDDEN') {
        navigate('/?lostAccessTo=' + courseSlug);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug, moduleSlug, lessonSlug, navigate]);

  const handleChange = useCallback((event, tab) => setCurrentTab(tab), []);

  const handleProgress = useCallback(
    (state) =>
      dispatch(
        recordLessonProgress({
          courseId: courseSlug,
          moduleId: moduleSlug,
          lessonId: lessonSlug,
          timestamp: state.playedSeconds,
        }),
      ),
    [dispatch, courseSlug, lessonSlug, moduleSlug],
  );

  const handlePrevLesson = useCallback(() => {
    const isStart = lessonIdx === 0;

    const module_ = isStart ? course.modules[moduleIdx - 1] : module;
    const lesson_ = isStart
      ? module_.lessons[module_.lessons.length - 1]
      : module_.lessons[lessonIdx - 1];

    navigate(`/learn/${course.slug}/watch/${module_.slug}/${lesson_.slug}`);
  }, [course, lessonIdx, module, moduleIdx, navigate]);

  const handleNextLesson = useCallback(() => {
    const isEnd = lessonIdx >= module.lessons.length - 1;

    const module_ = isEnd ? course.modules[moduleIdx + 1] : module;
    const lesson_ = isEnd ? module_.lessons[0] : module_.lessons[lessonIdx + 1];

    if (!module_.isLocked) {
      navigate(`/learn/${course.slug}/watch/${module_.slug}/${lesson_.slug}`);
    }
  }, [course, lessonIdx, module, moduleIdx, navigate]);

  const wistiaVideoProps = useMemo(() => {
    const restartAtTime =
      lesson &&
      lesson.progress &&
      lesson.progress.lastTimestamp / lesson.duration < 0.8
        ? lesson.progress.lastTimestamp
        : 0;

    return {
      video: lessonVideo,
      loading: isLoadingVideo,
      config: {
        silentAutoPlay: 'allow',
        time: restartAtTime,
      },
      errorText: formatError(videoError),
      onEnded: handleNextLesson,
      onProgress: handleProgress,
    };
  }, [
    lessonVideo,
    isLoadingVideo,
    lesson,
    videoError,
    handleNextLesson,
    handleProgress,
  ]);

  if (!isLoading && (!course || !module || !lesson)) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout
          headerItems={<HeaderItems />}
          drawerItems={<DrawerItems />}
          className={styles.root}
        >
          <div className={styles.grid}>
            <div className={styles.moduleSwitcher}>
              <ModuleSwitcher />
            </div>

            <VideoContainer
              className={styles.videoContainer}
              classes={styles}
              loading={isLoading}
              WistiaVideoProps={wistiaVideoProps}
              title={isLoading ? null : lesson.title}
              subtitle={
                isLoading ? null : `${module.title} - ${module.summary}`
              }
            />

            <Paper className={styles.lessonSwitcher}>
              <Button
                className={styles.button}
                onClick={handlePrevLesson}
                disabled={moduleIdx === 0 && lessonIdx === 0}
              >
                {isXsUp ? <ArrowBackIcon /> : null}
                <Typography className={styles.buttonLabel} align="center">
                  {isCustomUp ? 'Previous Lesson' : 'Previous'}
                </Typography>
              </Button>
              <Divider orientation="vertical" />
              <Button
                className={styles.button}
                onClick={handleNextLesson}
                disabled={
                  nextModule
                    ? nextModule.isLocked
                    : course &&
                      module &&
                      moduleIdx >= course.modules.length - 1 &&
                      lessonIdx >= module.lessons.length - 1
                }
              >
                <Typography className={styles.buttonLabel} align="center">
                  {isCustomUp ? 'Next Lesson' : 'Next'}
                </Typography>
                {isXsUp ? <ArrowForwardIcon /> : null}
              </Button>
            </Paper>

            <Hidden xlUp>
              <StickyTabs
                className={styles.tabs}
                value={currentTab}
                onChange={handleChange}
              >
                <Tab
                  data-testid="contentTab"
                  className={styles.tab}
                  label="Content"
                />
                <Tab
                  data-testid="resourcesTab"
                  className={styles.tab}
                  label="Resources"
                />
              </StickyTabs>
            </Hidden>
            <div className={styles.lessonResources}>
              <LessonResources />
            </div>
          </div>
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default WatchLessonPage;
