import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    margin: 0,
    display: 'inline-block',
    padding:
      props.size === 'small' ? theme.spacing(0.5, 1) : theme.spacing(2.5, 2),
    borderRadius: theme.shape.borderRadius,
    borderWidth: props.variant === 'outlined' ? 1 : 0,
    borderStyle: 'solid',
    color:
      props.color === 'default'
        ? theme.palette.text.primary
        : theme.palette.secondary.contrastText,
    borderColor: {
      default: theme.palette.grey[300],
      invalid: theme.palette.grey[500],
      success: theme.palette.success.main,
      warning: theme.palette.warning.main,
      error: theme.palette.error.main,
    }[props.color],
    backgroundColor: {
      default: theme.palette.background.default,
      invalid: theme.palette.grey[500],
      success: theme.palette.success.main,
      warning: theme.palette.warning.main,
      error: theme.palette.error.main,
    }[props.color],
  }),
  label: (props) => ({
    color:
      props.color === 'default'
        ? theme.palette.text.primary
        : theme.palette.secondary.contrastText,
    whiteSpace: 'nowrap',
    fontWeight: 400,
  }),
  value: {
    lineHeight: '23px',
  },
  detail: {
    fontWeight: 300,
    lineHeight: '23px',
  },
}));

const Highlight = (props) => {
  const {
    className,
    size,
    label,
    value,
    detail,
    align,
    variant,
    color,
    ...rest
  } = props;
  const styles = useStyles(props);

  return label ? (
    <div className={className} {...rest}>
      <Grid container spacing={1} direction="column" className={styles.root}>
        <Grid item>
          <Typography
            className={styles.label}
            variant="body2"
            color="textSecondary"
            align={align}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={styles.value}
            variant={size === 'small' ? 'subtitle2' : 'h5'}
            align={align}
          >
            {value}
          </Typography>
        </Grid>
        {detail && (
          <Grid item>
            <Typography variant="h6" className={styles.detail} align={align}>
              {detail}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  ) : (
    <div className={clsx(className, styles.root)} {...rest}>
      <Typography
        className={styles.value}
        variant={size === 'small' ? 'subtitle2' : 'h6'}
        align={align}
      >
        {value}
      </Typography>
    </div>
  );
};

Highlight.propTypes = {
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify'])
    .isRequired,
  size: PropTypes.oneOf(['small', 'medium']).isRequired,
  variant: PropTypes.oneOf(['standard', 'outlined']).isRequired,
  color: PropTypes.oneOf(['default', 'invalid', 'success', 'warning', 'error'])
    .isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Highlight.defaultProps = {
  align: 'left',
  size: 'medium',
  variant: 'outlined',
  color: 'default',
};

export default Highlight;
