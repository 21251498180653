import { useCallback, useRef, useState } from 'react';

const useCopyToClipboard = () => {
  const timerRef = useRef(null);
  const [copied, setCopied] = useState(null);

  const copyToClipboard = useCallback(async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopied(text);
      timerRef.current = setTimeout(() => {
        setCopied(null);
        timerRef.current = null;
      }, 4000);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopied(null);
      return false;
    }
  }, []);

  return [copied, copyToClipboard];
};

export default useCopyToClipboard;
