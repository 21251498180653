import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState, Suspense, lazy } from 'react';
import { find } from 'lodash';
import clsx from 'clsx';

import { selectCoursesWithGraph, selectMe } from 'Redux/selectors';
import RichText from 'Components/shared/rich-text';

import {
  ArrowDropDown as ArrowDropDownIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  withStyles,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core';

const ResponsivePdf = lazy(() => import('./responsive-pdf'));

const Accordion = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    overflow: 'hidden',
    '&$expanded': {
      margin: 'auto',
    },
    '&:before': {
      display: 'none',
    },
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  rounded: {
    borderRadius: '0 !important',
    '&:last-child': {
      [theme.breakpoints.up('sm')]: {
        borderBottomLeftRadius: theme.shape.borderRadius + 'px !important',
        borderBottomRightRadius: theme.shape.borderRadius + 'px !important',
      },
    },
    '&:first-child': {
      [theme.breakpoints.up('sm')]: {
        borderTopLeftRadius: theme.shape.borderRadius + 'px !important',
        borderTopRightRadius: theme.shape.borderRadius + 'px !important',
      },
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[1],
    flexDirection: 'row-reverse',
    height: 84,
    paddingLeft: theme.spacing(3.5),
    marginTop: 1,
  },
  expanded: {},
  expandIcon: {
    marginRight: 0,
    marginLeft: -12,
    transform: 'rotate(-90deg)',
    '&$expanded': {
      transform: 'rotate(0deg)',
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
}))(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 0,
    [theme.breakpoints.up('sm')]: {
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1],
    },
  },
  details: {
    padding: 0,
  },
  title: {
    width: '100%',
  },
  list: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    marginTop: 1,
  },
  listItem: {
    padding: theme.spacing(5, 3, 5, 0),
  },
  answer: {
    marginTop: 12,
  },
  resources: {
    width: '100%',
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexDirection: 'column',
  },
  resource: {
    padding: theme.spacing(1.25, 0),
    display: 'block',
    cursor: 'pointer',
  },
  container: {
    padding: theme.spacing(5, 3),
  },
  downloadButton: {
    marginLeft: 12,
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  downloadButtonIcon: {
    marginLeft: 8,
  },
}));

const LessonResources = (props) => {
  const { className } = props;
  const styles = useStyles();
  const { courseSlug, moduleSlug, lessonSlug } = useParams();

  const me = useSelector(selectMe);
  const courses = useSelector(selectCoursesWithGraph);

  const course = find(courses, ['slug', courseSlug]);
  const module = course ? find(course.modules, ['slug', moduleSlug]) : null;
  const lesson = module ? find(module.lessons, ['slug', lessonSlug]) : null;

  const [expanded, setExpanded] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  const handleChange = (index) => (event, value) =>
    setExpanded({ ...expanded, [index]: Boolean(value) });

  useEffect(() => {
    setExpanded({ 0: false, 1: false, 2: false, 3: false });
  }, [courseSlug, moduleSlug, lessonSlug]);

  if (!course || !module || !lesson) {
    return null;
  }

  const panels = [
    lesson.pdf
      ? {
          title: (
            <>
              Boxset PDF
              {me && me.roleId === 'ROLE_ADMIN' ? (
                <Link
                  className={styles.downloadButton}
                  href={lesson.pdf.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                  <GetAppIcon className={styles.downloadButtonIcon} />
                </Link>
              ) : null}
            </>
          ),
          element: (
            <Suspense fallback={<div>Loading....</div>}>
              <ResponsivePdf
                key={lesson.id}
                className={styles.container}
                url={lesson.pdf.url}
              />
            </Suspense>
          ),
        }
      : null,
    lesson.notes && lesson.notes.html
      ? {
          title: 'Quick Notes',
          element: (
            <RichText className={styles.container}>
              {lesson.notes.html}
            </RichText>
          ),
        }
      : null,
    lesson.resources.length
      ? {
          title: 'Downloads/Tools',
          element: (
            <div className={styles.resources}>
              {lesson.resources.map((resource, index) => (
                <Link
                  className={styles.resource}
                  key={index}
                  color="primary"
                  href={resource.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography variant="body1">
                    {index + 1}) {resource.title}
                  </Typography>
                </Link>
              ))}
            </div>
          ),
        }
      : null,
    lesson.questions.length
      ? {
          title: 'FAQ',
          element: (
            <List className={styles.list} disablePadding>
              {lesson.questions.map((question, index) => (
                <ListItem
                  className={styles.listItem}
                  key={index}
                  divider={index < lesson.questions.length - 1}
                >
                  <ListItemText>
                    <Typography variant="h6">{question.title}</Typography>
                    <RichText className={styles.answer}>
                      {question.answer.html}
                    </RichText>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          ),
        }
      : null,
  ];

  return (
    <div className={clsx(styles.root, className)}>
      {panels.filter(Boolean).map((panel, index) => {
        const { title, element } = panel;
        return (
          <Accordion
            key={index}
            expanded={expanded[index]}
            onChange={handleChange(index)}
            data-testid={`expandResource-${index}`}
          >
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
              <Typography className={styles.title} variant="h6">
                {title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.details}>
              {element}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default LessonResources;
