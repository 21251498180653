import { put, call } from 'redux-saga/effects';

import { omit } from 'lodash';
import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { setRememberMe } from './remember-me';

const MODULE = 'register';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const REGISTER_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const register = Exec.requestActionCreator(MODULE);
export const registerLoading = Exec.loadingActionCreator(MODULE);
export const registerSuccess = Exec.successActionCreator(MODULE);
export const registerError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
const defaultReducer = Exec.mutateReducerCreator(MODULE);

export const reducer = (state, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      const res = defaultReducer(state, action);
      return { ...res, payload: omit(action.payload, 'recaptchaResponse') };
    default:
      return defaultReducer(state, action);
  }
};

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectRegisterRequest = Exec.mutateRequestSelectorCreator(MODULE);
export const selectRegisterLastPayload = (store) => store.register.payload;

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doRegister = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(REGISTER_REQUEST));
    yield put(registerLoading());
    yield put(setRememberMe(payload.rememberMe || false));
    yield call(
      Api.register,
      payload.firstName,
      payload.lastName,
      payload.email,
      payload.password,
      payload.country,
      payload.phoneNumber,
      payload.recaptchaResponse,
    );
    yield put(registerSuccess(null, meta));
  } catch (err) {
    yield put(registerError(err, meta));
    if (err.code !== 'ERROR_CODE_TOO_MANY_REQUESTS') {
      yield put(
        enqueueSnackbar(REGISTER_REQUEST, {
          message:
            err.code === 'ERROR_CODE_BAD_REQUEST'
              ? err.detail
              : err.code === 'ERROR_CODE_EMAIL_ALREADY_TAKEN'
              ? 'The email you are trying to use is already taken'
              : err.code === 'ERROR_CODE_NETWORK_ERROR'
              ? 'Network error'
              : 'There was an error, try again later',
          variant: 'error',
        }),
      );
    }
  }
};

export const sagas = Exec.sagaCreator(MODULE, doRegister);
