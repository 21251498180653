import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useRef, useEffect, useState } from 'react';

import { selectSearch, selectSearchRequest } from 'Redux/selectors';
import { search } from 'Redux/actions';

import UnderlineButton from 'Components/shared/underline-button';
import Pagination from 'Components/shared/pagination';
import PageLoader from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import PageContainer from 'Components/shared/page-container';
import HeaderItems from 'Components/shared/header-items';
import DrawerItems from 'Components/shared/drawer-items';
import SearchResultList from 'Components/pages/help/search-result-list';
import SearchHeader from 'Components/pages/help/search-header';
import Cards from 'Components/pages/help/cards';

import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { makeStyles, Typography, Button } from '@material-ui/core';

const pageSize = 10;

const headingImage = {
  url: 'https://ecomfreedom-public-assets.s3.amazonaws.com/app/help-center-cover.jpg',
  isResizable: true,
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'flex-start',
    flexGrow: 1,
    padding: theme.spacing(8, 0.5, 7),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 1, 7),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8, 2, 7),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(8, 5, 7),
    },
  },
  infoText: {
    alignSelf: 'center',
  },
  container: {
    marginBottom: theme.spacing(5),
    padding: theme.spacing(0, 2.5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 4),
    },
  },
  filterResults: {
    marginBottom: theme.spacing(2.5),
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    columnGap: theme.spacing(1.5) + 'px',
    '@media only screen and (max-width: 300px)': {
      columnGap: theme.spacing(0.5) + 'px',
    },
  },
  viewAllResults: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  resourcesList: {
    marginTop: theme.spacing(10),
  },
  pagination: {
    marginTop: theme.spacing(4),
  },
  goBack: {
    alignSelf: 'center',
    marginTop: theme.spacing(7),
  },
}));

const HelpSearchPage = () => {
  const styles = useStyles();
  const filterRef = useRef(null);
  const [params] = useSearchParams();
  const [filter, setFilter] = useState({ type: 'all', page: 0 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, error } = useSelector(selectSearchRequest);
  const results = useSelector(selectSearch);

  const query = params.get('q'); // Set by <Heading /> component
  const backTo = params.get('backTo');

  const { type, page } = filter;

  const handleTypeChange = useCallback((event) => {
    const value = event.currentTarget.getAttribute('data-value');
    setFilter({ type: value, page: 0 });
    if (filterRef.current) {
      window.scrollTo(0, filterRef.current.offsetTop);
    }
  }, []);

  const handleChange = useCallback(
    (page) => setFilter((prev) => ({ ...prev, page: page - 1 })),
    [],
  );

  const handleBack = useCallback(() => {
    if (type !== 'all') {
      setFilter({ type: 'all', page: 0 });
    } else {
      navigate(backTo ? backTo : -1);
    }
  }, [backTo, navigate, type]);

  useEffect(() => {
    dispatch(search({ query }));
  }, [dispatch, query]);

  const questionsResults = !results
    ? []
    : results.questions.map((res) => ({
        ...res,
        href: `/help/faq/${res.courseSlug}/#/module-faq/${res.moduleSlug}/${res.id}`,
      }));

  const resourcesResults = !results
    ? []
    : results.resources.map((res) => ({ ...res, href: res.url }));

  const currentList =
    type === 'questions' ? questionsResults : resourcesResults;

  if (
    !query ||
    (!isLoading &&
      questionsResults.length === 0 &&
      resourcesResults.length === 0)
  ) {
    return (
      <PageLoader>
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <SearchHeader image={headingImage} withBackButton />
          <PageContainer component="section" className={styles.root}>
            <Typography
              variant="subtitle2"
              align="center"
              className={styles.infoText}
            >
              {!query ? (
                <>Search results will appear here.</>
              ) : (
                <>
                  No results found for <strong>"{query}"</strong>.
                </>
              )}
            </Typography>
            <Button
              className={styles.goBack}
              size="large"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
            >
              Go Back
            </Button>
          </PageContainer>
        </PageLayout>
      </PageLoader>
    );
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <PageLayout headerItems={<HeaderItems />} drawerItems={<DrawerItems />}>
          <SearchHeader image={headingImage} withBackButton />
          <PageContainer component="section" className={styles.root}>
            {questionsResults.length && resourcesResults.length ? (
              <div className={styles.container}>
                <Typography
                  ref={filterRef}
                  variant="subtitle2"
                  className={styles.filterResults}
                >
                  Filter Results
                </Typography>
                <div className={styles.buttons}>
                  <Button
                    variant={type === 'all' ? 'contained' : 'outlined'}
                    color="primary"
                    data-value="all"
                    onClick={handleTypeChange}
                  >
                    All
                  </Button>
                  <Button
                    variant={type === 'questions' ? 'contained' : 'outlined'}
                    color="primary"
                    data-value="questions"
                    onClick={handleTypeChange}
                  >
                    Questions
                  </Button>
                  <Button
                    variant={type === 'resources' ? 'contained' : 'outlined'}
                    color="primary"
                    data-value="resources"
                    onClick={handleTypeChange}
                  >
                    Resources
                  </Button>
                </div>
              </div>
            ) : null}
            {type === 'all' ? (
              <>
                {questionsResults.length ? (
                  <>
                    <SearchResultList
                      title="Frequently Asked Questions"
                      results={questionsResults.slice(0, 5)}
                      target="navigate"
                      currentIndex={0}
                      totalCount={questionsResults.length}
                    />
                    <UnderlineButton
                      size="large"
                      className={styles.viewAllResults}
                      data-value="questions"
                      color="primary"
                      onClick={handleTypeChange}
                    >
                      View All Results
                    </UnderlineButton>
                  </>
                ) : null}

                {resourcesResults.length ? (
                  <>
                    <SearchResultList
                      title="Resources &amp; Tools"
                      className={styles.resourcesList}
                      results={resourcesResults.slice(0, 5)}
                      target="open"
                      currentIndex={0}
                      totalCount={resourcesResults.length}
                    />
                    <UnderlineButton
                      size="large"
                      className={styles.viewAllResults}
                      data-value="resources"
                      color="primary"
                      onClick={handleTypeChange}
                    >
                      View All Results
                    </UnderlineButton>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <SearchResultList
                  title={
                    type === 'questions'
                      ? 'Frequently Asked Questions'
                      : 'Resources & Tools'
                  }
                  target={type === 'questions' ? 'navigate' : 'open'}
                  results={currentList.slice(page * pageSize, pageSize)}
                  currentIndex={page * pageSize}
                  totalCount={currentList.length}
                />
                {currentList.length / pageSize > 1 && (
                  <Pagination
                    className={styles.pagination}
                    current={page + 1}
                    total={currentList.length}
                    pageSize={pageSize}
                    onChange={handleChange}
                  />
                )}
              </>
            )}
            <Button
              className={styles.goBack}
              size="large"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
            >
              Go Back
            </Button>
          </PageContainer>
          <Cards />
        </PageLayout>
      )}
    </PageLoader>
  );
};

export default HelpSearchPage;
