import instance from 'Api/instance';

export const submitToolProblem = (subject, message, tags) =>
  instance.post('/support/ticket', {
    data: {
      attributes: {
        subject,
        message,
        tags,
      },
    },
  });
