import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import dynamicImageUrl from 'Util/dynamic-image-url';
import {
  selectAchievementLevels,
  selectAchievementLevelsRequest,
} from 'Redux/selectors';
import { fetchAchievementLevels } from 'Redux/actions';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import PageLoader from 'Components/shared/page-loader';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import ContentPaper from 'Components/pages/admin/content-paper';

import { Edit as EditIcon } from '@material-ui/icons';
import { makeStyles, Typography, Grid, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  container: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    width: '100%',
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  graphic: {
    width: 80,
    height: 80,
    borderRadius: '50%',
    border: 'none',
    backgroundColor: theme.palette.grey[50],
    objectFit: 'cover',

    margin: theme.spacing(-4, 2, -4, -1),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(-4, 2, -4, -2),
    },
  },
}));

const AdminAchievementLevelsPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const achievementLevels = useSelector(selectAchievementLevels);

  const { isLoading, error } = useCombinedRequestsSelector(
    selectAchievementLevelsRequest,
  );

  useScopedDispatchEffect(() => {
    dispatch(fetchAchievementLevels());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => (
        <UserDetailsLayout>
          <div className={styles.container}>
            <TitleAndAction
              className={styles.titleAndAction}
              title={<Typography variant="h4">Achievement Levels</Typography>}
            />

            <Grid container spacing={1}>
              {!achievementLevels.length ? (
                <Typography variant="h6">
                  No achievement levels found
                </Typography>
              ) : null}

              {(achievementLevels || []).map((l) => (
                <Grid key={l.id} item container>
                  <ContentPaper
                    className={styles.paper}
                    containerClassName={styles.content}
                    actions={
                      <IconButton
                        component={Link}
                        to={`/admin/achievement-levels/${l.id}/edit`}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                  >
                    {l.graphic ? (
                      <img
                        key={l.graphic.url}
                        src={dynamicImageUrl(l.graphic, {
                          resize: {
                            width: 80,
                            height: 80,
                            fit: 'cover',
                          },
                        })}
                        className={styles.graphic}
                        alt="achievement"
                      />
                    ) : (
                      <div className={styles.graphic} />
                    )}
                    <Typography variant="h6">{l.title}</Typography>
                  </ContentPaper>
                </Grid>
              ))}
            </Grid>
          </div>
        </UserDetailsLayout>
      )}
    </PageLoader>
  );
};

export default AdminAchievementLevelsPage;
