import { call, put } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'affiliate-stats';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
export const FETCH_AFFILIATE_STATS_SUCCESS =
  Exec.successConstantCreator(MODULE);
export const FETCH_AFFILIATE_STATS_ERROR = Exec.errorConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchAffiliateStats = Exec.requestActionCreator(MODULE);
export const fetchAffiliateStatsLoading = Exec.loadingActionCreator(MODULE);
export const fetchAffiliateStatsSuccess = Exec.successActionCreator(MODULE);
export const fetchAffiliateStatsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectAffiliateStatsRequest =
  Exec.fetchRequestSelectorCreator(MODULE);
export const selectAffiliateStats = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchAffiliateStats = function* ({ meta }) {
  try {
    const { data } = yield call(Api.getAffiliateStats);
    yield put(fetchAffiliateStatsSuccess({ data }));
  } catch (err) {
    yield put(fetchAffiliateStatsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchAffiliateStats);
