import * as yup from 'yup';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { get } from 'lodash';

import {
  selectSigninRequest,
  selectRememberMe,
  selectMeRequest,
} from 'Redux/selectors';
import { signin } from 'Redux/actions';
import useNavigateExternal from 'Hooks/use-navigate-external';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import { PageLoaderReset } from 'Components/shared/page-loader';
import PageLayout from 'Components/shared/page-layout';
import LoginTabs from 'Components/shared/login-tabs';
import Input from 'Components/shared/input';
import Checkbox from 'Components/shared/checkbox';

import {
  ChevronRight as ChevronRightIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  Button,
  Grid,
  FormControlLabel,
  InputAdornment,
  Container,
  IconButton,
} from '@material-ui/core';

const schema = yup.object().shape({
  email: yup.string().email('Invalid Email.').required('Field Required.'),
  password: yup.string().min(6, 'Too Short.').required('Field Required.'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
  },
  buttons: {
    paddingTop: theme.spacing(4) + 'px !important',
    justifyContent: 'space-between',
  },
  frame: {
    width: '100%',
    maxWidth: 340,
    margin: -1,
    marginTop: theme.spacing(2.5) - 1 + 'px',
    padding: theme.spacing(3, 4),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginTop: theme.spacing(2.5),
      borderWidth: 0,
    },
  },
}));

const SigninPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigateExternal();
  const [params] = useSearchParams();

  const redirectTo = params.get('redirectTo');

  const { isLoading } = useCombinedRequestsSelector(
    selectSigninRequest,
    selectMeRequest,
  );
  const rememberMe = useSelector(selectRememberMe);

  const [isPasswdVisible, setIsPasswdVisible] = useState(false);
  const handleToggleVisibility = useCallback(
    () => setIsPasswdVisible(!isPasswdVisible),
    [isPasswdVisible],
  );

  const handleFormSubmit = useCallback(
    (data) =>
      dispatch(signin(data, { isPromise: true }))
        .then(() => navigate(redirectTo || '/'))
        .catch(console.warn),
    [dispatch, navigate, redirectTo],
  );

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: { rememberMe },
  });

  return (
    <PageLoaderReset>
      <PageLayout>
        <Container className={styles.root} disableGutters>
          <LoginTabs value={0} />
          <Grid
            container
            spacing={2}
            direction="column"
            className={styles.frame}
            component="form"
            noValidate
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Grid item>
              <Input
                data-testid="email"
                control={control}
                className={styles.input}
                disabled={isLoading}
                label="Email Address"
                name="email"
                type="email"
                errorText={get(errors, 'email.message')}
              />
            </Grid>
            <Grid item>
              <Input
                data-testid="password"
                control={control}
                className={styles.input}
                disabled={isLoading}
                label="Password"
                name="password"
                type={isPasswdVisible ? 'text' : 'password'}
                errorText={get(errors, 'password.message')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="toggleVisibility"
                        onClick={handleToggleVisibility}
                      >
                        {isPasswdVisible ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="rememberMe"
                    control={control}
                    disabled={isLoading}
                    name="rememberMe"
                    color="secondary"
                  />
                }
                label="Remember me"
              />
            </Grid>
            <Grid container item className={styles.buttons}>
              <Grid item>
                <Button
                  data-testid="forgotPassword"
                  color="primary"
                  disabled={isLoading}
                  variant="text"
                  component={Link}
                  to={
                    redirectTo
                      ? '/account-recovery?redirectTo=' + redirectTo
                      : '/account-recovery'
                  }
                >
                  Forgot?
                </Button>
              </Grid>
              <Grid item>
                <Button
                  data-testid="submit"
                  type="submit"
                  color="secondary"
                  disabled={isLoading}
                  variant="contained"
                  endIcon={<ChevronRightIcon />}
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </PageLayout>
    </PageLoaderReset>
  );
};

export default SigninPage;
