import { middleware as sagaThunkMiddleware } from 'redux-saga-thunk';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { batchDispatchMiddleware } from 'redux-batched-actions';
import { createStore, applyMiddleware } from 'redux';

import sagas from './sagas';
import createReducer from './reducers';
import {
  fetchMe,
  fetchCourses,
  fetchAchievementLevels,
  fetchSubscriptionSteps,
} from './actions';

const configureStore = (initialState) => {
  const rootReducer = createReducer({});
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    batchDispatchMiddleware,
    sagaThunkMiddleware,
    sagaMiddleware,
  ];

  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  sagas.map(sagaMiddleware.run);
  store.dispatch(fetchMe());
  store.dispatch(fetchCourses());
  store.dispatch(fetchSubscriptionSteps());
  store.dispatch(fetchAchievementLevels());

  return store;
};

export default configureStore;
