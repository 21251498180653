import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ToolIcon from 'Icons/tool';
import ConstructionIcon from 'Icons/construction';
import DropdownMenu from 'Components/shared/dropdown-menu';

import {
  ChevronRight as ChevronRightIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Subscriptions as SubscriptionsIcon,
  PeopleAlt as PeopleAltIcon,
  SupervisorAccount as SupervisorAccountIcon,
  HelpOutline as HelpOutlineIcon,
} from '@material-ui/icons';
import {
  makeStyles,
  useMediaQuery,
  ButtonBase,
  MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    background:
      props.variant === 'search'
        ? 'linear-gradient(180deg, rgba(37, 50, 71, 0) 0%, rgba(37, 50, 71, 0.8) 100%)'
        : 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)',
    width: `calc(100% - ${theme.spacing(6)}px)`,
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  }),
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    maxWidth: 678,
    width: '100%',
  },
  button: {
    height: 48,
    fontSize: 20,
    letterSpacing: '0.75px',
    textTransform: 'capitalize',
    padding: theme.spacing(0, 3),
    color: 'white',
  },
  buttonActive: {
    position: 'relative',
    backgroundColor: 'white',
    color: theme.palette.text.primary,
    borderRadius: '8px 8px 0 0',
    zIndex: 1,

    '&::before': {
      content: '" "',
      position: 'absolute',

      backgroundColor: 'transparent',
      top: 24,
      left: -20,
      height: 24,
      width: 20,
      borderBottomRightRadius: 8,
      boxShadow: '8px 0 0 0 white',
    },
    '&::after': {
      content: '" "',
      position: 'absolute',
      zIndex: -1,

      backgroundColor: 'transparent',
      top: 24,
      right: -20,
      height: 24,
      width: 20,
      borderBottomLeftRadius: 8,
      boxShadow: '-8px 0 0 0 white',
    },
  },
  menu: {
    marginLeft: 0,
    marginTop: 64,
  },
  menuButton: {
    width: `100%`,
    justifyContent: 'flex-start',
    position: 'relative',
  },
  menuItem: {
    minWidth: `calc(100vw - ${theme.spacing(10)}px)`,
    position: 'relative',
    textTransform: 'capitalize',
  },
  menuIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  menuArrowIcon: {
    position: 'absolute',
    right: theme.spacing(1.5),
    color: theme.palette.text.secondary,
  },
}));

const HeaderNavigation = (props) => {
  const { className, option, variant } = props;

  const styles = useStyles({ variant });
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const { courseSlug } = useParams();

  const menuIcons = {
    courses: SubscriptionsIcon,
    tools: ConstructionIcon,
    community: PeopleAltIcon,
    mentorship: SupervisorAccountIcon,
    'resource-vault': ToolIcon,
    faq: HelpOutlineIcon,
  };

  const options =
    variant === 'vault'
      ? [
          {
            key: 'resource-vault',
            title: 'Resource Vault',
            to: '/help/resource-vault/' + courseSlug,
          },
          {
            key: 'faq',
            title: 'Common Questions',
            to: '/help/faq/' + courseSlug,
          },
        ]
      : [
          {
            key: 'courses',
            title: 'Courses',
            to: '/help/courses',
          },
          {
            key: 'tools',
            title: 'Tools',
            to: '/help/tools',
          },
          {
            key: 'community',
            title: 'Community',
            to: '/help/community',
          },
          {
            key: 'mentorship',
            title: 'Mentorship',
            to: '/help/mentorship',
          },
        ];

  return (
    <div className={clsx(className, styles.root)}>
      {isSmUp ? (
        <div className={styles.buttons}>
          {options.map(({ key, title, to }) => (
            <ButtonBase
              key={key}
              className={clsx(
                styles.button,
                option === key && styles.buttonActive,
              )}
              component={Link}
              to={to}
              data-testid={key + 'Button'}
            >
              {title}
            </ButtonBase>
          ))}
        </div>
      ) : (
        <DropdownMenu
          className={styles.menuButton}
          menuClassName={styles.menu}
          control={
            <ButtonBase
              className={clsx(
                styles.button,
                styles.buttonActive,
                styles.menuButton,
              )}
            >
              Help Menu
              <ArrowDropDownIcon className={styles.menuArrowIcon} />
            </ButtonBase>
          }
        >
          {options.map(({ key, title, to }) => {
            const Icon = menuIcons[key];
            return (
              <MenuItem
                key={key}
                component={Link}
                className={styles.menuItem}
                to={to}
              >
                <Icon className={styles.menuIcon} />
                {title}
                <ChevronRightIcon className={styles.menuArrowIcon} />
              </MenuItem>
            );
          })}
        </DropdownMenu>
      )}
    </div>
  );
};

HeaderNavigation.propTypes = {
  option: PropTypes.oneOfType([
    PropTypes.oneOf(['courses', 'tools', 'community', 'mentorship']),
    PropTypes.oneOf(['resource-vault', 'faq']),
  ]),
  variant: PropTypes.oneOf(['search', 'course', 'vault']),
};

export default HeaderNavigation;
