import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import {
  selectUploadFileRequest,
  selectUploadEventRecordingRequest,
} from 'Redux/selectors';
import {
  uploadFileCancel,
  uploadEventRecording,
  uploadEventRecordingReset,
} from 'Redux/actions';
import UploadDropzone from 'Components/shared/upload-dropzone';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    marginTop: theme.spacing(3),
  },
}));

const UploadEventRecordingDialog = (props) => {
  const { eventId, onClose } = props;
  const [fileRejections, setFileRejections] = useState([]);
  const [helperText, setHelperText] = useState(null);

  const styles = useStyles();
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(selectUploadEventRecordingRequest);
  const { progress } = useSelector(selectUploadFileRequest);
  useEffect(() => () => dispatch(uploadEventRecordingReset()), [dispatch]);

  const handleDrop = useCallback(
    (accepted, rejections) => {
      setHelperText(null);

      if (rejections.length) {
        setFileRejections(rejections);
      } else {
        dispatch(
          uploadEventRecording(
            { eventId, file: accepted[0] },
            { isPromise: true },
          ),
        )
          .then(onClose)
          .catch(noop);
      }
    },
    [dispatch, onClose, eventId],
  );

  const handleReset = useCallback(() => {
    setFileRejections([]);
    dispatch(uploadEventRecordingReset());
  }, [dispatch]);

  const handleSetHelperText = useCallback(
    () => setHelperText('You have to pick a video.'),
    [],
  );

  const handleCancel = useCallback(() => {
    dispatch(uploadFileCancel());
    onClose();
  }, [dispatch, onClose]);

  const hasError = Boolean(error) || fileRejections.length;

  return (
    <Dialog
      interruptible
      title="Upload Event Recording"
      loading={isLoading}
      onClose={onClose}
      cancelButton={
        <Button data-testid="cancel" onClick={handleCancel}>
          Cancel
        </Button>
      }
      submitButton={
        <Button
          data-testid="submit"
          onClick={hasError ? handleReset : handleSetHelperText}
        >
          {hasError ? 'Retry' : 'Change'}
        </Button>
      }
    >
      <UploadDropzone
        className={styles.dropzone}
        loading={isLoading}
        progress={progress}
        DropzoneProps={{
          onDrop: handleDrop,
          accept: ['video/*'],
          multiple: false,
        }}
        errorText={
          hasError
            ? error && error.code === 'ERROR_CODE_NETWORK_ERROR'
              ? 'Please check your internet connection.'
              : error.detail || error.title || error.message
            : null
        }
        helperText={helperText}
      />
    </Dialog>
  );
};

UploadEventRecordingDialog.propTypes = {
  eventId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UploadEventRecordingDialog;
