import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'create-payment-method';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CREATE_PAYMENT_METHOD_REQUEST = Exec.requestConstantCreator(MODULE);
export const CREATE_PAYMENT_METHOD_SUCCESS = Exec.successConstantCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const createPaymentMethod = Exec.requestActionCreator(MODULE);
export const createPaymentMethodLoading = Exec.loadingActionCreator(MODULE);
export const createPaymentMethodSuccess = Exec.successActionCreator(MODULE);
export const createPaymentMethodError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCreatePaymentMethodRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doCreatePaymentMethod = function* ({ payload, meta }) {
  const { stripe, data: input, isDefault } = payload;
  try {
    yield put(closeSnackbar(CREATE_PAYMENT_METHOD_REQUEST));
    yield put(createPaymentMethodLoading());

    const { error, paymentMethod } = yield call(
      stripe.createPaymentMethod.bind(stripe),
      input,
    );

    if (error) {
      throw error;
    }

    const { data } = yield call(
      Api.createPaymentMethod,
      paymentMethod.id,
      isDefault,
    );
    yield put(createPaymentMethodSuccess({ data }, meta));
  } catch (err) {
    yield put(createPaymentMethodError(err, meta));
    yield put(
      enqueueSnackbar(CREATE_PAYMENT_METHOD_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.message || err.detail,
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCreatePaymentMethod);
