import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'create-course-backup';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CREATE_COURSE_BACKUP_REQUEST = Exec.requestConstantCreator(MODULE);
export const CREATE_COURSE_BACKUP_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const createCourseBackup = Exec.requestActionCreator(MODULE);
export const createCourseBackupLoading = Exec.loadingActionCreator(MODULE);
export const createCourseBackupSuccess = Exec.successActionCreator(MODULE);
export const createCourseBackupError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCreateCourseBackupRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doCreateCourseBackup = function* ({ payload, meta }) {
  const { courseId } = payload;

  try {
    yield put(closeSnackbar(CREATE_COURSE_BACKUP_REQUEST));
    yield put(createCourseBackupLoading());
    const { data } = yield call(Api.createCourseBackup, courseId);
    yield put(createCourseBackupSuccess({ data }, meta));
  } catch (err) {
    yield put(createCourseBackupError(err, meta));
    yield put(
      enqueueSnackbar(CREATE_COURSE_BACKUP_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCreateCourseBackup);
