import qs from 'qs';

import instance from 'Api/instance';

export const getResources = ({ limit, offset, omitIds }) =>
  instance.get(
    `/resources?` +
      qs.stringify(
        {
          limit,
          offset,
          omitIds,
        },
        { arrayFormat: 'repeat' },
      ),
  );

export const createResource = (attributes) =>
  instance.post(`/resources`, { data: { attributes } });

export const updateResource = (resourceId, attributes) =>
  instance.patch(`/resources/` + resourceId, { data: { attributes } });

export const sortLessonResources = (lessonId, attributes) =>
  instance.post('/lessons/' + lessonId + '/resources/sort', {
    data: { attributes },
  });
