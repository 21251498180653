import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'delete-achievement-level-picture';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const DELETE_ACHIEVEMENT_LEVEL_PICTURE_REQUEST =
  Exec.requestConstantCreator(MODULE);
export const DELETE_ACHIEVEMENT_LEVEL_PICTUREL_SUCCESS =
  Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const deleteAchievementLevelPicture = Exec.requestActionCreator(MODULE);
export const deleteAchievementLevelPictureLoading =
  Exec.loadingActionCreator(MODULE);
export const deleteAchievementLevelPictureSuccess =
  Exec.successActionCreator(MODULE);
export const deleteAchievementLevelPictureError =
  Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectDeleteAchievementLevelPictureRequest =
  Exec.mutateRequestSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doDeleteAchievementLevelPicture = function* ({ payload, meta }) {
  const { achievementLevelId } = payload || {};

  try {
    yield put(closeSnackbar(DELETE_ACHIEVEMENT_LEVEL_PICTURE_REQUEST));
    yield put(deleteAchievementLevelPictureLoading());

    const { data } = yield call(
      Api.deleteAchievementLevelPicture,
      achievementLevelId,
    );

    yield put(deleteAchievementLevelPictureSuccess({ data }, meta));
  } catch (err) {
    yield put(deleteAchievementLevelPictureError(err, meta));
    yield put(
      enqueueSnackbar(DELETE_ACHIEVEMENT_LEVEL_PICTURE_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail ||
              err.message ||
              'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doDeleteAchievementLevelPicture);
