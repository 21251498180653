import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

const MODULE = 'achievement-stats';

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const fetchAchievementStats = Exec.requestActionCreator(MODULE);
export const fetchAchievementStatsLoading = Exec.loadingActionCreator(MODULE);
export const fetchAchievementStatsSuccess = Exec.successActionCreator(MODULE);
export const fetchAchievementStatsError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.fetchReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectAchievementStatsRequest = Exec.fetchRequestSelectorCreator(
  MODULE,
);
export const selectAchievementStats = Exec.dataSelectorCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doFetchAchievementStats = function* ({ meta }) {
  try {
    const { data } = yield call(Api.getAchievementStats);
    yield put(fetchAchievementStatsSuccess({ data }, meta));
  } catch (err) {
    yield put(fetchAchievementStatsError(err, meta));
  }
};

export const sagas = Exec.sagaCreator(MODULE, doFetchAchievementStats);
