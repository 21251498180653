import { call, put } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar } from './snackbar';

const MODULE = 'create-payment';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const CREATE_PAYMENT_REQUEST = Exec.requestConstantCreator(MODULE);
export const CREATE_PAYMENT_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const createPayment = Exec.requestActionCreator(MODULE);
export const createPaymentLoading = Exec.loadingActionCreator(MODULE);
export const createPaymentSuccess = Exec.successActionCreator(MODULE);
export const createPaymentError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectCreatePaymentRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////

const doCreatePayment = function* ({ payload, meta }) {
  const { userId, ...fields } = payload;

  try {
    yield put(createPaymentLoading());

    const { data } = yield call(Api.createPayment, userId, fields);

    if (data.paymentMethodId) {
      const { data: paymentMethod } = yield call(
        Api.getPaymentMethod,
        data.paymentMethodId,
      );

      delete data.paymentMethodId;
      data.paymentMethod = paymentMethod;
    }

    yield put(createPaymentSuccess({ data }, meta));
  } catch (err) {
    yield put(createPaymentError(err, meta));
    yield put(
      enqueueSnackbar(CREATE_PAYMENT_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail || 'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doCreatePayment);
