import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectUpdateLessonRequest } from 'Redux/selectors';
import { updateLesson } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmDeleteLessonBoxsetPdfDialog = (props) => {
  const { courseId, moduleId, lessonId, onClose } = props;
  const { isLoading } = useSelector(selectUpdateLessonRequest);
  const dispatch = useDispatch();

  const handleDelete = useCallback(() => {
    dispatch(
      updateLesson(
        { courseId, moduleId, lessonId, pdf: null },
        { isPromise: true },
      ),
    )
      .then(onClose)
      .catch(noop);
  }, [dispatch, onClose, courseId, moduleId, lessonId]);

  return (
    <Dialog
      title="Delete Boxset Pdf"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleDelete}>
          Delete
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to delete the Boxset PDF?
      </Typography>
    </Dialog>
  );
};

ConfirmDeleteLessonBoxsetPdfDialog.propTypes = {
  courseId: PropTypes.string,
  moduleId: PropTypes.string,
  lessonId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmDeleteLessonBoxsetPdfDialog;
