import { put, call, select } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { fetchUserSuccess, selectUser } from './user';
import { enqueueSnackbar, closeSnackbar } from './snackbar';
import { fetchMeSuccess, selectMe } from './me';

const MODULE = 'delete-profile-picture';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const DELETE_PROFILE_PICTURE_REQUEST = Exec.requestConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const deleteProfilePicture = Exec.requestActionCreator(MODULE);
export const deleteProfilePictureLoading = Exec.loadingActionCreator(MODULE);
export const deleteProfilePictureSuccess = Exec.successActionCreator(MODULE);
export const deleteProfilePictureError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectDeleteProfilePictureRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doDeleteProfilePicture = function* ({ payload, meta }) {
  const { userId } = payload || {};

  const me = yield select(selectMe);
  const user = yield select(selectUser);

  try {
    yield put(closeSnackbar(DELETE_PROFILE_PICTURE_REQUEST));
    yield put(deleteProfilePictureLoading());
    const { data } = userId
      ? yield call(Api.deleteUserProfilePicture, userId)
      : yield call(Api.deleteMeProfilePicture);

    if (!userId || (me && me.id === userId)) {
      yield put(fetchMeSuccess({ data }));
    }

    if (user && user.id === userId) {
      yield put(fetchUserSuccess({ data }));
    }

    yield put(deleteProfilePictureSuccess(null, meta));
  } catch (err) {
    yield put(deleteProfilePictureError(err, meta));
    yield put(
      enqueueSnackbar(DELETE_PROFILE_PICTURE_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail ||
              err.message ||
              'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doDeleteProfilePicture);
