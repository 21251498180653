import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { get, find, last, differenceBy, isEqual } from 'lodash';

import {
  selectCoursesWithGraph,
  selectCoursesRequest,
  selectModulesRequest,
  selectLessonsRequest,
  selectTopics,
  selectTopicsRequest,
  selectUpdateResourceRequest,
  selectCreateResourceRequest,
  selectUpdateLessonRequest,
} from 'Redux/selectors';
import {
  createResource,
  fetchCourses,
  fetchLessons,
  fetchModules,
  fetchTopics,
  updateLesson,
  updateResource,
} from 'Redux/actions';
import ErrorPage from 'Pages/error';
import useScopedDispatchEffect from 'Hooks/use-scoped-dispatch-effect';
import useCombinedRequestsSelector from 'Hooks/use-combined-requests-selector';
import AddTopicDialog from 'Dialogs/add-topic-dialog';
import Switch from 'Components/shared/switch';
import PageLoader from 'Components/shared/page-loader';
import Input from 'Components/shared/input';
import UserDetailsLayout from 'Components/pages/admin/user-details-layout';
import TitleAndAction from 'Components/pages/admin/title-and-action';
import Breadcrumbs from 'Components/pages/admin/breadcrumbs';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Save as SaveIcon } from '@material-ui/icons';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  TextField,
  FormControlLabel,
} from '@material-ui/core';

const filter = createFilterOptions();

const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      inputValue: params.inputValue,
      title: `Add "${params.inputValue}"`,
    });
  }

  return filtered;
};

const getOptionLabel = (option) => {
  // e.g value selected with enter, right from the input
  if (typeof option === 'string') {
    return option;
  }
  if (option.inputValue) {
    return option.inputValue;
  }
  return option.title;
};

const renderOption = (option) => option.title;

const renderInput = (params) => (
  <TextField {...params} label="Topics" variant="outlined" helperText=" " />
);

const schema = yup.object().shape({
  title: yup.string().required('Field Required.'),
  url: yup.string().required('Field Required.'),
  description: yup.string().required('Field Required.'),
  showInVault: yup.boolean().required('Field Required.'),
});

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(0.5),
  },
  titleAndAction: {
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    maxWidth: 1064,
    alignSelf: 'flex-start',
  },
  input: {
    width: '100%',
  },
  colorPreview: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  switch: {
    marginBottom: theme.spacing(2.5),
  },
  upload: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  topics: {
    marginBottom: theme.spacing(3),
  },
}));

const AdminCreateOrUpdateResourcePage = () => {
  const styles = useStyles();
  const loadedElRef = useRef(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId, moduleId, lessonId, resourceId } = useParams();

  const [createNewTopic, setCreateNewTopic] = useState();
  const [selectedTopics, setSelectedTopics] = useState([]);

  const topics = useSelector(selectTopics);
  const courses = useSelector(selectCoursesWithGraph);
  const course = find(courses, ['id', courseId]);
  const module = course ? find(course.modules, ['id', moduleId]) : null;
  const lesson = module ? find(module.lessons, ['id', lessonId]) : null;
  const resource = lesson ? find(lesson.resources, ['id', resourceId]) : null;

  const options = useMemo(
    () => differenceBy(topics, selectedTopics, 'id'),
    [topics, selectedTopics],
  );

  const { isLoading, error } = useCombinedRequestsSelector(
    selectCoursesRequest,
    selectModulesRequest,
    selectLessonsRequest,
    selectTopicsRequest,
  );

  const { isLoading: isLoadingMutation } = useCombinedRequestsSelector(
    selectUpdateResourceRequest,
    selectCreateResourceRequest,
    selectUpdateLessonRequest,
  );

  useEffect(() => {
    if (resource && resource.topics && !loadedElRef.current) {
      loadedElRef.current = true;
      setSelectedTopics(resource.topics);
    }
  }, [resource]);

  useScopedDispatchEffect(() => {
    if (!course) {
      dispatch(fetchCourses());
    }
    if (!module) {
      dispatch(fetchModules({ courseId, skipAccessCheck: true }));
    }
    if (!lesson) {
      dispatch(fetchLessons({ courseId, skipAccessCheck: true }));
    }
    dispatch(fetchTopics());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId]);

  const handleFormSubmit = useCallback(
    async (data) => {
      const action = resourceId
        ? updateResource(
            { ...data, topics: selectedTopics.map((t) => t.id), resourceId },
            { isPromise: true },
          )
        : createResource(
            { ...data, topics: selectedTopics.map((t) => t.id) },
            { isPromise: true },
          );

      try {
        const payload = await dispatch(action);

        if (!resourceId) {
          await dispatch(
            updateLesson(
              {
                courseId,
                moduleId,
                lessonId,
                resources: [
                  ...lesson.resources.map((l) => l.id),
                  payload.data.id,
                ],
              },
              { isPromise: true },
            ),
          );
        }

        navigate(
          `/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/overview`,
        );
      } catch {}
    },
    [
      dispatch,
      navigate,
      courseId,
      lessonId,
      moduleId,
      resourceId,
      lesson,
      selectedTopics,
    ],
  );

  const handleChangeTopic = useCallback((event, values) => {
    const newValue = last(values);

    if (typeof newValue === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        setCreateNewTopic(newValue);
      });
    } else if (newValue && newValue.inputValue) {
      setCreateNewTopic(newValue.inputValue);
    } else {
      setSelectedTopics(values);
    }
  }, []);

  const handleCloseAddTopicDialog = useCallback((created) => {
    setCreateNewTopic(null);
    if (created) {
      setSelectedTopics((previous) => [...previous, created]);
    }
  }, []);

  if (!isLoading && !resource && resourceId) {
    return <ErrorPage notFound />;
  }

  return (
    <PageLoader loading={isLoading} error={error}>
      {() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { control, errors, formState, handleSubmit } = useForm({
          defaultValues: resource
            ? { ...resource }
            : {
                showInVault: false,
              },
          validationSchema: schema,
          reValidateMode: 'onChange',
        });

        const edited =
          formState.dirty ||
          Boolean(
            resource
              ? !isEqual(
                  selectedTopics.map((t) => t.id),
                  resource.topics.map((t) => t.id),
                )
              : selectedTopics.length,
          );

        return (
          <UserDetailsLayout>
            {createNewTopic ? (
              <AddTopicDialog
                defaultTitle={createNewTopic}
                onClose={handleCloseAddTopicDialog}
              />
            ) : null}

            <Breadcrumbs
              className={styles.breadcrumbs}
              links={[
                { href: '/admin/courses', label: 'Courses' },
                {
                  href: `/admin/courses/${courseId}/overview`,
                  label: course.title,
                },
                {
                  href: `/admin/courses/${courseId}/modules/${moduleId}/overview`,
                  label: module.title,
                },
                {
                  href: `/admin/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/overview`,
                  label: lesson.title,
                },
                {
                  label: resource ? resource.title : 'Resource',
                },
              ]}
            />

            <form
              className={styles.form}
              noValidate
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <TitleAndAction
                className={styles.titleAndAction}
                title={
                  <Typography variant="h4">
                    {resource ? 'Edit Resource' : 'Create New Resource'}
                  </Typography>
                }
              />

              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Input
                    data-testid="title"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Title"
                    name="title"
                    errorText={get(errors, 'title.message')}
                  />
                </Grid>

                <Grid item>
                  <Input
                    data-testid="url"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Url"
                    name="url"
                    errorText={get(errors, 'url.message')}
                  />
                </Grid>
                <Grid item>
                  <Input
                    data-testid="description"
                    control={control}
                    className={styles.input}
                    disabled={isLoadingMutation}
                    label="Description"
                    name="description"
                    errorText={get(errors, 'description.message')}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    className={styles.switch}
                    control={
                      <Switch
                        data-testid="showInVault"
                        control={control}
                        disabled={isLoadingMutation}
                        name="showInVault"
                        color="primary"
                      />
                    }
                    label="Visible in Resource Vault"
                  />
                </Grid>

                <Grid item>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    value={selectedTopics}
                    onChange={handleChangeTopic}
                    filterOptions={filterOptions}
                    options={options}
                    getOptionLabel={getOptionLabel}
                    selectOnFocus
                    clearOnBlur
                    clearOnEscape
                    handleHomeEndKeys
                    renderOption={renderOption}
                    freeSolo
                    renderInput={renderInput}
                  />
                </Grid>

                <Grid item xs={10} sm={6} md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={!edited}
                  >
                    Save Resource
                  </Button>
                </Grid>
              </Grid>
            </form>
          </UserDetailsLayout>
        );
      }}
    </PageLoader>
  );
};

export default AdminCreateOrUpdateResourcePage;
