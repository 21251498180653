import { put, call } from 'redux-saga/effects';

import * as Exec from 'Redux/creators';
import * as Api from 'Api';

import { enqueueSnackbar, closeSnackbar } from './snackbar';

const MODULE = 'delete-user';

///////////////////////////////////////////////////////////////////////////////
//
// :: CONSTANTS
//
///////////////////////////////////////////////////////////////////////////////
const DELETE_USER_REQUEST = Exec.requestConstantCreator(MODULE);
export const DELETE_USER_SUCCESS = Exec.successConstantCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: ACTIONS
//
///////////////////////////////////////////////////////////////////////////////
export const deleteUser = Exec.requestActionCreator(MODULE);
export const deleteUserLoading = Exec.loadingActionCreator(MODULE);
export const deleteUserSuccess = Exec.successActionCreator(MODULE);
export const deleteUserError = Exec.errorActionCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: REDUCER
//
///////////////////////////////////////////////////////////////////////////////
export const reducer = Exec.mutateReducerCreator(MODULE);

///////////////////////////////////////////////////////////////////////////////
//
// :: SELECTORS
//
///////////////////////////////////////////////////////////////////////////////
export const selectDeleteUserRequest = Exec.mutateRequestSelectorCreator(
  MODULE,
);

///////////////////////////////////////////////////////////////////////////////
//
// :: SAGAS
//
///////////////////////////////////////////////////////////////////////////////
const doDeleteUser = function* ({ payload, meta }) {
  try {
    yield put(closeSnackbar(DELETE_USER_REQUEST));
    yield put(deleteUserLoading());
    const { data } = yield call(Api.deleteUser, payload.userId);
    yield put(deleteUserSuccess({ data }, meta));
  } catch (err) {
    yield put(deleteUserError(err, meta));
    yield put(
      enqueueSnackbar(DELETE_USER_REQUEST, {
        message:
          err.code === 'ERROR_CODE_NETWORK_ERROR'
            ? 'Network error'
            : err.detail ||
              err.message ||
              'There was an error, try again later',
        variant: 'error',
      }),
    );
  }
};

export const sagas = Exec.sagaCreator(MODULE, doDeleteUser);
