import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { selectSubmitToolProblemRequest } from 'Redux/selectors';
import { submitToolProblem } from 'Redux/actions';
import Select from 'Components/shared/select';
import Input from 'Components/shared/input';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button } from '@material-ui/core';

const options = [
  {
    value: 'amazon-fba-profit-calculator',
    label: 'Amazon FBA Profit Calculator',
  },
  {
    value: 'ecom-radar',
    label: 'Ecom Radar',
  },
];

const schema = yup.object().shape({
  tool: yup.string().required('Please select one tool.'),
  problem: yup.string().required('Please describe your problem.'),
});

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
  select: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

const SubmitToolProblemDialog = (props) => {
  const { onClose } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectSubmitToolProblemRequest);

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
  });

  const handleCreate = useCallback(
    (data) => {
      dispatch(submitToolProblem(data, { isPromise: true })).then(onClose);
    },
    [dispatch, onClose],
  );

  return (
    <Dialog
      title="Report A Problem"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleCreate)}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={<Button data-testid="submit">Submit</Button>}
    >
      <Select
        className={styles.select}
        control={control}
        label="Tool"
        name="tool"
        options={options}
        errorText={get(errors, 'tool.message')}
      />
      <Input
        className={styles.input}
        control={control}
        label="Describe the problem"
        name="problem"
        multiline
        rows={5}
        rowsMax={5}
        errorText={get(errors, 'problem.message')}
      />
    </Dialog>
  );
};

SubmitToolProblemDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SubmitToolProblemDialog;
