import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { identity, ary, noop, pickBy, sum, uniq } from 'lodash';

import ensurePrice from 'Util/ensure-price';
import {
  selectUser,
  selectTransactions,
  selectRefundTransactionsRequest,
  selectCourses,
} from 'Redux/selectors';
import { enqueueSnackbar, refundTransactions } from 'Redux/actions';
import Select from 'Components/shared/select';
import Dialog from 'Components/shared/dialog';

import { makeStyles, Button, Typography } from '@material-ui/core';

const reasonOptions = [
  {
    value: 'duplicate',
    label: 'Duplicate',
  },
  {
    value: 'fraudulent',
    label: 'Fraudulent',
  },
  {
    value: 'requested_by_customer',
    label: 'Requested by customer',
  },
];

const useStyles = makeStyles({
  select: {
    width: '100%',
  },
});

const RefundTransactionsDialog = (props) => {
  const { transactionIds, onClose } = props;
  const styles = useStyles();

  const user = useSelector(selectUser);
  const courses = useSelector(selectCourses);
  const transactions = useSelector(selectTransactions);
  const { isLoading } = useSelector(selectRefundTransactionsRequest);
  const dispatch = useDispatch();

  const filtered = useMemo(
    () =>
      transactions
        .filter(
          (t) => transactionIds.includes(t.id) && t.status === 'succeeded',
        )
        .map((t) => ({
          ...t,
          course:
            (courses &&
              courses
                .filter((c) => c.id === t.courseId)
                .map((c) => c.title)[0]) ||
            'Unknown',
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [courses, transactionIds],
  );

  const handleRefund = useCallback(
    (data) => {
      dispatch(
        refundTransactions(
          {
            ...pickBy(data, identity),
            userId: user.id,
            transactionIds: transactions
              .filter(
                (t) =>
                  transactionIds.includes(t.id) && t.status === 'succeeded',
              )
              .map((t) => t.id),
          },
          { isPromise: true },
        ),
      )
        .then(() => {
          dispatch(
            enqueueSnackbar('REFUND_TRANSACTIONS', {
              variant: 'success',
              message: 'Transactions refunded successfully',
            }),
          );
          onClose && onClose(true);
        })
        .catch(noop);
    },
    [dispatch, user, transactions, transactionIds, onClose],
  );

  const { control, handleSubmit } = useForm();

  return (
    <Dialog
      title="Refund Confirmation"
      component="form"
      loading={isLoading}
      onSubmit={handleSubmit(handleRefund)}
      onClose={ary(onClose, 0)}
      cancelButton={
        filtered.length ? <Button data-testid="cancel">Cancel</Button> : null
      }
      submitButton={
        filtered.length ? <Button data-testid="submit">Refund</Button> : null
      }
    >
      <Typography variant="body1" align="center" gutterBottom>
        {filtered.length
          ? `Are you sure you want to refund ${uniq(
              filtered.map((t) => t.course),
            ).join(' & ')} to ${user.firstName} ${
              user.lastName
            } for total of ${ensurePrice(
              sum(filtered.map((t) => t.grossAmount)),
            )}?`
          : 'None of the transactions you selected can be refunded.'}
      </Typography>
      {filtered.length ? (
        <Select
          data-testid="reason"
          className={styles.select}
          control={control}
          label="Reason (optional)"
          name="reason"
          options={reasonOptions}
        />
      ) : null}
    </Dialog>
  );
};

RefundTransactionsDialog.propTypes = {
  transactionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RefundTransactionsDialog;
