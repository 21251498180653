import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { selectDeletePaymentLinkRequest } from 'Redux/selectors';
import { deletePaymentLink, enqueueSnackbar } from 'Redux/actions';
import Dialog from 'Components/shared/dialog';

import { Button, Typography } from '@material-ui/core';

const ConfirmDeletePaymentLinkDialog = (props) => {
  const { onClose, paymentLinkId } = props;
  const { isLoading } = useSelector(selectDeletePaymentLinkRequest);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    dispatch(deletePaymentLink({ paymentLinkId }, { isPromise: true }))
      .then(() => {
        dispatch(
          enqueueSnackbar('DELETE_PAYMENT_LINK', {
            variant: 'success',
            message: 'Payment link deleted successfully',
          }),
        );
        navigate('/admin/payment-links');
        onClose && onClose();
      })
      .catch(noop);
  }, [dispatch, navigate, onClose, paymentLinkId]);

  return (
    <Dialog
      title="Delete Payment Link"
      loading={isLoading}
      onClose={onClose}
      cancelButton={<Button data-testid="cancel">Cancel</Button>}
      submitButton={
        <Button data-testid="submit" onClick={handleDelete}>
          Delete
        </Button>
      }
    >
      <Typography variant="body1" align="center">
        Are you sure you want to delete this link? This is a permanent change
        and cannot be reverted.{' '}
        <strong>The customer will no longer be able to use the link!</strong>
      </Typography>
    </Dialog>
  );
};

ConfirmDeletePaymentLinkDialog.propTypes = {
  paymentLinkId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmDeletePaymentLinkDialog;
